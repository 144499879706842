import React from "react";
import { Dustbin } from "./Dustbin";
import { Box } from "./Box";
import { ItemTypes } from "./ItemTypes";
import Grid from "@mui/material/Grid";
import isEqual from "react-fast-compare";
import parse from "html-react-parser";
import Button from "../../Components/common/Button";
import AddField from "./AddField";
import BackspaceIcon from "@mui/icons-material/Backspace";
import Tooltip from "@mui/material/Tooltip";
export class Container extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accept: ItemTypes.CUSTOM,
      lastDroppedItem: [],
      boxes: [],
      custom: [],
      text: "",
      operators: [
        { name: "+", type: ItemTypes.OPERATOR },
        { name: "-", type: ItemTypes.OPERATOR },
        { name: "*", type: ItemTypes.OPERATOR },
        { name: "/", type: ItemTypes.OPERATOR },
        { name: "%", type: ItemTypes.OPERATOR },
        { name: ">", type: ItemTypes.OPERATOR },
        { name: "<", type: ItemTypes.OPERATOR },
        { name: "!", type: ItemTypes.OPERATOR },
        { name: ":", type: ItemTypes.OPERATOR },
        { name: "?", type: ItemTypes.OPERATOR },
        { name: "(", type: ItemTypes.OPERATOR },
        { name: ")", type: ItemTypes.OPERATOR },
        { name: "++", type: ItemTypes.OPERATOR },
        { name: "!=", type: ItemTypes.OPERATOR },
        { name: ">=", type: ItemTypes.OPERATOR },
        { name: "<=", type: ItemTypes.OPERATOR },
        { name: "--", type: ItemTypes.OPERATOR },
        { name: "==", type: ItemTypes.OPERATOR },
        { name: "===", type: ItemTypes.OPERATOR },
        { name: "||", type: ItemTypes.OPERATOR },
        { name: "&&", type: ItemTypes.OPERATOR },
        { name: "+=", type: ItemTypes.OPERATOR },
        { name: "-=", type: ItemTypes.OPERATOR },
        { name: "*=", type: ItemTypes.OPERATOR },
        { name: "/=", type: ItemTypes.OPERATOR },
        { name: "%=", type: ItemTypes.OPERATOR },
      ],
    };
  }

  handleDrop = (item) => {
    this.setState(
      {
        lastDroppedItem: [...this.state.lastDroppedItem, item],
      },
      () => {
        this.props.playResult(this.state.lastDroppedItem);
      }
    );
  };

  UNSAFE_componentWillMount() {
    if (this.props.form && this.props.form.expression) {
      const list = (this.props.form.expression + "").split(" ");
      const operators = this.state.operators.map((e) => e.name);
      const custom = list.filter((e) => e && !operators.includes(e));

      const lastDroppedItem = [];
      list.map((e) => {
        if (operators.includes(e)) {
          lastDroppedItem.push({ name: e, type: ItemTypes.OPERATOR });
        } else {
          if (e) lastDroppedItem.push({ name: e, type: ItemTypes.CUSTOM });
        }
        return null;
      });
      this.setState(
        {
          custom: custom.map((e) => ({ name: e, type: ItemTypes.CUSTOM })),
          lastDroppedItem,
        },
        () => {
          this.props.playResult(this.state.lastDroppedItem);
        }
      );
    }
    this.setState({
      boxes:
        this.props.selctedDeviceAttributes &&
        this.props.selctedDeviceAttributes.length
          ? this.props.selctedDeviceAttributes.map((e) => ({
              name: e,
              type: ItemTypes.VARIABLE,
            }))
          : [],
    });
  }

  UNSAFE_componentWillReceiveProps(n) {
    if (
      !isEqual(n.selctedDeviceAttributes, this.props.selctedDeviceAttributes)
    ) {
      this.setState(
        {
          boxes: n.selctedDeviceAttributes.map((e) => ({
            name: e,
            type: ItemTypes.VARIABLE,
          })),
        },
        () => {
          this.props.playResult(this.state.lastDroppedItem);
        }
      );
    }
  }

  reverseLastAction = () => {
    const lastDroppedItem = [...this.state.lastDroppedItem];

    if (lastDroppedItem && lastDroppedItem.length > 0) {
      lastDroppedItem.pop();
      this.setState({ lastDroppedItem }, () => {
        this.props.playResult(this.state.lastDroppedItem);
      });
    }
  };

  changeText = (event) => {
    this.setState({ text: event.target.value });
  };

  addCustomItem = (event) => {
    if (this.state.text !== undefined) {
      event.preventDefault();
      const custom = [...this.state.custom];
      custom.push({ name: this.state.text, type: ItemTypes.CUSTOM });

      this.setState({
        custom,
        text: "",
      });
    }
  };

  render() {
    return (
      <>
        <Grid container style={{ alignItems: "center" }} spacing={2}>
          <Grid item xs={12} sm={6} md={4}>
            {this.props.deviceSelector}
          </Grid>
        </Grid>
        <div
          className="dnd-ebuilder"
          style={{
            background: this.props.themecolors.backgroundColor,
            color: this.props.themecolors.textColor,
          }}
        >
          <div
            className="dnd-placeholder"
            style={{ overflow: "hidden", clear: "both" }}
          >
            {this.state.lastDroppedItem && this.state.lastDroppedItem.length ? (
              <Tooltip
                title="Undo Last action"
                classes={{
                  popper: "menu-popper",
                  tooltip: "menu-popper-tooltip",
                }}
              >
                <Button
                  className="action-rollback-btn"
                  onClick={this.reverseLastAction}
                >
                  <BackspaceIcon />
                </Button>
              </Tooltip>
            ) : null}

            {this.state.accept === ItemTypes.OPERATOR ? (
              <Dustbin
                form={this.props.form}
                accept={[ItemTypes.OPERATOR, ItemTypes.CUSTOM]}
                lastDroppedItem={this.state.lastDroppedItem}
                onDrop={(item) => this.handleDrop(item)}
              />
            ) : null}
            {this.state.accept === ItemTypes.VARIABLE ? (
              <Dustbin
                form={this.props.form}
                accept={[ItemTypes.VARIABLE, ItemTypes.CUSTOM]}
                lastDroppedItem={this.state.lastDroppedItem}
                onDrop={(item) => this.handleDrop(item)}
              />
            ) : null}
            {this.state.accept === ItemTypes.CUSTOM ? (
              <Dustbin
                form={this.props.form}
                accept={[
                  ItemTypes.OPERATOR,
                  ItemTypes.VARIABLE,
                  ItemTypes.CUSTOM,
                ]}
                lastDroppedItem={this.state.lastDroppedItem}
                onDrop={(item) => this.handleDrop(item)}
              />
            ) : null}
            {this.props.result ? (
              <p className="text-warning">{parse(this.props.result)}</p>
            ) : null}
          </div>

          <div style={{ overflow: "hidden", clear: "both" }}>
            <Grid container>
              <Grid item sm={12}>
                {this.state.boxes.length ? <h4>Variables</h4> : null}
                {this.state.boxes.map(({ name, type }, index) => (
                  <Box
                    themecolors={this.props.themecolors}
                    name={name}
                    type={type}
                    key={index}
                  />
                ))}
              </Grid>
              <Grid item sm={12}>
                <h4>Operators</h4>
                {this.state.operators.map(({ name, type }, index) => (
                  <Box
                    themecolors={this.props.themecolors}
                    name={name}
                    type={type}
                    key={index}
                  />
                ))}
              </Grid>
              <Grid item sm={12}>
                {this.state.custom.length ? <h4>Custom</h4> : null}
                {this.state.custom.length
                  ? this.state.custom.map(({ name, type }, index) => (
                      <Box
                        themecolors={this.props.themecolors}
                        name={name}
                        type={type}
                        key={index}
                      />
                    ))
                  : null}
              </Grid>
            </Grid>
          </div>
        </div>

        <Grid container>
          <Grid item sm={12} md={4}>
            <AddField
              onSubmit={this.addCustomItem}
              onChange={this.changeText}
              value={this.state.text}
              fullWidth
            />
          </Grid>
        </Grid>
      </>
    );
  }
}
