import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import CTab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import { DeviceModal } from "../../Components/Devices/deviceModal";
import { unitsList } from "../../Components/Devices/unitsPortList";
import CustomDialog from "../../Components/common/Dialog";
import { connect } from "react-redux";
import IconForm from "./Components/IconForm";
import { SendCommand } from "./Components/sendCommand";
import {
  checkPrivileges,
  errorHandler,
  postionAttributesTypeComp,
  selectAll,
} from "../../Helpers";
// import Button from '../../Components/common/Button'
import Sensors from "./Components/sensors";
import Accumulators from "./Components/accumulators";
import AdvanceForm from "./Components/AdvanceForm";
import isEqual from "react-fast-compare";
import { notifyError, notifySuccess } from "../../Utils/CustomNotifcations";
// import MarkerMap from '../../Components/Maps'
import { ComputedAttributes } from "./Components/computedAttributes";
import parse from "html-react-parser";
// import Style from 'style-it'
// import axios from 'axios'
// import { DriversModal } from '../../Components/Drivers/driversModal'

/* ACTIONS */
import { updateDevice } from "../../Actions/Devices";
import { removeUser, addUser } from "../../Actions/Users";
import {
  driverDeviceWiseSelectedList,
  addNotification,
  userDeviceWiseSelectedList,
  removedUserDeviceWiseSelectedList,
  updateUserDeviceWiseSelectedList,
  addUserDeviceWiseSelectedList,
  addNotificationDeviceWise,
  RemoveNotificationDeviceWise,
  notificationDeviceWiseSelectedList,
  getNotificationDeviceWise,
  removedNotification,
  removeNotificationDeviceWiseSelectedList,
  addNotificationDeviceWiseSelectedList,
  updateNotificationDeviceWiseSelectedList,
  getNotification,
} from "../../Actions/Notifications";
import {
  addComputedAttribute,
  computedAttributeDeviceWiseSelectedList,
  getComputedAttributeDeviceWise,
  addComputedAttributeDeviceWise,
  RemoveComputedAttributeDeviceWise,
  removedComputedAttribute,
  removedComputedAttributeDeviceWiseSelectedList,
  addComputedAttributeDeviceWiseSelectedList,
  updateComputedAttributeDeviceWiseSelectedList,
  getComputedAttributes,
} from "../../Actions/ComputedAttributes";
import {
  addCommands,
  getCommandsDeviceWise,
  commandsDeviceWiseSelectedList,
  addCommandsDeviceWise,
  RemoveCommandsDeviceWise,
  removedCommands,
  addCommandsDeviceWiseSelectedList,
  updateCommandsDeviceWiseSelectedList,
  removedCommandsDeviceWiseSelectedList,
  getCommands,
} from "../../Actions/Commands";
import {
  addMaintenance,
  addMaintenanceDeviceWise,
  RemoveMaintenanceDeviceWise,
  getMaintenanceDeviceWise,
  maintenanceDeviceWiseSelectedList,
  removedMaintenance,
  addMaintenanceDeviceWiseSelectedList,
  updateMaintenanceDeviceWiseSelectedList,
  removedMaintenanceDeviceWiseSelectedList,
} from "../../Actions/Maintenance";
import { removeDevice } from "./../../Actions/Devices";
/* ACTIONS END */

// common sections
import Notification from "./Components/notification";
import ConfirmDialoag from "../../Components/common/ConfirmDialoag";
import SavedCommands from "./Components/savedCommands";
import MaintenanceForm from "./Components/maintenance";
import RawAttributes from "./Components/rawAttributes";
import Users from "./Components/users";
import Icon from "@mui/material/Icon";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import EcoDriving from "./Components/EcoDriving";
import instance from "../../axios";
import withTranslationWrapper from "../../HOC/withTranslation";

let sensorDeviceids = [];

const styles = (theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexGrow: 1,
    },
  },
  tabs: {
    [theme.breakpoints.up("md")]: {
      borderRight: `1px solid ${theme.palette.divider}`,
    },
  },
  scrollButtons: {
    color: "#333",
  },
  indicator: {
    backgroundColor: "#333",
  },
});

const VerticalTabs = withStyles((theme) => ({
  flexContainer: {
    [theme.breakpoints.up("md")]: {
      paddingBottom: 10,
      flexDirection: "column",
      paddingTop: 10,
    },
  },
  root: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      minWidth: 220,
      maxWidth: 220,
      borderRight: `1px solid ${theme.palette.divider}`,
      float: "left",
    },
  },
  indicator: {
    display: "none",
  },
  scrollable: {
    [theme.breakpoints.up("sm")]: {
      overflow: "auto",
    },
  },
}))(Tabs);

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ color: "inherit", ...other.style }}
      className="custom-unit-tabpanel-container"
    >
      {children}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// --> parking form
function checkMinimalTripDistance(val, convertForApi) {
  /* let initVal =
    (localStorage.getItem('userInfoFront') &&
      JSON.parse(localStorage.getItem('userInfoFront')).attributes.distanceUnit) ||
    ''
  let value = val
  if (convertForApi) {
    if (initVal) {
      if (initVal === 'km') {
        value = value * 1000
      } else if (initVal === 'mi') {
        value = value * 1609.34
      } else if (initVal === 'nmi') {
        value = value * 1852
      }
    }
  } else {
    if (initVal) {
      if (initVal === 'km') {
        value = value * 0.001
      } else if (initVal === 'mi') {
        value = value * 0.000621371
      } else if (initVal === 'nmi') {
        value = value * 0.000539957
      }
    }
  }
  let nVal = parseFloat(value).toFixed(5) */
  return val;
}
function checkSpeedThreshold(val, convertForApi) {
  let initVal =
    (localStorage.getItem("userInfoFront") &&
      JSON.parse(localStorage.getItem("userInfoFront")).attributes.speedUnit) ||
    "";
  let value = val;
  let nVal = 0;
  if (convertForApi) {
    if (initVal && initVal !== "kn") {
      if (initVal === "kmh") {
        value = value * 0.539957;
      } else if (initVal === "mph") {
        value = value * 0.868976;
      }
    }
    nVal = value;
  } else {
    if (initVal && initVal !== "kn") {
      if (initVal === "kmh") {
        value = value * 1.852;
      } else if (initVal === "mph") {
        value = value * 1.15078;
      }
    }
    nVal = parseFloat(Math.round(value).toFixed(5));
  }
  return nVal;
}

const unitParkingAttFormat = {
  minimalNoDataDuration: 600,
  minimalParkingDuration: 300,
  minimalTripDuration: 60,
  minimalTripDistance: checkMinimalTripDistance(100),
  speedThreshold: checkSpeedThreshold(1.61987),
  processInvalidPositions: false,
  useIgnition: 2,
  storeTime: 10,
  minimalFuelFillingVolume: 10,
  minimalFuelDrainVolume: 10,
  consecutiveFuelFillingTimeout: 120,
  consecutiveFuelDrainTimeout: 120,
  fuelAccuracy: 5,
};

const NotificationDataFormat = {
  always: "",
  attributes: {},
  calendarId: "",
  id: "",
  notificators: "",
  type: "",
};

const ComputedAttributesDataFormat = {
  description: "",
  attribute: "",
  expression: "",
  type: "",
  attributes: { copyFromLast: false },
};
const savedCommandsDataFormat = {
  attributes: {},
  deviceId: "",
  id: "",
  description: "",
  type: "custom",
  textChannel: "",
};
const maintenanceDataFormat = {
  attributes: { status: true },
  name: "",
  type: "",
  start: "",
  period: "",
};

const locations = [
  "#allAttribute",
  "#basic",
  "#icon",
  "#sensors",
  "#sharedDeviceAccumulators",
  "#Advance",
  "#sharedDrivers",
  "#Access",
  "#sharedGeofences",
  "#sharedNotifications",
  "#sharedComputedAttributes",
  "#sharedSavedCommand",
  "#sharedMaintenance",
  "#ecoDriving",
];

class MainUnitModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: "allAttribute",
      currentId: 0,
      selecteditem: null,
      activeOperation: "",
      driverObj: "",
      address: "",
      lat: "",
      lng: "",
      deviceModal: false,
      openDriverModal: false,
      unitParkingAttributesform: { ...unitParkingAttFormat },
      notificationForm: { ...NotificationDataFormat },
      computedAttForm: { ...ComputedAttributesDataFormat },
      savedCommandsForm: { ...savedCommandsDataFormat },
      sendCommandsForm: { ...savedCommandsDataFormat },
      maintenanceForm: { ...maintenanceDataFormat },
      isVisableASOneBtn: false,
      visableAttribute: false,
      visableNotification: false,
      visableSavedCommands: false,
      visableMaintenance: false,
      visableGeofence: false,

      visableListNotification: true,
      visableListGeofence: true,
      visableListAttribute: true,
      visableListSavedCommands: true,
      visableListMaintenance: true,

      notificationCallStatus: "",
      attributeCallStatus: "",
      commandsCallStatus: "",
      maintenanceCallStatus: "",

      suggestions: [],
      isAdvSettingEnable: false,

      geofenceModal: false,
      geofenceModalData: null,
      geofenceModalMapData: null,
      rowsPerPage: 5,
      copySensor: {},
      allDevices: [],
      showDialg: false,
      openResponseDialg: false,
      copiedData: [],
    };
    this.tabChange = this.tabChange.bind(this);
    this.logo_handelChanges = this.logo_handelChanges.bind(this);
    this.isButtonVisable = this.isButtonVisable.bind(this);
    this.parkingformSubmit = this.parkingformSubmit.bind(this);
    this.onCloseC_Att_Modal = this.onCloseC_Att_Modal.bind(this);
    this.advenceSectionOneformSubmit =
      this.advenceSectionOneformSubmit.bind(this);
    this.updateSelectedItem = this.updateSelectedItem.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);

    // notifications

    // common

    //1st
    this.onListNotification = this.onListNotification.bind(this);
    this.onListComputedAttributes = this.onListComputedAttributes.bind(this);
    this.onListSavedCommands = this.onListSavedCommands.bind(this);
    this.onListMaintenance = this.onListMaintenance.bind(this);

    // 2nd
    this.onCreateNewNotification = this.onCreateNewNotification.bind(this);
    //this.onCreateNewGeofence = this.onCreateNewGeofence.bind(this)
    this.onCreateNewAttributes = this.onCreateNewAttributes.bind(this);
    this.onCreateNewSavedCommands = this.onCreateNewSavedCommands.bind(this);
    this.onCreateNewMaintenance = this.onCreateNewMaintenance.bind(this);

    // 3rd
    this.notification_Selection = this.notification_Selection.bind(this);
    //this.geofence_Selection = this.geofence_Selection.bind(this)
    //this.driver_Selection = this.driver_Selection.bind(this)
    this.user_Selection = this.user_Selection.bind(this);
    this.computedAttributes_Selection =
      this.computedAttributes_Selection.bind(this);
    this.savedCommands_Selection = this.savedCommands_Selection.bind(this);
    this.maintenance_Selection = this.maintenance_Selection.bind(this);

    // 4th
    this.notificationHandle = this.notificationHandle.bind(this);
    this.attributeHandle = this.attributeHandle.bind(this);
    this.savedCommandsHandle = this.savedCommandsHandle.bind(this);
    this.maintenancesHandle = this.maintenancesHandle.bind(this);

    // 5th
    this.addNotification = this.addNotification.bind(this);
    this.addComputedAttributes = this.addComputedAttributes.bind(this);
    this.addSavedCommands = this.addSavedCommands.bind(this);
    this.addMaintenance = this.addMaintenance.bind(this);
    this.addUser = this.addUser.bind(this);

    // 6th
    this.editNotification = this.editNotification.bind(this);
    this.editComputedAttributes = this.editComputedAttributes.bind(this);
    this.editCommands = this.editCommands.bind(this);
    this.editMaintenance = this.editMaintenance.bind(this);
    this.addMaintenanceAttributes = this.addMaintenanceAttributes.bind(this);

    // remove functions

    this.removeMaintenance = this.removeMaintenance.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.removeNotification = this.removeNotification.bind(this);
    // this.removeGeofence = this.removeGeofence.bind(this)
    // this.removeDriver = this.removeDriver.bind(this)
    this.removeComputedAttributes = this.removeComputedAttributes.bind(this);
    this.removeCommands = this.removeCommands.bind(this);
    this.removeDevice = this.removeDevice.bind(this);

    this.formSubmit = this.formSubmit.bind(this);
  }

  /* openGeofenceModal = data => {
    let p = this.props.positions.find(
      p => parseInt(p.deviceId) === parseInt(this.props.id)
    )
    let geofenceModalMapData = {}
    if (p) {
      geofenceModalMapData = {
        center: {
          lat: p.latitude,
          lng: p.longitude
        },
        zoom: 16
      }
    }
    this.setState({
      geofenceModal: true,
      geofenceModalData: data[0],
      geofenceModalMapData
    })
  } */

  /* closeGeofenceModal = data => {
    let geofences = this.props.geofenceDeviceWise
    let allData = this.props.geofences
    if (geofences.length) {
      allData.map(item => {
        item.check = false
        geofences.map(main => {
          if (item.id && main.id && item.id === main.id) {
            item.check = main.check
          }
          return null
        })
        return null
      })
    } else {
      allData.map(n => (n.check = false))
    }
    this.props.dispatch(getGeofenceDeviceWise(geofences))
    this.props.dispatch(geofenceDeviceWiseSelectedList(allData))
    this.setState({
      geofenceModal: false,
      geofenceModalData: null,
      geofenceModalMapData: {}
    })
  } */

  componentWillMount() {
    this.setState(
      {
        currentId:
          this.props.devices3 && this.props.devices3.length
            ? parseInt(this.props.id)
            : 0,
      },
      () => {
        this.getCurrent(this.props.id);
      }
    );

    if (!this.props.disableHash && this.props.hash) {
      let hash = locations.indexOf(this.props.hash);
      if (hash !== -1) {
        this.setState({
          currentTab: this.props.hash.substring(1, this.props.hash.length),
        });
      }
    }
  }

  componentWillReceiveProps(n) {
    if (n.id) {
      let suggestions = false;
      if (n.positions.length) {
        n.positions.map((p) => {
          if (parseInt(n.id) === parseInt(p.deviceId)) {
            let inputs = Object.keys(p.attributes);
            if (p.protocol === "bce") {
              for (var i = 1; i <= 16; i++) {
                inputs.push("input" + i);
              }
            }
            suggestions = true;
            this.setState({ suggestions: inputs });
          }
          return null;
        });
      }
      if (!n.disableHash && n.hash !== this.props.hash) {
        let hash = locations.indexOf(n.hash);
        if (hash !== -1) {
          this.setState({ currentTab: n.hash.substring(1, n.hash.length) });
        }
      }

      if (!suggestions) {
        this.setState({ suggestions: [] });
      }
      if (
        n.id &&
        parseInt(
          this.state.currentId ||
            (this.state.selecteditem ? this.state.selecteditem.id : 0)
        ) !== parseInt(n.id) &&
        n.devices3.length
      ) {
        this.setState(
          {
            currentId: parseInt(n.id),
          },
          () => {
            this.getCurrent(n.id);
          }
        );
      }
    }
  }

  advenceSectionOneformSubmit(category) {
    this.onFormSubmit(category);
  }

  onFormSubmit(targetForm) {
    this.setState(
      {
        resFormData: { ...this.state.resFormData, ...targetForm },
      },
      () => {
        this.formSubmit();
      }
    );
  }

  // change tab
  tabChange = (event, currentTab) => {
    this.setState({
      driverObj: "",
      openDriverModal: false,
      activeOperation: "",
    });
    if (this.props.disableHash) {
      this.setState({ currentTab });
    } else {
      this.props.history.push("/units/" + this.props.id + "#" + currentTab);
    }
  };

  openDriverModal = () => {
    this.setState({
      openDriverModal: true,
      activeOperation: "add",
      driverObj: "",
    });
  };

  onCloseModal = () => {
    this.setState(
      {
        activeOperation: "",
        driverObj: "",
        openDriverModal: false,
        isSendCommandModal: false,
      },
      () => {
        this.updateSelectedDrivers();
      }
    );
  };

  addNewGroups = () => {
    this.setState({
      isaddGroupVisable: !this.state.isaddGroupVisable,
    });
  };

  changeAttribute2 = (name, event) => {
    this.setState({
      selecteditem: {
        ...this.state.selecteditem,
        attributes: {
          ...this.state.selecteditem.attributes,
          [name]: event.target.value,
        },
      },
    });
  };

  logo_handelChanges(item) {
    this.setState({
      aOneform: {
        category: item.name,
      },
      isVisableASOneBtn: !isEqual(item.name, this.state.selecteditem.category),
      selecteditem: {
        ...this.state.selecteditem,
        category: item.name,
      },
    });
  }

  // Common

  // 1st

  onListNotification() {
    this.setState({
      visableListNotification: true,
    });
  }

  onListComputedAttributes() {
    this.setState({
      visableListAttribute: true,
    });
  }

  onListSavedCommands() {
    this.setState({
      visableListSavedCommands: true,
    });
  }
  onListMaintenance() {
    this.setState({
      visableListMaintenance: true,
    });
  }

  // 2nd
  onCreateNewNotification() {
    this.props.history.push("/notificationCreate");
    this.setState({
      isAddVisableBtn: false,
      notificationCallStatus: "POST",
      visableNotification: true,
      notificationForm: { ...NotificationDataFormat },
      addButtonStatus: "sharedAdd",
      isHaveAccess: "notificationCreate",
    });
  }

  onCreateNewAttributes() {
    this.setState({
      isAddVisableBtn: false,
      attributeCallStatus: "POST",
      visableAttribute: true,
      computedAttForm: { ...ComputedAttributesDataFormat },
      addButtonStatus: "sharedAdd",
      isHaveAccess: "attributeCreate",
    });
  }

  onCreateNewSavedCommands() {
    this.setState({
      isAddVisableBtn: false,
      commandsCallStatus: "POST",
      visableSavedCommands: true,
      savedCommandsForm: { ...savedCommandsDataFormat },
      addButtonStatus: "sharedAdd",
      isHaveAccess: "commandCreate",
    });
  }

  onCreateNewMaintenance() {
    this.props.history.push("/maintenanceCreate");
    // this.setState({
    //   isAddVisableBtn: false,
    //   maintenanceCallStatus: 'POST',
    //   visableMaintenance: true,
    //   maintenanceForm: { ...maintenanceDataFormat },
    //   addButtonStatus: 'sharedAdd',
    //   isHaveAccess: 'maintenanceCreate'
    // })
  }

  // 3rd
  notification_Selection(checked, item) {
    let method = "DELETE";
    let value = checked;
    let allData = [...this.props.selectednotification];
    let obj = {
      deviceId: this.props.id,
      notificationId: item.id,
    };
    if (value) {
      method = "POST";
    }
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        if (method === "DELETE") {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          this.props.dispatch(updateNotificationDeviceWiseSelectedList(item));
          this.props.dispatch(RemoveNotificationDeviceWise(item));
          this.props.dispatch(
            notifySuccess(
              this.props.translate("notificationUnAssignedSuccessfully")
            )
          );
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          this.props.dispatch(updateNotificationDeviceWiseSelectedList(item));
          this.props.dispatch(addNotificationDeviceWise(item));
          this.props.dispatch(
            notifySuccess(
              this.props.translate("notificationAssignedSuccessfully")
            )
          );
        }
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }

  /* geofence_Selection (checked, item) {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selectedgeofence]
    let obj = {
      deviceId: this.props.id,
      geofenceId: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
            ...obj
          }
    })
    .then(response => {
      // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateGeofenceDeviceWiseSelectedList(item))
          this.props.dispatch(RemoveGeofenceDeviceWise(item))
          this.props.dispatch(
            notifySuccess({
              message: this.props.translate('geofenceUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateGeofenceDeviceWiseSelectedList(item))
          this.props.dispatch(addGeofenceDeviceWise(item))
          this.props.dispatch(
            notifySuccess({
              message: this.props.translate('geofenceAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        }
      // }
    })
  }

  driver_Selection (checked, item) {
    let method = 'DELETE'
    let value = checked
    let allData = [...this.props.selecteddriver]
    let obj = {
      deviceId: this.props.id,
      driverId: item.id
    }
    if (value) {
      method = 'POST'
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      data:{
            ...obj
          }
    })
    .then(response => {
      // if (response.status === 204) {
        if (method === 'DELETE') {
          item.check = false
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = false
            }
            return null
          })
          this.props.dispatch(updateDriverDeviceWiseSelectedList(item))
          this.props.dispatch(RemoveDriverDeviceWise(item))
          this.props.dispatch(
            notifySuccess({
              message: this.props.translate('driverUnAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        } else {
          item.check = true
          allData.map(dt => {
            if (dt.id === item.id) {
              dt.check = true
            }
            return null
          })
          this.props.dispatch(updateDriverDeviceWiseSelectedList(item))
          this.props.dispatch(addDriverDeviceWise(item))
          this.props.dispatch(
            notifySuccess({
              message: this.props.translate('driverAssignedSuccessfully'),
              autoDismiss: 10
            })
          )
        }
      // }
    })
  } */

  user_Selection(checked, item) {
    let method = "DELETE";
    let value = checked;
    let allData = [...this.props.selecteduser];
    let obj = {
      userId: item.id,
      deviceId: parseInt(this.props.id),
    };
    if (value) {
      method = "POST";
    }
    // fetch(`/api/permissions`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        if (method === "DELETE") {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.userId) {
              dt.check = false;
            }
            return null;
          });

          this.props.dispatch(
            notifySuccess(this.props.translate("userUnAssignedSuccessfully"))
          );
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.userId) {
              dt.check = true;
            }
            return null;
          });
          this.props.dispatch(
            notifySuccess(this.props.translate("userAssignedSuccessfully"))
          );
        }
        this.props.dispatch(userDeviceWiseSelectedList(allData));
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
    // if (e && e.text) {
    //   e.text().then(err => {
    //     let a = err.split('- SecurityException').splice(0, 1)
    //     //alert(a[0])
    //     this.props.dispatch(
    //       notifyError({
    //         message: a[0],
    //         autoDismiss: 10
    //       })
    //     )
    //   })
    // }
  }

  computedAttributes_Selection(checked, item) {
    let method = "DELETE";
    let value = checked;
    let allData = this.props.computedAttributes;
    let obj = {
      deviceId: this.props.id,
      attributeId: item.id,
    };
    if (value) {
      method = "POST";
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        if (method === "DELETE") {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          this.props.dispatch(
            updateComputedAttributeDeviceWiseSelectedList(item)
          );
          this.props.dispatch(RemoveComputedAttributeDeviceWise(item));
          this.props.dispatch(
            notifySuccess(
              this.props.translate("computedAttributeUnAssignedSuccessfully")
            )
          );
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          this.props.dispatch(
            updateComputedAttributeDeviceWiseSelectedList(item)
          );
          this.props.dispatch(addComputedAttributeDeviceWise(item));
          this.props.dispatch(
            notifySuccess(
              this.props.translate("computedAttributeAssignedSuccessfully")
            )
          );
        }
        // } else {
        //   throw response
        // }
      })
      .catch((e) => {
        // if (e && e.text) {
        //   e.text().then(err => {
        this.props.dispatch(
          notifyError(
            "Invalid Parameter. You cannot assign " +
              item.description +
              " parameter because device is not sending " +
              item.attribute +
              " data."
          )
        );
        this.setState({ visableListAttribute: false }, () => {
          this.setState({ visableListAttribute: true });
        });
        //   })
        // }
      });
    // .catch(error => {errorHandler(error,this.props.dispatch,()=>this.errorCallBack(item))})
  }

  savedCommands_Selection(checked, item) {
    let method = "DELETE";
    let value = checked;
    let allData = this.props.commands;
    let obj = {
      deviceId: this.props.id,
      commandId: item.id,
    };
    if (value) {
      method = "POST";
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        if (method === "DELETE") {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          this.props.dispatch(updateCommandsDeviceWiseSelectedList(item));
          this.props.dispatch(RemoveCommandsDeviceWise(item));
          this.props.dispatch(
            notifySuccess(
              this.props.translate("savedCommandsUnAssignedSuccessfully")
            )
          );
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          this.props.dispatch(updateCommandsDeviceWiseSelectedList(item));
          this.props.dispatch(addCommandsDeviceWise(item));
          this.props.dispatch(
            notifySuccess(
              this.props.translate("savedCommandsAssignedSuccessfully")
            )
          );
        }
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }

  maintenance_Selection(checked, item) {
    let method = "DELETE";
    let value = checked;
    let allData = this.props.maintenance;
    let obj = {
      deviceId: this.props.id,
      maintenanceId: item.id,
    };
    if (value) {
      method = "POST";
    }
    // fetch(`/api/permissions/`, {
    //   method: method,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        if (method === "DELETE") {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          this.props.dispatch(updateMaintenanceDeviceWiseSelectedList(item));
          this.props.dispatch(RemoveMaintenanceDeviceWise(item));
          this.props.dispatch(
            notifySuccess(
              this.props.translate("maintenanceUnAssignedSuccessfully")
            )
          );
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          this.props.dispatch(updateMaintenanceDeviceWiseSelectedList(item));
          this.props.dispatch(addMaintenanceDeviceWise(item));
          this.props.dispatch(
            notifySuccess(
              this.props.translate("maintenanceAssignedSuccessfully")
            )
          );
        }
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }

  //devices assigned in sensors
  Devices_Selection = (checked, item) => {
    let allData = this.props.devices2;
    item.check = checked;
    allData.map((dt) => {
      if (dt.id === item.id) {
        dt.check = checked;
      }
    });
    sensorDeviceids.push(item.id);
    this.setState({
      sensorDeviceids: sensorDeviceids,
      allDevices: allData,
    });
  };

  selectAllCopySensors = (e) => {
    let allData = this.props.devices2;
    allData.map((dt) => {
      dt.check = e ? true : false;
      sensorDeviceids.push(dt.id);
    });
    if (e) {
      this.setState({
        sensorDeviceids: sensorDeviceids,
        allDevices: allData,
      });
    } else {
      sensorDeviceids = [];
      this.setState({
        sensorDeviceids: [],
        allDevices: allData,
      });
    }
  };

  closeDialog = () => {
    let allData = this.props.devices2;
    allData.map((dt) => {
      dt.check = false;
    });
    this.setState({
      showDialg: false,
      openResponseDialg: false,
      sensorDeviceids: [],
      allDevices: allData,
    });
    sensorDeviceids = [];
  };

  onCopy = (item, mode) => {
    this.setState(
      {
        showDialg: true,
      },
      () => {
        this.selectedCopySensor(item);
      }
    );
  };

  submitCopySenors = () => {
    let method = "POST";
    let obj = [];
    instance({
      url: `/api/sensors/copy/${this.state.copySensor.id}`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: { deviceIds: sensorDeviceids },
    })
      .then((response) => {
        let allData = this.props.devices2;
        this.setState({
          showDialg: false,
          openResponseDialg: true,
        });
        for (const [key, value] of Object.entries(response)) {
          allData.map((dt) => {
            if (dt.id == key) {
              dt.message = value;
              obj.push(dt);
            }
          });
        }
        this.setState({
          copiedData: obj,
        });
        this.props.dispatch(
          notifySuccess(this.props.translate("sensorCopied"))
        );
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  selectedCopySensor = (item) => {
    this.setState({ copySensor: item });
  };

  // 4th
  notificationHandle(name, value) {
    this.setState(
      {
        notificationForm: {
          ...this.state.notificationForm,
          [name]: value.id,
        },
      },
      () => this.checkRequiredFields("notification")
    );
  }

  attributeHandle(name, value) {
    let selectedValue = postionAttributesTypeComp
      .filter((m) => m.key === value)
      .map((m) => m.valueType);
    if (name === "copyFromLast") {
      this.setState(
        {
          computedAttForm: {
            ...this.state.computedAttForm,
            attributes: {
              [name]: value,
            },
          },
        },
        () => this.checkRequiredFields("computedAtt")
      );
    } else {
      this.setState(
        {
          computedAttForm: {
            ...this.state.computedAttForm,
            [name]: value,
            type: selectedValue[0] ? selectedValue[0] : "string",
          },
        },
        () => this.checkRequiredFields("computedAtt")
      );
    }
  }

  savedCommandsHandle(name, value) {
    this.setState(
      {
        savedCommandsForm: {
          ...this.state.savedCommandsForm,
          type: value.id,
        },
      },
      () => this.checkRequiredFields("Commands")
    );
  }

  maintenancesHandle(name, value) {
    let obj = { ...this.state.maintenanceForm, [name]: value.id };

    if (this.state.visableMaintenance && name === "type") {
      let pos = this.props.positions.find(
        (p) => p.deviceId === parseInt(this.props.id)
      );
      if (pos) {
        if (value.id === "totalDistance") {
          obj.start = parseFloat(
            (pos.attributes.totalDistance / 1000).toFixed(2)
          );
        } else if (value.id === "hours") {
          obj.start = parseFloat(
            (pos.attributes.hours / (1000 * 3600)).toFixed(2)
          );
        } else if (value.id === "date") {
          obj.start = 1;
        }
      }
    }

    this.setState(
      {
        maintenanceForm: {
          ...obj,
        },
      },
      () => this.checkRequiredFields("maintenance")
    );
  }

  saveToParent = (obj) => {
    this.setState(
      {
        maintenanceForm: {
          ...this.state.maintenanceForm,
          attributes: { ...this.state.maintenanceForm.attributes, ...obj },
        },
      },
      () => this.checkRequiredFields("maintenance")
    );
  };

  // 5th
  addNotification() {
    let obj = { ...this.state.notificationForm };
    if (
      (this.state.notificationCallStatus === "PUT" ||
        this.state.notificationCallStatus === "POST") &&
      obj.notificators &&
      obj.notificators.length
    ) {
      let notificatorsArr = [];
      obj.notificators.map((v) => {
        notificatorsArr.push(v.value);
        return v;
      });
      let notificators = notificatorsArr.join(",");
      obj.notificators = notificators;
    }
    let params = "";
    if (
      this.state.notificationCallStatus === "PUT" ||
      this.state.notificationCallStatus === "DELETE"
    ) {
      params = obj.id;
    }
    if (obj) {
      let check;
      if (obj.check !== undefined) {
        check = obj.check;
        delete obj.check;
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms };
        delete obj.alarms;
      }
      // fetch(`/api/notifications/${params}`, {
      //   method: `${this.state.notificationCallStatus}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/notifications/${params}`,
        method: `${this.state.notificationCallStatus}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: {
          ...obj,
        },
      })
        .then((notifications) => {
          // if (response.ok) {
          if (this.state.notificationCallStatus === "DELETE") {
            this.props.dispatch(removeNotificationDeviceWiseSelectedList(obj));
            this.props.dispatch(removedNotification(obj));
            this.props.dispatch(
              notifySuccess(this.props.translate("notificationIsDeleted"))
            );
            this.setState({
              onDeleteConfirmationNotification: false,
              attributeChangesMessage: "",
            });
          } else {
            // response.json().then(notifications => {
            this.setState({
              visableNotification: false,
            });

            if (this.state.notificationCallStatus === "PUT") {
              let noti = { ...notifications, check };
              this.props.dispatch(
                updateNotificationDeviceWiseSelectedList(noti)
              );
              this.props.dispatch(addNotification(noti));
              this.props.dispatch(
                notifySuccess(this.props.translate("notificationIsUpdated"))
              );
              this.setState({
                attributeChangesMessage: "",
              });
            } else {
              this.notification_Selection(true, notifications);
              let noti = { ...notifications, check: true };
              this.props.dispatch(addNotificationDeviceWiseSelectedList(noti));
              this.props.dispatch(addNotification(noti));
              this.props.dispatch(
                notifySuccess(this.props.translate("notificationIsCreated"))
              );
              this.setState({
                attributeChangesMessage: "",
              });
            }
            // })
          }
          // }
          // else{
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  }

  addComputedAttributes() {
    let obj = this.state.computedAttForm;
    let params = "";
    if (
      this.state.attributeCallStatus === "PUT" ||
      this.state.attributeCallStatus === "DELETE"
    ) {
      params = obj.id;
      delete obj.deviceId;
    }
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check;
      }
      // fetch(`/api/attributes/computed/${params}`, {
      //   method: `${this.state.attributeCallStatus}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/attributes/computed/${params}`,
        method: `${this.state.attributeCallStatus}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((Attribute) => {
          // if (response.ok) {
          if (this.state.attributeCallStatus === "DELETE") {
            this.props.dispatch(
              removedComputedAttributeDeviceWiseSelectedList(obj)
            );
            this.props.dispatch(removedComputedAttribute(obj));
            this.props.dispatch(
              notifySuccess(this.props.translate("computedAttributeIsDeleted"))
            );
            this.setState({
              onDeleteConfirmationAttributes: false,
            });
          } else {
            // response.json().then(Attribute => {
            this.setState({
              visableAttribute: false,
            });
            if (this.state.attributeCallStatus === "PUT") {
              this.props.dispatch(
                updateComputedAttributeDeviceWiseSelectedList(Attribute)
              );
              this.props.dispatch(addComputedAttribute(Attribute));
              this.props.dispatch(
                notifySuccess(
                  this.props.translate("computedAttributeIsUpdated")
                )
              );
            } else {
              this.computedAttributes_Selection(true, Attribute);
              this.props.dispatch(
                addComputedAttributeDeviceWiseSelectedList(Attribute)
              );
              this.props.dispatch(addComputedAttribute(Attribute));
              this.props.dispatch(
                notifySuccess(
                  this.props.translate("computedAttributeIsCreated")
                )
              );
              this.setState({
                visableListAttribute: false,
              });
            }
            // })
          }
          // }
          // else{
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  }

  addSavedCommands() {
    let { attributes, id, description, type, textChannel } =
      this.state.savedCommandsForm;
    attributes = attributes || {};
    id = id || -1;
    let obj = { attributes, id, description, type, textChannel };
    obj.deviceId = 0;
    let params = "";
    if (
      this.state.commandsCallStatus === "PUT" ||
      this.state.commandsCallStatus === "DELETE"
    ) {
      params = obj.id;
    }

    if (obj) {
      // fetch(`/api/commands/${params}`, {
      //   method: `${this.state.commandsCallStatus}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/commands/${params}`,
        method: `${this.state.commandsCallStatus}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((commands) => {
          // if (response.ok) {
          if (this.state.commandsCallStatus === "DELETE") {
            this.props.dispatch(removedCommandsDeviceWiseSelectedList(obj));
            this.props.dispatch(removedCommands(obj));
            this.props.dispatch(
              notifySuccess(this.props.translate("savedCommandsIsDeleted"))
            );
            this.setState({
              onDeleteConfirmationCommand: false,
            });
          } else {
            // response.json().then(commands => {
            this.setState({
              visableSavedCommands: false,
            });
            if (this.state.commandsCallStatus === "PUT") {
              this.props.dispatch(
                updateCommandsDeviceWiseSelectedList(commands)
              );
              this.props.dispatch(addCommands(commands));
              this.props.dispatch(
                notifySuccess(this.props.translate("savedCommandsIsUpdated"))
              );
            } else {
              this.savedCommands_Selection(true, commands);
              this.props.dispatch(addCommandsDeviceWiseSelectedList(commands));
              this.props.dispatch(addCommands(commands));
              this.props.dispatch(
                notifySuccess(this.props.translate("savedCommandsIsCreated"))
              );

              this.setState({ visableListSavedCommands: false });
            }
            // })
          }
          // }
          // else{
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  }

  addMaintenance() {
    let obj = this.state.maintenanceForm;
    if (this.state.maintenanceForm.type === "totalDistance") {
      obj.start = parseFloat(this.state.maintenanceForm.start * 1000);
      obj.period = parseFloat(this.state.maintenanceForm.period * 1000);
    } else if (this.state.maintenanceForm.type === "hours") {
      obj.start = parseFloat(this.state.maintenanceForm.start * (1000 * 3600));
      obj.period = parseFloat(
        this.state.maintenanceForm.period * (1000 * 3600)
      );
    } else if (this.state.maintenanceForm.type === "date") {
      obj.start = 1;
    }

    let params = "";
    if (
      this.state.maintenanceCallStatus === "PUT" ||
      this.state.maintenanceCallStatus === "DELETE"
    ) {
      params = obj.id;
    }
    if (obj) {
      let c = false;
      let p = 0;
      if (obj.check !== undefined) {
        c = obj.check;
        p = obj.progress;
        delete obj.check;
      }
      // fetch(`/api/maintenance/${params}`, {
      //   method: `${this.state.maintenanceCallStatus}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/maintenance/${params}`,
        method: `${this.state.maintenanceCallStatus}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((Maintenance) => {
          // if (response.ok) {
          if (this.state.maintenanceCallStatus === "DELETE") {
            this.props.dispatch(removedMaintenanceDeviceWiseSelectedList(obj));
            this.props.dispatch(removedMaintenance(obj));
            this.props.dispatch(
              notifySuccess(this.props.translate("maintenanceIsDeleted"))
            );

            this.setState({
              onDeleteConfirmationMaintenance: false,
              attributeChangesMessage: "",
            });
          } else {
            // response.json().then(Maintenance => {
            if (this.state.maintenanceCallStatus === "PUT") {
              this.props.dispatch(
                updateMaintenanceDeviceWiseSelectedList({
                  ...Maintenance,
                  check: c,
                  progress: p,
                })
              );
              this.props.dispatch(addMaintenance({ ...Maintenance, check: c }));

              this.props.dispatch(
                notifySuccess(this.props.translate("maintenanceIsUpdated"))
              );
              this.setState({
                attributeChangesMessage: "",
              });
            } else {
              this.maintenance_Selection(true, Maintenance);
              this.props.dispatch(
                addMaintenanceDeviceWiseSelectedList(Maintenance)
              );
              this.props.dispatch(addMaintenance(Maintenance));
              this.props.dispatch(
                notifySuccess(this.props.translate("MaintenanceIsCreated"))
              );
              this.setState({
                attributeChangesMessage: "",
                visableListMaintenance: false,
              });
            }

            this.setState({
              visableMaintenance: false,
              attributeChangesMessage: "",
            });
            // })
          }
          // }
          // else{
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  }

  addUser() {
    let obj = this.state.userForm;
    let params = "";
    if (
      this.state.userCallStatus === "PUT" ||
      this.state.userCallStatus === "DELETE"
    ) {
      params = obj.id;
    }
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check;
      }
      // fetch(`/api/users/${params}`, {
      //   method: `${this.state.userCallStatus}`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   },
      //   body: JSON.stringify({
      //     ...obj
      //   })
      // })
      instance({
        url: `/api/users/${params}`,
        method: `${this.state.userCallStatus}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((User) => {
          // if (response.ok) {
          if (this.state.userCallStatus === "DELETE") {
            this.props.dispatch(removedUserDeviceWiseSelectedList(obj));
            this.props.dispatch(removeUser(obj));
            this.props.dispatch(
              notifySuccess(this.props.translate("userIsRemoved"))
            );

            this.setState({
              onDeleteConfirmationUser: false,
              attributeChangesMessage: "",
            });
          } else {
            // response.json().then(User => {
            if (this.state.maintenanceCallStatus === "PUT") {
              this.props.dispatch(updateUserDeviceWiseSelectedList(User));
              this.props.dispatch(addUser(User));
              this.props.dispatch(
                notifySuccess(this.props.translate("maintenanceIsUpdated"))
              );
              this.setState({
                attributeChangesMessage: "",
              });
            } else {
              this.maintenance_Selection(true, User);
              this.props.dispatch(addUserDeviceWiseSelectedList(User));
              this.props.dispatch(addUser(User));
              this.props.dispatch(
                notifySuccess(this.props.translate("UserIsCreated"))
              );
              this.setState({
                attributeChangesMessage: "",
                visableListUser: false,
              });
            }

            this.setState({
              visableUser: false,
              attributeChangesMessage: "",
            });
            // })
          }
          // }
          // else{
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  }

  // 6th
  editNotification(item) {
    this.props.history.push(`/notificationUpdate/${item[0].id}`);
    let a =
      item && item[0] && item[0].notificators
        ? item[0].notificators.split(",")
        : [];
    let notificators = a.length
      ? a.map((e) => ({ label: this.props.translate(e), value: e }))
      : [];
    this.setState({
      isAddVisableBtn: false,
      selecteItem: item[0],
      notificationCallStatus: "PUT",
      visableNotification: true,
      notificationForm: { ...item[0], notificators },
      addButtonStatus: "sharedUpdate",
      defaultAttributes: JSON.parse(JSON.stringify(item[0].attributes)),
      attributeChangesMessage: "",
      itemAttributes: item[0].attributes,
      isHaveAccess: "notificationUpdate",
    });
  }

  editComputedAttributes(item) {
    this.setState({
      isAddVisableBtn: false,
      selecteItem: item[0],
      attributeCallStatus: "PUT",
      visableAttribute: true,
      computedAttForm: {
        ...item[0],
        expression: parse(item[0].expression)[0],
      },
      addButtonStatus: "sharedUpdate",
      isHaveAccess: "attributeUpdate",
    });
  }

  editCommands(item) {
    let commands = JSON.parse(JSON.stringify(item[0]));
    this.setState({
      isAddVisableBtn: false,
      selecteItem: commands,
      commandsCallStatus: "PUT",
      visableSavedCommands: true,
      savedCommandsForm: {
        ...commands,
        textChannel: commands.textChannel === "Yes" ? true : false,
      },
      addButtonStatus: "sharedUpdate",
      isHaveAccess: "commandUpdate",
    });
  }

  editMaintenance(item) {
    this.props.history.push(`/maintenanceUpdate/${item[0].id}`);

    // let obj = JSON.parse(JSON.stringify(item[0]))
    // if (obj.type === 'totalDistance') {
    //   obj.start = parseFloat(obj.start / 1000)
    //   obj.period = parseFloat(obj.period / 1000)
    // } else if (obj.type === 'hours') {
    //   obj.start = parseFloat(obj.start / (1000 * 3600))
    //   obj.period = parseFloat(obj.period / (1000 * 3600))
    // } else if (obj.type === 'date') {
    //   obj.start = 1
    // }
    // delete obj.progress
    // this.setState({
    //   isAddVisableBtn: false,
    //   selecteItem: obj,
    //   maintenanceCallStatus: 'PUT',
    //   visableMaintenance: true,
    //   maintenanceForm: { ...obj },
    //   addButtonStatus: 'sharedUpdate',
    //   attributeChangesMessage: '',
    //   defaultAttributes: JSON.parse(JSON.stringify(obj.attributes)),
    //   itemAttributes: obj.attributes,
    //   isHaveAccess: 'maintenanceUpdate'
    // })
  }

  addMaintenanceAttributes() {
    this.setState({
      isAddAttributes: true,
      componentType: "Maintenance",
      itemAttributes: this.state.maintenanceForm.attributes,
    });
  }

  notification_handleChange2 = (value, option) => {
    if (option && option.name === "calendarId") {
      this.setState(
        {
          notificationForm: {
            ...this.state.notificationForm,
            [option.name]: value.value,
          },
        },
        () => {
          this.checkRequiredFields("notification");
        }
      );
    } else if (typeof option === "object" && Object.keys(option).length) {
      // for select
      this.setState(
        {
          notificationForm: {
            ...this.state.notificationForm,
            [option.name]: value,
          },
        },
        () => {
          this.checkRequiredFields("notification");
        }
      );
    } else if (value === "type") {
      this.setState(
        {
          notificationForm: {
            ...this.state.notificationForm,
            [value]: option,
          },
        },
        () => this.checkRequiredFields("notification")
      );
    } else {
      this.setState(
        {
          notificationForm: {
            ...this.state.notificationForm,
            attributes: {
              ...this.state.notificationForm.attributes,
              [value]: option,
            },
          },
        },
        () => this.checkRequiredFields("notification")
      );
    }
  };
  // direct arrow function
  notification_handleChange = (name) => (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        notificationForm: {
          ...this.state.notificationForm,
          [name]: value,
        },
      },
      () => this.checkRequiredFields("notification")
    );
  };

  geofence_handleChange = (name) => (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        geofenceForm: {
          ...this.state.geofenceForm,
          [name]: value,
        },
      },
      () => this.checkRequiredFields("geofence")
    );
  };

  driver_handleChange = (name) => (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        driverForm: {
          ...this.state.driverForm,
          [name]: value,
        },
      },
      () => this.checkRequiredFields("driver")
    );
  };

  C_Att_handleChange = (name) => (event) => {
    const { target } = event;
    this.setState(
      {
        computedAttForm: {
          ...this.state.computedAttForm,
          [name]: target.value,
        },
      },
      () => this.checkRequiredFields("computedAtt")
    );
  };

  savedCommands_handleChange = (name) => (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    if (name === "attributes") {
      let att = this.state.savedCommandsForm.attributes;
      let newAtt = { [target.name]: target.value };
      if (!target.value) {
        delete newAtt[name];
      }
      att = { ...att, ...newAtt };
      value = att;
      this.setState(
        {
          savedCommandsForm: {
            ...this.state.savedCommandsForm,
            attributes: {
              ...this.state.savedCommandsForm.attributes,
              [target.name]:
                target.type === "checkbox" ? target.checked : target.value,
            },
          },
        },
        () => this.checkRequiredFields("Commands")
      );
    }
    this.setState(
      {
        savedCommandsForm: {
          ...this.state.savedCommandsForm,
          [name]: value,
        },
      },
      () => this.checkRequiredFields("Commands")
    );
  };

  maintenance_handleChange = (name) => (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        maintenanceForm: {
          ...this.state.maintenanceForm,
          [name]: value,
        },
      },
      () => this.checkRequiredFields("maintenance")
    );
  };

  maintenance_handleChange_attr = (name) => (date) => {
    const value =
      name === "repeat" || name === "status" ? date.target.checked : date;
    this.setState(
      {
        maintenanceForm: {
          ...this.state.maintenanceForm,
          attributes: {
            ...this.state.maintenanceForm.attributes,
            [name]: value,
          },
        },
      },
      () => this.checkRequiredFields("maintenance")
    );
  };

  sendCommandsModal = () => {
    //fetch command list data
    let Data1 = [],
      Data2 = [],
      isRecived1 = false,
      isRecived2 = false;
    if (this.state.currentId) {
      // fetch(`/api/commands/send?deviceId=${this.state.currentId}`, {
      //   method: `GET`,
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   }
      // })
      instance({
        url: `/api/commands/send`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          deviceId: this.state.currentId,
        },
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
        .then((commands) => {
          if (commands.length) {
            commands.map((item) => {
              Data1.push({ ...item, key: item.id, name: item.description });
              return null;
            });
          }
          isRecived1 = true;
          this.setState(
            {
              descriptionList: Data1,
            },
            () => {
              if (isRecived2) {
                this.setState({
                  isSendCommandModal: true,
                  sendCommandsForm: { ...savedCommandsDataFormat },
                });
              }
            }
          );
        })
        // }
        // else{
        //   throw response
        // }
        // })
        .catch((error) => {
          // errorHandler(error,this.props.dispatch)
        });
      // fetch(
      //   `/api/commands/types?deviceId=${this.state.currentId}&textChannel=false`,
      //   {
      //     method: `GET`,
      //     headers: {
      //       Accept: 'application/json',
      //       'Content-Type': 'application/json'
      //     }
      //   }
      // )
      instance({
        url: `/api/commands/types`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          deviceId: this.state.currentId,
          textChannel: false,
        },
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
        .then((commands) => {
          if (commands.length) {
            commands.map((item) => {
              Data2.push({ key: item.type, name: item.type });
              return null;
            });
          }
          isRecived2 = true;
          this.setState(
            {
              typeList: Data2,
            },
            () => {
              if (isRecived1) {
                this.setState({
                  isSendCommandModal: true,
                  sendCommandsForm: { ...savedCommandsDataFormat },
                });
              }
            }
          );
        })
        //   }
        //   else{
        //     throw response
        //   }
        // })
        .catch((error) => {
          // errorHandler(error,this.props.dispatch)
        });
    } else {
      this.props.dispatch(
        notifySuccess(this.props.translate("componentIsLoadingTryAgain"))
      );
    }
  };

  sendCommands_handleChange = (name, event) => {
    if (name === "description") {
      const { attributes, ...others } = event;
      this.setState(
        {
          sendCommandsForm: {
            ...this.state.sendCommandsForm,
            attributes: {
              ...this.state.sendCommandsForm.attributes,
              data: attributes ? attributes.data : "",
            },
            [name]: others,
          },
        },
        () => this.checkSendCommandRequiredFields()
      );
    } else {
      const { target } = event;
      let value = target.type === "checkbox" ? target.checked : target.value;
      if (name === "attributes") {
        let { attributes } = { ...this.state.sendCommandsForm };
        attributes = { ...attributes, [target.name]: value };
        if (!value) {
          delete attributes[target.name];
        }
        this.setState(
          {
            sendCommandsForm: {
              ...this.state.sendCommandsForm,
              attributes,
            },
          },
          () => this.checkSendCommandRequiredFields()
        );
      } else {
        this.setState(
          {
            sendCommandsForm: {
              ...this.state.sendCommandsForm,
              [name]: value,
            },
          },
          () => {
            this.checkSendCommandRequiredFields();
            if (name === "textChannel") {
              this.loadItemOfCommandType(value);
            }
          }
        );
      }
    }
  };

  checkSendCommandRequiredFields = () => {
    let { description, type, attributes } = this.state.sendCommandsForm;
    if (description && type) {
      if (
        type === "custom" ||
        type === "silenceTime" ||
        type === "setPhonebook" ||
        type === "voiceMessage" ||
        type === "outputControl" ||
        type === "setIndicator" ||
        type === "configuration" ||
        type === "setOdometer" ||
        type === "alarmClock" ||
        type === "alarmSpeed" ||
        type === "alarmVibration" ||
        type === "timezone" ||
        type === "sendSms" ||
        type === "sendUssd" ||
        type === "sosNumber" ||
        type === "message" ||
        type === "alarmFall" ||
        type === "alarmRemove" ||
        type === "alarmBattery" ||
        type === "modeDeepSleep" ||
        type === "modePowerSaving" ||
        type === "setAgps" ||
        type === "voiceMonitoring" ||
        type === "novementAlarm"
      ) {
        if (attributes && Object.keys(attributes).length > 0) {
          this.setState({
            sendCommandBtnState: true,
          });
        } else {
          this.setState({
            sendCommandBtnState: false,
          });
        }
      } else {
        this.setState({
          sendCommandBtnState: true,
        });
      }
    } else {
      this.setState({
        sendCommandBtnState: false,
      });
    }
  };

  loadItemOfCommandType = (status) => {
    let Data = [];
    // fetch(
    //   `/api/commands/types?deviceId=${this.state.currentId}&textChannel=${status}`,
    //   {
    //     method: `GET`,
    //     headers: {
    //       Accept: 'application/json',
    //       'Content-Type': 'application/json'
    //     }
    //   }
    // )
    instance({
      url: `/api/commands/types`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params: {
        deviceId: this.state.currentId,
        textChannel: status,
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((commands) => {
        if (commands.length) {
          commands.map((item) => {
            Data.push({ key: item.type, name: item.type });
            return null;
          });
        }
        this.setState({
          typeList: Data,
        });
      })
      //   }
      //   else{
      //     throw response
      //   }
      // })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
  };

  sendCommandSubmit = () => {
    let { sendCommandsForm, currentId } = this.state;
    let obj = {
      id: 0,
      deviceId: currentId,
      description: sendCommandsForm.description.key,
      type: sendCommandsForm.type,
      attributes: { ...sendCommandsForm.attributes },
    };
    // fetch(`/api/commands/send`, {
    //   method: `POST`,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/commands/send`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        this.onCloseModal();
        if (response.status === 200) {
          this.props.dispatch(
            notifySuccess(this.props.translate("commandSent"))
          );
        } else if (response.status === 202) {
          this.props.dispatch(
            notifySuccess(this.props.translate("commandQueued"))
          );
        } else {
          this.props.dispatch(notifySuccess(this.props.translate("error")));
        }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };
  // remove

  removeNotification() {
    this.setState(
      {
        notificationCallStatus: "DELETE",
      },
      () => this.addNotification()
    );
  }

  removeMaintenance() {
    this.setState(
      {
        maintenanceCallStatus: "DELETE",
      },
      () => this.addMaintenance()
    );
  }

  removeUser() {
    this.setState(
      {
        userCallStatus: "DELETE",
      },
      () => this.addUser()
    );
  }

  removeComputedAttributes() {
    this.setState(
      {
        attributeCallStatus: "DELETE",
      },
      () => this.addComputedAttributes()
    );
  }

  onRemovedNotification = (item) => {
    this.setState({
      notificationForm: { ...item[0] },
      onDeleteConfirmationNotification: true,
    });
  };

  onRemovedGeofence = (item) => {
    this.setState({
      geofenceForm: { ...item[0] },
      onDeleteConfirmationGeofence: true,
    });
  };

  onRemovedDriver = (item) => {
    this.setState({
      driverForm: { ...item[0] },
      onDeleteConfirmationDriver: true,
    });
  };

  onRemovedAttribute = (item) => {
    this.setState({
      computedAttForm: { ...item[0] },
      onDeleteConfirmationAttributes: true,
    });
  };

  onRemovedCommand = (item) => {
    this.setState({
      savedCommandsForm: { ...item[0] },
      onDeleteConfirmationCommand: true,
    });
  };

  onRemovedMaintenance = (item) => {
    this.setState({
      maintenanceForm: { ...item[0] },
      onDeleteConfirmationMaintenance: true,
    });
  };

  onRemovedUser = (item) => {
    this.setState({
      userForm: { ...item[0] },
      onDeleteConfirmationUser: true,
    });
  };

  onRemovedDevice = (e, item) => {
    e.preventDefault();
    this.setState({
      slectedUnits: item,
      onDeleteConfirmationUnits: true,
    });
    return false;
  };

  removeCommands() {
    this.setState(
      {
        commandsCallStatus: "DELETE",
      },
      () => this.addSavedCommands()
    );
  }

  removeDevice() {
    let { slectedUnits } = this.state;
    // fetch(`/api/devices/${slectedUnits.id}`, {
    //   method: 'DELETE',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
    // })
    instance({
      url: `/api/devices/${slectedUnits.id}`,
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        this.props.dispatch(removeDevice(slectedUnits.id));
        this.props.history.push("/units");
        this.setState({
          onDeleteConfirmationUnits: false,
        });
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }

  onCancel = () => {
    this.setState({
      onDeleteConfirmationGeofence: false,
      onDeleteConfirmationNotification: false,
      onDeleteConfirmationAttributes: false,
      onDeleteConfirmationMaintenance: false,
      onDeleteConfirmationUser: false,
      onDeleteConfirmationCommand: false,
      onDeleteConfirmationUnits: false,
    });
  };

  // comman for all
  onCloseC_Att_Modal() {
    this.setState({
      visableAttribute: false,
      visableNotification: false,
      visableGeofence: false,
      visableMaintenance: false,
      visableSavedCommands: false,
      attributeChangesMessage: "",
      defaultAttributes: {},
    });
  }

  // check for required field
  checkRequiredFields(computedAtt) {
    // changed attribute work
    const {
      defaultAttributes,
      selecteItem,
      maintenanceForm,
      computedAttForm,
      savedCommandsForm,
      notificationForm,
    } = this.state;
    if (computedAtt === "computedAtt") {
      let changed_comAt = !isEqual(selecteItem, computedAttForm);
      let { attribute, expression } = this.state.computedAttForm;
      if (
        attribute &&
        expression &&
        attribute.trim().length > 0 &&
        expression.trim().length > 0 &&
        changed_comAt
      ) {
        this.setState({
          isAddVisableBtn: true,
        });
      } else {
        this.setState({
          isAddVisableBtn: false,
        });
      }
    } else if (computedAtt === "Commands") {
      let changed_comm = !isEqual(selecteItem, savedCommandsForm);
      let { type, description } = this.state.savedCommandsForm;
      if (type && description && changed_comm) {
        this.setState({
          isAddVisableBtn: true,
        });
      } else {
        this.setState({
          isAddVisableBtn: false,
        });
      }
    } else if (computedAtt === "notification") {
      // attChangedValue is for attributes changing value
      let attChangedValue = !isEqual(
        defaultAttributes,
        notificationForm.attributes
      );
      if (attChangedValue) {
        this.setState({
          attributeChangesMessage: "Change in attributes is pending",
        });
      } else {
        this.setState({
          attributeChangesMessage: "",
        });
      }
      let changed_noti = !isEqual(selecteItem, notificationForm);
      let { type, notificators } = this.state.notificationForm;
      if (type && notificators && changed_noti) {
        this.setState({
          isAddVisableBtn: true,
        });
      } else {
        this.setState({
          isAddVisableBtn: false,
        });
      }
    } else if (computedAtt === "maintenance") {
      let Attvalue = !isEqual(defaultAttributes, maintenanceForm.attributes);
      if (Attvalue) {
        this.setState({
          attributeChangesMessage: "Change in attributes is pending",
        });
      } else {
        this.setState({
          attributeChangesMessage: "",
        });
      }
      let changed_main = !isEqual(selecteItem, maintenanceForm);
      let { type, start, period, attributes } = this.state.maintenanceForm;
      if (type && period && (start || attributes.startDate) && changed_main) {
        this.setState({
          isAddVisableBtn: true,
        });
      } else {
        this.setState({
          isAddVisableBtn: false,
        });
      }
    }
  }

  isButtonVisable(target) {
    if (target === "parkingAttForm") {
      const { attributes } = this.state.resFormData;
      let form = { ...this.state.unitParkingAttributesform };

      form["speedThreshold"] = checkSpeedThreshold(form.speedThreshold, true);

      if (
        form.minimalFuelFillingVolume < 1 ||
        form.minimalFuelDrainVolume < 1 ||
        isNaN(form.minimalFuelFillingVolume) ||
        isNaN(form.minimalFuelDrainVolume)
      ) {
        this.setState({
          isVisableParkingAttBtn: false,
          errorInFuelFilling:
            form.minimalFuelFillingVolume < 1 ||
            isNaN(form.minimalFuelFillingVolume)
              ? true
              : false,
          errorInFuelDrain:
            form.minimalFuelDrainVolume < 1 ||
            isNaN(form.minimalFuelDrainVolume)
              ? true
              : false,
        });
      } else {
        this.setState({
          isVisableParkingAttBtn: !isEqual(attributes, form),
          errorInFuelDrain: false,
          errorInFuelFilling: false,
        });
      }
    }
    if (target === "aFormOne") {
      const { category } = this.state.resFormData;
      this.setState({
        isVisableASOneBtn: !isEqual(category, this.state.selecteditem.category),
      });
    }
  }

  handleChangeForParkingAtt = (name) => (event) => {
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    if (event.target.type !== "checkbox") {
      value = parseFloat(value);
    }
    if (
      name === "consecutiveFuelFillingTimeout" ||
      name === "consecutiveFuelDrainTimeout"
    ) {
      this.setState(
        {
          unitParkingAttributesform: {
            ...this.state.unitParkingAttributesform,
            [name]: parseInt(value),
          },
        },
        () => {
          this.isButtonVisable("parkingAttForm");
        }
      );
    } else {
      this.setState(
        {
          unitParkingAttributesform: {
            ...this.state.unitParkingAttributesform,
            [name]: value,
          },
        },
        () => {
          this.isButtonVisable("parkingAttForm");
        }
      );
    }
  };
  handleChangeForLanguageAtt = (name, event) => {
    this.setState(
      {
        unitParkingAttributesform: {
          ...this.state.unitParkingAttributesform,
          [name]: event.value,
        },
      },
      () => {
        this.isButtonVisable("parkingAttForm");
      }
    );
  };

  parkingformSubmit() {
    let { selecteditem, unitParkingAttributesform } = this.state;
    if (selecteditem) {
      let attributes = selecteditem.attributes;
      attributes = {
        ...unitParkingAttributesform,
        minimalTripDuration:
          this.state.unitParkingAttributesform.minimalTripDuration,
        speedThreshold: checkSpeedThreshold(
          this.state.unitParkingAttributesform.speedThreshold,
          true
        ),
        minimalTripDistance: checkMinimalTripDistance(
          this.state.unitParkingAttributesform.minimalTripDistance,
          true
        ),
      };
      selecteditem.attributes = attributes;
      this.setState(
        {
          selecteditem,
        },
        () => this.formSubmit()
      );
    }
  }

  formSubmit() {
    let data = this.state.selecteditem;
    let check = data.check;
    delete data.visible;
    delete data.check;
    delete data.indeterminate;
    delete data.parent;
    // fetch(`/api/devices/${parseInt(this.props.id)}`, {
    //   method: 'PUT',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...data
    //   })
    // })
    instance({
      url: `/api/devices/${parseInt(this.props.id)}`,
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...data,
      },
    })
      // .then(response => {
      //   if (response.status === 200) {
      //     response.json()
      .then((device) => {
        this.props.dispatch(updateDevice({ ...device, check }));
        //this.props.enqueueSnackbar(this.props.translate('trackersIsUpdated'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
        this.props.dispatch(
          notifySuccess(this.props.translate("trackersIsUpdated"))
        );
        this.setState({
          isVisableParkingAttBtn: false,
          isVisableASOneBtn: false,
        });
      })
      //   } else {
      //     throw response
      //   }
      // })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
    // .catch(e => {
    //   console.log("catch ==",e)
    //   // if (e && e.text) {
    //   //   e.text().then(err => {
    //       //this.props.enqueueSnackbar(this.props.translate('somthingWentWrongMessage'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
    //       this.props.dispatch(
    //         notifyError({
    //           message: this.props.translate('somthingWentWrongMessage'),
    //           autoDismiss: 10
    //         })
    //       )
    //   //   })
    //   // }
    // })
  }

  updateSelectedDrivers = () => {
    let driver = this.state.mulitDriversSelect;
    let allData = this.props.drivers;
    if (driver.length) {
      allData.map((item) => {
        item.check = false;
        driver.map((main) => {
          if (item.id && main.id && item.id === main.id) {
            item.check = true;
            main.check = true;
          }
          return null;
        });
        return null;
      });
    } else {
      allData.map((n) => (n.check = false));
    }
    this.props.dispatch(driverDeviceWiseSelectedList(allData));
  };

  getCurrent(id) {
    if (id) {
      let d = this.props.devices3.find(
        (device) => parseInt(device.id) === parseInt(id)
      );

      if (d && d.id) {
        this.setState(
          {
            selecteditem: {
              ...d,
              attributes: {
                ...d.attributes,
                storeTime: d.attributes.storeTime || 10,
              },
            },
            isVisableParkingAttBtn: false,
          },
          () => {
            //for teltonika protocol device Modal

            if (this.state.selecteditem) {
              // const deviceModal =unitsList.find(i =>i.protocol ==='teltonika' && this.state.selecteditem.model === i.device)
              this.setState({
                deviceModal:
                  this.state.selecteditem.attributes &&
                  this.state.selecteditem.attributes.protocol === "teltonika"
                    ? true
                    : false,
              });
            }
          }
        );
      }
      // for driver
      /* if (checkPrivileges('driver')) {
        // fetch(`/api/drivers?deviceId=${id}&all=true`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
    instance({
      url: `/api/drivers`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      params:{
        deviceId: id,
        all: true
      }
    })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(driver => {
              this.setState(
                {
                  mulitDriversSelect: driver,
                  isDriverRecived: true
                },
                () => {
                  this.updateSelectedDrivers()
                }
              )
            })
        //   }
        // })
      } */
      // for users
      if (checkPrivileges("user")) {
        // fetch(`/api/users/access?deviceId=${id}&all=true`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/users/access`,
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            deviceId: id,
            all: true,
          },
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((users) => {
            this.setState({
              mulitUsersSelect: users,
              isUsersRecived: true,
            });
            let allData = this.props.users;
            if (users.length) {
              allData.map((item) => {
                item.check = false;
                item.indeterminate = false;
                item.readonly = false;

                users.map((main) => {
                  if (item.id && main.userId && item.id === main.userId) {
                    // for parent
                    if (main.parent) {
                      item.check = true;
                      main.check = true;

                      item.readonly = true;
                      main.readonly = true;
                    } else {
                      if (main.directAccess) {
                        item.readonly = false;
                        main.readonly = false;
                        item.check = true;
                        main.check = true;
                      } else {
                        item.readonly = false;
                        main.readonly = false;
                        item.check = false;
                        main.check = false;
                        item.indeterminate = true;
                      }
                    }
                  }
                  return null;
                });
                return null;
              });
            } else {
              allData.map((n) => (n.check = false));
            }
            this.props.dispatch(userDeviceWiseSelectedList(allData));
          })
          //   }
          //   else{
          //     throw response
          //   }
          // })
          .catch((error) => {
            // errorHandler(error,this.props.dispatch)
          });
      }

      // for geofences
      /* if (checkPrivileges('geofence')) {
        // fetch(`/api/geofences?deviceId=${id}&all=true`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/geofences`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          },
          params:{
            deviceId: id,
            all: true
          }
        })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
            .then(geofences => {
              this.setState({
                multiGeofencesSelect: geofences,
                isGroRecived: true
              })

              let allData = this.props.geofences
              if (geofences.length) {
                allData.map(item => {
                  item.check = false
                  geofences.map(main => {
                    if (item.id && main.id && item.id === main.id) {
                      item.check = true
                      main.check = true
                    }
                    return null
                  })
                  return null
                })
              } else {
                allData.map(n => (n.check = false))
              }
              this.props.dispatch(getGeofenceDeviceWise(geofences))
              this.props.dispatch(geofenceDeviceWiseSelectedList(allData))
            })
        //   }
        // })
      } */
      // for Notification
      if (checkPrivileges("notification")) {
        // fetch(`/api/notifications?deviceId=${id}&all=true`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })

        instance({
          method: "GET",
          url: `/api/notifications/get?limit=${-1}`,
        })
          .then((response) => {
            // if(response && response.status === 200) {
            this.props.dispatch(getNotification(response.data));
            // }
            instance({
              url: `/api/notifications`,
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              params: {
                deviceId: id,
                all: true,
              },
            })
              // .then(response => {
              //   if (response.ok) {
              //     response.json()
              .then((notifications) => {
                let allData = this.props.notification;
                if (notifications.length) {
                  allData.map((item) => {
                    item.check = false;
                    notifications.map((main) => {
                      if (item.id && main.id && item.id === main.id) {
                        item.check = true;
                        main.check = true;
                      }
                      return null;
                    });
                    return null;
                  });
                } else {
                  allData.map((n) => (n.check = false));
                }
                this.props.dispatch(getNotificationDeviceWise(notifications));
                this.props.dispatch(
                  notificationDeviceWiseSelectedList(allData)
                );
              })
              //   }
              //   else{
              //     throw response
              //   }
              // })
              .catch((error) => {
                // errorHandler(error,this.props.dispatch)
              });
          })
          .catch((error) => {
            // errorHandler(error, this.props.dispatch)
          });
      }

      // for ComputedAttributes
      if (
        checkPrivileges("attribute") &&
        this.props.logInUser &&
        this.props.logInUser.id
      ) {
        // fetch(`/api/attributes/computed?deviceId=${id}&category=all&all=true`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })

        instance({
          method: "GET",
          url: `/api/attributes/computed/get?userId=${this.props.logInUser.id}&category=elogic&all=true&page=1&limit=-1`,
        })
          .then((response) => {
            // if(response && response.status === 200) {
            this.props.dispatch(getComputedAttributes(response.data));
            // }
            instance({
              url: `/api/attributes/computed`,
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              params: {
                deviceId: id,
                category: "all",
                all: true,
              },
            })
              // .then(response => {
              //   if (response.ok) {
              //     response.json()
              .then((attributes) => {
                let allData = this.props.computedAttributes;
                if (attributes.length) {
                  allData.map((item) => {
                    item.check = false;
                    attributes.map((main) => {
                      if (item.id === main.id) {
                        item.check = true;
                        main.check = true;
                      }
                      return null;
                    });
                    return null;
                  });
                } else {
                  allData.map((n) => (n.check = false));
                }
                this.props.dispatch(getComputedAttributeDeviceWise(attributes));
                this.props.dispatch(
                  computedAttributeDeviceWiseSelectedList(allData)
                );
                this.setState({
                  isComputedAttributesRecived: true,
                });
              })
              //   }
              //   else{
              //     throw response
              //   }
              // })
              .catch((error) => {
                // errorHandler(error,this.props.dispatch)
              });
          })
          .catch((error) => {
            // errorHandler(error, this.props.dispatch)
          });
      }
      // for SavedCommands
      if (
        checkPrivileges("command") &&
        this.props.logInUser &&
        this.props.logInUser.id
      ) {
        // fetch(`/api/commands?deviceId=${id}&all=true`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })

        instance({
          method: "GET",
          url: `/api/commands/get?userId=${
            this.props.logInUser.id
          }&limit=${-1}&all=${true}`,
        })
          .then((response) => {
            // if(response && response.status === 200) {
            this.props.dispatch(getCommands(response.data));
            // }
            instance({
              url: `/api/commands`,
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              params: {
                deviceId: id,
                all: true,
              },
            })
              // .then(response => {
              //   if (response.ok) {
              //     response.json()
              .then((commands) => {
                let data = commands;
                data.map((item) => {
                  item.textChannel = item.textChannel ? "Yes" : "No";
                  return null;
                });
                let allData = this.props.commands;
                if (commands.length) {
                  allData.map((item) => {
                    item.check = false;
                    commands.map((main) => {
                      if (item.id === main.id) {
                        item.check = true;
                        main.check = true;
                      }
                      return null;
                    });
                    return null;
                  });
                } else {
                  allData.map((n) => (n.check = false));
                }

                this.props.dispatch(getCommandsDeviceWise(data));
                this.props.dispatch(commandsDeviceWiseSelectedList(allData));
              })
              //   }
              //   else{
              //     throw response
              //   }
              // })
              .catch((error) => {
                // errorHandler(error,this.props.dispatch)
              });
          })
          .catch((error) => {
            // errorHandler(error, this.props.dispatch)
          });
      }
      // for Maintenance
      if (checkPrivileges("maintenance")) {
        // fetch(`/api/maintenance/get?deviceId=${id}&all=true`, {
        //   method: 'GET',
        //   headers: {
        //     Accept: 'application/json',
        //     'Content-Type': 'application/json'
        //   }
        // })
        instance({
          url: `/api/maintenance/get`,
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            deviceId: id,
            all: true,
          },
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((data) => {
            let allData = this.props.maintenance;
            if (data.data.length) {
              // allData.map(item => {
              //   item.check = false
              //   delete item.progress
              //   let main = data.data.find(main => item.id === main.id)
              //   if (main && main.attributes) {
              data.data.map((item) => {
                item.check = true;
                // main.check = true
                item.progress = {
                  value: item.attributes.progress,
                  bar: true,
                };
              });
              // }
              //   return null
              // })
            }
            // else {
            //   allData.map(n => {
            //     n.check = false
            //     delete n.progress
            //   })
            // }
            this.props.dispatch(getMaintenanceDeviceWise(data.data));
            this.props.dispatch(maintenanceDeviceWiseSelectedList(data.data));
          })
          //   }
          //   else{
          //     throw response
          //   }
          // })
          .catch((error) => {
            // errorHandler(error,this.props.dispatch)
          });
      }

      this.setState({
        gOneform: {},
        aOneform: {},
        resFormData: {},
        mulitDriversSelect: "",
        mulitUsersSelect: "",
        multiGeofencesSelect: "",
        isDriverRecived: false,
        isGroRecived: false,
        unitParkingAttributesform: { ...unitParkingAttFormat },
        isHaveAccess: "attributeCreate",
      });

      if (d && d.id) {
        let { name, uniqueId, groupId, phone, attributes, model } = d;
        if (model && model === "FX Tracker") {
          this.tabChange("", "allAttribute");
        }
        let { category } = d;
        let f_3 = { category };
        let att = [];
        if (attributes) {
          att = Object.keys(attributes).reduce((array, key) => {
            return [...array, [key, attributes[key]]];
          }, []);
        }
        let devicePassword = "";
        let mtime = { minimalTripDuration: 60 },
          mt = { minimalTripDistance: checkMinimalTripDistance(100) },
          st = { speedThreshold: checkSpeedThreshold(1.61987) };
        att.map((item) => {
          if (item[0] === "minimalTripDuration") {
            mtime.minimalTripDuration = Math.round(item[1]);
          }
          if (item[0] === "minimalTripDistance") {
            mt.minimalTripDistance = checkMinimalTripDistance(item[1]);
          }
          if (item[0] === "speedThreshold") {
            st.speedThreshold = checkSpeedThreshold(item[1]);
          }
          return null;
        });
        let f_1 = { name, uniqueId, groupId, phone, devicePassword };
        attributes = { ...unitParkingAttFormat, ...d.attributes };
        this.setState({
          gOneform: { ...f_1 },
          aOneform: { ...f_3 },
          unitParkingAttributesform: {
            ...attributes,
            ...mtime,
            ...st,
            ...mt,
          },
          resFormData: { ...d, attributes },
        });
      }
      return { d };
    }
  }

  updateSelectedItem(selecteditem) {
    this.setState({ selecteditem });
  }

  handleClick = () => {
    this.setState({
      isAdvSettingEnable: !this.state.isAdvSettingEnable,
    });
  };

  getPostion = (address, latlng) => {
    if (address) {
      this.setState({
        address: address,
        lat: latlng.lat,
        lng: latlng.lng,
      });
    }
  };

  selectDriver = (item) => {
    this.setState({
      driverObj: item[0],
      activeOperation: "edit",
      openDriverModal: true,
    });
  };

  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage });
  };

  fetchMoreItems = () => {};

  render() {
    const { currentTab, selecteditem, unitParkingAttributesform } = this.state;
    const { id } = this.props;
    const orientation = window.innerWidth <= 959 ? "horizontal" : "vertical";

    return (
      <Fragment>
        <h4 className="tab-title">
          {selecteditem ? selecteditem.name : "Unit"}
        </h4>
        <div className={" main-unit-modal-bg clearfix"}>
          {this.state.isSendCommandModal && (
            <CustomDialog
              title={this.props.translate("sendCommand")}
              themeColors={this.props.themecolors}
              // disableFooter
              onClose={this.onCloseModal}
              dialogHeight={250}
              draggable
              bodyPadding={0}
            >
              <SendCommand
                id={this.props.id}
                dispatch={this.props.dispatch}
                translate={this.props.translate}
                themecolors={this.props.themecolors}
                descriptionList={this.state.descriptionList}
              />
            </CustomDialog>
          )}
          {this.state.onDeleteConfirmationUnits && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeDevice}
              title={this.props.translate("areYouWantToDelete")}
              children={this.state.slectedUnits.name}
            />
          )}
          {this.state.onDeleteConfirmationNotification && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeNotification}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.translate(
                "notification." + this.state.notificationForm.type
              )}
            />
          )}
          {this.state.onDeleteConfirmationDriver && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeDriver}
              title={this.props.translate("areYouWantToDelete")}
              children={this.state.driverForm.name}
            />
          )}
          {this.state.onDeleteConfirmationGeofence && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeGeofence}
              title={this.props.translate("areYouWantToDelete")}
              children={this.state.geofenceForm.name}
            />
          )}
          {this.state.onDeleteConfirmationAttributes && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeComputedAttributes}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.translate(
                this.state.computedAttForm.description
              )}
            />
          )}
          {this.state.onDeleteConfirmationCommand && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeCommands}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.translate(
                "commands." + this.state.savedCommandsForm.type
              )}
            />
          )}
          {this.state.onDeleteConfirmationMaintenance && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeMaintenance}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.translate(this.state.maintenanceForm.type)}
            />
          )}
          {this.state.onDeleteConfirmationUser && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeUser}
              title={this.props.translate("areYouWantToDelete")}
              children={this.state.userForm.name}
            />
          )}
          {this.state.onDeleteConfirmationUnits && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeDevice}
              title={this.props.translate("areYouWantToDelete")}
              children={this.state.slectedUnits.name}
            />
          )}
          {this.state.onDeleteConfirmationNotification && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeNotification}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.translate(
                "notification." + this.state.notificationForm.type
              )}
            />
          )}
          {this.state.onDeleteConfirmationAttributes && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeComputedAttributes}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.translate(
                this.state.computedAttForm.description
              )}
            />
          )}
          {this.state.onDeleteConfirmationCommand && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeCommands}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.translate(
                "commands." + this.state.savedCommandsForm.type
              )}
            />
          )}
          {this.state.onDeleteConfirmationMaintenance && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removeMaintenance}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.translate(this.state.maintenanceForm.type)}
            />
          )}
          <VerticalTabs
            orientation={orientation}
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={this.tabChange}
            aria-label="Vertical tabs example"
            className="custom-tabs"
          >
            <CTab
              value="allAttribute"
              classes={{
                root: "common-tab",
                selected: "tab-selected",
                wrapper: "tab-wrapper",
              }}
              label={
                <span className="menu_item_text">
                  <ArrowRightIcon className="tab_menu_icon" />
                  <span className="menu_item_text_inner">
                    {this.props.translate("allAttributes")}
                  </span>
                </span>
              }
              {...a11yProps(0)}
            />
            <CTab
              value="basic"
              classes={{
                root: "common-tab",
                selected: "tab-selected",
                wrapper: "tab-wrapper",
              }}
              label={
                <span className="menu_item_text">
                  <ArrowRightIcon className="tab_menu_icon" />
                  <span className="menu_item_text_inner">
                    {this.props.translate("basic")}
                  </span>
                </span>
              }
              {...a11yProps(1)}
            />
            <CTab
              value="icon"
              classes={{
                root: "common-tab",
                selected: "tab-selected",
                wrapper: "tab-wrapper",
              }}
              label={
                <span className="menu_item_text">
                  <ArrowRightIcon className="tab_menu_icon" />
                  <span className="menu_item_text_inner">
                    {this.props.translate("icon")}
                  </span>
                </span>
              }
              {...a11yProps(2)}
            />
            {
              /* selecteditem &&
            selecteditem.model &&
            selecteditem.model === 'FX Tracker' ? null : */ checkPrivileges(
                "sensor"
              ) ? (
                <CTab
                  value="sensors"
                  classes={{
                    root: "common-tab",
                    selected: "tab-selected",
                    wrapper: "tab-wrapper",
                  }}
                  label={
                    <span className="menu_item_text">
                      <ArrowRightIcon className="tab_menu_icon" />
                      <span className="menu_item_text_inner">
                        {this.props.translate("sensors")}
                      </span>
                    </span>
                  }
                  {...a11yProps(3)}
                />
              ) : null
            }
            <CTab
              value="sharedDeviceAccumulators"
              classes={{
                root: "common-tab",
                selected: "tab-selected",
                wrapper: "tab-wrapper",
              }}
              label={
                <span className="menu_item_text">
                  <ArrowRightIcon className="tab_menu_icon" />
                  <span className="menu_item_text_inner">
                    {this.props.translate("sharedDeviceAccumulators")}
                  </span>
                </span>
              }
              {...a11yProps(4)}
            />
            <CTab
              value="Advance"
              classes={{
                root: "common-tab",
                selected: "tab-selected",
                wrapper: "tab-wrapper",
              }}
              label={
                <span className="menu_item_text">
                  <ArrowRightIcon className="tab_menu_icon" />
                  <span className="menu_item_text_inner">
                    {this.props.translate("advance")}
                  </span>
                </span>
              }
              {...a11yProps(5)}
            />
            {/* <CTab
              value='sharedDrivers'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <span className='menu_item_text'>
                <ArrowRightIcon className='tab_menu_icon'/>{' '}
                  {this.props.translate('sharedDrivers')}{' '}
                </span>
              }
              {...a11yProps(6)}
            /> */}
            {checkPrivileges("user") && (
              <CTab
                value="Access"
                classes={{
                  root: "common-tab",
                  selected: "tab-selected",
                  wrapper: "tab-wrapper",
                }}
                label={
                  <span className="menu_item_text">
                    <ArrowRightIcon className="tab_menu_icon" />
                    <span className="menu_item_text_inner">
                      {this.props.translate("access")}
                    </span>
                  </span>
                }
                {...a11yProps(7)}
              />
            )}
            {/* <CTab
              value='sharedGeofences'
              classes={{
                root: 'common-tab',
                selected: 'tab-selected',
                wrapper: 'tab-wrapper'
              }}
              label={
                <span className='menu_item_text'>
                <ArrowRightIcon className='tab_menu_icon'/>{' '}
                  {this.props.translate('sharedGeofences')}{' '}
                </span>
              }
              {...a11yProps(8)}
            /> */}
            {checkPrivileges("notification") && (
              <CTab
                value="sharedNotifications"
                classes={{
                  root: "common-tab",
                  selected: "tab-selected",
                  wrapper: "tab-wrapper",
                }}
                label={
                  <span className="menu_item_text">
                    <ArrowRightIcon className="tab_menu_icon" />
                    <span className="menu_item_text_inner">
                      {this.props.translate("sharedNotifications")}
                    </span>
                  </span>
                }
                {...a11yProps(9)}
              />
            )}
            {checkPrivileges("attribute") && (
              <CTab
                value="sharedComputedAttributes"
                classes={{
                  root: "common-tab",
                  selected: "tab-selected",
                  wrapper: "tab-wrapper",
                }}
                label={
                  <span className="menu_item_text">
                    <ArrowRightIcon className="tab_menu_icon" />
                    <span className="menu_item_text_inner">
                      {this.props.translate("sharedComputedAttributes")}
                    </span>
                  </span>
                }
                {...a11yProps(10)}
              />
            )}
            {checkPrivileges("command") && (
              <CTab
                value="sharedSavedCommand"
                classes={{
                  root: "common-tab",
                  selected: "tab-selected",
                  wrapper: "tab-wrapper",
                }}
                label={
                  <span className="menu_item_text">
                    <ArrowRightIcon className="tab_menu_icon" />
                    <span className="menu_item_text_inner">
                      {this.props.translate("sharedSavedCommand")}
                    </span>
                  </span>
                }
                {...a11yProps(11)}
              />
            )}
            {checkPrivileges("maintenance") && (
              <CTab
                value="sharedMaintenance"
                classes={{
                  root: "common-tab",
                  selected: "tab-selected",
                  wrapper: "tab-wrapper",
                }}
                label={
                  <span className="menu_item_text">
                    <ArrowRightIcon className="tab_menu_icon" />
                    <span className="menu_item_text_inner">
                      {this.props.translate("sharedMaintenance")}
                    </span>
                  </span>
                }
                {...a11yProps(12)}
              />
            )}
            {checkPrivileges("setting") && (
              <CTab
                value="ecoDriving"
                classes={{
                  root: "common-tab",
                  selected: "tab-selected",
                  wrapper: "tab-wrapper",
                }}
                label={
                  <span className="menu_item_text">
                    <ArrowRightIcon className="tab_menu_icon" />
                    <span className="menu_item_text_inner">
                      {this.props.translate("ecoDriving")}
                    </span>
                  </span>
                }
                {...a11yProps(12)}
              />
            )}
          </VerticalTabs>
          {currentTab === "allAttribute" && (
            <TabPanel value={currentTab} index="allAttribute">
              {selecteditem && selecteditem.id && (
                <div>
                  <RawAttributes
                    positions={this.props.positions}
                    devices={this.props.devices3}
                    selectedDeviceId={this.props.id}
                    translate={this.props.translate}
                  />
                </div>
              )}
            </TabPanel>
          )}
          {currentTab === "basic" && (
            <TabPanel value={currentTab} index="basic">
              {selecteditem && (
                <DeviceModal
                  {...this.props}
                  onCloseModal={this.onCloseModal}
                  activeOperation={"edit"}
                  selecteditem={selecteditem}
                  updateSelectedItem={this.updateSelectedItem}
                  addNewGroups={this.addNewGroups}
                />
              )}
            </TabPanel>
          )}
          {currentTab === "icon" && selecteditem && (
            <TabPanel value={currentTab} index="icon">
              <IconForm
                selectedDevice={selecteditem}
                translate={this.props.translate}
                images={this.props.category}
                themecolors={this.props.themecolors}
                isVisableASOneBtn={this.state.isVisableASOneBtn}
                logo_handelChanges={this.logo_handelChanges}
                isVisablBtn={checkPrivileges("deviceUpdate")}
                advenceSectionOneformSubmit={this.advenceSectionOneformSubmit}
              />
            </TabPanel>
          )}
          {
            /* selecteditem &&
          selecteditem.model &&
          selecteditem.model === 'FX Tracker' ? null :  */ checkPrivileges(
              "sensor"
            ) ? (
              <>
                {currentTab === "sensors" && (
                  <TabPanel value={currentTab} index="sensors">
                    <Sensors
                      sensorData={this.props.sensorData}
                      {...this.state}
                      allDevices={
                        this.state.sensorDeviceids &&
                        this.state.sensorDeviceids.length
                          ? this.state.allDevices
                          : this.props.devices2
                      }
                      deviceId={id}
                      deviceModal={this.state.deviceModal}
                      translate={this.props.translate}
                      suggestions={this.state.suggestions}
                      computedAttributesDeviceWise={
                        this.props.computedAttributesDeviceWise
                      }
                      isVisablBtn={checkPrivileges("sensorCreate")}
                      selectedDeviceId={id}
                      rowsPerPage={this.state.rowsPerPage}
                      handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                      Devices_Selection={this.Devices_Selection}
                      submitCopySenors={this.submitCopySenors}
                      closeDialog={this.closeDialog}
                      selectedCopySensor={this.selectedCopySensor}
                      onCopy={this.onCopy}
                      selectAllCopySensors={(e) => this.selectAllCopySensors(e)}
                      getSensors={this.props.getSensors}
                    />
                  </TabPanel>
                )}{" "}
              </>
            ) : null
          }
          {currentTab === "sharedDeviceAccumulators" && (
            <TabPanel value={currentTab} index="sharedDeviceAccumulators">
              <Accumulators
                deviceId={id}
                translate={this.props.translate}
                suggestions={this.state.suggestions}
                computedAttributesDeviceWise={
                  this.props.computedAttributesDeviceWise
                }
                selecteditem={selecteditem}
                computedAttributesData={this.props.selectedComputedAttributes}
                isVisablBtn={checkPrivileges("deviceUpdate")}
              />
            </TabPanel>
          )}
          {currentTab === "Advance" && (
            <TabPanel value={currentTab} index="Advance">
              <AdvanceForm
                translate={this.props.translate}
                languages={this.props.languages}
                drivers={this.props.drivers}
                unitParkingAttributesform={unitParkingAttributesform}
                handleChange={this.handleChangeForParkingAtt}
                handleChange2={this.handleChangeForLanguageAtt}
                isVisableParkingAttBtn={this.state.isVisableParkingAttBtn}
                onSubmit={this.parkingformSubmit}
                isVisablBtn={checkPrivileges("deviceUpdate")}
                isAdvSettingEnable={this.state.isAdvSettingEnable}
                handleClick={this.handleClick}
                errorInFuelFilling={this.state.errorInFuelFilling}
                errorInFuelDrain={this.state.errorInFuelDrain}
              />
            </TabPanel>
          )}
          {/* checkPrivileges('driver') && currentTab === 'sharedDrivers' && (
            <TabPanel value={currentTab} index='sharedDrivers'>
              {this.state.openDriverModal ? (
                <Grid
                  container
                  spacing={0}
                  className='driver-page-content'
                  style={{
                    background: this.props.themecolors.backgroundColor,
                    color: this.props.themecolors.textColor
                  }}
                >
                  <Grid item xs={12} md={7}>
                    <DriversModal
                      onCloseModal={this.onCloseModal}
                      activeOperation={this.state.activeOperation}
                      selecteditem={this.state.driverObj}
                      selectedAddress={this.state.address}
                      lat={this.state.lat}
                      lng={this.state.lng}
                      trackersApiResponce={this.state.trackersApiResponce}
                      multiTrackers={this.state.multiTrackers}
                      getMultiDevice={this.getMultiDevice}
                      fetchMoreItems={this.fetchMoreItems}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <MarkerMap
                      showMarker={true}
                      getPostion={this.getPostion}
                      zoom={0}
                      lat={this.state.lat || 0}
                      lng={this.state.lng || 0}
                    />
                  </Grid>
                </Grid>
              ) : (
                <CommonTableSection
                  createButton={
                    checkPrivileges('driverCreate') && (
                      <div style={{ marginLeft: 10 }}>
                        <Button size='small' onClick={this.openDriverModal}>
                          {this.props.translate('Create')}
                        </Button>
                      </div>
                    )
                  }
                  component_Selection={this.driver_Selection}
                  themecolors={this.props.themecolors}
                  componentData={this.props.selecteddriver}
                  showCheckbox
                  isEditable
                  onDelete={this.onRemovedDriver}
                  label={this.props.translate('sharedDrivers')}
                  canUpdate
                  onEdit={this.selectDriver}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  rowsPerPage={this.state.rowsPerPage}
                  selectAll={e =>
                    selectAll(
                      e,
                      'deviceId',
                      'driverId',
                      this.props.id,
                      this.props.selecteddriver,
                      this.props.drivers,
                      this.props.dispatch,
                      'driver',
                      this.props.translate
                    )
                  }
                  rowDefinition={[
                    {
                      id: 'name',
                      numeric: false,
                      disablePadding: false,
                      label: this.props.translate('sharedDrivers')
                    }
                  ]}
                  canDelete={checkPrivileges('driverDelete')}
                  canRemove={checkPrivileges('deviceLinkDriver')}
                  canAssign={checkPrivileges('deviceUnlinkDriver')}
                />
              )}
            </TabPanel>
          ) */}
          {checkPrivileges("user") && currentTab === "Access" && (
            <TabPanel value={currentTab} index="Access">
              <Users
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                rowsPerPage={this.state.rowsPerPage}
                selectAll={(e) =>
                  selectAll(
                    e,
                    "userId",
                    "deviceId",
                    this.props.id,
                    this.props.selecteduser,
                    this.props.users,
                    this.props.dispatch,
                    "access",
                    this.props.translate
                  )
                }
                parent={
                  selecteditem && selecteditem.parentId
                    ? selecteditem.parentId
                    : 0
                }
                user_Selection={this.user_Selection}
                onCloseC_Att_Modal={this.onCloseC_Att_Modal}
                user_handleChange={this.user_handleChange}
                usernHandle={this.usernHandle}
                themecolors={this.props.themecolors}
                userData={this.props.selecteduser}
                roles={this.props.roles}
                translate={this.props.translate}
                onDelete={this.onRemovedUser}
                canRemove={checkPrivileges("userUnlinkNotification")}
                canAssign={checkPrivileges("userLinkNotification")}
              />
            </TabPanel>
          )}
          {/* checkPrivileges('geofence') && currentTab === 'sharedGeofences' && (
            <TabPanel value={currentTab} index='sharedGeofences'>
              {this.state.geofenceModal && (
                <GeofenceModal
                  geofence={this.state.geofenceModalData}
                  {...this.state.geofenceModalMapData}
                  onClose={this.closeGeofenceModal}
                />
              )}
              <CommonTableSection
                createButton={
                  checkPrivileges('geofenceCreate') && (
                    <div style={{ marginLeft: 10 }}>
                      <Button size='small' onClick={this.openGeofenceModal}>
                        {this.props.translate('Create')}
                      </Button>
                    </div>
                  )
                }
                component_Selection={this.geofence_Selection}
                themecolors={this.props.themecolors}
                componentData={this.props.selectedgeofence}
                showCheckbox={true}
                onDelete={this.onRemovedGeofence}
                label={this.props.translate('sharedGeofences')}
                canUpdate={false}
                isEditable
                canUpdate
                handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                rowsPerPage={this.state.rowsPerPage}
                selectAll={e =>
                  selectAll(
                    e,
                    'deviceId',
                    'geofenceId',
                    this.props.id,
                    this.props.selectedgeofence,
                    this.props.geofences,
                    this.props.dispatch,
                    'geofence',
                    this.props.translate
                  )
                }
                onEdit={this.openGeofenceModal}
                rowDefinition={[
                  {
                    id: 'name',
                    numeric: false,
                    disablePadding: false,
                    label: this.props.translate('sharedGeofences')
                  }
                ]}
                canDelete={checkPrivileges('geofenceDelete')}
                canRemove={checkPrivileges('deviceLinkGeofence')}
                canAssign={checkPrivileges('deviceUnlinkGeofence')}
              />
            </TabPanel>
          ) */}
          {checkPrivileges("notification") &&
            currentTab === "sharedNotifications" && (
              <TabPanel value={currentTab} index="sharedNotifications">
                <Notification
                  onListNotification={this.onListNotification}
                  onCloseC_Modal={this.onCloseC_Modal}
                  onCreateNewNotification={this.onCreateNewNotification}
                  notification_Selection={this.notification_Selection}
                  onCloseC_Att_Modal={this.onCloseC_Att_Modal}
                  addNotification={this.addNotification}
                  notification_handleChange={this.notification_handleChange}
                  notification_handleChange2={this.notification_handleChange2}
                  notificationHandle={this.notificationHandle}
                  calendars={this.props.calendars}
                  notificationDeviceWise={this.props.notificationDeviceWise}
                  themecolors={this.props.themecolors}
                  notificationData={this.props.selectednotification}
                  visableCreateNotification={this.state.visableNotification}
                  notificationForm={this.state.notificationForm}
                  notificationChannel={this.props.notificationChannel}
                  notificationType={this.props.notificationType}
                  translate={this.props.translate}
                  onEdit={this.editNotification}
                  onDelete={this.onRemovedNotification}
                  addButtonStatus={this.state.addButtonStatus}
                  addAttributes={this.addNotificationAttributes}
                  isAddVisableBtn={this.state.isAddVisableBtn}
                  isHaveAccess={this.state.isHaveAccess}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  rowsPerPage={this.state.rowsPerPage}
                  selectAll={(e) =>
                    selectAll(
                      e,
                      "deviceId",
                      "notificationId",
                      this.props.id,
                      this.props.selectednotification,
                      this.props.notification,
                      this.props.dispatch,
                      "notification",
                      this.props.translate
                    )
                  }
                  canRemove={checkPrivileges("deviceUnlinkNotification")}
                  canAssign={checkPrivileges("deviceLinkNotification")}
                  attributeChangesMessage={this.state.attributeChangesMessage}
                />
              </TabPanel>
            )}
          {checkPrivileges("attribute") &&
            currentTab === "sharedComputedAttributes" && (
              <TabPanel value={currentTab} index="sharedComputedAttributes">
                <ComputedAttributes
                  type="units"
                  {...this.props}
                  suggestions={this.state.suggestions}
                  onListComputedAttributes={this.onListComputedAttributes}
                  translate={this.props.translate}
                  onCloseC_Att_Modal={this.onCloseC_Att_Modal}
                  onCloseC_Modal={this.onCloseC_Modal}
                  themecolors={this.props.themecolors}
                  computedAttributesDeviceWise={
                    this.props.computedAttributesDeviceWise
                  }
                  onCreateNewAttributes={this.onCreateNewAttributes}
                  C_Att_handleChange={this.C_Att_handleChange}
                  attributeHandle={this.attributeHandle}
                  addComputedAttributes={this.addComputedAttributes}
                  computedAttributes_handleChange={
                    this.computedAttributes_handleChange
                  }
                  visableAttribute={this.state.visableAttribute}
                  visableListAttribute={this.state.visableListAttribute}
                  computedAttributesData={this.props.selectedComputedAttributes}
                  computedAttForm={this.state.computedAttForm}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  rowsPerPage={this.state.rowsPerPage}
                  selectAll={(e) =>
                    selectAll(
                      e,
                      "deviceId",
                      "attributeId",
                      this.props.id,
                      this.props.selectedComputedAttributes,
                      this.props.computedAttributes,
                      this.props.dispatch,
                      "attribute",
                      this.props.translate
                    )
                  }
                  computedAttributes_Selection={
                    this.computedAttributes_Selection
                  }
                  onEdit={this.editComputedAttributes}
                  onDelete={this.onRemovedAttribute}
                  addButtonStatus={this.state.addButtonStatus}
                  isAddVisableBtn={this.state.isAddVisableBtn}
                  isHaveAccess={this.state.isHaveAccess}
                  canRemove={checkPrivileges("deviceUnlinkAttribute")}
                  canAssign={checkPrivileges("deviceLinkAttribute")}
                />
              </TabPanel>
            )}
          {checkPrivileges("command") &&
            currentTab === "sharedSavedCommand" && (
              <TabPanel value={currentTab} index="sharedSavedCommand">
                <SavedCommands
                  onListSavedCommands={this.onListSavedCommands}
                  translate={this.props.translate}
                  formType={this.state.formType}
                  onCloseC_Att_Modal={this.onCloseC_Att_Modal}
                  onCloseC_Modal={this.onCloseC_Modal}
                  themecolors={this.props.themecolors}
                  commandsDeviceWise={this.props.commandsDeviceWise}
                  onCreateNewSavedCommands={this.onCreateNewSavedCommands}
                  visableAttribute={this.state.visableSavedCommands}
                  addSavedCommands={this.addSavedCommands}
                  savedCommands_handleChange={this.savedCommands_handleChange}
                  savedCommandsHandle={this.savedCommandsHandle}
                  visableListSavedCommands={this.state.visableListSavedCommands}
                  visableSavedCommands={this.state.visableSavedCommands}
                  commandsData={this.props.selectedcommands}
                  savedCommandsForm={this.state.savedCommandsForm}
                  savedCommands_Selection={this.savedCommands_Selection}
                  commandsTypes={this.props.commandsTypes}
                  onEdit={this.editCommands}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  rowsPerPage={this.state.rowsPerPage}
                  selectAll={(e) =>
                    selectAll(
                      e,
                      "deviceId",
                      "commandId",
                      this.props.id,
                      this.props.selectedcommands,
                      this.props.commands,
                      this.props.dispatch,
                      "command",
                      this.props.translate
                    )
                  }
                  onDelete={this.onRemovedCommand}
                  addButtonStatus={this.state.addButtonStatus}
                  isAddVisableBtn={this.state.isAddVisableBtn}
                  isHaveAccess={this.state.isHaveAccess}
                  canRemove={checkPrivileges("deviceUnlinkCommand")}
                  canAssign={checkPrivileges("deviceLinkCommand")}
                  sendCommandsModal={this.sendCommandsModal}
                  sendCommand
                />
              </TabPanel>
            )}
          {checkPrivileges("maintenance") &&
            currentTab === "sharedMaintenance" && (
              <TabPanel value={currentTab} index="sharedMaintenance">
                <MaintenanceForm
                  onListMaintenance={this.onListMaintenance}
                  onCloseC_Modal={this.onCloseC_Modal}
                  onCreateNewMaintenance={this.onCreateNewMaintenance}
                  maintenance_Selection={this.maintenance_Selection}
                  saveToParent={this.saveToParent}
                  onCloseC_Att_Modal={this.onCloseC_Att_Modal}
                  addMaintenance={this.addMaintenance}
                  maintenance_handleChange={this.maintenance_handleChange}
                  maintenance_handleChange_attr={
                    this.maintenance_handleChange_attr
                  }
                  maintenancesHandle={this.maintenancesHandle}
                  maintenanceDeviceWise={this.props.maintenanceDeviceWise}
                  themecolors={this.props.themecolors}
                  visableListMaintenance={this.state.visableListMaintenance}
                  maintenanceData={this.props.selectedMaintenance}
                  visableCreateMaintenance={this.state.visableMaintenance}
                  maintenanceForm={this.state.maintenanceForm}
                  translate={this.props.translate}
                  onEdit={this.editMaintenance}
                  onDelete={this.onRemovedMaintenance}
                  addButtonStatus={this.state.addButtonStatus}
                  addAttributes={this.addMaintenanceAttributes}
                  isAddVisableBtn={this.state.isAddVisableBtn}
                  isHaveAccess={this.state.isHaveAccess}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  rowsPerPage={this.state.rowsPerPage}
                  selectAll={(e) =>
                    selectAll(
                      e,
                      "deviceId",
                      "maintenanceId",
                      this.props.id,
                      this.props.selectedMaintenance,
                      this.props.maintenance,
                      this.props.dispatch,
                      "maintenance",
                      this.props.translate
                    )
                  }
                  canRemove={checkPrivileges("deviceUnlinkMaintenance")}
                  canAssign={checkPrivileges("deviceLinkMaintenance")}
                  attributeChangesMessage={this.state.attributeChangesMessage}
                />
              </TabPanel>
            )}
          {checkPrivileges("setting") && currentTab === "ecoDriving" && (
            <TabPanel value={currentTab} index="ecoDriving">
              <EcoDriving
                selectItemId={this.props.id}
                translate={this.props.translate}
              />
            </TabPanel>
          )}
        </div>
      </Fragment>
    );
  }
}

MainUnitModal.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapState = (state) => ({
  logInUser: state.logInUsers,
  devices: state.devices.data,
  devices3: state.devices3,
  devices2: state.devices2,
  groups: state.groups,
  drivers: state.drivers,
  geofences: state.geoFence,
  users: state.users,
  themecolors: state.themeColors,
  notification: state.notification,
  notificationType: state.notificationType,
  notificationChannel: state.notificationChannel,
  selectednotification: state.selectednotification,
  selecteddriver: state.selecteddriver,
  selecteduser: state.selecteduser,
  selectedgeofence: state.selectedgeofence,
  geofenceDeviceWise: state.geofenceDeviceWise,
  notificationDeviceWise: state.notificationDeviceWise,
  computedAttributes: state.computedAttributes,
  selectedComputedAttributes: state.selectedComputedAttributes,
  computedAttributesDeviceWise: state.computedAttributesDeviceWise,
  sensorsDeviceWise: state.sensorsDeviceWise,
  maintenance: state.maintenance,
  maintenanceDeviceWise: state.maintenanceDeviceWise,
  selectedMaintenance: state.selectedMaintenance,
  commands: state.commands,
  commandsTypes: state.commandsTypes,
  commandsDeviceWise: state.commandsDeviceWise,
  selectedcommands: state.selectedcommands,
  calendars: state.calendars,
  category: state.category,
  positions: state.positions,
  roles: state.roles,
  ServerSetting: state.ServerSetting,
});

const mapStateToProps = connect(mapState);

export default mapStateToProps(
  withTranslationWrapper(withStyles(styles)(MainUnitModal))
);
