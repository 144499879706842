import React, { Component } from "react";
import { connect } from "react-redux";
import { checkPrivileges, getDateTimeFormat } from "../../../Helpers";
import Tooltip from "../../../Components/common/Tooltip";
import DateRangePicker from "../../../Components/common/DateRangePicker";
import Button from "../../../Components/common/Button";
import moment from "moment";
import Scrollbar from "react-scrollbars-custom";
import NotificationSelector from "./../../../Components/Devices/NotificationSelector";
import NotificationTypeSelector from "./../../../Components/Devices/NotificationTypeSelector";
// import SettingsIcon from '@mui/icons-material/Settings'
import SettingsIcon from "@mui/icons-material/FormatListBulleted";
import AddIcon from "@mui/icons-material/Add";
import FilterIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import DeviceSelector from "../../../Components/Devices/DeviceSelector";
import Stepper from "./../../../Components/common/Stepper";
import { UserWiseEvents } from "../../../Components/Events/userEvents";
import Loader from "../../Loader";
import withTranslationWrapper from "../../../HOC/withTranslation";

class Events extends Component {
  render() {
    const { userEvents, ...others } = this.props;
    return (
      <aside className="fms-sidebar sidebar-bg-layer fms-notification-inner">
        <div style={{ padding: 13 }}>
          <h3
            style={{
              display: "flex",
              alignItems: "center",
              margin: 0,
              justifyContent: "space-between",
            }}
          >
            <span style={{ color: this.props.themecolors.textColor }}>
              {this.props.translate("Notifications")}
            </span>

            {checkPrivileges("notification") ? (
              <Tooltip title={this.props.translate("notificationFilter")}>
                <span style={{ marginLeft: "auto" }}>
                  <div
                    style={{
                      border: "1px solid",
                      borderColor: this.props.themecolors.menuActiveBackground,
                      borderRadius: 4,
                      cursor: "pointer",
                      display: "flex",
                      padding: 3,
                    }}
                    color="inherit"
                    onClick={this.props.toggleFilterForm}
                  >
                    {!this.props.showFilterForm ? (
                      <FilterIcon style={{ fontSize: 18 }} />
                    ) : (
                      <CloseIcon style={{ fontSize: 18 }} />
                    )}
                  </div>
                </span>
              </Tooltip>
            ) : null}
            {/* {checkPrivileges("notification") ? (
              <Tooltip title={this.props.translate("userNotification")}>
                <span>
                  <Button
                    style={{ marginLeft: 6, minWidth: "inherit" }}
                    color="inherit"
                    onClick={(e) => this.props.history.push("/notifications")}
                  >
                    <SettingsIcon style={{ fontSize: 18 }} />
                  </Button>
                </span>
              </Tooltip>
            ) : null} */}
            {checkPrivileges("notification") ? (
              <Tooltip title={this.props.translate("createUpdateNotification")}>
                <span style={{ margin: "0 3px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid",
                      borderColor: this.props.themecolors.menuActiveBackground,
                      borderRadius: 8,
                      cursor: "pointer",
                    }}
                    onClick={(e) =>
                      this.props.history.push("/notifications?mode=create")
                    }
                  >
                    <span
                      style={{
                        background: this.props.themecolors.menuActiveBackground,
                        borderRadius: "4px 0px 0px 4px",
                        padding: "1px 8px",
                        display: "flex",
                        flexDirection: "column",
                        color: "#ffffff",
                        fontWeight: "500",
                      }}
                    >
                      +
                    </span>
                    <span
                      style={{
                        color: "#141313",
                        fontSize: 12,
                        padding: "0 12px",
                        fontWeight: "500",
                      }}
                    >
                      {this.props.translate("sharedCreate")}
                    </span>
                  </div>
                </span>
              </Tooltip>
            ) : null}
          </h3>
        </div>
        <div style={{ position: "relative", height: "100%" }}>
          {this.props.userEvents && this.props.userEvents.loading ? (
            <Loader defaultStyle />
          ) : this.props.showFilterForm ? (
            <EventFrom {...others} />
          ) : (
            <UserWiseEvents {...this.props.userEvents} />
          )}
        </div>
      </aside>
    );
  }
}
class EventFrom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: "",
      notificationId: [],
      notificationType: [],
      isProcessCompleted: false,
      selectitem: {},
      type: "event",
      from: "",
      to: "",
      steps: [
        { id: 1, title: "selectEventTimeControl" },
        { id: 2, title: "selectedTrackers" },
      ],
      activeStep: 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.selectedNotification = this.selectedNotification.bind(this);
    this.showDates = this.showDates.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.selectedDevices = this.selectedDevices.bind(this);
  }
  handleChange = (name) => (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        validatedForm: value,
        showAllNotification: value,
        showNotification: false,
        isEnable: false,
      },
      () => {
        if (this.state.showAllNotification) {
          this.setState({
            notificationType: [],
          });
        }
      }
    ); /*
    if (value === true) {
      this.setState({
        notificationType: [],
        to: '',
        from: ''
      })
    }*/
  };

  selectedNotification = (notificationType) => {
    this.setState({ notificationType }, () => {
      if (
        this.state.notificationType.length &&
        this.state.selectedDate &&
        this.state.selectitem.length
      ) {
        this.setState({
          validatedForm: true,
          showNotification: false,
          isEnable: false,
        });
      } else {
        this.setState({
          validatedForm: false,
          showNotification: false,
          isEnable: false,
        });
      }
    });
  };

  handleChangeType = (type) => {
    this.setState({
      type,
      notificationId: [],
      notificationType: [],
      showAllNotification: false,
    });
  };

  handleNotification = (notificationId) => {
    this.setState({ notificationId }, () => {
      if (
        (this.state.notificationId.length ||
          this.state.notificationType.length) &&
        this.state.selectedDate &&
        this.state.selectitem.length
      ) {
        this.setState({
          validatedForm: true,
          showNotification: false,
          isEnable: false,
        });
      } else {
        this.setState({
          validatedForm: false,
          showNotification: false,
          isEnable: false,
        });
      }
    });
  };

  showNotification = () => {
    this.setState(
      {
        isEnable: false,
        showNotification: true,
      },
      () => {
        this.props.collapseSidebar();
        this.props.showNotification({
          isEnable: false,
          showNotification: true,
          selecteditemId: this.state.selectitem.id,
          to: this.state.to,
          from: this.state.from,
          notificationType: this.state.notificationType,
          notificationId: this.state.notificationId,
          validatedForm: this.state.validatedForm,
          //selecteditem: this.state.selectitem
        });
      }
    );
  };

  getNotificationType = () => {
    let array = [];
    if (this.props.notificationType.length) {
      this.props.notificationType.map((item, i) => {
        array.push({
          name: this.props.translate("notification." + item.type),
          id: item.type,
        });
        return null;
      });
      return array;
    } else {
      return array;
    }
  };
  showDates(e, date) {
    let timezone = "Asia/Dubai";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);

    var formatedDate = {
      from: moment(date.startDate.format("YYYY-MM-DD HH:mm")).toISOString(),
      to: moment(date.endDate.format("YYYY-MM-DD HH:mm")).toISOString(),
    };

    let timeFormat = getDateTimeFormat();

    this.setState(
      {
        selectedDate:
          moment(date.startDate).format(timeFormat) +
          " - " +
          moment(date.endDate).format(timeFormat),
        ...formatedDate,
      },
      () => {
        if (
          (this.state.notificationType.length ||
            this.state.notificationId.length ||
            this.state.showAllNotification) &&
          this.state.selectedDate &&
          this.state.selectitem.length
        ) {
          this.setState({
            validatedForm: true,
            showNotification: false,
            isEnable: false,
          });
        } else {
          this.setState({
            validatedForm: false,
            showNotification: false,
            isEnable: false,
          });
        }
      }
    );
  }
  goToNextStep = (step, type, index) => {
    this.validatedCurrentStep(step, type, index);
  };
  goToBackStep = () => {
    const currentStep = this.state.activeStep;
    this.setState({ activeStep: currentStep - 1 });
  };
  validatedCurrentStep = (step, type, index) => {
    const steps = [];
    let errorMsg = null;
    let validated = false;
    if (step === 1) {
      if (this.state.selectedDate) {
        if (
          this.state.notificationId.length ||
          this.state.notificationType.length
        ) {
          validated = true;
        } else {
          errorMsg = (
            <span className="text-warning">
              {this.props.translate("pleaseSelectNotification")}
            </span>
          );
          validated = false;
        }
      } else {
        errorMsg = (
          <span className="text-warning">
            {this.props.translate("pleaseSelectFromTo")}
          </span>
        );
        validated = false;
      }
    }
    if (step === 2) {
      if (this.state.selectitem.length) {
        this.showNotification();
        // this.setState({activeStep: 0})
      } else {
        errorMsg = (
          <span className="text-warning">
            {this.props.translate("pleaseSelectUnit")}
          </span>
        );
      }
    }
    if (validated) {
      this.setState({ activeStep: index + 1 });
    }
    this.state.steps.map((s) => {
      if (s.id === step) {
        steps.push({ ...s, errorMsg });
      } else steps.push(s);
      return null;
    });
    this.setState({ steps });
  };

  selectedDevices(a) {
    this.setState(
      {
        selectitem: a,
      },
      () => {
        if (
          (this.state.notificationType.length ||
            this.state.notificationId.length ||
            this.state.showAllNotification) &&
          this.state.selectedDate &&
          this.state.selectitem.length
        ) {
          this.setState({
            validatedForm: true,
            showNotification: false,
            isEnable: false,
          });
        } else {
          this.setState({
            validatedForm: false,
            showNotification: false,
            isEnable: false,
          });
        }
      }
    );
    this.props.selectedItem(a);
  }

  setSelectedDevice = () => {
    let selectitem = this.props.devices2.find(
      (item) => parseInt(this.props.match.params.id) === item.id
    );
    this.setState({
      isProcessCompleted: true,
      selectitem: {
        id: selectitem.id,
        key: selectitem.id,
        name: selectitem.label,
        value: selectitem.id,
        label: selectitem.label,
      },
    });
  };
  getStepContent = (id) => {
    switch (id) {
      case 1:
        return (
          <div>
            <div>
              <DateRangePicker
                fill
                selectedDate={this.state.selectedDate}
                label={
                  this.props.translate("reportFrom") +
                  " " +
                  this.props.translate("reportTo")
                }
                onEvent={this.showDates}
              />
            </div>
            <div style={{ margin: "8px 0" }}>
              <div className="report-sidebar-switch">
                <Button
                  onClick={(e) => this.handleChangeType("event")}
                  className={
                    this.state.type === "event" ? "" : "button-inactive"
                  }
                >
                  {this.props.translate("event")}
                </Button>
                <Button
                  onClick={(e) => this.handleChangeType("type")}
                  className={
                    this.state.type === "type" ? "" : "button-inactive"
                  }
                >
                  {this.props.translate("type")}
                </Button>
              </div>
              {this.state.type === "type" ? (
                <NotificationTypeSelector
                  onChange={this.selectedNotification}
                  value={this.state.notificationType}
                />
              ) : null}
              {this.state.type === "event" ? (
                <NotificationSelector
                  onChange={this.handleNotification}
                  value={this.state.notificationId}
                  rows={7}
                />
              ) : null}
            </div>
          </div>
        );
      case 2:
        return (
          <div
            style={{
              position: "relative",
              zIndex: 2,
              margin: "0 8px",
            }}
          >
            <DeviceSelector
              value={this.state.selectitem}
              onChange={this.selectedDevices}
              hideIcons
              rows={5}
              themecolors={this.props.themecolors}
            />
          </div>
        );
      default:
        return null;
    }
  };

  render() {
    if (
      this.props.devices2.length &&
      !this.state.isProcessCompleted &&
      this.props.match.params.id
    ) {
      this.setSelectedDevice();
    }
    const viewScreen = (
      <form style={{ padding: "16px 0 16px 10px" }}>
        <>
          <Stepper
            translate={this.props.translate}
            activeStep={this.state.activeStep}
            reportType={this.state.selectitem.length}
            getStepContent={this.getStepContent}
            steps={this.state.steps}
            handleNext={this.goToNextStep}
            handleBack={this.goToBackStep}
          />
        </>
      </form>
    );

    return (
      <>
        {checkPrivileges("device") && (
          <Scrollbar>
            {checkPrivileges("device") ? (
              <div>
                {viewScreen}
                {/* <Button
                    disabled={!this.state.validatedForm}
                    variant='contained'
                    onClick={this.showNotification}
                  >
                    {this.props.translate('reportShow')}
                  </Button> */}
              </div>
            ) : null}
          </Scrollbar>
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    devices: state.devices.data,
    devices2: state.devices2,
    groups: state.groups,
    logInUser: state.logInUsers,
    themecolors: state.themeColors,
    notificationType: state.notificationType,
  };
};

export default connect(mapStateToProps)(withTranslationWrapper(Events));
