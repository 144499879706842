import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import "moment-timezone";
import {
  getDateTimeFormat,
  setAttributeFormat,
  validateImmobilier,
} from "../../../Helpers";
import EmptyState from "../../../Components/common/EmptyState";
import { Icon, Tooltip } from "@mui/material";
import ProtocolDialog from "../../../Components/Features/ProtocolDialog";

const DrawAttributes = (props) => {
  if (props.data && props.data.attributes) {
    const keys = Object.keys(props.data.attributes);
    if (keys.length) {
      return keys.map((k) => {
        let v = props.data.attributes[k];
        let t = k;

        let statusText = null;
        let inputText = null;

        switch (k) {
          case "status":
            if (props.data.protocol === "galileo") {
              statusText = (
                <HelpOutlineIcon
                  style={{ verticalAlign: "middle" }}
                  onClick={(e) =>
                    props.openProtocol(
                      props.data.attributes.status,
                      props.data.protocol,
                      k
                    )
                  }
                />
              );
            }
            break;
          case "input":
            if (props.data.protocol === "bce") {
              inputText = (
                <HelpOutlineIcon
                  style={{ verticalAlign: "middle" }}
                  onClick={(e) =>
                    props.openProtocol(
                      props.data.attributes.input,
                      props.data.protocol,
                      k
                    )
                  }
                />
              );
            }

            break;

          case "unknown":
            return null;
          default:
            v = setAttributeFormat(k, v);
            break;
        }

        switch (k) {
          case "odometer":
            return null;
          case "index":
          case "hdop":
          case "vdop":
          case "pdop":
          case "sat":
          case "satVisible":
          case "rssi":
          case "gps":
          case "roaming":
          case "event":
          case "alarm":
          case "status":
          case "serviceOdometer":
          case "tripOdometer":
          case "hours":
          case "steps":
          case "power":
          case "input":
          case "output":
          case "battery":
          case "batteryLevel":
          case "fuel":
          case "fuelConsumption":
          case "approximate":
          case "rpm":
          case "throttle":
          case "armed":
          case "acceleration":
          case "deviceTemp":
          case "obdSpeed":
          case "obdOdometer":
          case "distance":
          case "totalDistance":
          case "commandResult":
          case "dtcs":
          case "blocked":
          case "operator":
          case "deviceCommand":
          case "sharedGeofence":
          case "motion":
          case "vin":
          case "deviceTotalDistance":
          case "rfid":
          case "versionFw":
          case "versionHw":
          case "ignition":
          case "flags":
          case "charge":
          case "ip":
          case "archive":
          case "sharedType":
          case "driverUniqueId":
          case "trailerUniqueId":
          case "stopTime":
          case "raw":
          case "mileageAccuracy":
          case "parking":
          case "custom":
          case "boardPower":
          case "lastUpdated":
          case "immobilizer":
            t = props.translate(k);
            break;
          default:
            break;
        }
        return (
          <div className={`all-attribute-item`} key={k}>
            <Tooltip title={props.translate("positionTooltip." + k)}>
              <span className="attr-value">{t} </span>
            </Tooltip>{" "}
            <span className="attr-label">
              {k === "immobilizer" ? (
                validateImmobilier(v)
              ) : (
                <>
                  {" "}
                  {v} {inputText} {statusText}
                </>
              )}
            </span>
          </div>
        );
      });
    } else {
      return null;
    }
  } else {
    return null;
  }
};

class rawAttributes extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      device: {},
      details: {},
      group: {},
      modal: false,
      input: "",
      protocol: "",
      inputName: "",
    };
    this.openProtocol = this.openProtocol.bind(this);
    this.closeProtocol = this.closeProtocol.bind(this);
  }

  openProtocol(input, protocol, inputName) {
    this.setState({ modal: true, input, protocol, inputName });
  }

  closeProtocol() {
    this.setState({ modal: false, input: "", protocol: "", inputName: "" });
  }

  UNSAFE_componentWillMount() {
    const list = {};
    this.props.devices.map((d) => {
      list[d.id] = d;
      return null;
    });
    const positions = {};
    this.props.positions.map((d) => {
      positions[d.deviceId] = d;
      return null;
    });

    if (list && list[this.props.selectedDeviceId]) {
      this.setState({
        device: list[this.props.selectedDeviceId] || {},
        details: positions[this.props.selectedDeviceId] || {},
      });
    }
  }

  UNSAFE_componentWillReceiveProps(NextProps, NextState) {
    const list = {};
    NextProps.devices.map((d) => {
      list[d.id] = d;
      return null;
    });
    const positions = {};
    NextProps.positions.map((d) => {
      positions[d.deviceId] = d;
      return null;
    });

    const groups = {};
    NextProps.groups.map((d) => {
      groups[d.id] = d;
      return null;
    });
    if (list && list[NextProps.selectedDeviceId]) {
      this.setState(
        {
          device: list[NextProps.selectedDeviceId] || {},
          details: positions[NextProps.selectedDeviceId] || {},
        },
        () => {
          this.setState({
            group: groups[this.state.device.groupId]
              ? groups[this.state.device.groupId]
              : {},
          });
        }
      );
    } else {
      this.setState({
        details: {},
      });
    }
  }

  render() {
    let attr = null;
    let serverTime = null;
    let serverTimeString = null;
    const timeFormat = getDateTimeFormat();
    if (Object.keys(this.state.details).length) {
      if (
        this.props.logInUser.attributes &&
        this.props.logInUser.attributes.timezone
      ) {
        serverTimeString = moment(this.state.details.serverTime)
          .tz(this.props.logInUser.attributes.timezone)
          .format(timeFormat);
      }
      attr = (
        <DrawAttributes
          openProtocol={this.openProtocol}
          logInUser={this.props.logInUser}
          translate={this.props.translate}
          data={this.state.details}
        />
      );
      if (this.state.details.id) {
        serverTime = (
          <div className="all-attribute-item">
            <Tooltip
              title={this.props.translate("positionTooltip.lastUpdated")}
            >
              <span className="attr-label">
                {this.props.translate("positionTooltip.lastUpdated")}
              </span>
            </Tooltip>{" "}
            <span className="attr-value">{serverTimeString}</span>
          </div>
        );
      }
      return (
        <div style={{ marginTop: 12 }}>
          <h4
            className="all-attributes-heading"
            style={{ margin: "5px 10px", fontSize: 16 }}
          >
            {this.props.translate("allAttributes")}
          </h4>
          <div className="all-attributes-wrapper">
            {attr}
            {serverTime}
            {this.state.modal === true ? (
              <ProtocolDialog
                protocol={this.state.protocol}
                input={this.state.input}
                inputName={this.state.inputName}
                handleClose={this.closeProtocol}
              />
            ) : null}
          </div>
        </div>
      );
    } else {
      return <EmptyState text={this.props.translate("NotExistsAttribute")} />;
    }
  }
}

const mapState = (state) => ({
  groups: state.groups,
  logInUser: state.logInUsers,
});

const mapStateToProps = connect(mapState);
export default mapStateToProps(rawAttributes);
