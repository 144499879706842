// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.all-attributes-wrapper {
  display: flex;
  flex-flow: row wrap;
  /* this is where the magic is */
  background-repeat: repeat;
  background-size: 100px 174px; /* width is not relevant, but height must be 2*\$cell_height */
  margin-left: 10px;
}

.all-attribute-item {
  padding: 4px 0;
  font-size: 12px;
  display: inline-block;
  min-width: 160px;
  position: relative;
}
.all-attribute-item .attr-label {
  display: block;
  margin-bottom: 5px;
}
.all-attribute-item .attr-value {
  font-size: 12px;
  font-weight: 500;
  display: block;
  word-break: break-word;
}
@media (max-width: 959px) {
  .all-attribute-item {
    min-width: 150px;
  }
}

.tab_menu_icon {
  vertical-align: middle;
  float: left;
}

.tab-selected .tab_menu_icon {
  display: block;
}

.menu_item_text {
  font-weight: 700;
  opacity: 1;
}

.v-tab-heading {
  margin: 0;
  line-height: 24px;
}
.v-tab-heading span {
  vertical-align: top;
  color: #b0b0b0;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Users/Style.scss"],"names":[],"mappings":"AAEA;EACC,aAAA;EACA,mBAAA;EAEA,+BAAA;EACA,yBAAA;EACA,4BAAA,EAAA,6DAAA;EACA,iBAAA;AAFD;;AAIA;EACC,cAAA;EACA,eAAA;EACA,qBAAA;EACA,gBAAA;EAEA,kBAAA;AAFD;AAIC;EACC,cAAA;EACA,kBAAA;AAFF;AAIC;EACC,eAAA;EACA,gBAAA;EACA,cAAA;EACA,sBAAA;AAFF;AAIC;EAlBD;IAmBE,gBAAA;EADA;AACF;;AAKA;EACC,sBAAA;EACA,WAAA;AAFD;;AAKC;EACC,cAAA;AAFF;;AAKA;EACC,gBAAA;EACA,UAAA;AAFD;;AAKA;EACC,SAAA;EACA,iBAAA;AAFD;AAGC;EACC,mBAAA;EACA,cAAA;AADF","sourcesContent":["@import './../../assets/variables.scss';\n\n.all-attributes-wrapper {\n\tdisplay: flex;\n\tflex-flow: row wrap;\n\t\n\t/* this is where the magic is */\n\tbackground-repeat: repeat;\n\tbackground-size: 100px (87 * 2) * 1px; /* width is not relevant, but height must be 2*$cell_height */\n\tmargin-left:10px\n}\n.all-attribute-item {\n\tpadding: 4px 0;\n\tfont-size: 12px;\n\tdisplay: inline-block;\n\tmin-width: 160px;\n\t// text-align: center;\n\tposition: relative;\n\t\n\t.attr-label {\n\t\tdisplay: block;\n\t\tmargin-bottom: 5px;\n\t}\n\t.attr-value {\n\t\tfont-size: 12px;\n\t\tfont-weight: 500;\n\t\tdisplay: block;\n\t\tword-break: break-word;\n\t}\n\t@media (max-width: 959px) {\n\t\tmin-width: 150px;\t\t\n\t}\n}\n\n\n.tab_menu_icon {\n\tvertical-align: middle;\n\tfloat: left;\n}\n.tab-selected {\n\t.tab_menu_icon {\n\t\tdisplay: block;\n\t}\n}\n.menu_item_text {\n\tfont-weight: 700;\n\topacity: 1;\n}\n\n.v-tab-heading {\n\tmargin: 0;\n\tline-height: 24px;\n\tspan {\n\t\tvertical-align: top;\n\t\tcolor: #b0b0b0;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
