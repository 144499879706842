import React, { Fragment, PureComponent } from "react";
import Menu from "./../Menu/CommonMenu";
import MenuItem from "./../common/MenuItem";
import Checkbox from "./../common/Checkbox";
import Icon from "@mui/material/Icon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { connect } from "react-redux";
import "./PositionMenu.scss";
import { Tooltip } from "@mui/material";
import Style from "style-it";
import { applyDevicesFilter } from "../../Actions/Devices";
import axios from "axios";
import {
  fetchVehicles,
  fetchGeofences,
  fetchDrivers,
  fetchDevices,
  fetchUsers,
} from "./../../Actions/Devices/index";
import { fetchTrailers } from "./../../Actions/Trailer/index";
import { notifyError, notifySuccess } from "../../Utils/CustomNotifcations";
import Button from "../common/Button";
import Radio from "../common/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import PublishIcon from "@mui/icons-material/Publish";
import ExportExcel from "../common/ExportExcel";
import ImportExcel from "../common/ImportExcel";
import { checkPrivileges } from "../../Helpers";
import CustomDialog from "../common/Dialog";
import withTranslationWrapper from "../../HOC/withTranslation";
class ExportImportMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      process: false,
      enableImportPopup: false,
      uploadedFile: "",
      insertionMode: "1",
      selectedFile: "",
      isSubmit: true,
    };
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  onCloseModal = () => {
    this.setState({
      enableImportPopup: false,
      selectedFile: "",
      isSubmit: true,
    });
  };
  onOpenModal = () => {
    this.setState({
      enableImportPopup: true,
    });
  };
  uploadFile = (e) => {
    this.setState({
      uploadedFile: e.target.files && e.target.files[0],
      selectedFile:
        e.target.files && e.target.files[0] && e.target.files[0].name,
      isSubmit: false,
    });
  };
  selectInsertionMode = (e) => {
    this.setState({
      insertionMode: e.target.value,
    });
  };
  formSubmit = async (e, fileName) => {
    let api,
      message = "";
    if (
      [
        "vehicles",
        "drivers",
        "devices",
        "trailers",
        "users",
        "services",
      ].includes(fileName)
    ) {
      api = `/api/${fileName}/import`;
    } else if (fileName === "geofences") {
      api = `/api/geofences/import/circle`;
    }
    if (e) {
      const file = await this.convertBase64(e);
      const base64 = file.split("base64,");
      axios({
        method: "POST",
        url: api,
        data: {
          name: "anyName",
          contentType:
            "@file/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          oldUrl: "",
          insertionMode: this.state.insertionMode,
          data: base64[1],
        },
      })
        .then((response) => {
          if (response && response.data && response.data.status === "success") {
            if (fileName === "vehicles") {
              fetchVehicles(this.props.dispatch, this.props.logInUser);
            } else if (fileName === "drivers") {
              fetchDrivers(this.props.dispatch, this.props.logInUser);
            } else if (fileName === "devices") {
              this.props.dispatch(fetchDevices(this.props.logInUser, true));
            } else if (fileName === "geofences") {
              fetchGeofences(this.props.dispatch, this.props.logInUser);
            } else if (fileName === "trailers") {
              fetchTrailers(this.props.dispatch, this.props.logInUser);
            } else if (fileName === "users") {
              fetchUsers(this.props.dispatch, this.props.logInUser);
            }
            const b = [];
            const c = response.data.data;
            Object.keys(c).map((key) => {
              if (c[key] && key !== "total" && c.total !== c.skiped)
                b.push({ type: this.props.translate(key), value: c[key] });
            });
            b.map((data, i) => {
              if (b.length > 1) {
                if (b.length - 2 === i) {
                  message +=
                    data.value +
                    " " +
                    data.type +
                    " " +
                    this.props.translate("AND").toLowerCase() +
                    " ";
                } else if (b.length - 1 !== i) {
                  message += data.value + " " + data.type + ", ";
                } else {
                  message += data.value + " " + data.type;
                }
              } else {
                message += data.value + " " + data.type;
              }
            });
            if (b.length === 0) {
              message = this.props.translate("allItemsOmitted");
            } else {
              message += this.props.translate("successfully");
            }
            this.props.dispatch(
              notifySuccess(message)
            );
            this.onCloseModal();
          } else {
            this.props.dispatch(
              notifyError(this.props.translate("invalidFileFormat"))
            );
          }
        })
        .catch((error) => {
          console.log("er =", error);
          this.onCloseModal();
        });
    }
  };
  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  UNSAFE_componentWillMount() {}

  showSelectedUnits(data) {
    const list = [...this.props.filterList];
    let newList = [];
    if (list.includes(data)) {
      list.map((l) => (l != data ? newList.push(l) : null));
    } else {
      newList = [...list].concat(data);
    }
    this.props.dispatch(applyDevicesFilter(newList));
  }

  UNSAFE_componentWillReceiveProps(n) {}

  render() {
    const { anchorEl } = this.state;
    const empty = null;
    const { data, fileName, checkPrivilege } = this.props;
    return (
      <Style>
        {`
      .main-menu-list-wrapper {
        background-color: ${this.props.themecolors.themeLightColor};
      }
      a {
        color: inherit
      }
      `}
        <div>
          <a
            href={empty}
            aria-owns={anchorEl ? "simple-menu" : undefined}
            aria-haspopup="true"
            onClick={this.handleClick}
          >
            <MoreVertIcon
              style={{ fontSize: 26, marginRight: -8, verticalAlign: "middle" }}
            />
          </a>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
            themecolors={this.props.themecolors}
          >
            {fileName !== "users" ? (
              <ExportExcel
                type="Sample"
                fileName={fileName}
                data={data}
                title="Sample"
                sample={true}
                translate={this.props.translate}
              />
            ) : null}
            {data && data.length ? (
              <ExportExcel
                type="Excel"
                fileName={fileName}
                data={data}
                title={this.props.translate("Excel")}
                translate={this.props.translate}
                sample={false}
              />
            ) : null}
            {fileName !== "geofences" &&
            fileName !== "services" &&
            data &&
            data.length ? (
              <ExportExcel
                type="PDF"
                title={this.props.translate("PDF")}
                fileName={fileName}
                data={data}
                // title= 'PDF'
                sample={false}
                translate={this.props.translate}
              />
            ) : null}
            {fileName !== "users" && checkPrivileges(checkPrivilege || "") ? (
              <Tooltip title={this.props.translate("Upload Excel")}>
                <MenuItem
                  style={{ minWidth: "inherit" }}
                  component="label"
                  onClick={this.onOpenModal}
                >
                  {this.props.translate("Upload Excel")}
                </MenuItem>
              </Tooltip>
            ) : null}
          </Menu>
          {this.state.enableImportPopup && (
            <CustomDialog
              title="Import Excel"
              themecolors={this.props.themecolors}
              visable={true}
              onClose={this.onCloseModal}
              bodyPadding={10}
              headerActions={
                <Button
                  onClick={() =>
                    this.formSubmit(
                      this.state.uploadedFile,
                      this.props.fileName
                    )
                  }
                  disabled={this.state.isSubmit}
                >
                  {this.props.translate("Submit")}
                </Button>
              }
              isVisableBtn
              noFullScreen
            >
              <label
                style={{
                  padding: 10,
                  border: `1px dashed`,
                  borderRadius: 6,
                  marginBottom: 20,
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <div style={{ marginRight: "auto" }}>
                  {fileName !== "geofences"
                    ? this.state.selectedFile || "Upload Excel"
                    : this.state.selectedFile || "Circle Geofences Upload"}
                  <input
                    type="file"
                    onChange={(e) => this.uploadFile(e)}
                    hidden
                  />
                </div>
                <Button component="span">browse file</Button>
              </label>
              <RadioGroup
                aria-label="selectedDate"
                name="selectedDate"
                value={this.state.insertionMode}
                onChange={(e) => this.selectInsertionMode(e)}
                row
              >
                <FormControlLabel
                  classes={{
                    label: "form-contol-label",
                  }}
                  value={"1"}
                  control={<Radio size="small" />}
                  label={this.props.translate("addNewData")}
                  labelPlacement="end"
                />
                {fileName !== "geofences" && fileName !== "services" && (
                  <FormControlLabel
                    classes={{
                      label: "form-contol-label",
                    }}
                    value={"2"}
                    control={<Radio size="small" />}
                    label={this.props.translate("addNewAndUpdate")}
                    labelPlacement="end"
                  />
                )}
              </RadioGroup>
            </CustomDialog>
          )}
        </div>
      </Style>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  logInUser: state.logInUsers,
  filterList:
    state.filterList && state.filterList.filters
      ? state.filterList.filters
      : [],
});

export default connect(mapStateToProps)(
  withTranslationWrapper(ExportImportMenu)
);
