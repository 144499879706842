import React from "react";
import Panel from "./../common/Panel";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import ImageList from "@mui/material/ImageList";
import ListItemText from "@mui/material/ListItemText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { connect } from "react-redux";
import { userAttributesTypes } from "./../Attributes/AttributesArrays";
import withTranslationWrapper from "../../HOC/withTranslation";

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    position: "relative",
    // padding: theme.spacing(1) * 2,
    color: theme.palette.text.secondary,
    // marginBottom: theme.spacing(1) * 2,
  },
  nested: {
    // paddingLeft: theme.spacing(1) * 4,
  },
});
class editItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: [],
    };
    this.formatObject = this.formatObject.bind(this);
  }

  componentDidMount() {
    this.setState({ selectedItem: this.formatObject(this.props) });
  }
  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops) {
      this.setState({ selectedItem: this.formatObject(nextprops) });
    }
  }
  drawValue(item, key, classes) {
    let mainKey = "";
    switch (item.key) {
      case "name":
        mainKey = this.props.translate("sharedName");
        break;
      case "login":
        mainKey = this.props.translate("loginTitle");
        break;
      case "email":
        mainKey = this.props.translate("userEmail");
        break;
      case "phone":
        mainKey = this.props.translate("sharedPhone");
        break;
      case "readonly":
        mainKey = this.props.translate("serverReadonly");
        break;
      case "administrator":
        mainKey = this.props.translate("userAdmin");
        break;
      case "map":
        mainKey = this.props.translate("mapTitle");
        break;
      case "twelveHourFormat":
        mainKey = this.props.translate("settingsTwelveHourFormat");
        break;
      case "coordinateFormat":
        mainKey = this.props.translate("settingsCoordinateFormat");
        break;
      case "disabled":
        mainKey = this.props.translate("sharedDisabled");
        break;
      case "expirationTime":
        mainKey = this.props.translate("userExpirationTime");
        break;
      case "deviceReadonly":
        mainKey = this.props.translate("userDeviceReadonly");
        break;
      case "token":
        mainKey = this.props.translate("userToken");
        break;
      case "limitCommands":
        mainKey = this.props.translate("userLimitCommands");
        break;
      case "poiLayer":
        mainKey = this.props.translate("mapPoiLayer");
        break;
      case "password":
        mainKey = this.props.translate("attributeDevicePassword");
        break;
      case "attributes":
        mainKey = this.props.translate("sharedAttributes");
        break;
      default:
        mainKey = this.props.translate(item.key);
        break;
    }
    if (typeof item.value === "string" || item.value === null) {
      return (
        <Grid item xs={12} sm={6} md={4} key={key}>
          <ListItemText
            primary={item.value || "null"}
            secondary={mainKey}
          ></ListItemText>
        </Grid>
      );
    }
    if (typeof item.value === "boolean") {
      return (
        <Grid item xs={12} sm={6} md={4} key={key}>
          <ListItemText
            primary={item.value ? "Yes" : "No"}
            secondary={mainKey}
          ></ListItemText>
        </Grid>
      );
    } else if (typeof item.value === "object") {
      let o = Object.keys(item.value);

      let h = null;
      if (o.length) {
        h = o.map((k) => {
          if (
            k !== "serviceTitle" &&
            k !== "footerText" &&
            k !== "privacyPolicyLink" &&
            k !== "termsLink"
          ) {
            const selectedTrans = userAttributesTypes.filter(
              (el) => el.key === k
            );
            if (typeof item.value[k] === "boolean") {
              return (
                <TableRow key={k} style={{ height: 36 }}>
                  <TableCell component="th" width="250">
                    {selectedTrans && selectedTrans[0]
                      ? this.props.translate(selectedTrans[0].name)
                      : k}
                  </TableCell>
                  <TableCell component="td">
                    <strong
                      style={{ color: "rgba(0, 0, 0, 0.87)", fontWeight: 600 }}
                    >
                      {item.value[k] ? "Yes" : "No"}
                    </strong>
                  </TableCell>
                </TableRow>
              );
            } else {
              return (
                <TableRow key={k} style={{ height: 36 }}>
                  <TableCell component="th">
                    {selectedTrans && selectedTrans[0]
                      ? this.props.translate(selectedTrans[0].name)
                      : k}
                  </TableCell>
                  <TableCell component="td">
                    <strong
                      style={{ color: "rgba(0, 0, 0, 0.87)", fontWeight: 600 }}
                    >
                      {item.value[k]}
                    </strong>
                  </TableCell>
                </TableRow>
              );
            }
          }
          return null;
        });
        let head = (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={key}
            style={{ display: "block" }}
          >
            <Panel maxWidth={400} title={mainKey} bodyPadding={0}>
              <Table>
                <TableBody>{h}</TableBody>
              </Table>
            </Panel>
          </Grid>
        );
        return head;
      }
      return null;
    } else {
      return null;
    }
  }

  formatObject(props) {
    if (props.selectedItem) {
      let normalValues = [];
      let objValues = [];
      Object.keys(props.selectedItem).map((itm, key) => {
        if (
          typeof props.selectedItem[itm] !== "object" ||
          props.selectedItem[itm] === null
        ) {
          normalValues.push({ key: itm, value: props.selectedItem[itm] });
        } else {
          objValues.push({ key: itm, value: props.selectedItem[itm] });
        }

        return null;
      });
      return normalValues.concat(objValues);
    } else {
      return [];
    }
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Grid container spacing={0}>
            <Grid
              item
              md={12}
              style={{ position: "relative", paddingRight: 16 }}
            >
              <h2 style={{ margin: "5px", marginBottom: "15px" }}>
                {this.props.translate("usersDetails")}
              </h2>
              <ImageList cellHeight={"auto"} cols={3} spacing={2}>
                {this.state.selectedItem &&
                  this.state.selectedItem.map((item, key) =>
                    this.drawValue(item, key, classes)
                  )}
              </ImageList>
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}

editItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapState = (state) => {
  return {
    users: state.users,
    logInUser: state.logInUsers,
    themecolors: state.themeColors,
  };
};
const mapStateToProps = connect(mapState);
export const EditItem = mapStateToProps(
  withTranslationWrapper(withStyles(styles)(editItem))
);
