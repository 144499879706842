import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import Radio from "@mui/material/Radio";
import { connect } from "react-redux";

let themecolors;
const mapStateToProps = (state, ownProps) => {
  themecolors = state.themeColors;
  return {
    themecolors: state.themeColors,
  };
};
const styles2222 = (theme) => ({
  root: {
    color: themecolors.textColor,
    "&$checked": {
      color: themecolors.textColor,
    },
  },
  checked: {},
});

class RadioLabels extends Component {
  render() {
    const { classes, dispatch, ...others } = this.props;
    return (
      <Radio
        {...others}
        classes={{
          root: classes.root,
          checked: classes.checked,
        }}
      />
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles2222)(RadioLabels));
