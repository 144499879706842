import React, { PureComponent } from "react";
import TextField from "../../../../../common/TextField";
import Checkbox from "../../../../../common/Checkbox";
import MenuItem from "../../../../../common/MenuItem";
import "../styles/index.css";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import moment from "moment";
import Button from "../../../../../common/Button";
import { prepareCalendar, intervals } from "../../../../../../Helpers";

const format = "YYYY-MM-DD HH:mm";

const initState = {
  slots: [
    {
      startTime: moment().startOf("day").add(15, "minutes").format(format),
      endTime: moment().endOf("day").add(-14, "minutes").format(format),
    },
  ],
  weeklySlots: {
    sa: {
      startTime: moment().startOf("day").add(15, "minutes").format(format),
      endTime: moment().endOf("day").add(-14, "minutes").format(format),
    },
    su: {
      startTime: moment().startOf("day").add(15, "minutes").format(format),
      endTime: moment().endOf("day").add(-14, "minutes").format(format),
    },
    mo: {
      startTime: moment().startOf("day").add(15, "minutes").format(format),
      endTime: moment().endOf("day").add(-14, "minutes").format(format),
    },
    tu: {
      startTime: moment().startOf("day").add(15, "minutes").format(format),
      endTime: moment().endOf("day").add(-14, "minutes").format(format),
    },
    we: {
      startTime: moment().startOf("day").add(15, "minutes").format(format),
      endTime: moment().endOf("day").add(-14, "minutes").format(format),
    },
    th: {
      startTime: moment().startOf("day").add(15, "minutes").format(format),
      endTime: moment().endOf("day").add(-14, "minutes").format(format),
    },
    fr: {
      startTime: moment().startOf("day").add(15, "minutes").format(format),
      endTime: moment().endOf("day").add(-14, "minutes").format(format),
    },
  },
  allDay: false,
  freq: "YEARLY",
  after: "NEVER",
  byDay: [],
  byMonth: [1, 2],
  byMonthDay: [1, 2],
  repeatBy: "1",
  interval: "1",
  name: "CAL-" + new Date().getTime(),
};

class List extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      items: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ],
      isShiftDown: false,
      selectedItems: props.value.length ? props.value : [],
      lastSelectedItem: null,
    };

    this.listEl = null;

    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
    this.handleSelectStart = this.handleSelectStart.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keyup", this.handleKeyUp, false);
    document.addEventListener("keydown", this.handleKeyDown, false);
    this.listEl.addEventListener("selectstart", this.handleSelectStart, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleKeyUp);
    document.removeEventListener("keydown", this.handleKeyDown);
    this.listEl.removeEventListener("selectstart", this.handleSelectStart);
  }

  handleSelectStart(e) {
    // if we're clicking the labels it'll select the text if holding shift
    if (this.state.isShiftDown) {
      e.preventDefault();
    }
  }

  handleKeyUp(e) {
    if (e.key === "Shift" && this.state.isShiftDown) {
      this.setState({ isShiftDown: false });
    }
  }

  handleKeyDown(e) {
    if (e.key === "Shift" && !this.state.isShiftDown) {
      this.setState({ isShiftDown: true });
    }
  }

  handleSelectItem(e, value) {
    const nextValue = this.getNextValue(value);

    this.setState({ selectedItems: nextValue, lastSelectedItem: value }, () =>
      this.props.handleChange(this.state.selectedItems, "byMonthDay")
    );
  }

  getNextValue(value) {
    const { isShiftDown, selectedItems } = this.state;
    const hasBeenSelected = !selectedItems.includes(value);

    if (isShiftDown) {
      const newSelectedItems = this.getNewSelectedItems(value);
      // de-dupe the array using a Set
      const selections = [...new Set([...selectedItems, ...newSelectedItems])];

      if (!hasBeenSelected) {
        return selections.filter((item) => !newSelectedItems.includes(item));
      }

      return selections;
    }

    // if it's already in there, remove it, otherwise append it
    return selectedItems.includes(value)
      ? selectedItems.filter((item) => item !== value)
      : [...selectedItems, value];
  }

  getNewSelectedItems(value) {
    const { lastSelectedItem, items } = this.state;
    const currentSelectedIndex = items.findIndex((item) => item === value);
    const lastSelectedIndex = items.findIndex(
      (item) => item === lastSelectedItem
    );

    return items.slice(
      Math.min(lastSelectedIndex, currentSelectedIndex),
      Math.max(lastSelectedIndex, currentSelectedIndex) + 1
    );
  }

  renderItems() {
    const { items, selectedItems, isShiftDown, lastSelectedItem } = this.state;
    return items.map((item) => {
      return (
        <li
          key={item}
          className={
            (isShiftDown && lastSelectedItem === item ? " highlight " : "") +
            (selectedItems.includes(item) ? " selected-day " : "")
          }
        >
          <input
            style={{ display: "none" }}
            onChange={(e) => this.handleSelectItem(e, item)}
            checked={selectedItems.includes(item)}
            type="checkbox"
            value={item}
            id={`item-${item}`}
          />
          <label htmlFor={`item-${item}`}>{item}</label>
        </li>
      );
    });
  }

  render() {
    return (
      <>
        <div className="fake-calendar-result">
          {this.state.selectedItems.length} days selected
        </div>
        <div className="clearfix" />
        <ul
          className="fake-calendar clearfix"
          ref={(node) => (this.listEl = node)}
        >
          {this.renderItems()}
        </ul>
      </>
    );
  }
}

class ReactRRuleGenerator extends PureComponent {
  // compute default view based on user's config
  constructor(props) {
    super(props);
    this.state = {
      data: initState,
      openRepeat: false,
      startTimeArray: [],
      endTimeArray: [],
    };
  }

  handleChange = (event, name, key) => {
    const { target } = event;
    const newData = JSON.parse(JSON.stringify(this.state.data));

    let value = "";

    if (name === "byDay") {
      let byDay = newData.byDay.filter((e) => key !== e);

      if (target.checked) {
        byDay.push(key);
      }
      value = byDay;
    } else if (name === "byMonthDay") {
      value = event;
    } else if (name === "byMonth") {
      let byMonth = newData.byMonth.filter((e) => key !== e);

      if (target.checked) {
        byMonth.push(key);
      }
      value = byMonth;
    } else if (name === "startTime" && newData.freq === "YEARLY") {
      newData.slots[key].startTime = event.target.value;
      newData.slots[key].endTime = moment(event.target.value)
        .add(15, "minutes")
        .format(format);
    } else if (name === "startTime" && newData.freq === "WEEKLY") {
      newData.weeklySlots[key].startTime = event.target.value;
      newData.weeklySlots[key].endTime = moment(event.target.value)
        .add(15, "minutes")
        .format(format);
    } else if (name === "allDay" && newData.freq === "WEEKLY") {
      newData.weeklySlots[key].allDay = event.target.checked;
    } else if (name === "endTime" && newData.freq === "YEARLY") {
      newData.slots[key].endTime = event.target.value;
    } else if (name === "endTime" && newData.freq === "WEEKLY") {
      newData.weeklySlots[key].endTime = event.target.value;
    } else if (name === "start" || name === "end" || name === "until") {
      if (name === "start") {
        value = moment(event).format("YYYY-MM-DD");

        if (newData.end < value) {
          newData.end = value;
        }

        newData.startTime =
          value + " " + moment(newData.startTime).format("HH:mm");
        newData.endTime =
          newData.end + " " + moment(newData.endTime).format("HH:mm");

        let start = moment(value).startOf("day").toISOString();
        let end = moment(value).endOf("day").toISOString();

        this.setState({
          startTimeArray: intervals(
            start,
            moment(end).add(-15, "minutes").toISOString()
          ),
          endTimeArray: intervals(
            moment(start).add(15, "minutes").toISOString(),
            end
          ),
        });
      }

      if (name === "end") {
        value = moment(event).format("YYYY-MM-DD");
        if (newData.start > value) {
          newData.start = value;
        }
      } else {
        value = moment(event).set("hour", 0).set("minute", 0).toISOString();
      }
    } else {
      value =
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value;
    }

    this.setState(
      {
        data: {
          ...newData,
          [name]: value,
        },
      },
      () => this.onSaveData()
    );
  };

  UNSAFE_componentWillMount() {
    if (this.props.form && this.props.form.schedul)
      this.setState({ data: { ...this.props.form.schedul } });
  }

  openRepeat = (openRepeat) => {
    this.setState({ openRepeat: !this.state.openRepeat });
  };

  onSaveData = () => {
    let g = prepareCalendar(this.state.data);
    if (g) {
      this.props.onSaveData(this.state.data, g);
    } else {
      alert("please fill empty Fields");
    }
  };

  attachCalendar = () => {
    this.setState(
      {
        data: initState,
      },
      () => {
        this.onSaveData();
        this.props.attachCalendar();
      }
    );
  };
  removeCalendar = () => {
    this.setState({ data: {} }, () => {
      this.props.removeCalendar();
    });
  };

  addSlot = () => {
    const slots = this.state.data.slots;
    slots.push({
      startTime: moment().startOf("day").format(format),
      endTime: moment().endOf("day").add(-14, "minutes").format(format),
    });
    this.setState({ data: { ...this.state.data, slots } });
  };

  deleteSlot = (index) => {
    const slots = this.state.data.slots;

    slots.splice(index, 1);

    this.setState({ data: { ...this.state.data, slots } });
  };

  updateFrequency = (freq) => {
    this.setState({ data: { ...this.state.data, freq } });
  };

  render() {
    const { allDay, freq, weeklySlots, byDay, byMonth, byMonthDay } =
      this.state.data;
    return this.props.attached ? (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12} className="inline-buttons">
            <Button
              onClick={(e) => this.updateFrequency("WEEKLY")}
              className={freq === "WEEKLY" ? "button-white" : ""}
            >
              Weekly
            </Button>
            <Button
              onClick={(e) => this.updateFrequency("YEARLY")}
              className={freq === "YEARLY" ? "button-white" : ""}
            >
              Custom
            </Button>
          </Grid>

          {freq === "YEARLY" && this.state.data.slots ? (
            <>
              <Grid item xs={12}>
                <Checkbox
                  id="allDay"
                  name="allDay"
                  checked={allDay}
                  onChange={(e) => this.handleChange(e, "allDay")}
                  value="allDay"
                  color="primary"
                  label={this.props.translate("allDay")}
                />
              </Grid>
              {!allDay &&
                this.state.data.slots.map((row, index) => (
                  <>
                    <Grid item md={3} xs={12}>
                      <TextField
                        id="startTime"
                        name="startTime"
                        select
                        value={row.startTime || ""}
                        onChange={(e) =>
                          this.handleChange(e, "startTime", index)
                        }
                        margin="dense"
                        label={this.props.translate("reportFrom")}
                        fullWidth
                      >
                        {intervals(
                          moment(row.startTime).startOf("day").toISOString(),
                          moment(row.startTime)
                            .endOf("day")
                            .add(-15, "minutes")
                            .toISOString()
                        ).map((option) => (
                          <MenuItem key={option.key} value={option.key}>
                            {option.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <TextField
                        name="endTime"
                        select
                        value={row.endTime || ""}
                        onChange={(e) => this.handleChange(e, "endTime", index)}
                        margin="dense"
                        label={this.props.translate("reportTo")}
                        fullWidth
                      >
                        {intervals(
                          moment(row.startTime)
                            .add(15, "minutes")
                            .toISOString(),
                          moment(row.startTime).endOf("day").toISOString()
                        ).map((option) => (
                          <MenuItem key={option.key} value={option.key}>
                            {option.value}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div style={{ marginBottom: 4, marginTop: 8 }}>
                        {index === 0 ? (
                          <Button onClick={this.addSlot}>
                            {this.props.translate("addAnotherSlot")}
                          </Button>
                        ) : (
                          <Button onClick={(e) => this.deleteSlot(index)}>
                            {this.props.translate("removeSlot")}
                          </Button>
                        )}
                      </div>
                    </Grid>
                  </>
                ))}
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <span
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                      }}
                    >
                      {this.props.translate("selectDays")}
                    </span>
                    <Tooltip
                      classes={{
                        popper: "menu-popper",
                        tooltip: "menu-popper-tooltip",
                      }}
                      title={
                        <div>
                          <div style={{ marginBottom: 10 }}>
                            {this.props.translate("timeControlInstruction1")}
                          </div>
                          <div>
                            {this.props.translate("timeControlInstruction2")}
                          </div>
                        </div>
                      }
                    >
                      <HelpIcon
                        style={{ marginLeft: 8, verticalAlign: "middle" }}
                      />
                    </Tooltip>
                    <List value={byMonthDay} handleChange={this.handleChange} />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    style={{ alignItems: "center", display: "flex" }}
                  >
                    {this.props.translate("selectMonths")}
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <div style={{ marginRight: 10, marginBottom: 10 }}>
                      <Checkbox
                        checked={byMonth.includes(1)}
                        onChange={(e) => this.handleChange(e, "byMonth", 1)}
                        name="Jan"
                        color="primary"
                        label={this.props.translate("Jan")}
                      />
                    </div>
                    <div style={{ marginRight: 10, marginBottom: 10 }}>
                      <Checkbox
                        checked={byMonth.includes(2)}
                        onChange={(e) => this.handleChange(e, "byMonth", 2)}
                        name="Feb"
                        color="primary"
                        label={this.props.translate("Feb")}
                      />
                    </div>
                    <div style={{ marginRight: 10, marginBottom: 10 }}>
                      <Checkbox
                        checked={byMonth.includes(3)}
                        onChange={(e) => this.handleChange(e, "byMonth", 3)}
                        name="Mar"
                        color="primary"
                        label={this.props.translate("Mar")}
                      />
                    </div>
                    <div style={{ marginRight: 10, marginBottom: 10 }}>
                      <Checkbox
                        checked={byMonth.includes(4)}
                        onChange={(e) => this.handleChange(e, "byMonth", 4)}
                        name="Apr"
                        color="primary"
                        label={this.props.translate("Apr")}
                      />
                    </div>
                    <div style={{ marginRight: 10, marginBottom: 10 }}>
                      <Checkbox
                        checked={byMonth.includes(5)}
                        onChange={(e) => this.handleChange(e, "byMonth", 5)}
                        name="May"
                        color="primary"
                        label={this.props.translate("May")}
                      />
                    </div>
                    <div style={{ marginRight: 10, marginBottom: 10 }}>
                      <Checkbox
                        checked={byMonth.includes(6)}
                        onChange={(e) => this.handleChange(e, "byMonth", 6)}
                        name="Jun"
                        color="primary"
                        label={this.props.translate("Jun")}
                      />
                    </div>
                    <div style={{ marginRight: 10, marginBottom: 10 }}>
                      <Checkbox
                        checked={byMonth.includes(7)}
                        onChange={(e) => this.handleChange(e, "byMonth", 7)}
                        name="Jul"
                        color="primary"
                        label={this.props.translate("Jul")}
                      />
                    </div>
                    <div style={{ marginRight: 10, marginBottom: 10 }}>
                      <Checkbox
                        checked={byMonth.includes(8)}
                        onChange={(e) => this.handleChange(e, "byMonth", 8)}
                        name="Aug"
                        color="primary"
                        label={this.props.translate("Aug")}
                      />
                    </div>
                    <div style={{ marginRight: 10, marginBottom: 10 }}>
                      <Checkbox
                        checked={byMonth.includes(9)}
                        onChange={(e) => this.handleChange(e, "byMonth", 9)}
                        name="Sep"
                        color="primary"
                        label={this.props.translate("Sep")}
                      />
                    </div>
                    <div style={{ marginRight: 10, marginBottom: 10 }}>
                      <Checkbox
                        checked={byMonth.includes(10)}
                        onChange={(e) => this.handleChange(e, "byMonth", 10)}
                        name="Oct"
                        color="primary"
                        label={this.props.translate("Oct")}
                      />
                    </div>
                    <div style={{ marginRight: 10, marginBottom: 10 }}>
                      <Checkbox
                        checked={byMonth.includes(11)}
                        onChange={(e) => this.handleChange(e, "byMonth", 11)}
                        name="Nov"
                        color="primary"
                        label={this.props.translate("Nov")}
                      />
                    </div>
                    <div style={{ marginRight: 10, marginBottom: 10 }}>
                      <Checkbox
                        checked={byMonth.includes(12)}
                        onChange={(e) => this.handleChange(e, "byMonth", 12)}
                        name="Dec"
                        color="primary"
                        label={this.props.translate("Dec")}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}
          {freq === "WEEKLY" && weeklySlots ? (
            <>
              <Grid
                item
                xs={12}
                style={{ alignItems: "center", display: "flex" }}
              >
                {this.props.translate("repeatOn")}
              </Grid>
              <Grid item xs={12}>
                <div style={{ marginRight: 10, marginBottom: 10 }}>
                  <Grid container spacing={0}>
                    <Grid
                      style={{ alignItems: "center", display: "flex" }}
                      item
                      md={3}
                      xs={12}
                    >
                      <Checkbox
                        checked={byDay.includes("sa")}
                        onChange={(e) => this.handleChange(e, "byDay", "sa")}
                        value="sa"
                        color="primary"
                        label={this.props.translate("Saturday")}
                      />
                    </Grid>
                    {byDay.includes("sa") ? (
                      <>
                        {!weeklySlots.sa.allDay && (
                          <>
                            <Grid item md={3} xs={12}>
                              <TextField
                                id="startTime"
                                name="startTime"
                                select
                                value={weeklySlots.sa.startTime || ""}
                                onChange={(e) =>
                                  this.handleChange(e, "startTime", "sa")
                                }
                                margin="dense"
                                label={this.props.translate("reportFrom")}
                                fullWidth
                              >
                                {intervals(
                                  moment(weeklySlots.sa.startTime)
                                    .startOf("day")
                                    .add(15, "minutes")
                                    .toISOString(),
                                  moment(weeklySlots.sa.startTime)
                                    .endOf("day")
                                    .add(-15, "minutes")
                                    .toISOString()
                                ).map((option) => (
                                  <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              style={{
                                paddingLeft: 16,
                              }}
                            >
                              <TextField
                                name="endTime"
                                select
                                value={weeklySlots.sa.endTime || ""}
                                onChange={(e) =>
                                  this.handleChange(e, "endTime", "sa")
                                }
                                margin="dense"
                                label={this.props.translate("reportTo")}
                                fullWidth
                              >
                                {intervals(
                                  moment(weeklySlots.sa.startTime)
                                    .add(15, "minutes")
                                    .toISOString(),
                                  moment(weeklySlots.sa.startTime)
                                    .endOf("day")
                                    .toISOString()
                                ).map((option) => (
                                  <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </>
                        )}
                        <Grid
                          style={{
                            alignItems: "center",
                            display: "flex",
                            paddingLeft: 16,
                          }}
                          item
                          md={3}
                          xs={12}
                        >
                          <Checkbox
                            checked={weeklySlots.sa.allDay}
                            onChange={(e) =>
                              this.handleChange(e, "allDay", "sa")
                            }
                            color="primary"
                            label={this.props.translate("allDay")}
                          />
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </div>
                <div style={{ marginRight: 10, marginBottom: 10 }}>
                  <Grid container spacing={0}>
                    <Grid
                      style={{ alignItems: "center", display: "flex" }}
                      item
                      md={3}
                      xs={12}
                    >
                      <Checkbox
                        checked={byDay.includes("su")}
                        onChange={(e) => this.handleChange(e, "byDay", "su")}
                        value="su"
                        color="primary"
                        label={this.props.translate("Sunday")}
                      />
                    </Grid>
                    {byDay.includes("su") ? (
                      <>
                        {!weeklySlots.su.allDay && (
                          <>
                            <Grid item md={3} xs={12}>
                              <TextField
                                id="startTime"
                                name="startTime"
                                select
                                value={weeklySlots.su.startTime || ""}
                                onChange={(e) =>
                                  this.handleChange(e, "startTime", "su")
                                }
                                margin="dense"
                                label={this.props.translate("reportFrom")}
                                fullWidth
                              >
                                {intervals(
                                  moment(weeklySlots.su.startTime)
                                    .startOf("day")
                                    .add(15, "minutes")
                                    .toISOString(),
                                  moment(weeklySlots.su.startTime)
                                    .endOf("day")
                                    .add(-15, "minutes")
                                    .toISOString()
                                ).map((option) => (
                                  <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              style={{
                                paddingLeft: 16,
                              }}
                            >
                              <TextField
                                name="endTime"
                                select
                                value={weeklySlots.su.endTime || ""}
                                onChange={(e) =>
                                  this.handleChange(e, "endTime", "su")
                                }
                                margin="dense"
                                label={this.props.translate("reportTo")}
                                fullWidth
                              >
                                {intervals(
                                  moment(weeklySlots.su.startTime)
                                    .add(15, "minutes")
                                    .toISOString(),
                                  moment(weeklySlots.su.startTime)
                                    .endOf("day")
                                    .toISOString()
                                ).map((option) => (
                                  <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </>
                        )}
                        <Grid
                          style={{
                            alignItems: "center",
                            display: "flex",
                            paddingLeft: 16,
                          }}
                          item
                          md={3}
                          xs={12}
                        >
                          <Checkbox
                            checked={weeklySlots.su.allDay}
                            onChange={(e) =>
                              this.handleChange(e, "allDay", "su")
                            }
                            color="primary"
                            label={this.props.translate("allDay")}
                          />
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </div>
                <div style={{ marginRight: 10, marginBottom: 10 }}>
                  <Grid container spacing={0}>
                    <Grid
                      style={{ alignItems: "center", display: "flex" }}
                      item
                      md={3}
                      xs={12}
                    >
                      <Checkbox
                        checked={byDay.includes("mo")}
                        onChange={(e) => this.handleChange(e, "byDay", "mo")}
                        value="mo"
                        color="primary"
                        label={this.props.translate("Monday")}
                      />
                    </Grid>
                    {byDay.includes("mo") ? (
                      <>
                        {!weeklySlots.mo.allDay && (
                          <>
                            <Grid item md={3} xs={12}>
                              <TextField
                                id="startTime"
                                name="startTime"
                                select
                                value={weeklySlots.mo.startTime || ""}
                                onChange={(e) =>
                                  this.handleChange(e, "startTime", "mo")
                                }
                                margin="dense"
                                label={this.props.translate("reportFrom")}
                                fullWidth
                              >
                                {intervals(
                                  moment(weeklySlots.mo.startTime)
                                    .startOf("day")
                                    .add(15, "minutes")
                                    .toISOString(),
                                  moment(weeklySlots.mo.startTime)
                                    .endOf("day")
                                    .add(-15, "minutes")
                                    .toISOString()
                                ).map((option) => (
                                  <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              style={{
                                paddingLeft: 16,
                              }}
                            >
                              <TextField
                                name="endTime"
                                select
                                value={weeklySlots.mo.endTime || ""}
                                onChange={(e) =>
                                  this.handleChange(e, "endTime", "mo")
                                }
                                margin="dense"
                                label={this.props.translate("reportTo")}
                                fullWidth
                              >
                                {intervals(
                                  moment(weeklySlots.mo.startTime)
                                    .add(15, "minutes")
                                    .toISOString(),
                                  moment(weeklySlots.mo.startTime)
                                    .endOf("day")
                                    .toISOString()
                                ).map((option) => (
                                  <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </>
                        )}
                        <Grid
                          style={{
                            alignItems: "center",
                            display: "flex",
                            paddingLeft: 16,
                          }}
                          item
                          md={3}
                          xs={12}
                        >
                          <Checkbox
                            checked={weeklySlots.mo.allDay}
                            onChange={(e) =>
                              this.handleChange(e, "allDay", "mo")
                            }
                            color="primary"
                            label={this.props.translate("allDay")}
                          />
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </div>

                <div style={{ marginRight: 10, marginBottom: 10 }}>
                  <Grid container spacing={0}>
                    <Grid
                      style={{ alignItems: "center", display: "flex" }}
                      item
                      md={3}
                      xs={12}
                    >
                      <Checkbox
                        checked={byDay.includes("tu")}
                        onChange={(e) => this.handleChange(e, "byDay", "tu")}
                        value="tu"
                        color="primary"
                        label={this.props.translate("Tuesday")}
                      />
                    </Grid>
                    {byDay.includes("tu") ? (
                      <>
                        {!weeklySlots.tu.allDay && (
                          <>
                            <Grid item md={3} xs={12}>
                              <TextField
                                id="startTime"
                                name="startTime"
                                select
                                value={weeklySlots.tu.startTime || ""}
                                onChange={(e) =>
                                  this.handleChange(e, "startTime", "tu")
                                }
                                margin="dense"
                                label={this.props.translate("reportFrom")}
                                fullWidth
                              >
                                {intervals(
                                  moment(weeklySlots.tu.startTime)
                                    .startOf("day")
                                    .add(15, "minutes")
                                    .toISOString(),
                                  moment(weeklySlots.tu.startTime)
                                    .endOf("day")
                                    .add(-15, "minutes")
                                    .toISOString()
                                ).map((option) => (
                                  <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              style={{
                                paddingLeft: 16,
                              }}
                            >
                              <TextField
                                name="endTime"
                                select
                                value={weeklySlots.tu.endTime || ""}
                                onChange={(e) =>
                                  this.handleChange(e, "endTime", "tu")
                                }
                                margin="dense"
                                label={this.props.translate("reportTo")}
                                fullWidth
                              >
                                {intervals(
                                  moment(weeklySlots.tu.startTime)
                                    .add(15, "minutes")
                                    .toISOString(),
                                  moment(weeklySlots.tu.startTime)
                                    .endOf("day")
                                    .toISOString()
                                ).map((option) => (
                                  <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </>
                        )}
                        <Grid
                          style={{
                            alignItems: "center",
                            display: "flex",
                            paddingLeft: 16,
                          }}
                          item
                          md={3}
                          xs={12}
                        >
                          <Checkbox
                            checked={weeklySlots.tu.allDay}
                            onChange={(e) =>
                              this.handleChange(e, "allDay", "tu")
                            }
                            color="primary"
                            label={this.props.translate("allDay")}
                          />
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </div>

                <div style={{ marginRight: 10, marginBottom: 10 }}>
                  <Grid container spacing={0}>
                    <Grid
                      style={{ alignItems: "center", display: "flex" }}
                      item
                      md={3}
                      xs={12}
                    >
                      <Checkbox
                        checked={byDay.includes("we")}
                        onChange={(e) => this.handleChange(e, "byDay", "we")}
                        value="we"
                        color="primary"
                        label={this.props.translate("Wednesday")}
                      />
                    </Grid>
                    {byDay.includes("we") ? (
                      <>
                        {!weeklySlots.we.allDay && (
                          <>
                            <Grid item md={3} xs={12}>
                              <TextField
                                id="startTime"
                                name="startTime"
                                select
                                value={weeklySlots.we.startTime || ""}
                                onChange={(e) =>
                                  this.handleChange(e, "startTime", "we")
                                }
                                margin="dense"
                                label={this.props.translate("reportFrom")}
                                fullWidth
                              >
                                {intervals(
                                  moment(weeklySlots.we.startTime)
                                    .startOf("day")
                                    .add(15, "minutes")
                                    .toISOString(),
                                  moment(weeklySlots.we.startTime)
                                    .endOf("day")
                                    .add(-15, "minutes")
                                    .toISOString()
                                ).map((option) => (
                                  <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              style={{
                                paddingLeft: 16,
                              }}
                            >
                              <TextField
                                name="endTime"
                                select
                                value={weeklySlots.we.endTime || ""}
                                onChange={(e) =>
                                  this.handleChange(e, "endTime", "we")
                                }
                                margin="dense"
                                label={this.props.translate("reportTo")}
                                fullWidth
                              >
                                {intervals(
                                  moment(weeklySlots.we.startTime)
                                    .add(15, "minutes")
                                    .toISOString(),
                                  moment(weeklySlots.we.startTime)
                                    .endOf("day")
                                    .toISOString()
                                ).map((option) => (
                                  <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </>
                        )}
                        <Grid
                          style={{
                            alignItems: "center",
                            display: "flex",
                            paddingLeft: 16,
                          }}
                          item
                          md={3}
                          xs={12}
                        >
                          <Checkbox
                            checked={weeklySlots.we.allDay}
                            onChange={(e) =>
                              this.handleChange(e, "allDay", "we")
                            }
                            color="primary"
                            label={this.props.translate("allDay")}
                          />
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </div>
                <div style={{ marginRight: 10, marginBottom: 10 }}>
                  <Grid container spacing={0}>
                    <Grid
                      style={{ alignItems: "center", display: "flex" }}
                      item
                      md={3}
                      xs={12}
                    >
                      <Checkbox
                        checked={byDay.includes("th")}
                        onChange={(e) => this.handleChange(e, "byDay", "th")}
                        value="th"
                        color="primary"
                        label={this.props.translate("Thursday")}
                      />
                    </Grid>
                    {byDay.includes("th") ? (
                      <>
                        {!weeklySlots.th.allDay && (
                          <>
                            <Grid item md={3} xs={12}>
                              <TextField
                                id="startTime"
                                name="startTime"
                                select
                                value={weeklySlots.th.startTime || ""}
                                onChange={(e) =>
                                  this.handleChange(e, "startTime", "th")
                                }
                                margin="dense"
                                label={this.props.translate("reportFrom")}
                                fullWidth
                              >
                                {intervals(
                                  moment(weeklySlots.th.startTime)
                                    .startOf("day")
                                    .add(15, "minutes")
                                    .toISOString(),
                                  moment(weeklySlots.th.startTime)
                                    .endOf("day")
                                    .add(-15, "minutes")
                                    .toISOString()
                                ).map((option) => (
                                  <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              style={{
                                paddingLeft: 16,
                              }}
                            >
                              <TextField
                                name="endTime"
                                select
                                value={weeklySlots.th.endTime || ""}
                                onChange={(e) =>
                                  this.handleChange(e, "endTime", "th")
                                }
                                margin="dense"
                                label={this.props.translate("reportTo")}
                                fullWidth
                              >
                                {intervals(
                                  moment(weeklySlots.th.startTime)
                                    .add(15, "minutes")
                                    .toISOString(),
                                  moment(weeklySlots.th.startTime)
                                    .endOf("day")
                                    .toISOString()
                                ).map((option) => (
                                  <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </>
                        )}
                        <Grid
                          style={{
                            alignItems: "center",
                            display: "flex",
                            paddingLeft: 16,
                          }}
                          item
                          md={3}
                          xs={12}
                        >
                          <Checkbox
                            checked={weeklySlots.th.allDay}
                            onChange={(e) =>
                              this.handleChange(e, "allDay", "th")
                            }
                            color="primary"
                            label={this.props.translate("allDay")}
                          />
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </div>
                <div style={{ marginRight: 10, marginBottom: 10 }}>
                  <Grid container spacing={0}>
                    <Grid
                      style={{ alignItems: "center", display: "flex" }}
                      item
                      md={3}
                      xs={12}
                    >
                      <Checkbox
                        checked={byDay.includes("fr")}
                        onChange={(e) => this.handleChange(e, "byDay", "fr")}
                        value="fr"
                        color="primary"
                        label={this.props.translate("Friday")}
                      />
                    </Grid>
                    {byDay.includes("fr") ? (
                      <>
                        {!weeklySlots.fr.allDay && (
                          <>
                            <Grid item md={3} xs={12}>
                              <TextField
                                id="startTime"
                                name="startTime"
                                select
                                value={weeklySlots.fr.startTime || ""}
                                onChange={(e) =>
                                  this.handleChange(e, "startTime", "fr")
                                }
                                margin="dense"
                                label={this.props.translate("reportFrom")}
                                fullWidth
                              >
                                {intervals(
                                  moment(weeklySlots.fr.startTime)
                                    .startOf("day")
                                    .add(15, "minutes")
                                    .toISOString(),
                                  moment(weeklySlots.fr.startTime)
                                    .endOf("day")
                                    .add(-15, "minutes")
                                    .toISOString()
                                ).map((option) => (
                                  <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                            <Grid
                              item
                              md={3}
                              xs={12}
                              style={{
                                paddingLeft: 16,
                              }}
                            >
                              <TextField
                                name="endTime"
                                select
                                value={weeklySlots.fr.endTime || ""}
                                onChange={(e) =>
                                  this.handleChange(e, "endTime", "fr")
                                }
                                margin="dense"
                                label={this.props.translate("reportTo")}
                                fullWidth
                              >
                                {intervals(
                                  moment(weeklySlots.fr.startTime)
                                    .add(15, "minutes")
                                    .toISOString(),
                                  moment(weeklySlots.fr.startTime)
                                    .endOf("day")
                                    .toISOString()
                                ).map((option) => (
                                  <MenuItem key={option.key} value={option.key}>
                                    {option.value}
                                  </MenuItem>
                                ))}
                              </TextField>
                            </Grid>
                          </>
                        )}

                        <Grid
                          style={{
                            alignItems: "center",
                            display: "flex",
                            paddingLeft: 16,
                          }}
                          item
                          md={3}
                          xs={12}
                        >
                          <Checkbox
                            checked={weeklySlots.fr.allDay}
                            onChange={(e) =>
                              this.handleChange(e, "allDay", "fr")
                            }
                            color="primary"
                            label={this.props.translate("allDay")}
                          />
                        </Grid>
                      </>
                    ) : null}
                  </Grid>
                </div>
              </Grid>
            </>
          ) : null}
          <Grid item md={12} xs={12}>
            <Button onClick={this.removeCalendar}>
              {this.props.translate("removeTimecontrol")}
            </Button>
          </Grid>
        </Grid>
      </>
    ) : (
      <Button onClick={this.attachCalendar}>
        {this.props.translate("attachTimecontrol")}
      </Button>
    );
  }
}

export default ReactRRuleGenerator;
