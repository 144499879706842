import React, { Component } from "react";
import TextField from "../../../Components/common/TextField";
import Grid from "@mui/material/Grid";
import Button from "../../../Components/common/Button";
import Table from "../../../Components/common/TableMultiCheckbox";
import Strings from "./../../../lang/en.json";
import SingleSelect from "../../../Components/common/SingleSelect";
import { checkPrivileges } from "../../../Helpers";
import CustomDatePicker from "../../../Components/common/CustomDatePicker"
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "../../../Components/common/Checkbox";
import { SettingForm } from "../../Maintenance/SettingForm";

export default class Maintenance extends Component {
  constructor(props) {
    super(props);
    this.maintenance_handleChange = this.maintenance_handleChange.bind(this);
  }

  maintenance_handleChange(name, value) {
    if (this.props.maintenancesHandle) {
      this.props.maintenancesHandle(name, value);
    }
  }

  render() {
    const periodLabel =
      this.props.maintenanceForm.type === "hours"
        ? "hours"
        : this.props.maintenanceForm.type === "date"
        ? "days"
        : "km";
    return (
      <Grid container style={{ height: "100%" }}>
        <Grid item sm={this.props.visableCreateMaintenance ? 7 : 12} xs={12}>
          <Table
            insideDialog
            createButton={
              checkPrivileges("maintenanceCreate") && (
                <div style={{ marginLeft: 10 }}>
                  <Button onClick={() => this.props.onCreateNewMaintenance()}>
                    {this.props.translate("Create")}
                  </Button>
                </div>
              )
            }
            title={this.props.translate("sharedMaintenance")}
            disableHead
            showCheckbox={this.props.isAllowed}
            checkHandleChange={this.props.maintenance_Selection}
            rows={this.props.maintenanceData}
            isEditable
            canRemove={this.props.canAssign}
            canAssign={this.props.canRemove}
            canUpdate={checkPrivileges("maintenanceUpdate")}
            canDelete={checkPrivileges("maintenanceDelete")}
            onEdit={this.props.onEdit}
            onDelete={this.props.onDelete}
            className="custom-table2"
            rowsPerPage={this.props.rowsPerPage}
            handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
            selectAll={this.props.selectAll}
            searchable
            rowDefinition={[
              {
                id: "name",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("sharedName"),
              },
              {
                id: "deviceName",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("sharedDevice"),
              },
              {
                id: "attributes.mileage",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("targetValue"),
                translate: true,
                colspan: 3,
              },
              {
                id: "attributes.repeat",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("repeat"),
              },
              {
                id: "cost",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("cost"),
              },
              // {
              //   id: 'progress',
              //   numeric: false,
              //   disablePadding: false,
              //   label: this.props.translate('progress')
              // }
            ]}
          />
        </Grid>
        {this.props.visableCreateMaintenance && (
          <Grid item sm={5} xs={12}>
            <div
              style={{
                padding: "1px 20px",
                borderLeft:
                  "1px solid " + this.props.themecolors.themeLightColor,
                height: "100%",
              }}
            >
              <h4>{this.props.translate("sharedMaintenance")}</h4>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    margin="dense"
                    label={this.props.translate("sharedName")}
                    variant="outlined"
                    fullWidth
                    value={this.props.maintenanceForm.name}
                    onChange={this.props.maintenance_handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SingleSelect
                    array={postionAttributes}
                    selectName="type"
                    label={this.props.translate("sharedType")}
                    value={
                      this.props.maintenanceForm.type
                        ? {
                            key: this.props.maintenanceForm.type,
                            id: this.props.maintenanceForm.type,
                            value: this.props.maintenanceForm.type,
                            label: this.props.translate(
                              this.props.maintenanceForm.type
                            ),
                          }
                        : ""
                    }
                    handleChange={this.maintenance_handleChange}
                    canRemove={
                      checkPrivileges("maintenanceCreate") ||
                      checkPrivileges("maintenanceUpdate")
                    }
                    canAssign={
                      checkPrivileges("maintenanceCreate") ||
                      checkPrivileges("maintenanceUpdate")
                    }
                  />
                </Grid>
                {this.props.maintenanceForm.type === "date" ? (
                  <Grid item xs={12}>
                  <CustomDatePicker
                        format="dd/MM/yyyy"
                        margin="dense"
                        label={this.props.translate('startDate')}
                        variant="outlined"
                        fullWidth
                        // minDate={new Date()}
                        value={
                          this.props.maintenanceForm.attributes &&
                          this.props.maintenanceForm.attributes.startDate
                            ? 
                                `${this.props.maintenanceForm.attributes.startDate}`
                            : null
                        }
                        onChange={this.props.maintenance_handleChange_attr(
                          'startDate'
                        )}
                      />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <TextField
                      id="start"
                      type="number"
                      margin="dense"
                      label={this.props.translate("maintenanceStart")}
                      variant="outlined"
                      fullWidth
                      value={this.props.maintenanceForm.start}
                      onChange={this.props.maintenance_handleChange("start")}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    id="period"
                    type="number"
                    margin="dense"
                    label={
                      this.props.translate("maintenancePeriod") +
                      " (" +
                      periodLabel +
                      ")"
                    }
                    variant="outlined"
                    fullWidth
                    value={this.props.maintenanceForm.period}
                    onChange={this.props.maintenance_handleChange("period")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Checkbox
                    checked={this.props.maintenanceForm.attributes.repeat}
                    onChange={this.props.maintenance_handleChange_attr(
                      "repeat"
                    )}
                    color="primary"
                    label={this.props.translate("repeat")}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Checkbox
                    checked={this.props.maintenanceForm.attributes.status}
                    onChange={this.props.maintenance_handleChange_attr(
                      "status"
                    )}
                    color="primary"
                    label={this.props.translate("status")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SettingForm
                    fullWidth
                    deviceId={this.props.selecteddeviceId}
                    maintenanceForm={this.props.maintenanceForm}
                    form={this.props.maintenanceForm.attributes}
                    mode={
                      this.props.isHaveAccess === "maintenanceCreate"
                        ? "create"
                        : "update"
                    }
                    saveToParent={this.props.saveToParent}
                    dispatch={this.props.dispatch}
                    translate={this.props.translate}
                  />
                </Grid>
              </Grid>
              <div style={{ textAlign: "right", marginTop: 10 }}>
                <Button
                  size="small"
                  style={{ marginRight: 15 }}
                  className="button-white"
                  onClick={() => this.props.onCloseC_Att_Modal()}
                >
                  {" "}
                  {this.props.translate("sharedCancel")}{" "}
                </Button>
                {checkPrivileges(this.props.isHaveAccess) && (
                  <Button
                    disabled={!this.props.isAddVisableBtn}
                    size="small"
                    onClick={() => this.props.addMaintenance()}
                  >
                    {" "}
                    {this.props.translate(this.props.addButtonStatus)}
                  </Button>
                )}
              </div>
            </div>
          </Grid>
        )}
      </Grid>
    );
  }
}

const postionAttributes = [
  {
    key: "totalDistance",
    name: Strings.totalDistance,
    valueType: "number",
    dataType: "distance",
  },
  {
    key: "hours",
    name: Strings.hours,
    valueType: "number",
    dataType: "hours",
  },
  {
    key: "date",
    name: Strings.date,
    valueType: "number",
    dataType: "hours",
  },
];
