import React, { Component } from "react";
import Layout from "./../../Layout";
import { Grid } from "@mui/material";
import Card from "./components/Card/Card";
import { connect } from "react-redux";
import {
  fetchSummery,
  applyDevicesFilter,
  applyDevicesCatFilter,
  resetFilters,
  resetCatFilters,
} from "./../../Actions/Devices";
import barChartDefault from "./../../Components/common/Charts/barChartDefault";
import { ReactComponent as TrailerIcon } from "./../../assets/dashboard/truck.svg";
import { ReactComponent as NotRegistered } from "./../../assets/dashboard/not-registered.svg";
import { ReactComponent as GPSNotSupport } from "./../../assets/dashboard/gps-no-support.svg";
import { ReactComponent as OnlineStatus } from "./../../assets/dashboard/online.svg";
import { ReactComponent as OfflineStatus } from "./../../assets/dashboard/offline.svg";
import { ReactComponent as DriverIcon } from "./../../assets/dashboard/driver.svg";
import { ReactComponent as AssignedIcon } from "./../../assets/dashboard/assigned.svg";
import { ReactComponent as UnAssignedIcon } from "./../../assets/dashboard/not-assigned.svg";
import { ReactComponent as MovingIcon } from "./../../assets/dashboard/van-moving.svg";
import { ReactComponent as IdlingIcon } from "./../../assets/dashboard/idling.svg";
import { ReactComponent as TowingIcon } from "./../../assets/dashboard/tow-truck.svg";
import { ReactComponent as ParkingIcon } from "./../../assets/dashboard/parking.svg";
import { ReactComponent as GPSNotUpdated } from "./../../assets/dashboard/gps-not-updated.svg";
import { ReactComponent as StopIcon } from "./../../assets/dashboard/stop.svg";
import { ReactComponent as UnknownIcon } from "./../../assets/dashboard/unknown.svg";
import { ReactComponent as DashboardIcon } from "./../../assets/nav/dashboard.svg";

import "highcharts";
// import ReactHighcharts from "react-highcharts";
import moment from "moment";
import Skeleton from "@mui/material/Skeleton";
import Button from "../../Components/common/Button";
import Dialog from "../../Components/common/Dialog";
import DeviceSelector from "../../Components/Devices/DeviceSelector";
import DriverSelector from "../../Components/Devices/DriverSelector";
import { DistanceUnits, fonts } from "../../Helpers";
import Scrollbar from "react-scrollbars-custom";
import CloseIcon from "@mui/icons-material/Close";
import { BarChart } from "../../Components/common/Charts/BarChart";
import withTranslationWrapper from "../../HOC/withTranslation";

const initState = {
  userInfoFront: {},
  userFetch: false,
  devicesFetch: false,
  isFetching: false,
  enginHourReport: false,
  enginHourReportLoading: false,
  usersCount: {},
  devicesCount: {},
  report: {},
  mileage: {},
  pie1: null,
  positionsFetched: false,
  driverFetched: false,
  motionSelector: false,
  motionSelectorKey: "",
  deviceSelector: false,
  deviceSelectorKey: "",
  categorySelector: false,
  categorySelectorKey: "",
  geoSelector: false,
  geoSelectorKey: "",
  driverSelector: false,
  show: false,
  type: "",
  driverIds: [],
  driverSelectorKey: "",
  pieChart2: null,
  resArr2: {},
  resArr: {},
  drivers: {},
};

/* function compare (b, a) {
  if (a.y < b.y) {
    return -1
  }
  if (a.y > b.y) {
    return 1
  }
  return 0
} */

const colors = [
  "#7cb5ec",
  "#434348",
  "#90ed7d",
  "#f7a35c",
  "#2c2b79",
  "#f15c80",
  "#e4d354",
  "#2b908f",
  "#f45b5b",
  "#91e8e1",
  "#7cb5ec",
  "#434348",
  "#90ed7d",
  "#7cb5ec",
  "#434348",
  "#90ed7d",
  "#f7a35c",
  "#2c2b79",
  "#f15c80",
  "#e4d354",
  "#2b908f",
  "#f45b5b",
  "#91e8e1",
  "#7cb5ec",
  "#434348",
  "#90ed7d",
  "#7cb5ec",
  "#434348",
  "#90ed7d",
  "#f7a35c",
  "#2c2b79",
  "#f15c80",
  "#e4d354",
  "#2b908f",
  "#f45b5b",
  "#91e8e1",
  "#7cb5ec",
  "#434348",
  "#90ed7d",
  "#7cb5ec",
  "#434348",
  "#90ed7d",
  "#f7a35c",
  "#2c2b79",
  "#f15c80",
  "#e4d354",
  "#2b908f",
  "#f45b5b",
  "#91e8e1",
  "#7cb5ec",
  "#434348",
  "#90ed7d",
];

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initState,
    };
  }

  componentWillUnmount() {
    this.setState({
      ...initState,
    });
    this.props.dispatch(resetFilters());
    this.props.dispatch(resetCatFilters());
  }

  resetSelectors = () => {
    this.setState({
      motionSelector: false,
      motionSelectorKey: "",
      geoSelector: false,
      geoSelectorKey: "",
      deviceSelector: false,
      deviceSelectorKey: "",
      categorySelector: false,
      categorySelectorKey: "",
      driverSelector: false,
      driverSelectorKey: "",
      show: false,
      type: "",
      driverIds: [],
    });
  };

  openCategorySelector = (key, name) => {
    this.resetSelectors();
    this.setState(
      {
        show: true,
        type: "device",
      },
      () => {
        this.showSelectedUnits(key, name);
      }
    );
  };
  openMotionSelector = (key, name) => {
    this.resetSelectors();
    if (this.state.motionSelector === false) {
      this.showSelectedUnits(key, "device");
    }
    this.setState({
      motionSelector: !this.state.motionSelector,
      motionSelectorKey: name,
    });
  };

  openDeviceSelector = (key, name) => {
    this.resetSelectors();
    this.setState(
      {
        show: true,
        type: name,
      },
      () => {
        this.showSelectedUnits(key, name);
      }
    );
    /* if (this.state.deviceSelector === false) {
      this.showSelectedUnits(key, 'device')
    }
    this.setState({
      deviceSelector: !this.state.deviceSelector,
      deviceSelectorKey: name
    }) */
  };
  openGeoSelector = (key, name) => {
    this.resetSelectors();
    this.setState(
      {
        show: true,
        type: "device",
      },
      () => {
        this.showSelectedUnits(key, name);
      }
    );
  };

  openDriverSelector = (driverIds, name) => {
    this.resetSelectors();
    this.setState({
      show: true,
      type: name,
      driverIds,
    });
  };

  UNSAFE_componentWillMount() {
    this.prepareStates(this.props);
    if (this.props.logInUsers && this.props.logInUsers.id) {
      this.props.dispatch(fetchSummery());
    }
  }

  prepareDriver = ({ drivers, positions }) => {
    let ids = [];
    let drIds = [];
    let inactive = [];
    positions.map((pos) => {
      if (pos && pos.attributes && pos.attributes.driverUniqueId) {
        if (
          !moment(pos.serverTime).isBefore(moment().subtract(10, "minutes"))
        ) {
          ids.push(pos.attributes.driverUniqueId);
        } else {
          inactive.push(pos.attributes.driverUniqueId);
        }
      }
      return null;
    });
    drivers.map((dr) => {
      if (dr && dr.uniqueId && dr.uniqueId !== "0") {
        drIds.push(dr.uniqueId);
      }
      return null;
    });
    const assigned = drIds.filter((d) => ids.includes(d));
    const unassigned = drIds.filter(
      (d) => !ids.includes(d) && !inactive.includes(d)
    );
    const unknown = ids.filter((d) => !drIds.includes(d));

    this.setState({
      drivers: {
        assigned,
        unassigned,
        unknown,
        inactive,
        total:
          assigned.length +
          unassigned.length +
          unknown.length +
          inactive.length,
      },
    });
  };

  prepareStates = (n) => {
    if (
      n.positions &&
      n.positions.length &&
      n.drivers &&
      n.drivers.length &&
      n.allComputedAttributes &&
      n.allComputedAttributes.length &&
      this.state.driverFetched === false
    ) {
      this.setState(
        {
          driverFetched: true,
        },
        () => {
          this.prepareDriver(n);
        }
      );
    }

    if (n.devices3 && n.devices3.length) {
      const notification = n.allNotifications;

      let notRegistered = notification.notRegistered;

      let cat = {};

      n.devices3.map((v) => {
        let c = v.category || "default";
        cat[c] = cat[c] || 0;
        cat[c]++;
        return null;
      });

      if (!this.state.pie1) {
        let objList = { total: 0, data: [] };
        Object.keys(cat).map((e, i) => {
          objList.total = n.devices3.length;
          objList.data.push({
            value: cat[e],
            color: colors[i],
            name: this.props.translate(e),
            key: e,
            image: <img src={`/assets/category/default/${e}.svg`} alt="" />,
          });
          return null;
        });

        this.setState({
          pie1: objList,
        });
      }

      let resArr2 = {
        total:
          notRegistered.length +
          notification.statusOnline.length +
          notification.statusOffline.length +
          notification.gpsNotUpdated.length,
        online: notification.statusOnline.length,
        offline: notification.statusOffline.length,
        notRegistered: notification.notRegistered.length,
        gpsNotUpdated: notification.gpsNotUpdated.length,
      };

      let resArr = {};
      const total =
        notification.Moving.length +
        notification.overSpeed.length +
        notification.gpsNotUpdated.length +
        notification.Idling.length +
        notification.Towing.length +
        notification.statusParking.length +
        notification.stop.length +
        notification.statusOffline.length;

      resArr = {
        total,
        data: [
          {
            value: notification.Moving.length,
            color: "#28a745",
            name: this.props.translate("moving"),
            key: "Moving",
            image: <MovingIcon width={24} height={24} fill="currentColor" />,
          },
          {
            value: notification.Idling.length,
            color: "#ffbb54eb",
            name: this.props.translate("idling"),
            key: "Idling",
            image: <IdlingIcon width={24} height={24} fill="currentColor" />,
          },
          {
            value: notification.Towing.length,
            color: "#ffc107",
            name: this.props.translate("towing"),
            key: "Towing",
            image: <TowingIcon width={24} height={24} fill="currentColor" />,
          },
          {
            value: notification.statusParking.length,
            color: "#1976d2",
            name: this.props.translate("parking"),
            key: "statusParking",
            image: <ParkingIcon width={24} height={24} fill="currentColor" />,
          },
          {
            value: notification.gpsNotUpdated.length,
            color: "#328FEB",
            name: this.props.translate("GPSNotUpdated"),
            key: "gpsNotUpdated",
            image: <GPSNotUpdated width={24} height={24} fill="currentColor" />,
          },
          {
            value: notification.stop.length,
            color: "#f83b3b",
            name: this.props.translate("stop"),
            key: "stop",
            image: <StopIcon width={24} height={24} fill="currentColor" />,
          },
          {
            value: notification.statusOffline.length,
            color: "#DC3545",
            name: this.props.translate("unknown"),
            key: "statusOffline",
            image: <OfflineStatus width={24} height={24} fill="currentColor" />,
          },
          {
            value: notification.notRegistered.length,
            color: "#7E8793",
            key: "notRegistered",
            name: this.props.translate("tracker.notRegisteredYet"),
            image: <NotRegistered width={24} height={24} fill="currentColor" />,
          },
        ],
      };

      this.setState({
        resArr,
        pieChart2: resArr2,
      });
    }

    let reports = n.engineHourSummery.data;

    if (
      reports &&
      reports.length &&
      this.state.enginHourReportLoading === false
    ) {
      this.setState(
        {
          enginHourReportLoading: true,
        },
        () => {
          let report = reports;
          const data = {};
          const data2 = {};

          if (report && report.length) {
            report.map((row) => {
              if (row.type === "totalDistance") {
                row.data &&
                  row.data.map((d) => {
                    let totalDistance = parseFloat(
                      ((d.totalDistance || 0) / 1000).toFixed(2)
                    );
                    data[d.id] = { ...d, ...data[d.id], totalDistance };
                    return null;
                  });
              } else {
                row.data &&
                  row.data.map((d) => {
                    let hoursRaw = (d.hoursRaw || 0) / (60 * 60 * 1000);
                    data2[d.id] = { ...d, ...data[d.id], hoursRaw };
                  });
              }
              return null;
            });

            this.setState({
              report: Object.values(data2),
              mileage: Object.values(data),
            });
          }
        }
      );
    }
  };

  UNSAFE_componentWillReceiveProps(n) {
    if (
      this.state.isFetching === false &&
      n.positions &&
      n.positions.length &&
      n.ServerSetting &&
      n.logInUsers &&
      !(n.engineHourSummery && n.engineHourSummery.data)
    ) {
      this.setState({ isFetching: true }, () => {
        n.dispatch(
          fetchSummery({
            positions: n.positions,
            ServerSetting: n.ServerSetting,
            logInUsers: n.logInUsers,
          })
        );
      });
    }
    this.prepareStates(n);
  }

  checkLastResponseTime = (posDate, lastUpdate) => {
    return (
      posDate &&
      moment(posDate).isBefore(moment().subtract(10, "minutes")) &&
      !moment(lastUpdate).isBefore(moment().subtract(10, "minutes"))
    );
  };

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  showSelectedUnits = (data, type) => {
    if (type === "device") {
      let newList = [].concat(data);

      this.props.dispatch(applyDevicesCatFilter([]));
      this.props.dispatch(applyDevicesFilter(newList));
    }

    if (type === "geofence") {
      let newList = ["geoIds"];
      this.props.dispatch(applyDevicesCatFilter([]));
      this.props.dispatch(applyDevicesFilter(newList, data));
    }

    if (type === "category") {
      let newList = [].concat(data);

      this.props.dispatch(applyDevicesFilter([]));
      this.props.dispatch(applyDevicesCatFilter(newList));
    }
  };

  render() {
    const View = this.state.show ? (
      <div className="overlayform">
        <h4 className="no-margin mb-5">
          <span>
            {this.state.type === "device"
              ? `${this.props.translate("Devices")} (${
                  this.props.totalDevices
                })`
              : this.state.type === "driver"
              ? "Drivers (" + this.state.driverIds.length + ")"
              : ""}
          </span>{" "}
          <span>
            <CloseIcon onClick={this.resetSelectors} />
          </span>
        </h4>
        <div className="form-body">
          {this.state.type == "device" ? (
            <DeviceSelector readOnly rows={8} />
          ) : null}
          {this.state.type == "driver" ? (
            <DriverSelector
              readOnly
              applyFilter
              onDriverClick={this.onDriverClick}
              rows={8}
              displayList={this.state.driverIds}
            />
          ) : null}
        </div>
      </div>
    ) : null;

    return (
      <Layout
        {...this.props}
        noSidebar
        classFromChildren={"has-padding dashboard-page"}
        afterView={View}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} md={8} className="grid-br">
            <Grid container spacing={3}>
              {/* <Grid item xs={12}>
                <div className="dashboard-page-header">
                  <DashboardIcon width={24} height={24}/>
                  <span>Dashboard</span>
                </div>
              </Grid> */}
              <Grid item xs={12} className="grid-bb">
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <h4 className="no-margin mb-5">
                      {this.props.translate("trackers")} (
                      {this.state.pieChart2?.total || 0})
                    </h4>
                  </Grid>
                  {this.state.pieChart2 ? (
                    <Grid item xs={12} md={6} lg={3}>
                      <div
                        //  style={{width:"200px"}}
                        className="device-status-box"
                        onClick={(e) =>
                          this.openDeviceSelector("statusOnline", "device")
                        }
                      >
                        <div className="device-status-icon online">
                          <OnlineStatus
                            width={24}
                            height={24}
                            fill="currentColor"
                          />
                        </div>
                        <div
                          style={{
                            paddingLeft: "5px",
                            width: "80px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <span>{this.state.pieChart2.online}</span>
                          <em>{this.props.translate("tracker.online")}</em>
                        </div>
                      </div>
                    </Grid>
                  ) : null}
                  {this.state.pieChart2 ? (
                    <Grid item xs={12} md={6} lg={3}>
                      <div
                        className="device-status-box"
                        onClick={(e) =>
                          this.openDeviceSelector("statusOffline", "device")
                        }
                      >
                        <div className="device-status-icon offline">
                          <OfflineStatus
                            width={24}
                            height={24}
                            fill="currentColor"
                          />
                        </div>
                        <div
                          style={{
                            paddingLeft: "5px",
                            width: "80px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <span>{this.state.pieChart2.offline}</span>
                          <em>{this.props.translate("tracker.offline")}</em>
                        </div>
                      </div>
                    </Grid>
                  ) : null}
                  {this.state.pieChart2 ? (
                    <Grid item xs={12} md={6} lg={3}>
                      <div
                        className="device-status-box"
                        onClick={(e) =>
                          this.openDeviceSelector("gpsNotUpdated", "device")
                        }
                      >
                        <div className="device-status-icon not-update">
                          <GPSNotSupport
                            width={20}
                            height={20}
                            fill="currentColor"
                          />
                        </div>
                        <div
                          style={{
                            paddingLeft: "5px",
                            width: "80px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <span>{this.state.pieChart2.gpsNotUpdated}</span>
                          <em>
                            {this.props.translate("tracker.gpsNotUpdated")}
                          </em>
                        </div>
                      </div>
                    </Grid>
                  ) : null}
                  {this.state.pieChart2 ? (
                    <Grid item xs={12} md={6} lg={3}>
                      <div
                        className="device-status-box"
                        onClick={(e) =>
                          this.openDeviceSelector("notRegistered", "device")
                        }
                      >
                        <div className="device-status-icon not-register">
                          <NotRegistered
                            width={20}
                            height={20}
                            fill="currentColor"
                          />
                        </div>
                        <div
                          style={{
                            paddingLeft: "5px",
                            width: "80px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <span>{this.state.pieChart2.notRegistered}</span>
                          <em>
                            {this.props.translate("tracker.notRegisteredYet")}
                          </em>
                        </div>
                      </div>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
              <Grid item xs={12} className="grid-bb">
                <h4 className="no-margin mb-5">
                  {this.props.translate("unitsTypes")}
                </h4>
                <Scrollbar style={{ height: 145 }}>
                  <ul className="units-category-list">
                    {this.state.pie1 &&
                      this.state.pie1.data.map((e) => (
                        <li
                          onClick={() =>
                            this.openCategorySelector(e.key, "category")
                          }
                        >
                          <span
                            style={{
                              display: "flex",
                              alignSelf: "center",
                              color: e.color,
                              width: 58,
                              height: 58,
                              backgroundColor: "#fff",
                              borderRadius: 100,
                              border: "5px solid",
                              overflow: "hidden",
                            }}
                          >
                            {e.image}
                          </span>
                          <span className="unit-value">{e.value}</span>
                          <span className="unit-label">{e.name}</span>
                        </li>
                      ))}
                  </ul>
                </Scrollbar>
              </Grid>
              <Grid item xs={12} lg={6} className="grid-bb grid-br">
                <h4 className="no-margin mb-5">
                  {this.props.translate("reportEngineHours")}
                </h4>
                {this.state.report && this.state.report.length ? (
                  <div style={{ height: 300 }}>
                    <BarChart
                      data={this.state.report}
                      xKey="name"
                      yKey="hoursRaw"
                      dKey="hours"
                      fillColor={"#455AF7"}
                      xAxisFill={this.props.themecolors.textColor}
                    />
                  </div>
                ) : (
                  <BarGraphSkeleton type={1} />
                )}
              </Grid>
              <Grid item xs={12} lg={6} className="grid-bb">
                <h4 className="no-margin mb-5">
                  {this.props.translate("sensorsTranslation.mileage")}
                </h4>
                {this.state.mileage && this.state.mileage.length ? (
                  <div style={{ height: 300 }}>
                    <BarChart
                      data={this.state.mileage}
                      xKey="name"
                      yKey="totalDistance"
                      dKey="totalDistance"
                      fillColor={"#DC3444"}
                      xAxisFill={this.props.themecolors.textColor}
                    />
                  </div>
                ) : (
                  <BarGraphSkeleton type={1} />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={3}>
              {this.props.drivers && this.props.drivers.length ? (
                <Grid item xs={12} className="grid-bb">
                  <h4 className="no-margin mb-5">
                    {this.props.translate("sharedDrivers")}
                  </h4>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <div
                        className="device-status-box"
                        onClick={(e) =>
                          this.openDriverSelector(
                            this.state.drivers.assigned,
                            "driver"
                          )
                        }
                      >
                        <div
                          className="device-status-icon"
                          style={{
                            backgroundColor:
                              this.props.themecolors.themeLightColor,
                            color: this.props.themecolors.tabBgColor,
                          }}
                        >
                          <AssignedIcon
                            width={18}
                            height={18}
                            fill="currentColor"
                          />
                        </div>
                        <div
                          style={{
                            paddingLeft: "5px",
                            width: "80px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <span>
                            {this.state.drivers?.assigned?.length || 0}
                          </span>
                          <em>{this.props.translate("assigned")}</em>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div
                        className="device-status-box"
                        onClick={(e) =>
                          this.openDriverSelector(
                            this.state.drivers.unassigned,
                            "driver"
                          )
                        }
                      >
                        <div
                          className="device-status-icon"
                          style={{
                            backgroundColor:
                              this.props.themecolors.themeLightColor,
                            color: this.props.themecolors.tabBgColor,
                          }}
                        >
                          <UnAssignedIcon
                            width={18}
                            height={18}
                            fill="currentColor"
                          />
                        </div>
                        <div
                          style={{
                            paddingLeft: "5px",
                            width: "80px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <span>
                            {this.state.drivers?.unassigned?.length || 0}
                          </span>
                          <em>{this.props.translate("unassigned")}</em>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div
                        className="device-status-box"
                        onClick={(e) =>
                          this.openDriverSelector(
                            this.state.drivers.unknown,
                            "driver"
                          )
                        }
                      >
                        <div
                          className="device-status-icon"
                          style={{
                            backgroundColor:
                              this.props.themecolors.themeLightColor,
                            color: this.props.themecolors.tabBgColor,
                          }}
                        >
                          <NotRegistered
                            width={18}
                            height={18}
                            fill="currentColor"
                          />
                        </div>
                        <div
                          style={{
                            paddingLeft: "5px",
                            width: "80px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <span>
                            {this.state.drivers?.unknown?.length || 0}
                          </span>
                          <em>{this.props.translate("unknown")}</em>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div
                        className="device-status-box"
                        onClick={(e) =>
                          this.openDriverSelector(
                            this.state.drivers.inactive,
                            "driver"
                          )
                        }
                      >
                        <div
                          className="device-status-icon"
                          style={{
                            backgroundColor:
                              this.props.themecolors.themeLightColor,
                            color: this.props.themecolors.tabBgColor,
                          }}
                        >
                          <UnknownIcon
                            width={18}
                            height={18}
                            fill="currentColor"
                          />
                        </div>
                        <div
                          style={{
                            paddingLeft: "5px",
                            width: "80px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <span>
                            {this.state.drivers?.inactive?.length || 0}
                          </span>
                          <em>{this.props.translate("nosignal")}</em>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              ) : null}
              <Grid item xs={12} className="grid-bb">
                <h4 className="no-margin mb-5">
                  {this.props.translate("motionStatus")}
                </h4>
                <Grid container spacing={1}>
                  {this.state.resArr && this.state.resArr.total
                    ? this.state.resArr.data.map((row) => (
                        <Grid item xs={12} md={6}>
                          <div
                            key={row.key}
                            className="device-status-box nobg"
                            onClick={(e) =>
                              this.openDeviceSelector(row.key, "device")
                            }
                          >
                            <div
                              className="device-status-icon"
                              style={{ backgroundColor: row.color }}
                            >
                              {row.image}
                            </div>
                            <div
                              style={{
                                paddingLeft: "5px",
                                width: "80px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <span>{row.value}</span>
                              <em
                              // style={{width:"20px",whiteSpace:"nowrap", overflow:"hidden",textOverflow:"ellipsis"}}
                              >
                                {row.name}
                              </em>
                            </div>
                          </div>
                        </Grid>
                      ))
                    : null}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <h4 className="no-margin mb-5">
                  {this.props.translate("sharedGeofence")}
                </h4>
                {this.props.geofences && this.props.geofences.length ? (
                  <>
                    <Scrollbar
                      disableTracksWidthCompensation
                      style={{
                        height: 38 * 6,
                      }}
                    >
                      {this.props.geofences && this.props.geofences.length ? (
                        this.props.geofences.map((d, i) => {
                          return (
                            <div
                              style={{
                                background:
                                  this.props.themecolors
                                    .themeListingActiveInverse,
                                boxShadow: "0 4px 8px rgba(0,0,0,0.08)",
                                border: "1px solid #CFCFCF",
                                borderRadius: 8,
                                color: this.props.themecolors.textColor,
                              }}
                              key={d.id}
                              className={
                                "motion-status-bar motion-status-bar2 hover"
                              }
                              onClick={() =>
                                this.openGeoSelector(d.id, "geofence")
                              }
                            >
                              <span className="motion-status-img" />
                              <span className="motion-status-name">
                                {d.name}
                              </span>
                              <span className="motion-status-count">
                                {this.props.insideGeofence &&
                                this.props.insideGeofence[d.id] &&
                                this.props.insideGeofence[d.id].length
                                  ? this.props.insideGeofence[d.id].length
                                  : 0}
                              </span>
                            </div>
                          );
                        })
                      ) : (
                        <BarGraphSkeleton2 />
                      )}
                    </Scrollbar>
                  </>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Layout>
    );
  }
}

function BarGraphSkeleton(props) {
  return (
    <div
      style={{
        marginTop: 20,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <table
        style={{ width: "100%", boderCollapse: "collapse" }}
        border="0"
        cellPadding="0"
        cellSpacing="0"
      >
        <tbody>
          <tr>
            <td
              align="right"
              width={200}
              style={{ borderRight: "1px solid #ccd6eb", padding: "7px 20px" }}
            >
              <Skeleton className="skeleton-pulse" width={70} height={14} />
            </td>
            <td style={{ padding: "7px 20px" }}>
              <Skeleton className="skeleton-pulse" width={200} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align="right"
              style={{ borderRight: "1px solid #ccd6eb", padding: "7px 20px" }}
            >
              <Skeleton className="skeleton-pulse" width={100} height={14} />
            </td>
            <td style={{ padding: "7px 20px" }}>
              <Skeleton className="skeleton-pulse" width={130} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align="right"
              style={{ borderRight: "1px solid #ccd6eb", padding: "7px 20px" }}
            >
              <Skeleton className="skeleton-pulse" width={95} height={14} />
            </td>
            <td style={{ padding: "7px 20px" }}>
              <Skeleton className="skeleton-pulse" width={190} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align="right"
              style={{ borderRight: "1px solid #ccd6eb", padding: "7px 20px" }}
            >
              <Skeleton className="skeleton-pulse" width={125} height={14} />
            </td>
            <td style={{ padding: "7px 20px" }}>
              <Skeleton className="skeleton-pulse" width={165} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align="right"
              style={{ borderRight: "1px solid #ccd6eb", padding: "7px 20px" }}
            >
              <Skeleton className="skeleton-pulse" width={115} height={14} />
            </td>
            <td style={{ padding: "7px 20px" }}>
              <Skeleton className="skeleton-pulse" width={225} height={14} />
            </td>
          </tr>
          <tr>
            <td
              align="right"
              style={{ borderRight: "1px solid #ccd6eb", padding: "7px 20px" }}
            >
              <Skeleton className="skeleton-pulse" width={60} height={14} />
            </td>
            <td style={{ padding: "7px 20px" }}>
              <Skeleton className="skeleton-pulse" width={160} height={14} />
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ marginBottom: 20, marginTop: 30, display: "flex" }}>
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            paddingRight: 40,
          }}
        >
          <div style={{ marginRight: 9 }}>
            <Skeleton
              className="skeleton-pulse"
              variant="circle"
              width={12}
              height={12}
            />
          </div>
          <Skeleton className="skeleton-pulse" width={60} height={14} />
        </div>
        {props.type === 1 && (
          <div
            style={{
              display: "inline-flex",
              alignItems: "center",
              paddingRight: 40,
            }}
          >
            <div style={{ marginRight: 9 }}>
              <Skeleton
                className="skeleton-pulse"
                variant="circle"
                width={12}
                height={12}
              />
            </div>
            <Skeleton className="skeleton-pulse" width={60} height={14} />
          </div>
        )}
      </div>
    </div>
  );
}

function BarGraphSkeleton2() {
  return (
    <div
      style={{
        marginTop: 20,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
    </div>
  );
}
export const MotionList = ({
  data,
  showSelectedUnits,
  className,
  category,
}) => {
  const html = data.data.map((d) => (
    <div
      key={d.name}
      className={"motion-status-bar hover " + className}
      onClick={() =>
        showSelectedUnits &&
        showSelectedUnits(d.key, category ? "category" : "device", d.name)
      }
    >
      <span style={{ background: d.color }} className="motion-status-border" />{" "}
      <span className="motion-status-img">{d.image}</span>{" "}
      <span className="motion-status-name">{d.name}</span>
      <span className="motion-status-count">{d.value}</span>
    </div>
  ));
  return <div>{html}</div>;
};

export const GeoList = ({ data, showSelectedUnits, className, geoIds }) => {
  data.sort(
    (b, a) =>
      ((geoIds[a.id] && geoIds[a.id].length) || 0) -
      ((geoIds[b.id] && geoIds[b.id].length) || 0)
  );
  const html = data.map((d, i) => {
    return (
      <div
        key={d.id}
        className={"motion-status-bar hover " + className}
        onClick={() =>
          showSelectedUnits && showSelectedUnits(d.id, "geofence", d.name)
        }
      >
        <span
          style={{ background: colors[i % colors.length] }}
          className="motion-status-border"
        />
        <span className="motion-status-img" />
        <span className="motion-status-name">{d.name}</span>
        <span className="motion-status-count">
          {geoIds && geoIds[d.id] && geoIds[d.id].length
            ? geoIds[d.id].length
            : 0}
        </span>
      </div>
    );
  });
  return <div>{html}</div>;
};

const mapStateToProps = (state) => {
  let driverIdsList = [];
  Object.values(state.allComputedAttributes).map(({ item, deviceIds }) => {
    if (deviceIds && item.attribute === "driverUniqueId") {
      driverIdsList = [...driverIdsList, ...deviceIds];
    }
    return null;
  });
  return {
    ServerSetting: state.ServerSetting,
    logInUsers: state.logInUsers,
    engineHourSummery: state.engineHourSummery,
    devices3: state.devices3,
    geofences: state.geoFence,
    totalDevices: state.devices.total,
    allNotifications: state.allNotifications,
    drivers: state.drivers,
    positions: state.positions,
    insideGeofence: state.insideGeofence,
    allComputedAttributes: driverIdsList,
    themecolors: state.themeColors,
  };
};

export default connect(mapStateToProps)(withTranslationWrapper(Dashboard));
