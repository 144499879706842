import React, { Component } from "react";
import { checkPrivileges } from "../../Helpers";
import TextField from "../../Components/common/TextField";
import Checkbox from "../../Components/common/Checkbox";
import Button from "../../Components/common/Button";
import { Grid, InputAdornment, Tooltip } from "@mui/material";
import SingleSelect from "../../Components/common/SingleSelect";
import CustomDatePicker from "../../Components/common/CustomDatePicker"
import DateFnsUtils from "@date-io/date-fns";
import PublishIcon from "@mui/icons-material/Publish";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

export const UpdateMaintenanceHistoryForm = ({
  form,
  isVisible,
  translate,
  closeCreateFrom,
  handleChangeHist,
  // histFormVisible,
  imagePopup,
  submitHistoryForm,
  uploadImage,
  logInUser,
}) => {
  return (
    <div>
      {/* {histFormVisible &&( */}
      <>
        <h4 className="page-title">{translate("sharedMaintenance")}</h4>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id="cost"
              margin="dense"
              label={translate("cost")}
              variant="outlined"
              fullWidth
              symbol
              value={form.cost}
              onChange={(e) => handleChangeHist("cost", e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start" disableTypography>
                    {(logInUser.attributes &&
                      logInUser.attributes.currencyLabel) ||
                      "AED"}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <SingleSelect
              array={statusAttributes}
              selectName="type"
              label={translate("status")}
              value={
                form.status
                  ? {
                      key: form.status,
                      id: form.status,
                      value: form.status,
                      label: translate(form.status),
                    }
                  : ""
              }
              handleChange={handleChangeHist}
              canRemove={checkPrivileges("maintenanceUpdate")}
              canAssign={checkPrivileges("maintenanceUpdate")}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id="cValue"
              margin="dense"
              label={translate("currentValue")}
              variant="outlined"
              fullWidth
              value={form.cValue}
              onChange={(e) => handleChangeHist("cValue", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
          <CustomDatePicker
                        format="dd/MM/yyyy"
                        margin="dense"
                        label={translate('date')}
                        variant="outlined"
                        fullWidth
                        // minDate={new Date()}
                        value={
                          form.cDate
                            ? 
                                `${ form.cDate}`
                            : null
                        }
                        onChange={e => handleChangeHist('cDate', e)}
                      />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            {form.receiptUrl && (
              <Tooltip
                placement="right-end"
                title={translate("clickForPreview")}
              >
                <img
                  style={{ width: 120, height: "auto" }}
                  alt="Document Image"
                  src={form.receiptUrl}
                  onClick={() => imagePopup(form.receiptUrl)}
                />
              </Tooltip>
            )}
            <Tooltip title="Upload Image">
              <Button
                style={{ float: "left" }}
                variant="contained"
                margin="dense"
                component="label"
                onChange={(e) => uploadImage(e, "updateHist")}
              >
                <input type="file" multiple hidden />
                {translate("onlyAcceptImage")}
                <PublishIcon style={{ color: "#fff", marginLeft: 15 }} />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} style={{ marginLeft: 5 }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Checkbox
                checked={form.updateMaintenance}
                onChange={(e) =>
                  handleChangeHist("updateMaintenance", e.target.checked)
                }
                color="primary"
                label={
                  translate("updateMaintenance") +
                  " " +
                  (form.type === "totalDistance" ? "mileage" : form.type)
                }
              />
              <Tooltip
                classes={{
                  popper: "menu-popper",
                  tooltip: "menu-popper-tooltip",
                }}
                title={translate("updateMaintenanceTooltip")}
              >
                <HelpOutlineIcon
                  style={{ fontSize: 15, marginTop: 3, marginRight: 15 }}
                />
              </Tooltip>
            </div>
          </Grid>
        </Grid>
        <div style={{ display: "flex", marginTop: 10 }}>
          <div style={{ marginLeft: "auto" }} />
          <Button
            size="small"
            style={{ marginRight: 15 }}
            className="button-white"
            onClick={() => closeCreateFrom("history")}
          >
            {translate("sharedCancel")}{" "}
          </Button>
          {checkPrivileges("maintenanceCreate") && (
            <Button
              size="small"
              onClick={submitHistoryForm}
              disabled={isVisible}
            >
              {translate("update")}
            </Button>
          )}
        </div>
      </>
      {/* )} */}
    </div>
  );
};
const statusAttributes = [
  {
    key: "completed",
    name: "Completed",
    valueType: "string",
  },
  // {
  //   key: 'required',
  //   name: 'Required',
  //   valueType: 'string',
  // },
  // {
  //   key: 'expired',
  //   name: 'Overdue',
  //   valueType: 'string',
  // }
];
