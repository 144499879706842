import React, { Component } from "react";
import { updateLayer } from "./../../Actions/Maps";
import { connect } from "react-redux";
import "./MapPagination.scss";

import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import MenuItem from "./../common/MenuItem";

let themeColors;
const mapStateToProps = (state, ownProps) => {
  themeColors = state.themeColors;
  return {
    themecolors: state.themeColors,
    mapLayer: state.mapLayer,
  };
};

const styles = (theme) => ({
  pagination: {
    "& .active > a": {
      color: themeColors.themeDarkColor,
    },
    "& li:hover a": {
      color: themeColors.themeDarkColor,
    },
  },
});

class MapPagination1 extends Component {
  constructor(props) {
    super(props);
    this.updateLayers = this.updateLayers.bind(this);
  }

  updateLayers(id) {
    this.props.dispatch(updateLayer({ id }));
    this.props.updateLayer();
  }

  render() {
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.maps &&
      this.props.logInUser.attributes.maps.length
    ) {
      return (
        <React.Fragment>
          {this.props.logInUser.attributes.maps.map((itemMap, index) => {
            return (
              <MenuItem
                key={index}
                selected={this.props.mapLayer.id === itemMap}
                onClick={(e) => this.updateLayers(itemMap)}
              >
                {this.props.translate(itemMap)}
              </MenuItem>
            );
          })}
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
}

MapPagination1.propTypes = {
  classes: PropTypes.object.isRequired,
};
export const MapPagination = connect(mapStateToProps)(
  withStyles(styles)(MapPagination1)
);
