import React, { Component } from "react";
import SimpleModalWrapped from "../common/Modal";
import Grid from "@mui/material/Grid";
import TextField from "../common/TextField";
import SearchDevices from "../Devices/searchItems";
import { withStyles } from "@mui/styles";
import DateFnsUtils from "@date-io/date-fns";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "./../common/Tooltip";
import IconButton from "@mui/material/IconButton";
import CrossIcons from "@mui/icons-material/Remove";
import moment from "moment";
import { plateTypes } from "../../Helpers";
import { ReactComponent as SendIcon } from "./../../assets/nav/send.svg";
import Select from "../common/Select";
import withTranslationWrapper from "../../HOC/withTranslation";
import CustomDatePicker from "../common/CustomDatePicker";
import { Trans as Translate } from "react-i18next";

const styles = (theme) => ({});

class addVehicle extends Component {
  constructor() {
    super();
    this.state = {
      attm_isVisable: false,
    };
    this.addAttributes = this.addAttributes.bind(this);
  }

  addAttributes() {
    this.setState({
      attm_isVisable: !this.state.attm_isVisable,
    });
  }
  uploadFile = (e) => {};

  handleChange = (a, b) => {
    this.props.handleChangeForAttributesValues(a, { target: b });
  };

  render() {
    return (
      <div style={{ height: "100%" }}>
        <SimpleModalWrapped
          visable={true}
          notShowAttributesBtn
          isButtonVisable={true}
          title="sharedVehicle"
          formSubmit={this.props.formSubmit}
          modalControle={this.props.onClose}
          buttonText={this.props.buttonText}
          btnCloseText="sharedBack"
          isVisableBtn={this.props.isVisableUserBtn}
          selectedItemId={this.props.selectedVehicle.id}
          content={
            <form autoComplete="off">
              <Grid container spacing={2}>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id="label"
                    label={this.props.translate("label")}
                    required
                    placeholder=""
                    value={this.props.form.label || ""}
                    onChange={(e) => this.props.handleChange("label", e)}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id="vehicleLicensePlate"
                    label={this.props.translate("vehicleLicensePlate")}
                    required
                    placeholder=""
                    value={this.props.form.vehicleLicensePlate || ""}
                    onChange={(e) =>
                      this.props.handleChange("vehicleLicensePlate", e)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id="make"
                    label={this.props.translate("make")}
                    placeholder=""
                    value={this.props.form.attributes.make || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "make",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id="model"
                    label={this.props.translate("model")}
                    placeholder=""
                    value={this.props.form.model || ""}
                    onChange={(e) => this.props.handleChange("model", e)}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id="year"
                    label={this.props.translate("year")}
                    placeholder=""
                    value={this.props.form.attributes.year || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "year",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id="color"
                    label={this.props.translate("attributeColor")}
                    placeholder=""
                    value={this.props.form.attributes.color || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "color",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id="fuel_type"
                    label={this.props.translate("fuel_type")}
                    placeholder=""
                    value={this.props.form.attributes.fuel_type || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "fuel_type",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id="capacity"
                    label={this.props.translate("capacity")}
                    placeholder=""
                    value={this.props.form.attributes.capacity || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "capacity",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id="garage"
                    label={this.props.translate("garage")}
                    placeholder=""
                    value={this.props.form.garage || ""}
                    onChange={(e) => this.props.handleChange("garage", e)}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  md={4}
                  sm={6}
                  xs={12}
                  style={{ postion: "relative", zIndex: 10 }}
                >
                  <SearchDevices
                    isMulti={false}
                    api="devices"
                    isClearable
                    onChange={this.props.onChangedDevice}
                    value={this.props.vehicleTracker}
                    placeholder={this.props.translate("searchUnits")}
                  />
                </Grid>

                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id="vehicleType"
                    label={this.props.translate("sharedType")}
                    placeholder=""
                    value={this.props.form.vehicleType || ""}
                    onChange={(e) => this.props.handleChange("vehicleType", e)}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id="vin"
                    label={this.props.translate("vin")}
                    placeholder=""
                    value={this.props.form.attributes.vin || ""}
                    onChange={(e) =>
                      this.props.handleChangeForAttributesValues("vin", e)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id="chassisNumber"
                    label={this.props.translate("chassisNumber")}
                    placeholder=""
                    value={this.props.form.attributes.chassisNumber || ""}
                    onChange={(e) =>
                      this.props.handleChangeForAttributesValues(
                        "chassisNumber",
                        e
                      )
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                {/* start          -----------pending task from backEnd--------- Expiration Date*/}
                <Grid item md={4} sm={6} xs={12}>
                  <CustomDatePicker
                    format="dd/MM/yyyy"
                    error={
                      this.props.form.expirationTime &&
                      this.props.form.expirationTime < moment().startOf("day")
                    }
                    helperText={
                      this.props.form.expirationTime &&
                      this.props.form.expirationTime <
                        moment().startOf("day") ? (
                        <Translate i18nKey="licenseExpired" />
                      ) : (
                        ""
                      )
                    }
                    margin="dense"
                    label={<Translate i18nKey="V/LExpirationTime" />}
                    variant="outlined"
                    fullWidth
                    minDate={new Date()}
                    value={
                      this.props.form.expirationTime
                        ? `${this.props.form.expirationTime}`
                        : null
                    }
                    onChange={this.props.handleChangeLicenseExpDate(
                      "expirationTime"
                    )}
                  />
                  {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      format="dd/MM/yyyy"
                      error={
                        this.props.form.expirationTime &&
                        this.props.form.expirationTime <
                          moment().startOf("day")
                      }
                      helperText={
                        this.props.form.expirationTime &&
                        this.props.form.expirationTime <
                          moment().startOf("day") ? (
                          <Translate i18nKey="licenseExpired" />
                        ) : (
                          ""
                        )
                      }
                      margin="dense"
                      label={<Translate i18nKey="V/LExpirationTime" />}
                      variant="outlined"
                      fullWidth
                      minDate={new Date()}
                      value={
                        this.props.form.expirationTime
                          ? new Date(
                              `${this.props.form.expirationTime}`
                            )
                          : null
                      }
                      onChange={this.props.handleChangeLicenseExpDate(
                        "expirationTime"
                      )}
                      InputProps={{
                        classes: {
                          root: "theme-cssOutlinedInput",
                          input: "theme-input",
                          focused: "theme-cssFocused",
                          notchedOutline: "theme-notchedOutline",
                        },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title={<Translate i18nKey="noLimit" />}>
                              <IconButton
                                onClick={(e) => this.props.setExpirationTime(e)}
                                style={{ color: "inherit" }}
                              >
                                {<CrossIcons />}
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        classes: {
                          root: "theme-label",
                          focused: "theme-label-focused",
                          shrink: "theme-label-shrink",
                        },
                      }}
                    />
                  </LocalizationProvider> */}
                </Grid>
                {/*---end--- */}

                {/* ------state-------------upload pdf file-----pending task from backEnd
                <Grid item md={4} sm={6} xs={12}>
                  <Tooltip title='Upload pdf'>
                    <Button
                      style={{ float: 'right', maxWidth: '40px',minWidth: '40px' }}
                      variant='contained'
                      margin='dense'
                      component="label"
                      onChange={e => this.uploadFile(e)}
                    >
                      <PublishIcon  style={{ color: '#fff'}}/>
                      <input
                        type="file"
                        hidden
                      />
                    </Button>
                  </Tooltip>
                </Grid>
                ----end---- */}
                {/* <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='permittedSpeed'
                    type='number'
                    label={this.props.translate('permittedSpeed')}
                    placeholder=''
                    value={this.props.form.attributes.permittedSpeed || ''}
                    onChange={e =>
                      this.props.handleChangeForAttributesValues(
                        'permittedSpeed',
                        e
                      )
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid> */}
                {/* <Grid item xs={12}>
                  <h4 style={{ margin: 0, marginTop: '15px' }}>
                    <SendIcon width={14} height={14} style={{marginRight: 10, verticalAlign: 'middle'}} />
                    {this.props.translate('tags')}
                  </h4>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  {this.props.form.attributes.tags}
                  <TextField
                    id='tag_1'
                    label={
                      this.props.logInUsers.attributes &&
                      this.props.logInUsers.attributes.vt1
                        ? this.props.logInUsers.attributes.vt1
                        : this.props.translate('tag_1')
                    }
                    placeholder=''
                    value={this.props.form.attributes.tag_1 || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'tag_1',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='tag_2'
                    label={
                      this.props.logInUsers.attributes &&
                      this.props.logInUsers.attributes.vt2
                        ? this.props.logInUsers.attributes.vt2
                        : this.props.translate('tag_2')
                    }
                    placeholder=''
                    value={this.props.form.attributes.tag_2 || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'tag_2',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='tag_3'
                    label={
                      this.props.logInUsers.attributes &&
                      this.props.logInUsers.attributes.vt3
                        ? this.props.logInUsers.attributes.vt3
                        : this.props.translate('tag_3')
                    }
                    placeholder=''
                    value={this.props.form.attributes.tag_3 || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'tag_3',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='tag_4'
                    label={
                      this.props.logInUsers.attributes &&
                      this.props.logInUsers.attributes.vt4
                        ? this.props.logInUsers.attributes.vt4
                        : this.props.translate('tag_4')
                    }
                    placeholder=''
                    value={this.props.form.attributes.tag_4 || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'tag_4',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id='tag_5'
                    label={
                      this.props.logInUsers.attributes &&
                      this.props.logInUsers.attributes.vt5
                        ? this.props.logInUsers.attributes.vt5
                        : this.props.translate('tag_5')
                    }
                    placeholder=''
                    value={this.props.form.attributes.tag_5 || ''}
                    onChange={e => {
                      return this.props.handleChangeForAttributesValues(
                        'tag_5',
                        e
                      )
                    }}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid> */}

                <Grid item xs={12}>
                  <h4 style={{ margin: 0, marginTop: "15px" }}>
                    <SendIcon
                      width={14}
                      height={14}
                      style={{ marginRight: 10, verticalAlign: "middle" }}
                    />
                    {this.props.translate("integration")}
                  </h4>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <h4 style={{ marginBottom: 0 }}>
                    {this.props.translate("vehicleLicensePlate")}
                  </h4>
                </Grid>
                <Grid
                  item
                  style={{ position: "relative", zIndex: 8 }}
                  md={2}
                  sm={6}
                  xs={12}
                >
                  <label>{this.props.translate("plateType")}</label>
                  <Select
                    key="plateType"
                    array={plateTypes}
                    multi={
                      this.props.form.attributes.plateType &&
                      plateTypes.filter(
                        (e) => this.props.form.attributes.plateType == e.id
                      )
                    }
                    onChange={(e) => this.handleChange("plateType", e)}
                    canAssign={true}
                    canRemove={true}
                  />
                </Grid>
                <Grid
                  item
                  style={{ position: "relative", zIndex: 6 }}
                  md={2}
                  sm={6}
                  xs={12}
                >
                  <label>{this.props.translate("leftLetter")}</label>
                  <TextField
                    id="leftLetter"
                    label={null}
                    placeholder=""
                    value={this.props.form.attributes.leftLetter || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "leftLetter",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  style={{ position: "relative", zIndex: 4 }}
                  md={2}
                  sm={6}
                  xs={12}
                >
                  <label>{this.props.translate("middleLetter")}</label>
                  <TextField
                    id="middleLetter"
                    label={null}
                    placeholder=""
                    value={this.props.form.attributes.middleLetter || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "middleLetter",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid
                  item
                  style={{ position: "relative", zIndex: 2 }}
                  md={2}
                  sm={6}
                  xs={12}
                >
                  <label>{this.props.translate("rightLetter")}</label>
                  <TextField
                    id="rightLetter"
                    label={null}
                    placeholder=""
                    value={this.props.form.attributes.rightLetter || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "rightLetter",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <label>{this.props.translate("number")}</label>
                  <TextField
                    id="number"
                    label={null}
                    placeholder=""
                    value={this.props.form.attributes.number || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "number",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <label style={{ position: "static" }}>
                    {this.props.translate("sequenceNumber")}
                  </label>
                  <TextField
                    id="sequenceNumber"
                    label={this.props.translate("sequenceNumber")}
                    placeholder=""
                    value={this.props.form.attributes.sequenceNumber || ""}
                    onChange={(e) => {
                      return this.props.handleChangeForAttributesValues(
                        "sequenceNumber",
                        e
                      );
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </form>
          }
        />
      </div>
    );
  }
}

const AddVehicle = withStyles(styles);
export default AddVehicle(withTranslationWrapper(addVehicle));
