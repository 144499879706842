import React, { Component, forwardRef } from "react";
import Switch from "@mui/material/Switch";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import { connect } from "react-redux";
import Style from "style-it";

const mapStateToProps = (state, ownProps) => {
  return { themecolors: state.themeColors };
};
function CustomSwitch(props, ref) {
  const p = { ...props };
  delete p.dispatch;
  return (
    <React.Fragment>
      <Style>{`
    .switch-base.switch-base-checked + .switch-base-bar {
      background-color: ${p.themecolors.menuActiveBackground}
    }
    .switch-base.switch-base-checked,
    .switch-base {
      color: ${p.themecolors.menuActiveBackground}
    }`}</Style>
      <Switch
        {...p}
        classes={{
          switchBase: "switch-base",
          checked: "switch-base-checked",
          track: "switch-base-bar",
        }}
      />
    </React.Fragment>
  );
}

export default connect(mapStateToProps)(forwardRef(CustomSwitch));
