import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import Style from "style-it";

const mapStateToProps = (state, ownProps) => {
  return { themecolors: ownProps.themecolors || state.themeColors };
};

const styles = (theme) => ({
  root: {
    borderColor: "transparent",
    minWidth: 94,
    [theme.breakpoints?.down("lg")]: {
      minWidth: "inherit",
    },
    [theme.breakpoints?.down("sm")]: {
      padding: "5px 7px",
    },
  },
});

class CustomButton extends React.Component {
  render() {
    let p = { ...this.props };

    delete p.dispatch;

    return (
      <Style>
        {`
			.theme-button {
				background: transparent;
				color: ${this.props.themecolors.textColor};
				min-width: 50px;
				min-height: 32px;
				border:1px solid ${this.props.themecolors.menuActiveBackground}
			}
			.button-inactive {
				opacity: 0.6
			}
			.theme-button.active {
				opacity: 1;
				font-weight: 600
			}
			.button-dark {
				background: ${this.props.themecolors.themeDarkColor};
				color: ${this.props.themecolors.textColor};
			}
			.theme-button.text-left {
				justify-content: start;
			}
			.button-white {
				background: #fff;
				color: ${this.props.themecolors.textColor};
			}
			
			.button-grey {
				background: #f5f5f5;
				color: ${this.props.themecolors.textColor};
			}
			
			.button-grey:hover {
				background: #f5f5f5;
				color: ${this.props.themecolors.textColor};
			}
			
			.button-white:hover {
				background: #fafafa;
			}
			.theme-button.alter {
				background: #f0ad4e;
				color: #fff;
			}
			.theme-button:disabled {
 				color: ${this.props.themecolors.textColor};
				border:none
			}
		`}
        {this.props.normal && (
          <Button
            {...p}
            className={this.props.className + " theme-button"}
            classes={null}
          />
        )}
        {!this.props.normal && (
          <Button
            {...p}
            className={this.props.className + " theme-button"}
            classes={{ text: "button-text" }}
          />
        )}
      </Style>
    );
  }
}

CustomButton.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(CustomButton));
