import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import Layout from "./../../Layout";
import RouteMap from "./../../Components/Maps/RouteMap";
import instance from "../../axios";
import "moment-duration-format";
import {
  checkPrivileges,
  getDateTimeFormat,
  setAttributeFormat,
  errorHandler,
} from "../../Helpers";
import jsontoxml from "jsontoxml";
import withTranslationWrapper from "../../HOC/withTranslation";

const ReportData = [];

let trips = {};

class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainView: "Timeline",
    };
  }

  UNSAFE_componentWillMount() {}

  render() {
    let serverTimeZoneName = "Asia/Dubai";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone;
    }

    return (
      <div className="track-wrapper">
        <Layout
          {...this.props}
          classFromChildren="timeline"
          {...this.state}
        ></Layout>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ServerSetting: state.ServerSetting,
    themecolors: state.themeColors,
  };
};

export default connect(mapStateToProps)(withTranslationWrapper(Timeline));
