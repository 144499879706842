import React, { Component } from "react";
import SimpleModalWrapped from "../common/Modal";
import Grid from "@mui/material/Grid";
import TextField from "../common/TextField";
import { withStyles } from "@mui/styles";
import withTranslationWrapper from "../../HOC/withTranslation";

const styles = (theme) => ({});
class addRecourcesGroup extends Component {
  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {}

  render() {
    return (
      <div style={{ height: "100%", flex: 1 }}>
        <SimpleModalWrapped
          visable={true}
          notShowAttributesBtn
          isButtonVisable={true}
          title={this.props.itemType || "sharedName"}
          formSubmit={this.props.formSubmit}
          modalControle={this.props.onClose}
          buttonText={
            this.props.form && this.props.form.id ? "update" : "sharedCreate"
          }
          btnCloseText="sharedBack"
          isVisableBtn={this.props.isVisableUserBtn}
          selectedItemId={this.props.selectedVehicle}
          content={
            <form autoComplete="off">
              <Grid container spacing={2}>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id="name"
                    label={"Item Name"}
                    required
                    placeholder=""
                    value={(this.props.form && this.props.form.name) || ""}
                    onChange={(e) => this.props.handleChange("name", e)}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                  <TextField
                    id="description"
                    label={"description"}
                    placeholder=""
                    value={
                      (this.props.form && this.props.form.description) || ""
                    }
                    onChange={(e) => this.props.handleChange("description", e)}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                {/* <Grid item md={4} sm={6} xs={12}>
                    <TextField
                      id='itemType'
                      label={'Item Type'}
                      placeholder=''
                      value={(this.props.form&&this.props.form.itemType) || ''}
                      onChange={e => this.props.handleChange('itemType', e)}
                      variant='outlined'
                      margin='dense'
                      fullWidth
                    />
                  </Grid> */}
              </Grid>
            </form>
          }
        />
      </div>
    );
  }
}

const AddRecourcesGroup = withStyles(styles);
export default AddRecourcesGroup(withTranslationWrapper(addRecourcesGroup));
