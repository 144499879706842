import React from "react";
import { connect } from "react-redux";
import ReactRRuleGenerator from "./cal/components/ReactRRuleGenerator";
import withTranslationWrapper from "../../../../HOC/withTranslation";
class screen3 extends React.Component {
  constructor() {
    super();
    this.state = {
      form: {},
      ical: null,
    };
  }

  onSaveData = (cal, ical) => {
    this.setState(
      {
        form: cal,
        ical,
      },
      () => this.next()
    );
  };

  next = () => {
    this.props.combineData(this.state);
  };

  render() {
    return (
      <div className="Notification-Section">
        <ReactRRuleGenerator
          form={this.props.form}
          onSaveData={this.onSaveData}
          translate={this.props.translate}
          attached={this.props.attached}
          removeCalendar={this.props.removeCalendar}
          attachCalendar={this.props.attachCalendar}
        />
      </div>
    );
  }
}

const mapState = (state) => {
  return {};
};
const mapStateToProps = connect(mapState);
export const Screen3 = mapStateToProps(withTranslationWrapper(screen3));
