import React, { Component } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { notifyError, notifySuccess } from "../../../Utils/CustomNotifcations";
import { ScreenLast } from "./ScreenLast";
import { Screen2 } from "./Screen2";
import { ScreenTwo } from "./ScreenTwo";
import Loader from "../../../Layout/Loader";
import {
  convertSpeedUnits,
  errorHandler,
  prepareCalendar,
} from "../../../Helpers";
import {
  addNotification,
  updateNotification,
} from "../../../Actions/Notifications";
import axios from "axios";
import instance from "../../../axios";
import DeviceSelector from "../../Devices/DeviceSelector";
import withTranslationWrapper from "../../../HOC/withTranslation";

const CancelToken = axios.CancelToken;
let source;

class notification extends Component {
  constructor() {
    super();
    this.state = {
      tabPostion: 0,
      loader: false,
      responseGeofenceCall: false,
      responseListCall: false,
      devicesEnable: false,
      form: {
        deviceIds: [],
      },
      wizardData: {
        form1: {
          validatedForm: false,
          device: true,
          driver: false,
          passenger: false,
          byGroup: false,
          prv: false,
          type: "device",
          form: {
            deviceIds: [],
            driverIds: [],
            passengerIds: [],
            groupIds: [],
            all: false,
          },
        },
        form2: {
          validatedForm: false,
          prv: false,
          form: {
            functionalType: "movement",
            type: "",
            tolerance: 5,
            minLimit: "",
            maxLimit: "",
            logic: "",
            considerNull: false,
            threshold: "",
            useIgnition: false,
            analogType: "",
            digitalType: "",
            maintenanceId: "",
            maintenanceData: "",
            geoFences: [],
            input: "",
          },
        },
        form3: {
          validatedForm: false,
          isOpenEmailSection: false,
          isOpenCommandSection: false,
          isOpenSmsSection: false,
          isOpenWebSection: false,
          isOpenMobileSection: false,
          isOpenFirebaseSection: false,
          prv: false,
          form: {
            email: [{ id: 1, text: "" }],
            number: [{ id: 1, text: "" }],
            mailTo: "user",
            commandType: "command",
            commandData: "",
            commandName: "",
            sendCommand: false,
            commandId: 0,
          },
          deviceFullData: "",
          alertColor: "",
          alertSound: "",
        },
        form4: {
          validatedForm: false,
          prv: false,
          form: {
            name: "",
            description: "",
            alarmState: 10,
            interval: 5,
            initialTrigger: 0,
            schedul: "",
            geoFences: [],
          },
          scheduleModal: false,
        },
      },
    };
  }
  UNSAFE_componentWillMount() {
    let { mode, itemData } = this.props;
    if (mode && mode === "update") {
      let unit =
        this.props.logInUser.attributes &&
        this.props.logInUser.attributes.speedUnit
          ? this.props.logInUser.attributes.speedUnit
          : "Km/h";
      this.setState(
        {
          loader: true,
        },
        () => {
          let { attributes } = itemData;
          this.fetchGeoFences(attributes.geofenceId, true, "form4");
          this.fetchGeoFences(attributes.geofenceIds, false, "form2");
          this.setState(
            {
              wizardData: {
                form1: {
                  validatedForm: false,
                  device: attributes.type === "device" ? true : false,
                  driver: attributes.type === "driver" ? true : false,
                  passenger: attributes.type === "passenger" ? true : false,
                  byGroup:
                    attributes.groupIds && attributes.groupIds.length
                      ? true
                      : false,
                  prv: true,
                  type: attributes.type,
                  form: {
                    deviceIds: this.dataConvertingTostring(
                      attributes.deviceIds
                    ),
                    driverIds: this.dataConvertingTostring(
                      attributes.driverIds
                    ),
                    passengerIds: this.dataConvertingTostring(
                      attributes.passengerIds
                    ),
                    groupIds: this.dataConvertingTostring(attributes.groupIds),
                    all: itemData.always,
                  },
                },
                form2: {
                  validatedForm: false,
                  prv: true,
                  form: {
                    ...this.state.wizardData.form2.form,
                    functionalType: attributes.functionalType,
                    index: attributes.index,
                    type: itemData.type,
                    tolerance:
                      attributes.tolerance &&
                      itemData.type === "deviceOverspeed"
                        ? convertSpeedUnits(
                            attributes.tolerance || 0,
                            "kn",
                            unit
                          )
                        : attributes.tolerance || 5,
                    minLimit:
                      attributes.minLimit && itemData.type === "deviceOverspeed"
                        ? convertSpeedUnits(
                            attributes.minLimit || 0,
                            "kn",
                            unit
                          )
                        : attributes.minLimit || 0,
                    maxLimit:
                      attributes.maxLimit && itemData.type === "deviceOverspeed"
                        ? convertSpeedUnits(
                            attributes.maxLimit || 0,
                            "kn",
                            unit
                          )
                        : attributes.maxLimit || 180,
                    logic: attributes.logic,
                    input: attributes.input,
                    considerNull: attributes.considerNull,
                    threshold: attributes.threshold
                      ? itemData.type === "deviceParking"
                        ? attributes.threshold / (60 * 60000)
                        : attributes.threshold / 60000
                      : 0,
                    useIgnition: attributes.useIgnition || false,
                    analogType: attributes.analogType || "",
                    digitalType: attributes.digitalType || "",
                    maintenanceId: attributes.maintenanceId || "",
                    maintenanceData:
                      attributes.maintenanceId && attributes.maintenanceId
                        ? this.fetchListData(
                            attributes.maintenanceId,
                            "maintenance",
                            true
                          )
                        : "",
                  },
                },
                form3: {
                  validatedForm: false,
                  isOpenEmailSection: itemData.notificators.includes("mail"),
                  isOpenSmsSection: itemData.notificators.includes("sms"),
                  isOpenWebSection: itemData.notificators.includes("web"),
                  isOpenCommandSection:
                    itemData.attributes && itemData.attributes.sendCommand,
                  isOpenFirebaseSection:
                    itemData.notificators.includes("firebase"),
                  isOpenMobileSection: false,
                  prv: true,
                  form: {
                    mailTo: attributes.mailTo || "user",
                    alertColor: attributes.alertColor || "",
                    alertSound: attributes.alertSound || "",
                    email: attributes.emails
                      ? this.spiltItem(attributes.emails)
                      : [{ id: 1, text: "" }],
                    number: attributes.numbers
                      ? this.spiltItem(attributes.numbers)
                      : [{ id: 1, text: "" }],
                    commandType: attributes.commandType || "command",
                    commandData: attributes.commandData || "",
                    commandName: attributes.commandName || "",
                    commandId: attributes.commandId || 0,
                  },
                },
                form4: {
                  validatedForm: false,
                  prv: true,
                  form: {
                    ...this.state.wizardData.form4.form,
                    name: itemData.name,
                    description: itemData.description,
                    alarmState: attributes.alarmState || 0,
                    initialTrigger: attributes.initialTrigger
                      ? attributes.initialTrigger / 1000
                      : 0,
                    interval: attributes.interval
                      ? attributes.interval / 60000
                      : "",
                    schedul:
                      attributes.calendar && attributes.eventRawList
                        ? attributes.eventRawList
                        : "",
                  },
                  scheduleModal: false,
                },
              },
              form: {
                ...this.state.form,
                deviceIds: this.dataConvertingTostring(attributes.deviceIds),
              },
            },
            () => {
              this.setState({
                loader: false,
              });
            }
          );
        }
      );
    }
  }
  // data preparation supporting functions
  dataConvertingTostring = (data) => {
    let result = [];
    if (data && data.length) {
      data.map((item) => {
        result.push(item.toString());
        return null;
      });
    }
    return result;
  };
  checkIncludesSting = (item, data) => {
    if (data.includes(item)) {
      return true;
    } else {
      return false;
    }
  };
  spiltItem = (item) => {
    let result = [];
    let res = item.split(",");
    if (res.length) {
      res.map((subitem, ind) => {
        if (subitem) {
          result.push({ id: ind + 1, text: subitem });
        }
        return null;
      });
    }
    return result;
  };

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
  }

  fetchListData = (list, type, single) => {
    if (source) {
      source.cancel();
    }
    source = CancelToken.source();
    let result = [],
      fetchUrl = "/api/devices/list?";
    if (type === "device") {
      list.map((it, i) => {
        if (i === 0) {
          fetchUrl = fetchUrl + "id=" + it;
        } else {
          fetchUrl = fetchUrl + "&id=" + it;
        }
        return null;
      });
    } else if (type === "group") {
      fetchUrl = `/api/groups/get?userId=${this.props.logInUser.id}&limit=-1`;
    } else if (type === "maintenance") {
      fetchUrl = `/api/maintenance/get?userId=${this.props.logInUser.id}&limit=-1`;
    }
    this.setState({ responseListCall: true }, () => {
      axios({
        method: "GET",
        url: `${fetchUrl}`,
        cancelToken: source.token,
      }).then((response) => {
        if (response.status === 200) {
          const res = response.data;
          let { data } = res;
          if (data.length) {
            if (type === "device") {
              data.map((item) => {
                result.push(item);
                return null;
              });
            } else {
              if (single) {
                data.map((item) => {
                  if (list === item.id) {
                    result.push({
                      ...item,
                      label: item.label || item.name,
                      value: item.id,
                    });
                  }
                  return null;
                });
              } else {
                data.map((item) => {
                  let found = list.find((element) => element === item.id);
                  if (found) {
                    result.push(item);
                  }
                  return null;
                });
              }
            }
          }
          this.setState({
            responseListCall: false,
          });
        }
      });
    });
    return result;
  };
  fetchGeoFences = (list, single, value) => {
    if (source) {
      source.cancel();
    }
    source = CancelToken.source();
    let result = [];
    if (list) {
      this.setState({ responseGeofenceCall: true }, () => {
        axios({
          method: "GET",
          url: `/api/geofences/get?userId=${this.props.logInUser.id}&all=true`,
          cancelToken: source.token,
        }).then((response) => {
          if (response.status === 200) {
            const res = response.data;
            let { data } = res;
            if (data.data && data.data.length) {
              if (single) {
                data.data.map((item) => {
                  if (item.id === list) {
                    item["visible"] = true;
                  } else {
                    item["visible"] = false;
                  }
                  result.push(item);
                  return null;
                });
              } else {
                data.data.map((item) => {
                  let found = list.find((element) => element === item.id);
                  if (found) {
                    item["visible"] = true;
                  } else {
                    item["visible"] = false;
                  }
                  result.push(item);
                  return null;
                });
              }
            }
            this.setState({
              wizardData: {
                ...this.state.wizardData,
                [value]: {
                  ...this.state.wizardData[value],
                  form: {
                    ...this.state.wizardData[value].form,
                    geoFences: result,
                  },
                },
              },
              responseGeofenceCall: false,
            });
          }
        });
      });
    } else {
      this.setState({
        wizardData: {
          ...this.state.wizardData,
          [value]: {
            ...this.state.wizardData[value],
            form: { ...this.state.wizardData[value].form },
          },
        },
      });
    }
  };
  //~end~
  onChangedDevice = (item) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          deviceIds: item,
        },
      },
      () => {
        this.setState({
          devicesEnable:
            this.state.form &&
            this.state.form.deviceIds &&
            this.state.form.deviceIds.length
              ? true
              : false,
        });
      }
    );
  };

  handleChangeTab = (event, tab) => {
    let { wizardData, tabPostion } = this.state;

    if (
      (wizardData["form" + [tab]] && wizardData["form" + [tab]].prv) ||
      tab < tabPostion
      // ,()=>this.checkedDisableTab(tab)
    ) {
      this.setState({ tabPostion: tab });
    }
  };

  combineAllData = (name, data, type, parturalUpdat) => {
    if (parturalUpdat) {
      this.setState({
        wizardData: {
          ...this.state.wizardData,
          [name]: data[0],
          form1: data[1],
        },
        tabPostion: this.state.tabPostion + 1,
      });
    } else {
      if (type) {
        this.setState(
          {
            wizardData: {
              ...this.state.wizardData,
              [name]: data,
            },
            loader: true,
          },
          () => {
            this.saveNotification();
          }
        );
      } else {
        this.setState({
          wizardData: {
            ...this.state.wizardData,
            form6: data,
          },
          tabPostion: this.state.tabPostion + 1,
        });
      }
    }
  };
  checkedDisableTab = (type) => {
    let { wizardData } = this.state;

    if (wizardData[type] && wizardData[type].prv) {
      return false;
    } else {
      return true;
    }
  };

  //notification submit and also checked required fields values
  saveNotification = () => {
    let { mode, itemData } = this.props;
    let { wizardData } = this.state;
    let { form1, form2, form3, form4 } = wizardData;
    let vailidation = true;
    // if (mode && mode === "update") {
    //   if (
    //     form1.validatedForm ||
    //     form2.validatedForm ||
    //     form3.validatedForm ||
    //     form4.validatedForm
    //   ) {
    //     vailidation = true;
    //   } else {
    //     vailidation = false;
    //   }
    // } else {
    //   if (form2.validatedForm && form3.validatedForm && form4.validatedForm) {
    //     vailidation = true;
    //   } else {
    //     vailidation = false;
    //   }
    // }
    if (vailidation) {
      let dData = this.checkForAtt(wizardData, this.state.form.deviceIds);
      let obj = {
        id: (mode && mode === "update" && itemData.id) || "",
        parentId: itemData.parentId || 0,
        name: form3.form.name,
        description: form3.form.description,
        type: this.checkType(form2.form),
        //always: form1.form.all || false, //this will be chabged
        attributes: dData.attributes,
        notificators: dData.notificators,
        calendarId: 0,
      };
      if (form4.form && form4.form.schedul) {
        obj.attributes = {
          ...obj.attributes,
          calendar: form4.form.schedul.ical
            ? Buffer.from(form4.form.schedul.ical).toString("base64")
            : Buffer.from(prepareCalendar(form4.form.schedul)).toString(
                "base64"
              ),
          eventRawList: form4.form.schedul.form
            ? form4.form.schedul.form
            : form4.form.schedul,
        };
      }
      // function to submit value
      this.ac_submit(obj);
    } else {
      //triger error message of nothing changed
      this.setState({
        loader: false,
      });
      this.props.dispatch(
        notifyError(
          this.props.translate("Nothing was changed or required fields error")
        )
      );
    }
  };
  checkForLogicGeo = (list, single) => {
    let array = [],
      singleItem = "";
    if (single) {
      list &&
        list.map((item) => {
          if (item.visible) {
            singleItem = parseInt(item.id);
          }
          return null;
        });
      return singleItem;
    } else {
      list &&
        list.map((item) => {
          if (item.visible) {
            array.push(item.id);
          }
          return null;
        });
      return array;
    }
  };
  checkForAtt = (data, deviceIds) => {
    let fnData = { attributes: {}, notificators: "" };
    let { attributes, notificators } = fnData;
    if (data.form1) {
      attributes = { type: data.form1.type };
      if (data.form2.form.functionalType !== "maintenanceReminder") {
        if (data.form1.device) {
          if (data.form1.form.groupIds.length) {
            let grparray = [];
            data.form1.form.groupIds.map((dev) => {
              grparray.push(parseInt(dev));
              return null;
            });
            attributes = { ...attributes, groupIds: grparray };
          }
        } else if (data.form1.driver) {
          attributes = { ...attributes, driverIds: data.form1.form.driverIds };
        } else if (data.form1.passenger) {
          attributes = {
            ...attributes,
            passengerIds: data.form1.form.passengerIds,
          };
        }
      }
    }
    if (deviceIds.length) {
      let devarray = [];
      deviceIds.map((dev) => {
        devarray.push(parseInt(dev));
        return null;
      });
      attributes = { ...attributes, deviceIds: devarray };
    }
    if (data.form2) {
      attributes = {
        ...attributes,
        functionalType: data.form2.form.functionalType,
        index: data.form2.form.index,
      };
      if (data.form2.form.functionalType === "movement") {
        if (
          data.form2.form.type === "deviceEnterGeofence" ||
          data.form2.form.type === "deviceExitGeofence"
        ) {
          let arrayGeo = this.checkForLogicGeo(data.form2.form.geoFences);
          if (arrayGeo.length > 1) {
            attributes = {
              ...attributes,
              geofenceIds: arrayGeo,
              logic: data.form2.form.logic,
            };
          } else {
            attributes = { ...attributes, geofenceIds: arrayGeo };
          }
        } else if (data.form2.form.type === "deviceOverspeed") {
          let unit =
            this.props.logInUser.attributes &&
            this.props.logInUser.attributes.speedUnit
              ? this.props.logInUser.attributes.speedUnit
              : "Km/h";
          attributes = {
            ...attributes,
            maxLimit: parseFloat(
              convertSpeedUnits(data.form2.form.maxLimit || 0, unit, "kn", true)
            ),
            minLimit: parseFloat(
              convertSpeedUnits(data.form2.form.minLimit || 0, unit, "kn", true)
            ),
            tolerance: parseFloat(
              convertSpeedUnits(
                data.form2.form.tolerance || 0,
                unit,
                "kn",
                true
              )
            ),
          };
        } else if (data.form2.form.type === "deviceStopped") {
          attributes = {
            ...attributes,
            threshold: parseInt(data.form2.form.threshold * 60000),
            useIgnition: data.form2.form.useIgnition,
          };
        } else if (data.form2.form.type === "deviceIdling") {
          attributes = {
            ...attributes,
            threshold: parseInt(data.form2.form.threshold * 60000),
          };
        } else if (data.form2.form.type === "deviceParking") {
          attributes = {
            ...attributes,
            threshold: parseInt(data.form2.form.threshold * 60 * 60000),
          };
        }
      } else if (data.form2.form.functionalType === "maintenanceReminder") {
        attributes = {
          ...attributes,
          maintenanceId: data.form2.form.maintenanceId,
          minLimit: data.form2.form.minLimit
            ? parseInt(data.form2.form.minLimit)
            : 0,
          maxLimit: data.form2.form.maxLimit
            ? parseInt(data.form2.form.maxLimit)
            : 0,
        };
      } else if (data.form2.form.functionalType === "digital") {
        attributes = {
          ...attributes,
          input: data.form2.form.input,
          logic: data.form2.form.logic,
        };
      } else if (data.form2.form.functionalType === "analog") {
        attributes = {
          ...attributes,
          input: data.form2.form.input,
          logic: data.form2.form.logic,
          minLimit: data.form2.form.minLimit
            ? parseInt(data.form2.form.minLimit)
            : 0,
          maxLimit: data.form2.form.maxLimit
            ? parseInt(data.form2.form.maxLimit)
            : 0,
        };
      } else if (data.form2.form.functionalType === "other") {
        attributes = {
          ...attributes,
          input: data.form2.form.input,
          considerNull: data.form2.form.considerNull,
        };
      } else if (data.form2.form.functionalType === "output") {
        attributes = {
          ...attributes,
          input: data.form2.form.input,
          logic: data.form2.form.logic,
        };
      } else if (data.form2.form.functionalType === "deviceStatus") {
        attributes = {
          ...attributes,
        };
      } else if (data.form2.form.functionalType === "ecodriving") {
        attributes = {
          ...attributes,
          input: data.form2.form.input,
          maxLimit: parseFloat(data.form2.form.maxLimit),
          minLimit: parseFloat(data.form2.form.minLimit),
          tolerance: parseFloat(data.form2.form.tolerance),
        };
      } else if (data.form2.form.functionalType === "fuel") {
        attributes = {
          ...attributes,
          input: data.form2.form.functionalType,
        };
      }
      //out of moments
    }
    if (data.form6) {
      let notificatorsArray = [];
      attributes = {
        ...attributes,
        mailTo: data.form6.form.mailTo,
        commandType: data.form6.form.commandType,
        sendCommand: data.form6.isOpenCommandSection,
      };
      if (data.form6.isOpenCommandSection) {
        data.form6.form.commandType === "command"
          ? (attributes = {
              ...attributes,
              commandName: data.form6.form.commandName,
              commandId: data.form6.form.commandId,
              commandType: "",
            })
          : (attributes = {
              ...attributes,
              commandData: data.form6.form.commandData,
            });
      }
      if (data.form6.isOpenEmailSection) {
        notificatorsArray.push("mail");
        if (
          data.form6.form.mailTo !== "user" &&
          data.form6.form.email[0].text
        ) {
          let emailsarray = [];
          data.form6.form.email.map((em) => {
            if (em.text) {
              emailsarray.push(em.text);
            }
            return null;
          });
          let emails = emailsarray.join(",");
          attributes = { ...attributes, emails };
        }
      }

      if (data.form6.isOpenSmsSection && data.form6.form.number[0].text) {
        notificatorsArray.push("sms");
        let numbersarray = [];
        data.form6.form.number.map((em) => {
          if (em.text) {
            numbersarray.push(em.text);
          }
          return null;
        });
        let numbers = numbersarray.join(",");
        attributes = { ...attributes, numbers };
      }
      if (data.form6.isOpenWebSection) {
        notificatorsArray.push("web");
        attributes = {
          ...attributes,
          alertColor: data.form6.form.alertColor,
          alertSound: data.form6.form.alertSound,
        };
      }
      if (data.form6.isOpenFirebaseSection) {
        notificatorsArray.push("firebase");
      }
      notificators = notificatorsArray.join(",");
    }
    if (data.form4) {
      attributes = {
        ...attributes,
        alarmState: data.form4.form.alarmState
          ? parseInt(data.form4.form.alarmState)
          : 0,
        interval: parseInt(data.form4.form.interval * 60000),
        initialTrigger: parseInt((data.form4.form.initialTrigger || 0) * 1000),
        suspend: data.form4.form.suspend,
      };
      if (data.form2.form.type !== "geofence") {
        let arrayGeo = this.checkForLogicGeo(data.form3.form.geoFences, true);
        attributes = { ...attributes, geofenceId: arrayGeo || 0 };
      }
    }
    return { attributes, notificators };
  };
  checkType = (data) => {
    let result = "";
    if (
      data.functionalType === "movement" ||
      data.functionalType === "deviceStatus" ||
      data.functionalType === "maintenanceReminder" ||
      data.functionalType === "ecodriving" ||
      data.functionalType === "digital" ||
      data.functionalType === "analog" ||
      data.functionalType === "other" ||
      data.functionalType === "fuel"
    ) {
      result = data.type;
    } else {
      result = data.functionalType;
    }
    return result;
  };
  // submit function
  ac_submit = (prepObj) => {
    let { itemData, mode } = this.props;
    let obj = { ...prepObj };
    let fetchApiUrl = `/api/notifications/wizard`,
      callType = "POST";
    if (mode && mode === "update" && itemData && itemData.id) {
      fetchApiUrl = `/api/notifications/wizard/${itemData.id}`;
      callType = "PUT";
    }
    instance({
      url: fetchApiUrl,
      method: callType,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((notifications) => {
        if (mode && mode === "update") {
          this.props.dispatch(updateNotification(notifications));
          this.props.dispatch(
            notifySuccess(this.props.translate("notificationIsUpdated"))
          );
        } else {
          this.props.dispatch(addNotification(notifications));
          this.props.dispatch(
            notifySuccess(this.props.translate("notificationIsCreated"))
          );
        }
        this.setState({
          loader: false,
        });
        this.props.onBack();
      })
      .catch((error) => {
        this.setState({
          loader: false,
        });
        this.props.onBack();
        errorHandler(error, this.props.dispatch, this.errorCallBack);
      });
  };
  // end~...

  next = () => {
    if (
      this.state.form &&
      this.state.form.deviceIds &&
      this.state.form.deviceIds.length
    ) {
      this.setState({ tabPostion: this.state.tabPostion + 1 }, () => {});
    }
  };
  render() {
    let { tabPostion, loader, responseGeofenceCall, responseListCall } =
      this.state;
    let { mode } = this.props;
    let checkForcond = true;
    if (mode && mode === "update") {
      if (!responseGeofenceCall && !responseListCall) {
        checkForcond = true;
      } else {
        checkForcond = false;
      }
    }
    return (
      <>
        {loader || !checkForcond ? (
          <Loader />
        ) : (
          <>
            <>
              <Tabs
                value={tabPostion}
                onChange={this.handleChangeTab}
                TabIndicatorProps={{
                  style: { display: "none" },
                }}
                scrollButtons="on"
                className="custom-tabs"
              >
                <Tab
                  classes={{
                    root: "common-tab",
                    selected: "tab-selected",
                    wrapper: "tab-wrapper",
                  }}
                  label={
                    <span className="menu_item_text">
                      <span className="menu_item_text_inner">
                        {this.props.translate("functionalType")}
                      </span>
                    </span>
                  }
                />
                <Tab
                  classes={{
                    root: "common-tab",
                    selected: "tab-selected",
                    wrapper: "tab-wrapper",
                  }}
                  label={
                    <span className="menu_item_text">
                      <span className="menu_item_text_inner">
                        {this.props.translate("Units")}
                      </span>
                    </span>
                  }
                  disabled={this.checkedDisableTab("form2")}
                />
                <Tab
                  classes={{
                    root: "common-tab",
                    selected: "tab-selected",
                    wrapper: "tab-wrapper",
                  }}
                  label={
                    <span className="menu_item_text">
                      <span className="menu_item_text_inner">
                        {this.props.translate("notificators")}
                      </span>
                    </span>
                  }
                  disabled={this.checkedDisableTab("form3")}
                />
                <Tab
                  classes={{
                    root: "common-tab",
                    selected: "tab-selected",
                    wrapper: "tab-wrapper",
                  }}
                  label={
                    <span className="menu_item_text">
                      <span className="menu_item_text_inner">
                        {this.props.translate("properities")}
                      </span>
                    </span>
                  }
                  disabled={this.checkedDisableTab("form4")}
                />
              </Tabs>
            </>
            <div
              className="custom-unit-tabpanel-container"
              style={{ margin: "10px 20px" }}
            >
              {tabPostion === 0 && (
                <Screen2
                  handleChangeTab={this.handleChangeTab}
                  combineAllData={this.combineAllData}
                  {...this.state}
                  {...this.props}
                />
              )}
              {tabPostion === 1 && (
                <DeviceSelector
                  value={this.state.form?.deviceIds}
                  onChange={this.onChangedDevice}
                  next={this.next}
                  themecolors={this.props.themecolors}
                  devicesEnable={this.state.devicesEnable}
                  rows={10}
                  eventsModule
                  hideIcons
                  nextBtn
                  mode={mode}
                  handleChangeTab={this.handleChangeTab}
                  combineAllData={this.combineAllData}
                  {...this.state}
                  {...this.props}
                />
              )}
              {tabPostion === 2 && (
                <ScreenTwo
                  handleChangeTab={this.handleChangeTab}
                  combineAllData={this.combineAllData}
                  {...this.state}
                  {...this.props}
                />
              )}
              {tabPostion === 3 && (
                <ScreenLast
                  handleChangeTab={this.handleChangeTab}
                  combineAllData={this.combineAllData}
                  {...this.state}
                  {...this.props}
                />
              )}
            </div>
          </>
        )}
      </>
    );
  }
}

export const NotificationModal = withTranslationWrapper(notification);
