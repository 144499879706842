import React, { Component } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { ReactComponent as TrailerIcon } from "./../../assets/nav/trailerr.svg";
import { Link } from "react-router-dom";
import { checkPrivileges } from "../../Helpers";
import withTranslationWrapper from "../../HOC/withTranslation";

class Trailer extends Component {
  render() {
    let data = "";
    if (this.props.items.length) {
      data = this.props.items.map((trailer) => (
        <ListRow
          key={trailer.id}
          item={trailer}
          {...this.props}
          removedItem={this.props.removedItem}
          editItem={this.props.editItem}
          selecteItem={this.props.selecteItem}
        />
      ));
    }
    return (
      <div>
        {data && (
          <div className="individual-view">
            <ul className="list-view with-padding-right">{data}</ul>
          </div>
        )}
      </div>
    );
  }
}

export class ListRow extends Component {
  render() {
    const device =
      (this.props.allTrailerLocation &&
        this.props.allTrailerLocation[this.props.item.uniqueId]) ||
      null;
    return (
      <li>
        <Link
          onClick={() => this.props.selecteItem(this.props.item)}
          className={
            "clearfix list-row " +
            (parseInt(this.props.match.params.id) ===
            parseInt(this.props.item.id)
              ? "active"
              : "")
          }
          to={"/trailers/" + this.props.item.id}
        >
          <label className="checkbox">
            <span className="unit-name">
              <span
                style={{
                  marginRight: 10,
                }}
              >
                <TrailerIcon
                  style={{
                    verticalAlign: "middle",
                    marginRight: 5,
                    marginTop: -2,
                    height: 20,
                    width: 20,
                    fill: "currentColor",
                    display: "inline-block",
                  }}
                />
              </span>

              <span className="unit-name-text">{this.props.item.name}</span>
            </span>
          </label>
          <div className="pull-right">
            {checkPrivileges("trailerDelete") && (
              <span
                className="action-item hovered"
                title={this.props.translate("delete")}
                onClick={() => this.props.removedItem(this.props.item)}
              >
                <DeleteIcon style={{ fontSize: 16 }} />
              </span>
            )}
            {checkPrivileges("trailerUpdate") && (
              <span
                className="action-item hovered"
                title={this.props.translate("edit")}
                onClick={() => this.props.editItem(this.props.item)}
              >
                <EditIcon style={{ fontSize: 16 }} />
              </span>
            )}
            {device ? (
              <span style={{ display: "flex" }}>
                <img
                  width={24}
                  height={24}
                  src={
                    "/assets/category/default/" +
                    (device.category || "default") +
                    ".svg"
                  }
                  alt=""
                />
              </span>
            ) : (
              <span className="action-item">
                <LinkOffIcon style={{ fontSize: 16 }} />
              </span>
            )}
          </div>
        </Link>
      </li>
    );
  }
}

export const TrailerShortList = withTranslationWrapper(Trailer);
