// import React from 'react';
// import {connect} from 'react-redux';
// import Notifications from 'react-notification-system-redux';
// //import Notifications from 'react-awesome-notifications';

// class DemoComponent extends React.Component {
//   closeAll = () => {
//     this.props.dispatch(Notifications.removeAll());
//   }
//   render() {
//     const {notifications} = this.props;
//     return (
//     <div>
//         {(notifications && notifications.length > 2) ? <button className="clearAllNotifcations" onClick={this.closeAll}>Clear All Notifications</button> : null}
//       <Notifications
//         notifications={notifications}
//       />
//       </div>
//     );
//   }
// }
// export default connect(
//   state => ({ notifications: state.notificationSystem })
// )(DemoComponent);
