import React, { Component } from "react";
import axios from "axios";
import {
  fetchVehicles,
  fetchGeofences,
  fetchDrivers,
  fetchDevices,
  fetchUsers,
} from "./../../Actions/Devices/index";
import { fetchTrailers } from "./../../Actions/Trailer/index";
// import { notifyError, notifySuccess } from "../../Utils/CustomNotifications";
import { connect } from "react-redux";
import { Tooltip } from "@mui/material";
import MenuItem from "../common/MenuItem";
import withTranslationWrapper from "../../HOC/withTranslation";
import { notifySuccess, notifyError } from "../../Utils/CustomNotifcations";

class ImportExcel extends Component {
  uploadFile = async (e, fileName) => {
    let api;
    if (
      ["vehicles", "drivers", "devices", "trailers", "users"].includes(fileName)
    ) {
      api = `/api/${fileName}/import`;
    } else if (fileName === "geofences") {
      api = `/api/geofences/import/circle`;
    }
    if (e.target.files && e.target.files[0]) {
      const file = await this.convertBase64(e.target.files[0]);
      const base64 = file.split("base64,");
      axios({
        method: "POST",
        url: api,
        data: {
          name: "anyName",
          contentType:
            "@file/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          oldUrl: "",
          data: base64[1],
        },
      })
        .then((response) => {
          if (response && response.data && response.data.status === "success") {
            if (fileName === "vehicles") {
              fetchVehicles(this.props.dispatch, this.props.logInUser);
            } else if (fileName === "drivers") {
              fetchDrivers(this.props.dispatch, this.props.logInUser);
            } else if (fileName === "devices") {
              this.props.dispatch(fetchDevices(this.props.logInUser, true));
            } else if (fileName === "geofences") {
              fetchGeofences(this.props.dispatch, this.props.logInUser);
            } else if (fileName === "trailers") {
              fetchTrailers(this.props.dispatch, this.props.logInUser);
            } else if (fileName === "users") {
              fetchUsers(this.props.dispatch, this.props.logInUser);
            }
            this.props.dispatch(
              notifySuccess(this.props.translate("successfullyUpload"))
            );
          } else {
            this.props.dispatch(
              notifyError(this.props.translate("invalidFileFormat"))
            );
          }
        })
        .catch((error) => {
          console.log("er =", error);
        });
    }
  };
  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  render() {
    return (
      <div>
        <Tooltip title="Upload Excel">
          <MenuItem
            style={{ minWidth: "inherit" }}
            component="label"
            onChange={(e) => this.uploadFile(e, this.props.fileName)}
          >
            {/* <PublishIcon /> */}

            {this.props.fileName !== "geofences"
              ? "Upload Excel"
              : "Circel Geofences Upload"}
            <input type="file" hidden />
          </MenuItem>
        </Tooltip>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  logInUser: state.logInUsers,
});

export default connect(mapStateToProps)(withTranslationWrapper(ImportExcel));
