import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import { withStyles } from "@mui/styles";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
  },
}))(LinearProgress);

export const LinearProgressBar = (props) => {
  let className = "";
  if (props.value < 60) {
    className = "progress-green";
  } else if (props.value < 80) {
    className = "progress-yellow";
  } else {
    className = "progress-red";
  }

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div style={{ flex: 1, marginRight: 10 }}>
        <BorderLinearProgress classes={{ bar: className }} {...props} />
      </div>
      <div>{props.value}%</div>
    </div>
  );
};
