import React, { Component } from "react";
import Icon from "@mui/material/Icon";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSatelliteDish } from "@fortawesome/free-solid-svg-icons";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link } from "react-router-dom";
import { checkPrivileges } from "../../Helpers";
import Style from "style-it";
import withTranslationWrapper from "../../HOC/withTranslation";
library.add(faSatelliteDish);

class Driver extends Component {
  render() {
    let data = "";
    if (this.props.items.length) {
      data = this.props.items.map((driver) => (
        <ListRow
          key={driver.id}
          item={driver}
          {...this.props}
          removedItem={this.props.removedItem}
          editItem={this.props.editItem}
          selecteItem={this.props.selecteItem}
        />
      ));
    }
    return (
      <Style>
        {`
  .material-icons {vertical-align: middle; color: inherit}
`}
        <div>
          {data && (
            <div className="individual-view">
              <ul className="list-view with-padding-right">{data}</ul>
            </div>
          )}
        </div>
      </Style>
    );
  }
}

export class ListRow extends Component {
  render() {
    const device =
      (this.props.allDriverLocation &&
        this.props.allDriverLocation[this.props.item.id]) ||
      null;
    return (
      <li>
        <div
          className={
            "list-row " +
            (parseInt(this.props.match.params.id) ===
            parseInt(this.props.item.id)
              ? "active"
              : "")
          }
        >
          <Link
            onClick={(e) => this.props.selecteItem(this.props.item)}
            className="unit-name"
            to={"/drivers/" + this.props.item.id}
          >
            <span className="unit-img">
              <AccountCircleIcon style={{ fontSize: 24 }} />
            </span>
            <span className="unit-name-text">{this.props.item.name}</span>
          </Link>

          <div className="pull-right list-action-bar">
            {checkPrivileges("driverDelete") && (
              <span
                className="action-item hovered"
                title={this.props.translate("delete")}
                onClick={(e) => this.props.removedItem(this.props.item)}
              >
                <DeleteIcon style={{ fontSize: 16 }} />
              </span>
            )}
            {checkPrivileges("driverUpdate") && (
              <span
                className="action-item hovered"
                title={this.props.translate("edit")}
                onClick={() => this.props.editItem(this.props.item)}
              >
                <EditIcon style={{ fontSize: 16 }} />
              </span>
            )}
            {device ? (
              <span style={{ display: "flex" }}>
                <img
                  width={24}
                  height={24}
                  src={
                    "/assets/category/default/" +
                    (device.category || "default") +
                    ".svg"
                  }
                  alt=""
                />
              </span>
            ) : (
              <span className="action-item">
                <LinkOffIcon style={{ fontSize: 16 }} />
              </span>
            )}
          </div>
        </div>
      </li>
    );
  }
}

export const DriversShortList = withTranslationWrapper(Driver);
