import React, { useState,useEffect, useRef } from 'react'
import Dialog from '../common/Dialog'
import {
  MapContainer,
  Tooltip,
  TileLayer,
  FeatureGroup,
  Circle,
  Polygon,
  Polyline,
  // ZoomControl,
  Marker,
  LayersControl
} from 'react-leaflet'
import { EditControl } from 'react-leaflet-draw'
// import { withLocalize } from 'react-localize-redux'
import L from 'leaflet'
// import MarkerClusterGroup from './../../Components/Maps/MarkerClusterGroup'
// import CustomMarker from './../../Components/Maps/CustomMarker'
import { MapTooltip } from './../../Components/Maps/MapTooltip'
import 'leaflet-control-geocoder'
import { ReactComponent as LoaderIcon } from './../../assets/tracks-loader.svg'
import TextField from '../common/TextField'
import Button from '../common/Button'
import { SketchPicker } from 'react-color'
import { addGeoFence, updateGeoFence } from '../../Actions/Devices'
import { MapTileLayers } from '../../Reducers/Maps'
import moment from "moment"
import instance from '../../axios'
import { errorHandler } from '../../Helpers'
import { useDispatch, useSelector } from 'react-redux'
import MarkerClusterGroup from '@changey/react-leaflet-markercluster';
import { notifyError, notifySuccess } from '../../Utils/CustomNotifcations'


const GeofenceModal = (props) => {
  const [state, setState] = useState({
    minZoom: 3,
    lat: 25.097919,
    lng: 55.236683,
    zoom: 3,
    isVisible: false,
    bounds: null,
    layers: null,
    layer:null,
    layerForm: {},
    colorPicker: {},
    edit: props.geofence ? true : false,
    onFullScreenState: false,
    loading: false,
    currentLayer: null,
    onFeatureGroupAddCalled: false,
    markers: []
  })

  const [layersUpdated,setLayersUpdated]=useState(true)
  const mapRef = useRef(null)
  const map = mapRef.current;
  const isInitialized = useRef(false);
  let reactECref = useRef({});

  const mapLayer=useSelector((state)=>state.mapLayer)
  const deviceRelatedData=useSelector((state)=>state.deviceRelatedData)
  const devices=useSelector((state)=>state.devices)
  const logInUser=useSelector((state)=>state.logInUsers)
  const ServerSetting=useSelector((state)=>state.ServerSetting)
  const themecolors=useSelector((state)=>state.themeColors)

  const dispatch=useDispatch()

  const onClose = () => {
    props.onClose()
  }
  const [isDrawing, setIsDrawing] = useState(true);
  const [isLoading, setIsLoading] = useState(false);


  const handleDrawStart = () => {
      setIsDrawing(true);
  };

  const handleDrawStop = () => {
 
    setTimeout(() => {
      setIsDrawing(false);
    }, 1000);
};


  useEffect(() => {
    if (mapRef.current) {
      const map = mapRef.current;

      const geocoder = L.Control.geocoder({
        position: 'topright',
        placeholder: 'Search location...',
        defaultMarkGeocode: false
      });

      geocoder.on('markgeocode', (e) => {
        map.fitBounds([[e.geocode.center.lat, e.geocode.center.lng]]);

        if (props.getPostion) {
          props.getPostion(e.geocode.center, map.getZoom());
        }
      });

      geocoder.addTo(map);
    }
    return () => {
      if (mapRef.current) {
        mapRef.current = null;
      }
    };
  }, [props.getPostion]);
const [isAdded,setisAdded]=useState(false)

  const onFeatureGroupAdd = (e) => {
    const newLayer = e.target;
    map && map.fitBounds(e.getBounds())

    const {
      attributes,
      calendarId,
      id,
      name,
      description,
      area
    } = props.geofence;
    const newLayerForm = {
      [newLayer?._leaflet_id]: {
        attributes,
        calendarId,
        id,
        name,
        description,
        area
      }
    };
    const newLayers = [newLayer];
    setState((prevState) => ({
      ...prevState,
      onFeatureGroupAddCalled: true,
      layer: newLayer,
      layers: newLayers,
      layerForm: newLayerForm,
    }));
    setisAdded(true)
  }
useEffect(()=>{
  if(isAdded){
      state.layer?.bindPopup(htmlContent(state.layer._leaflet_id)).openPopup();
      reactECref.current?._toolbars?.edit?._toolbarContainer?.children[0]?.click();
      setisAdded(false)
  }
  return () => {
    if (mapRef.current) {
      mapRef.current = null;
    }
  };
},[isAdded])
  const htmlContent = (id) => {
    if (state.layerForm[id]) {
      let html = '<div className="position-box"><strong>Name: </strong>';

      html += state.layerForm[id].name;
      html += '<div className="position-box-body"><strong>Description</strong>:';
      html += (state.layerForm[id].description || '')
      if (state.layerForm[id] && state.layerForm[id].attributes) {
        if (state.layerForm[id].attributes.type === 'circle') {
          html += '<br /><div><strong>Radius</strong>: ' + ((state.layerForm[id].attributes.radius / 1000).toFixed(2) + ' km') + '</div>'
        }
        else if (state.layerForm[id].attributes.type === 'polyline') {
          html += '<br /><div><strong>Polyline Distance</strong>: ' + ((state.layerForm[id].attributes.polylineDistance || 0).toFixed(2) + ' km') + '</div>';
        }
        else {
          html += '<br /><strong>Area</strong>: ' + ((state.layerForm[id].attributes.area || 0).toFixed(2) + ' m2');
        }
      }
      html += '</div></div>'
      return html
    } else {
      return null
    }
  }

  const onDrawStop = (t) => {
    if (t && t.target && t.target._targets) {
      let layers = []
      Object.keys(t.target._targets).map((layer) => {
        if (layer + '' !== t.target._containerId + '') {
          let l = t.target._targets[layer]
          if (l && l._path) {
            layers.push(prepareLayerForm(l))
          }
        }
        return null
      })
      if (layers.length === 0) {
        //console.log('zero');
      }
      assignValues(layers)
    }
  }



  function colorToRgba(color, opacity) {
    // Function to convert HEX to RGBA
    function hexToRgba(hex, opacity) {
      hex = hex.replace(/^#/, '');
      
      if (hex.length === 3) {
        hex = hex.split('').map(char => char + char).join('');
      }
      
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }

    const namedColors = {
      red: '255, 0, 0',
      blue: '0, 0, 255',
      green: '0, 128, 0',
      yellow: '255, 255, 0',
      black: '0, 0, 0',
      white: '255, 255, 255',
    };
    if (color.startsWith('#')) {
      // HEX color
      return hexToRgba(color, opacity);
    } else {
      // Named color
      const rgb = namedColors[color.toLowerCase()];
      if (rgb) {
        return `rgba(${rgb}, ${opacity})`;
      } else {
        return `rgba(0, 0, 0, ${opacity})`;
      }
    }
  }

  function updateStrokeColor(layer, color) {
    if (layer && layer._path) {
        layer._path.setAttribute('stroke', color);
        layer._path.style.stroke = color;
        layer.redraw();
    }
}
  const prepareLayerForm = (layer) => {

    let color = layer.options.color
    let layerForm = state.layerForm

    if (layerForm[layer._leaflet_id]) {
      color = layer.options.color =
        layerForm[layer._leaflet_id].attributes.color
    }

    if (layer instanceof L.Polygon) {
      const seeArea = L.GeometryUtil.geodesicArea(layer._latlngs[0]);
      layer._path.attributes.fill.value = colorToRgba(color,0.3);
      updateStrokeColor(layer,color)

      // layer._path.attributes.stroke.value = color

      let prepare = layer._latlngs[0].map(pos => {
        return '' + pos.lat + ' ' + pos.lng + ''
      })

      if (!layerForm[layer._leaflet_id]) {
        layerForm[layer._leaflet_id] = {
          name: 'New Polygon',
          calendarId: 0,
          description: '',
          area: 'POLYGON((' + prepare.join(', ') + '))',
          attributes: {
            type: 'polygon',
            color,
            latlng: layer._latlngs[0],
            area: seeArea
          }
        }
      } else {
        layerForm[layer._leaflet_id] = {
          ...layerForm[layer._leaflet_id],
          area: 'POLYGON((' + prepare.join(', ') + '))',
          attributes: {
            ...layerForm[layer._leaflet_id].attributes,
            color,
            latlng: layer._latlngs[0],
            area: seeArea
          }
        }
      }
    } else if (layer instanceof L.Polyline) {
      layer._path.attributes.fill.value = colorToRgba(color,0.3);
      updateStrokeColor(layer,color)

      // layer._path.attributes.stroke.value = color

      let distance = 0
      let prepare = layer._latlngs.map((pos, index) => {
        distance += pos.distanceTo(layer._latlngs[index - 1] || pos)
        return '' + pos.lat + ' ' + pos.lng + ''
      })

      if (!layerForm[layer._leaflet_id]) {
        layerForm[layer._leaflet_id] = {
          name: 'New Polyline',
          calendarId: 0,
          description: '',
          area: 'LINESTRING (' + prepare.join(', ') + ')',
          attributes: {
            type: 'polyline',
            color,
            latlng: layer._latlngs,
            polylineDistance: distance / 1000
          }
        }
      } else {
        layerForm[layer._leaflet_id] = {
          ...layerForm[layer._leaflet_id],
          area: 'LINESTRING (' + prepare.join(', ') + ')',
          attributes: {
            ...layerForm[layer._leaflet_id].attributes,
            color,
            latlng: layer._latlngs,
            polylineDistance: distance / 1000
          }
        }
      }
    } else {
      layer._path.attributes.fill.value = colorToRgba(color,0.3);
      updateStrokeColor(layer,color)
      // layer._path.attributes.stroke.value = color
      if (!layerForm[layer._leaflet_id]) {
        layerForm[layer._leaflet_id] = {
          name: 'New Circle',
          calendarId: 0,
          description: '',
          area:
            'CIRCLE (' +
            layer._latlng.lat +
            ' ' +
            layer._latlng.lng +
            ', ' +
            layer._mRadius +
            ')',
          attributes: {
            type: 'circle',
            radius: layer._mRadius,
            color,
            latlng: [layer._latlng.lat, layer._latlng.lng]
          }
        }
      } else {
        layerForm[layer._leaflet_id] = {
          ...layerForm[layer._leaflet_id],
          area:
            'CIRCLE (' +
            layer._latlng.lat +
            ' ' +
            layer._latlng.lng +
            ', ' +
            layer._mRadius +
            ')',
          attributes: {
            ...layerForm[layer._leaflet_id].attributes,
            radius: layer._mRadius,
            color,
            latlng: [layer._latlng.lat, layer._latlng.lng]
          }
        }
      }
    }
    setState((prevState) => ({
      ...prevState,
      layerForm: layerForm
    }))
    // setTimeout(() => {
    //   layer.bindPopup(htmlContent(layer._leaflet_id)).openPopup();
    // }, 0);

    return layer
  }

  const assignValues = (layerss) => {
    setState((prevState) => ({
      ...prevState,
      layers: layerss
    }))
  }


  const onChange = (field, index, event) => {
    let updatedLayerForm = state.layerForm
    updatedLayerForm[index] = { ...updatedLayerForm[index], [field]: event.target.value }
    setState((prevState) => ({
      ...prevState,
      layerForm: updatedLayerForm
    }))

    let layer = state.layers?.find(l => l._leaflet_id + '' === index + '')
    layer?._popup?.setContent(htmlContent(layer?._leaflet_id))
  }
  useEffect(()=>{
checkRequiredFields()
return () => {
  if (mapRef.current) {
    mapRef.current = null;
  }
};
  },[state.layerForm])
  const checkRequiredFields = () => {
    setState((prevState) => ({
      ...prevState,
      isVisible: false
    }))

    Object.values(state.layerForm).map(({ name }) => {
      if (!name) {
        setState((prevState) => ({
          ...prevState,
          isVisible: true
        }))
      }
    })
  }
  const showColorPicker = (e, id) => {
    setState((prevState) => ({
      ...prevState,
      colorPicker: {
        ...prevState.colorPicker,
        [id]: true
      }
    }))
  }

  const hideColorPicker = (e, id) => {
    setState((prevState) => ({
      ...prevState,
      colorPicker: {
        ...prevState.colorPicker,
        [id]: false
      }
    }))
  }

  const updateColor = (e, id) => {
    let layer = state.layers.find(l => l._leaflet_id + '' === id + '')
    layer.options.color = layer._path.attributes.fill.value = layer._path.attributes.stroke.value =colorToRgba(e.hex,0.3)
    updateStrokeColor(layer,e.hex)

    setState((prevState) => ({
      ...prevState,
      layerForm: {
        ...prevState.layerForm,
        [id]: {
          ...prevState.layerForm[id],
          attributes: {
            ...prevState.layerForm[id].attributes,
            imported: false,
            color: e.hex
          }
        }
      }
    }))
  }

  const _onEditControlReady = (reactECrefar) => {
    if (!isInitialized.current) { 
      reactECref.current = reactECrefar; 
      isInitialized.current = true;
    }
  };

  const update = (updatedItem, id) => {
    if (!updatedItem.name) {
    props.dispatch(
        notifyError(props.translate("somthingWentWrongMessage"))
      );

    } else {
      setState((prevState) => ({ ...prevState, loading: true }))
      setIsLoading(true)
      instance({
        url: `/api/geofences/${id}`,
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {
          ...updatedItem,
          id:parseInt(id)
        }
      })
        .then(geofence => {
          setState((prevState) => ({ ...prevState, loading: false }))
          dispatch(updateGeoFence({ ...geofence, visible: false }))
          dispatch(updateGeoFence({ ...geofence, visible: true }))
          onClose()
          props.dispatch(
            notifySuccess(props.translate("geofenceUpdated"))
          );
      setIsLoading(false)

        })
        .catch(error => { 
      setIsLoading(false)
          
          errorHandler(error,dispatch) })
    }
  }


const addShape = (type)=> {
  if (type === 'circle') {
    reactECref.current?._toolbars?.draw?._toolbarContainer?.children[2]?.click()
  } else if (type === 'polygon') {
    reactECref.current?._toolbars?.draw?._toolbarContainer?.children[1]?.click()
  } else if (type === 'polyline') {
    reactECref.current?._toolbars?.draw?._toolbarContainer?.children[0]?.click()
  }
}

const save = () => {
  if (isLoading || state.loading) {
    return;
  }

  if (state.layerForm && Object.keys(state.layerForm).length) {
    setState((prevState) => ({ ...prevState, loading: true }));
    setIsLoading(true);

    const totalItems = Object.keys(state.layerForm)?.length;
    let completedRequests = 0;
    let hasError = false;

    Object.keys(state.layerForm).forEach((id) => {
      const item = state.layerForm[id];

      if (item && !item.name) {
        props.dispatch(
          notifyError(props.translate("somthingWentWrongMessage"))
        );
        setState((prevState) => ({ ...prevState, loading: false }));
        setIsLoading(false);
      } else {
        let updatedItem = state.layerForm[id];
        instance({
          url: `/api/geofences`,
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          data: { ...updatedItem },
        })
          .then((geofence) => {
            dispatch(addGeoFence([{ ...geofence, visible: true }]));
            completedRequests += 1;
            props.dispatch(
              notifySuccess(props.translate("geofenceCreated"))
            );
            if (completedRequests === totalItems && !hasError) {
              setState((prevState) => ({ ...prevState, loading: false }));
              setIsLoading(false);
              onClose();
              handleDrawStop();
            }
          })
          .catch((error) => {
            hasError = true;
            completedRequests += 1;
            errorHandler(error, dispatch);
            if (completedRequests === totalItems) {
              setState((prevState) => ({ ...prevState, loading: false }));
              setIsLoading(false);
            }
          });
      }
    });
  }
};


useEffect(() => {
  if (state.onFullScreenState && mapRef.current) {
    const timeoutId = setTimeout(() => {
      if (mapRef.current) {
        mapRef.current?.invalidateSize();
      }
    }, 50);

    return () => {
      clearTimeout(timeoutId);
    };
  }
}, [state.onFullScreenState]);

const onFullScreen = () => {
setState((prevState)=>({
...prevState,
onFullScreenState:!prevState.onFullScreenState
}))
};

useEffect(() => {
  if (props.center) {
    setState((prevState) => ({
      ...prevState,
      ...props.center,
      zoom: props.zoom || logInUser.zoom
    }))

  } else if (logInUser.zoom) {
    setState((prevState) => ({
      ...prevState,
      zoom: logInUser.zoom,
      lat: logInUser.latitude,
      lng: logInUser.longitude

    }))
  } else if (ServerSetting.zoom) {
    setState((prevState) => ({
      ...prevState,
      zoom: ServerSetting.zoom,
      lat: ServerSetting.latitude,
      lng: ServerSetting.longitude

    }))
  }
}, [])


// showUnits
useEffect(() => {
  const updateMarkers = () => {
    const { trailers,drivers,trackId, themecolors, logInUser, translate } = props;

    if (deviceRelatedData && Object.keys(deviceRelatedData).length) {
      const newMarkers = [];

      devices.data &&
        devices.data.forEach((deviceData) => {
          const position = deviceRelatedData[deviceData.id];
          const driver =
            drivers &&
            drivers.find(
              (dr) =>
                position &&
                position.exists &&
                position.driverId &&
                position.driverId === dr.id
            );
          const trailer =
            trailers &&
            trailers.find(
              (t) =>
                position &&
                position.exists &&
                position.attributes.trailerUniqueId &&
                position.attributes.trailerUniqueId === t.uniqueId
            );

          if (position && position.exists && deviceData && deviceData.visible === true) {
            newMarkers.push(
              <Marker
              key={deviceData.id}
              position={{ lat: position.latitude, lng: position.longitude, updated: moment(position.serverTime) }}
              rotationAngle={0}
              rotationOrigin='center'
              animationTime={0.5}
              tracking={trackId}
              icon={L.divIcon({
                iconUrl:
                  '/assets/category/default/' +
                  (deviceData.category || 'default') +
                  'top.svg',
                iconSize: [50, 50],
                iconAnchor: [25, 25],
                tooltipAnchor: [0, -20],
                className: 'custom-marker',
                html: `<img
                  style="transform: rotate(${deviceData.course}deg)"
                    src=
                      '/assets/category/default/${deviceData.category ||
                        'default'}top.svg'
                    
                    alt=''
                  />`
              })}
              iconSize={[50, 50]}
            >
              <Tooltip direction={'top'}>
              <MapTooltip
                    trailer={trailer}
                    themecolors={themecolors}
                    position={position}
                    driver={driver}
                    device={deviceData}
                    logInUser={logInUser}
                    translate={translate}
                    // updateVisible={updateVisible}
                  />
              </Tooltip>
            </Marker>
            );
          }
        });
      setState((prevState) => ({
        ...prevState,
        markers: newMarkers
      }))
    } else {
      setState((prevState) => ({
        ...prevState,
        markers: null
      }))
    }
  };

  updateMarkers();
}, [props.deviceRelatedData, props.devices, props.drivers, props.trailers, props.trackId])



const changeBaseLayer = (event) => {

  if (map) {
    const center = map.getCenter();
    map.options.crs = ['yandexMap', 'yandexSat'].includes(event.layer.options && event.layer._leaflet_id) ? L.CRS.EPSG3395 : L.CRS.EPSG3857;
    map.setView(center);
  }

  setState((prevState) => ({
    ...prevState,
    currentLayer: event.layer.options
  }))

}

const position = [state.lat, state.lng]
// const drawOptions = {
//   shapeOptions: {
//     color: 'red',
//     opacity: 1,
//     showArea: true
//   }
// }
// const settings = {
//   rectangle: false,
//   circle: false,
//   circlemarker: false,
//   marker: false,
//   polyline: false,
//   polygon: false,
//   continueDrawing:true
// }
// const drawSettings = {
//   ...settings,
//   circle: drawOptions,
//   polygon: drawOptions,
//   polyline: drawOptions,
//   continueDrawing:true
// }
const body = [
  <>
    <FeatureGroup>
      {
        isDrawing&&
        <EditControl
        onMounted={_onEditControlReady}
        draw={{
          polyline: state.edit?false:true,
          circle: state.edit?false:true,
          marker: false,
          circlemarker: false,
          polygon: state.edit?false:true,
          rectangle:false
      }}
        // draw={state.edit ? settings : drawSettings}
        edit={{
          edit:state.edit?layersUpdated:false,
          remove: state.edit ? false : true
        }}

        //position='bottomleft'
        onDrawStop={onDrawStop}
        onDrawStart={handleDrawStart}
        onEditStop={onDrawStop}
        onDeleted={onDrawStop}
      />
      }
   
      {state.edit &&
        props.geofence &&
        props.geofence.attributes &&
        props.geofence.attributes.type === 'circle' && (
          <Circle
            // ref={e => !state.onFeatureGroupAddCalled && onFeatureGroupAdd(e)}
            eventHandlers={{
              add: (e) => {
                onFeatureGroupAdd(e)
              },
            }}
            id={props.geofence.id}
            radius={props.geofence.attributes.radius}
            center={props.geofence.attributes.latlng}
            color={props.geofence.attributes.color}
          />
        )}
      {state.edit &&
        props.geofence &&
        props.geofence.attributes &&
        props.geofence.attributes.type === 'polygon' && (
          <Polygon
            // ref={e => !state.onFeatureGroupAddCalled && onFeatureGroupAdd(e)}
            eventHandlers={{
              add: (e) => {
                onFeatureGroupAdd(e)
              },
            }}
            id={props.geofence.id}
            positions={props.geofence.attributes.latlng}
            color={props.geofence.attributes.color}
          />
        )}
      {state.edit &&
        props.geofence &&
        props.geofence.attributes &&
        props.geofence.attributes.type === 'polyline' && (
          <Polyline
            // ref={e => !state.onFeatureGroupAddCalled && onFeatureGroupAdd(e)}
            eventHandlers={{
              add: (e) => {
                onFeatureGroupAdd(e)
              },
            }}
            id={props.geofence.id}
            positions={props.geofence.attributes.latlng}
            color={props.geofence.attributes.color}
          />
        )}
    </FeatureGroup>
    {props.logInUser?.attributes &&
          props.logInUser.attributes?.clusters ? (
            <MarkerClusterGroup key={1}>{state.markers}</MarkerClusterGroup>
          ) : (
            state.markers
          )}
        {/* end */}
    {/* <ZoomControl position={'topright'} /> */}
    {/* {logInUser.attributes &&
          logInUser.attributes.clusters ? (
            <MarkerClusterGroup key={1}>{markers}</MarkerClusterGroup>
          ) : (
            markers
          )} */}
  </>
]

const updatedLine=()=>{
setLayersUpdated(false)
  onDrawStop({ target: { _targets: state.layers } });
  if (state.edit) {
    Object.keys(state.layerForm).map(id => {
      if (state.layers && state.layers.length) {
          let updatedItem = state.layerForm[id]
          delete updatedItem.visible
          updatedItem.attributes.imported = false
          update(updatedItem,updatedItem.id)
        
      }
      return null
    })
  }
}
const isMapReady=(target)=>{
  if(target){
  const geocoder = L.Control.geocoder({
    position: 'topright',
    placeholder: 'Search location...',
    defaultMarkGeocode: false,
    expand: 'click',
    geocoder: L.Control.Geocoder.nominatim({
      htmlTemplate: r => {
        return `<span class="leaflet-control-geocoder-address-context">${r.display_name}<br>`;
      }
    })
  });

  geocoder.on('finishgeocode', e => {
    e.target._alts.childNodes.forEach(el => (el.innerHTML = 'hello'));
  });

  geocoder.on('markgeocode', e => {
    target.fitBounds([[e.geocode.center.lat, e.geocode.center.lng]]);
    setState((prevState) => ({
      ...prevState,
      pointer: (
        <Marker
          position={[e.geocode.center.lat, e.geocode.center.lng]}
          icon={L.icon({
            iconUrl: '/assets/images/location-pin.svg',
            iconSize: [79, 64],
            iconAnchor: [20, 64]
          })}
        />
      )
    }))


    if (props.getPostion && target) {
      props.getPostion(e.geocode.center, target.getZoom());
    }
  });
  geocoder.addTo(target)
}

}

return (
  <Dialog
    disableFooter
    title={props.translate('sharedGeofence')}
    onClose={onClose}
    maxWidth='md'
    onFullScreen={onFullScreen}
    geofenceModal
    
  >
    <div
      className={
        'geofence-modal ' + (state.edit ? 'hide-delete-btn ' : ' ')
      }
    >
      <div className='geofence-modal-form'>
        {state.loading ? (
          <div
            className='geofence-modal-form-loading'
            style={{
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              right: 0,
              borderRadius: 6,
              background: themecolors && themecolors.themeLightColor + 'aa',
              color: themecolors && themecolors.themeInverse,
              textAlign: 'center',
              zIndex: 99,
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              height: '100%',
              flex: 1
            }}
          >
            <LoaderIcon style={{ width: 30, height: 30, margin: 'auto' }} />
          </div>
        ) : null}
        <div className='geofence-modal-form-inner'>
          {!state.edit && (
            <p style={{ padding:0, margin: 5 }}>
              <Button onClick={() => addShape('circle')} size='small'>
                {props.translate('mapShapeCircle')}
              </Button>
              <Button
                onClick={() => addShape('polygon')}
                size='small'
                style={{ marginLeft: 8 }}
              >
                {props.translate('mapShapePolygon')}
              </Button>
              <Button
                onClick={() => addShape('polyline')}
                size='small'
                style={{ marginLeft: 8 }}
              >
                {props.translate('mapShapePolyline')}
              </Button>
            </p>
          )}
          {state.layers && state.layers.length
            ? state.layers?.map((layer,index) => (
              <div style={{ position: 'relative' }} key={index}>
                {state.colorPicker[layer._leaflet_id] && (
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      zIndex: 9
                    }}
                    onMouseLeave={e =>
                      hideColorPicker(e, layer._leaflet_id)
                    }
                  >
                    <SketchPicker
                      color={
                        state.layerForm[layer._leaflet_id] &&
                          state.layerForm[layer._leaflet_id].attributes
                            .color
                          ? state.layerForm[layer._leaflet_id]
                            .attributes.color
                          : ''
                      }
                      onChangeComplete={e =>
                        updateColor(e, layer._leaflet_id)
                      }
                    />
                  </div>
                )}
                <TextField
                  fullWidth
                  label={props.translate('sharedName')}
                  required
                  value={
                    state.layerForm[layer._leaflet_id] &&
                    state.layerForm[layer._leaflet_id].name
                  }
                  onChange={e =>
                    onChange('name', layer._leaflet_id, e)
                  }
                />
                <TextField
                  fullWidth
                  rows={5}
                  multiline
                  value={
                    state.layerForm[layer._leaflet_id] &&
                    state.layerForm[layer._leaflet_id].description
                  }
                  label={props.translate('sharedDescription')}
                  onChange={e =>
                    onChange('description', layer._leaflet_id, e)
                  }
                />
                <div style={{ margin: '15px 0' }}>
                  <label>{props.translate('selectColor')}</label>

                  <div
                    className='colorPicker-trigger'
                    style={{
                      background:
                        state.layerForm[layer._leaflet_id] &&
                          state.layerForm[layer._leaflet_id].attributes
                            .color
                          ? state.layerForm[layer._leaflet_id]
                            .attributes.color
                          : '',
                      float: 'right',
                      width: 48,
                      height: 14,
                      marginLeft: 10,
                      borderRadius: 14,
                      verticalAlign: 'middle',
                      position: 'relative'
                    }}
                    onClick={e =>
                      showColorPicker(e, layer._leaflet_id)
                    }
                  ></div>
                </div>

              </div>
            ))
            : null}
        </div>
          <div className='geofence-modal-form-footer'>
            <Button
              onClick={onClose}
              style={{ marginRight: 10 }}
              className={'button-white'}
              >
              {props.translate('sharedCancel')}
            </Button>
              {state.layers && state.layers.length ? (
            <Button
              disabled={state.isVisible || isLoading || state.loading}
              onClick={state.edit?updatedLine:save}>
              {isLoading?"loading":state.edit
                ? props.translate('update')
                : props.translate('sharedCreate')}
            </Button>
        ):null}
          </div>
      </div>
      <div className='geofence-modal-map'>
        {[
          'yandexMap',
          'yandexSat',
          'osm',
          'carto',
          'googleTerrain',
          'googleSatellite',
          'googleHybrid',
          'googleRoad',
          'baidu',
          'gccStreet',
          ''
        ].includes(mapLayer.id) ? (
          <MapContainer
            key={1}
            ref={mapRef}
            // onZoom={checkZoom}
            bounds={
              state.bounds && state.bounds.length
                ? state.bounds
                : null
            }
            // boundsOptions={setBoundOptions}
            style={{ height: props.height, width: props.width }}
            center={position}
            zoom={state.zoom}
            onBaselayerchange={changeBaseLayer}
            maxZoom={mapLayer.maxZoom}
            whenReady={({ target }) => isMapReady(target)}
          >
            {state.pointer}
            {body}
            <LayersControl position="bottomright">
              {MapTileLayers.map(layer => <LayersControl.BaseLayer
                id={layer.id} checked={layer.id === mapLayer.id} key={layer.id}
                name={props.translate(layer.name)}

              >
                <TileLayer {...layer} minZoom={state.minZoom} />
              </LayersControl.BaseLayer>)}
            </LayersControl>
          </MapContainer>
        ) : null}
      </div>
    </div>
  </Dialog>
)
}
export default GeofenceModal
