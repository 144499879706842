import React from "react";
import TextField from "../../../Components/common/TextField";
// import MenuItem from "../../../Components/common/MenuItem";
import Grid from "@mui/material/Grid";
import Autocomplete from "../../../Components/common/Autocomplete";
import { notifySuccess } from "../../../Utils/CustomNotifcations";
// import Checkbox from "../../../Components/common/CheckboxPermissions";
// import Tooltip from "../../../Components/common/Tooltip";
// import parse from "html-react-parser";
import instance from "../../../axios";
import { errorHandler } from "../../../Helpers";
// import Button from "../../../Components/common/Button";
class Sensors extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.changeAttribute = this.changeAttribute.bind(this);
    this.computedAttributes_Selection =
      this.computedAttributes_Selection.bind(this);
  }

  handleChange(name, event) {
    this.props.handleChange(name, event);
  }
  changeAttribute(name, value) {
    this.props.changeAttribute(name, value);
  }

  computedAttributes_Selection(deviceId, attributeId) {
    let obj = {
      deviceId,
      attributeId,
    };

    instance({
      url: `/api/permissions/`,
      method: "POST",
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        //this.props.dispatch(addComputedAttributeDeviceWise(item));
        //this.props.enqueueSnackbar(this.props.translate('attributeAssignedSuccessfully'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
        this.props.dispatch(
          notifySuccess(this.props.translate("attributeAssignedSuccessfully"))
        );
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
    // .catch(e => {
    //   if (e && e.text) {
    //     e.text().then(err => {
    //       alert(err)
    //     })
    //   }
    // })
  }
  render() {
    const disceteSensorsTypes = [
      {
        label: this.props.translate("sensorsTranslation.ignition"),
        value: "ignition",
      },
      {
        label: this.props.translate("devicePowerCut"),
        value: "devicePowerCut",
      },
      {
        label: this.props.translate("boardPower"),
        value: "boardPower",
      },
      // {
      //   label: this.props.translate("sensorsTranslation.enginStatus"),
      //   value: "enginStatus",
      // },
      // {
      //   label: this.props.translate("sensorsTranslation.carAlarm"),
      //   value: "carAlarm",
      // },
      // {
      //   label: this.props.translate("sensorsTranslation.doors"),
      //   value: "doors",
      // },
      // {
      //   label: this.props.translate("sensorsTranslation.custom"),
      //   value: "customDiscrete",
      // },
    ];
    return (
      <div
      //  style={{
      //   // minHeight: 300 ,
      //   background:this.props.themecolors.backgroundColor

      // }}
      >
        {/* {console.log("this.props=[====", this.props)} */}
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              id="description"
              label={this.props.translate("sharedLabel")}
              type="text"
              value={this.props.form.description || ""}
              onChange={(e) => this.handleChange("description", e)}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={6}>
            <TextField
              id="attribute"
              label={this.props.translate("positionTooltip.sensorsType")}
              type="text"
              value={this.props.form.attribute ? this.props.form.attribute : ""}
              onChange={(e) => this.handleChange("attribute", e)}
              variant="outlined"
              margin="dense"
              fullWidth
              select
            >
              {disceteSensorsTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid> */}
          <Grid item xs={6}>
            <Autocomplete
              translate={this.props.translate}
              value={this.props.form.input || ""}
              suggestions={this.props.suggestions}
              handleChange={this.changeAttribute}
              fieldName="input"
              placeholder={this.props.translate("sensorInput")}
              label={this.props.translate("sensorInput")}
            />
          </Grid>
          {/*   <Grid item xs={6}>
            <TextField
              id="minThreshold"
              label={this.props.translate("threshold")}
              type="number"
              value={this.props.form.minThreshold}
              onChange={(e) =>
                this.changeAttribute("minThreshold", e.target.value)
              }
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} m={5}>
            <Tooltip
              classes={{
                popper: "menu-popper",
                tooltip: "menu-popper-tooltip",
              }}
              title={`If this option is enabled, last known sensor's value will be considered as "current" and if disabled, sensor's current value will be calculated from the last message only.`}
            >
              <Checkbox
                canAssign
                canRemove
                translate={this.props.translate}
                checked={this.props.form.copyFromLast}
                onChange={(e) =>
                  this.changeAttribute("copyFromLast", e.target.checked)
                }
                label={this.props.translate("copyFromLast")}
              />
            </Tooltip>
          </Grid> */}
          {/* <Grid item xs={12}>
          <Grid container justify="center">
                <Button
                style={{
                  background:this.props.themecolors.backgroundColor,
                          color:"rgba(57, 58, 59, 1)",
                          border:`1px solid rgba(57, 58, 59, 1)`,
                          borderRadius:"8px",
                          width:"112px",
                          height:"32px",
                          fontWeight:"bold",
                          textTransform: "capitalize"
                }}
                    // disabled={!this.props.isVisableDiscreteBtn}
                  onClick={this.props.save}
                >
                  {this.props.translate(this.props.addButton)}
                </Button>
            </Grid>
            </Grid> */}
        </Grid>
      </div>
    );
  }
}

export default Sensors;
