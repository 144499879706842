import React, { Component, Fragment } from "react";
import axios from "axios";
import { EditForm } from "../../../Components/Geofence/editForm";
import Button from "../../../Components/common/Button";
import SearchField from "../../../Components/common/SearchField";
import { connect } from "react-redux";
import isEqual from "react-fast-compare";
import Icon from "@mui/material/Icon";
import { checkPrivileges, convertSpeedUnits } from "../../../Helpers";
import ConfirmDialoag from "../../../Components/common/ConfirmDialoag";
import Style from "style-it";
import Scrollbar from "react-scrollbars-custom";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../Loader";
import MaintenanceFilterModal from "../Components/MaintenanceFilterModal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { CheckIcon } from "../../../Components/common/CheckIcon";
import { removedMaintenance } from "../../../Actions/Maintenance";
import Tooltip from "@mui/material/Tooltip";
import SettingsIcon from "@mui/icons-material/Settings";
import { Grid } from "@mui/material";
import Card from "../../../Pages/Dashboard/components/Card/Card";
import { ReactComponent as OnlineStatus } from "../../../assets/dashboard/online.svg";
import { ReactComponent as OfflineStatus } from "../../../assets/dashboard/offline.svg";
import { ReactComponent as NotRegistered } from "../../../assets/dashboard/not-registered.svg";
import { ReactComponent as GPSNotSupport } from "../../../assets/dashboard/gps-no-support.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import Table from "../../../Components/common/TableMultiCheckbox";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
import withTranslationWrapper from "../../../HOC/withTranslation";
class Maintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}  
          .infinite-scroll-component .event-list-row {
            border-bottom: 1px solid ${this.props.themecolors.textColor}22
          }
          .infinite-scroll-component .event-list-row.active,
          .infinite-scroll-component .event-list-row:hover {
              background-color: ${this.props.themecolors.themeLightColor}88;
          } 
                
        `}
        <div className="fms-sidebar sidebar-bg-layer">
          {checkPrivileges("maintenance") && (
            <div>
              <div className="section-head section-head-filter">
                <ul className="filter-row">
                  <li>
                    <h3
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: 0,
                      }}
                    >
                      {this.props.translate("sharedMaintenance")}
                    </h3>
                  </li>
                </ul>
                <ul
                  className="list-view with-padding-right geofence-sidebar-list"
                  style={{ marginTop: 10 }}
                >
                  <Scrollbar
                    disableTracksWidthCompensation
                    scrollerProps={{
                      renderer: (props) => {
                        const { elementRef, ...restProps } = props;
                        return (
                          <div
                            {...restProps}
                            ref={elementRef}
                            id="scrollableDiv"
                          />
                        );
                      },
                    }}
                  >
                    {this.props.itemPagination ? (
                      <InfiniteScroll
                        dataLength={this.props.itemPagination.items.length}
                        // next={this.props.fetchMoreItems}
                        // hasMore={this.props.itemPagination.hasNext}
                        loader={<Loader defaultStyle={true} imgSize={50} />}
                        scrollableTarget="scrollableDiv"
                        // endMessage={this.props.endMessage}
                      >
                        <ListRow
                          analyticData={this.props.analyticData}
                          translate={this.props.translate}
                          themecolors={this.props.themecolors}
                          currencyLabel={
                            this.props.logInUser &&
                            this.props.logInUser.attributes &&
                            this.props.logInUser.attributes.currencyLabel
                          }
                        />
                      </InfiniteScroll>
                    ) : null}
                  </Scrollbar>
                </ul>
              </div>
            </div>
          )}
        </div>
      </Style>
    );
  }
}

class ListRow1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceSelector: false,
    };
  }
  openDeviceSelector = (key, name, total, type) => {
    // this.resetSelectors()
    // if (this.state.deviceSelector === false) {
    //   this.showSelectedUnits(key, 'device')
    // }
    this.setState({
      deviceSelector: !this.state.deviceSelector,
      deviceSelectorKey: name,
      filterType: key,
      totalDevices: total && total.count,
      statusType: type,
    });
  };

  render() {
    const { completed, required, scheduled, expired } =
      (this.props && this.props.analyticData) || {};
    return (
      <>
        <Grid container spacing={12}>
          <Grid item xs={12}>
            <Card themecolors={this.props.themecolors}>
              <div style={{ marginTop: 0 }}>
                {!this.state.deviceSelector ? (
                  <div style={{ paddingLeft: 16, paddingRight: 16 }}>
                    <h3
                      style={{
                        fontSize: 22,
                        fontWeight: 600,
                        // textAlign: 'center',
                        marginBottom: 5,
                        marginTop: 0,
                      }}
                    >
                      {/* {this.props.analyticData.completed && this.props.analyticData.completed.count && (this.props.analyticData.completed.count+this.props.analyticData.required.count+this.props.analyticData.scheduled.count+this.props.analyticData.expired.count)} */}
                      {((scheduled && scheduled.count) || 0) +
                        ((completed && completed.count) || 0) +
                        ((required && required.count) || 0) +
                        ((expired && expired.count) || 0)}
                      {" " + this.props.translate("total")}
                    </h3>
                  </div>
                ) : (
                  <>
                    <h3
                      style={{
                        fontSize: 18,
                        display: "flex",
                        fontWeight: 400,
                        textAlign: "left",
                        marginBottom: 8,
                        marginTop: 8,
                        justifyContent: "space-between",
                      }}
                    >
                      <span>
                        {this.props.translate(this.state.deviceSelectorKey)} (
                        {this.state.totalDevices})
                      </span>
                      <Button onClick={(e) => this.openDeviceSelector(null)}>
                        {this.props.translate("sharedBack")}
                      </Button>
                    </h3>
                  </>
                )}
                {!this.state.deviceSelector && (
                  <div className="driver-state-wrap">
                    {/* <h3>{this.state.pieChart2.total} Total</h3> */}
                    <div className="driver-state-container">
                      {checkPrivileges("maintenance") && (
                        <div
                          className="driver-state2 not-register hover"
                          onClick={(e) =>
                            this.openDeviceSelector(
                              "scheduled",
                              this.props.translate("scheduled"),
                              scheduled
                            )
                          }
                        >
                          <h3>{(scheduled && scheduled.count) || 0}</h3>
                          <p>{this.props.translate("scheduled")}</p>
                        </div>
                      )}
                      {checkPrivileges("service") && (
                        <>
                          <div
                            className="driver-state2  not-update hover"
                            style={{ background: "#F7A35C" }}
                            onClick={(e) =>
                              this.openDeviceSelector(
                                "due",
                                this.props.translate("due"),
                                required,
                                "required"
                              )
                            }
                          >
                            <h3>{(required && required.count) || 0}</h3>
                            <p>{this.props.translate("due")}</p>
                          </div>
                          <div
                            className="driver-state2 online hover"
                            onClick={(e) =>
                              this.openDeviceSelector(
                                "history",
                                this.props.translate("completed"),
                                completed
                              )
                            }
                          >
                            <h3>{(completed && completed.count) || 0}</h3>
                            <p>{this.props.translate("completed")}</p>
                          </div>
                          <div
                            className="driver-state2 offline hover"
                            onClick={(e) =>
                              this.openDeviceSelector(
                                "due",
                                this.props.translate("overdue"),
                                expired,
                                "expired"
                              )
                            }
                          >
                            <h3>{(expired && expired.count) || 0}</h3>
                            <p>{this.props.translate("overdue")}</p>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
                {this.state.deviceSelector ? (
                  <div
                    style={{
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingBottom: 10,
                    }}
                  >
                    <MaintenanceFilterModal
                      readOnly
                      rows={8}
                      filterType={this.state.filterType}
                      statusType={this.state.statusType || ""}
                    />
                  </div>
                ) : null}
              </div>
            </Card>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <div
            style={{
              padding: 15,
              background: this.props.themecolors.menuBackgroundColor,
              color: this.props.themecolors.menuTextColor,
              borderRadius: 6,
              marginTop: 20,
              height: "100%",
            }}
          >
            <h3
              style={{
                fontSize: 18,
                fontWeight: 400,
                textAlign: "left",
                marginBottom: 15,
                marginTop: 0,
              }}
            >
              {this.props.translate("expenses") + " "}
              {((scheduled && scheduled.cost) || 0) +
                ((completed && completed.cost) || 0) +
                ((required && required.cost) || 0) +
                ((expired && expired.cost) || 0)}
              {" " + (this.props.currencyLabel || "")}
            </h3>

            <Scrollbar
              disableTracksWidthCompensation
              style={{
                height: 20 * 8,
              }}
            >
              {this.props.analyticData ? (
                <MotionList
                  data={this.props.analyticData}
                  showSelectedUnits={this.openMotionSelector}
                  translate={this.props.translate}
                  currencyLabel={this.props.currencyLabel}
                />
              ) : (
                <BarGraphSkeleton2 />
              )}
            </Scrollbar>
          </div>
        </Grid>
      </>
    );
  }
}
function BarGraphSkeleton2() {
  return (
    <div
      style={{
        marginTop: 20,
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
      <div style={{ display: "flex", width: "100%" }}>
        <Skeleton className="skeleton-pulse" width={"100%"} height={35} />
      </div>
    </div>
  );
}
const MotionList = ({ data, className, translate, currencyLabel }) => {
  if (data.completed) {
    const html = (
      <div>
        <div className={"motion-status-bar hover " + className}>
          <span
            style={{ background: "#566374" }}
            className="motion-status-border"
          />{" "}
          <span className="motion-status-name">
            {translate("scheduled") + " " + translate("work")}
          </span>
          <span className="motion-status-count">
            {data.scheduled.cost + " " + (currencyLabel || "")}
          </span>
        </div>
        {checkPrivileges("service") && (
          <>
            <div className={"motion-status-bar hover " + className}>
              <span
                style={{ background: "#28a745" }}
                className="motion-status-border"
              />{" "}
              <span className="motion-status-name">
                {translate("completed") + " " + translate("work")}
              </span>
              <span className="motion-status-count">
                {data.completed.cost + " " + (currencyLabel || "")}
              </span>
            </div>
            <div className={"motion-status-bar hover " + className}>
              <span
                style={{ background: "#F7A35C" }}
                className="motion-status-border"
              />{" "}
              <span className="motion-status-name">
                {" "}
                {translate("due") + " " + translate("work")}
              </span>
              <span className="motion-status-count">
                {data.required.cost + " " + (currencyLabel || "")}
              </span>
            </div>
          </>
        )}
        {/* <div
        className={'motion-status-bar hover ' + className}
      >
        <span style={{ background: '#dc3545' }} className='motion-status-border' />{' '}
        <span className='motion-status-name'> {translate('overdue')+" "+translate('work')}</span>
        <span className='motion-status-count'>{data.expired.cost+" "+(currencyLabel||'')}</span>
      </div> */}
      </div>
    );
    return <div>{html}</div>;
  }
  return null;
};
const mapState = (state) => ({
  geoFence: state.geoFence,
  themecolors: state.themeColors,
});

const mapStateToProps = connect(mapState);
export const ListRow = mapStateToProps(withTranslationWrapper(ListRow1));
export default mapStateToProps(Maintenance);
