import React, { Fragment } from "react";
import { makeStyles } from "@mui/styles";
import Button from "./Button";
import {
  Adjust,
  CheckCircle,
  FiberManualRecord,
  Info,
  Email,
  GetApp,
} from "@mui/icons-material";
import { StepConnector } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  button: {
    // marginTop: theme.spacing(1),
    // marginRight: theme.spacing(1),
  },
  actionsContainer: {
    // marginBottom: theme.spacing(2),
    textAlign: "right",
  },
  resetContainer: {
    // padding: theme.spacing(3),
  },
}));

export default function VerticalLinearStepper(props) {
  const classes = useStyles();
  const steps = props.steps || [];
  return (
    <div className="stepper-wrapper">
      <div
        orientation="vertical"
        elevation={0}
        classes={{ root: "stepper" }}
        connector={
          <StepConnector
            classes={{
              root: "step-connector",
              active: "step-connector-active",
            }}
          />
        }
      >
        {steps.map((step, index) => (
          <Fragment key={index}>
            {index > 0 ? <hr className="hr-divider" /> : null}
            <div
              key={step.id}
              style={{ position: "relative", padding: "1px 0" }}
            >
              {props.getStepContent(step.id)}
            </div>
          </Fragment>
        ))}
        <div className={classes.actionsContainer}>
          <div>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={(e) => props.handleNext("")}
                className={classes.button}
              >
                {props.translate("submit")}
              </Button>
            </div>
            <div>
              <div
                className="report-sidebar-switch"
                style={{ marginTop: 15 }}
              ></div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => props.handleNext("downloadExcel")}
                  className={classes.button}
                >
                  <GetApp />
                  {props.translate("Excel")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => props.handleNext("downloadPdf")}
                  className={classes.button}
                >
                  <GetApp />
                  {props.translate("PDF")}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => props.handleNext("email")}
                  className={classes.button}
                >
                  <Email style={{ marginRight: 5 }} />
                  {props.translate("commandSend")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
