import React, { Component } from "react";
import { connect } from "react-redux";
import isEqual from "react-fast-compare";
import moment from "moment";
import Layout from "./../../Layout";
import Loader from "./../../Layout/Loader";
import Typography from "@mui/material/Typography";
import EmptyState from "./../../Components/common/EmptyState";
import {
  checkPrivileges,
  getDateTimeFormat,
  PaginationConfig,
  setAttributeFormat,
} from "../../Helpers";
import { RemoteReactTable } from "../../Components/common/ReactBaseTable";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { Link } from "react-router-dom";
import { isNumber } from "highcharts";
import Axios from "axios";
import { ReactComponent as LoaderIcon } from "./../../assets/tracks-loader.svg";
import ExportExcel from "../../Components/common/ExportExcel";
import instance from "../../axios";
import withTranslationWrapper from "../../HOC/withTranslation";

const TheadThStyle = {
  fontSize: 12,
  fontWeight: 700,
  width: "50%",
  padding: "7px 10px",
  color: "inherit",
};
const TheadTdStyle = {
  color: "inherit",
  fontSize: 12,
  fontWeight: 400,
  width: "50%",
  padding: "7px 10px",
  background: "none",
};

Object.defineProperty(Array.prototype, "chunk", {
  value: function (chunkSize) {
    var R = [];
    for (var i = 0; i < this.length; i += chunkSize)
      R.push(this.slice(i, i + chunkSize));
    return R;
  },
});

function DrawTableCell(props) {
  //const { children } = props.prop;
  ///const c = children.f;
  //let v = children.v;

  let value = props.children;

  let timeFormat = getDateTimeFormat();

  switch (props.id) {
    /* case 'distance':
      value = Math.round(value * 100) / 100
      break */
    case "startTime":
    case "endTime":
    case "entranceTime":
    case "exitTime":
    case "fixTime":
    case "eventTime":
    case "serverTime":
      if (value && typeof value !== "object") {
        value = new moment(value)
          .tz(props.serverTimeZone || "Asia/Dubai")
          .format(timeFormat);
      }
      break;

    case "address":
      if (
        value &&
        props.original &&
        props.original.latitude &&
        props.original.longitude
      ) {
        value = (
          <Link
            target="_blank"
            to={
              "/maps/" +
              props.original.latitude +
              "/" +
              props.original.longitude
            }
          >
            {value}
          </Link>
        );
      }
      break;

    /* case 'longitude':
      if (value && isNumber(value)) {
        value = setAttributeFormat('longitude', value)
      }
      break

    case 'latitude':
      if (value && isNumber(value)) {
        value = setAttributeFormat('latitude', value)
      }
      break
    case 'speed':
      if (value && isNumber(value)) {
        value = setAttributeFormat('speed', value)
      }
      break
    case 'course':
      if (value && isNumber(value)) {
        value = setAttributeFormat('course', value)
      }
      break */

    case "attributes":
      if (props.original) {
        const attr = props.original.attributes;
        const arr = Object.keys(attr).filter(
          (r) => r !== "unknown" && !props.attributesList.includes(r)
        );
        value = arr.map((key) => key + "=" + attr[key]).join(" ");
      } else {
        value = ""; //arr.map(key => key+"="+value[key]).join(' ');
      }
      break;
    case "sensorValue":
      if (props.original) {
        const attr = props.original.attributes;
        value = props.attributesList
          .map((key) => key + "=" + (attr[key] !== undefined ? attr[key] : ""))
          .join(" ");
      } else {
        value = ""; //arr.map(key => key+"="+value[key]).join(' ');
      }
      break;
    default:
      break;
  }

  return (
    <div
      {...props}
      className={props.className + " " + props.headerClassName + " rt-td"}
    >
      {value}
    </div>
  );
}

function drawValues(k, v, logInUser) {
  let newValue = v;
  switch (k) {
    case "motion":
      newValue = setAttributeFormat("motion", v);
      break;
    case "ignition":
      newValue = v ? "ON" : "OFF";
      break;
    case "parking":
      newValue = v ? "YES" : "NO";
      break;
    case "hdop":
      newValue = setAttributeFormat("hdop", v);
      break;
    case "hours":
    case "stopTime":
      newValue = setAttributeFormat("hours", v);
      break;
    case "batteryLevel":
      newValue = setAttributeFormat("percentage", v);
      break;
    case "deviceTemp":
      newValue = setAttributeFormat("temperature", v);
      break;
    case "power":
    case "battery":
      newValue = setAttributeFormat("voltage", v);
      break;
    case "totalDistance":
    case "distance":
      newValue = setAttributeFormat("distance", v);
      break;
    default:
      break;
  }

  return newValue;
}
function translateKeys(k, translate) {
  switch (k) {
    case "index":
    case "hdop":
    case "vdop":
    case "pdop":
    case "sat":
    case "satVisible":
    case "rssi":
    case "gps":
    case "roaming":
    case "event":
    case "alarm":
    case "status":
    case "odometer":
    case "serviceOdometer":
    case "tripOdometer":
    case "hours":
    case "steps":
    case "power":
    case "input":
    case "output":
    case "battery":
    case "batteryLevel":
    case "fuel":
    case "fuelConsumption":
    case "approximate":
    case "rpm":
    case "throttle":
    case "armed":
    case "acceleration":
    case "deviceTemp":
    case "obdSpeed":
    case "obdOdometer":
    case "distance":
    case "totalDistance":
    case "commandResult":
    case "dtcs":
    case "blocked":
    case "operator":
    case "deviceCommand":
    case "sharedGeofence":
    case "motion":
    case "vin":
    case "deviceTotalDistance":
    case "rfid":
    case "versionFw":
    case "versionHw":
    case "ignition":
    case "flags":
    case "charge":
    case "ip":
    case "archive":
    case "sharedType":
    case "driverUniqueId":
    case "raw":
    case "accel":
    case "accelBrake":
    case "accelTurn":
    case "accelBump":
    case "fuelTotal":
    case "boardPower":
    case "custom":
    case "stopTime":
    case "parking":
      return translate(k);
      break;
    default:
      return k;
      break;
  }
}

function DrawAttributes(props) {
  const { original } = props;
  const attr = original.attributes;

  if (Object.keys(attr).length) {
    let arr = Object.keys(attr).chunk(8);
    return (
      <div
        style={{
          background: "none",
          borderTop: "1px solid rgba(255,255,255, 0.5)",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {arr.map((row, tableIndex) => (
          <Table
            key={tableIndex}
            style={{ width: "350px" }}
            className="ReactTable-2"
          >
            <TableBody>
              {row.map((i) => {
                let v = attr[i];
                switch (i) {
                  case "speedLimit":
                    v = Math.round(v);
                    break;
                  default:
                    break;
                }

                if (i === "unknown") return null;

                return (
                  <TableRow key={i} style={{ height: "auto" }}>
                    <TableCell
                      style={{ ...TheadThStyle, textTransform: "capitalize" }}
                      key={i + "__n" + i}
                      className="rt-td-1"
                    >
                      {" "}
                      {translateKeys(i, props.translate)}{" "}
                    </TableCell>
                    <TableCell
                      style={{ ...TheadTdStyle }}
                      component="td"
                      key={i + "__v" + i}
                      className="rt-td-1"
                    >
                      {" "}
                      {drawValues(i, v, props.logInUser)}{" "}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        ))}
      </div>
    );
  } else {
    return (
      <div
        style={{
          fontSize: 14,
          padding: 20,
        }}
      >
        {this.props.translate("notFound")}
      </div>
    );
  }
}

class Messages extends ExportExcel {
  constructor(props) {
    super(props);
    this.state = {
      from: "",
      to: "",
      selectedDate: "",
      loading: false,
      tableLoading: false,
      validatedForm: false,
      device: {},
      device1: {},
      currentPage: 1,
      callSent: false,
      pagination: {
        ...PaginationConfig,
        pageSize: 20,
      },
      data: [],
      attributes: [],
      isExport: false,
      query: "",
    };
  }

  selectDevice = (e, date) => {
    this.setState({ device: { ...e } }, () => {
      if (this.state.device && this.state.device.value) {
        this.setState({ validatedForm: true });
      } else {
        this.setState({ validatedForm: false });
      }
    });
  };

  selectDate = (e, date) => {
    let timezone = "Asia/Dubai";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);
    var formatedDate = {
      from: moment(date.startDate.format("YYYY-MM-DD HH:mm")).toISOString(),
      to: moment(date.endDate.format("YYYY-MM-DD HH:mm")).toISOString(),
    };

    let timeFormat = getDateTimeFormat();
    this.setState(
      {
        selectedDate:
          moment(formatedDate.from).format(timeFormat) +
          " - " +
          moment(formatedDate.to).format(timeFormat),
        ...formatedDate,
      },
      () => {
        if (this.state.device && this.state.device.value) {
          this.setState({ validatedForm: true });
        } else {
          this.setState({ validatedForm: false });
        }
      }
    );
  };

  calcTime = (date, offset) => {
    let d = new Date(date);

    let utc = d.getTime() + d.getTimezoneOffset() * 60000;

    let nd = new Date(utc + 3600000 * offset);

    return nd.toISOString();
  };

  getAttributes = async (device) => {
    // instance({
    //   url: `/api/attributes/computed?deviceId=${device}&all=true`,
    //   method: "GET",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   // params:{
    //   //   deviceId: device,
    //   //   all: true
    //   // }
    // });
    return await Axios.get(
      `/api/attributes/computed?deviceId=${device}&all=true`
    );
  };

  createMessagesReport = (reset, type) => {
    const { device, from, to, validatedForm, currentPage } = this.state;
    if (type === "excel") {
      let Dform = {
        from: from,
        to: to,
        device: device.id,
      };
      this.exportFile("Dlogs", Dform);
    } else {
      this.setState({ loading: true, callSent: false }, () => {
        this.getAttributes(device.value).then(({ status, data }) => {
          if (status === 200) {
            const attributes = [];
            if (data && data.length) {
              data.map((d) => {
                if (d.category > 0) {
                  attributes.push(d.attribute);
                }
              });
            }
            attributes.push("totalDistance");
            attributes.push("hours");
            attributes.push("stopTime");
            attributes.push("idlTime");
            attributes.push("parking");

            this.setState({ attributes }, () => {
              let query = "?";

              if (validatedForm) {
                if (device && device.value) {
                  query += "&deviceId=" + device.value;
                }

                if (to) {
                  query += "&to=" + to;
                }
                if (from) {
                  query += "&from=" + from;
                }

                this.getMoreItems(query);
              }
            });
          }
        });
      });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  getMoreItems = (query) => {
    this.setState({
      query,
      callSent: true,
      tableLoading: false,
      loading: false,
    });
  };

  checkFloteNumber = (n) => {
    return Number(n) === n && n % 1 !== 0;
  };

  handleChangeRowsPerPage = (value) => {
    let { currentTab } = this.state;
    let pagItemValue = "pagination";
    let page = this.state;
    this.setState(
      {
        [pagItemValue]: {
          ...this.state[pagItemValue],
          pageSize: value,
          page: 1,
        },
        currentPage: 1,
      },
      () => {
        this.createMessagesReport();
      }
    );
  };

  handleChangePage = (page, pageSize) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page,
          pageSize,
        },
        currentPage: page,
      },
      () => this.createMessagesReport()
    );
  };

  SearchItem = (searchValue) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          page: 1,
          itemSearch: searchValue,
        },
      },
      () => this.createMessagesReport()
    );
  };

  fetchData = (page, pageSize) => {
    if (this.state.tableLoading === false) {
      this.setState({ tableLoading: true }, () => {
        this.handleChangePage(page, pageSize);
      });
    }
  };

  render() {
    let serverTimeZoneName = "Asia/Dubai";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone;
    }

    const { query } = this.state;

    if (checkPrivileges("position")) {
      return (
        <div>
          <Layout
            {...this.props}
            classFromChildren="has-padding"
            selectDate={this.selectDate}
            selectDevice={this.selectDevice}
            selectedDevice={this.state.device}
            validatedForm={this.validatedForm}
            createMessagesReport={this.createMessagesReport}
            {...this.state}
          >
            <div className="main-content-page">
              <div
                style={{
                  background: this.props.themecolors.backgroundColor,
                  color: this.props.themecolors.textColor,
                  borderRadius: 6,
                }}
              >
                {!this.state.loading && !this.state.callSent && (
                  <EmptyState
                    text={this.props.translate("noTrackersSelected")}
                  />
                )}
                {!this.state.loading ? (
                  <React.Fragment>
                    <Typography
                      variant="h5"
                      component="h4"
                      style={{
                        margin: 0,
                        color: "inherit",
                        padding: "15px 20px",
                      }}
                    >
                      {this.state.device1.label}
                    </Typography>
                    {this.state.callSent ? (
                      <RemoteReactTable
                        serverTimeZone={serverTimeZoneName}
                        data={this.state.data}
                        columns={[
                          {
                            Header: this.props.translate("address"),
                            accessor: "address",
                            width: 250,
                          },
                          {
                            Header: this.props.translate("time"),
                            accessor: "fixTime",
                            headerClassName: "text-center",
                            width: 150,
                          },
                          {
                            Header: this.props.translate("positionLatitude"),
                            accessor: "latitude",
                            headerClassName: "text-center",
                            width: 75,
                          },
                          {
                            Header: this.props.translate("positionLongitude"),
                            accessor: "longitude",
                            headerClassName: "text-center",
                            width: 75,
                          },
                          {
                            Header: this.props.translate(
                              "sensorsTranslation.speed"
                            ),
                            accessor: "speed",
                            headerClassName: "text-center",
                            width: 55,
                          },
                          {
                            Header: this.props.translate("parameters"),
                            accessor: (row) => {
                              const attr = row.attributes;
                              const arr = Object.keys(attr).filter(
                                (r) =>
                                  r !== "unknown" &&
                                  !this.state.attributes.includes(r)
                              );
                              return arr
                                .map((key) => key + "=" + attr[key])
                                .join(", ");
                            },
                            headerClassName: "text-left",
                            width: 400,
                          },
                          {
                            Header: this.props.translate("sensorValue"),
                            accessor: (row) => {
                              const attr = row.attributes;
                              return this.state.attributes
                                .map(
                                  (key) =>
                                    key +
                                    "=" +
                                    (attr[key] !== undefined ? attr[key] : "")
                                )
                                .join(", ");
                            },
                            headerClassName: "text-left",
                            width: 400,
                          },
                          {
                            Header: this.props.translate("course"),
                            accessor: "course",
                            headerClassName: "text-center",
                            width: 55,
                          },
                          {
                            Header: this.props.translate("altitude"),
                            accessor: "altitude",
                            headerClassName: "text-center",
                            width: 60,
                          },
                          {
                            Header: this.props.translate("serverTime"),
                            accessor: "serverTime",
                            headerClassName: "text-left",
                            width: 150,
                          },
                        ]}
                        query={`/api/positions/get${query}`}
                      />
                    ) : null}
                  </React.Fragment>
                ) : null}
                {this.state.loading && <Loader />}
              </div>
            </div>
          </Layout>
        </div>
      );
    } else {
      this.props.history.push("/");
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
  ServerSetting: state.ServerSetting,
});

export default connect(mapStateToProps)(withTranslationWrapper(Messages));
