import React from "react";
import { connect } from "react-redux";
import Modal from "./modal";
import isEqual from "react-fast-compare";
import withTranslationWrapper from "../../../../HOC/withTranslation";

class screen1 extends React.Component {
  constructor() {
    super();
    this.state = {};
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
  }
  UNSAFE_componentWillMount() {
    let { wizardData } = this.props;
    if (wizardData && wizardData.form1) {
      this.setState(
        {
          ...wizardData.form1,
        },
        () => this.checkRequiredFields()
      );
    }
  }
  typeSelection = (name) => {
    this.setState(
      {
        device: false,
        driver: false,
        passenger: false,
      },
      () => {
        this.setState(
          {
            [name]: true,
            type: name,
          },
          () => {
            this.checkRequiredFields();
          }
        );
      }
    );
  };
  grouptypeSelection = (name) => {
    this.setState(
      {
        device: false,
      },
      () => {
        this.setState(
          {
            [name]: !this.state[name],
            device: true,
          },
          () => {
            this.checkRequiredFields();
          }
        );
      }
    );
  };
  onChangedDevice = (item) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          deviceIds: item,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  onChangedGroup = (item) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          groupIds: item,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  onChangedDriver = (item) => {
    let ids = [];
    if (item.length) {
      item.map((item) => {
        ids.push(item.id);
        return null;
      });
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          driverIds: ids,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  onChangedPassenger = (item) => {
    let ids = [];
    if (item.length) {
      item.map((item) => {
        ids.push(item.id);
        return null;
      });
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          passengerIds: ids,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  handleChange = (name) => (event) => {
    const { target } = event;
    if (target && target.type === "checkbox") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: target.checked,
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: target.value,
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };
  checkRequiredFields() {
    let { device, driver, passenger, byGroup } = this.state;
    let { deviceIds, driverIds, passengerIds, groupIds } = this.state.form;
    let { wizardData, mode } = this.props;
    let { form } = wizardData.form1;
    let value = true;
    if (mode && mode === "update") {
      value = !isEqual(this.state.form, form);
    }
    if (
      (value && device && ((byGroup && groupIds.length) || deviceIds.length)) ||
      (driver && driverIds.length) ||
      (passenger && passengerIds.length)
    ) {
      this.setState(
        {
          validatedForm: true,
        },
        () => {
          let data = { ...this.state, prv: true };
          //this.props.combineAllData('form1', data, '', true)
          if (this.props.checkRequiredFieldsForFristForm) {
            this.props.checkRequiredFieldsForFristForm(data, value);
          }
        }
      );
    } else {
      this.setState(
        {
          validatedForm: false,
        },
        () => {
          let data = { ...this.state, prv: true };
          //this.props.combineAllData('form1', data, '', true)
          if (this.props.checkRequiredFieldsForFristForm) {
            this.props.checkRequiredFieldsForFristForm(data, value);
          }
        }
      );
    }
  }
  // next = () => {
  //   let data = { ...this.state, prv: true }
  //   this.props.combineAllData('form1', data)
  // }
  render() {
    return (
      <>
        <Modal
          {...this.props}
          {...this.state}
          typeSelection={this.typeSelection}
          grouptypeSelection={this.grouptypeSelection}
          onChangedDevice={this.onChangedDevice}
          onChangedGroup={this.onChangedGroup}
          onChangedDriver={this.onChangedDriver}
          onChangedPassenger={this.onChangedPassenger}
          handleChange={this.handleChange}
          themecolors={this.props.themecolors}
        />
        {/* <div className='clearfix'>
          <Tooltip
            title={this.props.translate('saveCurrentTabDataAndGotoNext')}
          >
            <Button
              disabled={!this.state.validatedForm}
              variant='contained'
              onClick={this.next}
              style={{ float: 'right' }}
            >
              {this.props.translate('next')}
            </Button>
          </Tooltip>
        </div> */}
      </>
    );
  }
}

export const Screen1 = connect(null, null)(withTranslationWrapper(screen1));
