import React, { Component, Fragment } from "react";
import Map from "./../../Pages/MarkerMap/Map";
import { ReactComponent as PlayIcon } from "../../assets/monitoring/play.svg";
import { ReactComponent as StopIcon } from "../../assets/monitoring/stop.svg";
import { ReactComponent as IgnitionIcon } from "../../assets/monitoring/idling.svg";
import { ReactComponent as TowingIcon } from "../../assets/monitoring/towing.svg";
import { ReactComponent as ParkingIcon } from "../../assets/monitoring/parking.svg";
import { ReactComponent as QuestionIcon } from "../../assets/monitoring/question.svg";
import { FeatureSection } from "../Features/FeatureSection";
import CloseIcon from "@mui/icons-material/Close";
import MinimizeIcon from "@mui/icons-material/Remove";
import { connect } from "react-redux";
import Draggable from "react-draggable";
import Scrollbar from "react-scrollbars-custom";
import Button from "./Button";
import isEqual from "react-fast-compare";

const maxVisible = 5;

export default class TrackingBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: {},
      mode: {},
    };
  }

  UNSAFE_componentWillReceiveProps(n) {
    if (n.boxes && n.boxes.length && !isEqual(n.boxes, this.props.boxes)) {
      n.boxes.map((r, index) => {
        if (maxVisible > index) {
          if (!Object.keys(this.state.toggle).includes(r.id)) {
            this.setState({
              toggle: { ...this.state.toggle, [r.id]: true },
            });
          }
          if (!Object.keys(this.state.mode).includes(r.id)) {
            this.setState({
              mode: { ...this.state.mode, [r.id]: "map" },
            });
          }
        } else {
          this.setState({
            toggle: { ...this.state.toggle, [r.id]: false },
            mode: { ...this.state.mode, [r.id]: "map" },
          });
        }
        return null;
      });
    }
  }

  toggleBox = (id) => {
    this.setState({
      toggle: { ...this.state.toggle, [id]: !this.state.toggle[id] },
    });
  };

  toggleViewBox = (id) => {
    this.setState({
      mode: {
        ...this.state.mode,
        [id]: this.state.mode[id] === "map" ? "info" : "map",
      },
    });
  };

  render() {
    const { boxes, closeBox, copyToClipboard, translate, logInUser } =
      this.props;
    return boxes.map((person, index) => (
      <Draggable key={index}>
        <ChatBody
          copyToClipboard={copyToClipboard}
          translate={translate}
          logInUser={logInUser}
          toggleBox={this.toggleBox}
          toggleViewBox={this.toggleViewBox}
          closeBox={closeBox}
          toggle={this.state.toggle}
          mode={this.state.mode}
          unit={person}
          index={index}
        />
      </Draggable>
    ));
  }
}
const remainder = 5;

const mapStateToProps = (state) => ({
  positions: state.positions,
  insideGeofence: state.insideGeofence,
  geofences: state.geoFence,
  deviceRelatedData: state.deviceRelatedData,
});

const ChatBody1 = ({
  deviceRelatedData,
  positions,
  insideGeofence,
  geofences,
  toggle,
  mode,
  unit,
  index,
  toggleViewBox,
  closeBox,
  toggleBox,
  copyToClipboard,
  translate,
  logInUser,
}) => {
  const row = Math.floor(index / remainder);
  const pos = positions.find((p) => p.deviceId === unit.id);
  const device = (deviceRelatedData && deviceRelatedData[unit.id]) || {};
  const { icons } = device;
  const icon =
    icons && icons.playicon && icons.playicon.icon ? icons.playicon.icon : "";
  let RelatedIcon = null;
  if (icon === "play") {
    RelatedIcon = PlayIcon;
  } else if (icon === "ignition") {
    RelatedIcon = IgnitionIcon;
  } else if (icon === "towing") {
    RelatedIcon = TowingIcon;
  } else if (icon === "parking") {
    RelatedIcon = ParkingIcon;
  } else if (icon === "stop") {
    RelatedIcon = StopIcon;
  } else if (icon === "unknown") {
    RelatedIcon = QuestionIcon;
  }
  const onClick = (e) => {
    toggleViewBox(unit.id);
  };
  return (
    <>
      <div className="chat-wrapper" key={unit.id}>
        <Draggable handle=".boxhandle">
          <div
            className="msg_box"
            style={{
              bottom:
                row === 0
                  ? 30
                  : row === 1
                  ? row * (unit.width + unit.margin + 30 + 30 + 10)
                  : unit.width + unit.margin + 30 + 40 * row,
              right:
                unit.width * (index % remainder) +
                unit.margin * ((index % remainder) + 1),
              width: unit.width,
            }}
          >
            <div className="msg_head boxhandle">
              <div className="msg-unit-icon">
                <img
                  src={`/assets/category/default/${
                    (device && device.category) || "default"
                  }.svg`}
                  alt=""
                />
              </div>
              <div style={{ marginRight: "auto" }}>{unit.name}</div>
              <div
                className={"action-item action-running " + icons?.play}
                style={{ margin: "0 10px" }}
              >
                {RelatedIcon && (
                  <RelatedIcon fill="currentColor" width={16} height={16} />
                )}
              </div>
              <div className="closebox" onClick={() => toggleBox(unit.id)}>
                <MinimizeIcon />
              </div>
              <div className="closebox" onClick={(e) => closeBox(unit.id, e)}>
                <CloseIcon />
              </div>
            </div>
            {toggle[unit.id] ? (
              <div className="msg_wrap">
                <div className="msg_body" style={{ height: unit.width }}>
                  {mode[unit.id] === "map" ? (
                    <Map
                      mapClass="chat-map-container"
                      onClick={onClick}
                      category={(device && device.category) || "default"}
                      animationTime={device.animationTime}
                      course={(pos && pos.course) || "default"}
                      lat={(pos && pos.latitude) || 0}
                      lng={(pos && pos.longitude) || 0}
                    />
                  ) : null}
                  {mode[unit.id] === "info" ? (
                    <Scrollbar>
                      <div>
                        <FeatureSection
                          key="basicDetails"
                          type="basicDetails"
                          device={device}
                          details={device}
                          translate={translate}
                          copyToClipboard={copyToClipboard}
                        />
                        <FeatureSection
                          key="Location"
                          type="Location"
                          device={device}
                          details={device}
                          insideGeofence={insideGeofence}
                          geofences={geofences}
                          logInUser={logInUser}
                          translate={translate}
                          copyToClipboard={copyToClipboard}
                        />
                        <div style={{ padding: "0 10px", textAlign: "center" }}>
                          <Button onClick={onClick}>Back to Map</Button>
                        </div>
                      </div>
                    </Scrollbar>
                  ) : null}
                </div>
                <div className="boxhandle-icon boxhandle" />
              </div>
            ) : null}
          </div>
        </Draggable>
      </div>
    </>
  );
};

const ChatBody = connect(mapStateToProps)(ChatBody1);
