import React from "react";
import TextField from "../../../Components/common/TextField";
import MenuItem from "../../../Components/common/MenuItem";
import Grid from "@mui/material/Grid";
import Autocomplete from "../../../Components/common/Autocomplete";
import {notifySuccess } from "../../../Utils/CustomNotifcations";
import Checkbox from "../../../Components/common/CheckboxPermissions";
import Tooltip from "../../../Components/common/Tooltip";
import parse from "html-react-parser";
import instance from "../../../axios";
import { errorHandler } from "../../../Helpers";
import Button from "../../../Components/common/Button";
class Sensors extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.changeAttribute = this.changeAttribute.bind(this);
    this.computedAttributes_Selection =
      this.computedAttributes_Selection.bind(this);
  }

  handleChange(name, event) {
    let e = event;
    if (name === "expression") {
      e = { target: { value: event } };
      this.props.handleChange("expression", e);
    } else {
      this.props.handleChange(name, e);
    }
  }
  changeAttribute(name, value) {
    this.props.changeAttribute(name, value);
  }

  computedAttributes_Selection(deviceId, attributeId) {
    let obj = {
      deviceId,
      attributeId,
    };

    instance({
      url: `/api/permissions/`,
      method: "POST",
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        //this.props.dispatch(addComputedAttributeDeviceWise(item));
        //this.props.enqueueSnackbar(this.props.translate('attributeAssignedSuccessfully'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
        this.props.dispatch(
          notifySuccess(this.props.translate("attributeAssignedSuccessfully"))
        );
        // } else {
        //   throw response
        // }
      })
      // .catch(e => {
      //   if (e && e.text) {
      //     e.text().then(err => {
      //       alert(err)
      //     })
      //   }
      // })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }

  render() {
    const disceteSensorsTypes = [
      {
        label: this.props.translate("sensorsTranslation.driverUniqueId"),
        value: "driverUniqueId",
      },
      {
        label: this.props.translate("sensorsTranslation.passengerId"),
        value: "passengerId",
      },
      {
        label: this.props.translate("sensorsTranslation.trailerUniqueId"),
        value: "trailerUniqueId",
      },
    ];

    return (
      <div
      //  style={{ minHeight: 300 }}
      >
        <Grid container spacing={1}>
          {/* <Grid item xs={6}>
            <TextField
              id="description"
              label={this.props.translate("sharedLabel")}
              type="text"
              value={parse(this.props.form.description) || ""}
              onChange={(e) => this.handleChange("description", e)}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid> */}
          {/* <Grid item xs={6}>
            <TextField
              id="attribute"
              label={this.props.translate("positionTooltip.sensorsType")}
              type="text"
              value={this.props.form.attribute ? this.props.form.attribute : ""}
              onChange={(e) => this.handleChange("attribute", e)}
              variant="outlined"
              margin="dense"
              fullWidth
              select
            >
              {disceteSensorsTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid> */}
          <Grid item xs={6}>
            <Autocomplete
              translate={this.props.translate}
              value={this.props.form.attributes.input || ""}
              suggestions={this.props.suggestions}
              handleChange={this.changeAttribute}
              fieldName="input"
              label={this.props.translate("sensorInput")}
            />
          </Grid>
          {/*  {this.props.form.attribute &&
          this.props.form.attribute === "driverUniqueId" ? (
            <Grid item xs={6}>
              <TextField
                id="resetDriver"
                label={this.props.translate("resetDriver")}
                type="text"
                value={
                  this.props.form.attributes &&
                  this.props.form.attributes.resetDriver
                    ? this.props.form.attributes.resetDriver
                    : "disabled"
                }
                onChange={(e) =>
                  this.changeAttribute("resetDriver", e.target.value)
                }
                variant="outlined"
                margin="dense"
                fullWidth
                select
              >
                <MenuItem value={"disabled"}>
                  {this.props.translate("sharedDisabled")}
                </MenuItem>
                <MenuItem value={"parking"}>
                  {this.props.translate("parkingStart")}
                </MenuItem>
                <MenuItem value={"ignition"}>
                  {this.props.translate("notification.ignitionOff")}
                </MenuItem>
              </TextField>
            </Grid>
          ) : null}
          {this.props.form.attribute &&
          this.props.deviceModal &&
          this.props.form.attribute === "driverUniqueId" ? (
            <Grid item xs={12}>
              <Tooltip
                classes={{
                  popper: "menu-popper",
                  tooltip: "menu-popper-tooltip",
                }}
                // title={`If this option is enabled, last known sensor's value will be considered as "current" and if disabled, sensor's current value will be calculated from the last message only.`}
              >
                <Checkbox
                  canAssign
                  canRemove
                  translate={this.props.translate}
                  checked={this.props.form.attributes.decodeToDecimal}
                  onChange={(e) =>
                    this.changeAttribute("decodeToDecimal", e.target.checked)
                  }
                  label={this.props.translate("decodeToDecimal")}
                />
              </Tooltip>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Tooltip
              classes={{
                popper: "menu-popper",
                tooltip: "menu-popper-tooltip",
              }}
              title={`If this option is enabled, last known sensor's value will be considered as "current" and if disabled, sensor's current value will be calculated from the last message only.`}
            >
              <Checkbox
                canAssign
                canRemove
                translate={this.props.translate}
                checked={this.props.form.attributes.copyFromLast}
                onChange={(e) =>
                  this.changeAttribute("copyFromLast", e.target.checked)
                }
                label={this.props.translate("copyFromLast")}
              />
            </Tooltip>
          </Grid> */}
          {/* <Grid item xs={12}   >
          <Grid container justify="center">
                <Button
                  style={{
                    background:this.props.themecolors.backgroundColor,
                            color:"rgba(57, 58, 59, 1)",
                            border:`1px solid rgba(57, 58, 59, 1)`,
                            borderRadius:"8px",
                            width:"112px",
                            height:"32px",
                            fontWeight:"bold",
                            textTransform: "capitalize"
                  }}
                    // disabled={!this.props.isVisableDiscreteBtn}
                  onClick={this.props.save}
                >
                  {this.props.translate(this.props.addButton)}
                </Button>
            </Grid>
            </Grid> */}
        </Grid>
      </div>
    );
  }
}
export default Sensors;
