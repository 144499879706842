import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Checkbox from "../../../Components/common/Checkbox";
import Style from "style-it";
import { ReactComponent as MaintenanceIcon } from "./../../../assets/nav/spanner.svg";
import SearchField from "../../../Components/common/SearchField";
import Scrollbar from "react-scrollbars-custom";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../Loader";
import withTranslationWrapper from "../../../HOC/withTranslation";
class MaintenanceFilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: {},
      others: [],
      search: "",
      isFetch: true,
      isLoader: true,
      isChecked: false,
      currentPage: 20,
      page: 1,
      itemsPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        hasNext: true,
      },
    };
  }
  UNSAFE_componentWillMount() {
    this.fetchMaintenanceHistory(
      this.state.page,
      this.state.currentPage,
      this.props.logInUser
    );
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (this.state.isFetch) {
      this.fetchMaintenanceHistory(
        this.state.page,
        this.state.currentPage,
        nextprops.logInUser
      );
    }
  }
  fetchMaintenanceHistory = (page, perPage, logInUser) => {
    let items = this.state.itemsPagination.items;
    if (this.props.filterType === "scheduled") {
      if (logInUser && logInUser.id) {
        axios({
          method: "GET",
          url: `/api/maintenance/get?userId=${logInUser.id}&page=${page}&limit=${perPage}`,
        }).then((response) => {
          if (response && response.status === 200) {
            this.setState({
              itemsPagination: {
                ...response.data,
                items: items.concat(response.data.data),
              },
              isLoader: false,
              devicesList: response.data,
              currentPageHist: response.data.hasNext
                ? response.data.page + 1
                : response.data.page,
            });
          }
        });
        this.setState({ isFetch: false });
      }
    } else {
      axios({
        method: "GET",
        url: `/api/services/${this.props.filterType}?&page=${page}&limit=${perPage}&status=${this.props.statusType}`,
      }).then((response) => {
        if (response && response.status === 200) {
          if (response.data && response.data.status === "success") {
            this.setState({
              itemsPagination: {
                ...response.data.data,
                items: items.concat(response.data.data.data),
              },
              isLoader: false,
              devicesList: response.data.data,
              currentPageHist: response.data.data.hasNext
                ? response.data.data.page + 1
                : response.data.data.page,
            });
          }
        }
      });
      this.setState({ isFetch: false });
    }
  };
  fetchMoreDevices = () => {
    if (this.state.devicesList) {
      this.setState(
        {
          page: this.state.devicesList.hasNext
            ? this.state.devicesList.page + 1
            : this.state.devicesList.page,
        },
        () => {
          this.fetchMaintenanceHistory(
            this.state.page,
            this.state.currentPage,
            this.props.logInUser
          );
        }
      );
    }
  };

  componentWillUnmount() {
    this.setState({
      list: {},
      search: "",
      isChecked: false,
    });
  }
  render() {
    const endMessage =
      this.state.devicesList && this.state.devicesList.total > 0 ? (
        <p style={{ textAlign: "center", color: "#ccc" }}>
          {" "}
          -- {this.props.translate("end")} --{" "}
        </p>
      ) : (
        <p style={{ textAlign: "center", color: "#ccc" }}>
          {" "}
          {this.props.translate("notFound")}{" "}
        </p>
      );
    return (
      <Style>
        {`
        .selector-list li > label.active,
        .selector-list li > label:hover {
            background: ${this.props.themecolors.themeLightColor};
            color: ${this.props.themecolors.themeLightInverse};
        }
        .selector-list li {
            margin-bottom: 6px
        }
        .selector-list li > label {
            cursor: pointer;
            display: block;
            padding: 2px 6px;
            border-radius: 4px;
            display: flex;
            align-items: center;
          }
          .selector-list {
            padding: 0;
            margin: 0;
            color: ${this.props.themecolors.themeListingInverse};
            list-style: none
          }
          .selector-label {
            font-size: 12px;
            line-height: 1.5;
            margin-left: 6px;
        }
        .selector-image {
            width: 26px;
            margin-left: 6px;
        }
        .selector-image img {
            height: auto;
            width: 26px;
            height: 26px;
            display: block;
            margin: auto;
        }
        .selector-count {
            padding: 0 0 6px;
            font-size: 10px;
            display: flex;
            justify-content: space-between
        }`}
        <div style={{ width: "100%" }}>
          <div
            className="section-head clearfix section-head-filter"
            style={{ padding: 0, marginLeft: -6, marginRight: -2 }}
          >
            <ul className="filter-row pull-left">
              {/* <li>
                <SearchField
                  label={this.props.translate('searchUnits')}
                  type='search'
                  placeholder='exp: ID, Name'
                  value={this.state.search}
                  onChange={this.searchItem}
                  variant='outlined'
                  margin='dense'
                  fullWidth
                />
              </li> */}
              {!this.props.readOnly ? (
                <li style={{ paddingRight: 0, paddingTop: 3, paddingLeft: 6 }}>
                  <Checkbox
                    checked={this.state.isChecked}
                    onChange={this.toggleItems}
                    label={this.props.translate("selectAll")}
                  />
                </li>
              ) : null}
            </ul>
          </div>
          {!this.props.readOnly ? (
            <div className="selector-count">
              <span>
                <span style={{ marginRight: 3 }}>
                  {Object.keys(this.state.list).length}
                </span>
                {this.props.translate("UnitsSelected")}
              </span>

              <span>
                <span style={{ marginRight: 3 }}>
                  {Object.keys(this.state.others).length}
                </span>
                {this.props.translate("more")}
              </span>
            </div>
          ) : null}
          <Scrollbar
            disableTracksWidthCompensation
            style={{
              height: 23 * (this.props.rows || 10),
            }}
            scrollerProps={{
              renderer: (props) => {
                const { elementRef, ...restProps } = props;
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    id="scrollableDeviceSelector"
                  />
                );
              },
            }}
          >
            {!this.state.isLoader &&
            this.state.itemsPagination &&
            this.state.itemsPagination.items ? (
              <InfiniteScroll
                dataLength={this.state.itemsPagination.items.length}
                next={() => this.fetchMoreDevices()}
                hasMore={this.state.itemsPagination.hasNext}
                loader={<Loader defaultStyle={true} />}
                scrollableTarget="scrollableDeviceSelector"
                endMessage={endMessage}
              >
                <ul className="selector-list">
                  {this.state.itemsPagination.items &&
                    this.state.itemsPagination.items.map((row) => (
                      <li key={row.id}>
                        <label
                          className={this.state.list[row.id] ? "active" : null}
                        >
                          {!this.props.readOnly ? (
                            <span className="selector-check">
                              <Checkbox
                                checked={this.state.list[row.id]}
                                value={row}
                                onChange={this.onChange}
                              />
                            </span>
                          ) : null}
                          {this.props.hideIcons ? null : (
                            <span className="selector-image">
                              <MaintenanceIcon
                                fill="currentColor"
                                style={{ width: 18, marginTop: 2 }}
                              />
                            </span>
                          )}
                          <span className="selector-label">{row.name}</span>
                        </label>
                      </li>
                    ))}
                </ul>
              </InfiniteScroll>
            ) : (
              <Loader defaultStyle={true} />
            )}
          </Scrollbar>
        </div>
      </Style>
    );
  }
}

const mapState = (state) => ({
  logInUser: state.logInUsers,
  devices3: state.devices3,
  devicesList: state.devices,
  themecolors: state.themeColors,
});
const mapStateToProps = connect(mapState);
export default mapStateToProps(withTranslationWrapper(MaintenanceFilterModal));
