import React, { Component } from "react";
import Button from "./../../../Components/common/Button";
import SearchItems from "./../../../Components/Devices/searchItems";
import Grid from "@mui/material/Grid";
import DateRangePicker from "./../../../Components/common/DateRangePicker";
import Scrollbar from "react-scrollbars-custom";
import { FormHelperText } from "@mui/material";

export default class MessagesSidebar extends Component {
  createMessagesReport = (type) => {
    this.props.collapseSidebar();
    this.props.createMessagesReport(1, type);
  };

  render() {
    let viewScreen = (
      <form className="section-head-filter">
        <h3 className="filter-row">{this.props.translate("DlogsForm")}</h3>
        <Grid container>
          <Grid item xs={12}>
            <DateRangePicker
              fill
              dlogs
              selectedDate={this.props.selectedDate}
              label={
                this.props.translate("reportFrom") +
                " " +
                this.props.translate("reportTo")
              }
              onEvent={this.props.selectDate}
            />
            <FormHelperText
              style={{
                fontWeight: "bold",
                color: this.props.themecolors.menuTextColor,
                fontSize: "12px",
              }} // Changed fontWeight and color
            >
              {this.props.translate(`You can select maximum 3 days`)}
            </FormHelperText>
          </Grid>

          <Grid item xs={12} style={{ padding: "0 0 8px" }}>
            <SearchItems
              api="devices"
              fill
              isMulti={false}
              onChange={this.props.selectDevice}
              value={
                this.props.selectedDevice && this.props.selectedDevice.label
                  ? this.props.selectedDevice
                  : null
              }
              placeholder={this.props.translate("selectedTrackers")}
            />
          </Grid>
          <Grid item xs={12} style={{ padding: "0 0 8px" }}>
            <Button
              fullWidth
              disabled={!this.props.validatedForm}
              onClick={this.createMessagesReport}
            >
              {this.props.translate("submit")}
            </Button>
          </Grid>
          <div
            className="report-sidebar-switch"
            style={{ margin: "0 auto", marginTop: "-5px" }}
          ></div>
          <Grid item xs={12} style={{ padding: "0 0 8px" }}>
            <Button
              fullWidth
              disabled={!this.props.validatedForm}
              onClick={(e) => this.createMessagesReport("excel")}
            >
              {this.props.translate("DOWNLOAD")}
            </Button>
          </Grid>
        </Grid>
      </form>
    );

    if (viewScreen) {
      return (
        <aside className="fms-sidebar sidebar-bg-layer">
          <Scrollbar>{viewScreen}</Scrollbar>
        </aside>
      );
    } else {
      return null;
    }
  }
}
