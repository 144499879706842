import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import isEqual from "react-fast-compare";
import Layout from "../../Layout";
import MainUnitModal from "./MainUnitModal";
//import { addDrivers } from './../../Actions/Drivers';
//import { addGroup } from './../../Actions/Groups';
import { removeUser } from "./../../Actions/Users";
import {notifySuccess } from "../../Utils/CustomNotifcations";
import ConfirmDialoag from "../../Components/common/ConfirmDialoag";
import { getGroups } from "../../Actions/Groups";
import {
  updateDevice,
  //addGeoFence,
  //addDevices
} from "../../Actions/Devices";
import {
  //getNotification,
  addNotification,
  addNotificationDeviceWise,
  RemoveNotificationDeviceWise,
  notificationDeviceWiseSelectedList,
  getNotificationDeviceWise,
  removedNotification,
  removeNotificationDeviceWiseSelectedList,
  addNotificationDeviceWiseSelectedList,
  updateNotificationDeviceWiseSelectedList,
} from "../../Actions/Notifications";
import {
  //getComputedAttributes,
  addComputedAttribute,
  computedAttributeDeviceWiseSelectedList,
  getComputedAttributeDeviceWise,
  addComputedAttributeDeviceWise,
  RemoveComputedAttributeDeviceWise,
  removedComputedAttribute,
  removedComputedAttributeDeviceWiseSelectedList,
  addComputedAttributeDeviceWiseSelectedList,
  updateComputedAttributeDeviceWiseSelectedList,
} from "../../Actions/ComputedAttributes";
import {
  //getCommands,
  addCommands,
  getCommandsDeviceWise,
  commandsDeviceWiseSelectedList,
  addCommandsDeviceWise,
  RemoveCommandsDeviceWise,
  removedCommands,
  addCommandsDeviceWiseSelectedList,
  updateCommandsDeviceWiseSelectedList,
  removedCommandsDeviceWiseSelectedList,
} from "../../Actions/Commands";
import {
  //getMaintenance,
  addMaintenance,
  addMaintenanceDeviceWise,
  RemoveMaintenanceDeviceWise,
  getMaintenanceDeviceWise,
  maintenanceDeviceWiseSelectedList,
  removedMaintenance,
  addMaintenanceDeviceWiseSelectedList,
  updateMaintenanceDeviceWiseSelectedList,
  removedMaintenanceDeviceWiseSelectedList,
} from "../../Actions/Maintenance";

import EmptyState from "../../Components/common/EmptyState";
import { GroupsModal } from "./../../Components/Groups/groupsModal";
import parse from "html-react-parser";
import {
  checkPrivileges,
  postionAttributesTypeComp,
  errorHandler,
} from "../../Helpers/index";
import "./Style.scss";
import instance from "../../axios";
import withTranslationWrapper from "../../HOC/withTranslation";

const groupDataFormat = {
  id: "",
  attributes: "",
  name: "",
  groupId: "",
};
const genteralOneFormFormat = {
  name: "",
  uniqueId: "",
  groupId: "",
  phone: "",
  devicePassword: "",
};

const advenceOneFormFormat = {
  category: "",
  positionId: "",
};
const ComputedAttributesDataFormat = {
  description: "",
  attribute: "",
  expression: "",
  type: "",
};
const NotificationDataFormat = {
  always: "",
  attributes: {},
  calendarId: "",
  id: "",
  notificators: "",
  type: "",
};
const savedCommandsDataFormat = {
  attributes: {},
  deviceId: "",
  id: "",
  description: "",
  type: "",
  textChannel: "",
};
const maintenanceDataFormat = {
  attributes: {},
  name: "",
  type: "",
  start: "",
  period: "",
};

class Units extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewScreen: null,
      groupProcess: false,
      deviceProcess: false,
      dirverProcess: false,
      geofencesProcess: false,
      notificationsProcess: false,
      maintenanceProcess: false,
      computedAttributesProcess: false,
      commandsProcess: false,
      isVisableGSOneBtn: false,
      isVisableGSTwoBtn: false,
      isVisableASOneBtn: false,
      mulitDriversSelect: "",
      mulitTrailersSelect: "",
      mulitVehiclesSelect: "",
      multiGeofencesSelect: "",
      mulitNotificationSelect: "",
      multiComputedAttributesSelect: "",
      mulitSavedCommandsSelect: "",
      mulitMaintenanceSelect: "",
      multiAccumlatorsSelect: "",
      isDeviceRecived: false,
      isGroupSelected: false,
      isDriverRecived: false,
      isTrailerRecived: false,
      isVehicleRecived: false,
      isGroRecived: false,
      isNotificationRecived: false,
      isComputedAttributesRecived: false,
      isSavedCommandsRecived: false,
      isMaintenanceRecived: false,
      isAccumlatorsRecived: false,
      attributeData: "",
      componentType: "",
      isNewAddEnable: false,
      activeOperation: "",
      //comman for all
      addButtonStatus: "sharedAdd",
      isAddVisableBtn: false,
      isAddAttributes: false,
      itemAttributes: "",
      defaultAttributes: {},
      selecteItem: "",
      //computed Att Form
      computedAttForm: { ...ComputedAttributesDataFormat },
      visableAttribute: false,
      visableListAttribute: false,
      attributeCallStatus: "",
      // nofifaction form
      notificationForm: { ...NotificationDataFormat },
      visableNotification: false,
      visableListNotification: false,
      notificationCallStatus: "",
      // Maintenance form
      maintenanceForm: { ...maintenanceDataFormat },
      visableMaintenance: false,
      visableListMaintenance: false,
      maintenanceCallStatus: "",
      // SavedCommands form
      savedCommandsForm: { ...savedCommandsDataFormat },
      visableSavedCommands: false,
      visableListSavedCommands: false,
      commandsCallStatus: "",
      // all fields of form
      gOneform: { ...genteralOneFormFormat },
      aOneform: { ...advenceOneFormFormat },
      resFormData: { ...groupDataFormat },
      // new
      isVisable: false,
      selecteditem: "",
      unassignedDevices: [],
      mulitGroupSelect: [],

      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
      currentPage2: 1,
      pageSize2: 50,
      itemPagination2: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText2: "",
      },
    };
    this.formSubmit = this.formSubmit.bind(this);
    this.generalSectionOneformSubmit =
      this.generalSectionOneformSubmit.bind(this);
    this.advenceSectionOneformSubmit =
      this.advenceSectionOneformSubmit.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onCreateNewAttributes = this.onCreateNewAttributes.bind(this);
    this.onCreateNewNotification = this.onCreateNewNotification.bind(this);
    this.onCreateNewMaintenance = this.onCreateNewMaintenance.bind(this);
    this.onCreateNewSavedCommands = this.onCreateNewSavedCommands.bind(this);
    this.onCloseC_Att_Modal = this.onCloseC_Att_Modal.bind(this);
    this.onCloseC_Modal = this.onCloseC_Modal.bind(this);
    this.maintenance_Selection = this.maintenance_Selection.bind(this);
    this.addMaintenance = this.addMaintenance.bind(this);
    this.addComputedAttributes = this.addComputedAttributes.bind(this);
    this.addSavedCommands = this.addSavedCommands.bind(this);
    this.onListMaintenance = this.onListMaintenance.bind(this);
    this.savedCommands_Selection = this.savedCommands_Selection.bind(this);
    this.onListSavedCommands = this.onListSavedCommands.bind(this);
    this.computedAttributes_Selection =
      this.computedAttributes_Selection.bind(this);
    this.onListComputedAttributes = this.onListComputedAttributes.bind(this);
    this.notification_Selection = this.notification_Selection.bind(this);
    this.onListNotification = this.onListNotification.bind(this);
    this.addNotification = this.addNotification.bind(this);
    this.onDrivresSubmit = this.onDrivresSubmit.bind(this);
    this.onTrailersSubmit = this.onTrailersSubmit.bind(this);
    this.onVehiclesSubmit = this.onVehiclesSubmit.bind(this);
    this.onDevicesSubmit = this.onDevicesSubmit.bind(this);
    this.onGroupSubmit = this.onGroupSubmit.bind(this);
    this.onGeoFenceSubmit = this.onGeoFenceSubmit.bind(this);
    this.editMaintenance = this.editMaintenance.bind(this);
    this.removeMaintenance = this.removeMaintenance.bind(this);
    this.editNotification = this.editNotification.bind(this);
    this.removeNotification = this.removeNotification.bind(this);
    this.editComputedAttributes = this.editComputedAttributes.bind(this);
    this.removeComputedAttributes = this.removeComputedAttributes.bind(this);
    this.editCommands = this.editCommands.bind(this);
    this.removeCommands = this.removeCommands.bind(this);
    this.addNotificationAttributes = this.addNotificationAttributes.bind(this);
    this.addMaintenanceAttributes = this.addMaintenanceAttributes.bind(this);
    this.changeAttribute = this.changeAttribute.bind(this);
    this.onCloseAttModal = this.onCloseAttModal.bind(this);
    this.addNewGroup = this.addNewGroup.bind(this);
    this.attributeHandle = this.attributeHandle.bind(this);
    this.notificationHandle = this.notificationHandle.bind(this);
    this.savedCommandsHandle = this.savedCommandsHandle.bind(this);
    this.maintenancesHandle = this.maintenancesHandle.bind(this);
    // new
    this.removedItem = this.removedItem.bind(this);
  }

  componentWillMount() {
    if (this.props.match.params.id) {
      this.setState({ viewScreen: "edit" });
    }
    if (
      checkPrivileges("group") &&
      this.props.logInUsers &&
      this.props.logInUsers.id
    ) {
      this.setState({ groupsFetched: true }, () => {
        instance({
          method: "GET",
          url: `/api/groups/get?userId=${this.props.logInUsers.id}`,
          // cancelToken: source.token,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            // if (response.status === 200) {
            response.data.map((item) => {
              item.check = false;
              return null;
            });
            this.props.dispatch(getGroups(response.data));
            // }
          })
          .catch((error) => {
            // errorHandler(error, this.props.dispatch)
            // this.displayError(e)
          });
      });
    }
  }

  componentWillReceiveProps(n) {
    if (n.match.params.id) {
      this.setState({ viewScreen: "edit" });
    }
    if (
      checkPrivileges("group") &&
      n.logInUsers &&
      n.logInUsers.id !== this.props.logInUsers.id
    ) {
      this.setState({ groupsFetched: true }, () => {
        instance({
          method: "GET",
          url: `/api/groups/get?userId=${n.logInUsers.id}`,
          // cancelToken: source.token,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            // if (response.status === 200) {
            response.data &&
              response.data.map((item) => {
                item.check = false;
                return null;
              });
            this.props.dispatch(getGroups(response.data));
            // }
          })
          .catch((error) => {
            // errorHandler(error, this.props.dispatch)
            // this.displayError(e)
          });
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      viewScreen: null,
      groupProcess: false,
      deviceProcess: false,
      dirverProcess: false,
      geofencesProcess: false,
      notificationsProcess: false,
      maintenanceProcess: false,
      computedAttributesProcess: false,
      commandsProcess: false,
      isVisableGSOneBtn: false,
      isVisableGSTwoBtn: false,
      isVisableASOneBtn: false,
      mulitDriversSelect: "",
      mulitTrailersSelect: "",
      mulitVehiclesSelect: "",
      multiGeofencesSelect: "",
      mulitNotificationSelect: "",
      multiComputedAttributesSelect: "",
      mulitSavedCommandsSelect: "",
      mulitMaintenanceSelect: "",
      multiAccumlatorsSelect: "",
      isDeviceRecived: false,
      isGroupSelected: false,
      isDriverRecived: false,
      isTrailerRecived: false,
      isVehicleRecived: false,
      isGroRecived: false,
      isNotificationRecived: false,
      isComputedAttributesRecived: false,
      isSavedCommandsRecived: false,
      isMaintenanceRecived: false,
      isAccumlatorsRecived: false,
      attributeData: "",
      componentType: "",
      isNewAddEnable: false,
      activeOperation: "",
      //comman for all
      addButtonStatus: "sharedAdd",
      isAddVisableBtn: false,
      isAddAttributes: false,
      itemAttributes: "",
      defaultAttributes: {},
      selecteItem: "",
      //computed Att Form
      computedAttForm: { ...ComputedAttributesDataFormat },
      visableAttribute: false,
      visableListAttribute: false,
      attributeCallStatus: "",
      // nofifaction form
      notificationForm: { ...NotificationDataFormat },
      visableNotification: false,
      visableListNotification: false,
      notificationCallStatus: "",
      // Maintenance form
      maintenanceForm: { ...maintenanceDataFormat },
      visableMaintenance: false,
      visableListMaintenance: false,
      maintenanceCallStatus: "",
      // SavedCommands form
      savedCommandsForm: { ...savedCommandsDataFormat },
      visableSavedCommands: false,
      visableListSavedCommands: false,
      commandsCallStatus: "",
      // all fields of form
      gOneform: { ...genteralOneFormFormat },
      aOneform: { ...advenceOneFormFormat },
      resFormData: { ...groupDataFormat },
      // new
      isVisable: false,
      selecteditem: "",
      unassignedDevices: [],
      mulitGroupSelect: [],

      initFetch: false,
      currentPage2: 1,
      pageSize2: 50,
      itemPagination2: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText2: "",
      },
    });
  }

  fetchMoreItems2 = (a, b, c) => {
    this.setState(
      {
        searchText2: "",
      },
      () => {
        this.fetchData2(
          this.props.logInUsers.id,
          this.state.currentPage2,
          this.state.pageSize2,
          true
        );
      }
    );
  };

  searchItems2 = (text) => {
    this.setState(
      {
        searchText2: text,
      },
      () => {
        this.fetchData2(
          this.props.logInUsers.id,
          1,
          this.state.pageSize2,
          true
        );
      }
    );
  };

  fetchData2 = (userId, page, perPage, reset) => {
    let searchText = this.state.searchText2;

    if (searchText) {
      searchText = "&search=" + searchText;
    } else {
      searchText = "";
    }

    let items = this.state.itemPagination2.items;
    if (reset) {
      this.setState(
        {
          itemPagination2: { ...this.state.itemPagination2, items: [] },
        },
        () => {
          items = [];
        }
      );
    }

    instance({
      url: `/api/groups/get`,
      method: `GET`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params: {
        page: page,
        limit: perPage,
        search: this.state.searchText2 || "",
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((res) => {
        this.removeDuplicates(res, items);
        //   })
        // }
        // else{
        //   throw response
        // }
      })
      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
  };
  removeDuplicates = (n, o) => {
    let items = [];
    o.map((j) => {
      let found = n.data.find((e) => e.id === j.id);
      if (found) {
        items.push(found);
      }
      return null;
    });

    if (!items.length) {
      this.setState(
        {
          itemPagination2: {
            ...n,
            items: o.concat(n.data),
          },
          currentPage2: n.hasNext ? n.page + 1 : n.page,
        },
        () => {
          this.props.dispatch(getGroups(o.concat(n.data)));
        }
      );
    }
  };

  getCurrentGroup(groups, id) {
    if (id) {
      this.setState(
        {
          isDeviceRecived: false,
        },
        () => {
          if (this.props.devices && this.props.devices.length) {
            let d;
            let unassignedDevices = [];

            this.props.devices.map((dev) => {
              if (parseInt(dev.groupId) === parseInt(id)) {
                d = dev;
              }
              if (!dev.groupId) {
                unassignedDevices.push(dev);
              }
              return null;
            });
            this.setState({
              mulitDevicesSelect: d,
              isDeviceRecived: true,
              unassignedDevices,
            });
          }
        }
      );

      if (this.props.groups && this.props.groups.length) {
        let d;

        this.props.groups.map((dev) => {
          if (parseInt(dev.id) === parseInt(id)) {
            d = dev;
          }
          return null;
        });

        d.child = [];
        if (d && d.id) {
          this.props.devices.map((device) => {
            if (device.groupId === d.id) {
              d.child.push(device);
            }
            return null;
          });
        }
        this.setState({
          mulitGroupSelect: d,
          isGroupRecived: true,
        });
      }

      // for driver
      if (checkPrivileges("driver")) {
        instance({
          url: `/api/drivers`,
          method: `GET`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            groupId: id,
          },
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((driver) => {
            this.setState({
              mulitDriversSelect: driver,
              isDriverRecived: true,
            });
          })
          //   } else {
          //     throw response
          //   }
          // })
          .catch((error) => {
            // errorHandler(error, this.props.dispatch)
          });
      }
      // for trailer
      if (checkPrivileges("binder")) {
        instance({
          url: `/api/trailers`,
          method: `GET`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            groupId: id,
          },
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((trailer) => {
            this.setState({
              mulitTrailersSelect: trailer,
              isTrailerRecived: true,
            });
          })
          //   } else {
          //     throw response
          //   }
          // })
          .catch((error) => {
            // errorHandler(error, this.props.dispatch)
          });
      }
      // for vehicle
      if (checkPrivileges("vehicle")) {
        instance({
          url: `/api/vehicles`,
          method: `GET`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            groupId: id,
          },
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((vehicle) => {
            this.setState({
              mulitVehiclesSelect: vehicle,
              isVehicleRecived: true,
            });
            //   })
            // } else {
            //   throw response
            // }
          })
          .catch((error) => {
            // errorHandler(error, this.props.dispatch)
          });
      }

      // for geofences
      if (checkPrivileges("geofence"))
        instance({
          url: `/api/geofences`,
          method: `GET`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            groupId: id,
          },
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((geofences) => {
            this.setState({
              multiGeofencesSelect: geofences,
              isGroRecived: true,
            });
            //   })
            // } else {
            //   throw response
            // }
          })
          .catch((error) => {
            // errorHandler(error, this.props.dispatch)
          });

      // for Notification
      if (checkPrivileges("notification")) {
        instance({
          url: `/api/notifications`,
          method: `GET`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            groupId: id,
          },
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((notifications) => {
            let allData = this.props.notification;
            if (notifications.length) {
              allData.map((item) => {
                item.check = false;
                notifications.map((main) => {
                  if (item.type && main.type && item.type === main.type) {
                    item.check = true;
                    main.check = true;
                  }
                  return null;
                });
                return null;
              });
            } else {
              allData.map((n) => (n.check = false));
            }
            this.props.dispatch(getNotificationDeviceWise(notifications));
            this.props.dispatch(notificationDeviceWiseSelectedList(allData));
            this.setState({
              isNotificationRecived: true,
            });
            //   })
            // } else {
            //   throw response
            // }
          })
          .catch((error) => {
            // errorHandler(error, this.props.dispatch)
          });
      }

      // for ComputedAttributes
      if (checkPrivileges("attribute")) {
        instance({
          url: `/api/attributes/computed`,
          method: `GET`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            groupId: id,
          },
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((attributes) => {
            let allData = this.props.computedAttributes;
            if (attributes.length) {
              allData.map((item) => {
                item.check = false;
                attributes.map((main) => {
                  if (item.id === main.id) {
                    item.check = true;
                    main.check = true;
                  }
                  return null;
                });
                return null;
              });
            } else {
              allData.map((n) => (n.check = false));
            }
            this.props.dispatch(getComputedAttributeDeviceWise(attributes));
            this.props.dispatch(
              computedAttributeDeviceWiseSelectedList(allData)
            );
            this.setState({
              isComputedAttributesRecived: true,
            });
            //   })
            // } else {
            //   throw response
            // }
          })
          .catch((error) => {
            // errorHandler(error, this.props.dispatch)
          });
      }

      // for SavedCommands
      if (checkPrivileges("command")) {
        instance({
          url: `/api/commands`,
          method: `GET`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            groupId: id,
          },
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((commands) => {
            let data = commands;
            data.map((item) => {
              item.textChannel = item.textChannel ? "Yes" : "No";
              return null;
            });
            let allData = this.props.commands;
            if (commands.length) {
              allData.map((item) => {
                item.check = false;
                commands.map((main) => {
                  if (item.id === main.id) {
                    item.check = true;
                    main.check = true;
                  }
                  return null;
                });
                return null;
              });
            } else {
              allData.map((n) => (n.check = false));
            }

            this.props.dispatch(getCommandsDeviceWise(data));
            this.props.dispatch(commandsDeviceWiseSelectedList(allData));
            this.setState({
              isSavedCommandsRecived: true,
            });
          })
          //   } else {
          //     throw response
          //   }
          // })
          .catch((error) => {
            // errorHandler(error, this.props.dispatch)
          });
      }

      // for Maintenance
      if (checkPrivileges("maintenance")) {
        instance({
          url: `/api/maintenance`,
          method: `GET`,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          params: {
            groupId: id,
          },
        })
          // .then(response => {
          //   if (response.ok) {
          //     response.json()
          .then((maintenance) => {
            let allData = this.props.maintenance;
            if (maintenance.length) {
              allData.map((item) => {
                item.check = false;
                maintenance.map((main) => {
                  if (item.id === main.id) {
                    item.check = true;
                    main.check = true;
                  }
                  return null;
                });
                return null;
              });
            } else {
              allData.map((n) => (n.check = false));
            }

            this.props.dispatch(getMaintenanceDeviceWise(maintenance));
            this.props.dispatch(maintenanceDeviceWiseSelectedList(allData));
            this.setState({
              isMaintenanceRecived: true,
            });
            //   })
            // } else {
            //   throw response
            // }
          })
          .catch((error) => {
            // errorHandler(error, this.props.dispatch)
          });
      }
    }
    this.setState({
      gOneform: {},
      aOneform: {},
      resFormData: {},
      //mulitGroupSelect: '',
      //mulitDevicesSelect: '',
      mulitDriversSelect: "",
      mulitTrailersSelect: "",
      mulitVehiclesSelect: "",
      multiGeofencesSelect: "",
      mulitNotificationSelect: "",
      multiComputedAttributesSelect: "",
      mulitSavedCommandsSelect: "",
      mulitMaintenanceSelect: "",
      multiAccumlatorsSelect: "",
      isDriverRecived: false,
      isTrailerRecived: false,
      isVehicleRecived: false,
      isGroRecived: false,
      isNotificationRecived: false,
      isComputedAttributesRecived: false,
      isSavedCommandsRecived: false,
      isMaintenanceRecived: false,
      isAccumlatorsRecived: false,
    });

    let user = "";
    user = groups.filter((user) => {
      return parseInt(user.id) === parseInt(id);
    });
    if (user[0]) {
      let { name, uniqueId, groupId, phone } = user[0];
      let { category } = user[0];
      let f_3 = { category };

      let f_1 = { name, uniqueId, groupId, phone };

      this.setState({
        gOneform: { ...f_1 },
        aOneform: { ...f_3 },
        resFormData: { ...user[0] },
      });
    }

    return { user };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }
  // Maintenance
  onCreateNewMaintenance() {
    this.setState({
      isAddVisableBtn: false,
      maintenanceCallStatus: "POST",
      visableMaintenance: true,
      maintenanceForm: { ...maintenanceDataFormat },
      addButtonStatus: "sharedAdd",
      isHaveAccess: "maintenanceCreate",
    });
  }

  editMaintenance(item) {
    this.setState({
      isAddVisableBtn: false,
      selecteItem: item[0],
      maintenanceCallStatus: "PUT",
      visableMaintenance: true,
      maintenanceForm: { ...item[0] },
      addButtonStatus: "sharedUpdate",
      attributeChangesMessage: "",
      defaultAttributes: JSON.parse(JSON.stringify(item[0].attributes)),
      itemAttributes: item[0].attributes,
      isHaveAccess: "maintenanceUpdate",
    });
  }
  removeMaintenance() {
    this.setState(
      {
        maintenanceCallStatus: "DELETE",
      },
      () => this.addMaintenance()
    );
  }
  maintenance_Selection(checked, item) {
    let method = "DELETE";
    let value = checked;
    let allData = this.props.maintenance;
    let obj = {
      groupId: this.props.match.params.id,
      maintenanceId: item.id,
    };
    if (value) {
      method = "POST";
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        if (method === "DELETE") {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          this.props.dispatch(updateMaintenanceDeviceWiseSelectedList(item));
          this.props.dispatch(RemoveMaintenanceDeviceWise(item));
          //this.props.enqueueSnackbar(this.props.translate('maintenanceUnAssignedSuccessfully'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess(this.props.translate(
                "maintenanceUnAssignedSuccessfully"
              ))
          );
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          this.props.dispatch(updateMaintenanceDeviceWiseSelectedList(item));
          this.props.dispatch(addMaintenanceDeviceWise(item));
          //this.props.enqueueSnackbar(this.props.translate('maintenanceAssignedSuccessfully'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess(this.props.translate("maintenanceAssignedSuccessfully"))
          );
        }
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
        // if (e && e.text) {
        //   e.text().then(err => {
        //     //alert(err)
        //     this.props.dispatch(
        //       notifyError({
        //         message: err,
        //         autoDismiss: 10
        //       })
        //     )
        //   })
        // }
      });
  }
  maintenance_handleChange = (name) => (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        maintenanceForm: {
          ...this.state.maintenanceForm,
          [name]: value,
        },
      },
      () => this.checkRequiredFields("maintenance")
    );
  };
  addMaintenance() {
    let obj = this.state.maintenanceForm;
    let params = "";
    if (
      this.state.maintenanceCallStatus === "PUT" ||
      this.state.maintenanceCallStatus === "DELETE"
    ) {
      params = obj.id;
    }
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check;
      }

      instance({
        url: `/api/maintenance/${params}`,
        method: `${this.state.maintenanceCallStatus}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((Maintenance) => {
          // if (response.ok) {
          if (this.state.maintenanceCallStatus === "DELETE") {
            this.props.dispatch(removedMaintenanceDeviceWiseSelectedList(obj));
            this.props.dispatch(removedMaintenance(obj));
            //this.props.enqueueSnackbar(this.props.translate('maintenanceIsDeleted'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            this.props.dispatch(
              notifySuccess(this.props.translate("maintenanceIsDeleted"))
            );
            this.setState({
              onDeleteConfirmationMaintenance: false,
              attributeChangesMessage: "",
            });
          } else {
            // response.json().then(Maintenance => {
            if (this.state.maintenanceCallStatus === "PUT") {
              this.props.dispatch(
                updateMaintenanceDeviceWiseSelectedList(Maintenance)
              );
              this.props.dispatch(addMaintenance(Maintenance));
              //this.props.enqueueSnackbar(this.props.translate('maintenanceIsUpdated'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              this.props.dispatch(
                notifySuccess(this.props.translate("maintenanceIsUpdated"))
              );
              this.setState({
                attributeChangesMessage: "",
              });
            } else {
              this.maintenance_Selection(true, Maintenance);
              this.props.dispatch(
                addMaintenanceDeviceWiseSelectedList(Maintenance)
              );
              this.props.dispatch(addMaintenance(Maintenance));
              //this.props.enqueueSnackbar(this.props.translate('MaintenanceIsCreated'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              this.props.dispatch(
                notifySuccess(this.props.translate("MaintenanceIsCreated"))
              );
              this.setState({
                visableListMaintenance: false,
                attributeChangesMessage: "",
              });
            }

            this.setState({
              visableMaintenance: false,
              attributeChangesMessage: "",
            });
            // })
          }
          // } else {
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  }
  onListMaintenance() {
    this.setState({
      visableListMaintenance: true,
    });
  }
  addMaintenanceAttributes() {
    this.setState({
      isAddAttributes: true,
      componentType: "Maintenance",
      //defaultAttributes: this.state.maintenanceForm.attributes,
      itemAttributes: this.state.maintenanceForm.attributes,
    });
  }
  // Saved Commands
  onCreateNewSavedCommands() {
    this.setState({
      isAddVisableBtn: false,
      commandsCallStatus: "POST",
      visableSavedCommands: true,
      savedCommandsForm: { ...savedCommandsDataFormat },
      addButtonStatus: "sharedAdd",
      isHaveAccess: "commandCreate",
    });
  }
  editCommands(item) {
    this.setState({
      isAddVisableBtn: false,
      selecteItem: item[0],
      commandsCallStatus: "PUT",
      visableSavedCommands: true,
      savedCommandsForm: { ...item[0] },
      addButtonStatus: "sharedUpdate",
      isHaveAccess: "commandUpdate",
    });
  }
  removeCommands() {
    this.setState(
      {
        commandsCallStatus: "DELETE",
      },
      () => this.addSavedCommands()
    );
  }

  savedCommands_handleChange = (name) => (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    if (name === "attributes") {
      let att = this.state.savedCommandsForm.attributes;
      let newAtt = { [target.name]: target.value };
      att = { ...att, ...newAtt };
      value = att;
      this.setState(
        {
          savedCommandsForm: {
            ...this.state.savedCommandsForm,
            [target.name]:
              target.type === "checkbox" ? target.checked : target.value,
          },
        },
        () => this.checkRequiredFields("Commands")
      );
    }
    this.setState(
      {
        savedCommandsForm: {
          ...this.state.savedCommandsForm,
          [name]: value,
        },
      },
      () => this.checkRequiredFields("Commands")
    );
  };
  addSavedCommands() {
    let { attributes, id, description, type, textChannel } =
      this.state.savedCommandsForm;
    textChannel = textChannel === "Yes" ? true : false;
    attributes = attributes || {};
    id = id || -1;
    let obj = { attributes, id, description, type, textChannel };
    obj.deviceId = 0;
    let params = "";
    if (
      this.state.commandsCallStatus === "PUT" ||
      this.state.commandsCallStatus === "DELETE"
    ) {
      params = obj.id;
    }

    if (obj) {
      instance({
        url: `/api/commands/${params}`,
        method: `${this.state.commandsCallStatus}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((commands) => {
          // if (response.ok) {
          if (this.state.commandsCallStatus === "DELETE") {
            this.props.dispatch(removedCommandsDeviceWiseSelectedList(obj));
            this.props.dispatch(removedCommands(obj));
            //this.props.enqueueSnackbar(this.props.translate('savedCommandsIsDeleted'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            this.props.dispatch(
              notifySuccess(this.props.translate("savedCommandsIsDeleted"))
            );
            this.setState({
              onDeleteConfirmationCommand: false,
            });
          } else {
            // response.json().then(commands => {
            this.setState({
              visableSavedCommands: false,
            });
            if (this.state.commandsCallStatus === "PUT") {
              this.props.dispatch(
                updateCommandsDeviceWiseSelectedList(commands)
              );
              this.props.dispatch(addCommands(commands));
              //this.props.enqueueSnackbar(this.props.translate('savedCommandsIsUpdated'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              this.props.dispatch(
                notifySuccess(this.props.translate("savedCommandsIsUpdated"))
              );
            } else {
              this.savedCommands_Selection(true, commands);
              this.props.dispatch(addCommandsDeviceWiseSelectedList(commands));
              this.props.dispatch(addCommands(commands));
              //this.props.enqueueSnackbar(this.props.translate('savedCommandsIsCreated'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              this.props.dispatch(
                notifySuccess(this.props.translate("savedCommandsIsCreated"))
              );
              this.setState({ visableListSavedCommands: false });
            }
            // })
          }
          // } else {
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          // if (e && e.text) {
          //   e.text().then(err => {
          //     //alert(err)
          //     this.props.dispatch(
          //       notifyError({
          //         message: err,
          //         autoDismiss: 10
          //       })
          //     )
          //   })
          // }
        });
    }
  }
  onListSavedCommands() {
    this.setState({
      visableListSavedCommands: true,
    });
  }
  savedCommands_Selection(checked, item) {
    let method = "DELETE";
    let value = checked;
    let allData = this.props.commands;
    let obj = {
      groupId: this.props.match.params.id,
      commandId: item.id,
    };
    if (value) {
      method = "POST";
    }

    instance({
      url: `/api/permissions}`,
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        if (method === "DELETE") {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          this.props.dispatch(updateCommandsDeviceWiseSelectedList(item));
          this.props.dispatch(RemoveCommandsDeviceWise(item));
          //this.props.enqueueSnackbar(this.props.translate('savedCommandsUnAssignedSuccessfully'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess(this.props.translate(
                "savedCommandsUnAssignedSuccessfully"
              ))
          );
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          this.props.dispatch(updateCommandsDeviceWiseSelectedList(item));
          this.props.dispatch(addCommandsDeviceWise(item));
          //this.props.enqueueSnackbar(this.props.translate('savedCommandsAssignedSuccessfully'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess(this.props.translate(
                "savedCommandsAssignedSuccessfully"
              ))
          );
        }
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
        // if (e && e.text) {
        //   e.text().then(err => {
        //     //alert(err)
        //     this.props.dispatch(
        //       notifyError({
        //         message: err,
        //         autoDismiss: 10
        //       })
        //     )
        //   })
        // }
      });
  }
  // Notification
  onCreateNewNotification() {
    this.setState({
      isAddVisableBtn: false,
      notificationCallStatus: "POST",
      visableNotification: true,
      notificationForm: { ...NotificationDataFormat },
      addButtonStatus: "sharedAdd",
      isHaveAccess: "notificationCreate",
    });
  }
  editNotification(item) {
    this.setState({
      isAddVisableBtn: false,
      selecteItem: item[0],
      notificationCallStatus: "PUT",
      visableNotification: true,
      notificationForm: { ...item[0] },
      addButtonStatus: "sharedUpdate",
      defaultAttributes: JSON.parse(JSON.stringify(item[0].attributes)),
      attributeChangesMessage: "",
      itemAttributes: item[0].attributes,
      isHaveAccess: "notificationUpdate",
    });
  }
  removeNotification() {
    this.setState(
      {
        notificationCallStatus: "DELETE",
      },
      () => this.addNotification()
    );
  }
  addNotificationAttributes() {
    this.setState({
      isAddAttributes: true,
      componentType: "Notification",
      // defaultAttributes: this.state.notificationForm.attributes,
      itemAttributes: this.state.notificationForm.attributes,
    });
  }
  onListNotification() {
    this.setState({
      visableListNotification: true,
    });
  }
  addNotification() {
    let obj = this.state.notificationForm;
    let params = "";
    if (
      this.state.notificationCallStatus === "PUT" ||
      this.state.notificationCallStatus === "DELETE"
    ) {
      params = obj.id;
    }
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check;
      }
      if (obj.alarms) {
        obj.attributes = { ...obj.attributes, alarms: obj.alarms };
        delete obj.alarms;
      }

      instance({
        url: `/api/notifications/${params}`,
        method: this.state.notificationCallStatus,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((notifications) => {
          // if (response.ok) {
          if (this.state.notificationCallStatus === "DELETE") {
            this.props.dispatch(removeNotificationDeviceWiseSelectedList(obj));
            this.props.dispatch(removedNotification(obj));
            //this.props.enqueueSnackbar(this.props.translate('notificationIsDeleted'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            this.props.dispatch(
              notifySuccess(this.props.translate("notificationIsDeleted"))
            );
            this.setState({
              onDeleteConfirmationNotification: false,
              attributeChangesMessage: "",
            });
          } else {
            // response.json().then(notifications => {
            this.setState({
              visableNotification: false,
            });
            if (this.state.notificationCallStatus === "PUT") {
              this.props.dispatch(
                updateNotificationDeviceWiseSelectedList(notifications)
              );
              this.props.dispatch(addNotification(notifications));
              //this.props.enqueueSnackbar(this.props.translate('notificationIsUpdated'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              this.props.dispatch(
                notifySuccess(this.props.translate("notificationIsUpdated"))
              );
              this.setState({
                attributeChangesMessage: "",
              });
            } else {
              this.notification_Selection(true, notifications);
              this.props.dispatch(
                addNotificationDeviceWiseSelectedList(notifications)
              );
              this.props.dispatch(addNotification(notifications));
              //this.props.enqueueSnackbar(this.props.translate('notificationIsCreated'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              this.props.dispatch(
                notifySuccess(this.props.translate("notificationIsCreated"))
              );

              this.setState({
                attributeChangesMessage: "",
                visableListNotification: false,
              });
            }
            // })
          }
          // } else {
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          // if (e && e.text) {
          //   e.text().then(err => {
          //     //alert(err)
          //     this.props.dispatch(
          //       notifyError({
          //         message: err,
          //         autoDismiss: 10
          //       })
          //     )
          //   })
          // }
        });
    }
  }
  notification_handleChange = (name) => (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    this.setState(
      {
        notificationForm: {
          ...this.state.notificationForm,
          [name]: value,
        },
      },
      () => this.checkRequiredFields("notification")
    );
  };
  notification_Selection(checked, item) {
    let method = "DELETE";
    let value = checked;
    let allData = this.props.selectednotification;
    let obj = {
      groupId: this.props.match.params.id,
      notificationId: item.id,
    };
    if (value) {
      method = "POST";
    }

    instance({
      url: `/api/permissions/`,
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        if (method === "DELETE") {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          this.props.dispatch(updateNotificationDeviceWiseSelectedList(item));
          this.props.dispatch(RemoveNotificationDeviceWise(item));
          //this.props.enqueueSnackbar(this.props.translate('notificationUnAssignedSuccessfully'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess(this.props.translate(
                "notificationUnAssignedSuccessfully"
              ))
          );
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          this.props.dispatch(updateNotificationDeviceWiseSelectedList(item));
          this.props.dispatch(addNotificationDeviceWise(item));
          //this.props.enqueueSnackbar(this.props.translate('notificationAssignedSuccessfully'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess(this.props.translate("notificationAssignedSuccessfully"))
          );
        }
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
        // if (e && e.text) {
        //   e.text().then(err => {
        //     //alert(err)
        //     this.props.dispatch(
        //       notifyError({
        //         message: err,
        //         autoDismiss: 10
        //       })
        //     )
        //   })
        // }
      });
  }
  // Computed Attribute
  onCreateNewAttributes() {
    this.setState({
      isAddVisableBtn: false,
      attributeCallStatus: "POST",
      visableAttribute: true,
      computedAttForm: { ...ComputedAttributesDataFormat },
      addButtonStatus: "sharedAdd",
      isHaveAccess: "attributeCreate",
    });
  }
  editComputedAttributes(item) {
    this.setState({
      isAddVisableBtn: false,
      selecteItem: item[0],
      attributeCallStatus: "PUT",
      visableAttribute: true,
      computedAttForm: {
        ...item[0],
        expression: parse(item[0].expression)[0],
      },
      addButtonStatus: "sharedUpdate",
      isHaveAccess: "attributeUpdate",
    });
  }
  removeComputedAttributes() {
    this.setState(
      {
        attributeCallStatus: "DELETE",
      },
      () => this.addComputedAttributes()
    );
  }
  onListComputedAttributes() {
    this.setState({
      visableListAttribute: true,
    });
  }

  attributeHandle(name, value) {
    let selectedValue = postionAttributesTypeComp
      .filter((m) => m.key === value)
      .map((m) => m.valueType);
    this.setState(
      {
        computedAttForm: {
          ...this.state.computedAttForm,
          [name]: value.id,
          type: selectedValue[0],
        },
      },
      () => this.checkRequiredFields("computedAtt")
    );
  }
  notificationHandle(name, value) {
    this.setState(
      {
        notificationForm: {
          ...this.state.notificationForm,
          [name]: value.id,
        },
      },
      () => this.checkRequiredFields("notification")
    );
  }
  maintenancesHandle(name, value) {
    this.setState(
      {
        maintenanceForm: {
          ...this.state.maintenanceForm,
          [name]: value.id,
        },
      },
      () => this.checkRequiredFields("maintenance")
    );
  }
  savedCommandsHandle(name, value) {
    this.setState(
      {
        savedCommandsForm: {
          ...this.state.savedCommandsForm,
          type: value.id,
        },
      },
      () => this.checkRequiredFields("Commands")
    );
  }

  C_Att_handleChange = (name) => (event) => {
    const { target } = event;
    this.setState(
      {
        computedAttForm: {
          ...this.state.computedAttForm,
          [name]: target.value,
        },
      },
      () => this.checkRequiredFields("computedAtt")
    );
  };
  addComputedAttributes() {
    let obj = this.state.computedAttForm;
    let params = "";
    if (
      this.state.attributeCallStatus === "PUT" ||
      this.state.attributeCallStatus === "DELETE"
    ) {
      params = obj.id;
      delete obj.deviceId;
    }
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check;
      }

      instance({
        url: `/api/attributes/computed/${params}`,
        method: this.state.attributeCallStatus,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((Attribute) => {
          // if (response.ok) {
          if (this.state.attributeCallStatus === "DELETE") {
            this.props.dispatch(
              removedComputedAttributeDeviceWiseSelectedList(obj)
            );
            this.props.dispatch(removedComputedAttribute(obj));
            //this.props.enqueueSnackbar(this.props.translate('computedAttributeIsDeleted'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            this.props.dispatch(
              notifySuccess(this.props.translate("computedAttributeIsDeleted"))
            );
            this.setState({
              onDeleteConfirmationAttributes: false,
            });
          } else {
            // response.json().then(Attribute => {
            this.setState({
              visableAttribute: false,
            });
            if (this.state.attributeCallStatus === "PUT") {
              this.props.dispatch(
                updateComputedAttributeDeviceWiseSelectedList(Attribute)
              );
              this.props.dispatch(addComputedAttribute(Attribute));
              //this.props.enqueueSnackbar(this.props.translate('computedAttributeIsUpdated'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              this.props.dispatch(
                notifySuccess(this.props.translate("computedAttributeIsUpdated"))
              );
            } else {
              this.computedAttributes_Selection(true, Attribute);
              this.props.dispatch(
                addComputedAttributeDeviceWiseSelectedList(Attribute)
              );
              this.props.dispatch(addComputedAttribute(Attribute));
              //this.props.enqueueSnackbar(this.props.translate('computedAttributeIsCreated'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              this.props.dispatch(
                notifySuccess(this.props.translate("computedAttributeIsCreated"))
              );
              this.setState({
                visableListAttribute: false,
              });
            }
            // })
          }
          // } else {
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          // if (e && e.text) {
          //   e.text().then(err => {
          //     //alert(err)
          //     this.props.dispatch(
          //       notifyError({
          //         message: err,
          //         autoDismiss: 10
          //       })
          //     )
          //   })
          // }
        });
    }
  }
  computedAttributes_Selection(checked, item) {
    let method = "DELETE";
    let value = checked;
    let allData = this.props.computedAttributes;
    let obj = {
      groupId: this.props.match.params.id,
      attributeId: item.id,
    };
    if (value) {
      method = "POST";
    }

    instance({
      url: `/api/permissions/`,
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        if (method === "DELETE") {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = false;
            }
            return null;
          });
          this.props.dispatch(
            updateComputedAttributeDeviceWiseSelectedList(item)
          );
          this.props.dispatch(RemoveComputedAttributeDeviceWise(item));
          //this.props.enqueueSnackbar(this.props.translate('computedAttributeUnAssignedSuccessfully'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess(this.props.translate(
                "computedAttributeUnAssignedSuccessfully"
              ))
          );
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.id) {
              dt.check = true;
            }
            return null;
          });
          this.props.dispatch(
            updateComputedAttributeDeviceWiseSelectedList(item)
          );
          this.props.dispatch(addComputedAttributeDeviceWise(item));
          //this.props.enqueueSnackbar(this.props.translate('computedAttributeAssignedSuccessfully'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess(this.props.translate(
                "computedAttributeAssignedSuccessfully"
              ))
          );
        }
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
        // if (e && e.text) {
        //   e.text().then(err => {
        //     //alert(err)
        //     this.props.dispatch(
        //       notifyError({
        //         message: err,
        //         autoDismiss: 10
        //       })
        //     )
        //   })
        // }
      });
  }
  // comman for all
  onCloseC_Att_Modal() {
    this.setState({
      visableAttribute: false,
      visableNotification: false,
      visableMaintenance: false,
      visableSavedCommands: false,
      attributeChangesMessage: "",
      defaultAttributes: {},
    });
  }

  onCloseC_Modal() {
    this.setState({
      visableListNotification: false,
      visableListAttribute: false,
      visableListMaintenance: false,
      visableListSavedCommands: false,
      attributeChangesMessage: "",
      defaultAttributes: {},
    });
  }
  onCloseAttModal() {
    this.setState({
      isAddAttributes: false,
    });
  }

  // new code for changing attribute
  changeAttribute(name, value, selectedOption, updateAtt) {
    if (this.state.componentType === "Notification") {
      if (selectedOption === "updateAtt" || selectedOption === "deleteAtt") {
        this.setState(
          {
            notificationForm: {
              ...this.state.notificationForm,
              attributes: { ...updateAtt },
            },
          },
          () => this.checkRequiredFields("notification")
        );
      } else {
        this.setState(
          {
            notificationForm: {
              ...this.state.notificationForm,
              attributes: {
                ...this.state.notificationForm.attributes,
                [name]: value,
              },
            },
          },
          () => this.checkRequiredFields("notification")
        );
      }
    }
    if (this.state.componentType === "Maintenance") {
      if (selectedOption === "updateAtt" || selectedOption === "deleteAtt") {
        this.setState(
          {
            maintenanceForm: {
              ...this.state.maintenanceForm,
              attributes: { ...updateAtt },
            },
          },
          () => this.checkRequiredFields("maintenance")
        );
      } else {
        this.setState(
          {
            maintenanceForm: {
              ...this.state.maintenanceForm,
              attributes: {
                ...this.state.maintenanceForm.attributes,
                [name]: value,
              },
            },
          },
          () => this.checkRequiredFields("maintenance")
        );
      }
    }
  }

  // check for required field
  checkRequiredFields(computedAtt) {
    // changed attribute work is in pendding
    const {
      defaultAttributes,
      selecteItem,
      maintenanceForm,
      computedAttForm,
      savedCommandsForm,
      notificationForm,
    } = this.state;
    if (computedAtt === "computedAtt") {
      let changed_comAt = !isEqual(selecteItem, computedAttForm);
      let { attribute, expression } = this.state.computedAttForm;
      if (
        attribute.trim().length > 0 &&
        expression.trim().length > 0 &&
        changed_comAt
      ) {
        this.setState({
          isAddVisableBtn: true,
        });
      } else {
        this.setState({
          isAddVisableBtn: false,
        });
      }
    } else if (computedAtt === "Commands") {
      let changed_comm = !isEqual(selecteItem, savedCommandsForm);
      let { type } = this.state.savedCommandsForm;
      if (type && changed_comm) {
        this.setState({
          isAddVisableBtn: true,
        });
      } else {
        this.setState({
          isAddVisableBtn: false,
        });
      }
    } else if (computedAtt === "notification") {
      // attChangedValue is for attributes changing value
      let attChangedValue = !isEqual(
        defaultAttributes,
        notificationForm.attributes
      );
      if (attChangedValue) {
        this.setState({
          attributeChangesMessage: "Change in attributes is pending",
        });
      } else {
        this.setState({
          attributeChangesMessage: "",
        });
      }
      let changed_noti = !isEqual(selecteItem, notificationForm);
      let { type, notificators } = this.state.notificationForm;
      if (type && notificators && changed_noti) {
        this.setState({
          isAddVisableBtn: true,
        });
      } else {
        this.setState({
          isAddVisableBtn: false,
        });
      }
    } else if (computedAtt === "maintenance") {
      let Attvalue = !isEqual(defaultAttributes, maintenanceForm.attributes);
      if (Attvalue) {
        this.setState({
          attributeChangesMessage: "Change in attributes is pending",
        });
      } else {
        this.setState({
          attributeChangesMessage: "",
        });
      }
      let changed_main = !isEqual(selecteItem, maintenanceForm);
      let { type, start, period } = this.state.maintenanceForm;
      if (type && period && start && changed_main) {
        this.setState({
          isAddVisableBtn: true,
        });
      } else {
        this.setState({
          isAddVisableBtn: false,
        });
      }
    }
  }
  // Drivers
  onDrivresSubmit(item, option) {
    if (item.length) {
      let obj = {
        groupId: this.props.match.params.id,
        driverId: item[item.length - 1].value,
      };

      instance({
        url: `/api/permissions/`,
        method: option,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((response) => {
          // if (response.status === 204) {
          if (option === "POST") {
            //this.props.enqueueSnackbar(this.props.translate('driverAssignedSuccessfully'));
            this.props.dispatch(
              notifySuccess(this.props.translate("driverAssignedSuccessfully"))
            );
          } else {
            //this.props.enqueueSnackbar(this.props.translate('driverUnAssignedSuccessfully'));
            this.props.dispatch(
              notifySuccess(this.props.translate("driverUnAssignedSuccessfully"))
            );
          }
          // } else {
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          // if (e && e.text) {
          //   e.text().then(err => {
          //     //alert(err)
          //     this.props.dispatch(
          //       notifyError({
          //         message: err,
          //         autoDismiss: 10
          //       })
          //     )
          //   })
          // }
        });
    }
  }
  // Trailers
  // Trailers
  onTrailersSubmit(item, option) {
    if (item.length) {
      let obj = {
        groupId: this.props.match.params.id,
        trailerId: item[item.length - 1].value,
      };

      instance({
        url: `/api/permissions/`,
        method: option,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((response) => {
          // if (response.status === 204) {
          if (option === "POST") {
            //this.props.enqueueSnackbar(this.props.translate('trailerAssignedSuccessfully'));
            this.props.dispatch(
              notifySuccess(this.props.translate("trailerAssignedSuccessfully"))
            );
          } else {
            //this.props.enqueueSnackbar(this.props.translate('trailerUnAssignedSuccessfully'));
            this.props.dispatch(
              notifySuccess(this.props.translate("trailerUnAssignedSuccessfully"))
            );
          }
          // } else {
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          // if (e && e.text) {
          //   e.text().then(err => {
          //     //alert(err)
          //     this.props.dispatch(
          //       notifyError({
          //         message: err,
          //         autoDismiss: 10
          //       })
          //     )
          //   })
          // }
        });
    }
  }
  // Vehicle
  onVehiclesSubmit(item, option) {
    if (item.length) {
      let obj = {
        groupId: this.props.match.params.id,
        vehicleId: item[item.length - 1].value,
      };

      instance({
        url: `/api/permissions/`,
        method: option,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((response) => {
          // if (response.status === 204) {
          if (option === "POST") {
            //this.props.enqueueSnackbar(this.props.translate('vehicleAssignedSuccessfully'));
            this.props.dispatch(
              notifySuccess(this.props.translate("vehicleAssignedSuccessfully"))
            );
          } else {
            //this.props.enqueueSnackbar(this.props.translate('vehicleUnAssignedSuccessfully'));
            this.props.dispatch(
              notifySuccess(this.props.translate("vehicleUnAssignedSuccessfully"))
            );
          }
          // } else {
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          // if (e && e.text) {
          //   e.text().then(err => {
          //     //alert(err)
          //     this.props.dispatch(
          //       notifyError({
          //         message: err,
          //         autoDismiss: 10
          //       })
          //     )
          //   })
          // }
        });
    }
  }

  onDevicesSubmit(item, option) {
    if (item.length) {
      let o = "PUT";
      let device = {};

      this.props.devices.map((d) => {
        if (parseInt(d.id) === parseInt(item[item.length - 1].value)) {
          device = d;
        }
        return null;
      });

      let obj = {
        ...device,
        groupId: this.props.match.params.id,
      };

      if (option === "DELETE") {
        obj.groupId = 0;
      }

      delete obj.visible;

      instance({
        url: `/api/devices/${device.id}`,
        method: `${o}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
        .then((device) => {
          this.props.dispatch(updateDevice(device));

          if (device.groupId !== 0) {
            //this.props.enqueueSnackbar(this.props.translate('trackerIsAssignedSuccessfully'));
            this.props.dispatch(
              notifySuccess(this.props.translate("trackerIsAssignedSuccessfully"))
            );
          } else {
            //this.props.enqueueSnackbar(this.props.translate('trackerIsUnAssignedSuccessfully'));
            this.props.dispatch(
              notifySuccess(this.props.translate(
                  "trackerIsUnAssignedSuccessfully"
                ))
            );
          }
        })
        //   } else {
        //     throw response
        //   }
        // })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          // if (e && e.text) {
          //   e.text().then(err => {
          //     //alert(err)
          //     this.props.dispatch(
          //       notifyError({
          //         message: err,
          //         autoDismiss: 10
          //       })
          //     )
          //   })
          // }
        });
    }
  }
  onGroupSubmit(item, option) {
    if (item.length) {
      let obj = {
        groupId: item[item.length - 1].value,
      };

      instance({
        url: `/api/permissions`,
        method: `${option}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((response) => {
          // if (response.status === 204) {
          if (option === "POST") {
            //this.props.enqueueSnackbar(this.props.translate('groupAssignedSuccessfully'));
            this.props.dispatch(
              notifySuccess(this.props.translate("groupAssignedSuccessfully"))
            );
          } else {
            //this.props.enqueueSnackbar(this.props.translate('groupUnAssignedSuccessfully'));
            this.props.dispatch(
              notifySuccess(this.props.translate("groupUnAssignedSuccessfully"))
            );
          }
          // } else {
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          // if (e && e.text) {
          //   e.text().then(err => {
          //     //alert(err)
          //     this.props.dispatch(
          //       notifyError({
          //         message: err,
          //         autoDismiss: 10
          //       })
          //     )
          //   })
          // }
        });
    }
  }
  // GeoFences
  onGeoFenceSubmit(item, option) {
    if (item.length) {
      let obj;
      if (option === "POST") {
        obj = {
          groupId: this.props.match.params.id,
          geofenceId: item[item.length - 1].value,
        };
      } else {
        obj = {
          groupId: this.props.match.params.id,
          geofenceId: item[item.length - 1].value,
        };
      }

      instance({
        url: `/api/permissions`,
        method: `${option}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((response) => {
          // if (response.status === 204) {
          if (option === "POST") {
            //this.props.enqueueSnackbar(this.props.translate('geoFanceAssignedSuccessfully'));
            this.props.dispatch(
              notifySuccess(this.props.translate("geoFanceAssignedSuccessfully"))
            );
          } else {
            //this.props.enqueueSnackbar(this.props.translate('geoFanceUnAssignedSuccessfully'));
            this.props.dispatch(
              notifySuccess(this.props.translate("geoFanceUnAssignedSuccessfully"))
            );
          }
          // } else {
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          // if (e && e.text) {
          //   e.text().then(err => {
          //     //alert(err)
          //     this.props.dispatch(
          //       notifyError({
          //         message: err,
          //         autoDismiss: 10
          //       })
          //     )
          //   })
          // }
        });
    }
  }
  // General From
  generalSectionOneformSubmit() {
    let { resFormData } = this.state;
    let attributes = resFormData.attributes;
    if (this.state.gOneform.devicePassword) {
      attributes = {
        ...attributes,
        devicePassword: this.state.gOneform.devicePassword,
      };
    }
    resFormData.attributes = attributes;
    this.setState(
      {
        resFormData,
      },
      () => this.onFormSubmit(this.state.gOneform)
    );
  }
  advenceSectionOneformSubmit(category) {
    this.onFormSubmit(category);
  }
  onFormSubmit(targetForm) {
    this.setState(
      {
        resFormData: { ...this.state.resFormData, ...targetForm },
      },
      () => {
        this.formSubmit();
      }
    );
  }
  formSubmit() {
    let data = this.state.resFormData;
    delete data.visible;
    delete data.devicePassword;

    instance({
      url: `/api/devices/${data.id}`,
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...data,
      },
    })
      // .then(response => {
      //   if (response.status === 200) {
      //     response.json()
      .then((device) => {
        this.props.dispatch(updateDevice(device));
        //this.props.enqueueSnackbar(this.props.translate('trackersIsUpdated'));
        this.props.dispatch(
          notifySuccess(this.props.translate("trackersIsUpdated"))
        );
        this.setState({
          isVisableGSOneBtn: false,
          isVisableGSTwoBtn: false,
        });
      })
      //   } else {
      //     throw response
      //   }
      // })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
        // if (e && e.text) {
        //   e.text().then(err => {
        //     //this.props.enqueueSnackbar(this.props.translate('somthingWentWrongMessage'));
        //     this.props.dispatch(
        //       notifyError({
        //         message: this.props.translate('somthingWentWrongMessage'),
        //         autoDismiss: 10
        //       })
        //     )
        //   })
        // }
      });
  }

  handleChangeGformOne = (name) => (event) => {
    const { target } = event;
    this.setState((preState) => ({
      ...preState,
      gOneform: {
        ...preState.gOneform,
        [name]: target.value,
      },
    }));
    this.setState(
      {
        isVisableGSOneBtn: this.state.isVisableGSOneBtn,
      },
      () => {
        this.isButtonVisable("gFormOne");
      }
    );
  };
  handleChangeAformOne = (name) => (event) => {
    const { target } = event;
    this.setState((preState) => ({
      ...preState,
      aOneform: {
        ...preState.aOneform,
        [name]: target.value,
      },
    }));
    this.setState(
      {
        isVisableASOneBtn: this.state.isVisableASOneBtn,
      },
      () => {
        this.isButtonVisable("aFormOne");
      }
    );
  };

  isButtonVisable(target) {
    if (target === "gFormOne") {
      const { name, uniqueId, groupId, phone, devicePassword } =
        this.state.resFormData;
      let obj = { name, uniqueId, groupId, phone, devicePassword };
      this.setState({
        isVisableGSOneBtn: !isEqual(obj, this.state.gOneform),
      });
    }
    if (target === "aFormOne") {
      const { category, positionId } = this.state.resFormData;
      let obj = { category, positionId };
      this.setState({
        isVisableASOneBtn: !isEqual(obj, this.state.aOneform),
      });
    }
  }

  addNewGroup() {
    this.props.history.push("/resources");
    this.setState({ viewScreen: "add" });
  }

  closeScreen = () => {
    this.setState({ viewScreen: "" });
  };

  removedItem = () => {
    const { selectedItem } = this.state;
    if (selectedItem.id) {
      instance({
        url: `/api/groups/${selectedItem.id}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...selectedItem,
        },
      })
        .then((response) => {
          // if (response.ok) {
          this.props.dispatch(removeUser(selectedItem));
          //this.props.enqueueSnackbar(this.props.translate('groupDeleted'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess(this.props.translate("groupDeleted"))
          );
          this.onCancel();
          // } else {
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          // if (e && e.text) {
          //   e.text().then(err => {
          //     this.props.dispatch(
          //       notifyError({
          //         message: err,
          //         autoDismiss: 10
          //       })
          //     )
          //   })
          // }
        });
    }
  };
  onCloseModal = () => {
    this.setState(
      {
        isNewAddEnable: false,
        activeOperation: "",
        isVisable: false,
      },
      () => this.closeScreen()
    );
  };

  onCancel = () => {
    this.setState(
      {
        onDeleteConfirmation: false,
        selectedItem: "",
        onDeleteConfirmationNotification: false,
        onDeleteConfirmationAttributes: false,
        onDeleteConfirmationMaintenance: false,
        onDeleteConfirmationCommand: false,
        viewScreen: null,
      },
      () => {
        this.props.history.push("/resources");
      }
    );
  };
  onRemovedGroup = (item) => {
    this.setState({
      onDeleteConfirmation: true,
      selectedItem: item,
    });
  };

  onRemovedNotification = (item) => {
    this.setState({
      notificationForm: { ...item[0] },
      onDeleteConfirmationNotification: true,
    });
  };
  onRemovedAttribute = (item) => {
    this.setState({
      computedAttForm: { ...item[0] },
      onDeleteConfirmationAttributes: true,
    });
  };
  onRemovedCommand = (item) => {
    this.setState({
      savedCommandsForm: { ...item[0] },
      onDeleteConfirmationCommand: true,
    });
  };
  onRemovedMaintenance = (item) => {
    this.setState({
      maintenanceForm: { ...item[0] },
      onDeleteConfirmationMaintenance: true,
    });
  };

  //check device limit
  checkedDeviceLimitBackCall = () => {
    this.setState({
      checkeDeviceLimit: false,
    });
  };

  render() {
    if (checkPrivileges("group")) {
      //const { resFormData } = this.state
      /* if (this.props.match.params && this.props.match.params.id) {
        if (this.props.groups.length && this.props.devices.length && parseInt(resFormData.id) !== parseInt(this.props.match.params.id)) {
          this.getCurrentGroup(this.props.groups, this.props.match.params.id)
        }
      } */

      const endMessage =
        this.props.itemPagination && this.props.itemPagination.total > 0 ? (
          <p style={{ textAlign: "center", color: "#ccc" }}>
            {" "}
            -- {this.props.translate("end")} --{" "}
          </p>
        ) : (
          <p style={{ textAlign: "center", color: "#ccc" }}>
            {" "}
            {this.props.translate("notFound")}{" "}
          </p>
        );

      return (
        <Layout
          {...this.props}
          goAddGroup={this.addNewGroup}
          removedItem={this.onRemovedGroup}
          checkedDeviceLimit={this.checkedDeviceLimitBackCall}
          classFromChildren="has-padding main-content-page"
          fetchMoreItems={this.fetchMoreItems}
          itemPagination={{ ...this.state.itemPagination, endMessage }}
          searchItems={this.searchItems}
          fetchMoreItems2={this.fetchMoreItems2}
          itemPagination2={{ ...this.state.itemPagination2 }}
          closeScreen={this.closeScreen}
          searchItems2={this.searchItems2}
        >
          {this.state.onDeleteConfirmation && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.removedItem}
              title={this.props.translate("areYouWantToDelete")}
              children={this.state.selectedItem.name}
            />
          )}

          {this.state.viewScreen === "add" ? (
            <GroupsModal
              {...this.props}
              viewScreen={this.state.viewScreen}
              onCloseModal={this.onCloseModal}
              activeOperation={this.state.activeOperation}
              selecteditem={this.state.resFormData}
            />
          ) : (
            <div>
              {this.state.viewScreen === "edit" ? (
                <MainUnitModal
                  id={this.props.match.params.id}
                  history={this.props.history}
                  onCloseModal={this.onCloseModal}
                />
              ) : (
                <EmptyState text={this.props.translate("noResourceSelected")} />
              )}
            </div>
          )}
        </Layout>
      );
    } else {
      return null;
    }
  }
}

const mapState = (state) => ({
  users: state.users,
  groups: state.groups,
  drivers: state.drivers,
  trailers: state.trailers,
  vehicles: state.vehicles,
  devices: state.devices,
  geofences: state.geoFence,
  themecolors: state.themeColors,
  notification: state.notification,
  selectednotification: state.selectednotification,
  notificationDeviceWise: state.notificationDeviceWise,
  computedAttributes: state.computedAttributes,
  selectedComputedAttributes: state.selectedComputedAttributes,
  computedAttributesDeviceWise: state.computedAttributesDeviceWise,
  maintenance: state.maintenance,
  maintenanceDeviceWise: state.maintenanceDeviceWise,
  selectedMaintenance: state.selectedMaintenance,
  commands: state.commands,
  commandsTypes: state.commandsTypes,
  commandsDeviceWise: state.commandsDeviceWise,
  selectedcommands: state.selectedcommands,
  calendars: state.calendars,
  logInUsers: state.logInUsers,
  category: state.category,
  ServerSetting: state.ServerSetting,
});

const mapStateToProps = connect(mapState);
export default mapStateToProps(withTranslationWrapper(Units));
