import React, { Component } from "react";
import Layout from "./../../Layout";
import VehicleTagSelector from "./../../Layout/Sidebar/Components/VehicleFilterModal";
import DriverTagSelector from "./../../Layout/Sidebar/Components/DriverFilterModal";
import DrawMap from "./../../Components/Maps/DrawMap";
import {
  checkPrivileges,
  errorHandler,
  setAttributeFormat,
} from "../../Helpers";
import {
  unsetDeviceId,
  resetFilters,
  prepareDriverTags,
  addDevices,
} from "../../Actions/Devices";
import { getGroups, saveGroupWiseDevices } from "../../Actions/Groups";
import { connect } from "react-redux";
import "./Style.scss";
import axios from "axios";
import { notifySuccess } from "../../Utils/CustomNotifcations";
import withResources from "../HOCRecources";
import ResourceModal from "../../Components/Recources/resourceModal";
import TrackingBox from "../../Components/common/TrackingBox";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Icon, Tooltip } from "@mui/material";
import instance from "../../axios";
import MainView from "./MainView";
import CreateUnit from "./CreateUnit";
import Axios from "axios";
import DevicePortList from "../../Components/Devices/devicePortList";
import withTranslationWrapper from "../../HOC/withTranslation";

const options = {
  width: 250,
  margin: 10,
};

const maxBoxes = 20;

const CancelToken = axios.CancelToken;
let source;

const advanceSettingsForm = {
  minimalNoDataDuration: 600,
  minimalParkingDuration: 300,
  minimalTripDuration: 60,
  minimalTripDistance: 100,
  speedThreshold: 1.61987,
  processInvalidPositions: false,
  useIgnition: "2",
  storeTime: 10,
};
const formDefaultState = {
  attributes: { ...advanceSettingsForm },
};
const deviceDataFormat = (data, extAttriubtes, id) => ({
  id: id ? id : 0,
  attributes: extAttriubtes
    ? { ...data.attributes, ...extAttriubtes }
    : { ...data.attributes },
  name: data.name.trim(),
  uniqueId: (data.uniqueId && data.uniqueId) || "",
  status: data.status,
  disabled: data.disabled,
  lastUpdate: data.lastUpdate,
  positionId: data.positionId,
  groupId: data.groupId,
  phone: data.phone,
  model: data.model && data.model.id ? data.model.id : data.model,
  contact: data.contact,
  category: data.category,
  geofenceIds: data.geofenceIds,
  parentId: data.parentId || 0,
});
class Monitoring extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfoFront: {},
      userFetch: false,
      userId: props.logInUser && props.logInUser.id ? props.logInUser.id : 0,
      initFetch: false,
      prepareTags: false,
      vehicleModal: false,
      driverModal: false,
      details: {},
      currentPage2: 1,
      pageSize2: -1,
      itemPagination2: {
        isFetching: true,
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText2: "",
      },
      boxes: [],
      selectedUnit: "",
      createUnit: false,
      form: { ...formDefaultState },
      isVisableDeviceBtn: false,
      sensorsData: [],
    };
    this.map = null;
  }

  UNSAFE_componentWillMount() {
    this.props.dispatch(resetFilters());
    if (this.props.logInUser && this.props.logInUser.id) {
      this.fetchData2(
        this.props.logInUser.id,
        this.state.currentPage2,
        this.state.pageSize2
      );
    }
  }

  setMapRef = (ref) => {
    this.map = ref;
  };

  displayError = (e) => {
    if (e) {
      console.log(e);
    }
  };

  UNSAFE_componentWillReceiveProps(n) {
    if (
      n.positions &&
      n.positions.length &&
      n.drivers &&
      n.drivers.length &&
      this.state.prepareTags === false
    ) {
      this.setState({ prepareTags: true }, () => {
        n.dispatch(prepareDriverTags());
      });
    }
    if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
      this.setState(
        {
          initFetch: true,
        },
        () => {
          this.fetchData2(
            n.logInUser.id,
            this.state.currentPage2,
            this.state.pageSize2
          );
        }
      );
    }
  }

  getSensors = async (deviceId) => {
    instance({
      method: "GET",
      url: `/api/attributes/computed?deviceId=${
        deviceId?.id || deviceId
      }&all=true`,
      cancelToken: source.token,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // if (response.status === 200) {
        this.setState({
          sensorsData: response,
        });
        // this.removeDuplicates(response, items);
        // }
      })
      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
  };
  openDeviceSettings = async (deviceId, check) => {
    if (check === "update") {
      const positions = this.props.positions.find(
        (item) => item.deviceId === deviceId.id
      );
      //  const positions2 = {}
      //  this.props.positions.map(d => {
      //   positions2[deviceId.id] = d
      //    return null
      //   })

      this.setState(
        {
          createUnit: false,
          details: positions ? positions : deviceId,
          selectedUnit: deviceId,
        },
        () => {
          this.getSensors(deviceId);
        }
      );
    } else {
      this.setState({ createUnit: true, selectedUnit: {}, details: {} });
    }
  };

  fetchMoreItems2 = (a, b, c) => {
    this.setState(
      {
        searchText2: "",
      },
      () => {
        this.fetchData2(
          this.props.logInUser.id,
          this.state.currentPage2,
          this.state.pageSize2,
          true
        );
      }
    );
  };

  removeDuplicates = (n, o) => {
    let items = [];
    o.map((j) => {
      let found = n.data.find((e) => e.id === j.id);
      if (found) {
        items.push(found);
      }
      return null;
    });

    if (!items.length) {
      this.setState(
        {
          itemPagination2: {
            ...n,
            isFetching: false,
            items: o.concat(n.data),
          },
          currentPage2: n.hasNext ? n.page + 1 : n.page,
        },
        () => {
          this.props.dispatch(getGroups(o.concat(n.data)));
        }
      );
    }
  };

  fetchData2 = (userId, page, perPage, reset) => {
    let searchText = this.state.searchText2;

    if (searchText) {
      searchText = "&search=" + searchText;
    } else {
      searchText = "";
    }

    let items = this.state.itemPagination2.items;
    if (reset) {
      this.setState(
        {
          itemPagination2: {
            ...this.state.itemPagination2,
            isFetching: true,
            items: [],
          },
        },
        () => {
          items = [];
        }
      );
    }
    source = CancelToken.source();
    instance({
      method: "GET",
      url: `/api/groups/get?page=${page}&limit=${perPage}${searchText}`,
      cancelToken: source.token,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // if (response.status === 200) {
        this.removeDuplicates(response, items);
        // }
      })
      .catch((error) => {
        // errorHandler(error, this.props.dispatch)
      });
  };

  searchItems2 = (text) => {
    this.setState(
      {
        searchText2: text,
      },
      () => {
        this.fetchData2(this.props.logInUser.id, 1, this.state.pageSize2, true);
      }
    );
  };

  componentWillUnmount() {
    this.props.dispatch(unsetDeviceId(0));
    this.props.dispatch(resetFilters());
    if (source) {
      source.cancel();
    }
    this.setState({
      userInfoFront: {},
      userFetch: false,
      userId: 0,
      initFetch: false,
      vehicleModal: false,
      driverModal: false,

      currentPage2: 1,
      pageSize2: -1,
      itemPagination2: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText2: "",
      },
    });
  }

  copyToClipboard = (obj) => {
    const el = document.createElement("textarea");
    el.value = `${obj.latitude},${obj.longitude}`;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.props.dispatch(notifySuccess("Copied to clipboard!"));
  };

  openVehicleTagsModal = () => {
    this.setState({
      vehicleModal: true,
    });
  };

  openDriverTagsModal = () => {
    this.setState({
      driverModal: true,
    });
  };

  cancelFilter = () => {
    this.setState({
      vehicleModal: false,
      driverModal: false,
    });
  };

  saveFilter = () => {
    this.setState({
      vehicleModal: false,
      driverModal: false,
    });
  };

  showResources = (type) => {
    if (!type) {
      this.props.dispatch(saveGroupWiseDevices([]));
      this.props.dispatch(resetFilters());
    }
    this.setState({
      resourceList: type,
    });
  };

  showBox = ({ id, name }) => {
    const boxes1 = this.state.boxes.filter((box) => box.id !== id);
    const boxes = boxes1.filter((e, index) => index + 1 < maxBoxes);

    this.setState({ boxes: [...boxes, { id, name, ...options }] });
  };

  closeBox = (id, event) => {
    event.stopPropagation();
    this.setState({ boxes: this.state.boxes.filter((box) => box.id !== id) });
  };

  onLinkResource = (item) => {
    this.setState(
      {
        linkResource: true,
        selecteditem: item,
      },
      () => {
        this.props.fetchNestedItems(item.id, 1);
      }
    );
  };

  onCloseResource = () => {
    this.setState({
      linkResource: false,
      selecteditem: "",
    });
  };

  openProtocol(input, protocol, inputName) {
    this.setState({ modal: true, input, protocol, inputName });
  }

  modalControle = () => {
    this.setState({ details: {}, createUnit: false, isVisablePortList: false });
  };

  checkRequiredFields = () => {
    const { name, model, uniqueId } = this.state.form;
    if (name && model && uniqueId) {
      this.setState({ isVisableDeviceBtn: true });
    } else {
      this.setState({ isVisableDeviceBtn: false });
    }
  };

  handleChange = (name, value) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          [name]: value,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  port_handleChange = (name, value) => {
    if (
      !("" + (this.state.form.uniqueId || "")).trim() &&
      value &&
      value.key === "FX Tracker"
    ) {
      instance({
        url: `/api/devices/uniqueid`,
        method: "GET",
      })
        .then((dataResp) => {
          this.setState({
            form: {
              ...this.state.form,
              uniqueId: dataResp.uniqueId,
            },
          });
          this.setState(
            (preState) => ({
              ...preState,
              form: {
                ...preState.form,
                [name]: value.key,
                device: value.port,
              },
            }),
            () => this.checkRequiredFields()
          );
        })
        .catch((error) => {});
    } else {
      this.setState((preState) => ({
        ...preState,
        form: {
          ...preState.form,
          [name]: value,
          device: value.port,
        },
      }));
    }
  };

  timezone_handleChange = (name, value) => {};

  changeAttribute = (name, value) => {};
  changeAttribute2 = (name, event) => {
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    if (!value) {
      //delete this.state.form.attributes[name]
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: "",
            },
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: value,
            },
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };

  onCloseModal = () => {
    this.setState({
      isVisablePortList: false,
    });
  };

  showPortList = () => {
    this.setState({
      isVisablePortList: true,
    });
  };

  submitAdddevice = (obj) => {
    let { attributes, name, device, model } = this.state.form;
    if (name.trim().length > 0) {
      const obj = deviceDataFormat(this.state.form, attributes);

      instance({
        url: `/api/devices/`,
        method: "POST",
        headers: {
          Accept: "application/json; odata=verbose",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((device) => {
          this.props.dispatch(addDevices([device]));
          this.setState({
            createUnit: false,
            details: false,
            selectedUnit: false,
          });
          this.props.dispatch(
            notifySuccess(this.props.translate("trackerIsAdded"))
          );
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  };
  closeMainModel = () => {
    this.setState({
      details: {},
      createUnit: false,
      selectedUnit: false,
    });
  };

  render() {
    if (checkPrivileges("device")) {
      const endMessage =
        this.props.itemPagination && this.props.itemPagination.total > 0 ? (
          <p style={{ textAlign: "center", color: "#ccc" }}>
            -- {this.props.translate("end")} --
          </p>
        ) : (
          <p style={{ textAlign: "center", color: "#ccc" }}>
            {this.props.translate("notFound")}
          </p>
        );
      const { devices3, ...resetProps } = this.props;
      return (
        <Layout
          {...resetProps}
          sensorData={this.state.sensorsData}
          resourceList={this.state.resourceList}
          showResources={this.showResources}
          onLinkResource={this.onLinkResource}
          monitoringMap={this.map}
          openDeviceSettings={this.openDeviceSettings}
          showBox={this.showBox}
          // classFromChildren="main-view"
          //showDeviceModal={this.state.showDeviceModal}
          fetchMoreItems={this.fetchMoreItems}
          openVehicleTagsModal={this.openVehicleTagsModal}
          openDriverTagsModal={this.openDriverTagsModal}
          itemPagination={{ ...this.state.itemPagination, endMessage }}
          searchItems={this.searchItems}
          fetchMoreItems2={this.fetchMoreItems2}
          itemPagination2={{ ...this.state.itemPagination2 }}
          searchItems2={this.searchItems2}
          copyToClipboard={this.copyToClipboard}
          viewOnMap={this.props.viewOnMap}
          details={this.state.details}
          selectedUnit={this.state.selectedUnit}
        >
          {this.state.details &&
          this.state.details.id &&
          !this.state.createUnit ? (
            <MainView
              openDeviceSettings={this.openDeviceSettings}
              sensorData={this.state.sensorsData}
              item1={this.state.selectedUnit}
              details={this.state.details}
              selectedUnit={this.state.selectedUnit}
              createUnit={this.state.createUnit}
              modalControle={this.modalControle}
              getSensors={this.getSensors}
              closeMainModel={this.closeMainModel}
            />
          ) : null}

          {this.state.createUnit && (
            <>
              <CreateUnit
                form={this.state.form}
                handleChange={this.handleChange}
                onlyAttributes={this.props.onlyAttributes}
                themecolors={this.props.themecolors}
                group={this.props.group}
                changeAttribute={this.changeAttribute}
                modalControle={this.modalControle}
                buttonText="sharedSave"
                isVisableDeviceBtn={this.state.isVisableDeviceBtn}
                duplicateIdentifier={"this.state.duplicateIdentifier"}
                itemAttributes={"this.state.itemAttributes"}
                attributeChangesMessage={"this.state.attributeChangesMessage"}
                isHaveAccess="deviceCreate"
                showPortList={this.showPortList}
                btnCancelNotShow={true}
                showPort={true}
                addOption
                serverIp={
                  this.props.ServerSetting
                    ? this.props.ServerSetting.attributes.serverIp
                    : ""
                }
                port_handleChange={this.port_handleChange}
                timezone_handleChange={this.timezone_handleChange}
                changeAttribute2={this.changeAttribute2}
                logInUser={this.props.logInUser}
                formSubmit={this.submitAdddevice}
                closeCreateView={this.closeMainModel}
              />
              {this.state.isVisablePortList && (
                <DevicePortList
                  themecolors={this.props.themecolors}
                  onCloseModal={this.onCloseModal}
                  translate={this.props.translate}
                />
              )}
            </>
          )}

          <ResourceModal
            changeResource={this.props.changeResource}
            selectedResourse={this.state.selecteditem}
            activeOperation={this.state.activeOperation}
            onCloseResource={this.onCloseResource}
            itemPagination={devices3}
            assignItem={this.props.assignItem}
            unassignItem={this.props.unassignItem}
            fetchNestedItems={this.props.fetchNestedItems}
            nestedResources={this.props.nestedResources}
            translate={this.props.translate}
            linkResource={this.state.linkResource}
            themecolors={this.props.themecolors}
            itemType="Device"
          />
          <TrackingBox
            boxes={this.state.boxes}
            translate={this.props.translate}
            logInUser={this.props.logInUser}
            copyToClipboard={this.copyToClipboard}
            closeBox={this.closeBox}
          />

          {this.state.vehicleModal ? (
            <VehicleTagSelector
              userTags={this.props.logInUser.attributes}
              tags={this.props.tags}
              vehicleModal={this.state.vehicleModal}
              translate={this.props.translate}
              dispatch={this.props.dispatch}
              saveFilter={this.saveFilter}
              cancelFilter={this.cancelFilter}
            />
          ) : null}
          {this.state.driverModal ? (
            <DriverTagSelector
              userTags={this.props.logInUser.attributes}
              tags={this.props.driverTags}
              driverModal={this.state.driverModal}
              translate={this.props.translate}
              dispatch={this.props.dispatch}
              saveFilter={this.saveFilter}
              cancelFilter={this.cancelFilter}
            />
          ) : null}
        </Layout>
      );
    } else {
      return null;
    }
  }
}

const DrawAttributes = (props) => {
  if (props.data && props.data.attributes) {
    const keys = Object.keys(props.data.attributes);
    if (keys.length) {
      return keys.map((k) => {
        let v = props.data.attributes[k];
        let t = k;

        let statusText = null;
        let inputText = null;

        switch (k) {
          case "status":
            if (props.data.protocol === "galileo") {
              statusText = (
                <HelpOutlineIcon
                  style={{ verticalAlign: "middle" }}
                  onClick={(e) =>
                    props.openProtocol(
                      props.data.attributes.status,
                      props.data.protocol,
                      k
                    )
                  }
                />
              );
            }
            break;
          case "input":
            if (props.data.protocol === "bce") {
              inputText = (
                <HelpOutlineIcon
                  style={{ verticalAlign: "middle" }}
                  onClick={(e) =>
                    props.openProtocol(
                      props.data.attributes.input,
                      props.data.protocol,
                      k
                    )
                  }
                />
              );
            }

            break;

          case "unknown":
            return null;
          default:
            v = setAttributeFormat(k, v);
            break;
        }

        switch (k) {
          case "odometer":
            return null;
          case "index":
          case "hdop":
          case "vdop":
          case "pdop":
          case "sat":
          case "satVisible":
          case "rssi":
          case "gps":
          case "roaming":
          case "event":
          case "alarm":
          case "status":
          case "serviceOdometer":
          case "tripOdometer":
          case "hours":
          case "steps":
          case "power":
          case "input":
          case "output":
          case "battery":
          case "batteryLevel":
          case "fuel":
          case "fuelConsumption":
          case "approximate":
          case "rpm":
          case "throttle":
          case "armed":
          case "acceleration":
          case "deviceTemp":
          case "obdSpeed":
          case "obdOdometer":
          case "distance":
          case "totalDistance":
          case "commandResult":
          case "dtcs":
          case "blocked":
          case "operator":
          case "deviceCommand":
          case "sharedGeofence":
          case "motion":
          case "vin":
          case "deviceTotalDistance":
          case "rfid":
          case "versionFw":
          case "versionHw":
          case "ignition":
          case "flags":
          case "charge":
          case "ip":
          case "archive":
          case "sharedType":
          case "driverUniqueId":
          case "trailerUniqueId":
          case "stopTime":
          case "raw":
          case "mileageAccuracy":
          case "parking":
          case "custom":
          case "boardPower":
          case "lastUpdated":
            t = props.translate(k);
            break;
          default:
            break;
        }
        return (
          <div className={`all-attribute-item`} key={k}>
            <Tooltip title={props.translate("positionTooltip." + k)}>
              <span className="attr-label">{t} </span>
            </Tooltip>{" "}
            <span className="attr-value">
              {v} {inputText} {statusText}
            </span>
          </div>
        );
      });
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const mapStateToProps = (state) => {
  return {
    devicesFetched: state.devices.isFetching,
    devices: state.devices,
    devices3: state.devices3,
    trailers: state.trailers,
    tags: state.tags,
    driverTags: state.driverTags,
    allNotifications: state.allNotifications,
    deviceId: state.deviceId,
    trackId: state.trackId,
    drivers: state.drivers,
    ServerSetting: state.ServerSetting,
    mapLayer: state.mapLayer,
    logInUser: state.logInUsers,
    themecolors: state.themeColors,
    filterTagsList: state.filterTagsList,
    filterDriversList: state.filterDriversList,
    filterList:
      state.filterList && state.filterList.filters
        ? state.filterList.filters
        : [],
    positions: state.positions,
  };
};
export default connect(mapStateToProps)(
  withTranslationWrapper(withResources(Monitoring, "Device"))
);
