import React from "react";
import { connect } from "react-redux";
import Modal from "./modal";
import Button from "../../../common/Button";
import Tooltip from "../../../common/Tooltip";
import { validEmailPattern, validNumberPattern } from "../../../../Helpers";
import withTranslationWrapper from "../../../../HOC/withTranslation";

class screen extends React.Component {
  constructor() {
    super();
    this.state = {
      validatedForm: false,
    };
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
  }
  UNSAFE_componentWillMount() {
    let { wizardData } = this.props;
    if (wizardData && wizardData.form3) {
      this.setState(
        {
          ...wizardData.form3,
        },
        () => this.checkRequiredFields()
      );
    }
  }
  hideColorPicker = (e) => {
    this.setState({
      colorPicker: false,
    });
  };
  showColorPicker = (e) => {
    this.setState({
      colorPicker: true,
    });
  };
  updateColor = (e) => {
    this.setState(
      {
        form: {
          ...this.state.form,
          alertColor: e.hex,
        },
      },
      () => {
        this.checkRequiredFields();
      }
    );
  };
  handleChange = (name, event, id) => {
    if (name === "type") {
      this.setState(
        {
          form: {
            ...this.state.form,
            commandId: event.id,
            commandName: event.name,
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      if (
        name === "number" ||
        name === "web" ||
        name === "email" ||
        name === "mailTo" ||
        name === "command"
      ) {
        if (name === "email") {
          let { email } = this.state.form;
          email.map((item) => {
            if (item.id === id) {
              item.text = event.target.value;
            }
            return null;
          });
          this.setState(
            {
              form: {
                ...this.state.form,
                email: email,
              },
            },
            () => {
              this.checkRequiredFields();
            }
          );
        } else if (name === "mailTo") {
          this.setState(
            {
              form: {
                ...this.state.form,
                [name]: event.target.value,
                email: [{ id: 1, text: "" }],
              },
            },
            () => {
              this.checkRequiredFields();
            }
          );
        } else if (name === "command") {
          this.setState(
            {
              form: {
                ...this.state.form,
                [name]: event.target.value,
              },
            },
            () => {
              this.checkRequiredFields();
            }
          );
        } else if (name === "number") {
          let { number } = this.state.form;
          number.map((item) => {
            if (item.id === id) {
              item.text = event.target.value;
            }
            return null;
          });
          this.setState(
            {
              form: {
                ...this.state.form,
                number: number,
              },
            },
            () => {
              this.checkRequiredFields();
            }
          );
        } else {
          let { notificators } = this.state.form;
          if (event.target.checked) {
            if (notificators.length) {
              notificators = notificators + "," + name;
            } else {
              notificators = name;
            }
          } else {
            notificators = notificators.replace(name, "");
            notificators = notificators.replace(",", "");
          }
          this.setState(
            {
              form: {
                ...this.state.form,
                notificators,
              },
            },
            () => {
              this.checkRequiredFields();
            }
          );
        }
      } else {
        if (
          event.target.value === "" ||
          event.target.value === null ||
          event.target.value === undefined
        ) {
          delete this.state.form[name];
          this.setState(
            {
              form: {
                ...this.state.form,
              },
            },
            () => this.checkRequiredFields()
          );
        } else {
          this.setState(
            {
              form: {
                ...this.state.form,
                [name]: event.target.value,
              },
            },
            () => this.checkRequiredFields()
          );
        }
      }
    }
  };

  onChangedUsers = (item) => {
    if (item) {
      this.setState(
        {
          form: {
            ...this.state.form,
            usersId: item.value,
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            usersId: "",
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };
  checkRequiredFields() {
    let {
      isOpenEmailSection,
      isOpenCommandSection,
      isOpenSmsSection,
      isOpenWebSection,
      isOpenFirebaseSection,
      isOpenMobileSection,
    } = this.state;
    let { email, number, mailTo, commandType, commandData, commandName } =
      this.state.form;

    let value = true;
    if (
      (isOpenEmailSection ||
        isOpenCommandSection ||
        isOpenSmsSection ||
        isOpenWebSection ||
        isOpenFirebaseSection ||
        isOpenMobileSection) &&
      value
    ) {
      let vald = true;
      if (isOpenEmailSection) {
        if (mailTo === "user" || email[0].text) {
          vald = true;
        } else {
          vald = false;
        }
      }
      if (isOpenMobileSection) {
        if (number[0].text) {
          vald = true;
        } else {
          vald = false;
        }
      }
      if (isOpenCommandSection) {
        if (
          (commandType === "command" && commandName) ||
          (commandType === "custom" && commandData)
        ) {
          if (isOpenEmailSection) {
            if (mailTo === "user" || email[0].text) {
              vald = true;
            } else {
              vald = false;
            }
          } else {
            vald = true;
          }
        } else {
          vald = false;
        }
      }
      this.setState({
        validatedForm: vald,
      });
    } else {
      this.setState({
        validatedForm: false,
      });
    }
  }
  onAddAnotherMail = (type) => {
    let { email, number } = this.state.form;
    // frist check the formate of prevous emails
    let formateValidation = true;
    if (type === "email") {
      email.map((itam) => {
        if (!validEmailPattern.test(itam.text)) {
          formateValidation = false;
        }
        return null;
      });
      if (formateValidation) {
        email.push({ id: email.length + 1, text: "" });
        this.setState({
          form: {
            ...this.state.form,
            email: email,
          },
        });
      } else {
        this.props.enqueueSnackbar(
          this.props.translate("previousEmailInvalid"),
          { autoHideDuration: 1000 }
        );
      }
    } else if (type === "number") {
      number.map((itam) => {
        if (!validNumberPattern.test(itam.text)) {
          formateValidation = false;
        }
        return null;
      });
      if (formateValidation) {
        number.push({ id: number.length + 1, text: "" });
        this.setState({
          form: {
            ...this.state.form,
            number: number,
          },
        });
      } else {
        this.props.enqueueSnackbar(
          this.props.translate("previousNumberInvalid"),
          { autoHideDuration: 1000 }
        );
      }
    }
  };
  deleteMail = (type, id) => {
    // frist check the formate of prevous emails
    let data = this.state.form[type];
    if (data.length === 1 && id === 1) {
      data[0].text = "";
      this.setState({
        form: {
          ...this.state.form,
          [type]: data,
        },
      });
    } else {
      let newList = data.filter((itm) => itm.id !== id);
      newList.map((item, ind) => {
        item.id = ind + 1;
        return null;
      });
      this.setState({
        form: {
          ...this.state.form,
          [type]: newList,
        },
      });
    }
  };
  onChangedUsers = (item) => {
    let ids = [];
    if (item.length) {
      item.map((item) => {
        ids.push(item.id);
        return null;
      });
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          usersIds: ids,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  handleClick = (item) => {
    this.setState(
      {
        [item]: !this.state[item],
      },
      () => this.checkRequiredFields()
    );
  };

  next = () => {
    let data = { ...this.state, prv: true };
    this.props.combineAllData("form3", data);
  };
  prv = () => {
    if (this.props.handleChangeTab) {
      this.props.handleChangeTab("", this.props.tabPostion - 1);
    }
  };
  render() {
    return (
      <>
        <Modal
          {...this.props}
          {...this.state}
          handleClick={this.handleClick}
          handleChange={this.handleChange}
          onAddAnotherMail={this.onAddAnotherMail}
          onChangedUsers={this.onChangedUsers}
          hideColorPicker={this.hideColorPicker}
          showColorPicker={this.showColorPicker}
          updateColor={this.updateColor}
          deleteMail={this.deleteMail}
        />
        <div
          className="clearfix"
          style={{
            padding: "12px 20px 15px",
            borderTop: `1px dashed ${this.props.themecolors.InputBorderColor}`,
          }}
        >
          <Tooltip
            title={this.props.translate("saveCurrentTabDataAndGotoNext")}
          >
            <span>
              <Button
                disabled={!this.state.validatedForm}
                variant="contained"
                onClick={this.next}
                style={{ float: "right" }}
              >
                {this.props.translate("next")}
              </Button>
            </span>
          </Tooltip>
          <Button variant="contained" onClick={this.prv}>
            {this.props.translate("previous")}
          </Button>
        </div>
      </>
    );
  }
}

export const ScreenTwo = connect(null, null)(withTranslationWrapper(screen));
