import React, { Component } from "react";
import { connect } from "react-redux";
import Layout from "../../Layout";
import { getVehicles, removedvehicle } from "../../Actions/Vehicles";
import Grid from "@mui/material/Grid";
import { VehicleModal } from "../../Components/Vehicles/vehicleModal";
import ConfirmDialoag from "../../Components/common/ConfirmDialoag";
import { checkPrivileges } from "../../Helpers/index";
import CustomMarker from "../../Components/Maps/CustomMarker";
import { MapTooltip } from "../../Components/Maps/MapTooltip";
import axios from "axios";
import moment from "moment";
import {
  MapContainer as Map,
  TileLayer,
  Tooltip,
  ZoomControl,
  LayersControl,
} from "react-leaflet";

import { MapTileLayers } from "../../Reducers/Maps";
import L from "leaflet";
import Style from "style-it";
import { setTrackId } from "../../Actions/Devices";
import withResources from "../HOCRecources";
import ResourceModal from "../../Components/Recources/resourceModal";
import { notifySuccess } from "../../Utils/CustomNotifcations";
import instance from "../../axios";
import { errorHandler } from "../../Helpers/index";
import withTranslationWrapper from "../../HOC/withTranslation";

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context("./../../images", false, /\.(png|jpe?g|svg)$/)
);
const CancelToken = axios.CancelToken;
let source;

class vehicle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selecteditem: "",
      isVisable: false,
      showItemDetail: false,
      selecteItemUrl: "",
      activeOperation: "",
      vehicleAddress: "",
      vehicleLat: "",
      vehicleLon: "",
      selecteditemId: props.match.params.id,
      trackersApiResponce: false,
      trackerData: "",
      assignVehicle: false,
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      lat: 0,
      lng: 0,
      zoom: 3,
      minZoom: 3,
      animCount: 0,
      assigned: false,
      tracking: false,
      applied: false,
      resourceList: false,
      allVehicleLocation: {},
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
    };
    source = CancelToken.source();
  }

  UNSAFE_componentWillReceiveProps(n) {
    if (n.ServerSetting.zoom) {
      this.setState({
        zoom: n.ServerSetting.zoom,
        lat: n.ServerSetting.latitude,
        lng: n.ServerSetting.longitude,
      });
    }
    if (n.logInUser.zoom) {
      this.setState({
        zoom: n.logInUser.zoom,
        lat: n.logInUser.latitude,
        lng: n.logInUser.longitude,
      });
    }
    if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
      this.setState(
        {
          initFetch: true,
        },
        () => {
          // this.fetchData(
          //   n.logInUser.id,
          //   this.state.currentPage,
          //   this.state.pagesize
          // )
        }
      );
    }
    if (!n.match.params.id && this.props.match.params.id) {
      this.setState({ selecteditemId: this.props.match.params.id });
    }

    if (this.props.trackId !== n.trackId) {
      this.setState({ allVehicleLocation: {}, animCount: 0 }, () => {
        this.calculate(n);
      });
    } else {
      this.calculate(n);
    }
    if (n.match.params.id && n.match.url !== this.state.selecteItemUrl) {
      if (source) {
        source.cancel();
      }
      this.selectedItem(n.match.params.id);
    }
  }

  checkZoom = () => {
    if (this.state.applied === true) {
      this.setState({ tracking: false, applied: false, animCount: 0 }, () => {
        this.props.dispatch(setTrackId(0));
      });
      this.setState({ allTrailerLocation: {} }, () => {
        this.calculate(this.props);
      });
    }
  };

  calculate = (n) => {
    if (n.deviceRelatedData && Object.values(n.deviceRelatedData).length) {
      const list = {};
      const ids = n.vehicles.map((d) => {
        list[d.deviceId] = d;
        return d.deviceId;
      });
      const vehicles = {};
      Object.values(n.deviceRelatedData).map((d) => {
        if (ids.includes(d.id)) {
          vehicles[d.id] = d;
          vehicles[d.id].vehicle = list[d.id];
        }
        return null;
      });
      this.setState({ allVehicleLocation: vehicles });
    }

    if (
      this.state.showItemDetail &&
      n.vehicles &&
      n.vehicles.length &&
      n.deviceRelatedData &&
      Object.values(n.deviceRelatedData).length &&
      n.match &&
      n.match.params &&
      n.match.params.id &&
      this.props.renderMode === "view"
    ) {
      const vehicleLocation = Object.values(n.deviceRelatedData).find(
        ({ id, vehicle }) =>
          vehicle && parseInt(vehicle.id) === parseInt(n.match.params.id)
      );
      if (
        vehicleLocation &&
        vehicleLocation.latitude &&
        vehicleLocation.longitude
      ) {
        this.setState({ vehicleLocation: vehicleLocation || null }, () => {
          if (vehicleLocation && this.state.tracking === true) {
            this.map &&
              this.map
                .setMaxZoom(16)
                .fitBounds([
                  [vehicleLocation.latitude, vehicleLocation.longitude],
                ]);
            setTimeout(() => {
              this.map && this.map.setMaxZoom(n.mapLayer.maxZoom);
              this.setState({ applied: true, animCount: 1 });
            }, 200);
          }
        });
      }
    }
  };

  componentDidUpdate() {
    if (
      this.state.assignVehicle === false &&
      this.props.vehicles &&
      this.props.devices &&
      this.props.devices.length > 0 &&
      this.state.itemPagination &&
      this.state.itemPagination.total > 0
    ) {
      this.setState({ assignVehicle: true }, () => {
        const selecteditem = this.props.vehicles.find(
          (v) => "" + v.id === "" + this.state.selecteditemId
        );
        if (selecteditem && this.props.renderMode === "edit") {
          this.editItem(selecteditem);
        }
      });
    }

    if (
      this.props.logInUser &&
      this.props.logInUser.latitude &&
      this.props.logInUser.longitude &&
      this.state.assigned === false
    ) {
      this.setState({
        assigned: true,
        lat: this.props.logInUser.latitude,
        lng: this.props.logInUser.longitude,
      });
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.ServerSetting && this.props.ServerSetting.zoom) {
      this.setState({
        zoom: this.props.ServerSetting.zoom,
        lat: this.props.ServerSetting.latitude,
        lng: this.props.ServerSetting.longitude,
      });
    }
    if (this.props.logInUser && this.props.logInUser.zoom) {
      this.setState({
        zoom: this.props.logInUser.zoom,
        lat: this.props.logInUser.latitude,
        lng: this.props.logInUser.longitude,
      });
    }

    if (this.props.match.params.id) {
      this.setState({ selecteditemId: this.props.match.params.id });
    }
    if (
      this.props.match.params.id &&
      this.props.match.url !== this.state.selecteItemUrl
    ) {
      if (source) {
        source.cancel();
      }
      this.selectedItem(this.props.match.params.id);
    }

    let trailerUniqueId = 0;
    if (
      this.props.deviceRelatedData &&
      Object.values(this.props.deviceRelatedData).length
    ) {
      const list = {};
      const ids = this.props.vehicles.map((d) => {
        list[d.deviceId] = d;
        if (d.id === parseInt(this.props.match.params.id)) {
          trailerUniqueId = d.id;
        }
        return d.deviceId;
      });
      const vehicles = {};
      Object.values(this.props.deviceRelatedData).map((d) => {
        if (ids.includes(d.id)) {
          vehicles[d.id] = d;
          vehicles[d.id].vehicle = list[d.id];
        }
        return null;
      });
      this.setState({ allVehicleLocation: vehicles });
    }

    if (
      this.state.showItemDetail &&
      this.props.deviceRelatedData &&
      Object.values(this.props.deviceRelatedData).length &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id &&
      this.props.renderMode === "view"
    ) {
      const vehicleLocation = Object.values(this.props.deviceRelatedData).find(
        ({ id }) =>
          trailerUniqueId && parseInt(id) === parseInt(trailerUniqueId)
      );
      if (
        vehicleLocation &&
        vehicleLocation.latitude &&
        vehicleLocation.longitude
      ) {
        this.setState({ vehicleLocation: vehicleLocation || null }, () => {
          if (vehicleLocation && this.state.tracking === true) {
            this.map &&
              this.map
                .setMaxZoom(16)
                .fitBounds([
                  [vehicleLocation.latitude, vehicleLocation.longitude],
                ]);
            setTimeout(() => {
              this.map && this.map.setMaxZoom(this.props.mapLayer.maxZoom);
              this.setState({ applied: true });
            }, 100);
          }
        });
      }
    }
  }

  componentWillUnmount() {
    if (source) {
      source.cancel();
    }
    this.setState({
      selecteditem: "",
      isVisable: false,
      showItemDetail: false,
      activeOperation: "",
      vehicleAddress: "",
      vehicleLat: "",
      vehicleLon: "",
      selecteditemId: "",
      trackersApiResponce: false,
      multiTrackers: "",
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      animCount: 0,
      allVehicleLocation: {},
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
    });
  }

  searchItems = (text) => {
    if (source) {
      source.cancel();
    }
    this.setState(
      {
        searchText: text,
      },
      () => {
        source = CancelToken.source();
        // this.fetchData(this.props.logInUser.id, 1, this.state.pagesize, true)
      }
    );
  };
  fetchMoreItems = (a, b, c) => {
    // this.fetchData(
    //   this.props.logInUser.id,
    //   this.state.currentPage,
    //   this.state.pagesize
    // )
  };

  removeDuplicates = (n, o) => {
    let items = [];
    o.map((j) => {
      let found = n.data.find((e) => e.id === j.id);
      if (found) {
        items.push(found);
      }
      return null;
    });

    if (!items.length) {
      this.setState(
        {
          itemPagination: {
            ...n,
            items: o.concat(n.data),
          },
          currentPage: n.hasNext ? n.page + 1 : n.page,
        },
        () => {
          this.props.dispatch(getVehicles(o.concat(n.data)));
        }
      );
    }
  };
  fetchData = (userId, page, perPage, reset) => {
    const query = "all=true&userId=" + userId;
    let searchText = this.state.searchText;

    if (searchText) {
      searchText = "&search=" + searchText;
    } else {
      searchText = "";
    }
    let items = this.state.itemPagination.items;
    if (reset) {
      this.setState(
        {
          itemPagination: { ...this.state.itemPagination, items: [] },
        },
        () => {
          items = [];
        }
      );
    }

    instance({
      method: "GET",
      url: `/api/vehicles/get?${query}&page=${page}&limit=${perPage}${searchText}`,
      cancelToken: source.token,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // if (response.status === 200) {
        // if (response.data.status === 'success') {
        this.removeDuplicates(response, items);
        // } else {
        //something went wrong
        // }
        // }
      })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
  };
  addItem = () => {
    this.setState(
      {
        isVisable: true,
        showItemDetail: false,
        selecteditem: "",
        trackerData: "",
        activeOperation: "add",
      },
      () => this.props.history.push("/vehicles")
    );
  };
  showResources = (type) => {
    this.setState({
      resourceList: type,
    });
  };
  addResource = () => {
    this.setState({
      isVisable: true,
      showItemDetail: false,
      activeOperation: "addResource",
      selecteditem: "",
      driverAddress: "",
      driverLat: "",
      driverLon: "",
    });
  };
  onEditResource = (item) => {
    this.setState({
      isVisable: true,
      showItemDetail: false,
      activeOperation: "editResource",
      selecteditem: item,
      driverAddress: "",
      driverLat: "",
      driverLon: "",
    });
  };
  onLinkResource = (item) => {
    this.setState(
      {
        linkResource: true,
        selecteditem: item,
      },
      () => {
        this.props.fetchNestedItems(item.id, 1);
      }
    );
  };
  onCloseResource = () => {
    this.setState({
      linkResource: false,
    });
    this.onCloseModal();
  };

  removedItem = (item) => {
    instance({
      url: `/api/vehicles/${item.id}`,
      method: "DELETE",
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((data) => {
        // if (data.status) {
        this.props.dispatch(removedvehicle(item));
        this.onCloseModal();
        this.setState({
          selectedVehicle: "",
        });
        this.fetchData(this.props.logInUser.id, 1, this.state.pagesize, true);
        this.props.dispatch(
          notifySuccess(this.props.translate("vehicleIsDeleted"))
        );
        // } else {
        //   this.props.dispatch(
        //     notifySuccess({
        //       message: data.message,
        //       autoDismiss: 10
        //     })
        //   )
        // }
        //   })
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  getSelectedDevice = (trailerUniqueId) => {
    if (trailerUniqueId) {
      const device = this.props.devices.find((d) => d.id === trailerUniqueId);
      if (device) {
        this.setState({
          trackerData: { value: device.id, label: device.name },
        });
      } else {
        this.setState({
          trackerData: "",
        });
      }

      if (
        this.map &&
        this.props.deviceRelatedData &&
        Object.values(this.props.deviceRelatedData).length
      ) {
        this.props.dispatch(setTrackId(0));
        this.map.setZoom(3);
        const vehicleLocation = Object.values(
          this.props.deviceRelatedData
        ).find(({ id }) => id === trailerUniqueId);
        this.setState({
          vehicleLocation:
            vehicleLocation && vehicleLocation.exists ? vehicleLocation : null,
          animCount: 0,
        });
        if (vehicleLocation && vehicleLocation.exists) {
          this.props.dispatch(setTrackId(vehicleLocation.id));
        }
      }
    }
    this.setState({
      trackersApiResponce: true,
    });
  };

  editItem = (item) => {
    if (item.deviceId) {
      this.setState(
        {
          trackersApiResponce: false,
        },
        () => this.getSelectedDevice(item.deviceId)
      );
    } else {
      this.setState({
        trackersApiResponce: true,
      });
    }

    this.setState({
      isVisable: true,
      showItemDetail: false,
      selecteditem: item,
      activeOperation: "edit",
      trackerData: "",
    });
    this.props.history.push("/vehicles/" + item.id);
  };

  onCloseModal = () => {
    this.setState({
      isVisable: false,
      showItemDetail: false,
      selecteditem: "",
      activeOperation: "",
      onDeleteConfirmation: false,
    });
    this.props.history.push("/vehicles");
  };
  selectedItem = async (peramId) => {
    let data;
    this.setState({
      selecteItemUrl: this.props.match.url,
    });
    if (source) {
      source.cancel();
    }
    source = CancelToken.source();
    try {
      const response = await instance({
        method: "GET",
        url: `/api/vehicles/get?vehicleId=${peramId}`,
        cancelToken: source.token,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      // if (response.status === 'success') {
      const data = response && response && response.data;
      const dd = (await this.props.devices.length) > 0;
      data.map((item) => {
        this.setState(
          {
            selecteditem: item,
            selecteditemId: item.id,
          },
          () => {
            if (this.props.renderMode === "view") {
              this.selecteItem(item);
            } else if (this.props.renderMode === "edit") {
              this.editItem(item);
            }
          }
        );
      });
      // }
    } catch (error) {
      //  errorHandler(error,this.props.dispatch)
    }
  };
  selecteItem = (item) => {
    this.props.dispatch(setTrackId(0));
    this.setState({ allVehicleLocation: {} }, () => {
      this.getSelectedDevice(item.deviceId);
      this.calculate(this.props);
      this.setState(
        {
          showItemDetail: true,
          isVisable: false,
          activeOperation: "details",
          trackersApiResponce: false,
          trackerData: "",
          multiTrackers: "",
          vehicleLocation: null,
          animCount: 0,
          tracking: true,
          applied: false,
          onDeleteConfirmation: false,
        },
        () => this.getSelectedDevice(item.deviceId)
      );
    });
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };

  mapReference = (el) => {
    if (el) {
      this.map = el.leafletElement;
    }
  };

  onRemovedItem = (item) => {
    this.setState({
      selecteditem: item,
      onDeleteConfirmation: true,
    });
  };

  render() {
    let crs = {};

    if (["yandexMap", "yandexSat"].includes(this.props.mapLayer.id)) {
      crs = { crs: L.CRS.EPSG3395 };
    }

    const body = [
      <>
        <ZoomControl position={"bottomright"} />
      </>,
    ];

    const position = [this.state.lat, this.state.lng];
    const thisMap = [
      <Map
        key={1}
        ref={this.mapReference}
        onZoomAnim={this.checkZoom}
        zoomControl={false}
        bounds={
          this.state.bounds && this.state.bounds.length
            ? this.state.bounds
            : null
        }
        boundsOptions={this.setBoundOptions}
        style={{ height: this.props.height, width: this.props.width }}
        center={position}
        zoom={this.state.zoom}
        minZoom={this.state.minZoom}
        maxZoom={this.props.mapLayer.maxZoom}
        maxNativeZoom={this.props.mapLayer.maxZoom}
        {...crs}
      >
        {this.state.pointer}
        {body}
        <LayersControl position="bottomright">
          {MapTileLayers.map((layer) => (
            <LayersControl.BaseLayer
              id={layer.id}
              checked={layer.id === this.props.mapLayer.id}
              key={layer.id}
              name={layer.name}
            >
              <TileLayer {...layer} minZoom={this.state.minZoom} />
            </LayersControl.BaseLayer>
          ))}
        </LayersControl>
      </Map>,
    ];

    if (checkPrivileges("vehicle")) {
      return (
        <>
          <Layout
            {...this.props}
            addItem={this.addItem}
            editItem={this.editItem}
            removedItem={this.onRemovedItem}
            selecteItem={this.selectedItem}
            fetchMoreItems={this.fetchMoreItems}
            classFromChildren={
              !this.state.isVisable ? "no-padding" : "has-padding"
            }
            itemPagination={{ ...this.state.itemPagination }}
            searchItems={this.searchItems}
            allVehicleLocation={this.state.allVehicleLocation}
            showResources={this.showResources}
            onEditResource={this.onEditResource}
            onLinkResource={this.onLinkResource}
            addResource={this.addResource}
            resourceList={this.state.resourceList}
          >
            <Style>{`
              .leaflet-control-layers-toggle {
                  background: url('/assets/images/maps/layers.png') no-repeat center;
                } 
            `}</Style>
            {!this.state.isVisable ? (
              <div>
                {["osm", ""].includes(this.props.mapLayer.id) ? thisMap : null}
                {["carto"].includes(this.props.mapLayer.id) ? thisMap : null}
                {["googleTerrain"].includes(this.props.mapLayer.id)
                  ? thisMap
                  : null}
                {["googleSatellite"].includes(this.props.mapLayer.id)
                  ? thisMap
                  : null}
                {["googleHybrid"].includes(this.props.mapLayer.id)
                  ? thisMap
                  : null}
                {["googleRoad"].includes(this.props.mapLayer.id)
                  ? thisMap
                  : null}
                {["baidu"].includes(this.props.mapLayer.id) ? thisMap : null}
                {["yandexMap", "yandexSat"].includes(this.props.mapLayer.id)
                  ? thisMap
                  : null}
              </div>
            ) : null}

            {this.state.showItemDetail && (
              <VehicleModal
                onCloseModal={this.onCloseModal}
                devicesIcons={images}
                currentLocation={this.state.vehicleLocation}
                devices={this.props.devices}
                resourceList={this.state.resourceList}
                getSelectedDevice={this.getSelectedDevice}
                {...this.state}
              />
            )}
            <ResourceModal
              changeResource={this.props.changeResource}
              selectedResourse={this.state.selecteditem}
              activeOperation={this.state.activeOperation}
              onCloseResource={this.onCloseResource}
              itemPagination={this.props.vehicles}
              assignItem={this.props.assignItem}
              unassignItem={this.props.unassignItem}
              fetchNestedItems={this.props.fetchNestedItems}
              nestedResources={this.props.nestedResources}
              translate={this.props.translate}
              editResource={this.state.editResource}
              linkResource={this.state.linkResource}
              themecolors={this.props.themecolors}
              itemType="Vehicle"
            />

            {!this.state.showItemDetail ? (
              <div className="main-content-page">
                <div
                  style={{
                    background: this.props.themecolors.backgroundColor,
                    color: this.props.themecolors.textColor,
                    borderRadius: 6,
                  }}
                >
                  {this.state.isVisable &&
                    ["add", "edit"].includes(this.state.activeOperation) && (
                      <Grid container spacing={1}>
                        <Grid item xs={12} md={12}>
                          <VehicleModal
                            resourceList={this.state.resourceList}
                            onCloseModal={this.onCloseModal}
                            getSelectedDevice={this.getSelectedDevice}
                            {...this.state}
                          />
                        </Grid>
                      </Grid>
                    )}
                </div>
              </div>
            ) : null}

            {this.state.onDeleteConfirmation && (
              <ConfirmDialoag
                onCancel={this.onCancel}
                onOk={() => this.removedItem(this.state.selecteditem)}
                title={this.props.translate("areYouWantToDelete")}
                children={this.state.selecteditem.label}
              />
            )}
          </Layout>
        </>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return {
    ServerSetting: state.ServerSetting,
    logInUser: state.logInUsers,
    vehicles: state.vehicles,
    positions: state.positions,
    devices: state.devices3,
    themecolors: state.themeColors,
    deviceRelatedData: state.deviceRelatedData,
    trackId: state.trackId,
    mapLayer: state.mapLayer,
  };
};
export default connect(mapStateToProps)(
  withTranslationWrapper(withResources(vehicle, "Vehicle"))
);
