import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import MuiExpansionPanel from "@mui/material/Accordion";
import ExpansionPanelDetails from "@mui/material/AccordionDetails";
import MuiExpansionPanelSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { connect } from "react-redux";
import Style from "style-it";

const mapStateToProps = (state, ownProps) => {
  return {
    themecolors: state.themeColors,
  };
};

const styles = (theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },

  heading: {
    // fontSize: theme.typography.pxToRem(14),
    color: "#333",
    fontWeight: 700,
  },
  content: {
    // padding: theme.spacing(1) * 3,
    // [theme.breakpoints.down("sm")]: {
    //   padding: theme.spacing(1),
    // },
  },

  summeryContent: {
    alignItems: "center",
  },
});

const ExpansionPanelStyle = (theme) => ({
  root: {
    background: "none",
    "&$expanded": {
      background: "none",
      height: "100%",
    },
  },
  expanded: {},
});

const ExpansionPanel = withStyles(ExpansionPanelStyle)((props) => (
  <MuiExpansionPanel {...props} />
));

ExpansionPanel.muiName = "ExpansionPanel";

const ExpansionPanelSummaryStyle = (theme) => ({
  root: {
    //background: themecolors.backgroundColor,
    //borderBottom: '1px solid rgba(0,0,0,.125)',
    marginBottom: -1,
    minHeight: 32,

    "&$expanded": {
      minHeight: 32,
    },
  },
  expandIcon: {
    color: "#969696",
    padding: 7,
    "&:hover": {
      color: "#000",
    },
  },
  content: {
    margin: "4px 0",
    "&$expanded": {
      margin: "4px 0",
    },
  },
  expanded: {},
});

const ExpansionPanelSummary = withStyles(ExpansionPanelSummaryStyle)(
  (props) => <MuiExpansionPanelSummary {...props} />
);

ExpansionPanelSummary.muiName = "ExpansionPanelSummary";

class ControlledExpansionPanels extends React.Component {
  state = {
    expanded: this.props.open,
  };

  handleChange = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  render() {
    const { classes, themecolors } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classes.root}>
        <Style>
          {`
          .ExpansionPanelSummary-header {
            background: ${themecolors.menuActiveBackground};
            color: ${themecolors.themeInverse};
          }
          .ExpansionPanelSummary-heading {
            background: ${themecolors.menuActiveBackground};
            color: ${themecolors.themeInverse};
          }
          `}
          <div>
            <ExpansionPanel
              classes={{ root: classes.panel }}
              expanded={expanded === true}
              onChange={this.handleChange(true)}
              style={{ maxWidth: this.props.maxWidth }}
            >
              <ExpansionPanelSummary
                className="ExpansionPanelSummary-header"
                classes={{
                  content: classes.summeryContent,
                  root: "ExpansionPanelSummary-paper",
                }}
                expandIcon={
                  <ExpandMoreIcon className={"ExpansionPanelSummary-heading"} />
                }
              >
                <Typography className={"ExpansionPanelSummary-heading"}>
                  {this.props.title || "Default Title"}
                </Typography>
                {this.props.headerActions}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                className={classes.content}
                style={{
                  padding: this.props.bodyPadding,
                  boxSizing: "border-box",
                  maxHeight: this.props.maxHeight || "inherit",
                  overflow: this.props.maxHeight ? "auto" : "visible",
                }}
              >
                {this.props.children}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        </Style>
      </div>
    );
  }
}

ControlledExpansionPanels.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(
  withStyles(styles)(ControlledExpansionPanels)
);
