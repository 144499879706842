import React from "react";
import Button from "../../../Components/common/Button";
import CustomDialog from "../../../Components/common/Dialog";
import { connect } from "react-redux";
import Ignition from "./ignition";
import OtherSensors from "./otherSensors";
import AggregateSensors from "./aggregateSensors";
import isEqual from "react-fast-compare";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
// import { notifySuccess } from "../../Utils/CustomNotifications";
import parse from "html-react-parser";
import AddIcon from "@mui/icons-material/Add";
import {
  updateComputedAttributeDeviceWise,
  RemoveComputedAttributeDeviceWise,
} from "./../../../Actions/ComputedAttributes";
import ConfirmDialoag from "../../../Components/common/ConfirmDialoag";
import { ReactComponent as DiscreteIcon } from "../../../assets/sensors/discrete.svg";
import { ReactComponent as MeasurementIcon } from "../../../assets/sensors/centimeter.svg";
import { ReactComponent as OtherSensorIcon } from "../../../assets/sensors/sensor.svg";
import { ReactComponent as AggregateIcon } from "../../../assets/sensors/chip.svg";
import Measurement from "./measurement";
import { checkPrivileges } from "../../../Helpers/index";
import { Collapse, IconButton } from "@mui/material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import instance from "../../../axios";
import { errorHandler } from "../../../Helpers/index";
import CrossIcons from "@mui/icons-material/Remove";
import { ReactComponent as IgnitionSensorIcon } from "../../../assets/monitoring/ignition-01.svg";
import { ReactComponent as BoardPowerIcon } from "../../../assets/monitoring/board-power.svg";
import { ReactComponent as PowerCutIcon } from "../../../assets/monitoring/electric-car-01.svg";

// import { fontWeight } from "@material-ui/system";
import { notifySuccess } from "../../../Utils/CustomNotifcations";

class Sensors extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEnableBtn: true,
      selecteItem: "",
      scale: {},
      modal: "",
      isVisableMeasurementBtn: false,
      isVisableDiscreteBtn: false,
      isVisableOthersBtn: false,
      isVisableAggregateBtn: false,
      collapse: {
        1: true,
        2: true,
        3: true,
        4: true,
      },
      form: {
        attributes: {
          minThreshold: 0,
          copyFromLast: false,
          decodeToDecimal: false,
          resetDriver: "disabled",
          allowMinMax: true,
        },
      },
      operation: "",
      isSensorError: false,
      addButton: "sharedSave",
      defaultData: "",
      formOpen: false,
      formOpen1: false,
      formOpen2: false,
      formOpen3: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.changeAttribute = this.changeAttribute.bind(this);
    this.updateComputedAttributes = this.updateComputedAttributes.bind(this);
    this.addComputedAttributes = this.addComputedAttributes.bind(this);
  }

  toggleCollapse = (id) => {
    this.setState({
      collapse: { ...this.state.collapse, [id]: !this.state.collapse[id] },
    });
  };

  UNSAFE_componentWillMount() {
    if (parseInt(this.props.deviceId) !== parseInt(this.state.selecteItem)) {
      this.setState({
        selecteItem: this.props.deviceId,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(n, NextState) {
    if (parseInt(n.deviceId) !== parseInt(this.state.selecteItem)) {
      this.setState({
        selecteItem: n.deviceId,
      });
    }
  }
  openDialog = (type) => {
    if (type === "boardPower") {
      this.setState({
        modal: type,
        addButton: "sharedSave",
        operation: "",
        formOpen: false,
        formOpen1: true,
        formOpen2: false,
        formOpen3: false,
        isVisableMeasurementBtn: false,
        form: {
          attribute: "boardPower",
          attributes: {
            input: "",
            copyFromLast: false,
            resetDriver: "disabled",
          },
        },
      });
    } else if (type === "others") {
      this.setState({
        modal: type,
        addButton: "sharedSave",
        operation: "",
        isVisableOthersBtn: false,
        copyFromLast: false,
        // formOpen:true,
        formOpen: false,
        formOpen1: false,
        formOpen2: true,
        formOpen3: false,
        resetDriver: "disabled",
        form: {
          attributes: {
            input: "",
            copyFromLast: false,
            decodeToDecimal: false,
            resetDriver: "disabled",
          },
        },
      });
    } else if (type === "immobilizer") {
      this.setState({
        modal: type,
        addButton: "sharedSave",
        operation: "",
        formOpen: false,
        formOpen1: false,
        formOpen2: false,
        formOpen3: true,
        isVisableAggregateBtn: false,
        form: {
          attribute: "immobilizer",
          copyFromLast: false,
          resetDriver: "disabled",
          attributes: {
            minThreshold: 0,
            copyFromLast: false,
            decodeToDecimal: false,
            resetDriver: "disabled",
          },
        },
      });
    } else if (type === "ignition") {
      this.setState({
        modal: type,
        addButton: "sharedSave",
        operation: "",
        formOpen: true,
        formOpen1: false,
        formOpen2: false,
        formOpen3: false,
        isVisableDiscreteBtn: false,
        form: {
          attribute: "ignition",

          copyFromLast: false,
          resetDriver: "disabled",
          attributes: {
            minThreshold: 0,
            copyFromLast: false,
            decodeToDecimal: false,
            resetDriver: "disabled",
          },
        },
      });
    } else {
      this.setState({
        formOpen: false,
        formOpen1: false,
        formOpen2: false,
        formOpen3: false,
        modal: "",
      });
    }
  };
  onClose = () => {
    this.setState({
      modal: "",
      formOpen: false,
      formOpen1: false,
      formOpen2: false,
      formOpen3: false,
    });
  };

  handleChange(name, event) {
    if (name === "sensorType" && this.state.modal === "boardPower") {
      if (this.state.form[name] !== event.target.value) {
        let unit = "";
        if (event.target.value === "fuel") {
          unit = "L";
        } else if (event.target.value === "temperature") {
          unit = "℃";
        } else if (event.target.value === "rpm") {
          unit = "rpm";
        } else if (
          event.target.value === "averagFuelConsumption" ||
          event.target.value === "instantFuelConsumption" ||
          event.target.value === "mileage"
        ) {
          unit = "L/100km";
        } else if (
          event.target.value === "boardPower" ||
          event.target.value === "humidity" ||
          event.target.value === "counter"
        ) {
          unit = "custom";
        } else if (event.target.value === "speed") {
          unit = "km/h";
        } else if (event.target.value === "flowMeter") {
          unit = "L";
        } else if (event.target.value === "custom") {
          unit = "custom";
        }
        let filterdataForm = this.state.form;
        delete filterdataForm["accuracy"];
        delete filterdataForm["customUnit"];
        delete filterdataForm["drainByTime"];
        delete filterdataForm["drainByDistance"];
        let filterdata = this.state.form.attributes;
        delete filterdata["accuracy"];
        delete filterdata["customUnit"];
        delete filterdata["drainByTime"];
        delete filterdata["drainByDistance"];
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: event.target && event.target.value,
              attributes: {
                ...this.state.form.attributes,
                unit: unit,
                [name]: event.target && event.target.value,
              },
              unit,
            },
          },
          () => this.checkVisablity()
        );
      }
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: event.target && event.target.value,
            attributes: { ...this.state.form.attributes },
          },
        },
        () => this.checkVisablity()
      );
    }
  }
  checkVisablityOfSensor = (value, scale) => {
    if (scale) {
      this.setState(
        {
          isSensorError: value,
          scale,
        },
        () => this.checkVisablity()
      );
    } else {
      this.setState(
        {
          isSensorError: value,
        },
        () => this.checkVisablity()
      );
    }
  };
  checkVisablity = () => {
    const { modal, isSensorError, form, scale, defaultData, operation } =
      this.state;
    let {
      attribute,
      description,
      input,
      unit,
      type,
      customUnit,
      attributes,
      category,
      check,
      expression,
      id,
      sensorType,
    } = form;

    if (modal === "ignition") {
      if (
        description &&
        description.trim().length > 0 &&
        input &&
        input.trim().length > 0
      ) {
        if (operation === "edit") {
          let obj = {
            // attribute,
            // attributes,
            category,
            // check,
            // description,
            // expression,
            // id,
          };
          let changed = !isEqual(
            obj,
            defaultData || obj.attributes,
            defaultData.attributes
          );
          this.setState({
            isVisableDiscreteBtn: changed,
          });
        } else {
          this.setState({
            isVisableDiscreteBtn: true,
          });
        }
      } else {
        this.setState({
          isVisableDiscreteBtn: false,
        });
      }
    } else if (modal === "others") {
      if (
        attribute &&
        attribute.trim().length > 0 &&
        description &&
        description.trim().length > 0
      ) {
        if (operation === "edit") {
          let obj = {
            // attribute,
            // attributes,
            // type,
            category,
            // check,
            // description,
            // expression,
            // id,
          };
          let changed = !isEqual(obj, defaultData);
          this.setState({
            isVisableOthersBtn: changed,
          });
        } else {
          this.setState({
            isVisableOthersBtn: true,
          });
        }
      } else {
        this.setState({
          isVisableOthersBtn: false,
        });
      }
    } else if (modal === "immobilizer") {
      if (description && description.trim().length > 0) {
        if (operation === "edit") {
          let obj = {
            // attribute,
            // attributes,
            // type,
            // category,
            // check,
            description,
            // expression,
            // id,
          };
          let changed = !isEqual(obj, defaultData);
          this.setState({
            isVisableAggregateBtn: changed,
          });
        } else {
          this.setState({
            isVisableAggregateBtn: true,
          });
        }
      } else {
        this.setState({
          isVisableAggregateBtn: false,
        });
      }
    }

    if (modal === "boardPower") {
      if (
        // sensorType &&
        // sensorType.trim() &&
        description &&
        description.trim() &&
        // unit &&
        // unit.trim() &&
        input &&
        input.trim() &&
        !isSensorError
      ) {
        let unitError = unit
          ? unit === "custom" &&
            (!customUnit || (customUnit && !customUnit.trim().length))
          : true;
        if (unit !== "custom") {
          delete attributes.customUnit;
        } else {
          attributes.customUnit = customUnit;
        }
        if (attributes && !attributes.sensorType) {
          attributes.sensorType = attribute;
        }
        let formObj = {
          attribute,
          type,
          attributes,
          category,
          description,
          expression,
          id,
          scale,
        };

        this.setState({
          isVisableMeasurementBtn: !isEqual(formObj, defaultData) && !unitError,
        });
      } else {
        this.setState({
          isVisableMeasurementBtn: false,
        });
      }
    }
  };
  changeAttribute(name, value) {
    if (
      name === "multiplier" ||
      name === "minThreshold" ||
      name === "maxThreshold" ||
      name === "accuracy"
    ) {
      value = parseFloat(value);
    }

    if (this.state.modal === "boardPower") {
      if (name === "allowMinMax") {
        this.setState(
          {
            form: {
              ...this.state.form,
              attributes: {
                ...this.state.form.attributes,
                allowMinMax: !value,
              },
              deviceId: this.props.deviceId,
            },
          },
          () => this.checkVisablity()
        );
      } else {
        this.setState(
          {
            form: {
              ...this.state.form,
              [name]: value,
              attributes: { ...this.state.form.attributes, [name]: value },
              deviceId: this.props.deviceId,
            },
          },
          () => this.checkVisablity()
        );
      }
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value,
            attributes: { ...this.state.form.attributes, [name]: value },
            deviceId: this.props.deviceId,
          },
        },

        () => this.checkVisablity()
      );
    }
  }

  save = () => {
    const { modal, scale } = this.state;
    if (modal === "boardPower") {
      const { description, id, attributes, attribute } = this.state.form;
      let scaleObj = {};
      if (scale && scale.length) {
        scale.map((item) => {
          let key = item.x,
            value = item.y;
          scaleObj = { ...scaleObj, [key]: value };
          return null;
        });
      }
      if (Object.keys(scaleObj).length) {
        attributes.scale = scaleObj;
      } else {
        attributes.scale = { 0: 0 };
      }
      let form = {
        attribute:
          attributes && attributes.sensorType
            ? attributes.sensorType + "" + (attributes.index || "")
            : attribute,
        description,
        attributes,
        type: "number",
        expression: "",
        category: 2,
        id: id || 0,
      };
      if (!attributes.allowMinMax) {
        delete form.attributes.maxThreshold;
        delete form.attributes.minThreshold;
      }
      if (id) {
        form.id = id;
        // method put
        this.updateComputedAttributes(this.props.deviceId, id, form);
      } else {
        this.addComputedAttributes(this.props.deviceId, form);
      }
    } else if (modal === "ignition") {
      const { attribute, description, id, attributes } = this.state.form;
      let form = {
        attribute,
        description,
        attributes,
        expression: "",
        type: "boolean",
        category: 1,
        id: id || 0,
      };
      if (id) {
        form.id = id;
        this.updateComputedAttributes(this.props.deviceId, id, form);
      } else {
        let form2 = {
          ...form,
          attribute: "ignition",
          attributes: {
            ...form.attributes,
            copyFromLast: false,
            decodeToDecimal: false,
            minThreshold: 0,
          },
        };
        this.addComputedAttributes(this.props.deviceId, form2);
      }
    } else if (modal === "immobilizer") {
      const {
        attribute,
        description,
        id,
        attributes,
        type,
        expStart,
        expOpt,
        expEnd,
      } = this.state.form;
      let form = {
        attribute,
        description,
        attributes,
        expression: "",
        type: "boolean",
        category: 1,
        id: id || 0,
      };
      if (id) {
        form.id = id;
        this.updateComputedAttributes(this.props.deviceId, id, form);
      } else {
        let form2 = {
          ...form,
          attribute: "immobilizer",
          attributes: {
            ...form.attributes,
            copyFromLast: false,
            decodeToDecimal: false,
            minThreshold: 0,
          },
        };
        this.addComputedAttributes(this.props.deviceId, form2);
      }
    } else {
      const { attribute, description, id, attributes } = this.state.form;
      let form = {
        attribute,
        description,
        attributes,
        type: "boolean",
        expression: "",
        category: 1,
        id: id || 0,
      };
      if (id) {
        form.id = id;
        // method put
        this.updateComputedAttributes(this.props.deviceId, id, form);
      } else {
        this.addComputedAttributes(this.props.deviceId, form);
      }
    }
  };

  updateComputedAttributes(deviceId, id, form) {
    if (form.attribute !== "driverUniqueId") delete form.attributes.resetDriver;
    if (
      form.attributes &&
      form.attributes.resetDriver &&
      form.attributes.resetDriver === "disabled"
    )
      delete form.attributes.resetDriver;
    instance({
      url: `/api/sensors/${id}/${deviceId}`,
      method: "PUT",
      data: {
        ...form,
      },
    })
      .then((Attribute) => {
        this.props.dispatch(updateComputedAttributeDeviceWise(Attribute));
        this.props.getSensors(deviceId);
        this.props.dispatch(
          notifySuccess(this.props.translate("sensorIsUpdated"))
        );

        this.setState(
          {
            isVisableMeasurementBtn: false,
            isVisableDiscreteBtn: false,
            isVisableOthersBtn: false,
            isVisableAggregateBtn: false,
            isVisableDecoderBtn: false,
            modal: "",
          }
          //  ()=>{
          //   this.props.openDeviceSettings(id,"update")
          // }
        );
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch, this.errorCallBack);
      });
  }
  errorCallBack = (data) => {
    this.setState({
      isVisableMeasurementBtn: false,
      isVisableDiscreteBtn: false,
      isVisableOthersBtn: false,
      isVisableAggregateBtn: false,
      isVisableDecoderBtn: false,
      modal: "",
    });
  };

  addComputedAttributes(deviceId, formData) {
    let id = this.props.item1.id;
    let form = formData;
    if (form.attribute !== "driverUniqueId") delete form.attributes.resetDriver;
    if (
      form.attributes &&
      form.attributes.resetDriver &&
      form.attributes.resetDriver === "disabled"
    )
      delete form.attributes.resetDriver;
    if (form.attributes.valueFrom === "decimal") {
      form.attributes["1stLittleEndian"] = false;
    }
    if (form.attributes["1stConv"] === "decimal") {
      form.attributes["2ndLittleEndian"] = false;
    }
    instance({
      url: `/api/sensors/${id}`,
      method: "POST",
      data: {
        ...form,
      },
    })
      .then((Attribute) => {
        //this.props.dispatch(addComputedAttributeDeviceWiseSelectedList(Attribute));
        // this.props.dispatch(addComputedAttribute(Attribute));
        this.props.getSensors(id);

        this.props.dispatch(notifySuccess(this.props.translate("sensorAdded")));
        this.props.dispatch(updateComputedAttributeDeviceWise(Attribute));
        this.setState(
          {
            isVisableMeasurementBtn: false,
            isVisableDiscreteBtn: false,
            isVisableOthersBtn: false,
            isVisableAggregateBtn: false,
            isVisableDecoderBtn: false,
            formOpen: false,
            // formOpen:false,
            formOpen1: false,
            formOpen2: false,
            formOpen3: false,
            modal: "",
          }
          // , ()=>{
          //   this.props.openDeviceSettings(id,"update")
          // }
        );
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch, this.errorCallBack);
      });
  }
  onEdit = (item, mood) => {
    let scales = [];
    let att = item.attributes;
    if (mood === "boardPower") {
      for (let key1 in att.scale) {
        let a = {
          x: "",
          y: "",
          e: false,
        };
        a.x = parseFloat(key1);
        a.y = att.scale[key1];
        scales.push(a);
      }
      item["scale"] = JSON.parse(JSON.stringify(scales));
      if (item.scale.length === 0) {
        item.scale.push({ x: 0, y: 0, e: false });
        scales.push({ x: 0, y: 0, e: false });
      }
      if (!att.sensorType) {
        att.sensorType = item.attribute;
      }
    }
    const ex = item && item.expression && item.expression.trim().split(" ");
    const exp = {};
    if (ex && ex.length && ex.length === 3) {
      exp.expStart = ex[0];
      exp.expOpt = ex[1];
      exp.expEnd = ex[2];
    }

    this.setState({
      form: { ...item, ...exp, ...att },
      modal: mood,
      operation: "edit",
      addButton: "update",
      defaultData: item,
      scale: scales,
      isVisableDiscreteBtn: false,
      isVisableMeasurementBtn: false,
      isVisableAggregateBtn: false,
      isVisableOthersBtn: false,
    });
  };

  onRemove = (item) => {
    this.setState({
      computedAttForm: item,
      onDeleteConfirmationAttributes: true,
    });
  };

  removeComputedAttributes = () => {
    instance({
      url: `/api/attributes/computed/${this.state.computedAttForm.id}`,
      method: "DELETE",
    })
      .then((response) => {
        this.props.dispatch(
          RemoveComputedAttributeDeviceWise(this.state.computedAttForm)
        );
        this.props.dispatch(
          notifySuccess(this.props.translate("attributeIsDelete"))
        );
        this.setState({
          onDeleteConfirmationAttributes: false,
        });
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  //   drawSensors = () => {
  //     let obj = {
  //       1: [],
  //       2: [],
  //       3: [],
  //       4: [],
  //     };
  //     // this.props.computedAttributesDeviceWise.map((item, index) => {/
  //       this.props.sensorData.map((item, index) => {
  //       obj[item.category] = obj[item.category] || [];
  //       obj[item.category].push(item);
  //       return null;
  //     });

  //     let wrapper = {
  //       1: [],
  //       2: [],
  //       3: [],
  //       4: [],
  //     };

  //     Object.keys(obj).map((key) => {
  //       if (parseInt(key) === 1) {
  //         // wrapper[key].push(
  //         //   <div
  //         //   style={{
  //         //     background:"#ffff",
  //         //   }}
  //         //   //  className="sensor_type_head"
  //         //    >
  //         //     <div
  //         //       style={{
  //         //         marginRight: "auto",
  //         //         alignItems: "center",
  //         //         display: "flex",
  //         //         // background:"#ffff",
  //         //         justifyContent:"space-between"
  //         //       }}
  //         //     >
  //         //    <div    style={{
  //         //         marginRight: "auto",
  //         //         alignItems: "center",
  //         //         display: "flex",
  //         //         // justifyContent:"space-between"
  //         //       }}>
  //         //       <OtherSensorIcon
  //         //        fill="currentColor"
  //         //        width={"28px"}
  //         //        height={"28px"}
  //         //        style={{ marginRight: 14 }}
  //         //       />
  //         //       <span>
  //         //          {this.props.translate("discrete")}
  //         //       </span>
  //         //       </div>

  //         //       <div
  //         //       style={{
  //         //         // marginRight: "auto",
  //         //         alignItems: "center",
  //         //         display: "flex",
  //         //         // justifyContent:"space-between"
  //         //       }}
  //         //       >
  //         //     {checkPrivileges("sensorCreate") && (
  //         //       <>
  //         //         {!this.state.formOpen ?
  //         //         <>
  //         //             <Button
  //         //                 style={{
  //         //                   background:this.props.themecolors.backgroundColor,
  //         //                   color:"rgba(57, 58, 59, 1)",
  //         //                   border:`1px solid rgba(57, 58, 59, 1)`,
  //         //                   borderRadius:"8px",
  //         //                   width:"62px",
  //         //                   height:"10px",
  //         //                   fontWeight:"bold",
  //         //                   textTransform: "capitalize"

  //         //                 }}
  //         //               disabled={!this.state.isEnableBtn}
  //         //               onClick={(e) => this.openDialog("discrete")}
  //         //                 >
  //         //                  {this.props.translate("sharedCreate")}
  //         //                 </Button>
  //         //         </>
  //         //             : this.state.formOpen ?
  //         //         <IconButton
  //         //         onClick={(e) => this.openDialog("close")}
  //         //         // onClick={e => this.props.setExpirationTime(e,'licenseExpDate')}
  //         //           style={{ color: 'inherit' }}
  //         //         >
  //         //         <CrossIcons />
  //         //         </IconButton>
  //         //      :""  }
  //         //       </>
  //         //     )}
  //         //  </div>
  //         //   </div>
  //         //   </div>
  //         // );
  //         wrapper[key].push(
  //              <Collapse
  //                style={{
  //                   marginTop:"10px",
  //                   borderBottom: "1px solid #e1e1e1",
  //                 }}
  //                 in={this.state.collapse[key]}
  //                 >
  //             {obj[key].map((i, k) => (
  //               <div key={k + "dis"}
  //               className="sensor-row"
  //               >
  //               <div  style={{display:"flex", justifyContent:"space-between" }}>
  //                   <div  style={{
  //                         marginRight: "auto",
  //                         alignItems: "center",
  //                         display: "flex",
  //                           justifyContent:"space-between"
  //                       }}>
  //                     <OtherSensorIcon
  //                       fill="currentColor"
  //                       width={"28px"}
  //                       height={"28px"}
  //                       style={{ marginRight: 14 }}
  //                     />
  //                     <span>
  //                         {i.description}
  //                     </span>
  //                     </div>
  //                     <div className="sensor-row-actions">
  //                   {checkPrivileges("sensorUpdate") && (
  //                     <>
  //                     <Button
  //                        style={{
  //                          background:this.props.themecolors.backgroundColor,
  //                          color:"rgba(57, 58, 59, 1)",
  //                          border:`1px solid rgba(57, 58, 59, 1)`,
  //                          borderRadius:"8px",
  //                          width:"62px",
  //                          height:"10px",
  //                          fontWeight:"bold",
  //                          textTransform: "capitalize"

  //                        }}
  //                        onClick={(e) => this.onEdit(i, "discrete")}
  //                        >
  //                             {this.props.translate("sharedEdit")}
  //                        </Button>
  //                     </>

  //                   )}

  //                   {/* {checkPrivileges("sensorDelete") && (
  //                     <Tooltip
  //                       classes={{
  //                         popper: "menu-popper",
  //                         tooltip: "menu-popper-tooltip",
  //                       }}
  //                       title={this.props.translate("delete")}
  //                     >
  //                       <DeleteIcon
  //                         fontSize="small"
  //                         onClick={(e) => this.onRemove(i)}
  //                       />
  //                     </Tooltip>
  //                   )} */}

  //                </div>

  //                 </div>

  //                 <div
  //                  style={{
  //                    display:"flex",
  //                   //  justifyContent:"space-between",
  //                    fontSize:"12px",
  //                    color:this.props.themecolors.textColor ,
  //                    margin:"20px 0"
  //                    }}>
  //                   <div   className="sensor-row-input" >
  //                      <span  style={{fontWeight:"bold" }}>Label</span> | {i.description || ""}
  //                  </div>

  //                 <div className="sensor-row-actions">
  //                   <span  style={{fontWeight:"bold"}}>Input</span> | {i.attributes.input || ""}
  //                 </div>

  //                 </div>
  //               </div>
  //             ))}
  //           </Collapse>
  //         );
  //         // wrapper[key].push(
  //         //   this.state.modal === "discrete" && (
  //         //     <div  style={{
  //         //       border:"1px solid #CFCFCF",
  //         //       // borderTop: this.state.expanded ? "3px solid rgb(32, 75, 112)" :"1px solid #CFCFCF",
  //         //       borderTop:  "3px solid rgb(32, 75, 112)",
  //         //       borderRadius:8,
  //         //       padding:"5px",
  //         //        background:this.props.themecolors.backgroundColor,

  //         //       marginTop:15,
  //         //       // borderTop:"3px solid blue"
  //         //       }}>
  //         //       <Ignition
  //         //         {...this.props}
  //         //         // suggestions={sug}
  //         //           // isVisableDiscreteBtn={this.state.isVisableDiscreteBtn}
  //         //          save={this.save}
  //         //          addButton={this.state.addButton}
  //         //          handleChange={this.handleChange}
  //         //          changeAttribute={this.changeAttribute}
  //         //         {...this.state}
  //         //       />
  //         //     </div>
  //         //   )

  //         // );
  //       } else if (parseInt(key) === 2) {
  //         wrapper[key].push(
  //           <div
  //           style={{
  //             background:"#ffff",
  //           }}
  //           //  className="sensor_type_head"
  //            >
  //            <div
  //               style={{
  //                 marginRight: "auto",
  //                 alignItems: "center",
  //                 display: "flex",
  //                 justifyContent:"space-between"
  //               }}
  //             >
  //                <div    style={{
  //                 marginRight: "auto",
  //                 alignItems: "center",
  //                 display: "flex",
  //                 // justifyContent:"space-between"
  //               }}>
  //               <MeasurementIcon
  //                 fill="currentColor"
  //                 width={"28px"}
  //                 height={"28px"}
  //                 style={{ marginRight: 14 }}
  //               />
  //               <span>
  //               {this.props.translate("boardPower")}
  //               </span>
  //            </div>

  //            <div
  //               style={{
  //                 // marginRight: "auto",
  //                 alignItems: "center",
  //                 display: "flex",
  //                 // justifyContent:"space-between"
  //               }}
  //               >
  //             {checkPrivileges("sensorCreate") && (
  //               <>
  //                 {!this.state.formOpen1?
  //                 <>
  //                     <Button
  //                         style={{
  //                           background:this.props.themecolors.backgroundColor,
  //                           color:"rgba(57, 58, 59, 1)",
  //                           border:`1px solid rgba(57, 58, 59, 1)`,
  //                           borderRadius:"8px",
  //                           width:"62px",
  //                           height:"10px",
  //                           fontWeight:"bold",
  //                           textTransform: "capitalize"

  //                         }}
  //                      onClick={(e) => this.openDialog("boardPower")}
  //                         >
  //                              {this.props.translate("sharedCreate")}
  //                         </Button>
  //                 </>
  //                 :this.state.formOpen1 ?
  //                 <IconButton
  //                 onClick={(e) => this.openDialog("close")}
  //                 // onClick={e => this.props.setExpirationTime(e,'licenseExpDate')}
  //                   style={{ color: 'inherit' }}
  //                 >
  //                 <CrossIcons />
  //                 </IconButton>
  //               :"" }
  //               </>
  //             )}
  //              </div>
  //              </div>

  //              {/* <div
  //                style={{
  //                 marginRight: "auto",
  //                 alignItems: "center",
  //                 display: "flex",
  //                 justifyContent:"flex-end"
  //               }}
  //             >
  //             {obj[key].length ? (
  //               <IconButton onClick={(e) => this.toggleCollapse(key)}>
  //                 <KeyboardArrowDown />
  //               </IconButton>
  //              ) : null}
  //               </div> */}

  //             {/* {this.state.modal === "boardPower" && (
  //                   <div style={{
  //                     border:"1px solid #CFCFCF",
  //                     // borderTop: this.state.expanded ? "3px solid rgb(32, 75, 112)" :"1px solid #CFCFCF",
  //                     borderTop:  "3px solid rgb(32, 75, 112)",
  //                     borderRadius:8,
  //                     padding:"5px",
  //                     marginTop:5,
  //                    background:this.props.themecolors.backgroundColor,
  //                     // borderTop:"3px solid blue"
  //                     }}>
  //                     <Measurement
  //                       {...this.props}
  //                       // suggestions={sug}
  //                       // isVisableDiscreteBtn={this.state.isVisableDiscreteBtn}
  //                       save={this.save}
  //                       addButton={this.state.addButton}

  //                       handleChange={this.handleChange}
  //                       changeAttribute={this.changeAttribute}
  //                       checkVisablity={this.checkVisablityOfSensor}
  //                       {...this.state}
  //                     />
  //                   </div>
  //                 )} */}
  //           </div>
  //         );
  //         wrapper[key].push(
  //           <Collapse
  //               style={{
  //                 marginTop:"10px",
  //                 borderBottom: "1px solid #e1e1e1",
  //               }}
  //              in={this.state.collapse[key]}
  //              >
  //             {obj[key].map((i, k) => (
  //               <div key={i.id + "mes"} className="sensor-row">
  //                 {/* <div className="sensor-row-title">
  //                   {i.attributes && i.attributes.sensorType
  //                     ? this.props.translate(
  //                         "sensorsTranslation." + i.attributes.sensorType
  //                       )
  //                     : this.props.translate("sensorsTranslation." + i.attribute)}
  //                 </div> */}
  //                  <div  style={{display:"flex", justifyContent:"space-between",
  //                 //  marginTop:"5px"
  //                  }}>
  //                 <div className="sensor-row-input"  style={{fontSize:"12px", color:this.props.themecolors.textColor}}>
  //                 <span  style={{fontWeight:"bold"}}>Input</span> | {i.attributes.input || ""}

  //                 </div>
  //                 {/* <div className="sensor-row-description">
  //                   {parse(i.description)}
  //                 </div> */}
  //                 <div className="sensor-row-actions">
  //                   {checkPrivileges("sensorUpdate") && (
  //                     <>
  //                     {/* <Tooltip
  //                       classes={{
  //                         popper: "menu-popper",
  //                         tooltip: "menu-popper-tooltip",
  //                       }}
  //                       title={this.props.translate("edit")}
  //                     >
  //                       <EditIcon
  //                         fontSize="small"
  //                         onClick={(e) => this.onEdit(i, "boardPower")}
  //                       />
  //                     </Tooltip> */}
  //                        <Button
  //                        style={{
  //                          background:this.props.themecolors.backgroundColor,
  //                          color:"rgba(57, 58, 59, 1)",
  //                          border:`1px solid rgba(57, 58, 59, 1)`,
  //                          borderRadius:"8px",
  //                          width:"62px",
  //                          height:"10px",
  //                          fontWeight:"bold",
  //                          textTransform: "capitalize"

  //                        }}
  //                        // onClick={(e) => this.onEdit(i, "immobilizer")}
  //                        onClick={(e) => this.onEdit(i, "boardPower")}
  //                        >
  //                             {this.props.translate("sharedEdit")}
  //                        </Button>
  //                        </>
  //                   )}
  //                   {/* {checkPrivileges("sensorDelete") && (
  //                     <Tooltip
  //                       classes={{
  //                         popper: "menu-popper",
  //                         tooltip: "menu-popper-tooltip",
  //                       }}
  //                       title={this.props.translate("delete")}
  //                     >
  //                       <DeleteIcon
  //                         fontSize="small"
  //                         onClick={(e) => this.onRemove(i)}
  //                       />
  //                     </Tooltip>
  //                   )} */}
  //                 </div>
  //                 </div>
  //               </div>
  //             ))}
  //           </Collapse>
  //         );

  //         // wrapper[key].push(
  //         // this.state.modal === "boardPower" && (
  //         //   <div style={{
  //         //     border:"1px solid #CFCFCF",
  //         //     // borderTop: this.state.expanded ? "3px solid rgb(32, 75, 112)" :"1px solid #CFCFCF",
  //         //     borderTop:  "3px solid rgb(32, 75, 112)",
  //         //     borderRadius:8,
  //         //     padding:"5px",
  //         //     marginTop:5,
  //         //    background:this.props.themecolors.backgroundColor,
  //         //     // borderTop:"3px solid blue"
  //         //     }}>
  //         //     <Measurement
  //         //       {...this.props}
  //         //       // suggestions={sug}
  //         //       // isVisableDiscreteBtn={this.state.isVisableDiscreteBtn}
  //         //       save={this.save}
  //         //       addButton={this.state.addButton}

  //         //       handleChange={this.handleChange}
  //         //       changeAttribute={this.changeAttribute}
  //         //       checkVisablity={this.checkVisablityOfSensor}
  //         //       {...this.state}
  //         //     />
  //         //   </div>
  //         // )
  //         // );

  //       } else if (parseInt(key) === 3) {
  //         wrapper[key].push(
  //           <div
  //           style={{
  //             background:"#ffff",
  //           }}
  //           //  className="sensor_type_head"
  //            >
  //            <div
  //               style={{
  //                 marginRight: "auto",
  //                 alignItems: "center",
  //                 display: "flex",
  //                 justifyContent:"space-between"
  //               }}
  //             >
  //                <div   style={{
  //                 marginRight: "auto",
  //                 alignItems: "center",
  //                 display: "flex",
  //                 // justifyContent:"space-between"
  //               }}>
  //               <OtherSensorIcon
  //                 fill="currentColor"
  //                 width={"28px"}
  //                 height={"28px"}
  //                 style={{ marginRight: 14 }}
  //               />
  //               <span>
  //               {this.props.translate("otherSensors")}
  //               </span>
  //                    </div>

  //                    <div
  //               style={{
  //                 // marginRight: "auto",
  //                 alignItems: "center",
  //                 display: "flex",
  //                 // justifyContent:"space-between"
  //               }}
  //               >
  //                   {/* {checkPrivileges("sensorCreate") && (
  //                     <>
  //                         {!this.state.formOpen2?
  //                         <>

  //                          <Button
  //                         style={{
  //                           background:this.props.themecolors.backgroundColor,
  //                           color:"rgba(57, 58, 59, 1)",
  //                           border:`1px solid rgba(57, 58, 59, 1)`,
  //                           borderRadius:"8px",
  //                           width:"62px",
  //                           height:"10px",
  //                           fontWeight:"bold",
  //                           textTransform: "capitalize"

  //                         }}
  //                      onClick={(e) => this.openDialog("others")}
  //                         >
  //                              {this.props.translate("sharedCreate")}
  //                         </Button>

  //                       </>
  //                               :
  //                       <IconButton
  //                       onClick={(e) => this.openDialog("close")}
  //                       // onClick={e => this.props.setExpirationTime(e,'licenseExpDate')}
  //                         style={{ color: 'inherit' }}
  //                       >
  //                       <CrossIcons />
  //                       </IconButton>

  //                     }
  //                     </>
  //                   )} */}
  //              </div>
  //              </div>

  //              {/* <div
  //                style={{
  //                 marginRight: "auto",
  //                 alignItems: "center",
  //                 display: "flex",
  //                 justifyContent:"flex-end"
  //               }}
  //             >
  //                {obj[key].length ? (
  //               <IconButton onClick={(e) => this.toggleCollapse(key)}>
  //                 <KeyboardArrowDown />
  //               </IconButton>
  //             ) : null}

  //               </div> */}

  //               {/* {this.state.modal === "others" && (
  //                    <div style={{
  //                     border:"1px solid #CFCFCF",
  //                     // borderTop: this.state.expanded ? "3px solid rgb(32, 75, 112)" :"1px solid #CFCFCF",
  //                     borderTop:  "3px solid rgb(32, 75, 112)",
  //                     borderRadius:8,
  //                     padding:"5px",
  //                     marginTop:5,
  //                     background:this.props.themecolors.backgroundColor,

  //                     // borderTop:"3px solid blue"
  //                     }}>
  //               <OtherSensors
  //                 {...this.props}
  //                 // suggestions={sug}
  //                 // isVisableDiscreteBtn={this.state.isVisableDiscreteBtn}
  //                 save={this.save}
  //                 addButton={this.state.addButton}
  //                 handleChange={this.handleChange}
  //                 changeAttribute={this.changeAttribute}
  //                 {...this.state}
  //               />
  //               </div>
  //               )} */}

  //           </div>
  //         );
  //         wrapper[key].push(
  //           <Collapse
  //           style={{
  //             marginTop:"10px",
  //             borderBottom: "1px solid #e1e1e1",
  //           }}
  //           in={this.state.collapse[key]}>
  //             {obj[key].map((i, k) => (
  //               <div key={k + "other"} className="sensor-row">

  //       <div  style={{display:"flex", justifyContent:"space-between",
  //                 //  marginTop:"5px"
  //                  }}>
  //                 {/* <div className="sensor-row-title">
  //                   {this.props.translate("sensorsTranslation." + i.attribute)}
  //                 </div> */}

  //                 <div className="sensor-row-input"  style={{fontSize:"12px", color:this.props.themecolors.textColor}}>
  //                 <span  style={{fontWeight:"bold"}}>Input</span> | {i.attributes.input || ""}
  //                 </div>
  //                 {/* <div className="sensor-row-description">
  //                   {parse(i.description)}
  //                 </div> */}
  //                 <div className="sensor-row-actions">
  //                   {checkPrivileges("sensorUpdate") && (
  //                     <>
  //                     {/* <Tooltip
  //                       classes={{
  //                         popper: "menu-popper",
  //                         tooltip: "menu-popper-tooltip",
  //                       }}
  //                       title={this.props.translate("edit")}
  //                     >
  //                       <EditIcon
  //                         fontSize="small"
  //                         onClick={(e) => this.onEdit(i, "others")}
  //                       />
  //                     </Tooltip> */}
  //                       <Button
  //                       style={{
  //                         background:this.props.themecolors.backgroundColor,
  //                         color:"rgba(57, 58, 59, 1)",
  //                         border:`1px solid rgba(57, 58, 59, 1)`,
  //                         borderRadius:"8px",
  //                         width:"62px",
  //                         height:"10px",
  //                         fontWeight:"bold",
  //                         textTransform: "capitalize"

  //                       }}
  //                       // onClick={(e) => this.onEdit(i, "immobilizer")}
  //                       onClick={(e) => this.onEdit(i, "others")}
  //                       >
  //                            {this.props.translate("sharedEdit")}
  //                       </Button>
  //                       </>
  //                   )}
  //                   {/* {checkPrivileges("sensorDelete") && (
  //                     <Tooltip
  //                       classes={{
  //                         popper: "menu-popper",
  //                         tooltip: "menu-popper-tooltip",
  //                       }}
  //                       title={this.props.translate("delete")}
  //                     >
  //                       <DeleteIcon
  //                         fontSize="small"
  //                         onClick={(e) => this.onRemove(i)}
  //                       />
  //                     </Tooltip>
  //                   )} */}
  //                 </div>
  //                 </div>
  //               </div>
  //             ))}
  //           </Collapse>
  //         );

  //     //     wrapper[key].push(
  //     //       this.state.modal === "others" && (
  //     //                <div style={{
  //     //                 border:"1px solid #CFCFCF",
  //     //                 // borderTop: this.state.expanded ? "3px solid rgb(32, 75, 112)" :"1px solid #CFCFCF",
  //     //                 borderTop:  "3px solid rgb(32, 75, 112)",
  //     //                 borderRadius:8,
  //     //                 padding:"5px",
  //     //                 marginTop:5,
  //     //                 background:this.props.themecolors.backgroundColor,

  //     //                 // borderTop:"3px solid blue"
  //     //                 }}>
  //     //           <OtherSensors
  //     //             {...this.props}
  //     //             // suggestions={sug}
  //     //             // isVisableDiscreteBtn={this.state.isVisableDiscreteBtn}
  //     //             save={this.save}
  //     //             addButton={this.state.addButton}
  //     //             handleChange={this.handleChange}
  //     //             changeAttribute={this.changeAttribute}
  //     //             {...this.state}
  //     //           />
  //     //           </div>
  //     //           )
  //     // );
  //       } else if (parseInt(key) === 4) {
  //         wrapper[key].push(
  //           <div
  //           style={{
  //             background:"#ffff",
  //           }}
  //           >
  //             <div
  //               style={{
  //                 marginRight: "auto",
  //                 alignItems: "center",
  //                 display: "flex",
  //                 justifyContent:"space-between"
  //               }}
  //             >
  //            <div
  //             style={{
  //                 marginRight: "auto",
  //                 alignItems: "center",
  //                 display: "flex",
  //                 // justifyContent:"space-between"
  //               }}>
  //               <AggregateIcon
  //                 fill="currentColor"
  //                 width={"28px"}
  //                 height={"28px"}
  //                 style={{ marginRight: 14 }}
  //               />
  //               <span>
  //               {this.props.translate("aggregateSensors")}
  //               </span>
  //               </div>

  //               <div
  //               style={{
  //                 // marginRight: "auto",
  //                 alignItems: "center",
  //                 display: "flex",
  //                 // justifyContent:"space-between"
  //               }}
  //               >
  //               {/* {checkPrivileges("sensorCreate") && (
  //               <>
  //               {!this.state.formOpen3?
  //               <>
  //                      <Button
  //                         style={{
  //                           background:this.props.themecolors.backgroundColor,
  //                           color:"rgba(57, 58, 59, 1)",
  //                           border:`1px solid rgba(57, 58, 59, 1)`,
  //                           borderRadius:"8px",
  //                           width:"62px",
  //                           height:"10px",
  //                           fontWeight:"bold",
  //                           textTransform: "capitalize"

  //                         }}
  //                        onClick={(e) => this.openDialog("immobilizer")}
  //                         >
  //                              {this.props.translate("sharedCreate")}
  //                         </Button>
  //                 </>
  //                   :
  //                 <IconButton
  //                 onClick={(e) => this.openDialog("close")}
  //                 // onClick={e => this.props.setExpirationTime(e,'licenseExpDate')}
  //                   style={{ color: 'inherit' }}
  //                 >
  //                 <CrossIcons />
  //                 </IconButton>
  //                }
  //               </>
  //             )} */}
  //             </div>
  //             </div>

  //             {/* <div
  //                style={{
  //                 marginRight: "auto",
  //                 alignItems: "center",
  //                 display: "flex",
  //                 justifyContent:"flex-end"
  //               }}
  //             >
  //               {obj[key].length ? (
  //                   <IconButton onClick={(e) => this.toggleCollapse(key)}>
  //                     <KeyboardArrowDown />
  //                   </IconButton>
  //                 ) : null}
  //            </div> */}

  //             {/* {this.state.modal === "immobilizer" && (
  //           <div style={{
  //             border:"1px solid #CFCFCF",
  //             // borderTop: this.state.expanded ? "3px solid rgb(32, 75, 112)" :"1px solid #CFCFCF",
  //             borderTop:  "3px solid rgb(32, 75, 112)",
  //             borderRadius:8,
  //              padding:"5px",
  //               marginTop:5,
  //               background:this.props.themecolors.backgroundColor,

  //             // borderTop:"3px solid blue"
  //             }}>
  //             <AggregateSensors
  //               {...this.props}
  //               isVisableDiscreteBtn={this.state.isVisableDiscreteBtn}
  //               save={this.save}
  //               addButton={this.state.addButton}
  //               // suggestions={sug}
  //               handleChange={this.handleChange}
  //               changeAttribute={this.changeAttribute}
  //               {...this.state}
  //             />
  //           </div>
  //           )} */}

  //           </div>
  //         );
  //         wrapper[key].push(
  //           <Collapse
  //             style={{
  //               marginTop:"10px",
  //               borderBottom: "1px solid #e1e1e1",
  //             }}
  //           in={this.state.collapse[key]}>
  //             {obj[key].map((i, k) => (
  //               <div key={k + "immobilizer"} className="sensor-row">
  //                 {/* <div className="sensor-row-title">
  //                   {this.props.translate("sensorsTranslation." + i.attribute)}
  //                 </div> */}
  //                  <div  style={{display:"flex", justifyContent:"space-between",
  //                 //  marginTop:"5px"
  //                  }}>
  //                 <div className="sensor-row-input"  style={{fontSize:"12px", color:this.props.themecolors.textColor}}>
  //                 <span  style={{fontWeight:"bold"}}>Input</span> | {i.attributes.input || ""}
  //                 </div>
  //                 {/* <div className="sensor-row-description">
  //                   {parse(i.description)}
  //                 </div> */}
  //                 <div className="sensor-row-actions">
  //                   {checkPrivileges("sensorUpdate") && (
  //                     <>
  //                     {/* <Tooltip
  //                       classes={{
  //                         popper: "menu-popper",
  //                         tooltip: "menu-popper-tooltip",
  //                       }}
  //                       title={this.props.translate("edit")}
  //                     >
  //                       <EditIcon
  //                         fontSize="small"
  //                         onClick={(e) => this.onEdit(i, "immobilizer")}
  //                       />
  //                     </Tooltip> */}
  //                         <Button
  //                         style={{
  //                           background:this.props.themecolors.backgroundColor,
  //                           color:"rgba(57, 58, 59, 1)",
  //                           border:`1px solid rgba(57, 58, 59, 1)`,
  //                           borderRadius:"8px",
  //                           width:"62px",
  //                           height:"10px",
  //                           fontWeight:"bold",
  //                           textTransform: "capitalize"

  //                         }}
  //                         onClick={(e) => this.onEdit(i, "immobilizer")}
  //                       //  onClick={(e) => this.openDialog("immobilizer")}
  //                         >
  //                              {this.props.translate("sharedEdit")}
  //                         </Button>
  //                         </>
  //                   )}
  //                   {/* {checkPrivileges("sensorDelete") && (
  //                     <Tooltip
  //                       classes={{
  //                         popper: "menu-popper",
  //                         tooltip: "menu-popper-tooltip",
  //                       }}
  //                       title={this.props.translate("delete")}
  //                     >
  //                       <DeleteIcon
  //                         fontSize="small"
  //                         onClick={(e) => this.onRemove(i)}
  //                       />
  //                     </Tooltip>
  //                   )} */}
  //                 </div>
  //                 </div>
  //               </div>
  //             ))}
  //           </Collapse>
  //         );

  //         // wrapper[key].push(
  //         //   this.state.modal === "immobilizer" && (
  //         //     <div style={{
  //         //       border:"1px solid #CFCFCF",
  //         //       // borderTop: this.state.expanded ? "3px solid rgb(32, 75, 112)" :"1px solid #CFCFCF",
  //         //       borderTop:  "3px solid rgb(32, 75, 112)",
  //         //       borderRadius:8,
  //         //        padding:"5px",
  //         //         marginTop:5,
  //         //         background:this.props.themecolors.backgroundColor,

  //         //       // borderTop:"3px solid blue"
  //         //       }}>
  //         //       <AggregateSensors
  //         //         {...this.props}
  //         //         isVisableDiscreteBtn={this.state.isVisableDiscreteBtn}
  //         //         save={this.save}
  //         //         addButton={this.state.addButton}
  //         //         // suggestions={sug}
  //         //         handleChange={this.handleChange}
  //         //         changeAttribute={this.changeAttribute}
  //         //         {...this.state}
  //         //       />
  //         //     </div>
  //         //     )
  //         // );
  //       }
  //       return null;
  //     });

  //     return Object.values(wrapper).map((row, key) => (
  //       <div className="sensor-collapse-wrapper">{row}</div>
  //     ));
  //   };

  drawSensors = () => {
    let ignitionSensor = {};
    let immobilizerSensor = {};
    let boardPowerSensor = {};

    this.props.sensorData.map((item, index) => {
      if (item.attribute === "ignition") ignitionSensor = item;
      else if (item.attribute === "immobilizer") immobilizerSensor = item;
      else if (item.attribute === "boardPower") boardPowerSensor = item;
      else {
      }
    });

    let wrapper = [];
    wrapper.push(
      <div
        style={{
          padding: "10px 0px",
          borderBottom: "1px solid rgba(207, 207, 207, 1)",
        }}
      >
        <div className="sensor-row">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                marginRight: "auto",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <IgnitionSensorIcon
                fill="currentColor"
                width={"32px"}
                height={"28px"}
                style={{ marginRight: "19px" }}
              />

              <span>{this.props.translate("ignition")}</span>
            </div>
            <div className="sensor-row-actions">
              {this.props?.deviceId && checkPrivileges("sensorUpdate") && (
                <>
                  <Button
                    style={{ padding: "6px 20px", margin: "0px 5px" }}
                    onClick={(e) => this.onEdit(ignitionSensor, "ignition")}
                  >
                    {this.props.translate("sharedEdit")}
                  </Button>
                </>
              )}
            </div>
          </div>
          {ignitionSensor && ignitionSensor.id ? (
            <div
              style={{
                display: "flex",
                fontSize: "12px",
                color: this.props.themecolors.textColor,
                margin: "13px 5px",
              }}
            >
              <div className="sensor-row-input">
                <div
                  style={{
                    paddingLeft: "5px",
                    width: "140px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Label</span> |{" "}
                  <span> {ignitionSensor?.description || ""} </span>
                </div>
              </div>
              <div>
                {" "}
                <span style={{ fontWeight: "bold" }}>Input</span> |{" "}
                {ignitionSensor?.attributes?.input || ""}{" "}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
    wrapper.push(
      <div
        style={{
          padding: "10px 0px",
          borderBottom: "1px solid rgba(207, 207, 207, 1)",
        }}
      >
        <div className="sensor-row">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                marginRight: "auto",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <PowerCutIcon
                fill="currentColor"
                width={"32px"}
                height={"28px"}
                style={{ marginRight: "19px" }}
              />

              <span>{this.props.translate("immobilizer")}</span>
            </div>
            <div className="sensor-row-actions">
              {this.props?.deviceId && checkPrivileges("sensorUpdate") && (
                <>
                  <Button
                    style={{ padding: "6px 20px", margin: "0px 5px" }}
                    onClick={(e) =>
                      this.onEdit(immobilizerSensor, "immobilizer")
                    }
                  >
                    {this.props.translate("sharedEdit")}
                  </Button>
                </>
              )}
            </div>
          </div>
          {immobilizerSensor && immobilizerSensor.id ? (
            <div
              style={{
                display: "flex",
                fontSize: "12px",
                color: this.props.themecolors.textColor,
                margin: "13px 5px",
              }}
            >
              <div className="sensor-row-input">
                <div
                  style={{
                    paddingLeft: "5px",
                    width: "140px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Label</span> |{" "}
                  <span> {immobilizerSensor?.description || ""} </span>
                </div>
              </div>
              <div>
                {" "}
                <span style={{ fontWeight: "bold" }}>Input</span> |{" "}
                {immobilizerSensor?.attributes?.input || ""}{" "}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );

    wrapper.push(
      <div
        style={{
          padding: "10px 0px",
          borderBottom: "1px solid rgba(207, 207, 207, 1)",
        }}
      >
        <div className="sensor-row">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                marginRight: "auto",
                alignItems: "center",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <BoardPowerIcon
                fill="currentColor"
                width={"32px"}
                height={"28px"}
                style={{ marginRight: "19px" }}
              />
              <span> {this.props.translate("boardPower")} </span>
            </div>
            <div className="sensor-row-actions">
              {this.props?.deviceId && checkPrivileges("sensorUpdate") && (
                <>
                  {boardPowerSensor && boardPowerSensor.id ? (
                    <Button
                      style={{ padding: "6px 20px", margin: "0px 5px" }}
                      onClick={(e) =>
                        this.onEdit(boardPowerSensor, "boardPower")
                      }
                    >
                      {this.props.translate("sharedEdit")}
                    </Button>
                  ) : (
                    <Button
                      style={{ padding: "6px 20px", margin: "0px 5px" }}
                      onClick={(e) => this.openDialog("boardPower")}
                    >
                      {this.props.translate("sharedEdit")}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
          {boardPowerSensor && boardPowerSensor.id && (
            <div
              style={{
                display: "flex",
                fontSize: "12px",
                color: this.props.themecolors.textColor,
                margin: "13px 5px",
              }}
            >
              <div className="sensor-row-input">
                <div
                  style={{
                    paddingLeft: "5px",
                    width: "140px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>Label</span> |{" "}
                  <span> {boardPowerSensor.description || ""}</span>
                </div>
              </div>
              <div>
                {" "}
                <span style={{ fontWeight: "bold" }}>Input</span> |{" "}
                {boardPowerSensor.attributes.input || ""}{" "}
              </div>
            </div>
          )}
        </div>
      </div>
    );

    return wrapper.map((row, key) => (
      <div className="sensor-collapse-wrapper">{row}</div>
    ));
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmationAttributes: false,
    });
  };
  render() {
    let { suggestions, computedAttributesDeviceWise } = this.props;
    let sug = [];
    if (computedAttributesDeviceWise.length) {
      suggestions.map((item) => {
        let prsntvalue = false;
        computedAttributesDeviceWise.map((item1) => {
          if (item === item1.attributes.input) {
            prsntvalue = true;
          }
          return null;
        });
        if (!prsntvalue) {
          sug.push(item);
        }
        return null;
      });
    } else {
      sug = suggestions;
    }
    return (
      <div
        style={{
          width: "100%",
          //  padding: 11,
          background: "#ffff",
          marginTop: 12,
        }}
      >
        <h4
          className="all-attributes-heading"
          style={{ margin: "5px 10px", fontSize: "16px" }}
        >
          {this.props.translate("sensors")}
        </h4>
        {this.drawSensors()}
        {this.state.onDeleteConfirmationAttributes && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={this.removeComputedAttributes}
            title={this.props.translate("areYouWantToDelete")}
            children={this.props.translate(
              this.state.computedAttForm.description
            )}
          />
        )}

        {this.state.modal === "ignition" && (
          <>
            <CustomDialog
              draggable
              onClose={this.onClose}
              title={this.props.translate("ingitionSensors")}
              cancelText={this.props.translate("sharedCancel")}
              headerActions={
                <Button
                  disabled={!this.state.isVisableDiscreteBtn}
                  onClick={this.save}
                >
                  {this.props.translate(this.state.addButton)}
                </Button>
              }
              isVisableBtn={this.props.isVisablBtn}
              isVisablBtn={this.props.isVisablBtn}
              bodyPadding={20}
              maxWidth={"xs"}
            >
              <Ignition
                {...this.props}
                suggestions={sug}
                handleChange={this.handleChange}
                changeAttribute={this.changeAttribute}
                {...this.state}
              />
            </CustomDialog>
          </>
        )}
        {this.state.modal === "boardPower" && (
          <>
            <CustomDialog
              onClose={this.onClose}
              title={this.props.translate("boardPowerSensors")}
              cancelText={this.props.translate("sharedCancel")}
              headerActions={
                <Button
                  disabled={!this.state.isVisableMeasurementBtn}
                  onClick={this.save}
                >
                  {this.props.translate(this.state.addButton)}
                </Button>
              }
              isVisableBtn={this.props.isVisablBtn}
              isVisablBtn={this.props.isVisablBtn}
              bodyPadding={20}
              draggable={false}
              fullWidth
              maxWidth={"md"}
            >
              <Measurement
                {...this.props}
                suggestions={sug}
                handleChange={this.handleChange}
                changeAttribute={this.changeAttribute}
                checkVisablity={this.checkVisablityOfSensor}
                {...this.state}
              />
            </CustomDialog>
          </>
        )}
        {this.state.modal === "others" && (
          <>
            <CustomDialog
              onClose={this.onClose}
              title={this.props.translate("otherSensors")}
              cancelText={this.props.translate("sharedCancel")}
              headerActions={
                <Button
                  // disabled={!this.state.isVisableOthersBtn}
                  onClick={this.save}
                >
                  {this.props.translate(this.state.addButton)}
                </Button>
              }
              isVisableBtn={this.props.isVisablBtn}
              isVisablBtn={this.props.isVisablBtn}
              bodyPadding={20}
              draggable={false}
              fullWidth
              maxWidth={"xs"}
            >
              <OtherSensors
                {...this.props}
                suggestions={sug}
                handleChange={this.handleChange}
                changeAttribute={this.changeAttribute}
                {...this.state}
              />
            </CustomDialog>
          </>
        )}
        {this.state.modal === "immobilizer" && (
          <>
            <CustomDialog
              onClose={this.onClose}
              title={this.props.translate("immobilizerSensors")}
              cancelText={this.props.translate("sharedCancel")}
              headerActions={
                <Button
                  disabled={!this.state.isVisableAggregateBtn}
                  onClick={this.save}
                >
                  {this.props.translate(this.state.addButton)}
                </Button>
              }
              isVisableBtn={this.props.isVisablBtn}
              isVisablBtn={this.props.isVisablBtn}
              bodyPadding={20}
              draggable={false}
              fullWidth
              maxWidth={"xs"}
            >
              <AggregateSensors
                {...this.props}
                suggestions={sug}
                handleChange={this.handleChange}
                changeAttribute={this.changeAttribute}
                {...this.state}
              />
            </CustomDialog>
          </>
        )}
      </div>
    );
  }
}

const mapState = (state) => ({
  computedAttributesDeviceWise: state.computedAttributesDeviceWise,
});
const mapStateToProps = connect(mapState);
export default mapStateToProps(Sensors);
