import React, { Component } from "react";
import { connect } from "react-redux";
import Checkbox from "../common/Checkbox";
import Style from "style-it";
import SearchField from "../common/SearchField";
import Scrollbar from "react-scrollbars-custom";
import Dialog from "../common/Dialog";
import withTranslationWrapper from "../../HOC/withTranslation";
class DriverSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: {},
      displayList: [],
      search: "",
      isChecked: false,
      driverModal: false,
      modalData: {},
    };
  }
  UNSAFE_componentWillMount() {
    let list = {};
    if (this.props.value && this.props.value.length) {
      this.props.value.map((d) => (list[d] = true));
      this.setState({ list });
    }

    this.prepareDrivers(this.props);
  }

  componentWillUnmount() {
    this.setState({
      list: {},
      search: "",
      displayList: [],
      isChecked: false,
    });
  }

  UNSAFE_componentWillReceiveProps(n) {
    let list = {};
    if (n.value && n.value.length) {
      n.value.map((d) => (list[d] = true));
    }
    this.setState({ list }, () => {
      this.prepareDrivers(n);
    });
  }

  prepareDrivers = (n) => {
    if (n.applyFilter && n.driverList && n.driverList.length) {
      let ids = [];
      let displayList = n.driverList.filter((dr) => {
        if (n.displayList && n.displayList.includes(dr.uniqueId)) {
          ids.push(dr.uniqueId);
          return true;
        } else {
          return false;
        }
      });

      if (n.displayList && displayList.length !== this.props.displayList) {
        let remaining = n.displayList.filter((id) => !ids.includes(id));
        let li = remaining.map((r) => ({ name: "", uniqueId: r, id: r }));
        this.setState({ displayList: [...displayList, ...li] });
      }
    } else {
      let displayList = n.driverList;
      this.setState({ displayList });
    }
  };
  sendToParent() {
    if (this.props.onChange) {
      this.props.onChange(Object.keys(this.state.list));
    }
  }
  onChange = (e, v) => {
    let list = { ...this.state.list };
    if (e.target.checked) {
      list[v.id] = true;
    } else {
      delete list[v.id];
    }
    this.setState(
      {
        list,
        isChecked: this.props.driverList.length === Object.keys(list).length,
      },
      () => {
        this.sendToParent();
      }
    );
  };
  searchItem = (text) => {
    this.setState({ search: text.target.value });
  };

  toggleItems = (event) => {
    if (event.target.checked) {
      let list = {};
      this.props.driverList.map((d) => (list[d.id] = true));
      this.setState({ list, isChecked: true }, () => {
        this.sendToParent();
      });
    } else {
      this.setState({ list: {}, isChecked: false }, () => {
        this.sendToParent();
      });
    }
  };

  onDriverClick = (item) => {
    this.setState(
      {
        modalData: {},
      },
      () => {
        const unitId = this.props.positions.find(
          (p) => p.attributes.driverUniqueId === item.uniqueId
        );

        if (unitId && unitId.deviceId) {
          let unit = this.props.devices3.find(
            (device) => unitId.deviceId === device.id
          );
          this.setState({
            modalData: {
              ...item,
              unit,
            },
            driverModal: true,
          });
        } else {
          this.setState({
            modalData: {
              ...item,
              unit: null,
            },
            driverModal: true,
          });
        }
      }
    );
  };

  handleCloseModal = () => {
    this.setState({
      modalData: {},
      driverModal: false,
    });
  };

  render() {
    let list = this.state.displayList.filter(
      (item) =>
        item.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1
    );
    return (
      <Style>
        {`
        .ScrollbarsCustom-Wrapper {
          margin: 0 -15px;
        }`}
        <div>
          {this.state.driverModal ? (
            <Dialog
              title={this.state.modalData.uniqueId}
              open={this.state.driverModal}
              maxWidth="xs"
              onClose={this.handleCloseModal}
              cancelText={this.props.translate("close")}
              noFullScreen
            >
              <div className="feature-section">
                <div className="feature-content-section">
                  <div className="item-row" style={{ padding: "0 5px" }}>
                    <span className="row-label">
                      {this.props.translate("driverName")}
                    </span>
                    <span className="row-value">
                      {this.state.modalData.name || "Unknown"}
                    </span>
                  </div>
                  <div className="item-row" style={{ padding: "0 5px" }}>
                    <span className="row-label">
                      {this.props.translate(
                        "sensorsTranslation.driverUniqueId"
                      )}
                    </span>
                    <span className="row-value">
                      {this.state.modalData.uniqueId || "Unknown"}
                    </span>
                  </div>
                  <div className="item-row" style={{ padding: "0 5px" }}>
                    <span className="row-label">
                      {this.props.translate("driverPhone")}
                    </span>
                    <span className="row-value">
                      {(this.state.modalData.attributes &&
                        this.state.modalData.attributes.phone) ||
                        "Unknown"}
                    </span>
                  </div>
                  <div className="item-row" style={{ padding: "0 5px" }}>
                    <span className="row-label">
                      {this.props.translate("unitId")}
                    </span>
                    <span className="row-value">
                      {(this.state.modalData.unit &&
                        this.state.modalData.unit.uniqueId) ||
                        "Unknown"}
                    </span>
                  </div>
                  <div className="item-row" style={{ padding: "0 5px" }}>
                    <span className="row-label">
                      {this.props.translate("reportDeviceName")}
                    </span>
                    <span className="row-value">
                      {(this.state.modalData.unit &&
                        this.state.modalData.unit.name) ||
                        "Unknown"}
                    </span>
                  </div>
                </div>
              </div>
            </Dialog>
          ) : null}
          <div
            className="section-head clearfix section-head-filter"
            style={{ padding: 0, marginLeft: -6, marginRight: -2 }}
          >
            <ul className="filter-row pull-left">
              <li>
                <SearchField
                  label={this.props.translate("searchDriver")}
                  type="search"
                  placeholder="exp: ID, Name"
                  value={this.state.search}
                  onChange={this.searchItem}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </li>
              {!this.props.readOnly ? (
                <li style={{ paddingRight: 0, paddingTop: 3, paddingLeft: 6 }}>
                  <Checkbox
                    checked={this.state.isChecked}
                    onChange={this.toggleItems}
                    label={this.props.translate("selectAll")}
                  />
                </li>
              ) : null}
            </ul>
          </div>
          {!this.props.readOnly ? (
            <div className="selector-count">
              {" "}
              {Object.keys(this.state.list).length}{" "}
              {this.props.translate("DriverSelected")}
            </div>
          ) : null}
          <Scrollbar
            disableTracksWidthCompensation
            style={{
              height:
                (this.props.minHeight ? parseInt(this.props.minHeight) : 32) *
                (this.props.rows || 10),
            }}
            scrollerProps={{
              renderer: (props) => {
                const { elementRef, ...restProps } = props;
                return (
                  <div
                    {...restProps}
                    ref={elementRef}
                    id="scrollableDivDriver"
                  />
                );
              },
            }}
          >
            <ul className="list-view">
              {list.map((row) => (
                <li
                  key={row.id}
                  className={
                    "list-row " + (this.state.list[row.id] ? "active" : "")
                  }
                  onClick={(e) =>
                    !this.props.disableModal ? this.onDriverClick(row) : null
                  }
                >
                  <label className="unit-name">
                    {!this.props.readOnly ? (
                      <span style={{ marginRight: 10 }}>
                        <Checkbox
                          checked={this.state.list[row.id]}
                          value={row}
                          onChange={this.onChange}
                        />
                      </span>
                    ) : null}
                    <span className="unit-name-text">
                      {row.name || row.uniqueId}
                    </span>
                  </label>
                </li>
              ))}
            </ul>
          </Scrollbar>
        </div>
      </Style>
    );
  }
}

const mapState = (state) => ({
  driverList: state.drivers,
  positions: state.positions,
  devices3: state.devices3,
  allComputedAttributes: state.allComputedAttributes,
});
const mapStateToProps = connect(mapState);
export default mapStateToProps(withTranslationWrapper(DriverSelector));
