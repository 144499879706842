import React from "react";
import Icon from "@mui/material/Icon";
import TrafficIcon from "@mui/icons-material/Traffic";
import { connect } from "react-redux";
import { Tooltip } from "@mui/material";
import { toggleTrafficLayer } from "../../Actions/Maps";
import withTranslationWrapper from "../../HOC/withTranslation";
function TrafficMenu(props) {
  const toggleMapTraffic = () => {
    props.dispatch(toggleTrafficLayer());
  };

  const empty = null;

  return (
    <Tooltip title={props.translate("toggleTraffic")} placement="top">
      <li className={props.mapTraffic ? "active" : ""}>
        <a href={empty} title="" onClick={toggleMapTraffic}>
          <TrafficIcon style={{ fontSize: 18, height: 18 }} />
        </a>
      </li>
    </Tooltip>
  );
}
const mapStateToProps = ({ mapTraffic }) => ({
  mapTraffic,
});

export default connect(mapStateToProps)(withTranslationWrapper(TrafficMenu));
