import React, { Component } from "react";
import { Switch, Redirect } from "react-router-dom";
import Route from "./Route";
import Monitoring from "../Pages/Monitoring";
import Login from "../Pages/Login";
import { LogOut } from "../Pages/LogOut";
import Loginasuser from "../Pages/LoginAsUser";
import Tracks from "../Pages/Tracks";
import Timeline from "../Pages/Timeline";
import Timemachine from "../Pages/Timemachine";
import Units from "../Pages/Units";
import Groups from "../Pages/Groups";
import Geofences from "../Pages/Geofences";
import Reports from "../Pages/Reports";
import { ResetPassword } from "../Pages/ResetPassword";
import Users from "../Pages/Users";
import Messages from "../Pages/Messages";
import Drivers from "../Pages/Drivers";
import Trailer from "../Pages/Trailer";
import Trash from "../Pages/Trash";
import Dashboard from "../Pages/Dashboard";
import MarkerMap from "../Pages/MarkerMap";
import Commands from "../Pages/Commands";
import Maintenance from "../Pages/Maintenance";
import Notification from "../Pages/Notifications";
import Events from "../Pages/Events";
import AccountManagements from "../Pages/AccountManagements";
import NotFound from "../Pages/NotFound";
import "../assets/main.scss";
import Vehicle from "../Pages/Vehicle";
import Jobs from "../Pages/Jobs";
import Parameters from "../Pages/Parameters";
import BuildTheme from "../Pages/BuildTheme";
import DashboardBuilder from "../Pages/DashboardBuilder";
import PremiumDashboard from "../Pages/PremiumDashboard";
import Services from "../Pages/Services";

export default class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route
          {...this.props}
          exact
          path="/"
          render={
            (routeProps) => (
              <Redirect to="/dashboard" />
            ) /*<Home {...routeProps} {...this.state} />*/
          }
        />
        <Route
          {...this.props}
          exact
          path="/dashboard"
          render={(routeProps) => <Dashboard {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/login"
          render={(routeProps) => (
            <Login {...this.props} {...routeProps} {...this.state} />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/logout"
          render={(routeProps) => <LogOut {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/loginasuser"
          render={(routeProps) => (
            <Loginasuser {...routeProps} {...this.state} />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/monitoring"
          render={(routeProps) => (
            <Monitoring
              {...routeProps}
              {...this.state}
              viewOnMap={this.props.viewOnMap}
            />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/tracks"
          render={(routeProps) => (
            <Tracks
              {...routeProps}
              {...this.state}
              drawTracks={this.props.drawTracks}
            />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/tracks/:id"
          render={(routeProps) => (
            <Tracks
              {...routeProps}
              {...this.state}
              drawTracks={this.props.drawTracks}
            />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/timeline"
          render={(routeProps) => <Timeline {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/timeline/:id"
          render={(routeProps) => <Timeline {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/Dlogs"
          render={(routeProps) => <Messages {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/timemachine"
          render={(routeProps) => (
            <Timemachine {...routeProps} {...this.state} />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/units/:id"
          render={(routeProps) => <Units {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/units/add/unit"
          render={(routeProps) => (
            <Units {...routeProps} {...this.state} addUnitScreen={true} />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/units"
          render={(routeProps) => <Units {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/users/:id"
          render={(routeProps) => <Users {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/commands"
          render={(routeProps) => <Commands {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/maintenance"
          render={(routeProps) => (
            <Maintenance {...routeProps} {...this.state} />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/maintenanceCreate"
          render={(routeProps) => (
            <Maintenance {...routeProps} {...this.state} />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/maintenanceUpdate/:id"
          render={(routeProps) => (
            <Maintenance {...routeProps} {...this.state} />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/parameters"
          render={(routeProps) => (
            <Parameters {...routeProps} {...this.state} />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/users"
          render={(routeProps) => <Users {...routeProps} {...this.state} />}
        />
        {/* <Route
         {...this.props}
          exact
          path='/addUser'
          render={routeProps => (
            <Users {...routeProps} {...this.state} addUserScreen={true} />
          )}
        /> */}
        <Route
          {...this.props}
          exact
          path="/resources"
          render={(routeProps) => <Groups {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/resources/:id"
          render={(routeProps) => <Groups {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/themeBuilder"
          render={(routeProps) => (
            <BuildTheme {...routeProps} {...this.state} />
          )}
        />

        <Route
          {...this.props}
          exact
          path="/premium_dashboard/:id"
          render={(routeProps) => (
            <PremiumDashboard {...routeProps} {...this.state} />
          )}
        />

        <Route
          {...this.props}
          exact
          path="/dashboardBuilder"
          render={(routeProps) => (
            <DashboardBuilder {...routeProps} {...this.state} />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/dashboardBuilder/:id"
          render={(routeProps) => (
            <DashboardBuilder {...routeProps} {...this.state} />
          )}
        />

        <Route
          {...this.props}
          exact
          path="/geofences"
          render={(routeProps) => <Geofences {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/reports"
          render={(routeProps) => <Reports {...routeProps} {...this.state} />}
        />

        <Route
          {...this.props}
          exact
          path="/users"
          render={(routeProps) => <Users {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/drivers"
          render={(routeProps) => <Drivers {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/drivers/:id"
          render={(routeProps) => <Drivers {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/trailers"
          render={(routeProps) => <Trailer {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/trailers/:id"
          render={(routeProps) => <Trailer {...routeProps} {...this.state} />}
        />

        <Route
          {...this.props}
          exact
          path="/notifications"
          render={(routeProps) => (
            <Notification {...routeProps} {...this.state} />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/notificationCreate"
          render={(routeProps) => (
            <Notification {...routeProps} {...this.state} />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/notificationUpdate/:id"
          render={(routeProps) => (
            <Notification {...routeProps} {...this.state} />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/events"
          render={(routeProps) => (
            <Events {...routeProps} {...this.state} {...this.props} />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/events/:id"
          render={(routeProps) => <Events {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/maps/:lat/:lng"
          render={(routeProps) => <MarkerMap {...routeProps} {...this.state} />}
        />

        <Route
          {...this.props}
          exact
          path="/vehicles"
          render={(routeProps) => (
            <Vehicle {...routeProps} {...this.state} renderMode="default" />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/schedules"
          render={(routeProps) => <Jobs {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/schedules/:id"
          render={(routeProps) => <Jobs {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/vehicle/:id"
          render={(routeProps) => (
            <Vehicle {...routeProps} {...this.state} renderMode="view" />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/vehicles/:id"
          render={(routeProps) => (
            <Vehicle {...routeProps} {...this.state} renderMode="edit" />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/trash"
          render={(routeProps) => <Trash {...routeProps} {...this.state} />}
        />
        <Route
          {...this.props}
          exact
          path="/accountManagements"
          render={(routeProps) => (
            <AccountManagements
              {...routeProps}
              {...this.state}
              {...this.props}
            />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/reset"
          render={(routeProps) => (
            <ResetPassword {...this.props} {...routeProps} {...this.state} />
          )}
        />
        <Route
          {...this.props}
          exact
          path="/services"
          render={(routeProps) => (
            <Services {...routeProps} {...this.state} renderMode="default" />
          )}
        />
        <Route {...this.props} path="*" component={NotFound} />
      </Switch>
    );
  }
}
