import React from "react";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "transparent",
    borderWidth: 1,
    borderStyle: "solid",
    padding: "2px 8px !important",
    display: "flex",
    marginTop: 8,
    marginBottom: 4,
    minHeight: 32,
  },
  input: {
    flex: 1,
    color: "inherit",
    fontSize: 12,
  },
  inputField: {
    padding: 4,
  },
  iconButton: {
    padding: 0,
    color: "inherit",
  },
}));

export default function CustomizedInputBase(props) {
  const classes = useStyles();

  return (
    <form
      onSubmit={props.onSubmit}
      className={"theme-input theme-notchedOutline " + classes.root}
    >
      <InputBase
        onChange={props.onChange}
        classes={{ root: classes.input, input: classes.inputField }}
        placeholder="Add Custom Variables/Operators"
        value={props.value || ""}
        inputProps={{ "aria-label": "add custom variables/operators" }}
      />
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="directions"
      >
        <SendIcon />
      </IconButton>
    </form>
  );
}
