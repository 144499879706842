import React, { Component } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { connect } from "react-redux";
import {
  checkPrivileges,
  selectAll,
  setAttributeFormat,
  errorHandler,
} from "../../Helpers";
import Layout from "./../../Layout";
import Button from "../../Components/common/Button";
import { notifyError, notifySuccess } from "../../Utils/CustomNotifcations";
import EmptyState from "../../Components/common/EmptyState";
import Dialog from "../../Components/common/Dialog";
import {
  getMaintenance,
  addMaintenance,
  removedMaintenance,
} from "../../Actions/Maintenance";
import Strings from "./../../lang/en.json";
import ItemsSelector from "../Units/Components/itemsSelector";
import { ReactComponent as MaintenanceIcon } from "./../../assets/nav/spanner.svg";
import {
  removedUserDeviceWiseSelectedList,
  userDeviceWiseSelectedList,
} from "../../Actions/Notifications";
import ConfirmDialoag from "../../Components/common/ConfirmDialoag";
import { removeUser } from "../../Actions/Users";
import moment from "moment";
import { removeDevice } from "../../Actions/Devices";
import CustomDialog from "../../Components/common/Dialog";
import { CreateMaintenanceForm } from "./CreateMaintenanceForm";
import { MaintenanceForm } from "./MaintenanceForm";
import { UpdateMaintenanceHistoryForm } from "./UpdateMaintenanceHistoryForm";
import { MaintenanceHistoryForm } from "./MaintenanceHistoryForm";
import { CreateExpense } from "./CreateExpense";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import CloseIcon from "@mui/icons-material/Close";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { Tooltip } from "@mui/material";
import { SingleItem } from "../../Hooks/useSingleItem";
import { store } from "../../index";
import { Provider } from "react-redux";
import instance from "../../axios";
import withTranslationWrapper from "../../HOC/withTranslation";
const CancelToken = axios.CancelToken;
let source;
let tempFile = [];
const servicesAttributes = [];
let driversList = [];
let vehiclesList = [];
let subTypes = [];
let allSubTypes = [];
let emptyLink = null;

const expenseType = [
  {
    key: "1",
    name: "Service",
    valueType: "string",
  },
  {
    key: "2",
    name: "Expense",
    valueType: "string",
  },
  {
    key: "3",
    name: "Fuel",
    valueType: "string",
  },
];

const defaultExpenseForm = {
  name: "",
  description: "",
  odometer: "",
  serviceTime: "",
  quantity: "",
  cost: "",
  address: "",
  coordinates: "",
  service: "Service",
  type: "",
  driver: "Driver",
  vehicles: "Vehicles",
  device: "",
  status: "",
  files: "",
  engineHours: "",
  currentEngineHours: 0,
  currentOdometer: 0,
  attributes: {
    updateMaintenance: false,
  },
};
const DataFormat = (data, totalCost, totalQuantity, serviceData) => {
  return {
    service: {
      id: data && data.id ? data.id : 0,
      updatedBy: data && data.updatedBy ? data.updatedBy : "",
      status: "completed",
      name: data && data.name ? data.name : "",
      description: data && data.description ? data.description : null,
      odometer: data && data.odometer ? parseFloat(data.odometer) * 1000 : "",
      typeId: data && data.type && data.type.id ? data.type.id : "3",
      quantity: totalQuantity ? totalQuantity : "",
      cost: totalCost ? totalCost : "",
      address: data && data.address ? data.address : null,
      coordinate: data && data.coordinates ? data.coordinates : "30.00,50.00",
      vehicleId:
        data && data.vehicles && data.vehicles.id
          ? parseInt(data.vehicles.id)
          : "",
      deviceId:
        data && data.device && data.device.id ? parseInt(data.device.id) : "",
      driverUniqueId:
        data && data.driver && data.driver.uniqueId
          ? data.driver.uniqueId
          : null,
      maintenanceId: data && data.maintenanceId ? data.maintenanceId : "",
      files: data.files,
      createdTime: data && data.createdTime ? data.createdTime : "",
      serviceTime: data && data.serviceTime ? data.serviceTime : "",
      userId: data && data.userId ? data.userId : 1,
      engineHours:
        data && data.engineHours
          ? parseInt(data.engineHours) * (1000 * 3600)
          : 0,
      attributes: data && data.attributes ? { ...data.attributes } : {},
    },
    serviceExpenseCollection: [...serviceData],
  };
};

class Maintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      maintenanceForm: {},
      maintenanceHistForm: {},
      historyId: "",
      totalCount: 0,
      mode: "showMaintenance",
      isVisible: true,
      // histFormVisible: false,
      onDeleteConfirm: false,
      onDeleteExpense: false,
      analyticData: "",
      anchorEl: null,
      countChecked: 0,
      statusFilter: [],
      filterStatusType: [],
      filterType: "due",
      imageFile: "",
      enableImagePopup: false,
      selectMaintenanceId: "",
      itemSearch: "",
      isDataRecieved: false,
      loader: false,
      model: false,
      assignMode: "",
      currentMaintenance: "",
      entityId: 0,
      image64Type: [],
      initFetch: false,
      currentPage: 1,
      currentPageHist: 1,
      pagesize: 20,
      pagesizeHist: 20,
      searchVehicleId: { id: 0, label: this.props.translate("searchVehicles") },
      selectedVehicleId: "",
      selectedDate: "",
      statusMode: false,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
      },
      itemsPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        hasNext: true,
      },
      deleteForm: {},
      expenseForm: {
        ...defaultExpenseForm,
      },
      serviceExpenseCollection: [{ quantity: "", cost: "", expensetypeId: "" }],
      resultMessage: "No Items",
    };
  }

  componentWillUnmount() {
    this.setState({
      maintenanceForm: {},
      mode: "",

      model: false,
      assignMode: "",
      currentMaintenance: "",
      entityId: 0,

      initFetch: false,
      currentPage: 1,
      pagesize: 20,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
      },
    });
  }

  UNSAFE_componentWillMount() {
    if (this.props.location.pathname === "/maintenanceCreate") {
      this.setState({ mode: "create" });
    }
    if (this.props.location.pathname.indexOf("/maintenanceUpdate") !== -1) {
      this.openEditMaintenanceForm();
    }

    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true,
        },
        () => {
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize
          );
        }
        // this.fetchMaintenanceHistory(
        //   this.state.currentPageHist,
        //   this.state.pagesizeHist
        // )
      );
    }
    this.maintenanceAnalytic();
    this.getExpenseTypes();
  }

  UNSAFE_componentWillReceiveProps(n) {
    if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
      this.setState(
        {
          initFetch: true,
        },
        () => {
          this.fetchData(
            n.logInUser,
            this.state.currentPage,
            this.state.pagesize
          );
        }
        // ,
        //   this.fetchMaintenanceHistory(
        //   this.state.currentPageHist,
        //   this.state.pagesizeHist
        // )
      );
    }
  }
  openEditMaintenanceForm = () => {
    let { maintenance } = this.props;
    maintenance.map((item) => {
      if (parseInt(this.props.match.params.id) === item.id) {
        this.editMaintenanceForm([item]);
      }
    });
  };

  fetchMoreItems = (a, b, c) => {
    this.fetchData(
      this.props.logInUser,
      this.state.currentPage,
      this.state.pagesize
    );
  };
  fetchMoreItemsHist = (a, b, c) => {
    this.fetchMaintenanceHistory(
      this.state.currentPageHist,
      this.state.pagesizeHist
    );
  };

  fetchData = (logInUser, page, perPage, reset) => {
    source = CancelToken.source();
    let items = this.state.itemPagination && this.state.itemPagination.items;
    if (reset) {
      items = [];
    }
    instance({
      method: "GET",
      url: `/api/maintenance/get?userId=${
        logInUser.id
      }&page=${page}&limit=${perPage}&all=${true}&search=${
        this.state.itemSearch
      }`,
      cancelToken: source.token,
    })
      .then((response) => {
        // if(response && response.status === 200) {
        this.setState(
          {
            itemPagination: {
              ...response,
              items: items.concat(response && response.data),
            },
            currentPage: response.hasNext ? response.page + 1 : response.page,
            isDataRecieved: true,
          },
          () => {
            this.props.dispatch(
              getMaintenance(items.concat(response && response.data))
            );
          }
        );
        if (this.state.itemSearch === "") {
          this.setState({
            savedData: {
              ...response,
              items: items.concat(response && response.data),
            },
          });
        }
        // }
      })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
  };
  fetchMaintenanceHistory = (page, perPage) => {
    let searchAreaid =
      this.state.searchAreaId && this.state.searchAreaId.id
        ? `&areaId=${this.state.searchAreaId.id}`
        : "";
    let searchVehicleid =
      this.state.searchVehicleId && this.state.searchVehicleId.id
        ? `&vehicleId=${this.state.searchVehicleId.id}`
        : "";

    let url =
      (this.state.vehicleId && this.state.vehicleId.length) ||
      (this.state.areaId && this.state.areaId.length)
        ? `/api/services/${
            this.state.filterType
          }?page=${page}&limit=${perPage}&search=${this.state.itemSearch}${
            this.state.selectedVehicleId
          }${this.state.selectedAreaId}${this.state.filterStatusType}&from=${
            this.state?.fromDate || ""
          }&to=${this.state?.toDate || ""}`
        : (this.state.searchVehicleId && this.state.searchVehicleId.id) ||
          (this.state.searchAreaId && this.state.searchAreaId.id)
        ? `/api/services/${this.state.filterType}?page=${page}&limit=${perPage}&search=${this.state.itemSearch}${searchVehicleid}${searchAreaid}${this.state.filterStatusType} `
        : `/api/services/${this.state.filterType}?page=${page}&limit=${perPage}&search=${this.state.itemSearch}${this.state.filterStatusType} `;
    source = CancelToken.source();
    let items = this.state.itemsPagination.items;
    instance({
      method: "GET",
      url: url,
      cancelToken: source.token,
    })
      .then((response) => {
        this.setState({
          itemsPagination: {
            ...response,
            items: items.concat(response && response.data),
          },
          currentPageHist: response.hasNext ? response.page + 1 : response.page,
          isDataRecieved: true,
          loadingSidebar: false,
        });
        //   }
        // }
      })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
  };
  assignModal = (assignMode, currentMaintenance) => {
    this.props.dispatch(userDeviceWiseSelectedList([]));
    this.setState(
      {
        assignMode,
        currentMaintenance,
        selectEntity: "",
        model: true,
      },
      () => {
        if (checkPrivileges("user") && assignMode === "user") {
          instance({
            url: `/api/users/access`,
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            params: {
              maintenanceId: currentMaintenance.id,
            },
          })
            // .then(response => {
            //   if (response.ok) {
            //     response.json()
            .then((users) => {
              let allData = this.props.users;
              if (users.length) {
                allData.map((item) => {
                  item.check = false;
                  users.map((main) => {
                    if (item.id && main.userId && item.id === main.userId) {
                      // for parent
                      item.check = true;
                    }
                    return null;
                  });
                  return null;
                });
              } else {
                allData.map((n) => (n.check = false));
              }

              this.props.dispatch(userDeviceWiseSelectedList(allData));
              //   })
              // }
              // else{
              //   throw response
              // }
            })
            .catch((error) => {
              // errorHandler(error,this.props.dispatch)
            });
        }
        if (checkPrivileges("device") && assignMode === "unit") {
          instance({
            url: `/api/devices/access`,
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            params: {
              maintenanceId: currentMaintenance.id,
            },
          })
            // .then(response => {
            //   if (response.ok) {
            //     response.json()
            .then((devices) => {
              let allData = this.props.devices3;
              if (devices.length) {
                allData.map((item) => {
                  item.check = false;
                  devices.map((main) => {
                    if (item.id && main.deviceId && item.id === main.deviceId) {
                      // for parent
                      item.check = true;
                    }
                    return null;
                  });
                  return null;
                });
              } else {
                allData.map((n) => (n.check = false));
              }
              this.props.dispatch(userDeviceWiseSelectedList(allData));
              //   })
              // }
              // else{
              //   throw response
              // }
            })
            .catch((error) => {
              // errorHandler(error,this.props.dispatch)
            });
        }
        /* if (checkPrivileges('group') && assignMode === 'group') {
        fetch(`/api/groups/access?maintenanceId=${currentMaintenance.id}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(response => {
          if (response.ok) {
            response.json().then(groups => {
              let allData = this.props.groups
              if (groups.length) {
                allData.map(item => {
                  item.check = false;
                  groups.map(main => {

                    if (item.id && main.groupId && item.id === main.groupId) {
                      // for parent
                      item.check = true
                      
                    }
                    return null
                  })
                  return null
                })
              } else {
                allData.map(n => (n.check = false))
              }
              this.props.dispatch(userDeviceWiseSelectedList(allData))
            })
          }
        })
      } */
      }
    );
  };

  selectEntity = (e) => {
    this.setState({
      selectEntity: e,
    });
  };

  submitAssignModel = () => {
    if (this.state.assignMode && this.state.assignMode === "unit") {
      let obj = {
        deviceId: this.state.selectEntity.id,
        maintenanceId: this.state.currentMaintenance.id,
      };
      this.assignMaintenance(obj);
    } else if (this.state.assignMode && this.state.assignMode === "user") {
      let obj = {
        userId: this.state.selectEntity.id,
        maintenanceId: this.state.currentMaintenance.id,
      };
      this.assignMaintenance(obj);
    } /* else if (this.state.assignMode && this.state.assignMode === 'group') {
      let obj = {
        groupId: this.state.selectEntity.id,
        maintenanceId: this.state.currentMaintenance.id
      }
      this.assignMaintenance(obj)
    } */
  };

  assignMaintenance = (obj) => {
    instance({
      url: `/api/permissions/`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.ok) {
        this.props.dispatch(
          notifySuccess(this.props.translate("maintenanceAssignedSuccessfully"))
        );
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
    // .catch(e => {
    //   if (e && e.text) {
    //     e.text().then(err => {
    //       if (err && err.includes('Duplicate entry')) {
    //         this.props.dispatch(
    //           notifyError({
    //             message: this.props.translate('alreadyAssigned'),
    //             autoDismiss: 10
    //           })
    //         )
    //       } else {
    //         this.props.dispatch(
    //           notifyError({
    //             message: this.props.translate('somthingWentWrongMessage'),
    //             autoDismiss: 10
    //           })
    //         )
    //       }
    //     })
    //   }
    // })
  };

  closeAssignModal = () => {
    this.setState({
      model: false,
      assignMode: "",
      commandTitle: "",
      currentMaintenance: "",
    });
  };

  openCreateFrom = () => {
    this.setState({ mode: "", maintenanceForm: {} }, () => {
      this.setState({
        mode: "create",
        maintenanceForm: {
          attributes: {
            mileage: 0,
            hours: 0,
            date: 0,
            status: true,
            mailTo: "",
            email: [],
          },
        },
        selectedUnit: null,
      });
    });
  };

  getExpenseTypes = () => {
    instance({
      method: "GET",
      url:
        this.props.ServerSettings && this.props.ServerSettings.contractNumber
          ? `/api/expensetypes?userId=${this.props.ServerSettings.contractNumber}`
          : `/api/expensetypes`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // console.log("response====", response);

        // if(response && response.data && response.data.data){
        // this.setState({allSubTypes:response})
        response &&
          response.map((type) => {
            servicesAttributes.push({
              key: type.id,
              id: type.id,
              type: type.type,
              name: type.name,
              subType: type.subType,
              description: type.description,
              attributes: type.attributes,
            });
          });
        let expenseTypes = response.map((item) => {
          return {
            key: item.id,
            id: item.id,
            name: item.name,
            description: item.description,
            attributes: item.attributes,
            parentId: item.parentId,
            valueType: "string",
          };
        });
        // expenseType = expenseTypes;
        // }
      })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
  };
  getSubTypes = (id) => {
    return new Promise((resolve, reject) => {
      let res = [];
      instance({
        method: "GET",
        url: `/api/expensetypes/subtype?expenseTypeId=${id}&search=`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          response &&
            response.map((item) => {
              res.push({
                key: item.id,
                id: item.id,
                expenseTypeId: item.expenseTypeId,
                name: item.label,
                label: item.label,
                parentId: item.parentId,
              });
            });
          subTypes = res;
          this.setState({ subTypes2: res }, () => resolve(res));
          // }
        })
        .catch((error) => {
          resolve(this.state.subTypes2);
          // errorHandler(error,this.props.dispatch)
        });
    });
  };
  createExpense = () => {
    this.getData("vehicles", "id", 0, "multi", (data) => {
      vehiclesList = data.map((item) => {
        return {
          id: item.id,
          label: item.label,
          value: item.id,
          vehicleLP: item.vehicleLicensePlate,
          valueType: "string",
        };
      });
    });
    this.getData("drivers", "id", 0, "multi", (data) => {
      driversList = data.map((item) => {
        return {
          key: item.id,
          name: item.name,
          uniqueId: item.uniqueId,
          valueType: "string",
        };
      });
    });
    this.setState({
      mode: "createExpense",
      image64Type: [],
      loader: false,
      selectedVehicleId: "",
      selectedDate: "",
    });
  };

  closeExpense = () => {
    this.setState({
      mode: "showHist",
    });
  };

  handleChangeExpense = (name, value) => {
    if (name === "driver") {
      this.setState(
        {
          expenseForm: {
            ...this.state.expenseForm,
            driver: value,
          },
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    } else if (value && value.name === "devices") {
      this.setState(
        {
          expenseForm: {
            ...this.state.expenseForm,
            device: name,
          },
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    } else if (name === "vehicles") {
      this.setState(
        {
          selectedVehicleId: value.id,
          expenseForm: {
            ...this.state.expenseForm,
            vehicles: value,
          },
        },
        () => {
          this.checkRequiredFields3();
          this.getVehiclesData(
            this.state.selectedVehicleId,
            this.state.mode,
            this.state.selectedDate
          );
        }
      );
    } else if (name === "type") {
      if (parseInt(value.id) !== 0) {
        subTypes = (allSubTypes && allSubTypes).filter(
          (item) => item.type === parseInt(value.id)
        );
        this.setState({
          serviceExpenseCollection: [
            { quantity: "", cost: "", expensetypeId: "" },
          ],
        });
      } else {
        subTypes = allSubTypes;
      }

      this.setState(
        {
          expenseForm: {
            ...this.state.expenseForm,
            [name]: value,
          },
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    } else if (name === "service") {
      this.setState(
        {
          expenseForm: {
            ...this.state.expenseForm,
            [name]: value.name,
          },
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    } else if (name === "status") {
      this.setState(
        {
          expenseForm: {
            ...this.state.expenseForm,
            [name]: value.name,
          },
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    } else if (name === "files") {
      // file.push(this.state.expenseForm.files)
      tempFile.push(value);
      this.setState(
        {
          image64Type: tempFile,
          // expenseForm: {
          //   ...this.state.expenseForm,
          //   [name]: tempFile
          // }
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    } else if (name === "serviceTime") {
      this.setState(
        {
          selectedDate: moment(value).toISOString(),
          expenseForm: {
            ...this.state.expenseForm,
            [name]: value,
          },
        },
        () => {
          this.checkRequiredFields3();
          this.getVehiclesData(
            this.state.selectedVehicleId,
            this.state.mode,
            this.state.selectedDate
          );
        }
      );
    } else if (name === "updateMaintenance") {
      this.setState(
        {
          expenseForm: {
            ...this.state.expenseForm,
            attributes: {
              ...this.state.expenseForm.attributes,
              [name]: value,
            },
          },
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    } else {
      this.setState(
        {
          expenseForm: {
            ...this.state.expenseForm,
            [name]: value,
          },
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    }
  };

  handleChangeServices = (name, value, index) => {
    let rows = [...this.state.serviceExpenseCollection];
    let keys = [];
    rows[index][name] = value;
    rows.map((i) => {
      keys.push(i.x);
      return null;
    });
    let sorted_arr = keys.slice().sort();
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] === sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    let a = [];
    keys.map((item, index) => {
      results.map((w) => {
        if (item === w) {
          a.push(index);
        }
        return null;
      });
      return null;
    });
    a.map((val) => {
      rows[val] = { ...rows[val] };
      return null;
    });
    this.setState(
      { serviceExpenseCollection: rows, error: a.length > 0 ? true : false },
      () => {
        this.checkRequiredFields3();
      }
    );
  };

  getVehiclesData = (id, check, date) => {
    let url = date
      ? `/api/vehicles/${id}/accumulator?date=${date}`
      : `/api/vehicles/${id}/accumulator`;
    if (id) {
      instance({
        method: "GET",
        url: url,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (check === "updateHist") {
            this.setState({
              expenseForm: {
                ...this.state.expenseForm,
                currentEngineHours: response.hours
                  ? (response.hours / (1000 * 3600)).toFixed(2) + " h"
                  : 0,
                currentOdometer: response.totalDistance
                  ? setAttributeFormat("mileage", response.totalDistance)
                  : 0,
              },
              // mode: check
            });
          } else {
            this.setState(
              {
                expenseForm: {
                  ...this.state.expenseForm,
                  engineHours: response.hours
                    ? (response.hours / (1000 * 3600)).toFixed(2) + " h"
                    : 0,
                  odometer: response.totalDistance
                    ? setAttributeFormat("mileage", response.totalDistance)
                    : 0,
                },
              },
              () => this.checkRequiredFields3()
            );
          }
          // }
          // }
        })
        .catch((error) => {
          // errorHandler(error,this.props.dispatch)
        });
      // .catch(e => {
      //     this.props.dispatch(
      //       notifyError({
      //         message: this.props.translate('invalidVehicle'),
      //         autoDismiss: 10
      //       })
      //     )
      // })
    }
  };
  checkRequiredFields3 = () => {
    let { name, odometer, serviceTime, vehicles, type } =
      this.state.expenseForm;
    let { expensetypeId, cost, quantity } =
      this.state.serviceExpenseCollection[0];
    if (
      name &&
      odometer &&
      serviceTime &&
      vehicles &&
      type &&
      expensetypeId &&
      cost &&
      quantity
    ) {
      this.setState({
        isVisible: false,
      });
    } else {
      this.setState({
        isVisible: true,
      });
    }
  };

  deleteRow = (row, index, e) => {
    let rows = [...this.state.serviceExpenseCollection];
    rows.splice(index, 1);
    this.setState(
      {
        serviceExpenseCollection: rows,
        isVisible:
          rows[0].expensetypeId && rows[0].cost && rows[0].quantity
            ? false
            : true,
      },
      () => {
        this.state.serviceExpenseCollection.map((row, index) => {
          this.handleChart2("quantity", row["quantity"], index);
          this.handleChart2("cost", row["cost"], index);
          this.handleChart2("expensetypeId", row["expensetypeId"], index);
          return null;
        });
      }
    );
  };

  handleChart2 = (name, value, index) => {
    let rows = [...this.state.serviceExpenseCollection];
    let keys = [];
    rows[index][name] = value;
    rows.map((i) => {
      keys.push(i.x);
      return null;
    });
    let sorted_arr = keys.slice().sort();
    let results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] === sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    let a = [];
    keys.map((item, index) => {
      results.map((w) => {
        if (item === w) {
          a.push(index);
        }
        return null;
      });
      return null;
    });
    rows.map((sal) => {
      sal.e = false;
      return null;
    });
    a.map((val) => {
      rows[val] = { ...rows[val], e: true };
      return null;
    });
    this.setState({
      serviceExpenseCollection: rows,
      error: a.length > 0 ? true : false,
    });
  };

  submitExpense = () => {
    let expenseSubTypeId = 0,
      totalQuantity = 0,
      totalCost = 0,
      expenseType = 0;
    let newFile1 = [];
    const orderNo = 1 + Math.random() * (1000 - 1);
    const newFile = (
      this.state.serviceExpenseCollection && this.state.serviceExpenseCollection
    ).map((item) => {
      if (item.quantity && item.expenseSubTypeId.id) {
        totalCost = totalCost + parseInt(item.cost);
        totalQuantity = totalQuantity + parseInt(item.quantity);
        expenseSubTypeId = item.expenseSubTypeId.id || "";
        expenseType = item.expenseTypeId.id || "";
        delete item.type;
        delete item.e;
        delete item.selectedSubTypes;
        delete item.allExpenseTypes;
        return {
          ...item,
          serviceId: 0,
          expenseSubTypeId: expenseSubTypeId,
          expenseTypeId: expenseType,
        };
      }
    });
    newFile.map((item) => {
      if (item) {
        newFile1.push(item);
      }
    });
    this.setState({ serviceExpenseCollection: newFile1 }, () => {
      const obj = DataFormat(
        this.state.expenseForm,
        totalCost,
        totalQuantity,
        this.state.serviceExpenseCollection
      );

      instance({
        url: `/api/services`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((response) => {
          this.props.dispatch(
            notifySuccess(this.props.translate("requestCreated"))
          );
          this.setState(
            {
              mode: this.state.dueActive
                ? "history"
                : this.state.tab === "showMaintenance"
                ? "showMaintenance"
                : "due",
              completeMode: false,
              itemSearch: "",
              currentPageHist: 1,
              expenseForm: { ...defaultExpenseForm },
              serviceExpenseCollection: [
                { quantity: "", cost: "", expenseSubTypeId: "" },
              ],
            },
            () => {
              this.fetchMaintenanceHistory(
                this.state.currentPageHist,
                this.state.pagesizeHist
              );
              this.fetchData(
                this.props.logInUser,
                this.state.currentPage,
                this.state.pagesize
              );
            }
          );
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          this.setState({
            mode: this.state.dueActive ? "due" : "history",
            itemSearch: "",
            expenseForm: { ...defaultExpenseForm },
            serviceExpenseCollection: [
              {
                quantity: "",
                cost: "",
                expenseSubTypeId: "",
                expenseTypeId: "",
              },
            ],
          });
        });
    });
    this.closeAllDueMaintenance();
  };

  ud_expenseSubmit = (chk) => {
    let expenseSubTypeId = 0,
      totalQuantity = 0,
      totalCost = 0,
      expenseTypeId = 0;
    let newFile1 = [];
    const newFile = (
      this.state.serviceExpenseCollection && this.state.serviceExpenseCollection
    ).map((item) => {
      if (item.quantity && item.expenseSubTypeId.id) {
        totalCost = totalCost + parseInt(item.cost);
        totalQuantity = totalQuantity + parseInt(item.quantity);
        expenseSubTypeId = item.expenseSubTypeId.id || "";
        expenseTypeId = item.expenseTypeId.id || "";
        delete item.selectedSubTypes;
        delete item.allExpenseTypes;
        delete item.type;
        delete item.e;
        return {
          ...item,
          serviceId: this.state.expenseForm.id,
          expenseSubTypeId: expenseSubTypeId,
          expenseTypeId: expenseTypeId,
        };
      }
    });
    newFile.map((item) => {
      if (item) {
        newFile1.push(item);
      }
    });
    this.setState({ serviceExpenseCollection: newFile1 }, () => {
      const obj = DataFormat(
        this.state.expenseForm,
        totalCost,
        totalQuantity,
        this.state.serviceExpenseCollection
      );
      instance({
        url: !chk
          ? `/api/services/${parseInt(this.state.expenseForm.id)}`
          : `/api/services/retry`,
        method: !chk ? "PUT" : "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((res) => {
          this.props.dispatch(
            notifySuccess(this.props.translate("requestUpdated"))
          );
          this.setState(
            {
              // mode: this.state.dueActive ? 'due':'history',
              mode: this.state.tab,
              itemSearch: "",
              loader: false,
              currentPageHist: 1,
              expenseForm: { ...defaultExpenseForm },
              serviceExpenseCollection: [
                {
                  quantity: "",
                  cost: "",
                  expenseSubTypeId: "",
                  expenseTypeId: "",
                },
              ],
            },
            () => {
              this.fetchMaintenanceHistory(
                this.state.currentPageHist,
                this.state.pagesizeHist
              );
            }
          );
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          this.setState({
            mode: this.state.dueActive ? "due" : "history",
            itemSearch: "",
            loader: false,
            expenseForm: { ...defaultExpenseForm },
            serviceExpenseCollection: [
              {
                quantity: "",
                cost: "",
                expenseSubTypeId: "",
                expenseTypeId: "",
              },
            ],
          });
        });
    });
    this.getExpenseTypes();
    this.closeAllDueMaintenance();
  };

  addMore = () => {
    let rows = [];
    rows = [...this.state.serviceExpenseCollection];

    if (rows.length) {
      let last = { ...rows[rows.length - 1] };
      rows.push({ expensetypeId: "", quantity: "", cost: "" });
    } else {
      let last = { ...rows[rows.length - 1] };
      rows.push(last);
    }
    this.setState({ serviceExpenseCollection: rows });
  };

  imagePopup = (files) => {
    this.setState({
      maintenanceHistForm: {
        ...this.state.maintenanceHistForm,
        files,
      },
      enableImagePopup: true,
    });
  };

  deleteImage = (e, image, type) => {
    // let image = this.state.maintenanceHistForm.files && this.state.maintenanceHistForm.files.split(',')
    const s = image.filter((item, index) => index !== e);
    if (type === "url") {
      this.setState(
        {
          maintenanceHistForm: {
            ...this.state.maintenanceHistForm,
            files: s.toString(),
          },
          expenseForm: {
            ...this.state.expenseForm,
            files: s.toString(),
          },
        },
        () => {
          this.checkRequiredFields3();
        }
      );
    } else if (type === "base64") {
      this.setState(
        {
          maintenanceHistForm: {
            ...this.state.maintenanceHistForm,
            files: s.toString(),
          },
          image64Type: s,
        },
        () => {
          this.checkRequiredFields3();
          tempFile = s;
        }
      );
    }

    //  tempFile = s
  };

  onCloseModal = () => {
    this.setState({
      enableImagePopup: false,
    });
  };

  handleClickFilter = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseFilter = () => {
    this.setState({ anchorEl: null });
  };
  handleChangeFilter = (name, event) => {
    let filter = "";
    this.setState(
      {
        statusFilter: {
          ...this.state.statusFilter,
          [name]: event.target.checked,
        },
        countChecked: 0,
        currentPageHist: 1,
      },
      () => {
        Object.entries(this.state.statusFilter).map(([key, value]) => {
          if (value) {
            // this.state.filterType.push(key)
            filter = this.state.countChecked === 1 ? "" : key;
            this.setState({
              countChecked: ++this.state.countChecked,
            });
          }
        });
        this.setState(
          {
            filterStatusType: filter,
          },
          () => {
            this.fetchMaintenanceHistory(
              this.state.currentPageHist,
              this.state.pagesizeHist
            );
          }
        );
      }
    );
  };
  switchMode = (mode, filterType) => {
    if (filterType === "due") {
      this.setState(
        {
          mode: filterType,
          itemSearch: "",
          filterType,
          isDataRecieved: false,
          dueActive: true,
          currentPageHist: 1,
          itemPagination: this.state.savedData,
          searchVehicleId: {
            id: 0,
            label: this.props.translate("searchVehicles"),
          },
        },
        () => {
          this.fetchMaintenanceHistory(
            this.state.currentPageHist,
            this.state.pagesizeHist
          );
        }
      );
    } else if (filterType === "history") {
      this.setState(
        {
          mode: filterType,
          itemSearch: "",
          filterType,
          isDataRecieved: false,
          dueActive: false,
          currentPageHist: 1,
          itemPagination: this.state.savedData,
        },
        () => {
          this.fetchMaintenanceHistory(
            this.state.currentPageHist,
            this.state.pagesizeHist
          );
        }
      );
    } else {
      this.setState({
        itemSearch: "",
        mode: filterType,
        itemPagination: this.state.savedData,
        dueActive: false,
        isDataRecieved: true,
      });
    }
  };
  editHistoryFrom = (id) => {
    Object.values(id).map((e) => {
      this.setState(
        {
          // mode: '',
          expenseForm: e,
          isVisible: true,
          loader: false,
          historyId: e.id,
          selectedVehicleId: e.vehicleId,
        },
        () => {
          this.getExpense(e.id);
          this.getVehiclesData(e.vehicleId, "updateHist", false);
        }
      );
    });
    this.getData("vehicles", "id", 0, "multi", (data) => {
      vehiclesList = data.map((item) => {
        return {
          id: item.id,
          label: item.label,
          value: item.id,
          vehicleLP: item.vehicleLicensePlate,
          valueType: "string",
        };
      });
    });
    this.getData("drivers", "id", 0, "multi", (data) => {
      driversList = data.map((item) => {
        return {
          key: item.id,
          name: item.name,
          uniqueId: item.uniqueId,
          valueType: "string",
        };
      });
    });
  };

  getExpense = (id) => {
    let serverTimeZoneName = "Asia/Dubai";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone;
    }

    instance({
      method: "GET",
      url: `/api/services/expenses/${id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        let type = expenseType.filter((obj) => {
          return parseInt(obj.key) === parseInt(this.state.expenseForm.typeId);
        });

        // const result = response.data.data.filter(res => {
        //   let arr = this.state.allSubTypes.filter(detail => detail.id === res.expensetypeId)
        //   return !(arr.length === 0)
        // });
        var tempData = [];
        this.getData(
          "devices3",
          "id",
          this.state.expenseForm.deviceId,
          "single",
          (data) => {
            let device = data;
            let vehicles = this.props.vehicles.find(
              (item) => item.id === this.state.expenseForm.vehicleId
            );
            let driver = this.props.drivers.find(
              (item) => item.uniqueId === this.state.expenseForm.driverUniqueId
            );

            response.data.data.map((item) => {
              this.state.allSubTypes.map((z) => {
                if (item.expensetypeId === z.id) {
                  item.expensetypeId = z;
                  tempData.push(item);
                }
              });
            });
            this.setState({
              expenseForm: {
                ...this.state.expenseForm,
                serviceTime: moment(this.state.expenseForm.serviceTime)
                  .tz(serverTimeZoneName)
                  .format("YYYY-MM-DD"),
                type: type && type[0],
                device,
                vehicles: vehicles,
                driver: driver,
                engineHours:
                  (this.state.expenseForm.engineHours / (1000 * 3600)).toFixed(
                    2
                  ) + " h",
                odometer: setAttributeFormat(
                  "mileage",
                  this.state.expenseForm.odometer
                ),
              },
              image64Type: [],
              serviceExpenseCollection: tempData,
              mode: "updateHist",
              itemSearch: "",
            });
          }
        );
      })
      .catch((e) => {
        this.props.dispatch(
          notifyError(this.props.translate("somthingWentWrongMessage"))
        );
      });
  };

  getExpense = (id) => {
    let allExpenseTypes = expenseType;
    // expenseType=[]
    let serverTimeZoneName = "Asia/Dubai";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone;
    }
    instance({
      method: "GET",
      url: `/api/services/expenses/${id}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        var tempData = [];
        this.getData(
          "devices3",
          "id",
          this.state.expenseForm.deviceId,
          "single",
          (data) => {
            let device = data;
            let vehicles = this.props.vehicles.find(
              (item) => item.id === this.state.expenseForm.vehicleId
            );
            let driver = this.props.drivers.find(
              (item) => item.uniqueId === this.state.expenseForm.driverUniqueId
            );
            // let garage = garageList.find(
            //   (item) => item.key === this.state.expenseForm.garageId
            // );
            // let areaId =
            //   areaList &&
            //   areaList.find(
            //     (item) => item.key === this.state.expenseForm.areaId
            //   );
            // let selectedStatus = statusTypes.find(
            //   (item) => item.name === this.state.expenseForm.status
            // );
            let serviceSubType = "";
            response.map((item) => {
              instance({
                method: "GET",
                url: `api/expensetypes/subtype?expenseTypeId=${item.expenseTypeId}&search=`,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
              }).then((resp) => {
                let serviceSubType = resp.find(
                  (el) => el.id === item.expenseSubTypeId
                );
                allExpenseTypes.map((z) => {
                  if (item.expenseTypeId === z.id) {
                    item.expenseSubTypeId = serviceSubType;
                    item.expenseTypeId = z;
                    item.selectedSubTypes = resp;
                    item.allExpenseTypes = allExpenseTypes;
                    tempData.push(item);
                    this.setState(
                      {
                        serviceExpenseCollection: tempData,
                        expenseForm: {
                          ...this.state.expenseForm,
                          serviceTime: moment(
                            this.state.expenseForm.serviceTime
                          )
                            .tz(serverTimeZoneName)
                            .format("YYYY-MM-DD"),
                          // type: 1,
                          device,
                          vehicles: vehicles,
                          // status: selectedStatus && selectedStatus.name,
                          // garage: garage,
                          // areaId: areaId,
                          driver: driver,
                          engineHours:
                            this.state.expenseForm.engineHours > 0
                              ? (
                                  this.state.expenseForm.engineHours /
                                  (1000 * 3600)
                                ).toFixed(2) + " h"
                              : "0",
                          odometer:
                            this.state.expenseForm.odometer > 0
                              ? setAttributeFormat(
                                  "mileage",
                                  this.state.expenseForm.odometer
                                )
                              : "0",
                        },
                        image64Type: [],
                        // serviceExpenseCollection: tempData,
                        mode: "updateHist",
                        loader: false,
                        itemSearch: "",
                      },
                      () => {
                        this.getExpenseTypes();
                      }
                    );
                  }
                });
              });
            });
          }
        );
      })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
  };

  editMaintenanceForm = (maintenanceForm) => {
    this.getSubTypes(
      maintenanceForm[0].attributes &&
        maintenanceForm[0].attributes.serviceExpenseTypeId
    ).then((subTypes) => {
      let selectedServiceExpenseId = expenseType.find(
        (item) => item.id === maintenanceForm[0].attributes.serviceTypeId
      );
      let selectedVehicle = this.props.vehicles.find(
        (item) => item.id === maintenanceForm[0].vehicleId
      );
      let selectedUnit = { id: 0, label: "", value: 0 };
      if (subTypes && subTypes.length) {
        Object.values(maintenanceForm).map((maintenanceForm1) => {
          this.setState({ mode: "", maintenanceForm1: {} }, () => {
            let obj = JSON.parse(JSON.stringify(maintenanceForm1));
            if (obj.attributes && obj.attributes.byMileage) {
              obj.attributes.mileage = parseFloat(
                (obj.attributes.mileage / 1000).toFixed(3)
              );
              obj.attributes.mileageInterval = parseFloat(
                (obj.attributes.mileageInterval / 1000).toFixed(3)
              );
              obj.attributes.reminderBeforeMileage = parseFloat(
                (obj.attributes.reminderBeforeMileage / 1000).toFixed(3)
              );
            }
            if (obj.attributes && obj.attributes.byHours) {
              obj.attributes.hours = parseFloat(
                (obj.attributes.hours / (1000 * 3600)).toFixed(3)
              );
              obj.attributes.hoursInterval = parseFloat(
                (obj.attributes.hoursInterval / (1000 * 3600)).toFixed(3)
              );
              obj.attributes.reminderBeforeHours = parseFloat(
                obj.attributes.reminderBeforeHours / (1000 * 3600).toFixed(3)
              );
            }
            if (obj.attributes && obj.attributes.byDays) {
              obj.attributes.date = obj.attributes.date;
              obj.attributes.daysInterval =
                parseFloat(obj.attributes && obj.attributes.daysInterval) ||
                parseFloat(1);
              obj.attributes.reminderBeforeDays =
                parseFloat(obj.attributes.reminderBeforeDays) || parseFloat(1);
            }
            if (obj.attributes.serviceExpenseTypeId) {
              let serviceSubType = subTypes.find(
                (el) =>
                  el.id === maintenanceForm[0].attributes.serviceExpenseTypeId
              );
              obj.attributes.serviceTypeId = selectedServiceExpenseId;
              obj.attributes.serviceExpenseTypeId = serviceSubType;
            }
            selectedUnit.id = obj.deviceId;
            selectedUnit.label = obj.deviceName;
            selectedUnit.value = obj.deviceId;

            delete obj.progress;
            this.setState({
              mode: "update",
              itemSearch: "",
              selectedUnit,
              maintenanceForm: {
                ...obj,
                vehicleId: selectedVehicle,
              },
            });
          });
        });
      }
    });
  };

  setPaginationHistory = (user) => {
    const { itemsPagination } = this.state;
    if (
      itemsPagination &&
      itemsPagination.data &&
      itemsPagination.data.length
    ) {
      let nUser = { ...user };
      let lastPage = nUser.total / this.state.pagesizeHist;
      let IsFloate = this.checkFloteNumber(lastPage);
      delete nUser.data;
      nUser.pagesizeHist = this.state.pagesizeHist;
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
      return nUser;
    }
    return {};
  };
  setPagination = (user) => {
    const { itemPagination } = this.state;
    if (itemPagination && itemPagination.items && itemPagination.items.length) {
      let nUser = { ...user };
      let lastPage = nUser.total / this.state.pagesize;
      let IsFloate = this.checkFloteNumber(lastPage);
      delete nUser.data;
      nUser.pagesize = this.state.pagesize;
      nUser.lastPage = IsFloate ? parseInt(lastPage + 1) : lastPage;
      return nUser;
    }
    return {};
  };
  checkFloteNumber(n) {
    return Number(n) === n && n % 1 !== 0;
  }
  handleChangeRowsPerPage = (value, mode) => {
    if (source) {
      source.cancel();
    }
    if (mode === "maintenanceHist") {
      this.setState(
        {
          pagesizeHist: value,
          currentPageHist: 1,
        },
        () => {
          this.fetchMaintenanceHistory(
            this.state.currentPageHist,
            this.state.pagesizeHist
          );
        }
      );
    } else if (mode === "maintenance") {
      this.setState(
        {
          pagesize: value,
          currentPage: 1,
        },
        () => {
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize,
            true
          );
        }
      );
    }
  };
  handleChangePage = (value, mode) => {
    if (source) {
      source.cancel();
    }
    if (mode === "maintenanceHist") {
      this.setState(
        {
          currentPageHist: value,
        },
        () => {
          this.fetchMaintenanceHistory(
            this.state.currentPageHist,
            this.state.pagesizeHist
          );
        }
      );
    } else if (mode === "maintenance") {
      this.setState(
        {
          currentPage: value,
        },
        () => {
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize,
            true
          );
        }
      );
    }
  };
  SearchItem = (searchValue, mode) => {
    if (source) {
      source.cancel();
    }
    this.setState(
      {
        itemSearch: searchValue,
        currentPage: 1,
        currentPageHist: 1,
      },
      () => {
        if (mode === "maintenanceHist") {
          this.fetchMaintenanceHistory(
            this.state.currentPageHist,
            this.state.pagesizeHist
          );
        } else if (mode === "maintenance") {
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize,
            true
          );
        }
      }
    );
  };
  SearchVehicle = (name, value) => {
    this.setState(
      {
        searchVehicleId: value,
        currentPage: 1,
        currentPageHist: 1,
      },
      () => {
        this.fetchMaintenanceHistory(
          this.state.currentPageHist,
          this.state.pagesizeHist
        );
      }
    );
  };

  closeCreateFrom = (mode) => {
    this.setState(
      {
        mode,
        itemSearch: "",
        itemPagination: this.state && this.state.savedData,
        maintenanceForm: "",
        isVisible: true,
        loader: false,
        // histFormVisible: false,
        vehicleTracker: "",
        expenseForm: { ...defaultExpenseForm },
        serviceExpenseCollection: [
          { quantity: "", cost: "", expensetypeId: "" },
        ],
      },
      () => {
        tempFile = [];
      }
    );
  };

  onDeleteMaintenance = () => {
    if (this.state.itemDeleteId) {
      instance({
        url: `/api/maintenance/${this.state.itemDeleteId}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // if (response.ok) {
          this.props.dispatch(
            removedMaintenance({ id: this.state.itemDeleteId })
          );
          this.props.dispatch(
            notifySuccess(this.props.translate("maintenanceIsDeleted"))
          );
          this.setState({
            onDeleteConfirm: false,
            currentPage: 1,
          });
          this.closeCreateFrom("showMaintenance");
          this.maintenanceAnalytic();
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize,
            true
          );
          // }
          // else{
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  };

  onDeleteExpenseItem = () => {
    if (this.state.itemDeleteExpenseId) {
      instance({
        url: `/api/services/${this.state.itemDeleteExpenseId}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // if (response.ok) {
          this.props.dispatch(
            notifySuccess(this.props.translate("expenseIsDeleted"))
          );
          this.setState(
            {
              onDeleteConfirm: false,
              currentPage: 1,
              currentPageHist: 1,
            },
            () => {
              this.fetchMaintenanceHistory(
                this.state.currentPageHist,
                this.state.pagesizeHist
              );
            }
          );

          this.onCancelExpense();

          // }
          // else{
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  };

  onRemovedMaintenance = (item) => {
    this.setState({
      onDeleteConfirm: true,
      itemDeleteId: item[0].id,
      itemDeleteName: item[0].name,
    });
  };

  onStatusChange = (item) => {
    this.setState({
      onStatusChange: true,
      selectedStatusChange: item,
      itemStatusChange: item.name,
    });
  };

  onChangeStatus = () => {
    let data = this.state.selectedStatusChange;
    if (data) {
      data.attributes.status = !data.attributes.status;
      this.setState(
        {
          maintenanceForm: data,
          statusMode: true,
        },
        () => {
          this.submitForm();
        }
      );
    }
  };

  onRemovedExpense = (item) => {
    this.setState({
      onDeleteExpense: true,
      itemDeleteExpenseId: item[0].id,
      itemDeleteExpense: item[0].name,
    });
  };

  onCancelMaintenance = () => {
    this.setState({
      onDeleteConfirm: false,
    });
  };

  onCancelStatus = () => {
    this.setState({
      onStatusChange: false,
    });
  };

  onCancelExpense = () => {
    this.setState({
      onDeleteExpense: false,
    });
  };

  uploadFile = async (mode) => {
    tempFile = [];
    let { image64Type } = this.state;
    this.setState({ loader: true });
    if (image64Type && image64Type.length) {
      // image64Type.map((item,i) =>
      for (let i = 0; i < image64Type.length; i++) {
        var today = Math.round(new Date().getTime() / 1000);
        const myExe = image64Type[i].split(";base64", 1);
        const exe = myExe[0].replace("data:", "");
        let obj = {
          name: today,
          contentType: exe,
          data: `${image64Type[i].split("base64")[1].substring(1)}`,
        };

        const response = await instance({
          url: `/api/uploads`,
          method: "POST",
          data: { ...obj },
        });
        try {
          const publicUrl = response;
          this.setState(
            {
              expenseForm: {
                ...this.state.expenseForm,
                files: this.state.expenseForm.files
                  ? publicUrl.publicUrl + "," + this.state.expenseForm.files
                  : publicUrl.publicUrl,
              },
            },
            () => {
              if (i === image64Type.length - 1) {
                if (this.state.mode === "updateHist") {
                  this.ud_expenseSubmit();
                } else {
                  this.submitExpense();
                }
              }
            }
          );
        } catch (error) {
          errorHandler(error, this.props.dispatch);
        }
        // catch(e){
        //     this.props.dispatch(
        //     notifySuccess({
        //       message: this.props.translate('File has wrong image data'),
        //       autoDismiss: 10
        //     })
        //   )
        // }
      }
    } else {
      if (this.state.mode === "updateHist") {
        this.ud_expenseSubmit();
      } else {
        this.submitExpense();
      }
    }
  };
  uploadImage = (event, mode) => {
    let totalImages = [];
    totalImages =
      this.state.expenseForm.files && this.state.expenseForm.files.split(",");
    totalImages && totalImages.length
      ? (totalImages = totalImages.length)
      : (totalImages = 0);

    let file = event.target.files;
    let imgData = "";
    if (totalImages + this.state.image64Type.length + file.length <= 5) {
      if (file && file[0]) {
        if (file[0].size / 1000000 <= 5) {
          Object.values(file).map((item) => {
            let reader = new FileReader();
            reader.readAsDataURL(item);
            reader.onload = (e) => {
              imgData = e.target.result;

              const myExe = imgData.split(";base64", 1);
              const exe = myExe[0].replace("data:", "");
              if (
                exe ===
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
                exe ===
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
                exe === "text/plain" ||
                exe === "image/png" ||
                exe === "image/jpeg" ||
                exe === "application/pdf"
              ) {
                let obj = {
                  name: `${item.name}`,
                  contentType: `${item.type}`,
                  // oldUrl: `${this.state.form.driverImg}`,
                  data: `${imgData.split("base64")[1].substring(1)}`,
                };
                this.setState(
                  {
                    imageFile: obj,
                  },
                  () => {
                    this.handleChangeExpense("files", imgData);
                  }
                );
              } else {
                this.props.dispatch(
                  notifyError(this.props.translate("onlyAcceptImage"))
                );
              }
            };
          });
        } else {
          this.props.dispatch(
            notifyError(this.props.translate("fileSize5MB"))
          );
        }
      }
    } else {
      this.props.dispatch(
        notifyError(this.props.translate("fileLimit"))
      );
    }
  };

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  handleChangeHist = (name, value) => {
    let v = value;
    if (name === "type") {
      v = value.id;
      this.setState(
        {
          maintenanceHistForm: {
            ...this.state.maintenanceHistForm,
            status: v,
          },
        },
        () => this.checkRequiredFields2()
      );
    } else {
      this.setState(
        {
          maintenanceHistForm: {
            ...this.state.maintenanceHistForm,
            [name]: v,
          },
        },
        () => {
          this.checkRequiredFields2();
        }
      );
    }
  };
  onChangedDevice = (item) => {
    if (item) {
      this.getData("positions", "deviceId", item.id, "single", (data) => {
        const mileage =
          data && data.attributes && data.attributes.totalDistance
            ? (data.attributes.totalDistance / 1000).toFixed(2)
            : null;
        const hours =
          data && data.attributes && data.attributes.hours
            ? (data.attributes.hours / (3600 * 1000)).toFixed(2)
            : null;
        this.setState(
          {
            vehicleTracker: item,
            vehicleId: item.id,
            maintenanceForm: {
              ...this.state.maintenanceForm,
              deviceId: item.id,
              attributes: {
                ...this.state.maintenanceForm.attributes,
                mileage,
                hours,
                byMileage: mileage ? true : false,
                byHours: hours ? true : false,
                byDays: true,
              },
            },
          },
          () => {
            this.checkRequiredFields();
          }
        );
      });
      return null;
    }
  };
  checkRequiredFields2 = () => {
    const { maintenanceHistForm } = this.state;
    if (
      maintenanceHistForm.cost + "".trim() &&
      maintenanceHistForm.status &&
      maintenanceHistForm.cDate
    ) {
      this.setState({
        isVisible: false,
      });
    } else {
      this.setState({
        isVisible: true,
      });
    }
  };
  handleChange = (name, value) => {
    let v = value;
    this.setState(
      {
        maintenanceForm: {
          ...this.state.maintenanceForm,
          [name]: v,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  checkRequiredFields = () => {
    let { name } = this.state.maintenanceForm;
    let {
      date,
      mileage,
      hours,
      byDays,
      byHours,
      byMileage,
      serviceExpenseTypeId,
      serviceTypeId,
    } = this.state.maintenanceForm && this.state.maintenanceForm.attributes;
    //  if ( name && (mileageInterval || hoursInterval || daysInterval) && (mileage || hours || date)) {
    if (
      name &&
      serviceExpenseTypeId &&
      ((byMileage && mileage) || (byHours && hours) || (byDays && date))
    ) {
      this.setState({
        isVisible: false,
      });
    } else {
      this.setState({
        isVisible: true,
      });
    }
  };
  getData = (collection, field, value, item, cb) => {
    const HideMe = document.getElementById("HideMe");
    ReactDOM.render(
      <Provider store={store}>
        <SingleItem
          collection={collection}
          field={field}
          value={value}
          item={item}
        >
          {(id) => {
            cb(id);
            return null;
          }}
        </SingleItem>
      </Provider>,
      HideMe
    );
  };
  handleChangeAttributes = (name, value, checked) => {
    if (value) {
      if (name === "byMileage" || name === "byHours" || name === "byDays") {
        let maintenanceForm = JSON.parse(
          JSON.stringify(this.state.maintenanceForm)
        );
        maintenanceForm.attributes[name] = checked;
        this.setState({ maintenanceForm });
      } else if (name === "serviceTypeId") {
        this.getSubTypes(value && value.id);
        this.setState(
          {
            maintenanceForm: {
              ...this.state.maintenanceForm,
              attributes: {
                ...this.state.maintenanceForm.attributes,
                serviceTypeId: value,
                expenseName: value.name,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      } else {
        this.setState(
          {
            maintenanceForm: {
              ...this.state.maintenanceForm,
              attributes: {
                ...this.state.maintenanceForm.attributes,
                [name]: value,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      }
    } else {
      if (this.state.maintenanceForm && this.state.maintenanceForm.attributes) {
        let attributes = { ...this.state.maintenanceForm.attributes };
        delete attributes[name];
        this.setState(
          {
            maintenanceForm: {
              ...this.state.maintenanceForm,
              attributes: {
                ...attributes,
              },
            },
          },
          () => this.checkRequiredFields()
        );
      }
    }
  };

  submitForm = (checkTrue) => {
    let obj = JSON.parse(JSON.stringify(this.state.maintenanceForm));
    if (obj.vehicleId) {
      obj.vehicleId = this.state.statusMode ? obj.vehicleId : obj.vehicleId.id;
      obj.deviceId = this.state.statusMode ? obj.deviceId : 0;
    }
    if (obj.attributes.byMileage && !this.state.statusMode) {
      obj.attributes.mileage = parseFloat(obj.attributes.mileage * 1000);
      obj.attributes.mileageInterval = parseFloat(
        obj.attributes.mileageInterval * 1000
      );
      obj.attributes.reminderBeforeMileage = parseFloat(
        obj.attributes.reminderBeforeMileage * 1000
      );
    }
    if (obj.attributes.byHours && !this.state.statusMode) {
      obj.attributes.hours = parseFloat(obj.attributes.hours * (1000 * 3600));
      obj.attributes.hoursInterval = parseFloat(
        obj.attributes.hoursInterval * (1000 * 3600)
      );
      obj.attributes.reminderBeforeHours = parseFloat(
        obj.attributes.reminderBeforeHours * (1000 * 3600)
      );
    }
    if (obj.attributes.byDays && !this.state.statusMode) {
      obj.attributes.date = obj.attributes.date;
      obj.attributes.daysInterval =
        parseFloat(obj.attributes.daysInterval) || parseFloat(1);
      obj.attributes.reminderBeforeDays =
        parseFloat(obj.attributes.reminderBeforeDays) || parseFloat(1);
    }
    if (obj.attributes.serviceExpenseTypeId) {
      obj.attributes.serviceExpenseTypeId = this.state.statusMode
        ? obj?.attributes?.serviceExpenseTypeId
        : obj?.attributes?.serviceExpenseTypeId?.id || "";
      obj.attributes.serviceTypeId = this.state.statusMode
        ? obj?.attributes?.serviceTypeId
        : obj?.attributes?.serviceTypeId?.id || "";
    }

    obj = {
      ...obj,
      type: "",
    };
    obj.cost = parseFloat(obj.cost);
    delete obj.check;
    delete obj.parent;
    if (!obj.attributes.repeat) {
      delete obj.attributes.mileageInterval;
      delete obj.attributes.hoursInterval;
      delete obj.attributes.daysInterval;
    }
    if (!obj.attributes.byMileage) {
      delete obj.attributes.mileage;
      delete obj.attributes.mileageInterval;
      delete obj.attributes.reminderBeforeMileage;
      obj.attributes.reminderByMileage = false;
    }
    if (!obj.attributes.byHours) {
      delete obj.attributes.hours;
      delete obj.attributes.hoursInterval;
      delete obj.attributes.reminderBeforeHours;
      obj.attributes.reminderByHours = false;
    }
    if (!obj.attributes.byDays) {
      delete obj.attributes.date;
      delete obj.attributes.daysInterval;
      delete obj.attributes.reminderBeforeDays;
      obj.attributes.reminderByDays = false;
    }

    let params = "";
    if (this.state.mode === "update" || this.state.statusMode) {
      params = obj.id;
    }
    this.setState({
      isVisible: true,
    });
    if (obj) {
      let url =
        checkTrue === "complete"
          ? `/api/maintenance/${params}/complete`
          : `/api/maintenance/${params}`;
      instance({
        url: `${url}`,
        method: `${
          this.state.statusMode || this.state.mode === "update" ? "PUT" : "POST"
        }`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((maintenance) => {
          // if (response.ok) {
          if (checkTrue === "complete") {
            this.props.dispatch(
              notifySuccess(this.props.translate("maintenanceIsMarkedComplate"))
            );
            this.setState({
              onMarkComplete: false,
            });
          } else {
            // response.json().then(maintenance => {
            this.props.dispatch(addMaintenance(maintenance));
            if (this.state.mode === "update" || this.state.statusMode) {
              this.props.dispatch(
                notifySuccess(this.props.translate("maintenanceIsUpdated"))
              );
            } else {
              this.props.dispatch(
                notifySuccess(this.props.translate("MaintenanceIsCreated"))
              );
            }
            this.setState(
              {
                selectMaintenanceId: maintenance && maintenance.id,
                itemSearch: "",
                onStatusChange: false,
                onMarkComplete: false,
                statusMode: false,
              }
              // ,()=>{
              //   if(this.state.vehicleId){
              //     this.maintenanceSelection()
              //   }
              // }
            );
            this.closeCreateFrom("showMaintenance");
            this.maintenanceAnalytic();
            this.fetchData(this.props.logInUser, 1, this.state.pagesize, true);

            // })
          }
          // }else{
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          this.setState(
            {
              itemSearch: "",
              onStatusChange: false,
              onMarkComplete: false,
              statusMode: false,
            },
            () => {
              this.fetchData(
                this.props.logInUser,
                1,
                this.state.pagesize,
                true
              );
            }
          );
        });
    }
  };
  maintenanceSelection = () => {
    let obj = {
      deviceId: this.state.vehicleId,
      maintenanceId: this.state.selectMaintenanceId,
    };

    instance({
      url: `/api/permissions/`,
      method: `POST`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        this.props.dispatch(
          notifySuccess(this.props.translate("maintenanceAssignedSuccessfully"))
        );
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  submitHistoryForm = (e) => {
    let obj = this.state.maintenanceHistForm;
    let cDate = "";
    let items = this.state.itemsPagination.items.map((row) => {
      if (this.state.historyId === row.id) {
        row.attributes.cost = parseInt(obj.cost);
        row.attributes.status = obj.status;
        row.attributes.cValue = obj.cValue;
        row.attributes.cDate = obj.cDate;
        row.attributes.updateMaintenance = obj.updateMaintenance;
        row.files = obj.files === "undefined" ? "" : obj.files;
      }
      cDate = moment(obj.cDate).toISOString();
      return row;
    });
    this.setState({ isVisible: true });
    instance({
      method: "GET",
      url: `/api/maintenance/history/${this.state.historyId}?cost=${obj.cost}&status=${obj.status}&cValue=${obj.cValue}&cDate=${cDate}&files=${obj.files}&updateMaintenance=${obj.updateMaintenance}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...obj,
      }),
    })
      .then((response) => {
        // if(response && response.status === 200) {
        // if(response.data && response.data.status === 'success') {
        this.props.dispatch(
          notifySuccess(this.props.translate("maintenancehistoryupdated"))
        );
        this.setState(
          {
            itemsPagination: {
              ...this.state.itemsPagination,
              items,
            },
          },
          () => {
            this.closeCreateFrom("history");
            this.maintenanceAnalytic();
          }
        );
        // }
        // }
      })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
    // .catch(e => {
    //     this.props.dispatch(
    //       notifyError({
    //         message: this.props.translate('somthingWentWrongMessage'),
    //         autoDismiss: 10
    //       })
    //     )
    // })
  };

  maintenanceAnalytic = () => {
    instance({
      url: `/api/services/analytic`,
      method: `GET`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((data) => {
        this.setState({
          analyticData: data,
        });
        //   })
        // }
        // else{
        //   throw response
        // }
      })
      .catch((error) => {
        // errorHandler(error,this.props.dispatch)
      });
  };
  item_Selection = (checked, item) => {
    let selectedUnit = item;
    let method = "DELETE";
    let value = checked;
    let allData = [...this.props.selecteduser];
    let obj = {};
    if (this.state.assignMode === "user") {
      obj.userId = item.id;
    }
    if (this.state.assignMode === "unit") {
      obj.deviceId = item.id;
    }
    /* if(this.state.assignMode === 'group') {
      obj.groupId = item.id
    } */
    obj.maintenanceId = parseInt(this.state.currentMaintenance.id);
    if (value) {
      method = "POST";
    }

    instance({
      url: `/api/permissions`,
      method: method,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        if (method === "DELETE") {
          item.check = false;
          allData.map((dt) => {
            if (dt.id === item.userId) {
              dt.check = false;
            }
            return null;
          });

          this.props.dispatch(
            notifySuccess(this.props.translate(
                this.state.assignMode === "user"
                  ? "userUnAssignedSuccessfully"
                  : this.state.assignMode === "unit"
                  ? "trackerIsUnAssignedSuccessfully"
                  : "groupUnAssignedSuccessfully"
              ))
          );
          if (item) {
            selectedUnit.label = item.name;
            selectedUnit.value = item.value;
            this.setState(
              {
                selectedUnit,
                vehicleTracker: {},
              },
              () => {
                this.onChangedDevice(this.state.vehicleTracker);
              }
            );
          }
        } else {
          item.check = true;
          allData.map((dt) => {
            if (dt.id === item.userId) {
              dt.check = true;
            }
            return null;
          });

          this.props.dispatch(
            notifySuccess(this.props.translate(
                this.state.assignMode === "user"
                  ? "userAssignedSuccessfully"
                  : this.state.assignMode === "unit"
                  ? "trackerIsAssignedSuccessfully"
                  : "groupAssignedSuccessfully"
              ))
          );
          if (item) {
            selectedUnit.label = item.name;
            selectedUnit.value = item.value;
            this.setState(
              {
                selectedUnit,
                vehicleTracker: {},
              },
              () => {
                this.onChangedDevice(this.state.selectedUnit);
              }
            );
          }
        }

        this.props.dispatch(userDeviceWiseSelectedList(allData));

        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
    // .catch(e => {
    //   if (e && e.text) {
    //     e.text().then(err => {
    //       let a = err.split('SecurityException').splice(0, 1)
    //       //alert(a[0])
    //       this.props.dispatch(
    //         notifyError({
    //           message: a[0],
    //           autoDismiss: 10
    //         })
    //       )
    //     })
    //   }
    // })
  };

  onRemovedItem = (item) => {
    this.setState({
      deleteForm: { ...item[0] },
      onDeleteConfirmation: true,
    });
  };

  onRemovedExpenseItem = (item) => {
    this.setState({
      onDeleteExpenseConfirm: true,
    });
  };

  removeItem = () => {
    if (this.state.assignMode === "unit") {
      this.removeFromDatabase();
    } else if (this.state.assignMode === "user") {
      this.removeFromDatabase();
    }
    /* else if (this.state.assignMode === 'group') {

      this.removeFromDatabase();
    } */
  };

  removeFromDatabase = () => {
    let obj = this.state.deleteForm;
    let params = obj.id;
    if (obj) {
      if (obj.check !== undefined) {
        delete obj.check;
      }

      let call;
      if (this.state.assignMode === "user") {
        call = `/api/users/${params}`;
      } else if (this.state.assignMode === "unit") {
        call = `/api/devices/${params}`;
      }
      /* else if(this.state.assignMode === 'group') {
        call = `/api/groups/${params}`
      } */

      if (call) {
        instance({
          url: `${call}`,
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((response) => {
            // if (response.ok) {
            this.props.dispatch(removedUserDeviceWiseSelectedList(obj));
            let translationKey;
            if (this.state.assignMode === "user") {
              translationKey = `userIsRemoved`;
              this.props.dispatch(removeUser(obj));
            } else if (this.state.assignMode === "unit") {
              translationKey = `trackersIsDeleted`;
              this.props.dispatch(removeDevice(obj.id));
            }
            /* else if(this.state.assignMode === 'group') {
                translationKey = `groupDeleted`
                this.props.dispatch(removeGroup(obj))
              } */
            this.props.dispatch(
              notifySuccess(this.props.translate(translationKey))
            );
            this.setState({
              onDeleteConfirmation: false,
            });
            // }
            // else{
            //   throw response
            // }
          })
          .catch((error) => {
            errorHandler(error, this.props.dispatch);
          });
      }
    }
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
      onDeleteExpenseConfirm: false,
    });
  };

  saveToParent = (obj) => {
    this.setState(
      {
        maintenanceForm: {
          ...this.state.maintenanceForm,
          attributes: { ...this.state.maintenanceForm.attributes, ...obj },
        },
      },
      () => this.checkRequiredFields()
    );
  };

  render() {
    const endMessage =
      this.state.itemPagination && this.state.itemPagination.total > 0 ? (
        <p style={{ textAlign: "center", color: "#ccc" }}>
          {" "}
          -- {this.props.translate("end")} --{" "}
        </p>
      ) : (
        <p style={{ textAlign: "center", color: "#ccc" }}>
          {" "}
          {this.props.translate("notFound")}{" "}
        </p>
      );
    let image =
      this.state.maintenanceHistForm.files &&
      this.state.maintenanceHistForm.files.split(",");

    if (checkPrivileges("maintenance")) {
      return (
        <div>
          <Layout
            {...this.props}
            endMessage={endMessage}
            openCreateFrom={this.openCreateFrom}
            createExpense={this.createExpense}
            editHistoryFrom={this.editHistoryFrom}
            closeCreateFrom={this.closeCreateFrom}
            classFromChildren="has-padding"
            editMaintenanceForm={this.editMaintenanceForm}
            fetchMoreItems={this.fetchMoreItems}
            fetchMoreItemsHist={this.fetchMoreItemsHist}
            searchItems={this.searchItems}
            {...this.state}
          >
            <div className="main-content-page">
              <div id="HideMe" />

              {/* <SingleItem collection="devices3" field="id" value={1536}>{
                (item) => {
                  return null;
                }
              }
              </SingleItem> */}
              {this.state.onDeleteConfirmation && (
                <ConfirmDialoag
                  onCancel={this.onCancel}
                  onOk={this.removeItem}
                  title={this.props.translate("areYouWantToDelete")}
                  children={
                    this.state.deleteForm.name ||
                    this.state.deleteForm.description
                  }
                />
              )}
              {this.state.onDeleteConfirm && (
                <ConfirmDialoag
                  onCancel={this.onCancelMaintenance}
                  onOk={this.onDeleteMaintenance}
                  title={this.props.translate("areYouWantToDelete")}
                  children={this.state.itemDeleteName}
                />
              )}
              {this.state.onStatusChange && (
                <ConfirmDialoag
                  onCancel={this.onCancelStatus}
                  onOk={this.onChangeStatus}
                  OkText={"Yes"}
                  CancelText={"No"}
                  title={this.props.translate("areYouWantChangeStatus")}
                  children={this.state.itemStatusChange}
                />
              )}

              {this.state.onDeleteExpense && (
                <ConfirmDialoag
                  onCancel={this.onCancelExpense}
                  onOk={this.onDeleteExpenseItem}
                  title={this.props.translate("areYouWantToDelete")}
                  children={this.state.itemDeleteExpense}
                />
              )}
              {this.state.enableImagePopup && (
                <CustomDialog
                  title={this.props.translate("Images")}
                  themecolors={this.props.themecolors}
                  visable={true}
                  onClose={this.onCloseModal}
                  bodyPadding={10}
                  cancelText={this.props.translate("sharedCancel")}
                  noFullScreen
                >
                  {this.state.maintenanceHistForm &&
                  this.state.maintenanceHistForm.files ? (
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                      <ImageList cols={2.5} style={{ flexWrap: "nowrap" }}>
                        {image.map(
                          (item, i) =>
                            item && (
                              <ImageListItem
                                key={item}
                                style={{ height: "auto", width: "auto" }}
                              >
                                <a href={item} download target="_blank">
                                  <Tooltip
                                    classes={{
                                      popper: "menu-popper",
                                      tooltip: "menu-popper-tooltip",
                                    }}
                                    title={this.props.translate("DOWNLOAD")}
                                  >
                                    <FileCopyIcon
                                      style={{
                                        width: 80,
                                        height: 80,
                                        color: "#ffffff",
                                      }}
                                    />
                                  </Tooltip>
                                </a>
                                <p
                                  style={{ textAlign: "center", marginTop: 0 }}
                                >
                                  File {i + 1}
                                </p>
                              </ImageListItem>
                            )
                        )}
                      </ImageList>
                    </div>
                  ) : (
                    <h3 style={{ display: "flex", justifyContent: "center" }}>
                      No Image Selected
                    </h3>
                  )}
                </CustomDialog>
              )}
              {this.state.mode === "create" || this.state.mode === "update" ? (
                <div
                  style={{
                    background: this.props.themecolors.backgroundColor,
                    color: this.props.themecolors.textColor,
                    borderRadius: 6,
                    padding: 16,
                  }}
                >
                  <CreateMaintenanceForm
                    vehicleTracker={this.state.vehicleTracker}
                    selectedUnit={this.state.selectedUnit}
                    mode={this.state.mode}
                    form={this.state.maintenanceForm}
                    isVisible={this.state.isVisible}
                    servicesAttributes={servicesAttributes}
                    translate={this.props.translate}
                    themecolors={this.props.themecolors}
                    logInUser={this.props.logInUser}
                    dispatch={this.props.dispatch}
                    saveToParent={this.saveToParent}
                    assignModal={this.assignModal}
                    submitForm={this.submitForm}
                    closeCreateFrom={this.closeCreateFrom}
                    handleChangeAttributes={this.handleChangeAttributes}
                    handleChange={this.handleChange}
                    onChangedDevice={this.onChangedDevice}
                    uploadImage={this.uploadImage}
                    imagePopup={this.imagePopup}
                    subTypes={this.state.subTypes2 || []}
                  />
                </div>
              ) : null}

              {this.state.mode === "updateHist" ? (
                <div
                  style={{
                    background: this.props.themecolors.backgroundColor,
                    color: this.props.themecolors.textColor,
                    borderRadius: 6,
                    padding: 16,
                  }}
                >
                  <CreateExpense
                    expenseForm={this.state.expenseForm}
                    vehicleTracker={this.state.vehicleTracker}
                    selectedUnit={this.state.selectedUnit}
                    isVisible={this.state.isVisible}
                    serviceExpenseCollection={
                      this.state.serviceExpenseCollection
                    }
                    mode={this.state.dueActive}
                    image64Type={this.state.image64Type}
                    dueActive={this.state.dueActive}
                    loader={this.state.loader}
                    drivers={driversList}
                    vehicles={vehiclesList}
                    subTypes={subTypes}
                    expenseType={expenseType}
                    themecolors={this.props.themecolors}
                    translate={this.props.translate}
                    logInUser={this.props.logInUser}
                    handleChangeExpense={this.handleChangeExpense}
                    onChangedDevice={this.onChangedDevice}
                    closeExpense={this.closeExpense}
                    closeCreateFrom={this.closeCreateFrom}
                    submitExpense={this.ud_expenseSubmit}
                    addMore={this.addMore}
                    uploadImage={this.uploadImage}
                    handleChangeServices={this.handleChangeServices}
                    deleteRow={this.deleteRow}
                    imagePopup={this.imagePopup}
                    uploadFile={this.uploadFile}
                    deleteImage={this.deleteImage}
                    check
                  />
                </div>
              ) : null}

              {this.state.mode === "showMaintenance" ? (
                <div
                  style={{
                    background: this.props.themecolors.backgroundColor,
                    color: this.props.themecolors.textColor,
                    borderRadius: 6,
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  <MaintenanceForm
                    data={this.state.itemPagination}
                    dueActive={this.state.dueActive}
                    isDataRecieved={this.state.isDataRecieved}
                    mode={this.state.mode}
                    translate={this.props.translate}
                    themecolors={this.props.themecolors}
                    logInUser={this.props.logInUser}
                    setPagination={this.setPagination}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    handleChangePage={this.handleChangePage}
                    SearchItem={this.SearchItem}
                    openCreateFrom={this.openCreateFrom}
                    onRemove={this.onRemovedMaintenance}
                    onStatusChange={this.onStatusChange}
                    switchMode={this.switchMode}
                    editMaintenanceForm={this.editMaintenanceForm}
                  />
                </div>
              ) : null}

              {this.state.mode === "history" || this.state.mode === "due" ? (
                <div
                  style={{
                    background: this.props.themecolors.backgroundColor,
                    color: this.props.themecolors.textColor,
                    borderRadius: 6,
                    paddingLeft: 0,
                    paddingRight: 0,
                  }}
                >
                  <MaintenanceHistoryForm
                    data={this.state.itemsPagination}
                    dueActive={this.state.dueActive}
                    isDataRecieved={this.state.isDataRecieved}
                    countChecked={this.state.countChecked}
                    statusFilter={this.state.statusFilter}
                    anchorEl={this.state.anchorEl}
                    mode={this.state.mode}
                    expenseForm={this.state.expenseForm}
                    searchVehicleId={this.state.searchVehicleId}
                    // vehicles={vehiclesList}
                    // histFormVisible={this.state.histFormVisible}
                    translate={this.props.translate}
                    vehicles={this.props.vehicles}
                    deviceRelatedData={this.props.deviceRelatedData}
                    themecolors={this.props.themecolors}
                    logInUser={this.props.logInUser}
                    setPagination={this.setPaginationHistory}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    handleChangePage={this.handleChangePage}
                    SearchItem={this.SearchItem}
                    SearchVehicle={this.SearchVehicle}
                    openCreateFrom={this.openCreateFrom}
                    handleChangeFilter={this.handleChangeFilter}
                    handleClickFilter={this.handleClickFilter}
                    handleCloseFilter={this.handleCloseFilter}
                    imagePopup={this.imagePopup}
                    switchMode={this.switchMode}
                    onRemove={this.onRemovedExpense}
                    editHistoryFrom={this.editHistoryFrom}
                    createExpense={this.createExpense}
                  />
                </div>
              ) : null}

              {this.state.mode === "createExpense" ? (
                <div
                  style={{ height: "100%", position: "relative", zIndex: 9999 }}
                >
                  <CreateExpense
                    expenseForm={this.state.expenseForm}
                    vehicleTracker={this.state.vehicleTracker}
                    selectedUnit={this.state.selectedUnit}
                    isVisible={this.state.isVisible}
                    serviceExpenseCollection={
                      this.state.serviceExpenseCollection
                    }
                    mode={this.state.dueActive}
                    image64Type={this.state.image64Type}
                    loader={this.state.loader}
                    dueActive={this.state.dueActive}
                    drivers={driversList}
                    vehicles={vehiclesList}
                    subTypes={subTypes}
                    expenseType={expenseType}
                    themecolors={this.props.themecolors}
                    translate={this.props.translate}
                    logInUser={this.props.logInUser}
                    handleChangeExpense={this.handleChangeExpense}
                    onChangedDevice={this.onChangedDevice}
                    closeExpense={this.closeExpense}
                    closeCreateFrom={this.closeCreateFrom}
                    submitExpense={this.submitExpense}
                    addMore={this.addMore}
                    uploadImage={this.uploadImage}
                    handleChangeServices={this.handleChangeServices}
                    deleteRow={this.deleteRow}
                    imagePopup={this.imagePopup}
                    uploadFile={this.uploadFile}
                    deleteImage={this.deleteImage}
                    check={false}
                  />
                </div>
              ) : null}

              {this.state.model ? (
                <Dialog
                  isVisableBtn={true}
                  headerActions={
                    <Button
                      onClick={this.submitAssignModel}
                      variant="outlined"
                      size="small"
                    >
                      {this.props.translate("assignMaintenance")}
                    </Button>
                  }
                  open={this.state.model}
                  onClose={this.closeAssignModal}
                  title={this.props.translate("assignMaintenance")}
                  disableFooter
                >
                  <>
                    <div
                      style={{ padding: 16, fontWeight: 700, paddingBottom: 0 }}
                    >
                      <MaintenanceIcon
                        fill="currentColor"
                        width={20}
                        height={20}
                        style={{ verticalAlign: "middle", marginRight: 5 }}
                      />{" "}
                      {this.state.currentMaintenance &&
                        this.state.currentMaintenance.name}
                    </div>
                    {this.state.assignMode === "unit" ? (
                      <ItemsSelector
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        rowsPerPage={this.state.rowsPerPage}
                        selectAll={(e) =>
                          selectAll(
                            e,
                            "deviceId",
                            "maintenanceId",
                            this.state.currentMaintenance.id,
                            this.props.selecteduser,
                            this.props.devices3,
                            this.props.dispatch,
                            "access",
                            this.props.translate
                          )
                        }
                        parent={0}
                        item_Selection={this.item_Selection}
                        themecolors={this.props.themecolors}
                        data={this.props.selecteduser}
                        translate={this.props.translate}
                        onDelete={this.onRemovedItem}
                        canDelete={checkPrivileges("deviceDelete")}
                        canRemove={checkPrivileges("deviceLinkMaintenance")}
                        canAssign={checkPrivileges("deviceUnlinkMaintenance")}
                      />
                    ) : null}

                    {this.state.assignMode === "user" ? (
                      <ItemsSelector
                        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                        rowsPerPage={this.state.rowsPerPage}
                        selectAll={(e) =>
                          selectAll(
                            e,
                            "userId",
                            "maintenanceId",
                            this.state.currentMaintenance.id,
                            this.props.selecteduser,
                            this.props.users,
                            this.props.dispatch,
                            "access",
                            this.props.translate
                          )
                        }
                        parent={0}
                        item_Selection={this.item_Selection}
                        themecolors={this.props.themecolors}
                        data={this.props.selecteduser}
                        translate={this.props.translate}
                        onDelete={this.onRemovedItem}
                        canDelete={checkPrivileges("userDelete")}
                        canRemove={checkPrivileges("userLinkMaintenance")}
                        canAssign={checkPrivileges("userUnlinkMaintenance")}
                        rowDefinition={[
                          {
                            id: "name",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("sharedName"),
                          },
                          {
                            id: "email",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("userEmail"),
                          },
                          {
                            id: "roleName",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("roleName"),
                          },
                          {
                            id: "parentName",
                            numeric: false,
                            disablePadding: false,
                            label: this.props.translate("parentName"),
                          },
                        ]}
                      />
                    ) : null}
                  </>
                </Dialog>
              ) : null}
              {this.state.mode === "" ? (
                <EmptyState
                  text={this.props.translate("noMaintenanceSelected")}
                />
              ) : null}
            </div>
          </Layout>
        </div>
      );
    } else {
      return null;
    }
  }
}
const mapStateToProps = (state) => ({
  maintenance: state.maintenance,
  users: state.users,
  groups: state.groups,
  selecteduser: state.selecteduser,
  roles: state.roles,
  deviceRelatedData: state.deviceRelatedData,
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
  drivers: state.drivers,
  vehicles: state.vehicles,
});

export default connect(mapStateToProps)(withTranslationWrapper(Maintenance));
