import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import { userAttributesTypes } from "./../Attributes/AttributesArrays";
import PinIcon from "./../../assets/nav/pin.svg";
import EmailIcon from "./../../assets/nav/email.svg";
import CallIcon from "./../../assets/nav/phone-call.svg";
import moment from "moment";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Style from "style-it";
import Scrollbar from "react-scrollbars-custom";
import Icon from "@mui/material/Icon";
import { setAttributeFormat } from "../../Helpers";
import TimeAgo from "react-timeago";
import parse from "html-react-parser";
import { ReactComponent as FileCopyIcon } from "./../../assets/monitoring/copy.svg";
import { notifySuccess } from "../../Utils/CustomNotifcations";
import Tooltip from "../common/Tooltip";
import withTranslationWrapper from "../../HOC/withTranslation";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    position: "relative",
    // padding: theme.spacing(1) * 3,
    // marginBottom: theme.spacing(1) * 2,
    boxShadow: "none",
    background: "none",
    [theme.breakpoints.down("sm")]: {
      // padding: theme.spacing(1),
      // marginBottom: theme.spacing(1),
    },
  },
  nested: {
    // paddingLeft: theme.spacing(1) * 4,
  },
});
class editItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: [],
    };
    this.formatObject = this.formatObject.bind(this);
  }

  componentDidMount() {
    this.setState({ selectedItem: this.formatObject(this.props) });
  }
  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops) {
      this.setState({ selectedItem: this.formatObject(nextprops) });
    }
  }
  setIcons = (data) => {
    let logo;
    if (data.category) {
      this.props.devicesIcons.map((item) => {
        let res = item.includes(data.category);
        if (res) {
          logo = item;
        }
        return null;
      });
    } else {
      this.props.devicesIcons.map((item) => {
        let res = item.includes("default");
        if (res) {
          logo = item;
        }
        return null;
      });
    }
    return logo;
  };
  drawValue(array, classes) {
    let cat = [];
    let htmlObject = [];
    let html = null;
    let mainKey = "";

    if (array.length) {
      html = array.map((item, key) => {
        switch (item.key) {
          case "name":
            mainKey = this.props.translate("sharedName");
            break;
          case "login":
            mainKey = this.props.translate("loginTitle");
            break;
          case "email":
            mainKey = this.props.translate("userEmail");
            break;
          case "phone":
            mainKey = this.props.translate("sharedPhone");
            break;
          case "readonly":
            mainKey = this.props.translate("serverReadonly");
            break;
          case "administrator":
            mainKey = this.props.translate("userAdmin");
            break;
          case "map":
            mainKey = this.props.translate("mapTitle");
            break;
          case "twelveHourFormat":
            mainKey = this.props.translate("settingsTwelveHourFormat");
            break;
          case "coordinateFormat":
            mainKey = this.props.translate("settingsCoordinateFormat");
            break;
          case "disabled":
            mainKey = this.props.translate("sharedDisabled");
            break;
          case "expirationTime":
            mainKey = this.props.translate("userExpirationTime");
            break;
          case "deviceReadonly":
            mainKey = this.props.translate("userDeviceReadonly");
            break;
          case "token":
            mainKey = this.props.translate("userToken");
            break;
          case "limitCommands":
            mainKey = this.props.translate("userLimitCommands");
            break;
          case "poiLayer":
            mainKey = this.props.translate("mapPoiLayer");
            break;
          case "password":
            mainKey = this.props.translate("attributeDevicePassword");
            break;
          case "attributes":
            mainKey = this.props.translate("sharedAttributes");
            break;
          case "trackers":
            mainKey = this.props.translate("deviceTitle");
            break;
          default:
            mainKey = this.props.translate(item.key);
            break;
        }

        if (item.key !== "name") {
          if (typeof item.value === "string" || item.value === null) {
            return (
              <Grid item xs={12} key={key}>
                <span className="row-label">{mainKey}</span>
                <span className="row-value">{item.value || "null"}</span>
              </Grid>
            );
          }
          if (typeof item.value === "boolean") {
            return (
              <Grid item xs={12} key={key}>
                <span className="row-label">{mainKey}</span>
                <span className="row-value">{item.value ? "Yes" : "No"}</span>
              </Grid>
            );
          } else if (typeof item.value === "object") {
            if (item.key === "trackers") {
              /* let h = null
              if (item.value.length) {
                h = item.value.map(k => {
                  return (
                    <li key={k.id}>
                      {
                        <img
                          className='row-image'
                          src={
                            '/assets/category/default/' +
                            (k.category || 'default') +
                            '.svg'
                          }
                          alt={k.label}
                        />
                      }

                      <span className='row-label'>{k.label}</span>
                      <span className='row-value'>{k.uniqueId}</span>
                    </li>
                  )
                })

                let head = (
                  <div key={item.key}>
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: 700,
                        margin: '0 0 15px'
                      }}
                      className='with-border'
                    >
                      {this.props.translate('deviceTitle')}
                    </p>
                    <ul className='trackers-list clearfix'>{h}</ul>
                  </div>
                )
                cat.push(head)
                return null
              } */
            } else {
              let o = Object.keys(item.value);
              let h = null;
              if (o.length) {
                let hasData = false;
                h = o.map((k) => {
                  if (["driverAddress", "driverLat", "driverLon"].includes(k)) {
                    hasData = true;
                    return (
                      <li key={k}>
                        <span className="row-label">
                          {this.props.translate(k)}
                        </span>
                        <span className="row-value">
                          {parse(item.value[k])}
                        </span>
                      </li>
                    );
                  } else if (
                    k !== "address" &&
                    k !== "email" &&
                    k !== "licenseClass" &&
                    k !== "department" &&
                    k !== "licenseNumb" &&
                    k !== "licenseExpDate" &&
                    k !== "phone" &&
                    k !== "driverImg"
                  ) {
                    hasData = true;
                    const selectedTrans = userAttributesTypes.filter(
                      (el) => el.key === k
                    );
                    if (typeof item.value[k] === "boolean") {
                      return (
                        <li key={k}>
                          <span className="row-label">
                            {selectedTrans && selectedTrans[0]
                              ? this.props.translate(selectedTrans[0].name)
                              : k}
                          </span>
                          <span className="row-value">
                            {item.value[k] ? "Yes" : "No"}
                          </span>
                        </li>
                      );
                    } else {
                      let kk = "";
                      if (
                        k === "tag_1" &&
                        this.props.logInUsers &&
                        this.props.logInUsers.attributes &&
                        this.props.logInUsers.attributes.st1
                      ) {
                        kk = this.props.logInUsers.attributes.st1;
                      }
                      if (
                        k === "tag_2" &&
                        this.props.logInUsers &&
                        this.props.logInUsers.attributes &&
                        this.props.logInUsers.attributes.st2
                      ) {
                        kk = this.props.logInUsers.attributes.st2;
                      }
                      if (
                        k === "tag_3" &&
                        this.props.logInUsers &&
                        this.props.logInUsers.attributes &&
                        this.props.logInUsers.attributes.st3
                      ) {
                        kk = this.props.logInUsers.attributes.st3;
                      }
                      if (
                        k === "tag_4" &&
                        this.props.logInUsers &&
                        this.props.logInUsers.attributes &&
                        this.props.logInUsers.attributes.st4
                      ) {
                        kk = this.props.logInUsers.attributes.st4;
                      }
                      if (
                        k === "tag_5" &&
                        this.props.logInUsers &&
                        this.props.logInUsers.attributes &&
                        this.props.logInUsers.attributes.st5
                      ) {
                        kk = this.props.logInUsers.attributes.st5;
                      }
                      return (
                        <li key={k}>
                          <span className="row-label">
                            {kk
                              ? kk
                              : selectedTrans && selectedTrans[0]
                              ? this.props.translate(selectedTrans[0].name)
                              : this.props.translate(k)}
                          </span>
                          <span className="row-value">
                            {k === "dateOfBirthGregorian"
                              ? item &&
                                item.value &&
                                moment(item.value[k]).format("DD-MM-YYYY")
                              : item.value[k]}
                          </span>
                        </li>
                      );
                    }
                  }
                  return null;
                });
                let head = hasData ? (
                  <div>
                    <p
                      style={{
                        fontSize: 14,
                        fontWeight: 700,
                        margin: "0 0 15px",
                      }}
                      className="with-border"
                    >
                      {mainKey}
                    </p>
                    <ul className="attribute-list clearfix">{h}</ul>
                  </div>
                ) : null;
                htmlObject.push(head);
                return null;
              }
              return null;
            }
          } else {
            return null;
          }
        } else {
          return null;
        }

        return null;
      });
    }

    return { html, htmlObject, cat };
  }

  formatObject(props) {
    if (props.selectedItem) {
      let normalValues = [];
      let objValues = [];
      Object.keys(props.selectedItem).map((itm, key) => {
        if (
          typeof props.selectedItem[itm] !== "object" ||
          props.selectedItem[itm] === null
        ) {
          normalValues.push({ key: itm, value: props.selectedItem[itm] });
        } else {
          objValues.push({ key: itm, value: props.selectedItem[itm] });
        }
        return null;
      });

      /*let newAtt = objValues[0].value
      Object.keys(newAtt).map((key, index) => {
        if (key === 'address' || key === 'email' || key === 'licenseClass' ||
          key === 'licenseNumb' || key === 'licenseExpDate' || key === 'phone' || key === 'department') {
          objLikeNormalValue.push({ key: key, value: newAtt[key] })
        }
      });*/

      //normalValues = normalValues.concat(objLikeNormalValue)
      return normalValues.concat(objValues);
    } else {
      return [];
    }
  }

  copyToClipboard = (obj) => {
    const el = document.createElement("textarea");
    el.value = `${obj.latitude},${obj.longitude}`;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.props.dispatch(notifySuccess("Copied to clipboard!"));
  };

  render() {
    const { classes } = this.props;

    const newSelected = {};
    this.state.selectedItem.map((e) => (newSelected[e.key] = e.value));

    return (
      <Style>
        {`
        .feature-close-button {
          position: absolute;
          margin-top: 6px;
          color: inherit;
          right: 15px;
          top: 3px;
          cursor: pointer;
        }
      .material-icons {vertical-align: middle; color: inherit}
      
      .license-card-img {
        background: ${this.props.themecolors.textColor};
      }

      .attribute-list .row-label {
        color: ${this.props.themecolors.textColor};
      }
      .attribute-list .row-value {
        color: ${this.props.themecolors.textColor};
      }
      
      .trackers-list li {
        background: ${this.props.themecolors.backgroundColor};
      }
     
      .license-card-detail {
        border-bottom: 1px solid ${this.props.themecolors.themeLightColor} 
      }
      `}
        <div className={classes.root + " driver-modal-fixed"}>
          <Paper
            style={{
              height: "100%",
              background: this.props.themecolors.backgroundColor,
              color: this.props.themecolors.textColor,
            }}
          >
            <Scrollbar
              disableTracksWidthCompensation
              contentProps={{ style: { padding: 16 } }}
            >
              {
                <div
                  style={{
                    margin: "5px",
                    marginBottom: "15px",
                    display: "flex",
                    width: "100%",
                  }}
                >
                  {newSelected.driverImg ? (
                    <img
                      style={{
                        width: 40,
                        height: 40,
                        borderRadius: "50%",
                        verticalAlign: "middle",
                      }}
                      alt="driverImage"
                      src={newSelected.driverImg}
                    />
                  ) : (
                    <AccountCircleIcon style={{ fontSize: 40 }} />
                  )}
                  <div style={{ paddingLeft: 15, flex: 1, minWidth: 0 }}>
                    <h3 style={{ margin: "0 0 5px" }}>{newSelected.name} </h3>
                    <Tooltip
                      classes={{
                        popper: "menu-popper",
                        tooltip: "menu-popper-tooltip",
                      }}
                      title={newSelected.uniqueId}
                    >
                      <span
                        style={{
                          display: "block",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {this.props.translate(
                          "sensorsTranslation.driverUniqueId"
                        )}
                        {" : "}
                        {newSelected.uniqueId}
                      </span>
                    </Tooltip>
                    <CloseIcon
                      className={"feature-close-button"}
                      onClick={this.props.handleClose}
                    />
                  </div>
                </div>
              }
              {newSelected.address || newSelected.email || newSelected.phone ? (
                <div
                  style={{
                    paddingBottom: 4,
                    borderBottom:
                      "1px solid " + this.props.themecolors.themeLightColor,
                    marginBottom: 30,
                  }}
                >
                  {newSelected.address && (
                    <div style={{ display: "flex", paddingBottom: 16 }}>
                      <strong>
                        <svg fill="currentColor" width={14} height={14}>
                          <use xlinkHref={`${PinIcon}#icon`} />
                        </svg>
                      </strong>{" "}
                      &nbsp;
                      <span style={{ marginLeft: 10 }}>
                        {parse(newSelected.address)}
                      </span>
                    </div>
                  )}
                  {newSelected.email && (
                    <div style={{ display: "flex", paddingBottom: 16 }}>
                      <strong>
                        <svg fill="currentColor" width={14} height={14}>
                          <use xlinkHref={`${EmailIcon}#icon`} />
                        </svg>
                      </strong>{" "}
                      &nbsp;
                      <span style={{ marginLeft: 10 }}>
                        {newSelected.email}
                      </span>
                    </div>
                  )}
                  {newSelected.phone && (
                    <div style={{ display: "flex", paddingBottom: 16 }}>
                      <strong>
                        <svg fill="currentColor" width={14} height={14}>
                          <use xlinkHref={`${CallIcon}#icon`} />
                        </svg>
                      </strong>{" "}
                      &nbsp;
                      <span style={{ marginLeft: 10 }}>
                        {newSelected.phone}
                      </span>
                    </div>
                  )}
                </div>
              ) : null}
              {this.state.selectedItem &&
                this.drawValue(this.state.selectedItem, classes).htmlObject}

              <p
                style={{
                  fontSize: 14,
                  fontWeight: 700,
                  margin: "0 0 15px",
                }}
                className="with-border"
              >
                {this.props.translate("licenseInfo")}
              </p>

              <div className="license-card-detail">
                <p>
                  <span className="row-label">
                    {this.props.translate("licenseClass")}
                  </span>
                  <span className="row-value">{newSelected.licenseClass}</span>
                </p>
                <p>
                  <span className="row-label">
                    {this.props.translate("driverLicenseNumber")}
                  </span>
                  <span className="row-value">{newSelected.licenseNumb}</span>
                </p>
                <p>
                  <span className="row-label">
                    {this.props.translate("department")}
                  </span>
                  <span className="row-value">{newSelected.department}</span>
                </p>
                <p>
                  <span className="row-label">
                    {this.props.translate("D/LExpirationTime")}
                  </span>
                  <span className="row-value">
                    {(newSelected.licenseExpDate &&
                      moment(newSelected.licenseExpDate).format(
                        "DD-MM-YYYY"
                      )) ||
                      null}
                  </span>
                </p>
                <p>
                  <span className="row-label">
                    {this.props.translate("expirationTime")}
                  </span>
                  <span className="row-value">
                    {(newSelected.expirationTime &&
                      moment(newSelected.expirationTime).format(
                        "DD-MM-YYYY"
                      )) ||
                      null}
                  </span>
                </p>
              </div>
              {this.state.selectedItem &&
                this.drawValue(this.state.selectedItem, classes).cat}

              {this.props.currentLocation && (
                <>
                  <p
                    style={{
                      fontSize: 14,
                      fontWeight: 700,
                      margin: "0 0 15px",
                    }}
                    className="with-border"
                  >
                    {this.props.translate("currentLocation")}
                  </p>
                  <p>
                    <span className="row-label">
                      {this.props.translate("settingsCoordinates")}
                    </span>
                    <span className="row-value">
                      {setAttributeFormat(
                        "latitude",
                        this.props.currentLocation.latitude
                      )}
                      ,
                      {setAttributeFormat(
                        "longitude",
                        this.props.currentLocation.longitude
                      )}
                      {
                        <button
                          onClick={(e) =>
                            this.copyToClipboard(this.props.currentLocation)
                          }
                          className="button-to-normal"
                        >
                          <FileCopyIcon
                            fill="currentColor"
                            width="14"
                            height="14"
                          />
                        </button>
                      }
                    </span>
                  </p>
                  <p>
                    <span className="row-label">
                      {this.props.translate("driverAddress")}
                    </span>
                    <span className="row-value">
                      {parse(this.props.currentLocation.address)}
                    </span>
                  </p>
                  <p>
                    <span className="row-label">
                      {this.props.translate("gpsUpdated")}
                    </span>
                    <span className="row-value">
                      <TimeAgo
                        date={this.props.currentLocation.serverTime}
                        minPeriod={60}
                      />
                    </span>
                  </p>
                </>
              )}
            </Scrollbar>
          </Paper>
        </div>
      </Style>
    );
  }
}

editItem.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapState = (state) => {
  return {
    themecolors: state.themeColors,
    logInUsers: state.logInUsers,
  };
};
const mapStateToProps = connect(mapState);
export const EditItem = mapStateToProps(
  withTranslationWrapper(withStyles(styles)(editItem))
);
