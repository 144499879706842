import React, { Component } from "react";
import SimpleModalWrapped from "../common/Modal";
import TextField from "../common/TextField";
import MenuItem from "../common/MenuItem";
import Grid from "@mui/material/Grid";

import {
  ownerUserType,
  dealerUserType,
  adminUserType,
  serviceProviderUserType,
  adminAccountUserType,
  measurementStandard,
} from "./userDataTypes";
import "./usersModal.scss";
import { checkPrivileges } from "../../Helpers";
import Divider from "@mui/material/Divider";
import { connect } from "react-redux";
import { timeZonesTypes } from "../Attributes/timezone";
import instance from "../../axios";
import withTranslationWrapper from "../../HOC/withTranslation";

//let themecolors;
const mapStateToProps = (state, ownProps) => {
  //themecolors = state.themeColors;
  return {
    logInUsers: state.logInUsers,
    themecolors: state.themeColors,
    roleTypes:
      ownProps.viewScreen === "add"
        ? state.roles.filter((item) => item.id > 7)
        : state.roles,
  };
};

class addUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      at_modal_visable: false,
      open: props.viewScreen === "add" ? true : false,
      resultMessage: "",
      showButton: this.props.editOption ? true : false,
    };
    this.addAttributes = this.addAttributes.bind(this);
    this.selectMe = this.selectMe.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.searchFromZoneList = this.searchFromZoneList.bind(this);
  }

  toggleCollapse() {
    let a = this.state.open;
    this.setState({
      open: !a,
    });
  }

  toggleRoles = () => {
    this.setState({
      showButton: !this.state.showButton,
    });
  };

  addAttributes() {
    this.setState({
      at_modal_visable: !this.state.at_modal_visable,
    });
  }

  selectMe(element) {
    var doc = document,
      text = element.target,
      range,
      selection;
    if (doc.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(text);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(text);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }

  async searchFromZoneList(inputValue) {
    if (!inputValue) {
      return [];
    }

    let result = timeZonesTypes.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    return result.map((item) => {
      return {
        id: item.key,
        key: item.key,
        label: item.label,
        value: item.key,
      };
    });
  }
  searchAccountsList = async (inputValue) => {
    if (!inputValue) {
      return [];
    }
    let params = {
      accountId: this.props.logInUser.accountId,
      search: inputValue,
      limit: 100,
    };
    if (this.props.logInUser.userType === -1) {
      params = {
        ...params,
        all: true,
      };
    }

    let response = instance({
      url: `/api/accounts/all`,
      method: "GET",
      params: {
        ...params,
      },
    });
    // let json = await response.json()
    let result =
      //   (await json.data) &&
      response.data.map((item) => ({
        ...item,
        label: item.label || item.name,
        value: item.id,
      }));
    if (result.length === 0) {
      this.setState({
        resultMessage: "No option found",
      });
    } else {
      this.setState({
        resultMessage: "",
      });
    }

    if (response.hasNext) {
      result.push({
        value: null,
        isDisabled: true,
        label: "Search for more.",
      });
    }
    return result;
  };
  searchUsersList = async (inputValue) => {
    if (!inputValue) {
      return [];
    }
    let fetchapi = `//api/users/list?accountId=${this.props.form.accountId}&search=${inputValue}&limit=100`;

    let response = instance({
      url: `/api/users/list`,
      method: "GET",
      params: {
        accountId: this.props.form.accountId,
        search: inputValue,
        limit: 100,
      },
    });
    // let json = await response.json()
    let result =
      //   (await json.data) &&
      response.data.map((item) => ({
        ...item,
        label: item.label || item.name,
        value: item.id,
      }));
    if (result.length === 0) {
      this.setState({
        resultMessage: "No option found",
      });
    } else {
      this.setState({
        resultMessage: "",
      });
    }

    if (response.hasNext) {
      result.push({
        value: null,
        isDisabled: true,
        label: "Search for more.",
      });
    }
    return result;
  };

  render() {
    let defaultZones = [];
    timeZonesTypes.map((item, i) => {
      // if (i <= 9) {
      defaultZones.push({
        id: item.key,
        key: item.key,
        label: item.label,
        value: item.key,
      });
      // }
      return null;
    });
    let userTypeArray = [];
    if (this.props.userType) {
      let { userType } = this.props;
      if (userType === -1) {
        userTypeArray = ownerUserType;
      } else if (userType === 2) {
        userTypeArray = adminUserType;
      } else if (userType === 3) {
        userTypeArray = adminAccountUserType;
      } else if (userType === 4) {
        userTypeArray = serviceProviderUserType;
      } else if (userType === 5) {
        userTypeArray = dealerUserType;
      } else {
        userTypeArray = [];
      }
    }
    const empty = null;
    return (
      <div>
        <SimpleModalWrapped
          visable={true}
          title={"sharedRequired"}
          isButtonVisable={true}
          notShowAttributesBtn
          formSubmit={this.props.formSubmit}
          addAttributes={this.addAttributes}
          modalControle={this.props.modalControle}
          buttonText={this.props.buttonText}
          btnCloseText="sharedBack"
          isVisableBtn={
            this.props.isVisableUserBtn && checkPrivileges("userUpdate")
          }
          // isVisableBtn={checkPrivileges("userUpdate")}
          hasPremissions
          canUpdate={this.props.canUpdate}
          attributeChangesMessage={this.props.attributeChangesMessage}
          btnCancelNotShow={true}
          content={
            <>
              <Grid container spacing={2}>
                <Grid item xl={12} sm={12} md={12} xs={12}>
                  <TextField
                    id="name"
                    label={this.props.translate("sharedName")}
                    type="text"
                    required
                    placeholder=""
                    value={this.props.form.name || ""}
                    onChange={this.props.handleChange("name")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item xl={12} sm={12} md={12} xs={12}>
                  <TextField
                    id="email"
                    error={this.props.duplicateEntry}
                    label={this.props.translate("userEmail")}
                    type="text"
                    required
                    placeholder=""
                    value={this.props.form.email || ""}
                    onChange={this.props.handleChange("email")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>

                {!this.props.editOption ? (
                  <>
                    <Grid item xl={12} sm={12} md={12} xs={12}>
                      <TextField
                        id="password"
                        label={this.props.translate("userPassword")}
                        type="password"
                        required
                        placeholder=""
                        value={this.props.form.password || ""}
                        onChange={this.props.handleChange("password")}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xl={12} sm={12} md={12} xs={12}>
                      <TextField
                        id="confirmPassword"
                        label={this.props.translate("confirmPassword")}
                        type="password"
                        error={
                          this.props.form.password ===
                          this.props.form.confirmPassword
                            ? false
                            : true
                        }
                        required
                        placeholder=""
                        value={this.props.form.confirmPassword}
                        onChange={this.props.handleChange("confirmPassword")}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                  </>
                ) : null}
                {this.props.viewScreen !== "edit" && (
                  <>
                    <Grid item xl={12} sm={12} md={12} xs={12}>
                      <TextField
                        id="userType"
                        select
                        required
                        label={this.props.translate("userType")}
                        value={this.props.form.userType || ""}
                        onChange={this.props.handleChange("userType")}
                        helperText={
                          this.props.roleTypes.length ? (
                            <span
                              onClick={this.toggleRoles}
                              style={{ cursor: "pointer" }}
                            >
                              <b>Select Custom Role</b>
                            </span>
                          ) : (
                            ""
                          )
                        }
                        margin="dense"
                        fullWidth
                      >
                        <MenuItem value="">
                          <em>{this.props.translate("none")}</em>
                        </MenuItem>
                        {userTypeArray.length &&
                          userTypeArray.map((option) => (
                            <MenuItem key={option.key} value={option.key}>
                              {this.props.translate(option.name)}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  </>
                )}

                {checkPrivileges("role") && this.state.showButton && (
                  <Grid item xl={12} sm={12} md={12} xs={12}>
                    <TextField
                      id="role"
                      select
                      label={this.props.translate("role")}
                      value={this.props.form.roleId || ""}
                      onChange={this.props.handleChange("roleId")}
                      margin="dense"
                      fullWidth
                    >
                      {!this.props.editOption && (
                        <MenuItem key={"defaultRole"} value={"defaultRole"}>
                          <em>{this.props.translate("default")}</em>
                        </MenuItem>
                      )}
                      {!this.props.editOption && (
                        <Divider style={{ background: "black" }} />
                      )}

                      {this.props.roleTypes.length ? (
                        this.props.roleTypes.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {this.props.translate(option.name)}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">
                          <em>{this.props.translate("none")}</em>
                        </MenuItem>
                      )}
                    </TextField>
                  </Grid>
                )}
                {/* {(checkPrivileges('userCreate') || checkPrivileges('userUpdate')) && (
                  <Grid item xl={3} sm={6} md={4} xs={12}>
                    <TextField
                      id='role'
                      select
                      label={this.props.translate('roleName')}
                      value={this.props.form.roleId || ''}
                      onChange={this.props.handleChange('roleId')}
                      // disabled={this.props.viewScreen === 'edit'}
                      margin='dense'
                      fullWidth
                    >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem> 
                      {this.props.roleTypes &&
                        this.props.roleTypes.map(option => (
                          <MenuItem key={option.id} value={option.id}>
                            {this.props.translate(option.name)}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                )} */}
              </Grid>
              {/* <h4> {this.props.translate("sharedPreferences")} </h4> */}
              {/* <Grid container spacing={2}>
                <Grid item xl={12} sm={12} md={12} xs={12}>
                  <TextField
                    id="phone"
                    label={this.props.translate("sharedPhone")}
                    type="text"
                    placeholder=""
                    value={this.props.form.phone || ""}
                    onChange={this.props.handleChange("phone")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                {this.props.viewScreen === "edit" && (
                  <Grid item xl={12} sm={12} md={12} xs={12}>
                    <TextField
                      id="map"
                      select
                      label={this.props.translate("mapTitle")}
                      value={this.props.form.map || ""}
                      onChange={this.props.handleChange("map")}
                      margin="dense"
                      fullWidth
                    >
                      <MenuItem value="">
                        <em>{this.props.translate("none")}</em>
                      </MenuItem>
                      {this.props.form &&
                        this.props.form.attributes.maps &&
                        this.props.form.attributes.maps.map((option) => (
                          <MenuItem key={option} value={option}>
                            {this.props.translate(option)}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                )}

                <Grid item xl={12} sm={12} md={12} xs={12}>
                  <TextField
                    id="coordinateFormat"
                    select
                    label={this.props.translate("settingsCoordinateFormat")}
                    value={this.props.form.coordinateFormat || ""}
                    onChange={this.props.handleChange("coordinateFormat")}
                    margin="dense"
                    fullWidth
                  >
                    <MenuItem value="">
                      <em>{this.props.translate("none")}</em>
                    </MenuItem>
                    {coordinateFormatTypes.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {this.props.translate(option.name)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xl={3} sm={6} md={4} xs={12}>
                  <TextField
                    id='poiLayer'
                    label={this.props.translate('mapPoiLayer')}
                    type='text'
                    placeholder=''
                    value={this.props.form.poiLayer || ''}
                    onChange={this.props.handleChange('poiLayer')}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
              </Grid> */}
              {/* <Grid container spacing={2}>
                <Grid item xl={12} sm={12} md={12} xs={12}>
                  <TextField
                    id="latitude"
                    type="number"
                    label={this.props.translate("positionLatitude")}
                    placeholder=""
                    value={this.props.form.latitude || ""}
                    onChange={this.props.handleChange("latitude")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item xl={12} sm={12} md={12} xs={12}>
                  <TextField
                    id="longitude"
                    type="number"
                    label={this.props.translate("positionLongitude")}
                    value={this.props.form.longitude || ""}
                    onChange={this.props.handleChange("longitude")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item xl={12} sm={12} md={12} xs={12}>
                  <TextField
                    id="zoom"
                    label={this.props.translate("serverZoom")}
                    type="number"
                    required
                    placeholder=""
                    error={
                      this.props.form.zoom > 19 || this.props.form.zoom < 3
                        ? true
                        : false
                    }
                    helperText="Enter Value 3 to 18"
                    value={this.props.form.zoom || ""}
                    onChange={this.props.handleChange("zoom")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item xl={12} sm={12} md={12} xs={12}>
                  <Tooltip title={this.props.translate("addLocation")}>
                    <IconButton onClick={this.props.handleClickShowPostion}>
                      {<EditIcon />}
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid> */}
              {this.props.form.userType && this.props.form.userType !== 1 && (
                <>
                  <h4> {this.props.translate("sharedPermissions")} </h4>
                  <Grid container spacing={2}>
                    {/* <Grid item xl={12} sm={12} md={12} xs={12}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          error={false}
                          margin="dense"
                          label={this.props.translate("userExpirationTime")}
                          variant="outlined"
                          fullWidth
                          minDate={new Date()}
                          value={
                            this.props.form.expirationTime
                              ? new Date(
                                  `${this.props.form.expirationTime}`
                                ).toString()
                              : null
                          }
                          onChange={this.props.handleChange("expirationTime")}
                          InputProps={{
                            classes: {
                              root: "theme-cssOutlinedInput",
                              input: "theme-input",
                              focused: "theme-cssFocused",
                              notchedOutline: "theme-notchedOutline",
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip
                                  title={this.props.translate("noLimit")}
                                >
                                  <IconButton
                                    onClick={(e) =>
                                      this.props.setExpirationTime(e)
                                    }
                                  >
                                    {<CrossIcons />}
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            classes: {
                              root: "theme-label",
                              focused: "theme-label-focused",
                              shrink: "theme-label-shrink",
                            },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                      {this.props.form.expirationTime && (
                        <Tooltip title={this.props.translate("noLimit")}>
                          <a
                            href={empty}
                            onClick={(e) => this.props.setExpirationTime(e)}
                          >
                            no limit
                          </a>
                        </Tooltip>
                      )}
                    </Grid> */}
                    <Grid item xl={12} sm={12} md={12} xs={12}>
                      <TextField
                        id="deviceLimit"
                        label={this.props.translate("userDeviceLimit")}
                        type="number"
                        placeholder=""
                        value={this.props.form.deviceLimit}
                        onChange={this.props.handleChange("deviceLimit")}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xl={12} sm={12} md={12} xs={12}>
                      <TextField
                        id="userLimit"
                        label={this.props.translate("userUserLimit")}
                        type="number"
                        placeholder=""
                        value={this.props.form.userLimit}
                        onChange={this.props.handleChange("userLimit")}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </>
              )}
              <h4> {this.props.translate("sharedAttributes")} </h4>
              <Grid container spacing={2}>
                {/* <Grid item xl={12} sm={12} md={12} xs={12}>
                  <SingleSelect
                    array={[]}
                    async
                    selectName="timezone"
                    isClearable={false}
                    defaultOptions={defaultZones}
                    loadOptions={this.searchFromZoneList}
                    label={this.props.translate("sharedTimezone")}
                    value={
                      this.props.form.attributes &&
                      this.props.form.attributes["timezone"]
                        ? timeZonesTypes.find(
                            (t) =>
                              t.key === this.props.form.attributes["timezone"]
                          )
                        : ""
                    }
                    handleChange={this.props.timezone_handleChange}
                    canAssign={true}
                  />
                </Grid> */}
                <Grid item xl={12} sm={12} md={12} xs={12}>
                  <TextField
                    id="measurementStandard"
                    select
                    label={this.props.translate("measurementStandard")}
                    value={
                      (this.props.form.attributes &&
                        this.props.form.attributes.measurementStandard) ||
                      ""
                    }
                    onChange={(e) =>
                      this.props.changeAttribute2("measurementStandard", e)
                    }
                    margin="dense"
                    fullWidth
                  >
                    {measurementStandard.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {this.props.translate(option.value)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                {/* <Grid item xl={12} sm={12} md={4} xs={12}>
                  <TextField
                    id="currencyLabel"
                    select
                    label={this.props.translate("currencyLabel")}
                    value={  (this.props.form.attributes &&
                        this.props.form.attributes.currencyLabel) ||
                      "AED"
                    }
                    onChange={(e) => this.props.changeAttribute2("currencyLabel", e) }
                    margin="dense"
                    fullWidth
                  >
                    {currencies &&
                      currencies.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                          {this.props.translate(option.value)}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid> */}
              </Grid>
              {/* <Grid item xs={12} style={{ marginTop: 20 }}>
                <label
                  style={{
                    fontSize: 10,
                    color: this.props.themecolors.textColor,
                  }}
                >
                  {this.props.translate("userToken")}
                </label>
                <Tooltip title={this.props.translate("getToken")}>
                  <Button
                    className="pull-right"
                    onClick={this.props.generateToken}
                  >
                    {
                      <RefreshIcon
                        style={{
                          color: this.props.themecolors.textColor,
                        }}
                      />
                    }
                  </Button>
                </Tooltip>
                <p onClick={(e) => this.selectMe(e)}>
                  <code style={{ wordBreak: "break-all" }}>
                    {this.props.viewScreen === "edit"
                      ? this.props.form.token &&
                        this.props.ServerSetting &&
                        this.props.ServerSetting.attributes &&
                        (this.props.ServerSetting.attributes.hostUrl +
                          "/loginasuser?&" +
                          btoa(this.props.form.token) ||
                          "")
                      : this.props.form.token || ""}
                  </code>{" "}
                  &nbsp;
                </p>
              </Grid> */}
            </>
          }
        />
        {/* {this.state.at_modal_visable ?
                    <Attributes
                        onCloseModal={this.addAttributes}
                        itemAttributes={this.props.itemAttributes}
                        changeAttribute={this.props.changeAttribute}
                        formSubmit={this.ud_submit}
                        componentType={'user'}
                        isHaveAccess={this.props.isHaveAccess}
                    />
                    : null} */}
      </div>
    );
  }
}

export default connect(mapStateToProps)(withTranslationWrapper(addUserModal));
