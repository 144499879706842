import React from "react";
import Grid from "@mui/material/Grid";
import Table from "../../../Components/common/TableMultiCheckbox";
import { checkPrivileges } from "../../../Helpers";

export default function (props) {
  const [rowsPerPage, handleChangeRowsPerPage] = React.useState(10);
  const users = [...props.userData];
  const id = props.parent ? props.parent : 0;
  users.map((u) => {
    u.parent = u.id === id;
    if (props.roles.length) {
      props.roles.map((r) => {
        if (r.id === u.roleId) {
          u.roleName = r.name;
        }
        return null;
      });
    }
    return null;
  });
  return (
    <Grid container style={{ height: "100%" }}>
      <Grid item sm={props.visableCreateGeofence ? 8 : 12} xs={12}>
        <Table
          title={props.translate("access")}
          insideDialog
          key="Notifi"
          disableHead
          showCheckbox
          checkHandleChange={props.user_Selection}
          rows={users}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
          isEditable
          canUpdate={false}
          canDelete={checkPrivileges("userDelete")}
          onDelete={props.onDelete}
          themecolors={props.themecolors}
          searchable
          canRemove={props.canRemove}
          selectAll={props.selectAll}
          canAssign={props.canAssign}
          className="custom-table2"
          rowDefinition={[
            {
              id: "name",
              numeric: false,
              disablePadding: false,
              label: props.translate("sharedName"),
            },
            {
              id: "email",
              numeric: false,
              disablePadding: false,
              label: props.translate("userEmail"),
            },
            {
              id: "roleName",
              numeric: false,
              disablePadding: false,
              label: props.translate("roleName"),
            },
            {
              id: "parentName",
              numeric: false,
              disablePadding: false,
              label: props.translate("parentName"),
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}
