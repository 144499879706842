import React, { Fragment } from "react";
import { connect } from "react-redux";
import {
  getDevices,
  addDevices,
  removeDevice,
  updateDevice,
} from "../../Actions/Devices";
import { updateGroups } from "../../Actions/Groups";
import AddDevice from "./addDevice";
import isEqual from "react-fast-compare";
import DevicePortList from "./devicePortList";
import { notifyError, notifySuccess } from "../../Utils/CustomNotifcations"
import axios from "axios";
import instance from "../../axios";
import { errorHandler } from "../../Helpers";
import withTranslationWrapper from "../../HOC/withTranslation";
const CancelToken = axios.CancelToken;
let source;

const advanceSettingsForm = {
  minimalNoDataDuration: 600,
  minimalParkingDuration: 300,
  minimalTripDuration: 60,
  minimalTripDistance: 100,
  speedThreshold: 1.61987,
  processInvalidPositions: false,
  useIgnition: "2",
  storeTime: 10,
};
const formDefaultState = {
  attributes: { ...advanceSettingsForm },
};
const deviceDataFormat = (data, extAttriubtes, id) => ({
  id: id ? id : 0,
  attributes: extAttriubtes
    ? { ...data.attributes, ...extAttriubtes }
    : { ...data.attributes },
  name: data.name.trim(),
  uniqueId: (data.uniqueId && data.uniqueId) || "",
  status: data.status,
  disabled: data.disabled,
  lastUpdate: data.lastUpdate,
  positionId: data.positionId,
  groupId: data.groupId,
  phone: data.phone,
  model: data.model,
  contact: data.contact,
  category: data.category,
  geofenceIds: data.geofenceIds,
  parentId: data.parentId || 0,
});

class deviceModal extends React.Component {
  constructor() {
    super();
    this.state = {
      addOption: false,
      editOption: false,
      selectedDevice: "",
      selectedUsers: "",
      d_attriubtes: "",
      defaultDeviceList: false,
      selectedOption: false,
      directOption: false,
      isVisableDeviceBtn: false,
      isAttChanged: false,
      duplicateIdentifier: false,
      usesId: "",
      deviceArray: [],
      itemAttributes: {},
      attributeChangesMessage: "",
      // all fields of form
      form: { ...formDefaultState },
    };
    this.editEnable = this.editEnable.bind(this);
    this.removeEnable = this.removeEnable.bind(this);
    this.deleteDevice = this.deleteDevice.bind(this);
    this.submitEditdevice = this.submitEditdevice.bind(this);
    this.submitAdddevice = this.submitAdddevice.bind(this);
    this.changeAttribute = this.changeAttribute.bind(this);
    this.changeAttribute2 = this.changeAttribute2.bind(this);
    this.modalControle = this.modalControle.bind(this);
    this.devicesSelection = this.devicesSelection.bind(this);
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
    this.checkForAttrubutesChanged = this.checkForAttrubutesChanged.bind(this);
    this.logo_handelChanges = this.logo_handelChanges.bind(this);
  }

  logo_handelChanges(item) {
    this.setState(
      {
        form: {
          ...this.state.form,
          category: item.name,
        },
        // isVisableASOneBtn: !isEqual(item.name, this.state.selectedDevice.category),
        selectedDevice: {
          ...this.state.selectedDevice,
          category: item.name,
        },
      },
      () => this.checkRequiredFields()
    );
  }

  UNSAFE_componentWillMount() {
    if (this.props.activeOperation === "add" && !this.state.addOption) {
      this.setState(
        {
          selectedDevice: "",
          addOption: true,
          editOption: false,
          itemAttributes: {},
          form: { ...formDefaultState },
        } /*,() => this.checkRequiredFields()*/
      );
    }
    if (
      this.props.activeOperation === "edit" &&
      (!this.state.editOption ||
        parseInt(this.state.selectedDevice.id) !== parseInt(this.props.id))
    ) {
      this.setState({
        selectedDevice: {
          ...this.props.selecteditem,
          attributes: {
            ...advanceSettingsForm,
            ...(this.props.selecteditem && this.props.selecteditem.attributes),
            speedLimit:
              this.props.selecteditem &&
              this.props.selecteditem.attributes &&
              this.props.selecteditem.attributes.speedLimit
                ? Math.round(
                    this.props.selecteditem.attributes.speedLimit / 0.539957
                  )
                : "",
            minimalTripDistance:
              (this.props.selecteditem &&
                this.props.selecteditem.attributes &&
                this.props.selecteditem.attributes.minimalTripDistance) ||
              100,
          },
        },
        editOption: true,
        addOption: false,
        attributeChangesMessage: "",
        itemAttributes:
          this.props.selecteditem && this.props.selecteditem.attributes,
        form: {
          ...this.props.selecteditem,
          attributes: {
            ...advanceSettingsForm,
            ...(this.props.selecteditem && this.props.selecteditem.attributes),
            speedLimit:
              this.props.selecteditem &&
              this.props.selecteditem.attributes &&
              this.props.selecteditem.attributes.speedLimit
                ? Math.round(
                    this.props.selecteditem.attributes.speedLimit / 0.539957
                  )
                : "",
            minimalTripDistance:
              (this.props.selecteditem &&
                this.props.selecteditem.attributes &&
                this.props.selecteditem.attributes.minimalTripDistance) ||
              100,
          },
        },
      });
    }

    if (this.props.id) {
      this.setState({
        addOption: false,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextprops) {
    if (nextprops.activeOperation === "add" && !this.state.addOption) {
      this.setState(
        {
          selectedDevice: "",
          addOption: true,
          editOption: false,
          itemAttributes: {},
          attributeChangesMessage: "",
          isVisableDeviceBtn: false,
          isAttChanged: false,
          form: { ...formDefaultState },
        } /*,() => this.checkRequiredFields()*/
      );
    }
    if (
      nextprops.activeOperation === "edit" &&
      (!this.state.editOption ||
        parseInt(this.state.selectedDevice.id) !==
          parseInt(nextprops.selectedUnit.id))
    ) {
      this.setState({
        selectedDevice: {
          ...nextprops.selecteditem,
          attributes: {
            ...advanceSettingsForm,
            ...(nextprops.selecteditem && nextprops.selecteditem.attributes),
            speedLimit:
              nextprops.selecteditem &&
              nextprops.selecteditem.attributes &&
              nextprops.selecteditem &&
              nextprops.selecteditem.attributes.speedLimit
                ? Math.round(
                    nextprops.selecteditem &&
                      nextprops.selecteditem.attributes.speedLimit * 1.852
                  )
                : "",
            minimalTripDistance:
              (nextprops.selecteditem &&
                nextprops.selecteditem.attributes &&
                nextprops.selecteditem &&
                nextprops.selecteditem.attributes.minimalTripDistance) ||
              100,
          },
        },
        editOption: true,
        addOption: false,
        attributeChangesMessage: "",
        isVisableDeviceBtn: false,
        isAttChanged: false,
        itemAttributes: {
          ...advanceSettingsForm,
          ...(nextprops.selecteditem && nextprops.selecteditem.attributes),
          speedLimit:
            nextprops.selecteditem &&
            nextprops.selecteditem.attributes &&
            nextprops.selecteditem &&
            nextprops.selecteditem.attributes.speedLimit
              ? Math.round(
                  nextprops.selecteditem &&
                    nextprops.selecteditem.attributes.speedLimit * 1.852
                )
              : "",
          minimalTripDistance:
            (nextprops.selecteditem &&
              nextprops.selecteditem.attributes &&
              nextprops.selecteditem &&
              nextprops.selecteditem.attributes.minimalTripDistance) ||
            100,
        },
        form: {
          ...nextprops.selecteditem,
          attributes: {
            ...advanceSettingsForm,
            ...(nextprops.selecteditem && nextprops.selecteditem.attributes),
            speedLimit:
              nextprops.selecteditem &&
              nextprops.selecteditem.attributes &&
              nextprops.selecteditem &&
              nextprops.selecteditem.attributes.speedLimit
                ? Math.round(
                    nextprops.selecteditem &&
                      nextprops.selecteditem.attributes.speedLimit * 1.852
                  )
                : "",
            minimalTripDistance:
              (nextprops.selecteditem &&
                nextprops.selecteditem.attributes &&
                nextprops.selecteditem &&
                nextprops.selecteditem.attributes.minimalTripDistance) ||
              100,
          },
        },
      });
    }

    if (nextprops.id) {
      this.setState({
        addOption: false,
        //selectedDevice: true
      });
    }
  }
  shouldComponentUpdate(nextprops, nextstate) {
    return (
      !isEqual(this.props.selecteditem, nextprops.selecteditem) ||
      !isEqual(this.props.activeLanguage, nextprops.activeLanguage) ||
      !isEqual(this.state, nextstate)
    );
  }

  // Edit device
  editEnable(selected) {
    if (selected[0]) {
      this.setState(
        {
          itemAttributes: selected[0].attributes,
          directOption: true,
          editOption: true,
          selectedDevice: selected[0],
          selectedOption: true,
          defaultDeviceList: false,
          form: { ...selected[0] },
        },
        () => this.checkRequiredFields()
      );
    } else {
      this.setState(
        {
          itemAttributes: this.state.d_attriubtes,
          editOption: true,
          defaultDeviceList: false,
          selectedOption: false,
          directOption: false,
        },
        () => this.checkRequiredFields()
      );
    }
  }
  removeEnable(selected) {
    if (selected[0]) {
      this.setState(
        {
          directOption: true,
          selectedDevice: selected[0],
          defaultDeviceList: false,
          attributeChangesMessage: "",
          form: "",
        },
        () => this.deleteDevice()
      );
    } else {
      this.setState(
        {
          defaultDeviceList: true,
          selectedOption: false,
          directDelete: false,
          attributeChangesMessage: "",
          form: "",
        },
        () => this.deleteDevice()
      );
    }
  }
  submitAdddevice() {
    let { attributes, name, device, model } = this.state.form;
    if (name.trim().length > 0) {
      const obj = deviceDataFormat(this.state.form, attributes);
      if (obj.attributes && !obj.attributes.devicePassword) {
        delete this.state.form.attributes.devicePassword;
      }
      if (device && model) {
        obj.attributes = {
          ...obj.attributes,
          port: parseInt(device),
          protocol: typeof model === "object" ? model.protocol : model,
        };
      }
      if (obj.attributes && obj.attributes.speedLimit) {
        obj.attributes = {
          ...obj.attributes,
          speedLimit: obj.attributes.speedLimit * 0.539957,
        };
      }
      obj.attributes.minimalTripDistance = attributes.minimalTripDistance;
      obj.attributes = {
        ...obj.attributes,
        "parking.maxIdleSpeed": (1.61987).toString(),
        "parking.minIdleTime": (1000 * 60 * 5).toString(),
      };
      obj.model = typeof model === "object" ? model.label : model;
      this.setState({
        isVisableDeviceBtn: false,
      });

      instance({
        url: `/api/devices/`,
        method: "POST",
        headers: {
          Accept: "application/json; odata=verbose",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        // .then(response => {
        //   if (response.status === 200) {
        //     response.json()
        .then((device) => {
          if (device) {
            // console.log("device ==",device);
            // this.props.dispatch(addDevices(device));s
            // this.props.checkedDeviceLimit();
            //this.props.enqueueSnackbar(this.props.translate('trackerIsAdded'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
            this.props.dispatch(
              notifySuccess(this.props.translate("trackerIsAdded"))
            );
            this.setState({
              addOption: false,
              selectedDevice: "",
              defaultDeviceList: true,
              // isVisableDeviceBtn: false,
              isAttChanged: false,
              form: "",
              attributeChangesMessage: "",
            });
          }
        })

        .catch((error) => {
          errorHandler(error, this.props.dispatch, this.errorCallBack);
        });
    } else {
      this.setState({
        isVisableDeviceBtn: false,
        isAttChanged: false,
      });
      //this.props.enqueueSnackbar(this.props.translate('emptyField'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
      this.props.dispatch(
        notifyError(this.props.translate("uniqueIdIsAllreadyInUsed"))
      );
    }
  }
  // Delete device
  deleteDevice() {
    if (window.confirm("Are you Sure to Delete")) {
      const { selectedDevice } = this.state;

      instance({
        url: `/api/devices/${selectedDevice.id}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // if (response.status === 204) {
          if (this.props.activeOperation === "ownDevice") {
            this.props.dispatch(removeDevice(selectedDevice.id));
          } else {
            const array = this.state.deviceArray.filter(
              (gr) => gr.id !== selectedDevice.id
            );
            this.setState({
              deviceArray: array,
            });
          }

          //this.props.enqueueSnackbar(this.props.translate('trackersIsDeleted'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess(this.props.translate("trackersIsDeleted"))
          );
          // } else {
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
      this.setState({
        selectedDevice: "",
        addOption: false,
        editOption: false,
        defaultDeviceList: true,
        attributeChangesMessage: "",
      });
    } else {
      this.setState({
        selectedDevice: "",
        addOption: false,
        editOption: false,
        defaultDeviceList: true,
        attributeChangesMessage: "",
      });
    }
  }
  submitEditdevice() {
    let { name, device, attributes, model } = this.state.form;
    if (name.trim().length > 0) {
      const { selectedDevice, form } = this.state;
      const obj = deviceDataFormat(form, attributes, selectedDevice.id);
      if (
        obj.attributes &&
        obj.attributes.devicePassword !== undefined &&
        obj.attributes.devicePassword === ""
      ) {
        delete obj.attributes["devicePassword"];
      }
      delete obj.indeterminate;
      delete obj.parent;
      if (device && model) {
        obj.attributes = {
          ...obj.attributes,
          port: parseInt(device),
          protocol: typeof model === "object" ? model.protocol : model,
        };
      }
      obj.model = typeof model === "object" ? model.label : model;

      const final = {
        ...obj,
        attributes: {
          ...obj.attributes,
          speedLimit:
            form.attributes && form.attributes.speedLimit
              ? form.attributes.speedLimit * 0.539957
              : "",
        },
      };

      instance({
        url: `/api/devices/${selectedDevice.id}`,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...final,
        },
      })
        .then((device) => {
          // device["visible"] = selectedDevice.visible || false;
          this.props.dispatch(updateDevice(device));
          // this.props.updateSelectedItem(device);

          //this.props.enqueueSnackbar(this.props.translate('trackersIsUpdated'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess(this.props.translate("trackersIsUpdated"))
          );
          if (this.props.activeOperation === "edit") {
            this.setState({
              isVisableDeviceBtn: false,
              isAttChanged: false,
              attributeChangesMessage: "",
            });
          } else {
            this.setState({
              editOption: false,
              defaultDeviceList: true,
              isVisableDeviceBtn: false,
              isAttChanged: false,
              attributeChangesMessage: "",
            });
          }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
          // this.props.dispatch(
          //   notifyError({
          //     message: this.props.translate('somthingWentWrongMessage'),
          //     autoDismiss: 10
          //   })
          // )
          /* e.text().then(err => {
            if (err.includes('Duplicate entry')) {
              this.setState({
                duplicateIdentifier: true
              })
              //this.props.enqueueSnackbar(this.props.translate('uniqueIdIsAllreadyInUsed'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
              this.props.dispatch(
                notifyError({
                  message: this.props.translate('uniqueIdIsAllreadyInUsed'),
                  autoDismiss: 10
                })
              )
            }
          }) */
        });
    } else {
      this.setState({
        isVisableDeviceBtn: false,
        isAttChanged: false,
      });
      //this.props.enqueueSnackbar(this.props.translate('emptyField'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
      this.props.dispatch(
        notifyError(this.props.translate("emptyField"))
      );
    }
  }
  modalControle() {
    source = CancelToken.source();

    if (this.state.isVisableDeviceBtn) {
      this.fatchDevice(this.props.logInUser.id);
    }
    this.setState({
      addOption: false,
      editOption: false,
      selectedDevice: "",
      defaultDeviceList: true,
      form: "",
      isVisableDeviceBtn: false,
      isAttChanged: false,
      duplicateIdentifier: false,
      attributeChangesMessage: "",
      isVisablePortList: false,
    });
    if (this.props.onCloseModal) {
      this.props.onCloseModal();
    }
  }
  addDevice() {
    this.setState({
      selectedDevice: "",
      addOption: true,
      editOption: false,
      itemAttributes: {},
      defaultDeviceList: false,
      isVisableDeviceBtn: false,
      isAttChanged: false,
      form: "",
      attributeChangesMessage: "",
    });
  }
  updateDevices() {
    this.setState({
      editOption: true,
      defaultDeviceList: false,
    });
  }
  changeAttribute(name, value, selectedOption, updateAtt) {
    if (selectedOption === "updateAtt" || selectedOption === "deleteAtt") {
      this.setState(
        {
          d_attriubtes: { ...updateAtt },
        },
        () => this.checkForAttrubutesChanged(selectedOption)
      );
    } else {
      this.setState(
        {
          d_attriubtes: {
            ...this.state.selectedDevice.attributes,
            [name]: value,
          },
        },
        () => this.checkForAttrubutesChanged()
      );
    }
  }
  changeAttribute2 = (name, event) => {
    let value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    if (!value) {
      //delete this.state.form.attributes[name]
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: "",
            },
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: value,
            },
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };

  checkForAttrubutesChanged(selectedOption) {
    let changed = false;
    if (this.state.selectedDevice) {
      changed = !isEqual(
        this.state.d_attriubtes,
        this.state.selectedDevice.attributes
      );
    } else {
      changed = !this.isEmpty(this.state.d_attriubtes);
    }
    let { name, uniqueId } = this.state.form;
    let selectedDevice = {};
    if ((name && uniqueId) || (name && uniqueId && changed)) {
      selectedDevice = this.state.d_attriubtes;
      if (selectedOption === "deleteAtt" || selectedOption === "updateAtt") {
        this.setState({
          isVisableDeviceBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...selectedDevice },
        });
      } else {
        this.setState({
          isVisableDeviceBtn: changed,
          isAttChanged: changed,
          itemAttributes: { ...this.state.itemAttributes, ...selectedDevice },
        });
      }
    } else {
      selectedDevice = this.state.d_attriubtes;
      if (selectedOption === "deleteAtt" || selectedOption === "updateAtt") {
        this.setState(
          {
            isVisableDeviceBtn: false,
            isAttChanged: changed,
            itemAttributes: { ...selectedDevice },
          },
          () => this.checkRequiredFields()
        );
      } else {
        this.setState(
          {
            isVisableDeviceBtn: false,
            isAttChanged: false,
            itemAttributes: { ...this.state.itemAttributes, ...selectedDevice },
          },
          () => this.checkRequiredFields()
        );
      }
    }
    if (changed) {
      this.setState({
        attributeChangesMessage: "Change in attributes is pending",
      });
    } else {
      this.setState(
        {
          attributeChangesMessage: "",
        },
        () => this.checkRequiredFields()
      );
    }
  }
  isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  };

  handleChange = (name) => (event) => {
    const { target } = event;
    const re = /^[a-zA-Z0-9_.-]*$/;
    let value = target.type === "checkbox" ? target.checked : target.value;
    if (value !== "" && (name === "uniqueId" ? re.test(value) : true)) {
      this.setState(
        (preState) => ({
          ...preState,
          form: {
            ...preState.form,
            [name]: value,
          },
        }),
        () => this.checkRequiredFields()
      );
    } else {
      if (re.test(value) || value === "") {
        this.setState(
          (preState) => ({
            ...preState,
            form: {
              ...preState.form,
              [name]: null,
            },
          }),
          () => this.checkRequiredFields()
        );
      }
    }
  };

  handleChange2 = (target, option) => {
    let value = target.type === "checkbox" ? target.checked : target.value;
    if (value !== "") {
      this.setState(
        (preState) => ({
          ...preState,
          form: {
            ...preState.form,
            [option.name]: value,
          },
        }),
        () => this.checkRequiredFields()
      );
    }
  };

  port_handleChange = (name, value) => {
    if (
      !("" + (this.state.form.uniqueId || "")).trim() &&
      value &&
      value.key === "FX Tracker"
    ) {
      instance({
        url: `/api/devices/uniqueid`,
        method: "GET",
      })
        .then((dataResp) => {
          this.setState({
            form: {
              ...this.state.form,
              uniqueId: dataResp.uniqueId,
            },
          });
          this.setState(
            (preState) => ({
              ...preState,
              form: {
                ...preState.form,
                [name]: value.key,
                device: value.port,
              },
            }),
            () => this.checkRequiredFields()
          );
        })
        .catch((error) => {
          // errorHandler(error, this.props.dispatch)
        });
    } else {
      this.setState(
        (preState) => ({
          ...preState,
          form: {
            ...preState.form,
            [name]: value,
            device: value.port,
          },
        }),
        () => this.checkRequiredFields()
      );
    }
  };

  timezone_handleChange = (name, value) => {
    if (!value || value.value === "None") {
      delete this.state.form.attributes[name];
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
            },
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: {
              ...this.state.form.attributes,
              [name]: value.value,
            },
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };

  checkRequiredFields() {
    let { selectedDevice } = this.state;
    let { name, uniqueId, model, category } = this.state.form;
    let valueOfPort = false;
    if (uniqueId) {
      valueOfPort = true;
    }
    if (model && model === "FX Tracker") {
      valueOfPort = true;
    }
    let value = !isEqual(this.state.form, selectedDevice);
    if ((name && model && valueOfPort && value) || category) {
      this.setState({
        isVisableDeviceBtn: true,
        duplicateIdentifier: false,
      });
    } else {
      this.setState({
        isVisableDeviceBtn: this.state.isAttChanged,
        duplicateIdentifier: false,
      });
    }
  }
  devicesSelection(item) {
    if (item) {
      this.setState(
        {
          selectedDevice: item,
          selectedOption: true,
          defaultDeviceList: false,
          directOption: false,
          d_attriubtes: item.attributes,
          form: {
            ...item,
            attributes: {
              ...item.attributes,
              speedLimit: Math.round(item.attributes.speedLimit / 0.539957),
            },
          },
        },
        () => {}
      );
    }
  }
  fatchDevice = (id) => {
    if (!this.props.devices.length) {
      axios({
        method: "GET",
        url: `/api/devices?userId=${id}`,
        cancelToken: source.token,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if (response.status === 200) {
          this.props.dispatch(getDevices(response.data));
          this.setState({
            usesId: this.props.logInUser.id,
          });
        }
      });
    }
    //get groups
    if (!this.props.group.length) {
      axios({
        method: "GET",
        url: `/api/groups?userId=${id}`,
        cancelToken: source.token,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }).then((response) => {
        if (response.status === 200) {
          this.props.dispatch(updateGroups(response.data));
        }
      });
    }
  };
  addNewGroups = () => {
    this.props.addNewGroups();
  };

  showPortList = () => {
    this.setState({
      isVisablePortList: true,
    });
  };

  onCloseModal = () => {
    this.setState({
      isVisablePortList: false,
    });
  };

  render() {
    return (
      <>
        {this.state.addOption && (
          <>
            <AddDevice
              logo_handelChanges={this.logo_handelChanges}
              images={this.props.category}
              onlyAttributes={this.props.onlyAttributes}
              themecolors={this.props.themecolors}
              group={this.props.group}
              formSubmit={this.submitAdddevice}
              changeAttribute={this.changeAttribute}
              modalControle={this.props.modalControle}
              buttonText="sharedSave"
              isVisableDeviceBtn={this.state.isVisableDeviceBtn}
              duplicateIdentifier={this.state.duplicateIdentifier}
              itemAttributes={this.state.itemAttributes}
              attributeChangesMessage={this.state.attributeChangesMessage}
              form={this.state.form}
              handleChange={this.handleChange}
              handleChange2={this.handleChange2}
              isHaveAccess="deviceCreate"
              showPortList={this.showPortList}
              btnCancelNotShow={true}
              showPort={true}
              addOption={this.state.addOption}
              serverIp={
                this.props.ServerSetting
                  ? this.props.ServerSetting.attributes.serverIp
                  : ""
              }
              port_handleChange={this.port_handleChange}
              timezone_handleChange={this.timezone_handleChange}
              changeAttribute2={this.changeAttribute2}
              logInUser={this.props.logInUser}
            />
            {this.state.isVisablePortList && (
              <DevicePortList
                themecolors={this.props.themecolors}
                onCloseModal={this.onCloseModal}
                translate={this.props.translate}
              />
            )}
          </>
        )}
        {this.state.editOption && (
          <>
            <AddDevice
              images={this.props.category}
              logo_handelChanges={this.logo_handelChanges}
              editOption
              onlyAttributes={this.props.onlyAttributes}
              selectedDevice={this.state.selectedDevice}
              themecolors={this.props.themecolors}
              group={this.props.group}
              changeAttribute={this.changeAttribute}
              formSubmit={this.submitEditdevice}
              handleChange={this.handleChange}
              handleChange2={this.handleChange2}
              form={this.state.form}
              modalControle={this.props.modalControle}
              buttonText="update"
              isVisableDeviceBtn={this.state.isVisableDeviceBtn}
              duplicateIdentifier={this.state.duplicateIdentifier}
              itemAttributes={this.state.itemAttributes || ""}
              attributeChangesMessage={this.state.attributeChangesMessage}
              btnCancelNotShow={true}
              addNewGroups={this.addNewGroups}
              isHaveAccess="deviceUpdate"
              timezone_handleChange={this.timezone_handleChange}
              changeAttribute2={this.changeAttribute2}
              notShowGroupBtn={this.props.notShowGroupBtn}
              logInUser={this.props.logInUser}
              port_handleChange={this.port_handleChange}
            />
          </>
        )}
      </>
    );
  }
}

const mapState = (state) => {
  return {
    devices: state.devices.data,
    group: state.groups,
    themecolors: state.themeColors,
    logInUser: state.logInUsers,
  };
};

const mapStateToProps = connect(mapState);
export const DeviceModal = mapStateToProps(withTranslationWrapper(deviceModal));
