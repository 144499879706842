import React, { Component } from "react";
import TextField from "../../../Components/common/TextField";
import MenuItem from "../../../Components/common/MenuItem";
import Grid from "@mui/material/Grid";
import Button from "../../../Components/common/Button";
import Checkbox from "../../../Components/common/Checkbox";
import Table from "../../../Components/common/TableMultiCheckbox";
import { timeZonesTypes } from "../../../Components/Attributes/timezone";
import { checkPrivileges } from "../../../Helpers";
import Dialog from "../../../Components/common/Dialog";

export default class SavedCommands extends Component {
  constructor(props) {
    super(props);
    this.savedCommands_handleChange =
      this.savedCommands_handleChange.bind(this);
  }

  savedCommands_handleChange(name, value) {
    if (this.props.savedCommandsHandle) {
      this.props.savedCommandsHandle(name, value);
    }
  }

  render() {
    /* let commandsTypes = this.props.commandsTypes.map(t => {
      return { key: t.type, name: this.props.translate('commands.' + t.type) }
    }) */
    return (
      <>
        <Grid container style={{ height: "100%" }}>
          <Grid item sm={9} xs={9}>
            <h4
              style={{
                color: this.props.themecolors.textColor,
                padding: "0 8px",
                margin: 0,
                paddingTop: 16,
              }}
            >
              {" "}
              {this.props.translate("sharedSavedCommand")}
            </h4>
          </Grid>
          <Grid item sm={3} xs={3}>
            {checkPrivileges("commandCreate") && (
              <div style={{ marginTop: 10 }}>
                {this.props.sendCommand
                  ? checkPrivileges("commandSend") && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid",
                          borderColor:
                            this.props.themecolors.menuActiveBackground,
                          borderRadius: 8,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.props.onCreateNewSavedCommands();
                        }}
                      >
                        <span
                          style={{
                            background:
                              this.props.themecolors.menuActiveBackground,
                            borderRadius: "4px 0px 0px 4px",
                            padding: "3px 8px",
                            display: "flex",
                            flexDirection: "column",
                            color: "#ffffff",
                          }}
                        >
                          +
                        </span>
                        <span
                          style={{
                            color: "#141313",
                            fontSize: 12,
                            padding: "0 12px",
                          }}
                        >
                          {this.props.translate("sharedCreate")}
                        </span>
                      </div>
                    )
                  : null}
              </div>
            )}
          </Grid>
        </Grid>
        <Grid container style={{ height: "100%" }}>
          <Grid item sm={12} xs={12}>
            <Table
              insideDialog
              disableHead
              showCheckbox
              checkHandleChange={this.props.savedCommands_Selection}
              rows={this.props.commandsData}
              isEditable
              selectAll={this.props.selectAll}
              onEdit={this.props.onEdit}
              onDelete={this.props.onDelete}
              themecolors={this.props.themecolors}
              searchable
              canRemove={this.props.canAssign}
              canAssign={this.props.canRemove}
              canUpdate={checkPrivileges("commandUpdate")}
              canDelete={checkPrivileges("commandDelete")}
              className="custom-table2"
              rowsPerPage={this.props.rowsPerPage}
              handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
              rowDefinition={[
                {
                  id: "description",
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate("sharedDescription"),
                },
                {
                  id: "type",
                  numeric: false,
                  disablePadding: false,
                  label: this.props.translate("sharedType"),
                  translate: true,
                  prefix: "commands.",
                },
              ]}
            />
          </Grid>
          {this.props.visableSavedCommands && (
            <Dialog
              open
              title={this.props.translate("Command")}
              themeColors={this.props.themecolors}
              disableFooter
              onClose={this.props.onCloseC_Att_Modal}
              dialogHeight={185}
              draggable
              bodyPadding={0}
              maxWidth={"xs"}
            >
              <div
                style={{
                  padding: "1px 20px",
                  borderLeft:
                    "1px solid " + this.props.themecolors.themeLightColor,
                  height: "100%",
                }}
              >
                <h4>{this.props.translate("sharedSavedCommand")}</h4>
                <TextField
                  id="description"
                  margin="dense"
                  label={this.props.translate("sharedDescription")}
                  variant="outlined"
                  fullWidth
                  value={this.props.savedCommandsForm.description}
                  onChange={this.props.savedCommands_handleChange(
                    "description"
                  )}
                />
                {/* <Checkbox
                checked={this.props.savedCommandsForm.textChannel}
                onChange={this.props.savedCommands_handleChange('textChannel')}
                color='primary'
                label={this.props.translate('commands.sendSms')}
              /> */}
                {[
                  "custom",
                  "silenceTime",
                  "setPhonebook",
                  "voiceMessage",
                  "outputControl",
                  "setIndicator",
                  "configuration",
                  "setOdometer",
                  "alarmClock",
                  "alarmSpeed",
                  "alarmVibration",
                ].includes(this.props.savedCommandsForm.type) ? (
                  <TextField
                    id="data"
                    name="data"
                    margin="dense"
                    label={this.props.translate("commandData")}
                    variant="outlined"
                    fullWidth
                    value={this.props.savedCommandsForm.attributes.data}
                    onChange={this.props.savedCommands_handleChange(
                      "attributes"
                    )}
                  />
                ) : null}
                {this.props.savedCommandsForm.type === "positionPeriodic" && (
                  <div>
                    <TextField
                      id="frequency"
                      name="frequency"
                      type="number"
                      margin="dense"
                      label={this.props.translate("commandFrequency")}
                      variant="outlined"
                      fullWidth
                      value={this.props.savedCommandsForm.attributes.frequency}
                      onChange={this.props.savedCommands_handleChange(
                        "attributes"
                      )}
                    />
                    <TextField
                      id="frequencyType"
                      name="frequencyType"
                      select
                      value={
                        this.props.savedCommandsForm.attributes.frequencyType
                      }
                      onChange={this.props.savedCommands_handleChange(
                        "attributes"
                      )}
                      margin="dense"
                      fullWidth
                    >
                      {frequencyType.map((option) => (
                        <MenuItem key={option.type} value={option.type}>
                          {option.type}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>
                )}
                {this.props.savedCommandsForm.type === "setTimezone" && (
                  <TextField
                    id="timezone"
                    name="timezone"
                    select
                    label={this.props.translate("commandTimezone")}
                    value={this.props.savedCommandsForm.timezone}
                    onChange={this.props.savedCommands_handleChange(
                      "attributes"
                    )}
                    margin="dense"
                    fullWidth
                  >
                    {timeZonesTypes.map((option) => (
                      <MenuItem key={option.key} value={option.key}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                {[
                  "sendSms",
                  "sendUssd",
                  "sosNumber",
                  "outputControl",
                  "message",
                ].includes(this.props.savedCommandsForm.type) ? (
                  <div>
                    {["sosNumber", "outputControl"].includes(
                      this.props.savedCommandsForm.type
                    ) ? (
                      <TextField
                        id="index"
                        name="index"
                        type="number"
                        margin="dense"
                        label={this.props.translate("commandIndex")}
                        variant="outlined"
                        fullWidth
                        value={this.props.savedCommandsForm.index}
                        onChange={this.props.savedCommands_handleChange(
                          "attributes"
                        )}
                      />
                    ) : null}
                    {["sendSms", "sendUssd", "sosNumber"].includes(
                      this.props.savedCommandsForm.type
                    ) ? (
                      <TextField
                        id="phoneNumber"
                        margin="dense"
                        name="phoneNumber"
                        label={this.props.translate("commandPhone")}
                        variant="outlined"
                        fullWidth
                        value={this.props.savedCommandsForm.phoneNumber}
                        onChange={this.props.savedCommands_handleChange(
                          "attributes"
                        )}
                      />
                    ) : null}
                    {["sendSms", "message"].includes(
                      this.props.savedCommandsForm.type
                    ) ? (
                      <TextField
                        id="messages"
                        name="messages"
                        margin="dense"
                        label={this.props.translate("Messages")}
                        variant="outlined"
                        fullWidth
                        value={this.props.savedCommandsForm.messages}
                        onChange={this.props.savedCommands_handleChange(
                          "attributes"
                        )}
                      />
                    ) : null}
                  </div>
                ) : null}
                {[
                  "alarmFall",
                  "alarmRemove",
                  "alarmBattery",
                  "modeDeepSleep",
                  "modePowerSaving",
                  "setAgps",
                  "voiceMonitoring",
                ].includes(this.props.savedCommandsForm.type) ? (
                  <Checkbox
                    checked={this.props.savedCommandsForm.enable}
                    onChange={this.props.savedCommands_handleChange(
                      "attributes"
                    )}
                    color="primary"
                    label={this.props.translate("commandEnable")}
                    name="enable"
                  />
                ) : null}
                {this.props.savedCommandsForm.type === "setConnection" && (
                  <div>
                    <TextField
                      id="server"
                      name="server"
                      margin="dense"
                      label={this.props.translate("commandServer")}
                      variant="outlined"
                      fullWidth
                      value={this.props.savedCommandsForm.server}
                      onChange={this.props.savedCommands_handleChange(
                        "attributes"
                      )}
                    />
                    <TextField
                      id="port"
                      name="port"
                      type="number"
                      margin="dense"
                      label={this.props.translate("commandPort")}
                      variant="outlined"
                      fullWidth
                      value={this.props.savedCommandsForm.port}
                      onChange={this.props.savedCommands_handleChange(
                        "attributes"
                      )}
                    />
                  </div>
                )}
                {this.props.savedCommandsForm.type === "novementAlarm" && (
                  <TextField
                    id="radius"
                    name="radius"
                    margin="dense"
                    label={this.props.translate("commandRadius")}
                    variant="outlined"
                    fullWidth
                    value={this.props.savedCommandsForm.radius}
                    onChange={this.props.savedCommands_handleChange(
                      "attributes"
                    )}
                  />
                )}
                <div style={{ textAlign: "right", marginTop: 10 }}>
                  <Button
                    size="small"
                    style={{ marginRight: 15 }}
                    className="button-white"
                    onClick={() => this.props.onCloseC_Att_Modal()}
                  >
                    {this.props.translate("sharedCancel")}{" "}
                  </Button>
                  {checkPrivileges(this.props.isHaveAccess) && (
                    <Button
                      disabled={!this.props.isAddVisableBtn}
                      size="small"
                      onClick={() => this.props.addSavedCommands()}
                    >
                      {this.props.translate(this.props.addButtonStatus)}{" "}
                    </Button>
                  )}
                </div>
              </div>
            </Dialog>
          )}
        </Grid>
      </>
    );
  }
}

const frequencyType = [{ type: "s" }, { type: "m" }, { type: "h" }];
