import React, { Component, Fragment, useState } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { getNotificationType } from "../../../Actions/Notifications";
import Stepper from "./../../../Components/common/StepperReport";
import Button from "./../../../Components/common/Button";
import Checkbox from "./../../../Components/common/Checkbox";
import TextField from "./../../../Components/common/SearchField";
import { ReactComponent as SendIcon } from "./../../../assets/nav/send.svg";

import Grid from "@mui/material/Grid";
import DateRangePicker from "./../../../Components/common/DateRangePicker";
import Loader from "../../Loader";
import Scrollbar from "react-scrollbars-custom";
import DeviceSelector from "../../../Components/Devices/DeviceSelector";
import { connect } from "react-redux";
import DriverSelector from "../../../Components/Devices/DriverSelector";
import NotificationSelector from "../../../Components/Devices/NotificationSelector";
import NotificationTypeSelector from "../../../Components/Devices/NotificationTypeSelector";
import Slider from "@mui/material/Slider";
import moment from "moment";
import GeofenceSelector from "../../../Components/Devices/GeofenceSelector";
import Autocomplete from "../../../Components/common/Autocomplete";
import { Collapse, FormControlLabel, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ItemGroupSelector from "../../../Components/Devices/ItemGroupSelector";
import { RemoteItems } from "../../../Hooks/useRemoteItems";
import VehicleSelector from "../../../Components/Devices/VehicleSelector";
import TodayIcon from "@mui/icons-material/Today";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CollapseIcon from "@mui/icons-material/ArrowDropDownCircle";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import Switch from "../../../Components/common/Switch";
import instance from "../../../axios";
import { ReactComponent as TracksIcon } from "../../../assets/nav/tracks.svg";
import { ReactComponent as EngineIcon } from "../../../assets/nav/idling.svg";
import { ReactComponent as StopIcon } from "../../../assets/monitoring/stoping-report.svg";
import { ReactComponent as GeofenceIcon } from "../../../assets/monitoring/geofence.svg";
import InputAdornment from "@mui/material/InputAdornment";

import { styled } from "@mui/material/styles";
const PrettoSlider = styled(Slider)({
  color: "#ed3a3a",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 22,
    width: 22,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    // lineHeight: 1.2,
    fontSize: 10,
    background: "unset",
    padding: 0,
    width: 30,
    height: 30,
    marginTop: 10,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#ed3a3a",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&::before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

let templates = [
  {
    rpTmpl: {
      id: 1,
      attributes: {
        allowFields: ["device", "driver", "group"],
      },
      name: "Trip",
      description: "",
      parentId: 1,
      active: true,
      directAccess: true,
    },
  },
  {
    rpTmpl: {
      id: 2,
      attributes: {
        allowFields: ["device", "group"],
      },
      name: "Stop",
      description: "",
      parentId: 1,
      active: true,
      directAccess: true,
    },
  },
  {
    rpTmpl: {
      id: 3,
      attributes: {
        allowFields: ["device", "driver", "group"],
      },
      name: "Engine hours",
      description: "",
      parentId: 1,
      active: true,
      directAccess: true,
    },
  },
  {
    rpTmpl: {
      id: 6,
      attributes: {
        allowFields: ["device", "driver", "group"],
      },
      name: "Geofence",
      description: "",
      parentId: 1,
      active: true,
      directAccess: true,
    },
  },
];
class ReportsSidebar extends Component {
  constructor(props) {
    super(props);
    this.reportDetail = this.reportDetail.bind(this);
    this.state = {
      datetime: true,
      template: true,
      entity: true,
      event: true,
      geofence: true,
      sensor: true,

      //
      geoReport: false,
      geoStep: 1,
    };
  }

  toggleCollapse = (type, value) => {
    this.setState({
      [type]: value !== undefined ? value : !this.state[type],
    });
  };

  reportDetail(e) {
    this.props.reportDetail(e.target.checked);
  }

  createReport = (type) => {
    this.props.collapseSidebar();
    this.props.createReport(type);
  };

  openReportGenerator = (type) => {
    this.props.collapseSidebar();
    this.props.openReportGenerator();
  };

  componentDidMount() {
    if (!this.props.notificationType.length) {
      instance({
        url: `/api/notifications/types/`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
        .then((notifications) => {
          this.props.dispatch(getNotificationType(notifications));
        })
        //   } else {
        //     throw response
        //   }
        // })
        .catch((error) => {
          // errorHandler(error, this.props.dispatch)
        });
    }
  }

  getNotificationType = () => {
    let array = [];
    if (this.props.notificationType.length) {
      this.props.notificationType.map((item, i) => {
        array.push({
          name: this.props.translate("notification." + item.type),
          id: item.type,
        });
        return null;
      });
      return array;
    } else {
      return array;
    }
  };

  valuetext = (value) => {
    return moment("2016-03-13")
      .startOf("day")
      .add(value, "minutes")
      .format("HH:mm");
  };
  showDates = (e, date) => {
    if (e.type === "apply") {
      this.toggleCollapse("datetime", false);
      this.props.showDates(date);
    }
  };

  reportTypeChange = (value) => {
    this.props.reportTypeChange(value);
    this.toggleCollapse("template", false);
    if (value && value.rpTmpl.id === 6) {
      this.setState({ geoReport: true });
    } else {
      this.setState({ geoReport: false, geoStep: 1 });
    }
  };

  changeStep = (val) => {
    this.setState({
      geoStep: val ? 2 : 1,
    });
  };

  render() {
    const templateTitle = this.props.templates.find(
      (e) => e.rpTmpl.id === this.props.reportType
    );

    const viewScreen = (
      <form style={{ padding: "0 16px" }}>
        {this.props.loading ? (
          <Loader />
        ) : (
          <>
            <Grid container>
              <h4
                style={{
                  margin: "10px 0 0px 2px",
                  color: this.props.themecolors.textColor,
                }}
              >
                {this.props.translate("selectReportTimeControl")}
              </h4>
              {this.props.errorStep == 1 && this.props.errorMsg}
              <Grid item xs={12}>
                <DateRangePicker
                  customPicker
                  error={this.props.timeError}
                  onEvent={this.showDates}
                >
                  <TextField
                    id="selectReportTimeControl"
                    value={
                      this.props.selectedDateLabel !== "Custom Range"
                        ? this.props.selectedDateLabel
                        : this.props.selectedDate
                    }
                    margin="dense"
                    fullWidth
                    readOnly
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" disableTypography>
                          <TodayIcon
                            style={{
                              fontSize: 18,
                              marginRight: 7,
                              color: "#363947",
                            }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </DateRangePicker>
              </Grid>
              <Grid item xs={12}>
                <label
                  style={{
                    fontSize: 10,
                    marginTop: 10,
                    marginBottom: 5,
                    display: "block",
                    color: this.props.themecolors.textColor,
                  }}
                >
                  {this.props.translate("reportForDays")}
                </label>
                <div
                  style={{
                    border: `1px solid ${this.props.themecolors.themeLightInverse}`,
                    display: "inline-flex",
                    color: this.props.themecolors.textColor,
                  }}
                >
                  {day.map((option) => (
                    <div
                      key={option.id}
                      style={{
                        display: "inline-flex",
                        textTransform: "uppercase",
                        fontWeight: 600,
                        marginRight: option.id === "7" ? 0 : 1,

                        backgroundColor: this.props.controlDays.includes(
                          parseInt(option.id)
                        )
                          ? this.props.themecolors.menuActiveBackground
                          : "transparent",
                        color: this.props.controlDays.includes(
                          parseInt(option.id)
                        )
                          ? this.props.themecolors.themeInverse
                          : this.props.themecolors.textColor,
                      }}
                    >
                      <label
                        style={{
                          padding: 5,
                          minWidth: 30,
                          cursor: "pointer",
                        }}
                      >
                        <input
                          type="checkbox"
                          key={option.id}
                          checked={this.props.controlDays.includes(
                            parseInt(option.id)
                          )}
                          onChange={(e) =>
                            this.props.onDayChange(e.target.checked, option)
                          }
                          style={{ display: "none" }}
                        />
                        {this.props.translate(option.name)}
                      </label>
                    </div>
                  ))}
                </div>
              </Grid>
              <Grid item xs={12}>
                <label
                  style={{
                    fontSize: 10,
                    marginTop: 10,
                    marginBottom: 30,
                    display: "block",
                    color: this.props.themecolors.textColor,
                  }}
                >
                  {this.props.translate("reportBetweenTime")}
                </label>
                <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                  <PrettoSlider
                    value={this.props.controlTime}
                    onChange={this.props.handleTimeControl}
                    valueLabelDisplay="on"
                    aria-label="pretto slider"
                    valueLabelFormat={this.valuetext}
                    max={1439}
                    // classes={{
                    //   root: "theme-range-slider",
                    //   thumb: "theme-range-slider-thumb",
                    //   track: "theme-range-slider-track",
                    //   rail: "theme-range-slider-rail",
                    //   valueLabel: "theme-range-slider-label",
                    // }}
                    step={5}
                  />
                </div>
              </Grid>
            </Grid>
            <>
              <h4
                style={{
                  margin: "0px 0 8px 6px",
                  color: this.props.themecolors.textColor,
                }}
              >
                {templateTitle && templateTitle.rpTmpl.name
                  ? this.props.translate(templateTitle.rpTmpl.name)
                  : this.props.translate("selectReportTemplate")}
              </h4>
              {this.props.errorStep == 2 && this.props.errorMsg}
              <Grid container>
                <Grid item xs={12} className="geofences-columns-wise">
                  <div
                    className="list-view"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      margin: 0,
                    }}
                  >
                    {templates.map((option) => {
                      return (
                        <div
                          key={option.rpTmpl.id}
                          onClick={() => this.reportTypeChange(option)}
                          style={{
                            alignItems: "center",
                            borderRadius: 8,
                            background:
                              this.props.reportType === option.rpTmpl.id
                                ? this.props.themecolors.menuActiveBackground
                                : "#F0F4F7",
                            margin: "0 5px",
                            height: 70,
                            width: 130,
                          }}
                        >
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "15px 0 0 0",
                            }}
                          >
                            {option.rpTmpl.id === 1 ? (
                              <TracksIcon
                                width={20}
                                height={20}
                                fill={
                                  this.props.reportType === option.rpTmpl.id
                                    ? "#fff"
                                    : "#000"
                                }
                              />
                            ) : option.rpTmpl.id === 2 ? (
                              <StopIcon
                                width={20}
                                height={20}
                                fill={
                                  this.props.reportType === option.rpTmpl.id
                                    ? "#fff"
                                    : "#000"
                                }
                              />
                            ) : option.rpTmpl.id === 6 ? (
                              <GeofenceIcon
                                width={20}
                                height={20}
                                fill={
                                  this.props.reportType === option.rpTmpl.id
                                    ? "#fff"
                                    : "#000"
                                }
                              />
                            ) : (
                              <EngineIcon
                                width={20}
                                height={20}
                                fill={
                                  this.props.reportType === option.rpTmpl.id
                                    ? "#fff"
                                    : "#000"
                                }
                              />
                            )}
                          </p>
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              margin: "0px",
                              textAlign: "center",
                              color:
                                this.props.reportType === option.rpTmpl.id
                                  ? "#fff"
                                  : "#000",
                              fontSize: 12,
                              padding: "0 10px",
                              lineHeight: "14px",
                            }}
                            className="unit-name-text"
                          >
                            {this.props.translate(option.rpTmpl.name)}{" "}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                  <hr className="hr-divider" />
                </Grid>
                <Grid item xs={12} style={{ position: "relative" }}>
                  <h4
                    style={{
                      margin: "10px 0 0 0",
                      color: this.props.themecolors.textColor,
                    }}
                  >
                    {this.props.translate("selectedUnits")}(
                    {this.props.deviceId.length})
                  </h4>
                  <Grid
                    item
                    xs={12}
                    style={{ position: "relative", marginBottom: 16 }}
                  >
                    {" "}
                    {this.state.geoStep !== 2 && (
                      <div className="report-list-wrapper">
                        {
                          <>
                            <DeviceSelector
                              value={this.props.deviceId}
                              onChange={this.props.selectedDevices}
                              rows={6}
                              themecolors={this.props.themecolors}
                            />
                          </>
                        }
                      </div>
                    )}
                    {this.state.geoReport && this.state.geoStep === 2 && (
                      <div className="report-list-wrapper">
                        <GeofenceSelector
                          value={this.props.geofenceId}
                          onChange={this.props.selectedGeofences}
                          rows={8}
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>

            {this.state.geoReport ? (
              <Grid
                style={{
                  margin: "25px 0",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {this.state.geoStep === 2 && (
                  <Button
                    onClick={(e) => this.changeStep(false)}
                    style={{
                      padding: "6px 20px",
                      color: "#000",
                      borderRadius: 8,
                      background: "white",
                      border: `1px solid ${this.props.themecolors.menuActiveBackground}`,
                    }}
                  >
                    {this.props.translate("back")}
                  </Button>
                )}
                {this.state.geoStep !== 2 && (
                  <Button
                    onClick={(e) => this.changeStep(true)}
                    style={{
                      padding: "6px 20px",
                      margin: "0px 5px",
                      color: "#000",
                      borderRadius: 8,
                      background: "white",
                      border: `1px solid ${this.props.themecolors.menuActiveBackground}`,
                    }}
                  >
                    {this.props.translate("next")}
                  </Button>
                )}
              </Grid>
            ) : null}
            {this.state.geoStep === 2 || !this.state.geoReport ? (
              <Grid
                style={{
                  margin: "25px 0",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  disabled={this.props.disableSubmit}
                  onClick={(e) => this.props.createReport("downloadExcel")}
                  style={{
                    padding: "6px 20px",
                    margin: "0px 5px",
                    color: this.props.disableSubmit ? "#000" : "#fff",
                    borderRadius: 8,
                    background: !this.props.disableSubmit
                      ? this.props.themecolors.menuActiveBackground
                      : "white",
                    border: `1px solid ${this.props.themecolors.menuActiveBackground}`,
                  }}
                >
                  {this.props.translate("Excel")}
                </Button>
                <Button
                  disabled={this.props.disableSubmit}
                  onClick={(e) => this.props.createReport("downloadPdf")}
                  style={{
                    padding: "6px 20px",
                    color: this.props.disableSubmit ? "#000" : "#fff",
                    borderRadius: 8,
                    background: !this.props.disableSubmit
                      ? this.props.themecolors.menuActiveBackground
                      : "white",
                    border: `1px solid ${this.props.themecolors.menuActiveBackground}`,
                  }}
                >
                  {this.props.translate("PDF")}
                </Button>
                <Button
                  disabled={this.props.disableSubmit}
                  onClick={(e) => this.props.createReport(e)}
                  style={{
                    padding: "6px 20px",
                    margin: "0px 5px",
                    color: this.props.disableSubmit ? "#000" : "#fff",
                    borderRadius: 8,
                    background: !this.props.disableSubmit
                      ? this.props.themecolors.menuActiveBackground
                      : "white",
                    border: `1px solid ${this.props.themecolors.menuActiveBackground}`,
                  }}
                >
                  {this.props.translate("submit")}
                </Button>
              </Grid>
            ) : null}
          </>
        )}
      </form>
    );

    return (
      <aside className="fms-sidebar sidebar-bg-layer">
        <Scrollbar disableTracksWidthCompensation>{viewScreen}</Scrollbar>
      </aside>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    notificationType: state.notificationType,
  };
};

const day = [
  {
    id: "1",
    name: "su",
  },
  {
    id: "2",
    name: "mo",
  },
  {
    id: "3",
    name: "tu",
  },
  {
    id: "4",
    name: "we",
  },
  {
    id: "5",
    name: "th",
  },
  {
    id: "6",
    name: "fr",
  },
  {
    id: "7",
    name: "sa",
  },
];

export default connect(mapStateToProps)(ReportsSidebar);

const SensorSelector = (props) => {
  const [scale, setScale] = useState(props.value);

  const addMore = () => {
    const newscale = [...scale];
    newscale.push({ input: "", index: "", value: "" });
    setScale(newscale);
  };

  const deleteRow = (index) => {
    let newscale = [...scale];
    newscale.splice(index, 1);
    setScale(newscale);

    props.handleChange(newscale);
  };

  const handleChange = (name, value, row) => {
    if (name === "allSensors") {
      if (value.target.checked) {
        const reset = [{ input: "", index: "", value: "allSensors" }];
        setScale(reset);
        props.handleChange(reset);
      } else {
        const reset = [{ input: "", index: "", value: "" }];
        setScale(reset);
        props.handleChange(reset);
      }
    } else {
      row[name] = value;
      row.value = row.input + (row.index !== undefined ? row.index : "");

      const newscale = [...scale];
      setScale(newscale);

      props.handleChange(newscale);
    }
  };

  const suggestions = props.digital
    ? [
        "ignition",
        "panicButton",
        "externalPower",
        "enginStatus",
        "carAlarm",
        "doors",
        "custom",
      ]
    : [
        "driverUniqueId",
        "passengerId",
        "trailerUniqueId",
        "ignition",
        "panicButton",
        "externalPower",
        "enginStatus",
        "carAlarm",
        "doors",
        "fuel",
        "temperature",
        "rpm",
        "boardPower",
        "mileage",
        "speed",
        "counter",
        "averagFuelConsumption",
        "instantFuelConsumption",
        "flowMeter",
        "humidity",
        "custom",
        "weight",
      ];

  return (
    <div style={{ marginBottom: 16, width: "100%" }}>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <div
            className="pull-right"
            style={{ display: "inline-flex", alignItems: "center" }}
          >
            {!props.allSensors && (
              <Tooltip
                title={props.translate("addMore")}
                classes={{
                  popper: "menu-popper",
                  tooltip: "menu-popper-tooltip",
                }}
              >
                <a href={null} onClick={addMore} style={{ color: "inherit" }}>
                  <AddBoxIcon />
                </a>
              </Tooltip>
            )}
            <Checkbox
              checked={props.allSensors}
              onChange={(e) => handleChange("allSensors", e)}
              label={props.translate("selectAll")}
            />
          </div>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          {!props.allSensors &&
            scale.map((row, index) => (
              <Grid key={index} container spacing={0} className="sensors-rows">
                <Grid item xs={scale.length > 1 ? 11 : 12}>
                  <Grid container spacing={2}>
                    <Grid item md={8} xs={12}>
                      <Autocomplete
                        value={row.input || ""}
                        suggestions={suggestions}
                        handleChange={(e, v) => handleChange(e, v, row)}
                        fieldName="input"
                        label={props.translate("sensorType")}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        type="number"
                        value={row.index}
                        onChange={(e) =>
                          handleChange("index", e.target.value, row)
                        }
                        variant="outlined"
                        margin="dense"
                        label={props.translate("index")}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {scale.length > 1 ? (
                  <Grid
                    item
                    xs={1}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <DeleteIcon
                      onClick={(e) => deleteRow(index)}
                      style={{ cursor: "pointer" }}
                    />
                  </Grid>
                ) : null}
              </Grid>
            ))}
        </Grid>
      </Grid>
    </div>
  );
};
