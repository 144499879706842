import React, { Component } from "react";
import Button from "./../common/Button";
import TextField from "./../common/TextField";
import { SketchPicker } from "react-color";
import parse from "html-react-parser";

export class EditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.showColorPicker = this.showColorPicker.bind(this);
    this.hideColorPicker = this.hideColorPicker.bind(this);
    this.updateColor = this.updateColor.bind(this);
  }

  showColorPicker(e) {
    this.setState({
      colorPicker: true,
    });
  }

  updateName(e) {
    this.props.updateName(e.target.value, this.props.vector.id);
  }
  updateColor(e) {
    this.props.updateColor(e.hex);
  }
  updateDescription(e) {
    this.props.updateDescription(e.target.value);
  }

  updateCalendar = (e) => {
    this.props.updateCalendar(e.value);
  };
  updateSpeedLimit(e) {
    this.props.updateSpeedLimit(e.target.value);
  }

  cancel(e) {
    this.props.cancel();
  }
  save(e) {
    this.props.save();
  }

  updatePolylineDistance(e) {
    this.props.updatePolylineDistance(e.target.value);
  }

  hideColorPicker(e) {
    this.setState({
      colorPicker: false,
    });
  }

  render() {
    const { vector } = this.props;
    return (
      <form>
        <TextField
          fullWidth
          value={vector.name}
          label={this.props.translate("sharedName")}
          margin="dense"
          onChange={(e) => this.updateName(e)}
        />
        <p>
          <label>{this.props.translate("selectColor")}</label>

          <div
            className="colorPicker-trigger"
            style={{
              background:
                vector.attributes && vector.attributes.color
                  ? vector.attributes.color
                  : "",
              display: "inline-block",
              width: 28,
              height: 28,
              marginLeft: 10,
              verticalAlign: "middle",
              position: "relative",
              zIndex: 9,
            }}
            onClick={this.showColorPicker}
          >
            {this.state.colorPicker && (
              <div
                style={{ position: "absolute", top: 0, left: "-50%" }}
                onMouseLeave={this.hideColorPicker}
              >
                <SketchPicker
                  color={vector.attributes.color}
                  onChangeComplete={this.updateColor}
                />
              </div>
            )}
          </div>
        </p>
        <TextField
          fullWidth
          value={vector.description}
          label={this.props.translate("sharedDescription")}
          rows={5}
          multiline
          margin="dense"
          onChange={(e) => this.updateDescription(e)}
        />
        {/* <div style={{ zIndex: 2, position: 'relative' }}>
          <SearchCalendars
            isMulti={false}
            api='calendars'
            onChange={this.updateCalendar}
            placeholder={this.props.translate('searchCalendar')}
          />
        </div> */}

        <TextField
          type="number"
          fullWidth
          value={vector.attributes.speedLimit}
          label={this.props.translate("attributeSpeedLimit") + "(km)"}
          margin="dense"
          onChange={(e) => this.updateSpeedLimit(e)}
        />
        {vector.attributes && vector.attributes.type === "polyline" ? (
          <TextField
            type="number"
            fullWidth
            value={vector.attributes.polylineDistance}
            label={this.props.translate("attributePolylineDistance") + "(km)"}
            margin="dense"
            onChange={(e) => this.updatePolylineDistance(e)}
          />
        ) : null}

        <p>
          <Button onClick={(e) => this.save(e)}>
            {" "}
            {this.props.translate("sharedSave")}
          </Button>
          <Button
            type="button"
            variant="outlined"
            style={{ marginLeft: 8 }}
            onClick={(e) => this.cancel(e)}
          >
            {this.props.translate("sharedCancel")}
          </Button>
        </p>
      </form>
    );
  }
}

export class EditFormPOI extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  updateName(e) {
    this.props.onChange("name", e.target.value);
  }
  updateDescription(e) {
    this.props.onChange("description", e.target.value);
  }

  cancel(e) {
    this.props.cancel();
  }
  save(e) {
    this.props.save();
  }

  render() {
    const { vector } = this.props;
    return (
      <form>
        <TextField
          fullWidth
          value={vector.name}
          label={this.props.translate("sharedName")}
          margin="dense"
          onChange={(e) => this.updateName(e)}
        />
        <p>
          <TextField
            fullWidth
            value={parse(vector.description)}
            label={this.props.translate("sharedDescription")}
            rows={5}
            multiline
            margin="dense"
            onChange={(e) => this.updateDescription(e)}
          />
        </p>
        <Button onClick={(e) => this.save(e)}>
          {this.props.translate("sharedSave")}
        </Button>
        <Button
          type="button"
          normal
          variant="outlined"
          style={{ marginLeft: 8 }}
          onClick={(e) => this.cancel(e)}
        >
          {this.props.translate("sharedCancel")}
        </Button>
      </form>
    );
  }
}
