import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
const mapStateToProps = (state, ownProps) => {
  return {
    themecolors: state.themeColors,
  };
};

const styles = (theme) => ({});

class CustomTextField extends React.PureComponent {
  render() {
    const { classes } = this.props;

    const p = { ...this.props };

    delete p.dispatch;

    return (
      <div>
        <TextField
          margin="dense"
          {...p}
          variant="outlined"
          classes={classes}
          // style={{ height: "22px" }}
          InputProps={{
            ...this.props.InputProps,
            classes: {
              root: "theme-cssOutlinedInput",
              error: "theme-input-error",
              input: this.props.multiline ? "theme-textarea" : "theme-input",
              focused: "theme-cssFocused",
              notchedOutline: "theme-notchedOutline",
            },
          }}
          SelectProps={{
            MenuProps: {
              classes: {
                paper: "custom-menu-paper",
              },
            },
            classes: {
              icon: "menu-list-icon",
            },
          }}
          InputLabelProps={{
            ...this.props.InputLabelProps,
            classes: {
              root: "theme-label",
              focused: "theme-label-focused",
              shrink: "theme-label-shrink",
            },
          }}
        />
      </div>
    );
  }
}

CustomTextField.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(CustomTextField));
