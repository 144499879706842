import React from "react";
import Grid from "@mui/material/Grid";
import Radio from "../../../common/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "../../../common/TextField";
import Switch from "../../../common/Switch";
import Tooltip from "../../../common/Tooltip";
import { ReactComponent as AddNewIcon } from "../../../../assets/add-new.svg";

import Collapse from "@mui/material/Collapse";
import DeleteIcon from "@mui/icons-material/Delete";
import Paper from "@mui/material/Paper";
import { CompactPicker } from "react-color";
import { IconButton } from "@mui/material";
import SingleSelect from "../../../common/SingleSelect";
import Checkbox from "../../../common/Checkbox";

const Modal = (props) => {
  const paperStyle = {
    background: "none",
    marginBottom: 5,
    marginTop: 5,
    paddingTop: 0,
    paddingBottom: 0,
  };
  const HeadingStyle = {
    display: "flex",
    alignItems: "center",
    margin: 0,
    padding: "0 12px",
    borderRadius: 6,
    background: props.themecolors.menuBackgroundColor,
  };
  let createdCommands = [];
  props.commands &&
    props.commands.map((e) => {
      createdCommands.push({
        id: e.id,
        key: e.id,
        name: e.description,
        valueType: "string",
      });
    });
  return (
    <Grid
      container
      style={{
        minHeight: 200,
        display: "block",
        color: props.themecolors.textColor,
      }}
    >
      <Paper
        className={paperStyle}
        style={{
          background: props.isOpenEmailSection
            ? props.themecolors.menuBackgroundColor
            : "transparent",
          margin: "5px 0",
        }}
        elevation={0}
      >
        <Grid item sm={12} xs={12} style={{ display: "flex", padding: 14 }}>
          <Checkbox
            checked={props.isOpenEmailSection}
            value={props.isOpenEmailSection}
            onChange={() => props.handleClick("isOpenEmailSection")}
          />

          <h4 className="page-title-notification" style={HeadingStyle}>
            <span>{props.translate("emails")}</span>
          </h4>
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Collapse in={props.isOpenEmailSection} style={{ padding: "0 16px" }}>
            <Grid container>
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  style={{ width: "max-content" }}
                >
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={props.form.mailTo}
                    onChange={(e) => props.handleChange("mailTo", e)}
                  >
                    <FormControlLabel
                      classes={{
                        label: "form-contol-label",
                      }}
                      value="user"
                      control={<Radio />}
                      label={props.translate("userEmail")}
                      labelPlacement="end"
                      disabled={!props.isOpenEmailSection}
                    />
                    <FormControlLabel
                      classes={{
                        label: "form-contol-label",
                      }}
                      value="custom"
                      control={<Radio />}
                      label={props.translate("customEmails")}
                      labelPlacement="end"
                      disabled={!props.isOpenEmailSection}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {props.form.mailTo !== "user" && (
                <Grid item xs={12}>
                  {props.form.email.map((item) => {
                    return (
                      <div style={{ display: "flex" }} key={item.id}>
                        <TextField
                          id="email"
                          label={props.translate("userEmail")}
                          placeholder=""
                          value={item.text || ""}
                          onChange={(e) =>
                            props.handleChange("email", e, item.id)
                          }
                          disabled={!props.isOpenEmailSection}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                        {item.id === props.form.email.length && (
                          <Tooltip title={props.translate("addAnother")}>
                            <span>
                              <IconButton
                                color="inherit"
                                aria-label="directions"
                                onClick={(e) => props.onAddAnotherMail("email")}
                                disabled={!props.isOpenEmailSection}
                              >
                                <AddNewIcon
                                  width={18}
                                  height={18}
                                  style={{ fill: "currentColor" }}
                                />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                        {1 < props.form.email.length && (
                          <Tooltip title={props.translate("delete")}>
                            <span>
                              <IconButton
                                color="inherit"
                                aria-label="directions"
                                onClick={(e) =>
                                  props.deleteMail("email", item.id)
                                }
                                disabled={!props.isOpenEmailSection}
                              >
                                <DeleteIcon
                                  width={18}
                                  height={18}
                                  style={{ fill: "currentColor" }}
                                />
                              </IconButton>
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </Collapse>
        </Grid>
      </Paper>
      <Grid item sm={12} xs={12} />

      <Paper
        className={paperStyle}
        style={{
          background: props.isOpenWebSection
            ? props.themecolors.menuBackgroundColor
            : "transparent",
          margin: "5px 0",
        }}
        elevation={0}
      >
        <Grid item sm={12} xs={12} style={{ display: "flex", padding: 14 }}>
          <Checkbox
            checked={props.isOpenWebSection}
            value={props.isOpenWebSection}
            onChange={() => props.handleClick("isOpenWebSection")}
          />
          <h4 className="page-title-notification" style={HeadingStyle}>
            <span>{props.translate("webPopUp")}</span>
          </h4>
        </Grid>
        <Grid
          item
          sm={12}
          xs={12}
          style={{ display: "flex", alignItems: "center" }}
        >
          <Collapse in={props.isOpenWebSection} style={{ padding: "0 16px" }}>
            <Grid container>
              <Grid item xs={12}>
                <div>
                  <label>{props.translate("selectColor")}</label>
                  <div
                    className="colorPicker-trigger"
                    style={{
                      background:
                        props.form && props.form.alertColor
                          ? props.form.alertColor
                          : "red",
                      display: "inline-block",
                      width: 18,
                      height: 18,
                      marginLeft: 10,
                      verticalAlign: "middle",
                      position: "relative",
                      zIndex: 9,
                      borderRadius: "50%",
                      pointerEvents: props.isOpenWebSection ? "auto" : "none",
                    }}
                    onClick={props.showColorPicker}
                  >
                    {props.colorPicker && (
                      <div
                        style={{ position: "absolute", top: 0, left: "-50%" }}
                        onMouseLeave={props.hideColorPicker}
                      >
                        <CompactPicker
                          color={props.form.alertColor}
                          onChangeComplete={props.updateColor}
                          //disabled={!props.isOpenWebSection}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Collapse>
        </Grid>
      </Paper>

      <Grid item sm={12} xs={12} />
      <Paper
        className={paperStyle}
        style={{
          background: props.isOpenFirebaseSection
            ? props.themecolors.menuBackgroundColor
            : "transparent",
          margin: "5px 0",
        }}
        elevation={0}
      >
        <Grid item sm={12} xs={12} style={{ display: "flex", padding: 14 }}>
          <Checkbox
            checked={props.isOpenFirebaseSection}
            value={props.isOpenFirebaseSection}
            onChange={() => props.handleClick("isOpenFirebaseSection")}
          />
          <h4 className="page-title-notification" style={HeadingStyle}>
            <span>{props.translate("mobileNotifications")}</span>
          </h4>
        </Grid>
      </Paper>

      <Grid item sm={12} xs={12} />
      <Paper
        className={paperStyle}
        style={{
          background: props.isOpenCommandSection
            ? props.themecolors.menuBackgroundColor
            : "transparent",
          margin: "5px 0",
        }}
        elevation={0}
      >
        <Grid item sm={12} xs={12} style={{ display: "flex", padding: 14 }}>
          <Checkbox
            checked={props.isOpenCommandSection}
            value={props.isOpenCommandSection}
            onChange={() => props.handleClick("isOpenCommandSection")}
          />
          <h4 className="page-title-notification" style={HeadingStyle}>
            <span>{props.translate("sharedSavedCommands")}</span>
          </h4>
        </Grid>
        <Grid item sm={12} xs={12}>
          <Collapse
            in={props.isOpenCommandSection}
            style={{ padding: "0 16px" }}
          >
            <Grid container>
              <Grid item xs={12}>
                <FormControl
                  component="fieldset"
                  style={{ width: "max-content" }}
                >
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    value={props.form.commandType}
                    onChange={(e) => props.handleChange("commandType", e)}
                  >
                    <FormControlLabel
                      classes={{
                        label: "form-contol-label",
                      }}
                      value="command"
                      control={<Radio />}
                      label={props.translate("sharedSavedCommands")}
                      labelPlacement="end"
                      disabled={!props.isOpenCommandSection}
                    />
                    <FormControlLabel
                      classes={{
                        label: "form-contol-label",
                      }}
                      value="custom"
                      control={<Radio />}
                      label={props.translate("commands.custom")}
                      labelPlacement="end"
                      disabled={!props.isOpenCommandSection}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {props.form.commandType === "command" && (
                <Grid item xs={12}>
                  <div
                    style={{ display: "flex" }}
                    // key={item.id}
                  >
                    <Grid item xs={12}>
                      <SingleSelect
                        array={createdCommands}
                        selectName="type"
                        label={"sharedType"}
                        value={
                          props.form.commandId !== 0
                            ? {
                                key: props.form.commandId,
                                id: props.form.commandId,
                                value: props.form.commandId,
                                label: props.form.commandName,
                              }
                            : ""
                        }
                        handleChange={props.handleChange}
                        canRemove={true}
                        canAssign={true}
                      />
                    </Grid>
                  </div>
                </Grid>
              )}
              {props.form.commandType === "custom" && (
                <Grid item xs={12}>
                  <div
                    style={{ display: "flex" }}
                    // key={item.id}
                  >
                    <TextField
                      id="command"
                      label={props.translate("commands.custom")}
                      placeholder=""
                      value={props.form.commandData || ""}
                      onChange={(e) => props.handleChange("commandData", e)}
                      disabled={!props.isOpenCommandSection}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </div>
                </Grid>
              )}
            </Grid>
          </Collapse>
        </Grid>
      </Paper>
    </Grid>
  );
};
export default Modal;
