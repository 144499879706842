import React, { Component } from "react";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import Button from "./../common/Button";
import isEqual from "react-fast-compare";
import Panel from "./../common/Panel";
import {
  filterGreaterThan2,
  ReactBaseTable,
  SliderColumnFilter2,
} from "../common/ReactBaseTable";
import Dialog from "../common/Dialog";
import { FormControlLabel, Grid } from "@mui/material";
import Switch from "../common/Switch";

export default class DetailView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      eventsDialog: false,
      loading: false,
    };
  }

  handleClick = (event) => {
    event.stopPropagation();
    this.setState({ eventsDialog: true });
  };

  handleChange = (event, name) => {
    this.props.handleChange(event, name);
  };

  onCloseModal = (event) => {
    this.setState({ eventsDialog: false });
  };

  componentDidUpdate() {
    this.props.onRender();
  }

  setColumns = (columns) => {
    this.setState({
      columns,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  renderRowSubComponent = ({ row }) => (
    <ReactBaseTable
      id={"childTable_" + row.id}
      columns={this.props.columns}
      hiddenColumns={this.props.hiddenColumns}
      data={row.original.rows}
      total={row.original.total}
    />
  );

  render() {
    const { table /* , disableFilter, toggleFilter */ } = this.props;
    const { eventsDialog } = this.state;
    return (
      <div>
        <Panel
          title={this.props.title}
          bodyPadding={0}
          open={true}
          headerActions={
            <>
              {/* <Button
              size='small'
              color='inherit'
              style={{marginLeft: 'auto' }}
              onClick={e => toggleFilter(e)}
              >
                {disableFilter ? 'Show Filter' : 'Hide Filter'}
                
              </Button> |  */}
              <Button
                size="small"
                color="inherit"
                style={{ marginLeft: "auto", color: "#fff" }}
                onClick={(e) => this.handleClick(e, "tripsDialog")}
              >
                Show/Hide Columns
              </Button>
            </>
          }
        >
          <ReactBaseTable
            id="parentTable"
            disableFooter
            columns={[
              {
                // Build our expander column
                id: "expander", // Make sure it has an ID
                Header: ({
                  getToggleAllRowsExpandedProps,
                  isAllRowsExpanded,
                }) => (
                  <span {...getToggleAllRowsExpandedProps()}>
                    {isAllRowsExpanded ? (
                      <IndeterminateCheckBoxIcon />
                    ) : (
                      <AddBoxIcon />
                    )}
                  </span>
                ),
                Cell: ({ row }) => (
                  <span {...row.getToggleRowExpandedProps()}>
                    {row.isExpanded ? (
                      <IndeterminateCheckBoxIcon />
                    ) : (
                      <AddBoxIcon />
                    )}
                  </span>
                ),
                width: 35,
              },
              {
                Header: "",
                accessor: "head",
                width: "100%",
                filter: filterGreaterThan2,
                Filter: SliderColumnFilter2,
              },
            ]}
            data={table.data}
            renderRowSubComponent={this.renderRowSubComponent}
          />
        </Panel>

        {eventsDialog && (
          <Dialog
            key="eventsDialog"
            visable={true}
            title="Show/Hide Columns"
            onClose={(e) => this.onCloseModal(e)}
            isButtonVisable={true}
            draggable={true}
            cancelText="Close"
            bodyPadding={20}
          >
            <Grid container direction="row">
              {Object.keys(this.props.dialogColumns).map((k) => (
                <Grid item lg={6} key={k}>
                  <FormControlLabel
                    key={k}
                    classes={{ label: "form-control-label" }}
                    control={
                      <Switch
                        key={k}
                        checked={this.props.dialogColumns[k].checked}
                        onChange={(e) => this.handleChange(e, k)}
                        value={k}
                      />
                    }
                    label={this.props.dialogColumns[k].label}
                  />
                </Grid>
              ))}
            </Grid>
          </Dialog>
        )}
      </div>
    );
  }
}
