import React, { Component } from "react";
import TextField from "../../../Components/common/TextField";
import MenuItem from "../../../Components/common/MenuItem";
import Grid from "@mui/material/Grid";
import Button from "../../../Components/common/Button";
import SingleSelect from "../../../Components/common/SingleSelect";
import Autocomplete from "../../../Components/common/Autocomplete";
import Table from "../../../Components/common/TableMultiCheckbox";
import CustomDialog from "../../../Components/common/Dialog";
import parse from "html-react-parser";
import { connect } from "react-redux";
import {
  checkPrivileges,
  postionAttributes,
  errorHandler,
} from "../../../Helpers";
import { Tooltip } from "@mui/material";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import "@webscopeio/react-textarea-autocomplete/style.css";
import instance from "../../../axios";
class ComputedAttributes1 extends Component {
  constructor(props) {
    super(props);
    this.C_Att_handleChange = this.C_Att_handleChange.bind(this);
    this.state = { openDevices: false, selected: null, result: "" };
    this.selectUnit = this.selectUnit.bind(this);
  }

  C_Att_handleChange(name, value) {
    if (this.props.attributeHandle) {
      this.props.attributeHandle(name, value);
    }
  }

  runTest = () => {
    let device = this.props.devices.find(
      (d) => parseInt(d.id) === parseInt(this.props.id)
    );
    if (device && device.id) {
      this.setState({
        selected: {
          id: this.props.id,
          label: device.name,
          key: this.props.id,
          value: this.props.id,
        },
        openDevices: true,
      });
    }
  };
  closeDevices = () => {
    this.setState({ openDevices: false, selected: "", result: "" });
  };
  selectUnit(e, r) {
    this.setState({ selected: { ...r, value: r.id } });
  }
  componentDidMount() {}
  executeTest = () => {
    let body = { ...this.props.computedAttForm };

    delete body.check;

    let id = (this.state.selected && this.state.selected.id) || this.props.id;

    instance({
      url: `/api/attributes/computed/test`,
      method: "POST",
      params: {
        deviceId: id,
      },
      data: {
        ...body,
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     if (response.status === 200) {
      //       response.json()
      .then((result) => {
        // if (result.status === 'success') {
        this.setState({ result: "Result: " + result });
        // } else {
        //   this.setState({ result: 'Result: ' + result.message })
        // }
      })
      //     }
      //   } else {
      //     throw response
      //   }
      // })
      // .catch(e => {
      //   if (e && e.text) {
      //     e.text().then(err => {

      //     })
      //   }
      // })
      .catch((error) => {
        errorHandler(error, this.props.dispatch, this.errorCallBack);
      });
  };
  datafilter = (data) => {
    let rows = [];
    if (data.length) {
      data.map((item) => {
        if (item.category === 0) {
          rows.push(item);
        }
        return null;
      });
      return rows;
    }
    return rows;
  };

  render() {
    // let attr = postionAttributes.map(a => a.key)
    return (
      <Grid container style={{ height: "100%" }}>
        <Grid item sm={this.props.visableAttribute ? 8 : 12} xs={12}>
          <Table
            createButton={
              checkPrivileges("attributeCreate") && (
                <div style={{ marginLeft: 10 }}>
                  <Button onClick={() => this.props.onCreateNewAttributes()}>
                    {this.props.translate("Create")}
                  </Button>
                </div>
              )
            }
            title={this.props.translate("sharedComputedAttributes")}
            insideDialog
            disableHead
            showCheckbox
            checkHandleChange={this.props.computedAttributes_Selection}
            rows={this.datafilter(this.props.computedAttributesData)}
            rowsPerPage={this.props.rowsPerPage}
            handleChangeRowsPerPage={this.props.handleChangeRowsPerPage}
            isEditable
            canUpdate={checkPrivileges("attributeUpdate")}
            canDelete={checkPrivileges("attributeDelete")}
            canRemove={this.props.canAssign}
            canAssign={this.props.canRemove}
            onEdit={this.props.onEdit}
            onDelete={this.props.onDelete}
            themecolors={this.props.themecolors}
            selectAll={this.props.selectAll}
            searchable
            className="custom-table2"
            rowDefinition={[
              {
                id: "description",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("sharedDescription"),
              },
              {
                id: "attribute",
                numeric: false,
                disablePadding: false,
                label: this.props.translate("sharedComputedAttribute"),
                translate: true,
              },
              //{ id: 'expression', numeric: false, disablePadding: false, label: this.props.translate('sharedExpression')},
              //{ id: 'type', numeric: false, disablePadding: false, label: this.props.translate('sharedType') },
            ]}
          />
        </Grid>
        {this.props.visableAttribute && (
          <Grid item sm={4} xs={12}>
            <div
              style={{
                padding: "1px 20px",
                borderLeft:
                  "1px solid " + this.props.themecolors.themeLightColor,
                height: "100%",
              }}
            >
              <h4>{this.props.translate("sharedComputedAttribute")}</h4>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    id="description"
                    label={this.props.translate("sharedDescription")}
                    type="text"
                    value={this.props.computedAttForm.description}
                    onChange={this.props.C_Att_handleChange("description")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    suggestions={postionAttributes}
                    fieldName="attribute"
                    translate={this.props.translate}
                    label={this.props.translate("sharedAttribute")}
                    value={
                      this.props.computedAttForm.attribute
                        ? this.props.computedAttForm.attribute
                        : ""
                    }
                    handleChange={this.C_Att_handleChange}
                    canRemove={
                      checkPrivileges("attributeCreate") ||
                      checkPrivileges("attributeUpdate")
                    }
                    canAssign={
                      checkPrivileges("attributeCreate") ||
                      checkPrivileges("attributeUpdate")
                    }
                  />
                </Grid>
                {this.props.type === "units" ? (
                  <Grid item xs={12} style={{ marginTop: 8 }}>
                    <Tooltip
                      classes={{
                        popper: "menu-popper",
                        tooltip: "menu-popper-tooltip",
                      }}
                      placement="bottom-start"
                      title={
                        <>
                          {this.props.translate("InputStartingWith")}
                          distance = speed*time.
                        </>
                      }
                    >
                      <Grid xs={12}>
                        <ReactTextareaAutocomplete
                          style={{ maxWidth: "100%" }}
                          onChange={this.props.C_Att_handleChange("expression")}
                          loadingComponent={() => <span>Loading</span>}
                          minChar={0}
                          rows={4}
                          placeholder={this.props.translate(
                            "sharedExpressionLabel"
                          )}
                          className="custom-theme-input theme-notchedOutline"
                          value={this.props.computedAttForm.expression}
                          trigger={{
                            ":": {
                              dataProvider: (token) => {
                                return this.props.suggestions
                                  .filter((r) => r.includes(token))
                                  .map((row) => ({
                                    name: row,
                                  }));
                              },
                              component: ({ entity: { name } }) => {
                                return <div>{`${name}`}</div>;
                              },
                              output: (item, trigger) => item.name,
                            },
                          }}
                        />
                      </Grid>
                    </Tooltip>
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <TextField
                      id="expression"
                      label={this.props.translate("sharedExpressionLabel")}
                      multiline
                      rowsMax="8"
                      rows="4"
                      value={this.props.computedAttForm.expression}
                      onChange={this.props.C_Att_handleChange("expression")}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <TextField
                    id="type"
                    select
                    label={this.props.translate("sharedType")}
                    value={this.props.computedAttForm.type}
                    onChange={this.props.C_Att_handleChange("type")}
                    margin="dense"
                    fullWidth
                  >
                    {att_valueType.map((option) => (
                      <MenuItem key={option.Type} value={option.Type}>
                        {this.props.translate(option.Type)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <div style={{ textAlign: "right", marginTop: 10 }}>
                {
                  <Button
                    size="small"
                    style={{ marginRight: 15 }}
                    onClick={this.runTest}
                  >
                    {" "}
                    {this.props.translate("runTest")}
                  </Button>
                }
                {checkPrivileges(this.props.isHaveAccess) && (
                  <Button
                    disabled={!this.props.isAddVisableBtn}
                    size="small"
                    onClick={() => this.props.addComputedAttributes()}
                  >
                    {" "}
                    {this.props.translate(this.props.addButtonStatus)}
                  </Button>
                )}
              </div>
            </div>
          </Grid>
        )}

        {this.state.openDevices && (
          <CustomDialog
            themecolors={this.props.themecolors}
            visable={false}
            title={this.props.translate("units")}
            onClose={this.closeDevices}
            isButtonVisable={false}
            draggable
            dialogHeight={240}
            isVisableBtn={
              checkPrivileges("attributeCreate") ||
              checkPrivileges("attributeUpdate")
            }
            headerActions={
              <Button
                size="small"
                style={{
                  background: this.props.themecolors.backgroundColor,
                  color: this.props.themecolors.textColor,
                }}
                variant="contained"
                color="secondary"
                onClick={this.executeTest}
              >
                {" "}
                {this.props.translate("runTest")}
              </Button>
            }
            bodyPadding={20}
          >
            <SingleSelect
              array={this.props.devices.map((d) => {
                return { ...d, key: d.id };
              })}
              selectName="unit"
              label={this.props.translate("pleaseSelectedUnit")}
              value={this.state.selected || { key: "", label: "", id: "" }}
              handleChange={this.selectUnit}
              canRemove={
                checkPrivileges("attributeCreate") ||
                checkPrivileges("attributeUpdate")
              }
              canAssign={
                checkPrivileges("attributeCreate") ||
                checkPrivileges("attributeUpdate")
              }
            />
            {this.state.result && (
              <div
                style={{
                  color: this.props.themecolors["error"],
                  fontWeight: 700,
                  fontStyle: "italic",
                }}
              >
                {parse(this.state.result)}
              </div>
            )}
          </CustomDialog>
        )}
      </Grid>
    );
  }
}

const mapState = (state) => ({
  devices: state.devices.data,
});

const mapStateToProps = connect(mapState);
export const ComputedAttributes = mapStateToProps(ComputedAttributes1);

const att_valueType = [
  {
    Type: "string",
  },
  { Type: "number" },
  { Type: "boolean" },
];
