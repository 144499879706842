import React from "react";
import { FormControlLabel, Grid } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from "../../Components/common/Checkbox";
import Table from "../../Components/common/tableWithBackEndPagination";
import MenuItem from "../../Components/common/MenuItem";
import Menu from "../../Components/Menu/CommonMenu";
import Button from "../../Components/common/Button";
import { checkPrivileges } from "../../Helpers";
import Loader from "../../Layout/Loader";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ExportImportMenu from "../../Components/Maps/ExportImportMenu";

export const MaintenanceHistoryForm = ({
  data,
  translate,
  setPagination,
  isDataRecieved,
  // histFormVisible,
  openCreateFrom,
  imagePopup,
  handleChangeRowsPerPage,
  handleChangePage,
  SearchItem,
  SearchVehicle,
  handleChangeFilter,
  handleClickFilter,
  handleCloseFilter,
  countChecked,
  statusFilter,
  anchorEl,
  onRemove,
  mode,
  vehicles,
  switchMode,
  themecolors,
  editHistoryFrom,
  deviceRelatedData,
  dueActive,
  createExpense,
  logInUser,
  expenseForm,
  searchVehicleId,
}) => {
  return (
    <div>
      {
        //  !histFormVisible &&(
        <Grid container style={{ height: "100%" }}>
          <Grid item sm={12} xs={12}>
            {isDataRecieved ? (
              <Table
                rows={(data && data.data) || []}
                pagination={setPagination(data)}
                handleChangeRowsPerPage={(n) =>
                  handleChangeRowsPerPage(n, "maintenanceHist")
                }
                handleChangePage={(n) => handleChangePage(n, "maintenanceHist")}
                SearchItem={(n) => SearchItem(n, "maintenanceHist")}
                SearchVehicle={SearchVehicle}
                onEdit={(n) => editHistoryFrom(n)}
                imagePopup={imagePopup}
                vehicles={vehicles}
                expenseForm={expenseForm}
                searchVehicleId={searchVehicleId}
                createButton={
                  checkPrivileges("serviceCreate") &&
                  !dueActive && (
                    <div style={{ marginLeft: 10 }}>
                      <Button onClick={createExpense}>
                        {translate("Create")}
                      </Button>
                    </div>
                  )
                }
                onDelete={(n) => onRemove(n)}
                hasAccessOfDelete={checkPrivileges("serviceDelete")}
                logInUser={logInUser}
                switchMode={
                  <>
                    <AppBar
                      position="static"
                      color="inherit"
                      style={{ background: "none", boxShadow: "none" }}
                    >
                      <Tabs
                        value={mode}
                        onChange={switchMode}
                        TabIndicatorProps={{
                          style: { display: "none" },
                        }}
                        scrollButtons="on"
                        classes={{
                          root: "custom-tabs-root",
                          flexContainer: "custom-tabs",
                          scrollable: "custom-tabs-scrollable",
                          indicator: "custom-indicator",
                          scrollButtons: "scrollable-buttons",
                        }}
                      >
                        {checkPrivileges("maintenance") && (
                          <Tab
                            value={"showMaintenance"}
                            classes={{
                              root: "common-tab",
                              selected: "tab-selected",
                            }}
                            label={translate("sharedMaintenance")}
                          />
                        )}
                        {checkPrivileges("service") && (
                          <Tab
                            value={"due"}
                            classes={{
                              root: "common-tab",
                              selected: "tab-selected",
                            }}
                            label={
                              translate("work") +
                              " " +
                              translate("due") +
                              "/" +
                              translate("overdue")
                            }
                            // disabled={checkedDisableTab('form2')}
                            // disabled={() => switchMode('showHist', 'due')}
                          />
                        )}
                        {checkPrivileges("service") && (
                          <Tab
                            value={"history"}
                            classes={{
                              root: "common-tab",
                              selected: "tab-selected",
                            }}
                            label={translate("maintenanceHistory")}
                            // disabled={() => switchMode('showHist', 'history')}
                          />
                        )}
                      </Tabs>
                    </AppBar>
                  </>
                }
                isDownload={
                  !dueActive ? (
                    <ExportImportMenu
                      fileName="services"
                      checkPrivilege="serviceCreate"
                      data={(data && data.data) || []}
                      themecolors={themecolors}
                    />
                  ) : null
                }
                isFilter={
                  dueActive ? (
                    <Filter
                      countChecked={countChecked}
                      anchorEl={anchorEl}
                      handleChangeFilter={handleChangeFilter}
                      handleClickFilter={handleClickFilter}
                      handleCloseFilter={handleCloseFilter}
                      translate={translate}
                      statusFilter={statusFilter}
                    />
                  ) : null
                }
                // onDelete={this.removeUser}----
                isEditable={true}
                deviceRelatedData={deviceRelatedData}
                completeIcon={mode === "due" ? true : false}
                themecolors={themecolors}
                translate={translate}
                searchable
                isSearchVehicle
                hasAccessOfUpdate={checkPrivileges("serviceUpdate")}
                // hasAccessOfDelete={checkPrivileges('userDelete')}----
                // title={this.props.translate('users')}----------
                rowDefinition={[
                  {
                    id: "vehicleName",
                    numeric: false,
                    disablePadding: false,
                    label: translate("sharedVehicle"),
                  },
                  {
                    id: "name",
                    numeric: false,
                    disablePadding: false,
                    label: translate("serviceMaintenance"),
                  },
                  {
                    id: "status", //attribute.status
                    numeric: false,
                    disablePadding: false,
                    label: translate("status"),
                    hide: mode === "due" ? false : true,
                  },
                  {
                    id: "odometer", //'attributes.cDate',
                    numeric: false,
                    disablePadding: false,
                    label: translate("totalDistance"),
                  },
                  {
                    id: "engineHours", //'attributes.cDate',
                    numeric: false,
                    disablePadding: false,
                    label: translate("reportEngineHours"),
                  },
                  {
                    id: "serviceTime", //'attributes.cDate',
                    numeric: false,
                    disablePadding: false,
                    label: translate("date"),
                  },
                  {
                    id: "files",
                    numeric: false,
                    disablePadding: false,
                    label: translate("sharedFile"),
                    hide: mode === "due" ? true : false,
                  },
                  {
                    id: "cost", //attribute.cost
                    numeric: false,
                    disablePadding: false,
                    label: translate("cost"),
                  },
                ]}
              />
            ) : (
              <Loader />
            )}
            {/* {!isDataRecieved && <Loader/>} */}
          </Grid>
        </Grid>
        // )
      }
    </div>
  );
};
const Filter = (props) => {
  const html = (
    <div>
      <a
        href={null}
        aria-owns={props.anchorEl ? "simple-menu" : undefined}
        aria-haspopup="true"
        onClick={props.handleClickFilter}
      >
        <p className="v-tab-heading pull-left" style={{ width: 100 }}>
          {props.translate("selected")}
          {": "}
          {props.countChecked}
        </p>
        <ArrowDropDownIcon style={{ color: "#FFFFFF" }} />
      </a>
      <Menu
        id="simple-menu"
        anchorEl={props.anchorEl}
        open={Boolean(props.anchorEl)}
        onClose={props.handleCloseFilter}
      >
        {/* <MenuItem component={FormControlLabel} style={{margin: 0, paddingTop: 0, paddingBottom: 0}}
        control={<Checkbox 
          name='Completed'
          checked={props.statusFilter.history}
          onChange={e=>props.handleChangeFilter('history',e)} 
          canAssign
          canRemove
        />}
        label={props.translate('completed')}
      /> */}
        <MenuItem
          component={FormControlLabel}
          style={{ margin: 0 }}
          control={
            <Checkbox
              name="Required"
              checked={props.statusFilter.required}
              onChange={(e) => props.handleChangeFilter("required", e)}
              canAssign
              canRemove
            />
          }
          label={
            <span style={{ marginLeft: 8 }}>{props.translate("due")}</span>
          }
        />
        <MenuItem
          component={FormControlLabel}
          style={{ margin: 0 }}
          control={
            <Checkbox
              name="Expired"
              checked={props.statusFilter.expired}
              onChange={(e) => props.handleChangeFilter("expired", e)}
              canAssign
              canRemove
            />
          }
          label={
            <span style={{ marginLeft: 8 }}>{props.translate("overdue")}</span>
          }
        />
      </Menu>
    </div>
  );
  return <div className="v-filter-field">{html}</div>;
};
