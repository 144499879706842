import React, { Component, useState } from "react";
import Grid from "@mui/material/Grid";
import Radio from "../common/Radio";
import Checkbox from "../common/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import Icon from "@mui/material/Icon";
import AddBoxIcon from "@mui/icons-material/AddBox";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "./../common/TextField";
import MenuItem from "../common/MenuItem";
import Tooltip from "../common/Tooltip";
import { ReactComponent as AddNewIcon } from "../../assets/add-new.svg";
import { IconButton } from "@mui/material";
import TimePicker from "react-time-picker";
import { withStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { connect } from "react-redux";
import DateRangePicker from "./../common/DateRangePicker";
import Autocomplete from "./../common/Autocomplete";
import Button from "./../common/Button";
import DeviceSelector from "./../Devices/DeviceSelector";
import GroupSelector from "./../Devices/GroupSelector";
import DriverSelector from "./../Devices/DriverSelector";
import moment from "moment";
import NotificationSelector from "../../Components/Devices/NotificationSelector";
import NotificationTypeSelector from "../../Components/Devices/NotificationTypeSelector";
import Slider from "@mui/material/Slider";
import Stepper from "../common/Stepper";
import GeofenceSelector from "../../Components/Devices/GeofenceSelector";
import { RemoteItems } from "../../Hooks/useRemoteItems";
import ItemGroupSelector from "../Devices/ItemGroupSelector";
import VehicleSelector from "../Devices/VehicleSelector";
import Loader from "../../Layout/Loader";
import withTranslationWrapper from "../../HOC/withTranslation";

const mapStateToProps = (state) => {
  return {
    logInUser: state.logInUsers,
    themecolors: state.themeColors,
  };
};

const styles = (theme) => ({});

class addJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: "report",
    };
  }

  onTabChange = (event, currentTab) => {
    this.setState({ currentTab });
  };

  onNextTab = () => {
    this.setState({ currentTab: "trigger" });
  };

  selectRange = (type) => {
    const rangesFuture = {
      Today: [moment().startOf("day"), moment().endOf("day")],
      "Next 7 Days": [
        moment().endOf("day"),
        moment().add(7, "days").endOf("day"),
      ],
      "Next 30 Days": [
        moment().endOf("day"),
        moment().add(30, "days").endOf("day"),
      ],
      "This Month": [
        moment().startOf("month").startOf("day"),
        moment().endOf("month").endOf("day"),
      ],
      "Next Month": [
        moment().add(1, "month").startOf("month").startOf("day"),
        moment().add(1, "month").endOf("month").endOf("day"),
      ],
      "Never End": [null, null],
    };
    if (rangesFuture && rangesFuture[type]) {
      this.props.showJobDates(rangesFuture[type][0], rangesFuture[type][1]);
    }
    this.props.handleSelectedJobDate(type);
  };

  valuetext = (value) => {
    return moment("2016-03-13")
      .startOf("day")
      .add(value, "minutes")
      .format("HH:mm");
  };

  selectRangePicker = (e, date) => {
    this.props.showJobDates(date.startDate, date.endDate);
  };

  getStepContent = (id) => {
    let timezone = "Asia/Dubai";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      timezone = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      timezone = this.props.logInUser.attributes.timezone;
    }
    moment.tz.setDefault(timezone);
    switch (id) {
      case 1:
        return (
          <Grid container className="form-group">
            <Grid item xs={12}>
              <TextField
                id="description"
                label={this.props.translate("sharedName")}
                required
                placeholder=""
                value={this.props.form.description}
                onChange={this.props.handleChange("description")}
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <h4 style={{ marginTop: 15, marginBottom: 10 }}>
                {this.props.translate("reportInterval")}
              </h4>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="selectedDate"
                  name="selectedDate"
                  value={this.props.selectedDate + ""}
                  onChange={(e) =>
                    this.props.selectedReportDateType("selectedDate", e)
                  }
                  row
                >
                  {CustomDates.map((option) => (
                    <FormControlLabel
                      key={option.id}
                      classes={{
                        label: "form-contol-label",
                      }}
                      value={option.id + ""}
                      control={<Radio size="small" />}
                      label={this.props.translate(option.label)}
                      labelPlacement="end"
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              {this.props.selectedDate === "custom" && (
                <TextField
                  label={this.props.translate("reportDuration")}
                  value={this.props.reportDuration}
                  onChange={(e) =>
                    this.props.onDayChange2("reportDuration", e.target.value)
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start" disableTypography>
                        {this.props.translate("days")}
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <h4 style={{ marginTop: 15, marginBottom: 10 }}>
                {this.props.translate("reportForDays")}
              </h4>
              <div
                style={{
                  border: `1px solid ${this.props.themecolors.textColor}15`,
                  display: "inline-flex",
                }}
              >
                {day.map((option) => (
                  <div
                    key={option.id}
                    style={{
                      display: "inline-flex",
                      textTransform: "uppercase",
                      fontWeight: 600,
                      marginRight: option.id === "7" ? 0 : 1,

                      backgroundColor:
                        this.props.form.attributes.controlDays.includes(
                          parseInt(option.id)
                        )
                          ? this.props.themecolors.themeLightColor
                          : this.props.themecolors.backgroundColor,
                      color: this.props.form.attributes.controlDays.includes(
                        parseInt(option.id)
                      )
                        ? this.props.themecolors.themeLightInverse
                        : this.props.themecolors.textColor,
                    }}
                  >
                    <label
                      style={{
                        padding: 5,
                        minWidth: 30,
                        cursor: "pointer",
                      }}
                    >
                      <input
                        type="checkbox"
                        key={option.id}
                        checked={this.props.form.attributes.controlDays.includes(
                          parseInt(option.id)
                        )}
                        onChange={(e) =>
                          this.props.onDayChange3(e.target.checked, option)
                        }
                        style={{ display: "none" }}
                      />
                      {this.props.translate(option.name)}
                    </label>
                  </div>
                ))}
              </div>
            </Grid>
            <Grid item xs={12}>
              <h4 style={{ marginBottom: 30 }}>
                {this.props.translate("reportBetweenTime")}
              </h4>
              <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                <Slider
                  value={this.props.controlTime}
                  onChange={this.props.handleTimeControl}
                  valueLabelDisplay="on"
                  aria-labelledby="range-slider"
                  valueLabelFormat={this.valuetext}
                  max={1439}
                  classes={{
                    root: "theme-range-slider",
                    thumb: "theme-range-slider-thumb",
                    track: "theme-range-slider-track",
                    rail: "theme-range-slider-rail",
                    valueLabel: "theme-range-slider-label",
                  }}
                  step={5}
                />
              </div>
            </Grid>
          </Grid>
        );
      case 2:
        return (
          <Grid container className="form-group">
            <Grid item xs={12}>
              <TextField
                select
                label={this.props.translate("selectTemplate")}
                value={this.props.reportType || ""}
                variant="outlined"
                onChange={this.props.reportTypeChange}
                margin="dense"
                fullWidth
              >
                {this.props.templates.map((option) => (
                  <MenuItem key={option.rpTmpl.id} value={option.rpTmpl.id}>
                    {option.rpTmpl.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {this.props.allowFields.includes("driver") ||
            this.props.allowFields.includes("device") ||
            this.props.allowFields.includes("vehicle") ||
            this.props.allowFields.includes("notification") ? (
              <Grid item xs={12} style={{ padding: "8px 0" }}>
                <div className="report-sidebar-switch">
                  {this.props.allowFields.includes("device") ? (
                    <Button
                      onClick={(e) => this.props.reportTypeSelection("device")}
                      className={
                        (this.props.reportUnits === "device" ? "active" : "") +
                        " button-inactive"
                      }
                    >
                      {this.props.translate("trackers")}
                    </Button>
                  ) : null}
                  {/* {this.props.allowFields.includes('group') ? (
              <Button
                onClick={e => this.props.reportTypeSelection('group')}
                className={
                  (this.props.reportUnits === 'group'
                    ? 'active'
                    : '') + ' button-inactive'
                }
              >
                {this.props.translate('groups')}
              </Button>
            ) : null} */}
                  {this.props.allowFields.includes("vehicle") ? (
                    <Button
                      onClick={(e) => this.props.reportTypeSelection("vehicle")}
                      className={
                        (this.props.reportUnits === "vehicle" ? "active" : "") +
                        " button-inactive"
                      }
                    >
                      {this.props.translate("sharedVehicle")}
                    </Button>
                  ) : null}
                  {this.props.allowFields.includes("driver") ? (
                    <Button
                      onClick={(e) => this.props.reportTypeSelection("driver")}
                      className={
                        (this.props.reportUnits === "driver" ? "active" : "") +
                        " button-inactive"
                      }
                    >
                      {this.props.translate("sharedDrivers")}
                    </Button>
                  ) : null}
                </div>
                <div className="report-sidebar-switch">
                  <a
                    className={
                      "btn-link " + (this.props.groupView === 1 ? "active" : "")
                    }
                    href={null}
                    onClick={() => this.props.switchGroupView(1)}
                  >
                    {this.props.reportUnits === "device"
                      ? this.props.translate("trackers")
                      : this.props.reportUnits === "driver"
                      ? this.props.translate("driver")
                      : this.props.reportUnits === "vehicle"
                      ? this.props.translate("sharedVehicle")
                      : ""}
                  </a>
                  <a
                    className={
                      "btn-link " + (this.props.groupView === 2 ? "active" : "")
                    }
                    href={null}
                    onClick={() => this.props.switchGroupView(2)}
                  >
                    {this.props.reportUnits === "device"
                      ? this.props.translate("groups")
                      : this.props.reportUnits === "driver"
                      ? this.props.translate("driverGroups")
                      : this.props.reportUnits === "vehicle"
                      ? this.props.translate("vehicleGroups")
                      : ""}
                  </a>
                </div>
              </Grid>
            ) : null}
            {this.props.reportUnits === "device" &&
            this.props.allowFields.includes("device") ? (
              <div>
                {this.props.groupView === 2 ? (
                  <GroupSelector
                    value={this.props.groupIds}
                    onChange={this.props.selectedGroups}
                    rows={8}
                  />
                ) : (
                  <DeviceSelector
                    value={this.props.deviceIds}
                    onChange={this.props.selectedDevices}
                    rows={8}
                  />
                )}
              </div>
            ) : this.props.reportUnits === "group" &&
              this.props.allowFields.includes("group") ? (
              <GroupSelector
                value={this.props.groupIds}
                onChange={this.props.selectedGroups}
                rows={8}
              />
            ) : this.props.allowFields.includes("driver") ? (
              <div>
                {this.props.groupView === 2 ? (
                  <RemoteItems type="Driver">
                    {({ data, isLoaded }) =>
                      isLoaded ? (
                        <ItemGroupSelector
                          groupList={data}
                          value={this.props.groupIds}
                          onChange={this.props.selectedGroups}
                          rows={8}
                        />
                      ) : (
                        <Loader defaultStyle />
                      )
                    }
                  </RemoteItems>
                ) : (
                  <DriverSelector
                    disableModal={true}
                    value={this.props.driverIds}
                    onChange={this.props.selectedDrivers}
                    rows={8}
                  />
                )}
              </div>
            ) : this.props.allowFields.includes("vehicle") ? (
              <div>
                {this.props.groupView === 2 ? (
                  <RemoteItems type="Vehicle">
                    {({ data, isLoaded }) =>
                      isLoaded ? (
                        <ItemGroupSelector
                          groupList={data}
                          value={this.props.groupIds}
                          onChange={this.props.selectedGroups}
                          rows={8}
                        />
                      ) : (
                        <Loader defaultStyle />
                      )
                    }
                  </RemoteItems>
                ) : (
                  <VehicleSelector
                    value={this.props.vehicleIds}
                    onChange={this.props.selectedVehicles}
                    rows={8}
                  />
                )}
              </div>
            ) : null}
          </Grid>
        );
      case 3:
        return (
          <Grid container className="form-group">
            {this.props.allowFields.includes("eventType") ||
            this.props.allowFields.includes("notification") ? (
              <Grid item xs={12}>
                <div className="report-sidebar-switch">
                  {this.props.allowFields.includes("notification") ? (
                    <Button
                      onClick={(e) =>
                        this.props.handleChangeType("notification")
                      }
                      className={
                        this.props.eventType === "notification"
                          ? ""
                          : "button-inactive"
                      }
                    >
                      {this.props.translate("event")}
                    </Button>
                  ) : null}
                  {this.props.allowFields.includes("eventType") ? (
                    <Button
                      onClick={(e) => this.props.handleChangeType("eventType")}
                      className={
                        this.props.eventType === "eventType"
                          ? ""
                          : "button-inactive"
                      }
                    >
                      {this.props.translate("type")}
                    </Button>
                  ) : null}
                </div>
                {this.props.eventType === "eventType" &&
                this.props.allowFields.includes("eventType") ? (
                  <NotificationTypeSelector
                    value={this.props.types}
                    onChange={this.props.selectedNotifications}
                  />
                ) : null}
                {this.props.eventType === "notification" &&
                this.props.allowFields.includes("notification") ? (
                  <NotificationSelector
                    value={this.props.notificationIds}
                    onChange={this.props.selectedEvents}
                  />
                ) : null}
              </Grid>
            ) : null}
          </Grid>
        );

      case 4:
        return (
          <Grid container className="form-group" spacing={2}>
            {this.props.allowFields.includes("geofence") ? (
              <>
                <Grid item xs={12}>
                  <GeofenceSelector
                    value={this.props.geofenceIds}
                    onChange={this.props.selectedGeofences}
                    rows={8}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    type="number"
                    onChange={this.props.handleChange("geoTimeThreshold")}
                    label={
                      this.props.translate("geoTimeThreshold") +
                      " (" +
                      this.props.translate("sharedMinutes") +
                      ")"
                    }
                    value={this.props.form.attributes.geoTimeThreshold}
                    fullWidth
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        );

      case 5:
        return (
          <Grid container className="form-group">
            {this.props.allowFields.includes("sensor") ||
            this.props.allowFields.includes("digitalSensor") ? (
              <SensorSelector
                digital={this.props.allowFields.includes("digitalSensor")}
                allSensors={this.props.allSensors}
                value={this.props.sensorValues}
                handleChange={this.props.selectedSensors}
                translate={this.props.translate}
              />
            ) : null}
          </Grid>
        );

      case 6:
        return (
          <div className="form-group">
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="selectedJobDate"
                name="selectedJobDate"
                value={this.props.selectedJobDate}
                onChange={(e) => this.selectRange(e.target.value)}
                row
              >
                {[
                  "Today",
                  "Next 7 Days",
                  "Next 30 Days",
                  "This Month",
                  "Next Month",
                  "Never End",
                  "custom",
                ].map((option) => (
                  <FormControlLabel
                    key={option}
                    classes={{
                      label: "form-contol-label",
                    }}
                    value={option}
                    control={<Radio size="small" />}
                    label={this.props.translate(option)}
                    labelPlacement="end"
                  />
                ))}
              </RadioGroup>
            </FormControl>
            {this.props.selectedJobDate === "custom" ? (
              <div
                style={{
                  width: 225,
                }}
              >
                <DateRangePicker
                  fill
                  fullWidth
                  ranges={[]}
                  minDate={moment()}
                  selectedDate={`${
                    this.props.form.start
                      ? moment(this.props.form.start).format("YYYY-MM-DD HH:mm")
                      : ""
                  }-${
                    this.props.form.end
                      ? moment(this.props.form.end).format("YYYY-MM-DD HH:mm")
                      : ""
                  }`}
                  label={this.props.translate("schedule")}
                  onEvent={this.selectRangePicker}
                />
              </div>
            ) : null}
            <h4 style={{ marginTop: 15, marginBottom: 10 }}>
              {this.props.translate("triggerTime")}
            </h4>
            <TimePicker
              clearIcon={false}
              disableClock
              value={
                this.props.form.triggerTime
                  ? moment(this.props.form.triggerTime).format("HH:mm")
                  : null
              }
              minutePlaceholder="mm"
              hourPlaceholder="hh"
              onChange={this.props.handleChange("triggerTime")}
            />
            <h4 style={{ marginTop: 15, marginBottom: 10 }}>
              {this.props.translate("repeat")}
            </h4>
            <TextField
              select
              label={this.props.translate("repeat")}
              value={this.props.form.period || ""}
              variant="outlined"
              onChange={(e) => this.props.handleChangePeriod(e.target.value)}
              margin="dense"
              fullWidth
            >
              {repeat.map((option) => (
                <MenuItem key={option.key} value={option.key}>
                  {this.props.translate(option.value)}
                </MenuItem>
              ))}
            </TextField>

            {this.props.form &&
              this.props.form.period &&
              ["daily", "weekly", "monthly"].includes(
                this.props.form.period
              ) && (
                <Grid item xs={12}>
                  {this.props.form.period === "daily" ? (
                    <div
                      style={{
                        border: `1px solid ${this.props.themecolors.textColor}15`,
                        display: "inline-flex",
                      }}
                    >
                      {day.map((option) => (
                        <div
                          key={option.id}
                          style={{
                            display: "inline-flex",
                            textTransform: "uppercase",
                            fontWeight: 600,
                            marginRight: option.id === "7" ? 0 : 1,

                            backgroundColor: this.props.form.days.includes(
                              option.id
                            )
                              ? this.props.themecolors.themeLightColor
                              : "transparent",
                            color: this.props.themecolors.textColor,
                          }}
                        >
                          <label
                            style={{
                              padding: 5,
                              minWidth: 30,
                              cursor: "pointer",
                            }}
                          >
                            <input
                              type="checkbox"
                              key={option.id}
                              checked={this.props.form.days.includes(
                                parseInt(option.id)
                              )}
                              onChange={(e) => this.props.onDayChange(option)}
                              style={{ display: "none" }}
                            />
                            {this.props.translate(option.name)}
                          </label>
                        </div>
                      ))}
                    </div>
                  ) : null}
                  {this.props.form.period === "weekly" ? (
                    <div
                      style={{
                        border: `1px solid ${this.props.themecolors.textColor}15`,
                        display: "inline-flex",
                      }}
                    >
                      {day.map((option) => (
                        <div
                          key={option.id}
                          style={{
                            display: "inline-flex",
                            textTransform: "uppercase",
                            fontWeight: 600,
                            marginRight: option.id === "7" ? 0 : 1,

                            backgroundColor:
                              parseInt(this.props.form.days) ===
                              parseInt(option.id)
                                ? this.props.themecolors.themeLightColor
                                : "transparent",
                            color: this.props.themecolors.textColor,
                          }}
                        >
                          <label
                            style={{
                              padding: 5,
                              minWidth: 30,
                              cursor: "pointer",
                            }}
                            onClick={(e) =>
                              this.props.onDayChange2("days", option.id)
                            }
                          >
                            {this.props.translate(option.name)}
                          </label>
                        </div>
                      ))}
                    </div>
                  ) : null}

                  {this.props.form.period === "monthly" ? (
                    <TextField
                      type="number"
                      fullWidth
                      label={this.props.translate("date")}
                      value={this.props.form.days}
                      onChange={(e) =>
                        this.props.onDayChange2("days", e.target.value)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="start" disableTypography>
                            {this.props.translate("day")}
                          </InputAdornment>
                        ),
                      }}
                    />
                  ) : null}
                </Grid>
              )}
            <h4 style={{ marginTop: 15, marginBottom: 10 }}>
              {this.props.translate("setEmail")}
            </h4>
            <RadioGroup
              aria-label="position"
              name="position"
              value={this.props.form.mailTo}
              onChange={(e) => this.props.handleChangeForEmails("mailTo", e)}
              row
            >
              <FormControlLabel
                classes={{
                  label: "form-contol-label",
                }}
                value="user"
                control={<Radio size="small" />}
                label={this.props.translate("userEmail")}
                labelPlacement="end"
              />
              <FormControlLabel
                classes={{
                  label: "form-contol-label",
                }}
                value="custom"
                control={<Radio size="small" />}
                label={this.props.translate("customEmails")}
                labelPlacement="end"
              />
            </RadioGroup>
            {this.props.form.mailTo !== "user" && (
              <Grid item xs={12}>
                {this.props.form.email &&
                  this.props.form.email.map((item) => {
                    return (
                      <div style={{ display: "flex" }} key={item.id}>
                        <TextField
                          id="email"
                          label={this.props.translate("userEmail")}
                          placeholder=""
                          value={item.text || ""}
                          onChange={(e) =>
                            this.props.handleChangeForEmails(
                              "email",
                              e,
                              item.id
                            )
                          }
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                        {item.id === this.props.form.email.length && (
                          <Tooltip title={this.props.translate("addAnother")}>
                            <IconButton
                              color="inherit"
                              aria-label="directions"
                              onClick={(e) =>
                                this.props.onAddAnotherMail("email")
                              }
                            >
                              <AddNewIcon
                                width={18}
                                height={18}
                                style={{ fill: "currentColor" }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                        {1 < this.props.form.email.length && (
                          <Tooltip title={this.props.translate("delete")}>
                            <IconButton
                              color="inherit"
                              aria-label="directions"
                              onClick={(e) =>
                                this.props.deleteMail("email", item.id)
                              }
                            >
                              <DeleteIcon
                                width={18}
                                height={18}
                                style={{ fill: "currentColor" }}
                              />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    );
                  })}
              </Grid>
            )}

            {this.props.editOption && (
              <>
                <h4 style={{ marginTop: 15, marginBottom: 10 }}>
                  {this.props.translate("status")}
                </h4>
                <Grid item xs={12}>
                  <Checkbox
                    checked={this.props.form.status}
                    onChange={this.props.handleChange("status")}
                    name="status"
                    label={this.props.translate("enable")}
                  />
                </Grid>
              </>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  render() {
    return (
      <div style={{ borderRadius: 8, padding: "0 16px" }}>
        <h4 className="with-border">{this.props.translate("reportForm")}</h4>
        <Grid container>
          <Grid item xs={12} md={6}>
            <Stepper
              translate={this.props.translate}
              activeStep={this.props.activeStep}
              reportType={this.props.reportType}
              getStepContent={this.getStepContent}
              steps={this.props.steps}
              handleNext={this.props.goToNextStep}
              handleBack={this.props.goToBackStep}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

const AddJobs = withStyles(styles);
export default connect(mapStateToProps)(
  AddJobs(withTranslationWrapper(addJobs))
);

const repeat = [
  {
    key: "once",
    value: "once",
  },
  {
    key: "daily",
    value: "daily",
  },
  {
    key: "weekly",
    value: "weekly",
  },
  {
    key: "monthly",
    value: "monthly",
  },
];

const day = [
  {
    id: "1",
    name: "su",
  },
  {
    id: "2",
    name: "mo",
  },
  {
    id: "3",
    name: "tu",
  },
  {
    id: "4",
    name: "we",
  },
  {
    id: "5",
    name: "th",
  },
  {
    id: "6",
    name: "fr",
  },
  {
    id: "7",
    name: "sa",
  },
];

const CustomDates = [
  {
    id: 0,
    label: "reportToday",
  },
  {
    id: 1,
    label: "reportYesterday",
  },
  {
    id: 7,
    label: "reportLastWeek",
  },
  {
    id: 14,
    label: "reportLastTwoWeek",
  },
  {
    id: 30,
    label: "reportLastMonth",
  },
  {
    id: 60,
    label: "reportLastTwoMonths",
  },
  {
    id: 90,
    label: "reportLastThreeMonths",
  },
  {
    id: "custom",
    label: "custom",
  },
];

const SensorSelector = (props) => {
  const [scale, setScale] = useState(props.value || []);

  const addMore = () => {
    const newscale = [...scale];
    newscale.push({ input: "", index: "", value: "" });
    setScale(newscale);
  };

  const deleteRow = (index) => {
    let newscale = [...scale];
    newscale.splice(index, 1);
    setScale(newscale);

    props.handleChange(newscale);
  };

  const handleChange = (name, value, row) => {
    if (name === "allSensors") {
      if (value.target.checked) {
        const reset = [{ input: "", index: "", value: "allSensors" }];
        setScale(reset);
        props.handleChange(reset);
      } else {
        const reset = [{ input: "", index: "", value: "" }];
        setScale(reset);
        props.handleChange(reset);
      }
    } else {
      row[name] = value;
      row.value = row.input + (row.index !== undefined ? row.index : "");

      const newscale = [...scale];
      setScale(newscale);

      props.handleChange(newscale);
    }
  };

  const suggestions = props.digital
    ? [
        "ignition",
        "panicButton",
        "externalPower",
        "enginStatus",
        "carAlarm",
        "doors",
        "custom",
      ]
    : [
        "driverUniqueId",
        "passengerId",
        "trailerUniqueId",
        "ignition",
        "panicButton",
        "externalPower",
        "enginStatus",
        "carAlarm",
        "doors",
        "fuel",
        "temperature",
        "rpm",
        "boardPower",
        "mileage",
        "speed",
        "counter",
        "averagFuelConsumption",
        "instantFuelConsumption",
        "flowMeter",
        "humidity",
        "custom",
      ];

  return (
    <div style={{ marginBottom: 16, width: "100%" }}>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ paddingBottom: 0, paddingTop: 0, marginTop: -22 }}
        >
          <div className="pull-right">
            {!props.allSensors && (
              <Tooltip
                title={props.translate("addMore")}
                classes={{
                  popper: "menu-popper",
                  tooltip: "menu-popper-tooltip",
                }}
              >
                <a href={null} onClick={addMore} style={{ color: "inherit" }}>
                  <AddBoxIcon />
                </a>
              </Tooltip>
            )}
            <Checkbox
              checked={props.allSensors}
              onChange={(e) => handleChange("allSensors", e)}
              label={props.translate("selectAll")}
            />
          </div>
        </Grid>
        <Grid item xs={12} style={{ paddingBottom: 0 }}>
          {!props.allSensors &&
            scale.map((row, index) => (
              <Grid key={index} container spacing={0} className="sensors-rows">
                <Grid item xs={scale.length > 1 ? 11 : 12}>
                  <Grid container spacing={2}>
                    <Grid item md={8} xs={12}>
                      <Autocomplete
                        value={row.input || ""}
                        suggestions={suggestions}
                        handleChange={(e, v) => handleChange(e, v, row)}
                        fieldName="input"
                        label={props.translate("sensorType")}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        type="number"
                        value={row.index}
                        onChange={(e) =>
                          handleChange("index", e.target.value, row)
                        }
                        variant="outlined"
                        margin="dense"
                        label={props.translate("index")}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {scale.length > 1 ? (
                  <Grid
                    item
                    xs={1}
                    style={{
                      alignItems: "center",
                      justifyContent: "center",
                      display: "flex",
                    }}
                  >
                    <DeleteIcon
                      onClick={(e) => deleteRow(index)}
                      style={{ cursor: "pointer" }}
                    />
                  </Grid>
                ) : null}
              </Grid>
            ))}
        </Grid>
      </Grid>
    </div>
  );
};
