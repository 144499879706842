import React, { Component } from "react";
import { connect } from "react-redux";
import { EditItem } from "./EditItem";
import Loader from "../../Layout/Loader";
import isEqual from "react-fast-compare";
import {
  removedvehicle,
  updatevehicle,
  addvehicle,
} from "../../Actions/Vehicles";
import AddVehicle from "./addVehicle";
import { notifyError, notifySuccess } from "../../Utils/CustomNotifcations";
import instance from "../../axios";
import { errorHandler } from "../../Helpers";
import withTranslationWrapper from "../../HOC/withTranslation";

const vehicleDataFormat = (data, itemId) => ({
  id: itemId ? itemId : 0,
  attributes: { ...data.attributes },
  deviceId: data.deviceId,
  label: data.label,
  model: data.model,
  garage: data.garage,
  vehicleType: data.vehicleType,
  vehicleLicensePlate: data.vehicleLicensePlate,
  expirationTime: data.expirationTime,
});

const formDefault = {
  label: "",
  garage: "",
  model: "",
  vehicleType: "",
  expirationTime: "",
  vehicleLicensePlate: "",
  attributes: {}, //vehicleLicensePlate: ''
};

class vehicleModal extends Component {
  constructor() {
    super();
    this.state = {
      selectedVehicle: "",
      addOption: false,
      editOption: false,
      isVisableTrackerModal: false,
      isVisableUserBtn: false,
      vehicleId: "",
      vehicleTracker: "",
      form: { ...formDefault },
    };
    this.handleChange = this.handleChange.bind(this);
    this.ac_submit = this.ac_submit.bind(this);
    this.onCloseSubModal = this.onCloseSubModal.bind(this);
    this.uc_submit = this.uc_submit.bind(this);
    this.checkRequiredFields = this.checkRequiredFields.bind(this);
  }
  UNSAFE_componentWillMount() {
    this.dataFormatting();
  }
  dataFormatting = () => {
    let dt = JSON.parse(JSON.stringify(this.props.selecteditem));
    if (this.props.showItemDetail && this.props.trackersApiResponce) {
      let formData = this.setFormData(dt);
      let attributes = dt.attributes;
      let trackers = this.props.trackerData;
      formData = { ...formData, attributes, trackers };
      this.setState({
        selectedVehicle: formData,
        vehicleId: dt.id,
        vehicleTracker: this.props.trackerData,
      });
    } else {
      if (
        this.props.activeOperation === "edit" &&
        this.props.trackersApiResponce
      ) {
        if (
          !this.state.editOption ||
          !isEqual(this.state.selectedVehicle, dt)
        ) {
          this.setState({
            form: { ...dt },
            vehicleId: dt.id,
            editOption: true,
            addOption: false,
            selectedVehicle: dt,
            vehicleTracker: this.props.trackerData,
          });
        }
      }
      if (this.props.activeOperation === "add" && !this.state.addOption) {
        this.setState({
          vehicleTracker: "",
          vehicleId: "",
          selectedVehicle: "",
          addOption: true,
          editOption: false,
          form: formDefault,
        });
      }
    }
  };
  setFormData = (data) => {
    let formData = {
      label: data.label,
      garage: data.garage,
      model: data.model,
      vehicleType: data.vehicleType,
      expirationTime: data.expirationTime,
      vehicleLicensePlate: data.vehicleLicensePlate || "",
      vin: data.attributes.vin || "",
      chassisNumber: data.attributes.chassisNumber || "",
      permittedSpeed: data.attributes.permittedSpeed || "",
    };
    return formData;
  };
  ac_submit() {
    let { form } = this.state;
    const obj = vehicleDataFormat(form);
    this.setState({
      isVisableUserBtn: false,
    });

    instance({
      url: `/api/vehicles`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((response) => {
        if (response) {
          this.props.dispatch(addvehicle(response));
          this.props.onCloseModal();
          this.setState({
            addOption: false,
            selectedVehicle: "",
            form: formDefault,
          });

          this.props.dispatch(
            notifySuccess(this.props.translate("vehicleIsAdded"))
          );
        } else {
          if (response && response.message.includes("vehicles_deviceid")) {
            this.props.dispatch(
              notifyError(this.props.translate("alreadyAttachedWithVehicle"))
            );
          } else {
            this.props.dispatch(
              notifyError(this.props.translate(
                  "vehicleLicensePlateAllreadyInUsed"
                ))
            );
          }
        }
      })
      //   } else {
      //     throw response
      //   }
      // })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
    // .catch(e => {
    //   this.props.dispatch(
    //     notifyError({
    //       message: this.props.translate('vehicleFormateIssue'),
    //       autoDismiss: 10
    //     })
    //   )
    // })
  }
  uc_submit() {
    let { form, selectedVehicle } = this.state;
    const obj = vehicleDataFormat(form, selectedVehicle.id);
    this.setState({
      isVisableUserBtn: false,
    });

    // fetch(`/api/vehicles/${selectedVehicle.id}`, {
    //   method: "PUT",
    //   headers: {
    //     Accept: "application/json",
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify({
    //     ...obj,
    //   }),
    // })
    instance({
      url: `/api/vehicles/${selectedVehicle.id}`,
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...obj,
      },
    })
      // .then((response) => {
      //   if (response.ok) {
      //     response.json()
      .then((data) => {
        if (data) {
          this.props.dispatch(updatevehicle(data));
          this.props.onCloseModal();
          this.props.dispatch(
            notifySuccess(this.props.translate("vehicleIsUpdated"))
          );
          this.setState({
            editOption: false,
          });
        } else {
          this.props.dispatch(
            notifyError(data.message)
          );
        }
      })
      //   } else {
      //     throw response;
      //   }
      // })
      .catch((e) => {
        alert(
          "Something went wrong with your detail. Please check your form again."
        );
      });
  }
  onChangedDevice = (item) => {
    if (item) {
      this.setState(
        {
          vehicleTracker: item,
          form: {
            ...this.state.form,
            deviceId: item.value,
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      this.setState(
        {
          vehicleTracker: "",
          form: {
            ...this.state.form,
            deviceId: "",
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };

  enableAssignTrackerModal = () => {
    this.setState({
      isVisableTrackerModal: true,
    });
  };
  onCloseModal = () => {
    this.setState({
      isVisableTrackerModal: false,
    });
  };
  onCloseSubModal() {
    this.setState({
      addOption: false,
      editOption: false,
    });
    this.props.onCloseModal();
  }

  handleChangeLicenseExpDate = (name) => (event) => {
    if (name === "expirationTime") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: event.toISOString(),
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };
  setExpirationTime = (e) => {
    e.stopPropagation();
    this.setState(
      {
        form: {
          ...this.state.form,
          expirationTime: null,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  handleChange(name, event) {
    const { target } = event;
    if (target && target.type === "checkbox") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: target.checked,
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      let value = target.value.toString();
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value,
          },
        },
        () => this.checkRequiredFields()
      );
    }
  }
  handleChangeForAttributesValues = (name, event) => {
    let { attributes } = this.state.form;
    const { target } = event;
    let value = target.value.toString();
    if (value) {
      attributes[name] = value;
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: { ...attributes },
          },
        },
        () => {
          if (this.state.editOption) {
            this.setState({
              isVisableUserBtn: true,
            });
          }
        }
      );
    } else {
      delete attributes[name];
      this.setState(
        {
          form: {
            ...this.state.form,
            attributes: { ...attributes },
          },
        },
        () => {
          if (this.state.editOption) {
            this.setState({
              isVisableUserBtn: true,
            });
          }
        }
      );
    }
  };
  checkRequiredFields() {
    let { label, vehicleLicensePlate } = this.state.form;
    let value = true;
    if (this.state.editOption) {
      value = !isEqual(this.state.form, this.props.selecteditem);
    }
    if (
      label &&
      label.trim().length &&
      vehicleLicensePlate &&
      vehicleLicensePlate.trim().length &&
      value
    ) {
      this.setState({
        isVisableUserBtn: true,
      });
    } else {
      this.setState({
        isVisableUserBtn: false,
      });
    }
  }

  render() {
    let { selecteditem } = this.props;

    if (
      selecteditem.id !== this.state.vehicleId &&
      this.props.trackersApiResponce
    ) {
      this.dataFormatting();
    }
    return (
      <div style={{ height: "100%", position: "relative", zIndex: 9999 }}>
        {this.state.addOption && (
          <AddVehicle
            {...this.state}
            logInUsers={this.props.logInUsers}
            onClose={this.onCloseSubModal}
            formSubmit={this.ac_submit}
            changeAttribute={this.changeAttribute}
            handleChange={this.handleChange}
            handleChangeForAttributesValues={
              this.handleChangeForAttributesValues
            }
            handleChangeLicenseExpDate={this.handleChangeLicenseExpDate}
            setExpirationTime={this.setExpirationTime}
            onChangedDevice={this.onChangedDevice}
            form={this.state.form}
            buttonText={"sharedCreate"}
            isHaveAccess={"vehicleCreate"}
          />
        )}
        {this.state.editOption && (
          <AddVehicle
            {...this.state}
            logInUsers={this.props.logInUsers}
            onClose={this.onCloseSubModal}
            formSubmit={this.uc_submit}
            changeAttribute={this.changeAttribute}
            handleChange={this.handleChange}
            handleChangeForAttributesValues={
              this.handleChangeForAttributesValues
            }
            handleChangeLicenseExpDate={this.handleChangeLicenseExpDate}
            setExpirationTime={this.setExpirationTime}
            onChangedDevice={this.onChangedDevice}
            buttonText={"update"}
            isHaveAccess={"vehicleUpdate"}
          />
        )}
        {this.state.selectedVehicle &&
        this.props.showItemDetail &&
        this.props.trackersApiResponce ? (
          <EditItem
            logInUsers={this.props.logInUsers}
            currentLocation={this.props.currentLocation}
            selectedItem={this.state.selectedVehicle}
            handleClose={this.onCloseSubModal}
            devicesIcons={this.props.devicesIcons}
          />
        ) : !this.state.addOption && !this.state.editOption ? (
          <Loader />
        ) : null}
      </div>
    );
  }
}

const mapState = (state) => ({
  devices: state.devices.data,
  logInUsers: state.logInUsers,
  themecolors: state.themeColors,
});
const mapStateToProps = connect(mapState);
export const VehicleModal = mapStateToProps(
  withTranslationWrapper(vehicleModal)
);
