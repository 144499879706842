import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
//import TextField from './../common/TextField';
import { withStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import isEqual from "react-fast-compare";
//import InputAdornment from '@mui/material/InputAdornment'
import RefreshIcon from "@mui/icons-material/Refresh";
//import IconButton from '@mui/material/IconButton';
import Tooltip from "../common/Tooltip";
//import CrossIcons from '@mui/icons-material/Remove'
import Paper from "@mui/material/Paper";
//import { MuiPickersUtilsProvider, DatePicker } from 'material-ui-pickers';
//import DateFnsUtils from '@date-io/date-fns';
import Button from "../common/Button";
import { checkPrivileges, errorHandler } from "../../Helpers";
import Style from "style-it";
import { logInUserInfo } from "./../../Actions/Users";
import axios from "axios";
import instance from "../../axios";
import { notifySuccess } from "../../Utils/CustomNotifcations";
const CancelToken = axios.CancelToken;
let source;

//let themecolors;
const mapStateToProps = (state, ownProps) => {
  // themecolors = state.themeColors;
  return {
    themecolors: state.themeColors,
  };
};

const styles = (theme) => ({});

class Permissions extends Component {
  constructor() {
    super();
    this.state = {
      isVisableAddBtn: false,
      isLoadUnit: true,
      isLoadUser: true,
      deviceCount: {
        limit: 0,
        remaningLimit: 0,
      },
      userCount: {
        limit: 0,
        remaningLimit: 0,
      },
      form: {
        readonly: "",
        deviceReadonly: "",
        limitCommands: "",
        deviceLimit: "",
        userLimit: "",
        token: "",
        expirationTime: "",
      },
    };
    this.handleChange = this.handleChange.bind(this);
    this.generateToken = this.generateToken.bind(this);
    this.formSubmit = this.formSubmit.bind(this);
  }
  componentWillUmount() {
    if (source) {
      source.cancel();
    }
  }
  UNSAFE_componentWillMount() {
    source = CancelToken.source();

    if (this.props.logInUser) {
      axios({
        method: "GET",
        url: `/api/devices/count?userId=${this.props.logInUser.id}`,
        cancelToken: source.token,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              deviceCount:
                response.data && response.data.data && response.data.data,
            });
          }
          this.setState({
            isLoadUnit: false,
          });
        })
        .catch((e) => {
          this.setState({
            isLoadUnit: false,
          });
        });
      axios({
        method: "GET",
        url: `/api/users/count?userId=${this.props.logInUser.id}`,
        cancelToken: source.token,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (response.status === 200) {
            this.setState({
              userCount:
                response.data && response.data.data && response.data.data,
            });
          }
          this.setState({
            isLoadUser: false,
          });
        })
        .catch((e) => {
          this.setState({
            isLoadUser: false,
          });
        });
    }
    this.setState({
      form: { ...this.props.logInUser },
    });
  }
  formReset = () => {
    this.setState({
      form: { ...this.props.logInUser },
      isVisableAddBtn: false,
    });
  };
  formSubmit() {
    let { logInUser } = this.props;
    const { form } = this.state;
    logInUser = { ...form };

    instance({
      url: `api/users/${logInUser.id}`,
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...logInUser,
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((user) => {
        this.props.dispatch(logInUserInfo(user));
        //this.props.enqueueSnackbar(this.props.translate('permissionsInfoIsUpdated'), { autoHideDuration: 1000 });
        this.props.dispatch(
          notifySuccess(this.props.translate("permissionsInfoIsUpdated"))
        );
        // })
        this.setState({
          isVisableAddBtn: false,
        });
        //   } else {
        //     throw response
        //   }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }
  handleChange = (name) => (event) => {
    if (name === "expirationTime") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: event.toISOString(),
          },
        },
        () => this.checkRequiredFields()
      );
    } else {
      const { target } = event;
      let value = target.type === "checkbox" ? target.checked : target.value;
      if (target.type === "number") {
        value = parseInt(value);
        if (value === 0) {
          value = "";
        }
      }
      this.setState(
        {
          form: {
            ...this.state.form,
            [name]: value,
          },
        },
        () => this.checkRequiredFields()
      );
    }
  };
  generateToken() {
    let symbols =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let i,
      newToken = "";
    for (i = 0; i < 32; i++) {
      newToken += symbols.charAt(Math.floor(Math.random() * symbols.length));
    }
    this.setState(
      {
        form: {
          ...this.state.form,
          token: newToken,
        },
      },
      () => this.checkRequiredFields()
    );
  }
  setExpirationTime = (e) => {
    e.stopPropagation();
    this.setState(
      {
        form: {
          ...this.state.form,
          expirationTime: null,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  checkRequiredFields = () => {
    let value = !isEqual(this.state.form, this.props.logInUser);
    this.setState({
      isVisableAddBtn: value,
    });
  };

  render() {
    const { logInUser } = this.props;
    return (
      <Style>
        {`
          .row-value {
              font-size: 14px;
          }
          .row-label {
              font-size: 10px;
              font-weight: bold;
              min-width: 100px;
              display: inline-block;
          }
			`}

        <div
        // style={{ marginLeft: 10, }}
        >
          <Grid container spacing={1}>
            {logInUser.userType === -1 &&
              renderOwnerField(
                this.state,
                this.props,
                this.handleChange,
                this.setExpirationTime,
                this.generateToken
              )}
            {logInUser.userType !== -1 &&
              renderAdminField(
                this.state,
                this.props,
                this.handleChange,
                this.generateToken
              )}
          </Grid>

          {/* <br /> */}
          {checkPrivileges("userUpdate") && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  onClick={this.formReset}
                  style={{ marginRight: 8 }}
                >
                  {" "}
                  {this.props.translate("resetButton")}
                </Button>
                <Button
                  variant="contained"
                  disabled={!this.state.isVisableAddBtn}
                  onClick={() => this.formSubmit()}
                >
                  {" "}
                  {this.props.translate("sharedSave")}
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      </Style>
    );
  }
}

export default connect(mapStateToProps)(withStyles(styles)(Permissions));

function renderOwnerField(
  state,
  props,
  handleChange,
  setExpirationTime,
  generateToken
) {
  // const { classes } = props

  const selectMe = (element) => {
    var doc = document,
      text = element.target,
      range,
      selection;
    if (doc.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(text);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(text);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  return (
    <div>
      <Grid
        container
        spacing={1}
        //  style={{ padding: 17, }}
      >
        {/* */}
        <Grid item xs={12} md={12}>
          <div
          // style={{marginLeft:15}}
          >
            <p style={{ fontSize: 14, fontWeight: 700, margin: "10px 0 0 0" }}>
              {props.translate("permissions")}
            </p>
          </div>
        </Grid>

        {/* <Grid container spacing={1} style={{ margin: "0, 10px" }} > */}
        <Grid item xs={12}>
          <span className="row-label">
            {props.translate("userExpirationTime")}
          </span>
          <span className="row-value">
            {state.form.expirationTime
              ? new Date(`${state.form.expirationTime}`).toString()
              : null}
          </span>
        </Grid>
        <Grid item xs={12}>
          <span className="row-label">
            {props.translate("userDeviceLimit")}
          </span>
          {state.isLoadUnit && <span className="row-value">...</span>}
          {!state.isLoadUnit && (
            <span className="row-value">
              {state.form.deviceLimit === -1
                ? props.translate("unlimited")
                : state.form.deviceLimit}
            </span>
          )}
        </Grid>
        <Grid item xs={12}>
          <span className="row-label">{props.translate("userUserLimit")}</span>
          {state.isLoadUser && <span className="row-value">...</span>}
          {!state.isLoadUser && (
            <span className="row-value">
              {state.form.userLimit === -1
                ? props.translate("unlimited")
                : state.form.userLimit}
            </span>
          )}
        </Grid>
        {/* </Grid> */}

        {/* <Grid container spacing={1} style={{ margin: "0, 10px" }}> */}
        <Grid item xs={12} md={6}>
          <div className="license-card clearfix">
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <label style={{ fontSize: 10 }}>
                  {props.translate("userToken")}
                </label>
                <Tooltip title={props.translate("getToken")}>
                  <Button
                    className="pull-right button-white"
                    onClick={generateToken}
                  >
                    {<RefreshIcon />}
                  </Button>
                </Tooltip>
                <p onClick={(e) => selectMe(e)}>
                  <code style={{ wordBreak: "break-all" }}>
                    {state.form.token &&
                      props.ServerSetting &&
                      props.ServerSetting.attributes &&
                      (props.ServerSetting.attributes.hostUrl +
                        "/loginasuser?&" +
                        btoa(state.form.token) ||
                        "")}
                  </code>
                </p>
              </Grid>
            </Grid>
          </div>
        </Grid>
        {/* </Grid> */}
      </Grid>
    </div>
  );
}

function renderAdminField(state, props, handleChange, generateToken) {
  // const { classes } = props
  const selectMe = (element) => {
    var doc = document,
      text = element.target,
      range,
      selection;
    if (doc.body.createTextRange) {
      range = document.body.createTextRange();
      range.moveToElementText(text);
      range.select();
    } else if (window.getSelection) {
      selection = window.getSelection();
      range = document.createRange();
      range.selectNodeContents(text);
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };
  return (
    <Fragment>
      <Grid item xs={12} md={6}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <span className="row-label">
              {props.translate("userExpirationTime")}
            </span>
            <span className="row-value">
              {state.form.expirationTime
                ? new Date(`${state.form.expirationTime}`).toString()
                : null}
            </span>
          </Grid>
          <Grid item xs={12}>
            <span className="row-label">
              {props.translate("userDeviceLimit")}
            </span>
            {state.isLoadUnit && <span className="row-value">...</span>}
            {!state.isLoadUnit && (
              <span className="row-value">
                {state.deviceCount && state.deviceCount.limit === -1 ? (
                  props.translate("unlimited")
                ) : (
                  <span>
                    {props.translate("total")}{" "}
                    {state.deviceCount && state.deviceCount.limit}
                    {" / "}
                    {props.translate("remaning")}{" "}
                    {state.deviceCount && state.deviceCount.remaningLimit}
                  </span>
                )}
              </span>
            )}
          </Grid>
          <Grid item xs={12}>
            <span className="row-label">
              {props.translate("userUserLimit")}
            </span>
            {state.isLoadUser && <span className="row-value">...</span>}
            {!state.isLoadUser && (
              <span className="row-value">
                {state.userCount && state.userCount.limit === -1 ? (
                  props.translate("unlimited")
                ) : (
                  <span>
                    {props.translate("total")}{" "}
                    {state.userCount && state.userCount.limit}
                    {" / "}
                    {props.translate("remaning")}{" "}
                    {state.userCount && state.userCount.remaningLimit}
                  </span>
                )}
              </span>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <div
          className="license-card clearfix"
          style={{
            padding: 16,
            color: "inherit",
            borderRadius: 0,
            boxShadow: "none",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <label style={{ fontSize: 10 }}>
                {props.translate("userToken")}
              </label>
              <Tooltip title={props.translate("getToken")}>
                <Button
                  className="pull-right button-white"
                  onClick={generateToken}
                >
                  {<RefreshIcon />}
                </Button>
              </Tooltip>
              <p onClick={(e) => selectMe(e)}>
                <code style={{ wordBreak: "break-all" }}>
                  {state.form.token &&
                    props.ServerSetting &&
                    props.ServerSetting.attributes &&
                    (props.ServerSetting.attributes.hostUrl +
                      "/loginasuser?&" +
                      btoa(state.form.token) ||
                      "")}
                </code>
              </p>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Fragment>
  );
}
