import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "../../../Components/common/TextField";
import Button from "../../../Components/common/Button";
import Dialog from "../../../Components/common/ConfirmDialoag";
// import { notifyError, notifySuccess } from "../../Utils/CustomNotifications";
import { connect } from "react-redux";
import { checkPrivileges, errorHandler } from "../../../Helpers";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

import axios from "axios";
import instance from "../../../axios";
import Tooltip from "../../../Components/common/Tooltip";
import withTranslationWrapper from "../../../HOC/withTranslation";
import { notifySuccess, notifyError } from "../../../Utils/CustomNotifcations";
const savedCommandsDataFormat = {
  attributes: {},
  deviceId: 0,
  id: 0,
  description: "New",
  type: "custom",
  textChannel: false,
};

class SendCommand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendCommandsForm: { ...savedCommandsDataFormat },
      currentForm: null,
      modal: false,
      createForm: false,
      createData: {
        deviceId: 0,
        id: -1,
        textChannel: "",
        type: "custom",
        description: "",
        attributes: {
          data: "",
        },
      },
      isVisible: false,
      isVisibleCmdBtn: false,
      enableEdit: false,
      enableDelete: false,
    };
  }

  sendCommandSubmit = () => {
    let obj = {
      id: 0,
      ...this.state.currentForm,
    };

    axios({
      url: `/api/commands/send`,
      method: "POST",
      data: {
        ...obj,
      },
    })
      .then((response) => {
        this.setState({
          currentForm: null,
          modal: false,
        });
        if (response.data.statusCode === "200") {
          if (this.props.closeModel) {
            this.props.closeModel();
          }
          this.props.dispatch(
            notifySuccess(this.props.translate("commandSent"))
          );
        } else if (response.data && response.data.statusCode === "202") {
          this.props.dispatch(
            notifySuccess(this.props.translate("commandQueued"))
          );
        } else {
          this.props.dispatch(notifyError(this.props.translate("error")));
        }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  onChange = (event) => {
    this.setState(
      {
        sendCommandsForm: {
          ...this.state.sendCommandsForm,
          attributes: {
            ...this.state.sendCommandsForm.attributes,
            data: event.target.value,
          },
        },
      },
      () => {
        this.checkReqFieldsCommand();
      }
    );
  };

  checkReqFieldsCommand = () => {
    const { sendCommandsForm } = this.state;
    const { data } = sendCommandsForm.attributes;
    if (data) this.setState({ isVisibleCmdBtn: true });
    else this.setState({ isVisibleCmdBtn: false });
  };

  openModal = (row) => {
    this.setState({
      currentForm: { ...row, deviceId: parseInt(this.props.id) },
      modal: checkPrivileges("commandSend") ? true : false,
    });
  };

  hideModal = () => {
    this.setState({
      currentForm: null,
      modal: false,
    });
  };

  openCreate = () => {
    this.setState({
      createForm: true,
      createData: {
        deviceId: 0,
        id: -1,
        textChannel: "",
        type: "custom",
        description: "",
        attributes: {
          data: "",
        },
      },
    });
  };
  onEdit = (item) => {
    this.setState({ createData: item, enableEdit: true });
  };
  openDeleteDialog = (item) => {
    this.setState({ enableDelete: true, createData: item });
  };
  closeDeleteDialog = () => {
    this.setState({
      enableDelete: false,
      // createData: "",
    });
  };

  onSubmitCreate = () => {
    instance({
      url: `/api/commands`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...this.state.createData,
      },
    })
      .then((res) => {
        let z = this.props.id;
        let obj = {
          deviceId: z.toString(),
          commandId: res.id,
        };
        instance({
          url: `/api/permissions`,
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          data: {
            ...obj,
          },
        })
          .then((perm) => {
            this.props.dispatch(
              notifySuccess(this.props.translate("commandCreated"))
            );
            this.setState({ createForm: false }, () => {
              this.props.sendCommandFun(this.props.device);
            });
          })
          .catch((err) => {
            console.log("err===", err);
            this.props.dispatch(notifyError(this.props.translate(err.message)));
            this.setState({ createForm: false });
          });
      })
      .catch((err) => {
        console.log("err===", err);
      });
  };

  onSubmitUpdate = () => {
    instance({
      url: `/api/commands/${this.state.createData.id}`,
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        ...this.state.createData,
      },
    })
      .then((res) => {
        let z = this.props.id;
        let obj = {
          deviceId: z.toString(),
          commandId: res.id,
        };
        this.props.dispatch(
          notifySuccess(this.props.translate("commandUpdated"))
        );
        this.setState({ enableEdit: false }, () => {
          this.props.sendCommandFun(this.props.device);
        });
        //   instance({
        //     url: `/api/permissions`,
        //     method:  "POST",
        //     headers: {
        //       Accept: "application/json",
        //       "Content-Type": "application/json",
        //     },
        //     data: {
        //       ...obj,
        //     },
        //   })
        //   .then(perm=>{
        //     this.props.dispatch(
        //       notifySuccess({
        //         message: this.props.translate('commandUpdated'),
        //         autoDismiss: 10,
        //       })
        //     )
        //         this.setState({createForm:false, enableEdit:false},()=>{
        //           this.props.sendCommandFun()
        //         })
        //   })
        //   .catch(err=>{console.log('err===', err)
        //       this.props.dispatch(
        //         notifyError({
        //           message: this.props.translate(err.message),
        //           autoDismiss: 10,
        //         })
        //       )
        //      this.setState({createForm:false, enableEdit:false})
        // })
      })
      .catch((err) => {
        console.log("err===", err);
      });
  };
  onSubmitDelete = () => {
    instance({
      url: `/api/commands/${this.state.createData.id}`,
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      // data: {
      //   ...this.state.createData,
      // },
    })
      .then((res) => {
        let z = this.props.id;
        let obj = {
          deviceId: z.toString(),
          commandId: res.id,
        };
        this.props.dispatch(
          notifySuccess(this.props.translate("commandDeleted"))
        );
        this.setState(
          {
            // createData: {},
            enableDelete: false,
          },
          () => {
            this.props.sendCommandFun(this.props.device);
          }
        );
      })
      .catch((err) => {
        console.log("err===", err);
      });
  };

  handleChange = (name, value) => {
    if (name === "data") {
      this.setState(
        {
          createData: {
            ...this.state.createData,
            attributes: {
              ...this.state.createData.attributes,
              [name]: value,
            },
          },
        },
        () => {
          this.checkReqFields();
        }
      );
    } else {
      this.setState(
        {
          createData: {
            ...this.state.createData,
            [name]: value,
          },
        },
        () => this.checkReqFields()
      );
    }
  };

  checkReqFields = () => {
    const { description } = this.state.createData;
    const { data } = this.state?.createData?.attributes || {};
    if (data && description) this.setState({ isVisible: true });
    else this.setState({ isVisible: false });
  };

  render() {
    const { props } = this;
    return (
      <>
        <Grid container style={{ height: "100%", padding: 20 }}>
          {checkPrivileges("commandSend") &&
          !this.state.createForm &&
          !this.state.enableEdit ? (
            <>
              <Grid
                item
                xs={12}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <strong
                  style={{ paddingBottom: 5, paddingTop: 5, display: "block" }}
                >
                  {props.translate("newCommand")}
                </strong>
                <Button onClick={(e) => this.openCreate()}>
                  {" "}
                  {this.props.translate("sharedCreate")}{" "}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs>
                    <TextField
                      id="data"
                      name="data"
                      margin="dense"
                      label={props.translate("commandData")}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => this.onChange(e)}
                    />
                  </Grid>
                  <Grid
                    item
                    style={{
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      display: "flex",
                      paddingLeft: 10,
                    }}
                  >
                    <Button
                      onClick={(e) =>
                        this.openModal(this.state.sendCommandsForm)
                      }
                      disabled={!this.state.isVisibleCmdBtn ? "disabled" : ""}
                      style={{
                        color: !this.state.isVisibleCmdBtn ? "#000" : "#fff",
                        background: this.state.isVisibleCmdBtn
                          ? this.props.themecolors.menuActiveBackground
                          : "white",
                        border: `1px solid ${this.props.themecolors.menuActiveBackground}`,
                      }}
                    >
                      {this.props.translate("commandSend")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}

          {this.state.createForm || this.state.enableEdit ? (
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <strong
                    style={{
                      paddingBottom: 5,
                      paddingTop: 5,
                      display: "block",
                    }}
                  >
                    {props.translate("CreateCommand")}
                  </strong>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xl={6} md={6} xs={6}>
                    <TextField
                      id="description"
                      name="description"
                      margin="dense"
                      label={props.translate("sharedDescription")}
                      value={this.state.createData?.description || ""}
                      variant="outlined"
                      fullWidth
                      onChange={(e) =>
                        this.handleChange("description", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xl={6} md={6} xs={6}>
                    <TextField
                      id="data"
                      name="data"
                      margin="dense"
                      label={props.translate("data")}
                      value={this.state.createData?.attributes?.data || ""}
                      variant="outlined"
                      fullWidth
                      onChange={(e) =>
                        this.handleChange("data", e.target.value)
                      }
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={2}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    paddingLeft: 10,
                    marginTop: 15,
                  }}
                >
                  <Button
                    disabled={!this.state.isVisible}
                    onClick={() =>
                      this.state.createForm
                        ? this.onSubmitCreate()
                        : this.state.enableEdit
                        ? this.onSubmitUpdate()
                        : null
                    }
                  >
                    {this.state.createForm
                      ? this.props.translate("sharedCreate")
                      : this.state.enableEdit
                      ? this.props.translate("sharedUpdate")
                      : ""}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : null}

          {!this.state.createForm && !this.state.enableEdit ? (
            <Grid item xs={12}>
              <strong
                style={{ paddingBottom: 5, paddingTop: 10, display: "block" }}
              >
                {props.translate("availableCommands")}
              </strong>
              <ul
                style={{
                  listStyle: "none",
                  padding: 0,
                  paddingTop: 8,
                  margin: 0,
                }}
              >
                {props.descriptionList && props.descriptionList.length
                  ? props.descriptionList.map((e) => (
                      <li
                        style={{
                          background: `${props.themecolors.themeLightColor}44`,
                          padding: "6px 12px",
                          borderRadius: 4,
                          cursor: "pointer",
                          marginBottom: 6,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        key={e.key}
                      >
                        {e.name}

                        <div>
                          {checkPrivileges("commandUpdate") && (
                            <Tooltip title="Edit">
                              <EditIcon
                                className="actionButton"
                                fontSize="small"
                                style={{ margin: "0px 5px" }}
                                onClick={(ev) => this.onEdit(e)}
                              />
                            </Tooltip>
                          )}

                          {checkPrivileges("commandDelete") && (
                            <Tooltip title="Delete">
                              <DeleteIcon
                                className="actionButton"
                                fontSize="small"
                                style={{ margin: "0px 5px" }}
                                onClick={(ev) => this.openDeleteDialog(e)}
                              />
                            </Tooltip>
                          )}

                          {checkPrivileges("commandSend") && (
                            <Tooltip
                              title={this.props.translate("commandSend")}
                            >
                              <span
                                style={{
                                  float: "right",
                                  fontSize: "0.8125rem",
                                  padding: 1,
                                  textTransform: "uppercase",
                                  fontWeight: 500,
                                }}
                                onClick={(event) => this.openModal(e)}
                              >
                                {this.props.translate("commandSend")}
                              </span>
                            </Tooltip>
                          )}
                        </div>
                      </li>
                    ))
                  : props.translate("commandNotAvailable")}
              </ul>
            </Grid>
          ) : null}
        </Grid>
        {this.state.enableDelete ? (
          <Dialog
            title={props.translate("areYouWantToDelete")}
            onCancel={this.closeDeleteDialog}
            children={this.state.createData.name}
            onOk={this.onSubmitDelete}
          />
        ) : null}
        {this.state.modal ? (
          <Dialog
            title={props.translate("areYouSureToSendThisCommand")}
            onCancel={this.hideModal}
            onOk={this.sendCommandSubmit}
          />
        ) : null}
      </>
    );
  }
}
const frequencyType = [
  {
    type: "s",
  },
  { type: "m" },
  { type: "h" },
];

export { SendCommand };
export default withTranslationWrapper(SendCommand);
