import React, { Component } from "react";
import { connect } from "react-redux";
import isEqual from "react-fast-compare";
import {
  checkPrivileges,
  errorHandler,
  isOwner,
  selectAll,
} from "../../Helpers";
import Layout from "./../../Layout";
import TextField from "../../Components/common/TextField";
import Checkbox from "../../Components/common/Checkbox";
import { ReactComponent as CommandIcon } from "./../../assets/nav/command.svg";
import { timeZonesTypes } from "../../Components/Attributes/timezone";
import MenuItem from "../../Components/common/MenuItem";
import Button from "../../Components/common/Button";
import { addCommands, getCommands } from "../../Actions/Commands";
import {notifySuccess } from "../../Utils/CustomNotifcations";
import Grid from "@mui/material/Grid";
import EmptyState from "../../Components/common/EmptyState";
import Dialog from "../../Components/common/Dialog";
import ItemsSelector from "../Units/Components/itemsSelector";
import {
  removedUserDeviceWiseSelectedList,
  userDeviceWiseSelectedList,
} from "../../Actions/Notifications";
import ConfirmDialoag from "../../Components/common/ConfirmDialoag";
import { removeUser } from "../../Actions/Users";
import { removeDevice } from "../../Actions/Devices";
import { removeGroup } from "../../Actions/Groups";
import instance from "../../axios";
// import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import DateFnsUtils from "@date-io/date-fns";
import EditIcon from "@mui/icons-material/Place";
import IconButton from "@mui/material/IconButton";
import Table from "../../Components/common/TableMultiCheckbox";
import CommonTableSection from "../Users/Components/commonTableSection";
import { EditForm } from "../../Components/Geofence/editForm";
import { addTypes, getTypes, removedTypes } from "../../Actions/Services";
import withTranslationWrapper from "../../HOC/withTranslation";

class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      serviceForm: {},
      typeSelection: "",
      expenseId: "",
      mode: "",
      isVisableSubmitBtn: true,
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
      deleteForm: {},
      servicesdata: [],
      expense: [],
      rowsPerPage: 5,
    };
  }

  componentWillUnmount() {
    this.setState({
      serviceForm: {},
      mode: "",
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
    });
  }

  componentWillMount() {
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true,
        },
        () => {
          this.fetchData(
            this.props.logInUser,
            this.state.currentPage,
            this.state.pagesize
          );
          // this.fetchTypes()
        }
      );
    }
  }

  componentWillReceiveProps(n) {
    if (n.logInUser && n.logInUser.id && this.state.initFetch === false) {
      this.setState(
        {
          initFetch: true,
        },
        () => {
          this.fetchData(
            n.logInUser,
            this.state.currentPage,
            this.state.pagesize
          );
          // this.fetchTypes()
        }
      );
    }
  }

  searchItems = (text) => {
    this.setState(
      {
        searchText: text,
      },
      () => {
        this.fetchServices(this.state.searchText, this.state.servicesdata);
      }
    );
  };

  fetchServices = (text, services) => {
    const u = services.filter((u) =>
      (u.id + u.name).toLowerCase().includes(text.toLowerCase())
    );
    this.props.dispatch(getTypes(u));
  };

  fetchData = (logInUser, page, perPage, pagination) => {
    // let searchText = this.state.searchText
    // if (searchText) {
    //   searchText = '&search=' + searchText
    // } else {
    //   searchText = ''
    // }

    let items = this.state.itemPagination.items;
    // if (reset) {
    //   items = []
    // }
    instance({
      method: "GET",
      url:
        this.props.logInUser && this.props.logInUser.id
          ? `/api/expensetypes/get?userId=${
              this.props.logInUser.id
            }&all=${true}`
          : `/api/expensetypes`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      params: {
        // userId: logInUser?logInUser.id:null,
        // all:true,
        page: page,
        limit: perPage,
        // search: this.state.searchText || ''
      },
    })
      .then((response) => {
        this.setState({
          itemPagination: {
            ...response,
            items: items.concat(response.data),
          },
          currentPage: response.hasNext ? response.page + 1 : response.page,
          servicesdata: pagination
            ? items.concat(response.data)
            : response.data,
        });
        this.props.dispatch(
          getTypes(pagination ? items.concat(response.data) : response.data)
        );
        // this.setState({ servicesdata: response })
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  openCreateTypeFrom = () => {
    this.setState({ mode: "", serviceForm: {}, typeSelection: "" }, () => {
      this.setState({
        mode: "create",
        serviceForm: {},
        typeSelection: "createType",
      });
    });
  };
  openCreateSubTypeFrom = () => {
    this.setState({ mode: "", serviceForm: {}, typeSelection: "" }, () => {
      this.setState({
        mode: "create",
        serviceForm: {},
        typeSelection: "createSub",
      });
    });
  };

  openEditSubTypeFrom = (data) => {
    this.setState({ mode: "", serviceForm: {}, typeSelection: "" }, () => {
      this.setState({
        mode: "editSub",
        serviceForm: data[0],
        typeSelection: "updateSub",
      });
    });
  };

  openEditTypeFrom = (data) => {
    this.setState({ mode: "", serviceForm: {}, typeSelection: "" }, () => {
      this.setState({
        mode: "editSub",
        serviceForm: data,
        typeSelection: "updateType",
      });
    });
  };

  onDeleteSub = (item) => {
    this.setState({
      deleteForm: { ...item[0] },
      onDeleteConfirmation: true,
    });
  };

  removeSubType = () => {
    const id = this.state.deleteForm.id;
    instance({
      url: `/api/expensetypes/subtype/${id}`,
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((commands) => {
        this.props.dispatch(
          notifySuccess(this.props.translate("subTypeIsDeleted"))
        );
        this.setState(
          {
            deleteForm: {},
            onDeleteConfirmation: false,
          },
          () => {
            this.getExpense(this.state.expenseId);
          }
        );
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  showSubTypes = (service) => {
    this.getExpense(service.id);
    this.setState({ mode: "", serviceForm: {} }, () => {
      this.setState({
        mode: "Show",
        serviceForm: service,
        expenseId: service.id,
      });
    });
  };
  closeCreateFrom = () => {
    this.setState(
      {
        mode: "",
        serviceForm: "",
        typeSelection: "",
      },
      () => this.checkRequiredFields()
    );
  };
  closeEditForm = () => {
    this.setState(
      {
        mode: "",
        serviceForm: "",
        typeSelection: "",
      },
      () => this.checkRequiredFields()
    );
  };

  handleChange = (name, value) => {
    this.setState(
      {
        serviceForm: {
          ...this.state.serviceForm,
          [name]: value,
        },
      },
      () => this.checkRequiredFields()
    );
  };
  checkRequiredFields = () => {
    let { name, label } = this.state.serviceForm;
    if (name || label) {
      this.setState({
        isVisableSubmitBtn: false,
      });
    } else {
      this.setState({
        isVisableSubmitBtn: true,
      });
    }
  };

  getExpense = (id) => {
    if (id) {
      instance({
        method: "GET",
        url: `/api/expensetypes/subtype?expenseTypeId=${id}&search=`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          this.setState({
            expense: res,
          });
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  };

  submitForm = () => {
    let { mode, typeSelection, serviceForm, expenseId } = this.state;
    let obj;
    let params = "";
    let api;
    if (mode === "create") {
      if (typeSelection === "createType") {
        obj = {
          ...serviceForm,
          attributes: {},
          parentId: 0,
        };
        api = `/api/expensetypes/${params}`;
      } else if (typeSelection === "createSub") {
        obj = {
          ...serviceForm,
          parentId: 0,
          expensetypeId: expenseId,
        };
        api = `/api/expensetypes/subtype/${params}`;
      }
    }
    if (mode === "editSub") {
      if (typeSelection === "updateType") {
        obj = { ...serviceForm };
        params = serviceForm.id;
        api = `/api/expensetypes/${params}`;
      } else if (typeSelection === "updateSub") {
        obj = { ...serviceForm };
        params = serviceForm.id;
        api = `/api/expensetypes/subtype/${params}`;
      }
    }
    if (obj) {
      instance({
        url: api,
        method: `${mode === "editSub" ? "PUT" : "POST"}`,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...obj,
        },
      })
        .then((res) => {
          if (mode === "editSub") {
            if (typeSelection === "updateType") {
              this.props.dispatch(addTypes(res));
              this.props.dispatch(
                notifySuccess(this.props.translate("savedTypeIsUpdated"))
              );
            } else {
              this.props.dispatch(
                notifySuccess(this.props.translate("savedSubTypeIsUpdated"))
              );
            }
          } else if (mode === "create") {
            if (typeSelection === "createType") {
              this.props.dispatch(addTypes(res));
              this.props.dispatch(
                notifySuccess( this.props.translate("typeIsCreated"))
              );
            } else {
              this.props.dispatch(
                notifySuccess(this.props.translate("SubTypeIsCreated"))
              );
            }
          }
          this.closeCreateFrom();
        })
        .catch((error) => {
          console.log("errror ===", error);
          errorHandler(error, this.props.dispatch);
        });
    }
  };

  onRemovedItem = (item) => {
    this.setState({
      deleteForm: { ...item[0] },
      onDeleteConfirmation: true,
    });
  };

  handleChangeRowsPerPage = (rowsPerPage) => {
    this.setState({ rowsPerPage });
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };
  fetchMoreItems = (a, b, c) => {
    let pagination;
    this.fetchData(
      this.props.logInUser,
      this.state.currentPage,
      this.state.pagesize,
      (pagination = true)
    );
  };

  render() {
    const endMessage =
      this.state.itemPagination && this.state.itemPagination.total > 0 ? (
        <p style={{ textAlign: "center", color: "#ccc" }}>
          -- {this.props.translate("end")} --
        </p>
      ) : (
        <p style={{ textAlign: "center", color: "#ccc" }}>
          {/* {this.props.translate('notFound')} */}
          -- {this.props.translate("end")} --
        </p>
      );

    if (checkPrivileges("device")) {
      const pp = {
        ...this.props,
        services: this.props.services,
      };
      return (
        <div>
          <Layout
            {...pp}
            endMessage={endMessage}
            openCreateFrom={this.openCreateTypeFrom}
            classFromChildren="has-padding"
            editCommandForm={this.showSubTypes}
            searchItems={this.searchItems}
            onEditType={this.openEditTypeFrom}
            fetchMoreItems={this.fetchMoreItems}
            {...this.state}
          >
            <div className="main-content-page">
              {this.state.onDeleteConfirmation && (
                <ConfirmDialoag
                  onCancel={this.onCancel}
                  onOk={this.removeSubType}
                  title={this.props.translate("areYouWantToDelete")}
                  children={
                    this.state.deleteForm?.name ||
                    this.state.deleteForm?.label ||
                    ""
                  }
                />
              )}
              <div
                style={{
                  background: this.props.themecolors.backgroundColor,
                  color: this.props.themecolors.textColor,
                  borderRadius: 6,
                  padding: 16,
                }}
              >
                {this.state.mode === "Show" ? (
                  <CommonTableSection
                    onEdit={this.openEditSubTypeFrom}
                    themecolors={this.props.themecolors}
                    componentData={this.state?.expense || []}
                    showCheckbox={false}
                    showGroupAccess={false}
                    onDelete={this.onDeleteSub}
                    label={this.props.translate("services")}
                    canUpdate={true}
                    handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                    rowsPerPage={this.state?.rowsPerPage}
                    createButton={
                      <div style={{ marginLeft: 10 }}>
                        <Button onClick={this.openCreateSubTypeFrom}>
                          {this.props.translate("Create")}
                        </Button>
                      </div>
                    }
                    parent={2}
                    rowDefinition={[
                      {
                        id: "label",
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate("sharedName"),
                      },
                      {
                        id: "subtypeGroup",
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate("subtypeGroup"),
                      },
                      {
                        id: "subtypenum",
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate("subtypenum"),
                      },
                      {
                        id: "unitPrice",
                        numeric: false,
                        disablePadding: false,
                        label: this.props.translate("unitPrice"),
                      },
                    ]}
                    canDelete={true}
                    canRemove={false}
                    canAssign={false}
                  />
                ) : null}
                {this.state.mode === "editSub" ||
                this.state.mode === "create" ? (
                  <EditSubForm
                    form={this.state.serviceForm}
                    handleChange={this.handleChange}
                    translate={this.props.translate}
                    submitForm={this.submitForm}
                    closeEditForm={this.closeEditForm}
                    mode={this.state?.mode}
                    isVisableSubmitBtn={this.state?.isVisableSubmitBtn}
                    typeSelection={this.state?.typeSelection}
                    field={
                      this.state.typeSelection === "createSub" ||
                      this.state.typeSelection === "updateSub"
                        ? "label"
                        : "name"
                    }
                  />
                ) : null}
                {this.state.mode === "" ? (
                  <EmptyState
                    text={this.props.translate("noServicesSelected")}
                  />
                ) : null}
              </div>
            </div>
          </Layout>
        </div>
      );
    } else {
      return null;
    }
  }
}

const EditSubForm = ({
  form,
  handleChange,
  translate,
  submitForm,
  closeEditForm,
  mode,
  isVisableSubmitBtn,
  typeSelection,
  field,
}) => {
  return (
    <div>
      <h4 className="page-title">{translate("services")}</h4>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="name"
            required
            margin="dense"
            label={translate("sharedName")}
            variant="outlined"
            fullWidth
            value={form.name || form.label}
            onChange={(e) => handleChange(field, e.target.value)}
          />
        </Grid>
        {(typeSelection === "createSub" || typeSelection === "updateSub") && (
          <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="subtypeGroup"
                margin="dense"
                label={translate("subtypeGroup")}
                variant="outlined"
                fullWidth
                value={form.subtypeGroup}
                onChange={(e) => handleChange("subtypeGroup", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="subtypenum"
                margin="dense"
                label={translate("subtypenum")}
                variant="outlined"
                fullWidth
                value={form.subtypenum}
                onChange={(e) => handleChange("subtypenum", e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <TextField
                id="unitPrice"
                margin="dense"
                label={translate("Price")}
                variant="outlined"
                fullWidth
                type="number"
                value={form.unitPrice}
                onChange={(e) => handleChange("unitPrice", e.target.value)}
              />
            </Grid>
          </>
        )}
        {(typeSelection === "createType" || typeSelection === "updateType") && (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              id="description"
              margin="dense"
              label={translate("sharedDescription")}
              variant="outlined"
              fullWidth
              value={form.description}
              onChange={(e) => handleChange("description", e.target.value)}
            />
          </Grid>
        )}
      </Grid>
      <div style={{ display: "flex", marginTop: 10 }}>
        <div style={{ marginLeft: "auto" }} />
        <Button
          size="small"
          style={{ marginRight: 15 }}
          className="button-white"
          onClick={closeEditForm}
        >
          {translate("sharedCancel")}{" "}
        </Button>
        {checkPrivileges("commandCreate") && (
          <Button
            size="small"
            onClick={submitForm}
            disabled={isVisableSubmitBtn}
          >
            {translate(mode === "editSub" ? "update" : "sharedCreate")}
          </Button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  commands: state.commands,
  users: state.users,
  groups: state.groups,
  selecteduser: state.selecteduser,
  devices3: state.devices3,
  roles: state.roles,
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
  services: state.services,
});

export default connect(mapStateToProps)(withTranslationWrapper(Services));
