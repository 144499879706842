import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import Layout from "../../Layout";
import { removeUser } from "./../../Actions/Users";
import { getGroups } from "./../../Actions/Groups";
import ConfirmDialoag from "../../Components/common/ConfirmDialoag";
import { notifySuccess } from "../../Utils/CustomNotifcations";
import MainUnitModal from "./MainUnitModal";
import withResources from "../HOCRecources";
import instance from "../../axios";
import EmptyState from "../../Components/common/EmptyState";
import { UsersModal } from "./../../Components/Users/usersModal";
import { checkPrivileges, errorHandler } from "../../Helpers/index";
import "./Style.scss";
import withTranslationWrapper from "../../HOC/withTranslation";

const deviceDataFormat = {
  id: "",
  attributes: "",
  name: "",
  uniqueId: "",
  status: "",
  disabled: "",
  lastUpdate: "",
  positionId: "",
  groupId: "",
  phone: "",
  model: "",
  contact: "",
  category: "",
  geofenceIds: "",
  devicePassword: "",
};

class Units extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewScreen: null,
      // new
      userLimit: false,
      resFormData: { ...deviceDataFormat },
      selecteditem: "",
      remainings: {},
      remainingsDevice: {},
      limitFetech: false,
      resourceList: false,
      editResource: false,
      selectedItem: false,
      users: [],
      searchText: "",

      /* initFetch: false,
      currentPage: 1,
      pagesize: 50,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: ''
      } */
    };
    // new
    this.removedItem = this.removedItem.bind(this);
    this.addNewUser = this.addNewUser.bind(this);
    this.fetchUserLimit = this.fetchUserLimit.bind(this);
  }

  // General From

  addNewUser() {
    // this.props.history.push("/addUser");
    this.setState(
      { viewScreen: "add", resFormData: { ...deviceDataFormat } },
      () => {
        this.props.history.push("/users");
      }
    );
  }

  removedItem = () => {
    const { selectedItem } = this.state;
    if (selectedItem.id) {
      instance({
        url: `/api/users/${selectedItem.id}`,
        method: "DELETE",
      })
        .then((response) => {
          // if (response.ok) {
          this.props.dispatch(removeUser(selectedItem));
          //this.props.enqueueSnackbar(this.props.translate('userIsRemoved'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
          this.props.dispatch(
            notifySuccess(this.props.translate("userIsRemoved"))
          );
          this.setState({
            onDeleteConfirmation: false,
          });
          this.props.history.push("/users");
          this.fetchUsers(this.state.searchText, this.props.users2);
          this.onCancel();
          // } else {
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
      // if (e && e.text) {
      //   e.text().then(err => {
      //       var array = err.split("-")
      //       if(array.length > 1){
      //             this.props.dispatch(
      //               notifyError({
      //                 message: this.props.translate(array[0]),
      //                 autoDismiss: 5,
      //                 location: this.props.location
      //               })
      //             )
      //       }
      //       else {
      //           this.props.dispatch(
      //             notifyError({
      //               message: this.props.translate('somthingWentWrongMessage'),
      //               autoDismiss: 5,
      //               location: this.props.location
      //             })
      //           )
      //       }
      //       this.setState({
      //         onDeleteConfirmation: false
      //       })
      //   })
      // }
    }
  };
  showResources = () => {
    this.setState({
      resourceList: !this.state.resourceList,
    });
    if (this.state.resourceList) {
      this.setState({
        editResource: false,
      });
    }
  };
  addResource = () => {
    this.setState({
      editResource: true,
      selectedResourse: "",
      viewScreen: null,
    });
  };
  onEditResource = (item) => {
    this.setState({
      editResource: true,
      selectedResourse: item,
    });
  };
  onCloseResource = () => {
    this.setState({
      editResource: false,
    });
  };

  onCloseModal = () => {
    this.setState({
      viewScreen: "",
    });
    this.props.history.push("/users");
  };

  fetchUserLimit(value) {
    this.setState({ limitFetech: value, remainings: {} }, () => {
      this.checkUserLimit();
    });
  }

  // this call from render where check the limit of users
  checkUserLimit = () => {
    if (checkPrivileges("userCreate")) {
      if (
        this.props.logInUsers &&
        this.props.logInUsers.id &&
        !Object.keys(this.state.remainings).length &&
        !this.state.limitFetech
      ) {
        if (this.props.logInUsers.userLimit !== -1) {
          this.setState({ limitFetech: true }, () => {
            instance({
              url: `/api/users/count`,
              method: "GET",
              params: {
                userId: this.props.logInUsers.id,
              },
            })
              // .then(response => {
              //   if (response.ok) {
              //     response.json()
              .then((params) => {
                this.setState({
                  remainings: params,
                  userLimit: params.remaningLimit,
                });
                //   })
                // }
                // else{
                //   throw response
                // }
              })
              .catch((error) => {
                // errorHandler(error,this.props.dispatch)
              });
          });
        } else {
          this.setState({
            userLimit: true,
            //remainings: {'allset': true}
          });
        }
      } else if (
        this.props.logInUsers &&
        this.props.logInUsers.userLimit === -1
      ) {
        this.setState({
          userLimit: true,
          //remainings: {'allset': true}
        });
      }
    } else {
      this.setState({
        userLimit: false,
        limitFetech: true,
      });
    }
  };

  /* fetchRoles = () => {
    if (checkPrivileges('role') && !this.state.userRolePropess) {
      this.setState(
        {
          userRolePropess: true
        },
        () => {
          if (this.props.logInUsers.userType === -1) {
            fectch(`/api/roles?all=true`).then(response => {
              if (response.ok) {
                response.json().then(role => {
                  this.setState({
                    roleTypes: role
                  })
                })
              }
            })
          } else if (this.props.logInUsers.userType !== 1) {
            fectch(`/api/roles?userId=${this.props.logInUsers.id}`).then(
              response => {
                if (response.ok) {
                  response.json().then(role => {
                    this.setState({
                      roleTypes: role
                    })
                  })
                }
              }
            )
          }
        }
      )
    }
  } */

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
      selectedItem: "",
      onDeleteConfirmationNotification: false,
      onDeleteConfirmationAttributes: false,
      onDeleteConfirmationMaintenance: false,
      onDeleteConfirmationCommand: false,
    });
  };
  onRemovedUser = (item) => {
    this.setState({
      onDeleteConfirmation: true,
      selectedItem: item,
    });
  };

  onRemovedNotification = (item) => {
    this.setState({
      notificationForm: { ...item[0] },
      onDeleteConfirmationNotification: true,
    });
  };
  onRemovedAttribute = (item) => {
    this.setState({
      computedAttForm: { ...item[0] },
      onDeleteConfirmationAttributes: true,
    });
  };
  onRemovedCommand = (item) => {
    this.setState({
      savedCommandsForm: { ...item[0] },
      onDeleteConfirmationCommand: true,
    });
  };
  onRemovedMaintenance = (item) => {
    this.setState({
      maintenanceForm: { ...item[0] },
      onDeleteConfirmationMaintenance: true,
    });
  };
  UNSAFE_componentWillMount() {
    this.setState({ users: this.props.users2 });
    this.checkUserLimit();
    // if (checkPrivileges("group") && this.props.logInUsers.id) {
    //   this.fetchResources(this.props.logInUsers.id);
    // }
  }

  UNSAFE_componentWillReceiveProps(n) {
    let s = this.state;
    if (n.match.params.id) {
      this.setState({ viewScreen: "edit" });
    }

    if (n.addUserScreen) {
      this.setState({ viewScreen: "add" });
    }
    // if (!n.addUserScreen && !n.match.params.id) {
    //   this.setState({ viewScreen: null });
    // }
    if (!s.searchText && n.users2.length) {
      this.fetchUsers("", n.users2);
    }
    // if (
    //   checkPrivileges("group") &&
    //   (n.logInUsers && n.logInUsers.id) !== this.props.logInUsers.id
    // ) {
    //   this.fetchResources(n.logInUsers.id);
    // }
    this.checkUserLimit();
  }

  fetchUsers = (text, users) => {
    const u = users.filter((u) =>
      (u.id + u.name + u.email).toLowerCase().includes(text.toLowerCase())
    );
    this.setState({ users: u });
  };
  fetchResources = (id) => {
    axios({
      method: "GET",
      url: `/api/groups/get?userId=${id}`,
      // cancelToken: source.token,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.data &&
            response.data.data.map((item) => {
              item.check = false;
              return null;
            });
          this.props.dispatch(getGroups(response.data && response.data.data));
        }
      })
      .catch((e) => {
        // this.displayError(e)
      });
  };
  searchItems = (text) => {
    this.setState(
      {
        searchText: text,
      },
      () => {
        this.fetchUsers(this.state.searchText, this.props.users2);
      }
    );
  };

  componentWillUnmount() {
    this.setState({
      viewScreen: null,
      // new
      userLimit: false,
      resFormData: { ...deviceDataFormat },
      selecteditem: "",
      remainings: {},
      remainingsDevice: {},
      limitFetech: false,

      users: [],
      searchText: "",
    });
  }

  render() {
    if (checkPrivileges("user")) {
      const pp = {
        ...this.props,
        users: this.state.users,
      };

      return (
        <Layout
          {...pp}
          addUser={this.addNewUser}
          removedItem={this.onRemovedUser}
          userLimit={this.state.userLimit}
          classFromChildren="main-content-page has-padding"
          searchItems={this.searchItems}
          showResources={this.showResources}
          onEditResource={this.onEditResource}
          addResource={this.addResource}
          resourceList={this.state.resourceList}
        >
          <div>
            {this.state.onDeleteConfirmation && (
              <ConfirmDialoag
                onCancel={this.onCancel}
                onOk={this.removedItem}
                title={this.props.translate("areYouWantToDelete")}
                children={this.state.selectedItem.name}
              />
            )}
            {this.state.viewScreen === "add" && this.state.userLimit ? (
              <UsersModal
                {...pp}
                viewScreen={this.state.viewScreen}
                onCloseModal={this.onCloseModal}
                activeOperation={"add"}
                selecteditem={this.state.resFormData}
                roleTypes={this.state.roleTypes}
                fetchUserLimit={this.fetchUserLimit}
                resourceList={this.state.resourceList}
              />
            ) : (
              <div>
                {this.state.viewScreen === "edit" &&
                this.props.users2.length &&
                this.props.match.params.id ? (
                  <MainUnitModal
                    id={this.props.match.params.id}
                    history={this.props.history}
                    hash={this.props.location.hash}
                    extra={{ fetchUserLimit: this.fetchUserLimit }}
                  />
                ) : (
                  <EmptyState
                    text={this.props.translate("pleaseSelectedUser")}
                  />
                )}
              </div>
            )}
          </div>
          {/* {this.state.editResource ? (
            <div className="main-content-page">
              <div
                style={{
                  background: this.props.themecolors.backgroundColor,
                  color: this.props.themecolors.textColor,
                  borderRadius: 6,
                }}
              >
                <ResourceModal
                  changeResource={this.props.changeResource}
                  selectedResourse={this.state.selectedResourse}
                  fetchNestedItems={this.props.fetchNestedItems}
                  editResource={this.state.editResource}
                  onCloseResource={this.onCloseResource}
                  itemType="User"
                />
              
              </div>
            </div>
          ) : null} */}
        </Layout>
      );
    } else {
      return null;
    }
  }
}

const mapState = (state) => {
  return {
    users2: state.users,
    themecolors: state.themeColors,
    logInUsers: state.logInUsers,
  };
};

const mapStateToProps = connect(mapState);
export default mapStateToProps(
  withTranslationWrapper(withResources(Units, "User"))
);
