import React, { Component } from "react";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Button from "./../../../Components/common/Button";
import Loader from "./../../Loader";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Grid from "@mui/material/Grid";
import DateRangePicker from "./../../../Components/common/DateRangePicker";
import moment from "moment";
import Collapse from "@mui/material/Collapse";
import { CompactPicker } from "react-color";
import Style from "style-it";
import {
  checkPrivileges,
  getTimeFormat,
  setAttributeFormat,
  errorHandler,
} from "../../../Helpers";
import "moment-timezone";
import Scrollbar from "react-scrollbars-custom";
import PublishIcon from "@mui/icons-material/Publish";
import { CheckIcon } from "../../../Components/common/CheckIcon";
import DeviceSelector from "../../../Components/Devices/DeviceSelector";
import DownloadIcon from "@mui/icons-material/GetApp";
import { Icon, Tooltip } from "@mui/material";
import { ReactComponent as LoaderIcon } from "./../../../assets/tracks-loader.svg";
import SettingsIcon from "@mui/icons-material/Settings";
import instance from "../../../axios";
import { ReactComponent as DistanceIcon } from "../../../assets/monitoring/icons/distance.svg";
import { ReactComponent as DurationIcon } from "../../../assets/monitoring/icons/duration.svg";
import { ReactComponent as TripsIcon } from "../../../assets/monitoring/icons/trips.svg";
import { ReactComponent as SelectedDateIcon } from "../../../assets/tracks/date-check-full.svg";

class DrawTrips extends Component {
  constructor(props) {
    super(props);
    this.state = { devices: props.trips || {}, assigned: false };
    this.toggleAllGroup = this.toggleAllGroup.bind(this);
  }

  UNSAFE_componentWillReceiveProps(n) {
    this.setState({ devices: n.trips });
  }

  toggleAllGroup(groupId, check) {
    this.props.toggleAllGroup(groupId, check);
  }

  render() {
    if (this.state.emptyScreen) {
      return <div>No Record</div>;
    } else {
      return (
        <Devices
          groups={this.state.devices}
          toggleAllGroup={this.toggleAllGroup}
          {...this.props}
        />
      );
    }
  }
}
export default class Tracks extends Component {
  constructor(props) {
    super(props);
    this.goBack = this.goBack.bind(this);
    this.state = {
      kmlFile: {},
    };
  }
  createReport = (type) => {
    //this.props.collapseSidebar();
    this.props.createReport(type);
  };
  goBack() {
    this.props.openForm();
  }

  uploadTrigger = () => {
    const el = document.getElementById("uploadTrigger");
    el.click();
  };

  kmlFileUpload = (event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      const kmlFile = this.props.kmlFileUpload(event);
      if (kmlFile) {
        this.setState({ kmlFile });
      }
    }
  };

  clearSelection = () => {
    this.setState({ kmlFile: {} }, () => {
      this.props.clearSelection();
    });
  };

  render() {
    let viewScreen;
    const empty = null;
    if (this.props.view === "reportsData") {
      viewScreen = (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 10px",
              alignItems: "center",
              color: this.props.themecolors.textColor,
              // textAlign: "right",
              // padding: "0 10px",
              // margin: "16px 0 12px",
            }}
          >
            <h2>{this.props.translate("Tracks")}</h2>
            <div>
              <a
                href={empty}
                style={{
                  textAlign: "center",
                  display: "inline-block",
                  width: 48,
                  cursor: "pointer",
                  color: this.props.themecolors.textColor,
                }}
                onClick={(e) => this.goBack("displayForm")}
              >
                <KeyboardArrowLeftIcon style={{ fontSize: 30 }} />
              </a>
            </div>
          </div>
          {this.props.mainView === "tracks" && (
            <ul className="list-view with-padding-right">
              {this.props &&
              this.props.reportsData &&
              this.props.reportsData.length ? (
                <>
                  {/* <div style={{
                  display:"flex", 
                  margin:"10px",
                // justifyContent:"space-between",
                 alignContent:"center"}}> 
                <SelectedDateIcon/>
                <div style={{fontSize: 14,fontWeight:600, color:this.props.themecolors.textColor,   marginLeft:"10px",}}>
                 <span> {
                   this.props.selectedDateLabel ==="Choose Date" ?
                    <>From: {moment().startOf("day").format("DD-MM-YYYY")}  To:{moment().endOf("day").format("DD-MM-YYYY")}</> :
                    //  this.props.selectedDateLabel === "Custom Range" ?
                    <>From: {moment(this.props.from).format("DD-MM-YYYY")} To: {moment(this.props.to).format("DD-MM-YYYY")} </>
                    // : this.props.selectedDateLabel 
                    } 
                    </span>

                 </div>
                </div> */}
                  <DrawTrips
                    to={this.props.to}
                    from={this.props.from}
                    selectedDateLabel={this.props.selectedDateLabel}
                    logInUser={this.props.logInUser}
                    changeCheckbox={this.props.changeCheckbox}
                    routesLoading={this.props.routesLoading}
                    updateColor={this.props.updateColor}
                    downloadAllTracks={this.props.downloadAllTracks}
                    pageLoading={this.props.pageLoading}
                    trips={this.props.trips}
                    drawRoute={this.props.drawRoute}
                    toggleAllGroup={this.props.toggleAllGroup}
                    toggleDaywiseGroup={this.props.toggleDaywiseGroup}
                    cancelRoute={this.props.cancelRoute}
                    collapseSidebar={this.props.collapseSidebar}
                    translate={this.props.translate}
                    themecolors={this.props.themecolors}
                  />
                </>
              ) : (
                <div style={{ padding: 50, textAlign: "center", fontSize: 20 }}>
                  {this.props.translate("noRecordFound")}
                </div>
              )}
            </ul>
          )}
        </div>
      );
    } else if (this.props.view === "displayForm") {
      viewScreen = (
        <form className="section-head-filter">
          <h3
            className="filter-row"
            style={{ color: this.props.themecolors.textColor }}
          >
            {this.props.translate("trackForm")}{" "}
          </h3>
          {!this.state.kmlFile.name ? (
            <>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <DateRangePicker
                    fill
                    selectedDate={this.props.selectedDate}
                    label={
                      this.props.translate("reportFrom") +
                      " " +
                      this.props.translate("reportTo")
                    }
                    onEvent={this.props.showDates}
                  />
                </Grid>
              </Grid>
              <DeviceSelector
                value={this.props.deviceId}
                onChange={this.props.selectedDevices}
                rows={10}
                themecolors={this.props.themecolors}
              />
              <Button
                disabled={!this.props.validatedForm}
                // style={{ marginBottom: 10, marginTop: 15 }}
                // variant="contained"
                style={{
                  padding: "6px 20px",
                  margin: "10px 5px",
                  color: !this.props.validatedForm ? "#000" : "#fff",
                  borderRadius: 8,
                  background: this.props.validatedForm
                    ? this.props.themecolors.menuActiveBackground
                    : "white",
                  border: `1px solid ${this.props.themecolors.menuActiveBackground}`,
                }}
                onClick={this.createReport}
              >
                {this.props.translate("submit")}
              </Button>
            </>
          ) : (
            <div>
              <div>File Name: {this.state.kmlFile.name}</div>
              <p style={{ marginTop: 0 }}>Size: {this.state.kmlFile.size}</p>
              <Button onClick={this.clearSelection}>Clear</Button>
            </div>
          )}
        </form>
      );
    }

    if (viewScreen) {
      return (
        <Style>
          {`
            .material-icons {vertical-align: middle; color: inherit}
          `}
          <aside className="fms-sidebar sidebar-bg-layer">
            {checkPrivileges("track") && (
              <Scrollbar disableTracksWidthCompensation>{viewScreen}</Scrollbar>
            )}
          </aside>
        </Style>
      );
    } else {
      return this.props.trackLoading ? (
        <div className="sidebar-bg-layer">
          <Loader />
        </div>
      ) : null;
    }
  }
}

class Devices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      driversProcess: false,
      groupsProcess: false,
      calendarsProcess: false,
    };

    this.devices = [];
  }

  render() {
    return <DrawGroups {...this.props} />;
  }
}

export class DrawGroups extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(id, event) {
    //const { checked }  = event.target;
    //this.props.dispatch(updateDeviceVisible({checked, id}));
  }

  render() {
    return (
      <div className="group-view list-row-condest tracks-view">
        <ul className="list-view-group">
          {Object.keys(this.props.groups).map((key, index) => {
            return (
              <DrawGroupRow
                key={index}
                index={index}
                groupId={key}
                group={this.props.groups[key]}
                {...this.props}
              />
            );
          })}
        </ul>
        {/*<ul className="list-view">
            <ListRow motion="play" signals="online" unit="car" status="online" />
            <ListRow motion="play" signals="online" unit="car" status="online" />
            <ListRow motion="pause" signals="online" unit="car" status="offline" />
            <ListRow motion="pause" signals="online" unit="car" status="offline" />
          </ul>*/}
      </div>
    );
  }
}

export class DrawGroupRow extends Component {
  constructor(props) {
    super(props);
    this.groupCollapse = this.groupCollapse.bind(this);
    this.toggleGroupDevices = this.toggleGroupDevices.bind(this);
    this.state = {
      isChecked: false,
      open: props.index === 0,
    };
  }

  groupCollapse(event) {
    event.preventDefault();
    let open = this.state.open;
    this.setState({ open: !open });
    //const { checked }  = event.target;
    //this.props.dispatch(updateDeviceVisible({checked, id}));
  }

  UNSAFE_componentWillReceiveProps(NextProps) {
    let devices = Object.assign({ ...NextProps.group });

    if (Object.keys(devices["data"]).length) {
      this.setState({ isChecked: true });

      Object.keys(devices["data"]).map((key) => {
        devices["data"][key].map((row) => {
          if (row.checked !== true) {
            this.setState({ isChecked: false });
          }
          return null;
        });
        return null;
      });
    }
  }

  toggleGroupDevices(event) {
    //this.props.dispatch(toggleGroupDevices({checked: event.target.checked, id: this.props.group.id}));
    this.props.toggleAllGroup(this.props.groupId, event.target.checked);
    this.setState({
      isChecked: event.target.checked,
    });
  }

  /*shouldComponentUpdate(nextProps, nextState){
      return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state); // equals() is your implementation
  }*/

  render() {
    const emptyLinks = null;

    let mileage = 0;
    let totalTrips = 0;
    let totalDistance = 0;
    if (
      this.props.group &&
      this.props.group.data &&
      Object.keys(this.props.group.data) &&
      Object.keys(this.props.group.data).length
    ) {
      Object.keys(this.props.group.data).map((date) => {
        if (this.props.group.data[date]) {
          totalTrips += this.props.group.data[date].length;
          this.props.group.data[date].map((row) => {
            totalDistance += row.distance;
            if (row.checked) {
              mileage += row.distance;
            }
            return null;
          });
        }
        return null;
      });
    }

    return (
      <li key={this.props.group.id} style={{ margin: "5px 0" }}>
        <div
          className={"clearfix group-list-row list-row sticky"}
          style={{
            backgroundColor: "#F0F4F7",
            borderRadius: 8,
            padding: "0px 10px",
            margin: "0 10px",
            maxWidth: "100%",
          }}
        >
          <label className="checkbox" style={{ width:"100%"}}>
            <span
              style={{
                // marginTop: "50px",
                // marginLeft: "5px",
                // marginRight: "5px",
                // marginBottom: "0px",
                margin: "10px 10px 20px 0px",
                maxWidth: "5%",
              }}
            >
              <input
                type="checkbox"
                checked={this.state.isChecked}
                onChange={this.toggleGroupDevices.bind(this)}
              />
              <CheckIcon className="theme-input-checkbox" />
            </span>

            <div style={{ padding: "2px" , width:"100%"}}>
              <div
                className="unit-name"
                style={{
                  marginTop: "5px",
                  display: "flex",
                  width:"100%",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    margin: 0,
                    display: "flex",
                    width: "55%",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span className="unit-img">
                    <img
                      src={
                        "/assets/category/default/" +
                        (this.props.group.detail.category || "default") +
                        ".svg"
                      }
                      alt={this.props.group.detail.deviceName}
                    />
                  </span>
                  <span className="unit-name-text">
                    {this.props.group.detail.deviceName}
                  </span>
                </div>
                <div>
                  <a
                    href={emptyLinks}
                    onClick={(e) => this.groupCollapse(e)}
                    title={this.props.translate("Additional Menu")}
                    // style={{marginLeft:80}}
                  >
                    <KeyboardArrowDownIcon style={{ fontSize: 24 }} />
                  </a>
                </div>
              </div>

              {/* <div style={{ display: "flex", margin: 0, marginTop: 4 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      alignSelf: "center",
                      padding: "0px 2px 0 2px",
                      margin: "5px 0 0 0",
                    }}
                  >
                    <DistanceIcon
                      width={20}
                      height={22}
                      color="#ffffff"
                      fill="#ffffff"
                    />
                  </p>
                  <p
                    style={{
                      marginLeft: 5,
                      lineHeight: "0.8em",
                      padding: 2,
                      margin: 0,
                    }}
                  >
                    <span style={{ fontSize: 11 }}>
                      {this.props.translate("distance")}
                    </span>
                    <br />
                    <span style={{ fontSize: 11 }}>
                      <strong>
                        {setAttributeFormat(
                          "distance",
                          this.props.group.detail?.distance || ""
                        )}
                      </strong>
                    </span>{" "}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "0 10px",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      alignSelf: "center",
                      padding: "0px 2px 0 2px",
                      margin: "5px 0 0 0",
                    }}
                  >
                    <DurationIcon
                      width={20}
                      height={22}
                      color="#ffffff"
                      fill="#ffffff"
                    />
                  </p>
                  <p
                    style={{
                      marginLeft: 5,
                      lineHeight: "0.8em",
                      padding: 2,
                      margin: 0,
                    }}
                  >
                    <span style={{ fontSize: 11 }}>
                      {this.props.translate("reportDuration")}
                    </span>
                    <br />
                    <span style={{ fontSize: 11 }}>
                      <strong>
                        {moment
                          .duration(
                            this.props.group.detail?.duration,
                            "milliseconds"
                          )
                          .format("H[h]m[m]")}
                      </strong>
                    </span>{" "}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      alignSelf: "center",
                      padding: "0px 2px 0 2px",
                      margin: "5px 0 0 0",
                    }}
                  >
                    <TripsIcon
                      width={20}
                      height={22}
                      color="#ffffff"
                      fill="#ffffff"
                    />
                  </p>
                  <p
                    style={{
                      marginLeft: 5,
                      lineHeight: "0.8em",
                      padding: 2,
                      margin: 0,
                    }}
                  >
                    <span style={{ fontSize: 11 }}>Trips</span>
                    <br />
                    <span style={{ fontSize: 11 }}>
                      <strong>{totalTrips}</strong>
                    </span>{" "}
                  </p>
                </div>
              </div> */}
            </div>
            {/* <span className="unit-name">
                <span className="unit-img">
                  <img
                    src={
                      "/assets/category/default/" +
                      (this.props.group.detail.category || "default") +
                      ".svg"
                    }
                    alt={this.props.group.detail.deviceName}
                  />
                </span>
                <span style={{ minWidth: 0 }}>
                  <span className="unit-name-text">
                    {this.props.group.detail.deviceName}
                  </span>
                  <span className={"unit-status-text online"}>
                    <span className="action-related-info">
                      {this.props.translate("distance")}{" "}
                      <span className="action-related-info-value">
                        {totalDistance
                          ? (totalDistance / 1000).toFixed(2) + " km"
                          : null}
                      </span>
                    </span>
                  </span>
                </span>
            </span> */}
          </label>
          {/* <div
            className="pull-right"
            style={{ flexDirection: "column", justifyContent: "center" }}
          >
            <span className="action-item">
              <small className="action-related-info">
                {this.props.translate("reportTrips")}{" "}
                <span className="action-related-info-value">{totalTrips}</span>
              </small>
              <a
                href={emptyLinks}
                onClick={(e) => this.groupCollapse(e)}
                title="Additional Menu"
              >
                <KeyboardArrowDownIcon style={{ fontSize: 24 }} />
              </a>
            </span>
            <strong className="action-item">
              <small>
                {mileage ? (mileage / 1000).toFixed(2) + " km" : null}
              </small>
              {mileage ? (
                <a
                  href={emptyLinks}
                  onClick={(e) => this.props.downloadAllTracks(e)}
                  title="Download all tracks"
                >
                  <DownloadIcon style={{ fontSize: 16 }} />
                </a>
              ) : null}
            </strong>
          </div> */}
        </div>
        <div style={{ padding: "0 10px" }}>
          <Collapse in={this.state.open} timeout="auto" unmountOnExit>
            <ListRows {...this.props} isChecked={this.state.isChecked} />
          </Collapse>
        </div>
      </li>
    );
  }
}

export class ListRows extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selectedDate: "",
    };
    this.groupCollapse = this.groupCollapse.bind(this);
  }
  UNSAFE_componentWillMount() {
    Object.keys(this.props.group.data).map((key, index) =>
      this.setState({
        [key]: index === 0,
        date: {
          ...this.state.date,
          [key]: false,
        },
        selectedDate: key,
      })
    );
  }
  UNSAFE_componentWillReceiveProps(NextProps) {
    let devices = Object.assign({ ...NextProps.group });
    if (Object.keys(devices["data"]).length) {
      this.setState(
        {
          date: {
            ...this.state.date,
            [this.state.selectedDate]: true,
          },
        },
        () => {
          devices["data"][this.state.selectedDate].map((row) => {
            if (row.checked !== true) {
              this.setState({
                date: {
                  ...this.state.date,
                  [this.state.selectedDate]: false,
                },
              });
            }
            return null;
          });
        }
      );
    }
  }
  groupCollapse(id) {
    let open = this.state[id] || false;
    this.setState({ [id]: !open });
    //const { checked }  = event.target;
    //this.props.dispatch(updateDeviceVisible({checked, id}));
  }
  toggleDaywiseGroup = (groupId, event, key) => {
    let check = event.target.checked;
    if (check) {
      this.setState(
        {
          selectedDate: key,
          date: {
            ...this.state.date,
            [key]: true,
          },
        },
        () => {
          this.props.toggleDaywiseGroup(groupId, check, key);
        }
      );
    } else {
      this.setState(
        {
          selectedDate: key,
          date: {
            ...this.state.date,
            [key]: false,
          },
        },
        () => {
          this.props.toggleDaywiseGroup(groupId, check, key);
        }
      );
    }
  };
  render() {
    return (
      <ul className="tracks-list-view">
        {Object.keys(this.props.group.data).map((key, index) => {
          let duration = 0;
          let distance = 0;
          this.props.group.data[key].map((row) => {
            distance += row.distance;
            duration += row.duration;
            return null;
          });

          return (
            <React.Fragment key={key}>
              <li
                className={"trip-date-row " + (this.state[key] ? "" : "closed")}
                style={{ cursor: "pointer" }}
                onClick={() => this.groupCollapse(key)}
              >
                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={this.props.isChecked || this.state.date[key]}
                    onChange={(e) =>
                      this.toggleDaywiseGroup(this.props.groupId, e, key)
                    }
                  />
                  <CheckIcon className="theme-input-checkbox" />
                </label>
                {key}
                <span className="pull-right">
                  {this.props.translate("reportTrips")}:{" "}
                  {this.props.group.data[key].length}
                  <KeyboardArrowDownIcon
                    style={{ fontSize: 24, verticalAlign: "middle" }}
                  />
                </span>
              </li>
              <Collapse in={this.state[key]} timeout="auto" unmountOnExit>
                <ListRow1
                  groupDate={key}
                  rows={this.props.group.data[key]}
                  {...this.props}
                />
              </Collapse>
              {/* <li>
                <div
                  className={
                    "list-footer-row " + (this.state[key] ? "" : "closed")
                  }
                >
                  <span className="trip-entity trip-distance">
                    <span style={{ fontSize: 10 }}>
                      {this.props.translate("distance")}
                    </span>
                    <br />
                    <strong>{setAttributeFormat("distance", distance)}</strong>
                  </span>
                  <span className="trip-entity trip-duration">
                    <span style={{ fontSize: 10 }}>
                      {this.props.translate("reportDuration")}
                    </span>
                    <br />
                    <strong>
                      {moment
                        .duration(duration, "milliseconds")
                        .format("H[h]m[m]")}
                    </strong>
                  </span>
                </div>
              </li> */}
            </React.Fragment>
          );
        })}
      </ul>
    );
  }
}

export class ListRow1 extends Component {
  /*  constructor (props) {
    super(props);
  }*/
  render() {
    return this.props.rows.map((position, index) => (
      <ListRow key={position.startPositionId} data={position} {...this.props} />
    ));
  }
}

export class ListRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trackId: "",
      deviceId: "",
      anchorEl: null,
      selectedDevice: "",
      editOption: false,
      removeOption: false,
      d_attriubtes: "",
      play: "offline",
      colorPicker: false,
      emptyScreen: false,
      playicon: { label: "Pause", value: "pause" },
    };

    this.handleChange = this.handleChange.bind(this);
    this.viewOnMap = this.viewOnMap.bind(this);
    this.hideColorPicker = this.hideColorPicker.bind(this);
    this.onClose = this.onClose.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.onCloseConfirm = this.onCloseConfirm.bind(this);
    this.removeDevice = this.removeDevice.bind(this);
    this.editDevice = this.editDevice.bind(this);
    this.updateColor = this.updateColor.bind(this);

    if (props.data) {
      props.pageLoading(false);
    }
  }

  editDevice(selectedDevice, event) {
    this.setState({
      editOption: true,
      removeOption: false,
      selectedDevice: selectedDevice,
    });
  }

  onCloseConfirm() {
    this.setState({ removeOption: false, editOption: false });
  }

  /*removeDevice(selectedDevice, event) {

    this.setState({ removeOption: true,
      editOption: false,
      selectedDevice: selectedDevice
    });     
  }*/

  removeDevice(selectedDevice) {
    if (window.confirm("Are you Sure to Delete")) {
      instance({
        url: `/api/devices/${selectedDevice.id}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // if (response.status === 204) {
          //this.props.dispatch(removeDevice(selectedDevice.id));
          alert("Unit is Deleted");
          // } else {
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  }

  handleChange = (isChecked, shouldViewOnMap) => {
    if (isChecked) {
      this.viewOnMap();
    } else {
      this.props.changeCheckbox(
        this.props.groupDate,
        this.props.groupId,
        this.props.data.startPositionId,
        false
      );
      this.props.cancelRoute(this.props.data);
    }
  };

  viewOnMap() {
    this.props.changeCheckbox(
      this.props.groupDate,
      this.props.groupId,
      this.props.data.startPositionId,
      true
    );
    this.props.drawRoute(this.props.data, true, "single");

    this.props.collapseSidebar();
  }

  openMenu(event) {
    this.setState({ colorPicker: true });
  }
  hideColorPicker(event) {
    this.setState({ colorPicker: false });
  }

  UNSAFE_componentWillReceiveProps(NextProps) {
    if (NextProps.data) {
      NextProps.pageLoading(false);
    }
  }
  /*componentWillReceiveProps(NextProps) {

    var checked = true;

      NextProps.devices.map((item, index) => {
          if(item.visible === false) {          
            checked = false;
          }
          return '';
      });

      this.props.isChecked(checked);

      if(NextProps.trackId === this.props.data.id) {
        this.setState({trackId: 'online'});
      }
      else {
        this.setState({trackId: ''});       
      }

      if(NextProps.deviceId === this.props.data.id) {
        this.setState({deviceId: 'online'});
      }
      else {
        this.setState({deviceId: ''});        
      }


      if(NextProps.trackId) {
        if(NextProps.positions.length) {
        NextProps.positions.map((pos) => {
          if(pos.deviceId === NextProps.trackId) {
            if(!isEqual(NextProps.bounds, [[pos.latitude, pos.longitude]])) {
              //this.props.dispatch(setBounds([[pos.latitude, pos.longitude]]));
            }
          }
          return '';
        })        
      }       
      }


      this.makeIcons(NextProps);


    }*/

  onClose() {
    this.setState({ anchorEl: null });
  }

  /*    shouldComponentUpdate(nextProps, nextState){
      return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state); // equals() is your implementation
  }*/

  checkLastResponseTime = (date) => {
    return moment(date).isBefore(moment().subtract(1, "day"));
  };

  updateColor(e) {
    this.props.updateColor(
      this.props.groupDate,
      this.props.groupId,
      this.props.data.startPositionId,
      e.hex
    );
  }

  render() {
    const emptyLinks = null;

    let distanceUnit =
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.distanceUnit
        ? this.props.logInUser.attributes.distanceUnit
        : "";

    let tz = "Asia/Dubai";

    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      tz = this.props.logInUser.attributes.timezone;
    }

    let timeFormat = getTimeFormat();
    return (
      <li className="">
        <div
          // onPointerDown ={() => this.handleChange(!this.props.data.checked, true)}
          onClick={() => this.handleChange(!this.props.data.checked)}
          onMouseDown={(e) => e.preventDefault()}
          style={{
            margin: "10px 0",
            boxShadow:
              "0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
            borderRadius: 8,
            background: "#FFFFFF",
            borderTop: this.props.data.checked
              ? `3px solid ${this.props.themecolors.menuActiveBackground}`
              : 0,
          }}
        >
          <div style={{ padding: "2px 8px", margin: 5 }}>
            <div style={{ display: "flex" }}>
              <label className="checkbox">
                <span
                  style={{
                    // marginTop: "50px",
                    // marginLeft: "5px",
                    // marginRight: "5px",
                    // marginBottom: "0px",
                    margin: "5px 0 0 0",
                    maxWidth: "5%",
                  }}
                >
                  <input
                    type="checkbox"
                    onChange={(e) => this.handleChange(this.props.data.checked)}
                    checked={this.props.data.checked}
                  />
                  <CheckIcon className="theme-input-checkbox" />
                </span>
              </label>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0  5px 0 0",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    alignSelf: "center",
                    padding: "0px 2px 0 2px",
                    margin: "5px 0 0 0",
                  }}
                >
                  <DistanceIcon
                    width={20}
                    height={22}
                    color="#ffffff"
                    fill="#ffffff"
                  />
                </p>
                <p
                  style={{
                    marginLeft: 5,
                    lineHeight: "0.8em",
                    padding: 2,
                    margin: 0,
                  }}
                >
                  <span style={{ fontSize: 11 }}>
                    {this.props.translate("distance")}
                  </span>
                  <br />
                  <span style={{ fontSize: 11 }}>
                    <strong>
                      {setAttributeFormat(
                        "distance",
                        this.props.data?.distance || ""
                      )}
                    </strong>
                  </span>{" "}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 5px 0 15px",
                  alignItems: "center",
                }}
              >
                <p
                  style={{
                    alignSelf: "center",
                    padding: "5px 2px 0 2px",
                    margin: "5px 0 0 0",
                  }}
                >
                  <DurationIcon
                    width={20}
                    height={22}
                    color="#ffffff"
                    fill="#ffffff"
                  />
                </p>
                <p
                  style={{
                    marginLeft: 5,
                    lineHeight: "0.8em",
                    padding: 2,
                    margin: 0,
                  }}
                >
                  <span style={{ fontSize: 11 }}>
                    {this.props.translate("reportDuration")}
                  </span>
                  <br />
                  <span style={{ fontSize: 11 }}>
                    <strong>
                      {moment
                        .duration(this.props.data?.duration, "milliseconds")
                        .format("H[h]m[m]")}{" "}
                    </strong>
                  </span>{" "}
                </p>
              </div>
              <div style={{ alignSelf: "center", marginLeft: "35px" }}>
                <span
                  className="action-item has-menu"
                  style={{
                    background: this.props.data?.color || "#ED3A3A",
                    width: 15,
                    height: 15,
                    borderRadius: 10,
                  }}
                >
                  <a
                    href={emptyLinks}
                    onClick={this.openMenu}
                    title="Monitoring Panel Customizer"
                  >
                    {/* <SettingsIcon />  */}
                  </a>
                </span>
              </div>
            </div>
            <hr style={{ margin: 0 }} />
            <div
              style={{ marginTop: 5, display: "flex", flexDirection: "row" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 8,
                }}
              >
                <span
                  style={{
                    background: "#35B369",
                    width: 15,
                    height: 15,
                    borderRadius: 10,
                  }}
                ></span>
                <span
                  style={{
                    background: "#CFCFCF",
                    width: 3,
                    height: 45,
                    alignSelf: "center",
                  }}
                ></span>
                <span
                  style={{
                    background: "#ED3A3A",
                    width: 15,
                    height: 15,
                    borderRadius: 10,
                  }}
                ></span>
              </div>
              <div style={{ marginLeft: 5 }}>
                <div>
                  {" "}
                  <p style={{ margin: 0 }}>
                    <strong>Start</strong>
                    <span style={{ marginLeft: 4, fontSize: 12 }}>
                      {moment(this.props.data.startTime)
                        .tz(tz)
                        .format(timeFormat)}
                    </span>
                  </p>
                  <p style={{ margin: 0, fontSize: 12 }}>
                    {this.props.data?.startAddress || ""}
                  </p>
                </div>
                <div style={{ marginTop: 5 }}>
                  {" "}
                  <p style={{ margin: 0 }}>
                    <strong style={{ color: "#ED3A3A" }}>End</strong>
                    <span style={{ marginLeft: 4, fontSize: 12 }}>
                      {moment(this.props.data.endTime)
                        .tz(tz)
                        .format(timeFormat)}
                    </span>
                  </p>
                  <p style={{ margin: 0, fontSize: 12 }}>
                    {this.props.data?.endAddress || ""}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div
          className={
            "tracks-list-row " +
            (this.props.data.checked ? "active " : "") +
            (this.checkLastResponseTime(this.props.data.lastUpdate) &&
              "disabled-row")
          }
        >
          <div
            style={{
              background: this.props.data.color,
              width: 6,
              height: 56,
              margin: "-8px 0 -8px 1px",
            }}
          />
            <label className="checkbox">
              <input
                type="checkbox"
                onChange={this.handleChange}
                checked={this.props.data.checked}
              />
              <CheckIcon className="theme-input-checkbox" />
            </label>
            <a
              href={emptyLinks}
              onClick={this.viewOnMap}
              className="unit-name unit-name-full"
              style={{ textDecoration: "none" }}
            >
              {" "}
              <span className="trip-entity trip-distance">
                <span style={{ fontSize: 10 }}>
                  {this.props.translate("distance")}
                </span>
                <br />
                <strong>
                  {setAttributeFormat(
                    "distance",
                    this.props.data.distance,
                    distanceUnit
                  )}
                </strong>
                <small>
                  <em>{}</em>
                </small>
              </span>{" "}
              <span className="trip-entity trip-time">
                <span style={{ fontSize: 10 }}>
                  {this.props.translate("maintenanceStart")}
                </span>
                <br />
                <strong>
                  {moment(this.props.data.startTime).tz(tz).format(timeFormat)}
                </strong>
              </span>
              <span className="trip-entity trip-duration">
                <span style={{ fontSize: 10 }}>
                  {this.props.translate("reportDuration")}
                </span>
                <br />
                <strong>
                  {moment
                    .duration(this.props.data.duration, "milliseconds")
                    .format("H[h]m[m]")}
                </strong>
              </span>
            </a>
          <div className="pull-right">
            <span className="action-item has-menu">
              <a
                href={emptyLinks}
                onClick={this.openMenu}
                title="Monitoring Panel Customizer"
              >
                <SettingsIcon />
              </a>
            </span>
            {this.props.routesLoading &&
              this.props.routesLoading[this.props.data.startPositionId] ? (
              <span
                style={{
                  position: "absolute",
                  left: 0,
                  top: 0,
                  bottom: 0,
                  right: 0,
                  borderRadius: 6,
                  background: this.props.themecolors.themeLightColor + "80",
                  color: this.props.themecolors.themeInverse,
                  textAlign: "center",
                }}
              >
                <LoaderIcon style={{ width: 30, height: 30 }} />
              </span>
            ) : null}
          </div>
        </div> */}
        {this.state.colorPicker ? (
          <div
            style={{ position: "absolute", top: "100%", right: 0, zIndex: 9 }}
            onMouseLeave={this.hideColorPicker}
          >
            {
              <CompactPicker
                color={this.props.data.color}
                onChangeComplete={this.updateColor}
              />
            }
          </div>
        ) : null}
      </li>
    );
  }
}
