import React, { Component } from "react";
import TextField from "../../../Components/common/TextField";
import Grid from "@mui/material/Grid";
import Button from "../../../Components/common/Button";
import MenuItem from "../../../Components/common/MenuItem";
import { connect } from "react-redux";
import isEqual from "react-fast-compare";
//import IconButton from '@mui/material/IconButton';
//import CloseIcon from '@mui/icons-material/Close';
import {notifySuccess } from "../../../Utils/CustomNotifcations";
import Autocomplete from "../../../Components/common/Autocomplete";
import { ReactComponent as SendIcon } from "../../../assets/nav/send.svg";

import {
  addComputedAttribute,
  addComputedAttributeDeviceWiseSelectedList,
} from "./../../../Actions/ComputedAttributes";
import { Icon } from "@mui/material";

//import Checkbox from './../../../Components/common/Checkbox'
import { updateDevice } from "../../../Actions/Devices";
import instance from "../../../axios";
import { errorHandler } from "../../../Helpers";
import Checkbox from "../../../Components/common/Checkbox";
class Accumulators extends Component {
  constructor(props) {
    super(props);
    let resData = {
      totalDistance: null,
      hours: null,
      mileage: null,
      mileageAccuracy: 0.2,
      mileageMinLimit: "",
      mileageMaxLimit: "",
      mFilter: false,
      mileageCounter: "gps",
      mileageUnit: "m",
      expression: "",
    };
    this.state = {
      isDisableBtn: true,
      assigned: false,
      currentId: 0,
      type: "mileage",
      ...resData,
      resData,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleChange2 = this.handleChange2.bind(this);
    this.updateAccumulators = this.updateAccumulators.bind(this);
    this.computedAttributes_Selection =
      this.computedAttributes_Selection.bind(this);
  }

  assignValues = () => {
    let exists = false;
    // console.log("=== deviceId",this.props.selectedUnit.id );
    // console.log("=== devices", this.props.devices);

    let device = this.props.devices.find(
      (item) => item.id === this.props.selectedUnit.id
    );

    // this.props.devices.map(d => {
    //   if (parseInt(this.props.selectedUnit.id) === parseInt(d.id)) {
    //     device = d
    //   }
    // console.log("=== devices",device);

    //   return null
    // })

    const position = this.props.positions.find(
      (item) => item.deviceId === this.props.selectedUnit.id
    );

    // this.props.positions.map(p => {
    if (position) {
      exists = true;
      this.setState({ assigned: true, currentId: position.deviceId }, () => {
        let a = position.attributes;
        let attr = device.attributes || {};
        let expression = "";
        let mileageCounter = "";
        let mileageUnit = (attr && attr.mileageUnit) || "m";
        let mileageMinLimit = (attr && attr.mileageMinLimit) || "0";
        let mileageMaxLimit = (attr && attr.mileageMaxLimit) || "0";
        let mFilter = (attr && attr.mFilter) || false;
        if (
          attr &&
          attr.mileageCounter &&
          attr.mileageCounter !== "gps" &&
          attr.mileageCounter !== "deviceOdometer"
        ) {
          expression = attr.mileageCounter;
          mileageCounter = "alternative";
        } else {
          mileageCounter = attr.mileageCounter || "gps";
        }
        if (attr && attr["report.ignoreOdometer"]) {
          let resData = {
            totalDistance: a.totalDistance
              ? (a.totalDistance / 1000).toFixed(2)
              : null,
            hours: a.hours ? (a.hours / (1000 * 3600)).toFixed(2) : null,
            mileageAccuracy: attr.mileageAccuracy,
            mileageCounter,
            mileageUnit,
            mileageMinLimit,
            mileageMaxLimit,
            mFilter,
            expression,
          };
          this.setState({
            type: "distance",
            ...resData,
            resData,
            // isDisableBtn: true
          });
        } else {
          let resData = {
            totalDistance: a.totalDistance
              ? (a.totalDistance / 1000).toFixed(2)
              : null,
            hours: a.hours ? (a.hours / (1000 * 3600)).toFixed(2) : null,
            mileageAccuracy: attr.mileageAccuracy,
            mileageCounter,
            mileageUnit,
            mileageMinLimit,
            mFilter,
            mileageMaxLimit,
            expression,
          };
          this.setState({
            type: "mileage",
            ...resData,
            resData,
            isDisableBtn: true,
          });
        }
      });
    }

    // ret/urn null
    // })
    if (!exists) {
      let resData = {
        // totalDistance: null,
        // hours: null,
        mileage: null,
        // mileageAccuracy: '',
        mileageCounter:
          // this.state.mileageCounter ? this.state.mileageAccuracy :
          device && device.attributes && device.attributes.mileageCounter
            ? device.attributes.mileageCounter
            : "gps",
      };
      this.setState({
        // isDisableBtn: true,
        assigned: false,
        // currentId: 0,
        type: "mileage",
        ...resData,
        resData,
      });
    }
  };

  componentWillMount() {
    this.assignValues();
  }

  componentWillReceiveProps(n) {
    if (
      n.devices.length &&
      n.positions.length &&
      this.state.currentId !== parseInt(n.selectedUnit.id)
    ) {
      this.assignValues();
    }
  }

  handleChange(name, event) {
    let { resData } = this.state;
    let dataValue =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;
    // if (!dataValue || dataValue === '0') {
    if (!dataValue) {
      dataValue = null;
    }
    // if(this.state.mileageCounter==='gps'){
    this.setState(
      { [name]: name === "mFilter" ? !this.state.mFilter : dataValue },
      () => {
        let {
          hours,
          mileageAccuracy,
          totalDistance,
          mileageUnit,
          mileageMinLimit,
          mileageMaxLimit,
          mFilter,
        } = this.state;
        let obj = {
          hours,
          mileageAccuracy,
          totalDistance,
          mileageMinLimit,
          mFilter,
          mileageMaxLimit,
        };
        let value = !isEqual(resData, obj);
        this.setState({
          isDisableBtn: !value,
        });
      }
    );
    // }
    // else{
    //   this.setState({ [name]: dataValue }, () => {
    //     let { hours, mileageAccuracy, totalDistance, mileageUnit } = this.state
    //     let obj = {
    //       hours,
    //       mileageAccuracy,
    //       totalDistance,
    //       mileageUnit
    //     }
    //     let value = !isEqual(resData, obj)
    //     this.setState({
    //       isDisableBtn: !value
    //     })
    //   })
    // }
  }
  handleChange2(name, newValue) {
    let { resData } = this.state;

    this.setState({ [name]: newValue }, () => {
      let { hours, mileageAccuracy, totalDistance } = this.state;
      let obj = {
        hours,
        mileageAccuracy,
        totalDistance,
      };
      let value = !isEqual(resData, obj);
      // this.setState({
      //   isDisableBtn: !value
      // })
    });
  }

  save = () => {
    const { hours, totalDistance, mileageAccuracy, mileageUnit } = this.state;
    let id = this.props.selectedUnit.id;
    // if (this.state.type === 'distance') {
    //   let form = {
    //     totalDistance: totalDistance * 1000,
    //     mileageAccuracy: mileageAccuracy,
    //     hours: hours * 1000 * 3600,
    //     deviceId: id
    //   }

    //   if (id) {
    //     //form.id = id;
    //     // method put

    //     this.updateAccumulators(id, form)
    //   }
    // } else {
    let form = {
      totalDistance: totalDistance * 1000,
      mileageAccuracy: mileageAccuracy,
      hours: hours * 1000 * 3600,
      deviceId: id,
    };

    if (id) {
      //form.id = id;
      // method put

      this.updateAccumulators(id, form);
      // }
    }
  };

  updateDevice = (dev) => {
    let visible = dev.visible;
    delete dev.visible;
    delete dev.indeterminate;
    delete dev.parent;
    // fetch(`/api/devices/${dev.id}`, {
    //   method: 'PUT',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...dev
    //   })
    // })
    instance({
      url: `/api/devices/${dev.id}`,
      method: "PUT",
      data: {
        ...dev,
      },
    })
      // .then(response => {
      //   if (response.status === 200) {
      //     response.json()
      .then((device) => {
        device["visible"] = visible || false;
        this.props.dispatch(updateDevice(device));
        //this.props.enqueueSnackbar(this.props.translate('accumulatorIsUpdated'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
        this.props.dispatch(
          notifySuccess(this.props.translate("accumulatorIsUpdated"))
        );
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
    //   }
    // })
  };

  updateAccumulators(id, form) {
    // fetch(`/api/devices/${id}/accumulators`, {
    //   method: 'PUT',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...form
    //   })
    // })
    instance({
      url: `/api/devices/${id}/accumulators`,
      method: "PUT",
      data: {
        ...form,
      },
    })
      .then((response) => {
        // if (response.ok) {
        let device = this.props.devices.find(
          (d) => parseInt(this.props.selectedUnit.id) === parseInt(d.id)
        );

        device.attributes["mileageAccuracy"] = form["mileageAccuracy"];

        let mileageCounter = "";
        if (
          this.state &&
          this.state.mileageCounter !== "gps" &&
          this.state.mileageCounter !== "deviceOdometer"
        ) {
          mileageCounter = this.state.expression;
        } else {
          mileageCounter = this.state.mileageCounter || "gps";
        }

        device.attributes["mileageCounter"] = mileageCounter;
        if (this.state.mileageUnit && this.state.mileageCounter !== "gps") {
          device.attributes["mileageUnit"] = this.state.mileageUnit;
          if (this.state.mFilter) {
            device.attributes["mileageMinLimit"] = this.state.mileageMinLimit
              ? parseFloat(this.state.mileageMinLimit)
              : 0;
            device.attributes["mileageMaxLimit"] = this.state.mileageMaxLimit
              ? parseFloat(this.state.mileageMaxLimit)
              : 0;
            device.attributes["mFilter"] = this.state.mFilter;
          } else {
            device.attributes["mFilter"] = false;
            delete device.attributes["mileageMinLimit"];
            delete device.attributes["mileageMaxLimit"];
          }
        } else {
          delete device.attributes["mileageUnit"];
          delete device.attributes["mileageMinLimit"];
          delete device.attributes["mileageMaxLimit"];
          delete device.attributes["mFilter"];
        }

        this.updateDevice(device);

        //this.props.enqueueSnackbar(this.props.translate('accumulatorIsUpdated'), { action: (<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }

  addComputedAttributes(deviceId, form) {
    // fetch(`/api/attributes/computed/`, {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...form
    //   })
    // })
    instance({
      url: `/api/attributes/computed/`,
      method: "POST",
      data: {
        ...form,
      },
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((Attribute) => {
        this.props.dispatch(
          addComputedAttributeDeviceWiseSelectedList(Attribute)
        );
        this.props.dispatch(addComputedAttribute(Attribute));
        //this.props.enqueueSnackbar(this.props.translate('newAttributeAdded'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
        this.props.dispatch(
          notifySuccess(this.props.translate("newAttributeAdded"))
        );
        this.computedAttributes_Selection(deviceId, Attribute.id);
        //   })
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }

  computedAttributes_Selection(deviceId, attributeId) {
    let obj = {
      deviceId,
      attributeId,
    };

    // fetch(`/api/permissions/`, {
    //   method: 'POST',
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   },
    //   body: JSON.stringify({
    //     ...obj
    //   })
    // })
    instance({
      url: `/api/permissions/`,
      method: "POST",
      data: {
        ...obj,
      },
    })
      .then((response) => {
        // if (response.status === 204) {
        //this.props.dispatch(addComputedAttributeDeviceWise(item));
        //this.props.enqueueSnackbar(this.props.translate('attributeAssignedSuccessfully'), { action:(<IconButton color="inherit" size="small"> <CloseIcon /> </IconButton>) });
        this.props.dispatch(
          notifySuccess(this.props.translate("attributeAssignedSuccessfully"))
        );
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  }

  render() {
    return (
      <div style={{ marginTop: 12 }}>
        <h4
          className="all-attributes-heading"
          style={{ margin: "5px 10px", fontSize: 16 }}
        >
          {this.props.translate("mileageAndEngineHours")}
        </h4>
        <div style={{ padding: "0 16px" }}>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={6}>
              <TextField
                id="mileageCounter"
                label={this.props.translate("mileageCounter")}
                type="text"
                value={
                  this.state.mileageCounter ? this.state.mileageCounter : "gps"
                }
                onChange={(e) => this.handleChange("mileageCounter", e)}
                variant="outlined"
                margin="dense"
                fullWidth
                select
              >
                <MenuItem value={"gps"}>{this.props.translate("gps")}</MenuItem>
                <MenuItem value={"deviceOdometer"}>
                  {this.props.translate("deviceOdometer")}
                </MenuItem>
                <MenuItem value={"alternative"}>
                  {this.props.translate("alternativeSensor")}
                </MenuItem>
              </TextField>
            </Grid>
            {this.state.mileageCounter !== "gps" &&
            this.state.mileageCounter !== "deviceOdometer" ? (
              <Grid item sm={6} xs={6}>
                <Autocomplete
                  translate={this.props.translate}
                  value={this.state.expression || ""}
                  suggestions={this.props.suggestions}
                  handleChange={this.handleChange2}
                  fieldName="expression"
                  label={this.props.translate("input")}
                  prefix="position"
                />
              </Grid>
            ) : null}
            {this.state.mileageCounter === "gps" ? (
              <Grid item sm={6} xs={6}>
                <TextField
                  id="totalDistance"
                  label={this.props.translate("odometer") + " (Km)"}
                  type="number"
                  value={this.state.totalDistance || ""}
                  onChange={(e) => this.handleChange("totalDistance", e)}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            ) : null}
            <Grid item sm={6} xs={6}>
              <TextField
                id="mileageAccuracy"
                label={this.props.translate("deviceMileageAccuracyPercentage")}
                type="number"
                value={this.state.mileageAccuracy || ""}
                onChange={(e) => this.handleChange("mileageAccuracy", e)}
                variant="outlined"
                margin="dense"
                fullWidth
              />
            </Grid>
            {this.state.mileageCounter !== "gps" &&
            (this.state.mileageCounter === "deviceOdometer" ||
              this.state.mileageCounter === "alternative") ? (
              <Grid item sm={6} xs={12}>
                <TextField
                  id="mileageUnit"
                  label={this.props.translate("mileageUnit")}
                  type="text"
                  value={
                    this.state.mileageUnit ? this.state.mileageUnit : "gps"
                  }
                  onChange={(e) => this.handleChange("mileageUnit", e)}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                  select
                >
                  <MenuItem value={"m"}>
                    {this.props.translate("meter")}
                  </MenuItem>
                  <MenuItem value={"mi"}>
                    {this.props.translate("miles")}{" "}
                  </MenuItem>
                  <MenuItem value={"nmi"}>
                    {this.props.translate("noticalMiles")}
                  </MenuItem>
                  <MenuItem value={"km"}>
                    {this.props.translate("kilometer")}
                  </MenuItem>
                </TextField>
              </Grid>
            ) : null}
            {this.state.mileageCounter !== "gps" &&
            (this.state.mileageCounter === "deviceOdometer" ||
              this.state.mileageCounter === "alternative") ? (
              <Grid item sm={6} xs={12}>
                <Checkbox
                  checked={this.state.mFilter && this.state.mFilter}
                  onChange={(e) => this.handleChange("mFilter", e)}
                  canAssign
                  canRemove
                  label={
                    this.state.mFilter === true
                      ? this.props.translate("enable") +
                        " " +
                        this.props.translate("mileageLimit")
                      : this.props.translate("disable") +
                        " " +
                        this.props.translate("mileageLimit")
                  }
                />
              </Grid>
            ) : null}
          </Grid>
          <Grid container spacing={2}>
            {this.state.mileageCounter !== "gps" &&
            this.state.mFilter &&
            (this.state.mileageCounter === "deviceOdometer" ||
              this.state.mileageCounter === "alternative") ? (
              <Grid item sm={6} xs={12}>
                <TextField
                  id="mileageMinLimit"
                  label={this.props.translate("mileageMinLimit")}
                  type="text"
                  value={
                    this.state.mileageMinLimit === null
                      ? ""
                      : this.state.mileageMinLimit
                  }
                  onChange={(e) => this.handleChange("mileageMinLimit", e)}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
                <Grid item sm={6} xs={12}></Grid>
              </Grid>
            ) : null}
            {this.state.mileageCounter !== "gps" &&
            this.state.mFilter &&
            (this.state.mileageCounter === "deviceOdometer" ||
              this.state.mileageCounter === "alternative") ? (
              <Grid item sm={6} xs={12}>
                <TextField
                  id="mileageMinLimit"
                  label={this.props.translate("mileageMaxLimit")}
                  type="text"
                  value={
                    this.state.mileageMaxLimit === null
                      ? ""
                      : this.state.mileageMaxLimit
                  }
                  onChange={(e) => this.handleChange("mileageMaxLimit", e)}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>
            ) : null}
          </Grid>

          {this.props.selecteditem &&
            this.props.selecteditem.model !== "FX Tracker" && (
              <div
                style={{ textAlign: "right", padding: "16px 16px 8px 0" }}
                className="clearfix"
              >
                <h4 className="v-tab-heading pull-left">
                  {this.props.translate("reportEngineHours")}
                </h4>
              </div>
            )}
          <Grid container spacing={2}>
            {this.props.selecteditem &&
              this.props.selecteditem.model !== "FX Tracker" && (
                <Grid item sm={6} xs={6}>
                  <TextField
                    id="hours"
                    label={this.props.translate("reportEngineHours") + " (Hrs)"}
                    type="number"
                    value={this.state.hours || ""}
                    onChange={(e) => this.handleChange("hours", e)}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
              )}
            <Grid item xs={12} style={{ textAlign: "right" }}>
              {this.props.isVisablBtn && (
                <Button
                  disabled={!this.state.currentId || this.state.isDisableBtn}
                  onClick={this.save}
                >
                  {this.props.translate("sharedSave")}
                </Button>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}

const mapState = (state) => ({
  positions: state.positions,
  devices: state.devices3,
  themecolors: state.themeColors,
  computedAttributesDeviceWise: state.computedAttributesDeviceWise,
});
const mapStateToProps = connect(mapState);
export default mapStateToProps(Accumulators);
