import React, { Component } from "react";
import "./index.scss";
import isEqual from "react-fast-compare";
import {
  DevicesList,
  ListRowAlter as MonitoringListRow,
} from "./../../Components/Devices";
import { JobsShortList } from "./../../Components/Jobs/shortList";
import AddIcon from "@mui/icons-material/Add";
import { UsersShortList2 } from "./../../Components/Users/shortList";
import GroupsShortList from "./../../Components/Groups/shortList";
import { searchvehicle } from "../../Actions/Vehicles";
import Loader from "./../Loader";
import { ReactComponent as TowingIcon } from "../../assets/monitoring/towing.svg";
import { ReactComponent as OnlineIcon } from "../../assets/dashboard/online.svg";
import { ReactComponent as Moving } from "../../assets/monitoring/icons/moving.svg";
// import { ReactComponent as GPSNotUpdatedIcon } from '../../assets/monitoring/gpsNotUpdated.png'
import { ReactComponent as ImmobiliserIcon } from "../../assets/monitoring/immobiliser.svg";
import { ReactComponent as Parking } from "../../assets/monitoring/icons/parking.svg";
import { ReactComponent as Idling } from "../../assets/monitoring/icons/idling.svg";
import { ReactComponent as Stop } from "../../assets/monitoring/stop.svg";
import { ReactComponent as Online } from "../../assets/monitoring/icons/online.svg";
import { ReactComponent as Offline } from "../../assets/monitoring/icons/offline.svg";
import { ReactComponent as GPSNotUpdated } from "../../assets/monitoring/gpsNotUpdated.svg";
import { ReactComponent as ReportsNotRegistered } from "../../assets/monitoring/notRegistered.svg";
import {
  toggleAllDevices,
  sortDevices,
  searchDevices,
  searchcalendar,
  sortcalendars,
  fetchMoreDevices,
  //applyGroupByFilter,
  resetFilters,
  sortDeviceTypes,
  applyDevicesFilter,
} from "./../../Actions/Devices";
import { sortDrivers, searchDriver } from "./../../Actions/Drivers";
import { sortJobs, searchJob } from "./../../Actions/Jobs";
import { sortUsers, searchUser } from "./../../Actions/Users";
import Button from "./../../Components/common/Button";
import TextField from "./../../Components/common/TextField";

import GeofenceSidebar from "./Components/Geofences";
import TemplateGeneratorSidebar from "./Components/TemplateGenerator";
import ReportsSidebar from "./Components/Reports";
import MessagesSidebar from "./Components/Messages";
import CommandsSidebar from "./Components/Commands";
import MaintenanceSidebar from "./Components/Maintenance";
import ParametersSidebar from "./Components/Parameters";
import TimelineSidebar from "./Components/TimelineSidebar";
import TracksSidebar from "./Components/Tracks";
import TimemachineSidebar from "./Components/Timemachine";
import EventsSidebar from "./Components/Events";
import NotificationSidebar from "./Components/Notification";
import UnitsSidebar from "./Components/Units";
import InfiniteScroll from "react-infinite-scroll-component";
import { checkPrivileges } from "../../Helpers";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPlusSquare,
  faObjectGroup,
  faFolderPlus,
  faPlay,
  faCrosshairs,
  faSatelliteDish,
  faLink,
  faBatteryFull,
  faCaretSquareDown,
  faTimes,
  faEllipsisV,
  faSortAlphaUp,
  faPause,
  faArrowCircleDown,
  faDotCircle,
} from "@fortawesome/free-solid-svg-icons";
import SearchField from "../../Components/common/SearchField";
import PositionMenu from "../../Components/Maps/PositionMenu";
// import CloseIcon from "../../assets/nav/close.svg";
import Scrollbar from "react-scrollbars-custom";
import Draggable from "react-draggable";
import ExportImportMenu from "../../Components/Maps/ExportImportMenu";
import RecourcesList from "./Components/RecourcesList";
import { IconButton, Tooltip } from "@mui/material";
import DriverSidebar from "./Components/Driver";
import TrailerSidebar from "./Components/Trailer";
import VehicleSidebar from "./Components/Vehicle";
// import Switch from "../../Components/common/Switch";
// import withStyles from "@material-ui/styles/withStyles";
// import CTab from "@mui/material/Tab";
// import Tabs from "@mui/material/Tabs";
import ServicesSidebar from "./Components/Services";

library.add([
  faPlusSquare,
  faObjectGroup,
  faFolderPlus,
  faPlay,
  faCrosshairs,
  faSatelliteDish,
  faLink,
  faBatteryFull,
  faCaretSquareDown,
  faTimes,
  faEllipsisV,
  faSortAlphaUp,
  faPause,
  faArrowCircleDown,
  faDotCircle,
]);

function Wrapper(props) {
  if (props.type === true) {
    return props.children;
  } else {
    return (
      <Draggable axis="x" handle=".sidebar-dragicon" bounds="parent">
        {props.children}
      </Draggable>
    );
  }
}
// const VerticalTabs = withStyles((theme) => ({
//   root: {
//     textAlign: "center",
//   },
//   indicator: {
//     display: "none",
//   },
// }))(Tabs);
const orientation = "horizontal";
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      view: true,
    };
    this.collapseSidebar = this.collapseSidebar.bind(this);
    this.collapseSidebarWithButton = this.collapseSidebarWithButton.bind(this);
  }

  collapseSidebar() {
    this.props.sidebarClose();
  }

  collapseSidebarWithButton() {
    this.props.collapseSidebarWithButton();
  }

  componentWillUnmount() {
    if (this.props.location.pathname === "/drivers") {
      this.props.dispatch(searchDriver({ driver: "" }));
    } else if (this.props.location.pathname === "/schedules") {
      this.props.dispatch(searchJob({ job: "" }));
    } else if (this.props.location.pathname === "/calendars") {
      this.props.dispatch(searchcalendar({ calendar: "" }));
    } else if (
      this.props.location.pathname === "/vehicles" ||
      this.props.location.pathname === "/vehicle"
    ) {
      this.props.dispatch(searchvehicle({ vehicle: "" }));
    } else if (
      this.props.location.pathname.indexOf("/users") !== -1 ||
      this.props.location.pathname.indexOf("/users2") !== -1
    ) {
      this.props.dispatch(searchUser({ user: "" }));
    }

    this.setState({ view: false });
  }

  toggleListView = () => {
    if (this.props.location.pathname.indexOf("/monitoring") !== -1) {
      this.setState({
        view: !this.state.view,
      });
    }
  };

  render() {
    const emptyLinks = null;
    let cls = "other-page";
    if (this.props.location.pathname.indexOf("/monitoring") !== -1) {
      cls = "monitoring-page";
    }

    if (this.props.noSidebar) {
      return null;
    } else {
      return (
        <Wrapper type={this.props.collapse}>
          <div
            className={
              "fms-sidebar-wrapper " +
              cls +
              " " +
              this.props.view +
              " " +
              (this.state.view ? "main-list-view" : "main-thumb-view")
            }
          >
            {/* <div className='sidebar-collapse-button'>
              <a
                className='hidden-responsive'
                href={emptyLinks}
                onClick={e => this.collapseSidebarWithButton()}
              >
                {this.props.collapse ? (
                  <ArrowForwardIosIcon style={{ fontSize: 14 }} />
                ) : (
                  <svg fill='currentColor' width={10} height={10}>
                    <use xlinkHref={`${CloseIcon}#icon`} />
                  </svg>
                )}
              </a>
              <a
                className='visible-responsive'
                href={emptyLinks}
                onClick={e => this.collapseSidebarWithButton()}
              >
                {this.props.collapse ? (
                  <svg fill='currentColor' width={10} height={10}>
                    <use xlinkHref={`${CloseIcon}#icon`} />
                  </svg>
                ) : (
                  <ArrowForwardIosIcon style={{ fontSize: 14 }} />
                )}
              </a>
              <a
                href={emptyLinks}
                className='sidebar-dragicon sidebar-thumbs-icon hidden-responsive'
              >
                <DragIndicatorIcon />
              </a>
            </div> */}

            <SidebarInner
              collapseSidebar={this.collapseSidebar}
              toggleListView={this.toggleListView}
              ListViewSwitch={this.state.view}
              {...this.props}
            />
          </div>
        </Wrapper>
      );
    }
  }
}
export class SidebarInner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      switchView: "units",
      isPopupVisable: false,
      is_gm_Visable: false,
      newPage: 0,
      isChecked: true,
      menuSettings: [],

      items: Array.from({ length: 20 }),
      hasMore: true,
    };

    this.isChecked = this.isChecked.bind(this);
    this.toggleAllDevices = this.toggleAllDevices.bind(this);
    this.searchItem = this.searchItem.bind(this);
    this.addDevices = this.addDevices.bind(this);
    this.switchPageView = this.switchPageView.bind(this);
    this.sortByName = this.sortByName.bind(this);
    this.addGroup = this.addGroup.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.setSearchEmpty = this.setSearchEmpty.bind(this);
  }

  onCloseModal() {
    this.setState({
      is_gm_Visable: false,
    });
  }

  addGroup() {
    this.setState({
      is_gm_Visable: true,
    });
  }

  switchView = (type) => {
    this.props.dispatch(searchDevices({ device: "" }));
    if (type === "groups") {
      this.props.dispatch(fetchMoreDevices(true, null, "abc"));
      this.props.fetchMoreItems2();
    } else {
      this.props.dispatch(fetchMoreDevices(true, null));
    }
    this.setState({
      switchView: type,
      isChecked: true,
      sort: "ASC",
      search: "",
    });
  };

  UNSAFE_componentWillMount() {
    if (this.props.viewScreen) {
      this.setState({ newPage: this.props.viewScreen === "groups" ? 1 : 0 });
    }
    this.setState({
      menuSettings: [
        {
          label: this.props.translate("moving"),
          value: "Moving",
          icon: (
            <span className="">
              <Moving width={20} height={20} />
            </span>
          ),
        },
        {
          label: this.props.translate("idling"),
          value: "Idling",
          icon: (
            <span className="n">
              <Idling width={20} height={20} />
            </span>
          ),
        },
        {
          label: this.props.translate("stop"),
          value: "stop",
          icon: (
            <span className="">
              <Stop fill="red" width={20} height={20} />
            </span>
          ),
        },
        {
          label: this.props.translate("towing"),
          value: "Towing",
          icon: (
            <span className="">
              <TowingIcon width={20} height={20} />
            </span>
          ),
        },
        {
          label: this.props.translate("parking"),
          value: "statusParking",
          icon: (
            <span className="">
              <Parking width={20} height={20} />
            </span>
          ),
        },
        {
          label: this.props.translate("tracker.online"),
          value: "statusOnline",
          icon: (
            <span className="">
              <Online width={20} height={20} />
            </span>
          ),
        },
        {
          label: this.props.translate("tracker.offline"),
          value: "statusOffline",
          icon: (
            <span className="">
              <Offline width={20} height={20} />
            </span>
          ),
        },
        {
          label: this.props.translate("tracker.gpsNotUpdated"),
          value: "gpsNotUpdated",
          icon: (
            <span className="">
              <GPSNotUpdated width={20} height={20} />
            </span>
          ),
        },
        {
          label: this.props.translate("tracker.notRegisteredYet"),
          value: "notRegistered",
          icon: (
            <span className="">
              {/* <NotRegisteredIcon /> */}
              <ReportsNotRegistered width={20} height={20} />
            </span>
          ),
        },
      ],
    });
  }

  switchPageView(e, newValue) {
    if (newValue === 1) {
      this.props.fetchMoreItems2(1, "");
    }
    this.setState(
      {
        newPage: newValue,
        search: "",
      },
      () => {
        this.props.dispatch(searchDevices({ device: "" }));
      }
    );
  }

  isChecked(value) {
    this.setState({
      isChecked: value,
    });
  }

  toggleAllDevices(event) {
    this.props.dispatch(toggleAllDevices({ checked: event.target.checked }));
    this.setState({
      isChecked: event.target.checked,
    });
  }

  sortByDeviceType = () => {
    this.props.dispatch(sortDeviceTypes({ sort: this.state.sort || "DESC" }));
    this.setState({
      sort: this.state.sort === "ASC" ? "DESC" : "ASC",
    });
  };

  sortByName() {
    if (this.props.location.pathname === "/drivers") {
      this.props.dispatch(sortDrivers({ sort: this.state.sort || "DESC" }));
      this.setState({
        sort: this.state.sort === "ASC" ? "DESC" : "ASC",
      });
    } else if (this.props.location.pathname === "/schedules") {
      this.props.dispatch(sortJobs({ sort: this.state.sort || "DESC" }));
      this.setState({
        sort: this.state.sort === "ASC" ? "DESC" : "ASC",
      });
    } else if (this.props.location.pathname === "/calendars") {
      this.props.dispatch(sortcalendars({ sort: this.state.sort || "DESC" }));
      this.setState({
        sort: this.state.sort === "ASC" ? "DESC" : "ASC",
      });
    } else if (
      this.props.location.pathname === "/users" ||
      this.props.location.pathname === "/addUser"
    ) {
      this.props.dispatch(sortUsers({ sort: this.state.sort || "DESC" }));
      this.setState({
        sort: this.state.sort === "ASC" ? "DESC" : "ASC",
      });
    } else {
      this.props.dispatch(sortDevices({ sort: this.state.sort || "DESC" }));
      this.resetFilters();
      this.setState({
        sort: this.state.sort === "ASC" ? "DESC" : "ASC",
      });
    }
  }

  setSearchEmpty() {
    this.props.dispatch(searchDevices({ device: "" }));
    this.setState({
      search: "",
    });
  }

  searchItem(event) {
    if (this.props.resourceList) {
      this.props.searchNestedResources(
        this.props.loadingItem,
        event.target.value
      );
      this.setState({
        search: event.target.value,
      });
    } else if (this.props.location.pathname.indexOf("/drivers") !== -1) {
      this.props.searchItems(event.target.value);
    } else if (this.props.location.pathname.indexOf("/trailers") !== -1) {
      this.props.searchItems(event.target.value);
    } else if (this.props.location.pathname.indexOf("/schedules") !== -1) {
      this.props.searchItems(event.target.value);
    } else if (this.props.location.pathname.indexOf("/units") !== -1) {
      this.props.dispatch(searchDevices({ device: event.target.value }));
      this.setState({
        search: event.target.value,
      });
    } else if (
      this.props.location.pathname.indexOf("/calendars") !== -1 ||
      this.props.location.pathname.indexOf("/users") !== -1 ||
      this.props.location.pathname.indexOf("/users2") !== -1 ||
      this.props.location.pathname.indexOf("/addUser") !== -1 ||
      this.props.location.pathname.indexOf("/vehicles") !== -1 ||
      this.props.location.pathname.indexOf("/vehicle") !== -1
    ) {
      this.props.searchItems(event.target.value);
      this.setState({
        search: event.target.value,
      });
      //this.props.dispatch(searchUser({ user: event.target.value }));
    } else if (this.props.location.pathname.indexOf("/monitoring") !== -1) {
      if (this.state.switchView === "groups") {
        this.props.searchItems2(event.target.value, this.state.switchView);
      } else {
        this.props.dispatch(searchDevices({ device: event.target.value }));
      }
      this.setState({
        search: event.target.value,
      });
    } else {
      this.props.dispatch(searchDevices({ device: event.target.value }));
      this.setState({
        search: event.target.value,
      });
    }
  }
  addDevices() {
    this.setState({
      isPopupVisable: !this.state.isPopupVisable,
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state); // equals() is your implementation
  }

  selecteItem = (data) => {
    this.props.collapseSidebar();
    this.props.selecteItem(data);
  };

  editItem = (data) => {
    this.props.collapseSidebar();
    this.props.editItem(data);
  };

  onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
  };

  resetFilters = () => {
    this.props.dispatch(resetFilters());
    this.setState({
      search: "",
    });
  };
  openSelectedVehicles = (item) => {
    let newList = this.props.filterList.includes(item) ? [] : [item];
    this.props.dispatch(applyDevicesFilter(newList));
  };

  render() {
    const { allNotifications } = this.props;
    const emptyLinks = null;
    if (this.props.location.pathname === "/geofences") {
      return (
        <GeofenceSidebar
          {...this.props}
          collapseSidebar={this.props.collapseSidebar}
        />
      );
    }
    if (this.props.location.pathname === "/templateGenerator") {
      return (
        <TemplateGeneratorSidebar
          {...this.props}
          collapseSidebar={this.props.collapseSidebar}
        />
      );
    } else if (this.props.location.pathname === "/monitoring") {
      const endMessage =
        this.props.devices && this.props.devices.total > 0 ? (
          <p style={{ textAlign: "center", color: "#ccc" }}>
            -- {this.props.translate("end")} --{" "}
          </p>
        ) : (
          <span
            style={{ display: "flex", justifyContent: "center", color: "#ccc" }}
          >
            {this.props.translate("notFound")}
          </span>
        );
      return (
        <aside className="fms-sidebar scrollable-sidebar">
          {checkPrivileges("device") && (
            <div className="fms-sidebar-monitoring monitoring-only">
              <div className="sidebar-bg-layer">
                <div className="section-head-filter">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: 10,
                    }}
                  >
                    <Tooltip title={this.props.translate("online")}>
                      <div
                        style={{
                          background: this.props.filterList.includes(
                            "statusOnline"
                          )
                            ? "#28a05b"
                            : "#35b3691a",
                          cursor: "pointer",
                          marginRight: 2,
                          borderRadius: 6,
                          width: 55,
                          height: 52,
                          border: "1px solid #3ce885",
                        }}
                        onClick={() => {
                          this.openSelectedVehicles("statusOnline");
                        }}
                      >
                        <div
                          style={{
                            paddingTop: 5,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <OnlineIcon
                            width={20}
                            height={20}
                            fill={
                              this.props.filterList.includes("statusOnline")
                                ? "#FFFFFF"
                                : "#3ce885"
                            }
                          />{" "}
                        </div>
                        <div
                          style={{
                            paddingTop: 5,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <span
                            style={{
                              fontSize: 13,
                              fontWeight: 500,
                              color: this.props.filterList.includes(
                                "statusOnline"
                              )
                                ? "#fff"
                                : "#000000",
                            }}
                          >
                            {" "}
                            {allNotifications &&
                              allNotifications.statusOnline &&
                              allNotifications.statusOnline.length}
                          </span>
                        </div>
                      </div>
                    </Tooltip>
                    <Tooltip title={this.props.translate("moving")}>
                      <div
                        style={{
                          background: this.props.filterList.includes("Moving")
                            ? "#35b369eb"
                            : "#35b3691a",
                          cursor: "pointer",
                          marginRight: 2,
                          borderRadius: 6,
                          width: 55,
                          height: 52,
                          border: "1px solid #35B369",
                        }}
                        onClick={() => {
                          this.openSelectedVehicles("Moving");
                        }}
                      >
                        <div
                          style={{
                            paddingTop: 5,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <Moving
                            width={20}
                            height={20}
                            fill={
                              this.props.filterList.includes("Moving")
                                ? "#fff"
                                : "#35b369eb"
                            }
                          />{" "}
                        </div>
                        <div
                          style={{
                            paddingTop: 5,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <span
                            style={{
                              fontSize: 13,
                              fontWeight: 500,
                              color: this.props.filterList.includes("Moving")
                                ? "#fff"
                                : "#000000",
                            }}
                          >
                            {" "}
                            {allNotifications &&
                              allNotifications.Moving &&
                              allNotifications.Moving.length}
                          </span>
                        </div>
                      </div>
                    </Tooltip>

                    <Tooltip title={this.props.translate("parking")}>
                      <div
                        style={{
                          background: this.props.filterList.includes(
                            "statusParking"
                          )
                            ? "#409cf7"
                            : "#2e80ee1a",
                          cursor: "pointer",
                          marginRight: 2,
                          borderRadius: 6,
                          width: 55,
                          height: 52,
                          border: "1px solid #2E80EE",
                        }}
                        onClick={() => {
                          this.openSelectedVehicles("statusParking");
                        }}
                      >
                        <div
                          style={{
                            paddingTop: 5,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <Parking
                            width={20}
                            height={20}
                            fill={
                              this.props.filterList.includes("statusParking")
                                ? "#fff"
                                : "#3083EA"
                            }
                          />{" "}
                        </div>
                        <div
                          style={{
                            paddingTop: 5,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <span
                            style={{
                              fontSize: 13,
                              fontWeight: 500,
                              color: this.props.filterList.includes(
                                "statusParking"
                              )
                                ? "#fff"
                                : "#000000",
                            }}
                          >
                            {" "}
                            {allNotifications &&
                              allNotifications.statusParking &&
                              allNotifications.statusParking.length}
                          </span>
                        </div>
                      </div>
                    </Tooltip>

                    <Tooltip title={this.props.translate("idling")}>
                      <div
                        style={{
                          background: this.props.filterList.includes("Idling")
                            ? "#ffbb54eb"
                            : "#ed3a3a1a",
                          cursor: "pointer",
                          marginRight: 2,
                          borderRadius: 6,
                          width: 55,
                          height: 52,
                          border: "1px solid #ED3A3A",
                        }}
                        onClick={() => {
                          this.openSelectedVehicles("Idling");
                        }}
                      >
                        <div
                          style={{
                            paddingTop: 5,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <Idling
                            width={20}
                            height={20}
                            fill={
                              this.props.filterList.includes("Idling")
                                ? "#fff"
                                : "#ffbb54eb"
                            }
                          />{" "}
                        </div>
                        <div
                          style={{
                            paddingTop: 5,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <span
                            style={{
                              fontSize: 13,
                              fontWeight: 500,
                              color: this.props.filterList.includes("Idling")
                                ? "#fff"
                                : "#000000",
                            }}
                          >
                            {" "}
                            {allNotifications &&
                              allNotifications.Idling &&
                              allNotifications.Idling.length}
                          </span>
                        </div>
                      </div>
                    </Tooltip>
                    <Tooltip title={this.props.translate("immobilizer")}>
                      <div
                        style={{
                          background: "#ff700a1a",
                          background: this.props.filterList.includes(
                            "immobilizer"
                          )
                            ? "#dc3545eb"
                            : "#ff700a1a",
                          cursor: "pointer",
                          marginRight: 2,
                          borderRadius: 6,
                          width: 55,
                          height: 52,
                          border: "1px solid #FF700A",
                        }}
                        onClick={() => {
                          this.openSelectedVehicles("immobilizer");
                        }}
                      >
                        <div
                          style={{
                            paddingTop: 5,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <ImmobiliserIcon
                            width={20}
                            height={20}
                            fill={
                              this.props.filterList.includes("immobilizer")
                                ? "#fff"
                                : "#dc3545eb"
                            }
                          />{" "}
                        </div>
                        <div
                          style={{
                            paddingTop: 5,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <span
                            style={{
                              fontSize: 13,
                              fontWeight: 500,
                              color: this.props.filterList.includes(
                                "immobilizer"
                              )
                                ? "#fff"
                                : "#000000",
                            }}
                          >
                            {" "}
                            {allNotifications &&
                              allNotifications.immobilizer &&
                              allNotifications.immobilizer.length}
                          </span>
                        </div>
                      </div>
                    </Tooltip>
                  </div>

                  <ul className="filter-row" style={{ marginTop: 5 }}>
                    <li>
                      <SearchField
                        label={
                          this.state.switchView === "groups"
                            ? this.props.translate("searchGroup")
                            : this.props.translate("searchUnits")
                        }
                        type="search"
                        placeholder="exp: ID, Name"
                        value={this.state.search}
                        onChange={this.searchItem}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    </li>

                    <li
                      style={{
                        flex: 0.01,
                        lineHeight: "22px",
                        paddingLeft: 10,
                        marginTop: 3,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid",
                          borderColor:
                            this.props.themecolors.menuActiveBackground,
                          borderRadius: 8,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.props.openDeviceSettings({}, "create");
                        }}
                      >
                        <span
                          style={{
                            background:
                              this.props.themecolors.menuActiveBackground,
                            borderRadius: "4px 0px 0px 4px",
                            padding: "3px 8px",
                            display: "flex",
                            flexDirection: "column",
                            color: "#ffffff",
                          }}
                        >
                          +
                        </span>
                        <span
                          style={{
                            color: "#141313",
                            fontSize: 12,
                            padding: "0 12px",
                          }}
                        >
                          {this.props.translate("sharedCreate")}
                        </span>
                      </div>
                    </li>
                    <li
                      style={{
                        flex: 0.01,
                        margin: "0px 3px 0px 3px ",
                        paddingLeft: 0,
                        paddingTop: 3,
                      }}
                    >
                      <PositionMenu
                        themecolors={this.props.themecolors}
                        menuSettings={this.state.menuSettings}
                      />
                    </li>

                    <li
                      style={{
                        flex: 0.01,
                        paddingLeft: 0,
                        margin: "0px 3px 0px 3px ",
                        paddingTop: 3,
                      }}
                    >
                      <ExportImportMenu
                        fileName="devices"
                        checkPrivilege="deviceCreate"
                        data={this.props.devices.data}
                        themecolors={this.props.themecolors}
                      />
                    </li>
                  </ul>
                </div>
                <div
                  className="sidebar-devices-list"
                  style={{ marginBottom: 50 }}
                >
                  <Scrollbar
                    disableTracksWidthCompensation={true}
                    scrollerProps={{
                      renderer: (props) => {
                        const { elementRef, ...restProps } = props;
                        return (
                          <div
                            {...restProps}
                            ref={elementRef}
                            id="scrollableDivDevices"
                          />
                        );
                      },
                    }}
                  >
                    {checkPrivileges("device") && this.props.resourceList ? (
                      <>
                        <RecourcesList
                          disableEdit
                          ItemChild={MonitoringListRow}
                          ItemChildProps={{
                            collapseSidebar: this.props.collapseSidebar,
                            deviceId: this.props.deviceId,
                            monitoringMap: this.props.monitoringMap,
                            openDeviceSettings: this.props.openDeviceSettings,
                            showBox: this.props.showBox,
                            themecolors: this.props.themecolors,
                            trackId: this.props.trackId,
                            dispatch: this.props.dispatch,
                            translate: this.props.translate,
                          }}
                          exact
                          fromArray={
                            (this.props.devices && this.props.devices.data) ||
                            []
                          }
                          {...{
                            fetchNestedItems: this.props.fetchNestedItems,
                            resources: this.props.resources,
                            nestedResources: this.props.nestedResources,
                            onLinkResource: this.props.onLinkResource,
                            deleteResource: this.props.deleteResource,
                            itemType: this.props.itemType,
                            nextResources: this.props.nextResources,
                            dispatch: this.props.dispatch,
                            translate: this.props.translate,
                          }}
                        />
                      </>
                    ) : checkPrivileges("device") &&
                      !this.props.resourceList ? (
                      this.props.devices.isFetching ? (
                        <Loader defaultStyle />
                      ) : (
                        <InfiniteScroll
                          dataLength={this.props.devices.data.length}
                          next={() => this.props.dispatch(fetchMoreDevices())}
                          hasMore={this.props.devices.hasNext}
                          loader={<Loader defaultStyle={true} />}
                          scrollableTarget="scrollableDivDevices"
                          endMessage={endMessage}
                        >
                          <DevicesList
                            sensorData={this.props.sensorData}
                            {...this.props}
                            {...this.state}
                            item1={this.props.item1}
                            openDeviceSettings={this.props.openDeviceSettings}
                            isCheckedFn={this.isChecked}
                            collapseSidebar={this.props.collapseSidebar}
                            trackId={this.props.trackId}
                            deviceId={this.props.deviceId}
                            showBox={this.props.showBox}
                            monitoringMap={this.props.monitoringMap}
                            devices={this.props.devices.data}
                            translate={this.props.translate}
                            dispatch={this.props.dispatch}
                            themecolors={this.props.themecolors}
                            viewOnMap={this.props.viewOnMap}
                          />
                        </InfiniteScroll>
                      )
                    ) : null}
                  </Scrollbar>
                </div>
              </div>
            </div>
          )}
        </aside>
      );
    } else if (this.props.location.pathname === "/reports") {
      return <ReportsSidebar {...this.props} />;
    } else if (this.props.location.pathname === "/Dlogs") {
      return <MessagesSidebar {...this.props} />;
    } else if (this.props.location.pathname === "/commands") {
      return <CommandsSidebar {...this.props} />;
    } else if (this.props.location.pathname === "/services") {
      return <ServicesSidebar {...this.props} />;
    } else if (
      this.props.location.pathname === "/maintenance" ||
      this.props.location.pathname === "/maintenanceCreate" ||
      this.props.location.pathname.indexOf("/maintenanceUpdate") !== -1
    ) {
      return <MaintenanceSidebar {...this.props} />;
    } else if (
      this.props.location.pathname === "/notifications" ||
      this.props.location.pathname === "/notificationCreate" ||
      this.props.location.pathname.indexOf("/notificationUpdate") !== -1
    ) {
      return <NotificationSidebar {...this.props} />;
    } else if (this.props.location.pathname === "/parameters") {
      return <ParametersSidebar {...this.props} />;
    } else if (this.props.location.pathname === "/timeline") {
      return <TimelineSidebar {...this.props} />;
    } else if (this.props.location.pathname.indexOf("/tracks") !== -1) {
      return this.state.trackLoading ? (
        <Loader />
      ) : (
        <TracksSidebar {...this.props} />
      );
    } else if (this.props.location.pathname === "/timemachine") {
      return this.state.trackLoading ? (
        <Loader />
      ) : (
        <TimemachineSidebar {...this.props} />
      );
    } else if (
      this.props.location.pathname.indexOf("/users") !== -1 ||
      this.props.location.pathname.indexOf("/addUser") !== -1
    ) {
      return (
        <aside className="fms-sidebar sidebar-bg-layer">
          <div className="section-head-filter">
            <ul className="filter-row">
              <li>
                <SearchField
                  label={this.props.translate("searchUsers")}
                  type="search"
                  placeholder="exp: ID, Name"
                  value={this.state.search}
                  onChange={this.searchItem}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </li>
              {this.props.resourceList ? (
                <li style={{ paddingLeft: 10, flex: 0.01 }}>
                  {this.props.logInUser.administrator &&
                    checkPrivileges("itemgroupCreate") && (
                      <Button
                        fullWidth
                        disabled={!this.props.userLimit}
                        onClick={() => this.props.addResource()}
                      >
                        {this.props.translate("sharedCreate")}
                      </Button>
                    )}
                </li>
              ) : (
                <li
                  style={{
                    paddingLeft: 10,
                    flex: 0.01,
                    lineHeight: 1.5,
                    marginTop: 2,
                  }}
                >
                  {this.props.logInUser.administrator &&
                    checkPrivileges("userCreate") && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid",
                          borderColor:
                            this.props.themecolors.menuActiveBackground,
                          borderRadius: 8,
                          cursor: "pointer",
                        }}
                        onClick={() => this.props.addUser()}
                      >
                        <span
                          style={{
                            background:
                              this.props.themecolors.menuActiveBackground,
                            borderRadius: "4px 0px 0px 4px",
                            padding: "3px 8px",
                            display: "flex",
                            flexDirection: "column",
                            color: "#ffffff",
                          }}
                        >
                          +
                        </span>
                        <span
                          style={{
                            color: "#141313",
                            fontSize: 12,
                            padding: "0 12px",
                          }}
                        >
                          {this.props.translate("sharedCreate")}
                        </span>
                      </div>
                    )}
                </li>
              )}
              {/* <li style={{ paddingRight: 0 }}>
                {this.props.logInUser.administrator &&
                  checkPrivileges('userCreate') && (
                    <Button
                      size='small'
                      disabled={!this.props.userLimit}
                      onClick={() => this.props.showResources()}
                    >
                      {this.props.translate('Resources')}
                    </Button>
                  )}
              </li> */}
              <li style={{ flex: 0.01 }}>
                <ExportImportMenu
                  fileName="users"
                  checkPrivilege="userCreate"
                  data={this.props.users}
                  themecolors={this.props.themecolors}
                />
              </li>
            </ul>
          </div>
          {this.props.resourceList ? (
            <RecourcesList {...this.props} />
          ) : (
            <div className="sidebar-devices-list">
              <Scrollbar
                disableTracksWidthCompensation
                scrollerProps={{
                  renderer: (props) => {
                    const { elementRef, ...restProps } = props;
                    return (
                      <div {...restProps} ref={elementRef} id="scrollableDiv" />
                    );
                  },
                }}
              >
                <UsersShortList2
                  {...this.state}
                  match={this.props.match}
                  editItem={this.editItem}
                  removedItem={this.props.removedItem}
                  //selecteItem={this.selecteItem}
                  collapseSidebar={this.props.collapseSidebar}
                  logInUser={this.props.logInUser}
                  //selectedItemId={this.props.selectedItemId}
                  users={this.props.users}
                  translate={this.props.translate}
                />
              </Scrollbar>
            </div>
          )}
        </aside>
      );
    } else if (this.props.location.pathname.indexOf("/resources") !== -1) {
      return (
        <aside className="fms-sidebar sidebar-bg-layer">
          <div className="fms-sidebar-monitoring">
            <div className="section-head-filter">
              <ul className="filter-row">
                <li>
                  <TextField
                    label={this.props.translate("sharedSearch")}
                    type="search"
                    placeholder="exp: ID, Name"
                    value={this.state.search}
                    onChange={this.searchItem}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </li>
                {checkPrivileges("groupCreate") && (
                  <li
                    style={{
                      paddingLeft: 10,
                      flex: 0.01,
                    }}
                  >
                    <IconButton
                      fullWidth
                      onClick={() => this.props.goAddGroup()}
                    >
                      <AddIcon />
                    </IconButton>
                  </li>
                )}
              </ul>
            </div>
            <div className="sidebar-devices-list" id="scrollableDiv2">
              {this.props.itemPagination2 ? (
                <Scrollbar
                  disableTracksWidthCompensation
                  scrollerProps={{
                    renderer: (props) => {
                      const { elementRef, ...restProps } = props;
                      return (
                        <div
                          {...restProps}
                          ref={elementRef}
                          id="scrollableDiv2"
                        />
                      );
                    },
                  }}
                >
                  <GroupsShortList
                    {...this.state}
                    {...this.props}
                    editItem={this.editItem}
                    removedItem={this.props.removedItem}
                    collapseSidebar={this.props.collapseSidebar}
                    translate={this.props.translate}
                  />
                </Scrollbar>
              ) : null}
            </div>
          </div>
        </aside>
      );
    } else if (this.props.location.pathname.indexOf("/units") !== -1) {
      return (
        <aside className="fms-sidebar">
          <div className="fms-sidebar-monitoring">
            <div className="sidebar-bg-layer">
              {checkPrivileges("device") ? (
                <UnitsSidebar
                  {...this.props}
                  devices={this.props.devices}
                  search={this.state.search}
                  searchItem={this.searchItem}
                  setSearchEmpty={this.setSearchEmpty}
                  sortByName={this.sortByName}
                  isChecked={this.isChecked}
                  viewOnMap={this.props.viewOnMap}
                />
              ) : null}

              {/* checkPrivileges('group') && this.state.newPage === 1 && (
                <GroupsSidebar
                  {...this.props}
                  searchItem={this.searchItem}
                  sortByName={this.sortByName}
                  isChecked={this.isChecked}
                />
              ) */}
            </div>
          </div>
        </aside>
      );
    } else if (this.props.location.pathname.indexOf("/drivers") !== -1) {
      return (
        <DriverSidebar
          {...this.props}
          searchItem={this.searchItem}
          isChecked={this.isChecked}
          editItem={this.editItem}
          selecteItem={this.selecteItem}
        />
      );
    } //start trailer
    else if (this.props.location.pathname.indexOf("/trailers") !== -1) {
      return (
        <TrailerSidebar
          {...this.props}
          searchItem={this.searchItem}
          isChecked={this.isChecked}
          editItem={this.editItem}
          selecteItem={this.selecteItem}
        />
      );
    } //end triler
    else if (this.props.location.pathname.indexOf("/schedules") !== -1) {
      const endMessage =
        this.props.itemPagination && this.props.itemPagination.total > 0 ? (
          <div style={{ textAlign: "center", color: "#ccc" }}>
            -- {this.props.translate("end")} --
          </div>
        ) : (
          <span style={{ textAlign: "center", color: "#ccc" }}>
            {this.props.translate("notFound")}
          </span>
        );
      return (
        <aside className="fms-sidebar sidebar-bg-layer">
          {checkPrivileges("job") && (
            <div className="section-head-filter">
              <ul className="filter-row">
                <li>
                  <SearchField
                    label={this.props.translate("searchSchedule")}
                    type="search"
                    placeholder="exp: ID, Name"
                    value={this.state.search}
                    onChange={this.searchItem}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </li>
                <li style={{ paddingLeft: 10, flex: 0.01 }}>
                  {checkPrivileges("jobCreate") && (
                    <IconButton onClick={() => this.props.addJob()}>
                      <AddIcon />
                    </IconButton>
                  )}
                </li>
              </ul>
            </div>
          )}
          <div className="sidebar-devices-list">
            <Scrollbar
              disableTracksWidthCompensation
              scrollerProps={{
                renderer: (props) => {
                  const { elementRef, ...restProps } = props;
                  return (
                    <div {...restProps} ref={elementRef} id="scrollableJob" />
                  );
                },
              }}
            >
              {checkPrivileges("job") && this.props.itemPagination ? (
                <InfiniteScroll
                  dataLength={this.props.itemPagination.items.length}
                  next={this.props.fetchMoreItems}
                  hasMore={this.props.itemPagination.hasNext}
                  loader={<Loader defaultStyle={true} />}
                  scrollableTarget="scrollableJob"
                  endMessage={endMessage}
                >
                  <JobsShortList
                    match={this.props.match}
                    editItem={this.editItem}
                    removedItem={this.props.removedItem}
                    selecteItem={this.selecteItem}
                    translate={this.props.translate}
                  />
                </InfiniteScroll>
              ) : null}
            </Scrollbar>
          </div>
        </aside>
      );
    } else if (this.props.location.pathname.indexOf("/events") !== -1) {
      return (
        <EventsSidebar
          userEvents={this.props.userEvents}
          showNotification={this.props.showNotification}
          showFilterForm={this.props.showFilterForm}
          toggleFilterForm={this.props.toggleFilterForm}
          addRules={this.props.addRules}
          selectedItem={this.props.selectedItem}
          searchItem={this.searchItem}
          sortByName={this.sortByName}
          selecteItem={this.selecteItem}
          onCloseModal={this.onCloseModal}
          collapseSidebar={this.props.collapseSidebar}
          {...this.state}
          match={this.props.match}
          history={this.props.history}
        />
      );
    } else if (
      this.props.location.pathname.indexOf("/vehicles") !== -1 ||
      this.props.location.pathname.indexOf("/vehicle") !== -1
    ) {
      return (
        <VehicleSidebar
          {...this.props}
          allVehicleLocation={this.props.allVehicleLocation}
          search={this.state.search}
          searchItem={this.searchItem}
          isChecked={this.isChecked}
          editItem={this.editItem}
          selecteItem={this.selecteItem}
        />
      );
    }
  }
}
export default Sidebar;
