import React from "react";
import { ReactComponent as Moving } from "../../../assets/monitoring/icons/moving.svg";
import { ReactComponent as PinIcon } from "../../../assets/nav/pinLocation.svg";
import { Stepper, Step, StepLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { StepIcon } from "@mui/material";

export default function TimelineSidebar(props) {
  let data = [
    {
      name: "F-150",
      events: [
        { id: 1, name: "towing" },
        { id: 1, name: "Moving" },
        { id: 1, name: "stop" },
      ],
    },
    {
      name: "FE-189898950",
      events: [
        { id: 1, name: "Idling" },
        { id: 1, name: "Moving" },
        { id: 1, name: "Parking" },
      ],
    },
    {
      name: "8689778150",
      events: [
        { id: 1, name: "Parking" },
        { id: 1, name: "Moving" },
        { id: 1, name: "stop" },
      ],
    },
    ,
    {
      name: "F-16",
      events: [
        { id: 1, name: "Moving" },
        { id: 1, name: "Moving" },
        { id: 1, name: "Stop" },
      ],
    },
  ];
  return (
    <div className="sidebar-bg-layer">
      <h3
        className="filter-row"
        style={{ color: props.themecolors.textColor, margin: 10 }}
      >
        {props.translate("timeline")}{" "}
      </h3>
      <DrawDevices data={data} />
    </div>
  );
}
const useStyles = makeStyles({
  connectorVertical: {
    // Add your custom styles here
    // Example styles
    padding: "0 0 8px",
    marginLeft: "9.4px",
  },
});
export function DrawDevices(props) {
  const classes = useStyles();
  return (
    <div style={{ margin: 10 }}>
      {props.data &&
        props.data.map((item, index) => {
          return (
            <div
              style={{
                padding: 4,
                border: "1px solid #CFCFCF",
                borderRadius: 8,
                margin: "10px 2px",
                boxShadow:
                  "0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
              }}
            >
              <div style={{ display: "flex", margin: 8 }}>
                <Moving width={20} height={20} />
                <span style={{ marginLeft: 6, fontSize: 14, fontWeight: 600 }}>
                  {item.name}
                </span>
              </div>
              <div>
                <div class="MuiStepConnector-root MuiStepConnector-vertical Mui-disabled"></div>

                <Stepper
                  orientation="vertical"
                  connector={
                    <div className={classes.connectorVertical}>
                      <span
                        style={{
                          minHeight: "24px",
                          borderLeftStyle: "solid",
                          borderLeftWidth: " 1px",
                          borderColor: "#bdbdbd",
                        }}
                      ></span>
                    </div>
                  }
                >
                  {item.events.map((it, index) => {
                    return (
                      <Step key={index}>
                        <StepLabel icon={<PinIcon width={20} height={20} />}>
                          {it.name}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </div>
            </div>
          );
        })}
    </div>
  );
}
const CustomStepIcon = ({ active, completed, icon }) => {
  const classes = useStyles();
  return (
    <div className={classes.stepIcon} style={{}}>
      <StepIcon
        icon={icon === 1 ? <PinIcon width={20} height={20} /> : icon}
        active={active}
        completed={completed}
      />
    </div>
  );
};
