import React from "react";
import { makeStyles } from "@mui/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "./Button";
import {
  Adjust,
  CheckCircle,
  FiberManualRecord,
  Info,
  Email,
  GetApp,
} from "@mui/icons-material";
import { StepConnector } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  button: {
    // marginTop: theme.spacing(1),
    // marginRight: theme.spacing(1),
  },
  actionsContainer: {
    // marginBottom: theme.spacing(2),
    textAlign: "right",
  },
  resetContainer: {
    // padding: theme.spacing(3),
  },
}));

function CircleStepIcon(props) {
  const { active, completed, error, ...rest } = props;

  return (
    <div {...rest}>
      {completed ? (
        <CheckCircle />
      ) : active ? (
        <Adjust />
      ) : error ? (
        <Info />
      ) : (
        <FiberManualRecord />
      )}
    </div>
  );
}

export default function VerticalLinearStepper(props) {
  const classes = useStyles();
  const steps = props.steps || [];
  return (
    <div className="stepper-wrapper">
      <Stepper
        activeStep={props.activeStep}
        orientation="vertical"
        elevation={0}
        classes={{ root: "stepper" }}
        connector={
          <StepConnector
            classes={{
              root: "step-connector",
              active: "step-connector-active",
            }}
          />
        }
      >
        {steps.map((step, index) => (
          <Step key={step.id}>
            <StepLabel
              StepIconComponent={CircleStepIcon}
              classes={{
                root: "step-label-root",
                label: "step-label",
                active: "step-label-active",
                completed: "step-label-completed",
                color: "#27272e !important",
              }}
            >
              {props.translate(step.title)}
            </StepLabel>
            <StepContent>
              {props.getStepContent(step.id)}
              {step.errorMsg ? step.errorMsg : null}
              <div className={classes.actionsContainer}>
                <div>
                  <div
                    style={
                      props.activeStep === steps.length - 1 &&
                      props.reportType &&
                      props.download &&
                      props.emails
                        ? { display: "flex", justifyContent: "center" }
                        : {}
                    }
                  >
                    <Button
                      disabled={props.activeStep === 0}
                      onClick={(e) => props.handleBack(step.id)}
                      className={classes.button}
                    >
                      {props.translate("sharedBack")}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => props.handleNext(step.id, "", index)}
                      className={classes.button}
                    >
                      {props.activeStep === steps.length - 1 && props.reportType
                        ? props.translate("submit")
                        : props.translate("next")}
                    </Button>
                  </div>
                  {props.activeStep === steps.length - 1 &&
                  props.reportType &&
                  props.download &&
                  props.emails ? (
                    <div>
                      <div
                        className="report-sidebar-switch"
                        style={{ marginTop: 15 }}
                      ></div>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) =>
                            props.handleNext(step.id, "downloadExcel", index)
                          }
                          className={classes.button}
                        >
                          <GetApp />
                          {props.translate("Excel")}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) =>
                            props.handleNext(step.id, "downloadPdf", index)
                          }
                          className={classes.button}
                        >
                          <GetApp />
                          {props.translate("PDF")}
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={(e) =>
                            props.handleNext(step.id, "email", index)
                          }
                          className={classes.button}
                        >
                          <Email style={{ marginRight: 5 }} />
                          {props.translate("commandSend")}
                        </Button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
