import React, { Component, useState } from "react";
import List from "@mui/material/List";
import InfiniteScroll from "react-infinite-scroll-component";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Loader from "../../Layout/Loader";
import moment from "moment";
import { connect } from "react-redux";
import Style from "style-it";
import Collapse from "@mui/material/Collapse";
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import {
  getDateFormat,
  getDateTimeFormat,
  getTimeFormat,
  setAttributeFormat,
} from "../../Helpers";
import PinIcon from "./../../assets/nav/pin.svg";
import UserIcon from "./../../assets/nav/user-two.svg";
import CoordinatesIcon from "./../../assets/nav/coordinates.svg";
import { ReactComponent as DropDownIcon } from "./../../assets/nav/dropDown.svg";
import parse from "html-react-parser";
import Tooltip from "@mui/material/Tooltip";
import { Types } from "../Notifications/Wizard/Screen2";

class UserEvents extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedEventId: "",
      selecteditemId: "",
      currentDevice: "",
      prevProps: "",
      open: true,
    };
    this.openNotification = this.openNotification.bind(this);
    this.selecteItem = this.selecteItem.bind(this);
  }
  selecteItem(item) {
    // let open = true
    // if (window.innerWidth <= 959) {
    //   open = false
    // }
    // this.setState(
    //   {
    //     open,
    //     selectedEventId: item.id
    //   },
    //   () => {
    //   }
    //   )
    this.props.showDetail(item);
  }

  openNotification() {
    this.setState({ open: !this.state.open });
  }

  render() {
    let title = `${this.props.translate("unread")} (${
      this.props.notificationCount
    })`;
    if (this.props.itemDetails && this.props.itemDetails.length) {
      title = this.props.translate("sharedLoading");
      if (this.props.itemDetails.length === 1) {
        if (this.props.devices && this.props.devices.length) {
          const device = this.props.devices.find(
            (d) => parseInt(d.id) === parseInt(this.props.itemDetails[0])
          );
          title = (device && device.name) || "";
        }
      } else {
        title = `${this.props.itemDetails.length} ${this.props.translate(
          "devicesEvents"
        )}`;
      }
    }

    let data = "";

    if (this.props.itemPagination.items.length) {
      data = this.props.itemPagination.items.map((event) => (
        <ListRow
          key={event.id}
          data={event}
          {...this.props}
          selectedEventId={this.state.selectedEventId}
          serverTimeZone={this.props.serverTimeZone}
          selecteItem={this.selecteItem}
        />
      ));
    }

    if (this.props.loading) {
      return <Loader defaultStyle />;
    } else {
      const endMessage =
        this.props.itemPagination && this.props.itemPagination.total > 0 ? (
          <p style={{ textAlign: "center", color: "#ccc" }}>
            {" "}
            -- {this.props.translate("end")} --{" "}
          </p>
        ) : (
          <p style={{ textAlign: "center", color: "#ccc" }}>
            {" "}
            {this.props.translate("notFound")}{" "}
          </p>
        );
      return (
        <Style>
          {`
           .infinite-scroll-component .event-list-row {
               border-bottom: 1px solid ${this.props.themecolors.textColor}22
            }
          .infinite-scroll-component .event-list-row.active,
          .infinite-scroll-component .event-list-row:hover {
                 background-color: ${this.props.themecolors.themeLightColor}88;
            } 
          `}
          <div style={{ overflow: "hidden" }}>
            <div
              id="scrollableDiv"
              className="notification-scroller maintain-height"
              style={{ marginTop: 0 }}
            >
              {/* <div className="notification-head-row"><span>{title}</span> { this.props.notificationCount > 0 ? <a onClick={this.props.markAsReadAll} style={{color: 'inherit', fontWeight: 'normal', fontSize: '0.85em', marginLeft: 'auto'}} href={null}>Mark all as read</a> : null }</div> */}

              <InfiniteScroll
                dataLength={this.props.itemPagination.items.length}
                next={() => {
                  this.props.fetchMoreItems();
                }}
                hasMore={this.props.itemPagination.hasNext}
                loader={<Loader defaultStyle={true} />}
                endMessage={endMessage}
                className="center-height"
                scrollableTarget="scrollableDiv"
              >
                <div className="individual-view">
                  <Collapse in={this.state.open}>
                    <List component="nav">{data}</List>
                  </Collapse>
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </Style>
      );
    }
  }
}

export function ListRow(props) {
  const [expanded, setExpanded] = useState(false);
  // console.log('props.data =====', props.data)
  // console.log('Types =====', Types)
  let value = "";
  Object.keys(Types).map((key) => {
    if (
      Types[key] &&
      Types[key].find((type) => type.value === props.data.type)
    ) {
      value = key;
    }
  });
  // console.log('value ========', value)
  const date = new Date(props.data.eventTime);
  const time = date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const extractedDate = `${year}-${month}-${day}`;

  return (
    <li
      style={{ cursor: "pointer", padding: 5 }}
      onClick={() => props.selecteItem(props.data)}
    >
      <div
        style={{
          background: "#FFFFFF",
          border: "1px solid #CFCFCF",
          borderRadius: 8,
          padding: 10,
          boxShadow:
            "0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: 0,
            padding: 0,
          }}
        >
          <div>
            <span style={{ fontSize: 14, fontWeight: 700, color: "#3A3A39" }}>
              {props?.data?.deviceName || ""}
            </span>
            <span
              style={{
                color: "#3A3A39",
                fontSize: 12,
                fontStyle: "italic",
                display: "block",
              }}
            >
              {time} | {extractedDate}
            </span>
          </div>
          <span
            style={{ alignSelf: "center" }}
            onClick={() => setExpanded(!expanded)}
          >
            <DropDownIcon
              fill="#000"
              width={10}
              height={14}
              className={expanded ? "inverted" : " "}
            />
          </span>
        </div>
        {expanded ? (
          <div style={{ padding: "0 10px" }}>
            <div
              style={{
                background: "#F0F4F7",
                borderRadius: 8,
                marginTop: 10,
                padding: "5px 10px",
              }}
            >
              <div style={{ display: "flex", flex: 1 }}>
                <span
                  style={{
                    fontSize: 12,
                    marginRight: 10,
                    color: "inherit",
                  }}
                >
                  <strong style={{ paddingRight: 10 }}>
                    {props?.data?.name || ""}{" "}
                    {props.data.attributes.input === "hours"
                      ? (
                          (props.data.attributes &&
                            props.data.attributes.value) /
                          (1000 * 3600)
                        ).toFixed(2) + " hours"
                      : props.data.attributes?.functionalType || ""}
                    <br />
                  </strong>
                </span>
              </div>
              {value === "digital" || props.data?.type === "workMode" ? (
                <div style={{ display: "flex", flex: 1 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: "inherit",
                    }}
                  >
                    <strong style={{ paddingRight: 10 }}>
                      {props.translate("status")}
                      {" : "}
                    </strong>
                    {props.data &&
                      props.data.attributes &&
                      props.data.attributes.status}
                    <br />
                  </span>
                  <div style={{ margin: "auto" }} />
                </div>
              ) : null}

              {value === "analog" ? (
                <div style={{ display: "flex", flex: 1 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: "inherit",
                    }}
                  >
                    <strong style={{ paddingRight: 10 }}>
                      {props.translate("range")}
                      {" : "}
                    </strong>
                    {props.data.attributes &&
                      props.data.attributes.minLimit +
                        " - " +
                        props.data.attributes &&
                      props.data.attributes.maxLimit}
                    <br />
                    <strong style={{ paddingRight: 10 }}>
                      {props.translate("sharedValue")}
                      {" : "}
                    </strong>
                    {props.data.attributes && props.data.attributes.value}
                    <br />
                    <strong style={{ paddingRight: 10 }}>
                      {props.translate("status")}
                      {" : "}
                    </strong>
                    {props.data.attributes.logic + "-Range"}
                    <br />
                    {props.data && props.data.geofenceName ? (
                      <>
                        <strong style={{ paddingRight: 10 }}>
                          {props.translate("geofence")}
                          {" : "}
                        </strong>
                        {props.data && props.data.geofenceName}
                        <br />
                      </>
                    ) : null}
                  </span>
                  <div style={{ margin: "auto" }} />
                </div>
              ) : null}

              {value === "ecodriving" ? (
                <div style={{ display: "flex", flex: 1 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: "inherit",
                    }}
                  >
                    <strong style={{ paddingRight: 10 }}>
                      {props.translate("range")}
                      {" : "}
                    </strong>
                    {props.data.attributes && props.data.attributes.minLimit}
                    {props.translate("g")} {" - "}{" "}
                    {props.data.attributes && props.data.attributes.maxLimit}
                    {props.translate("g")}
                    <br />
                    <strong style={{ paddingRight: 10 }}>
                      {props.translate("sharedValue")}
                      {" : "}
                    </strong>
                    {props.data.attributes && props.data.attributes.value}
                    {props.translate("g")}
                    <br />
                    <strong style={{ paddingRight: 10 }}>
                      {props.translate("tolerance")}
                      {" : "}
                    </strong>
                    {props.data.attributes && props.data.attributes.tolerance}
                    {props.translate("g")}
                    <br />
                  </span>
                  <div style={{ margin: "auto" }} />
                </div>
              ) : null}

              {value === "movement" ? (
                <div style={{ display: "flex", flex: 1 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: "inherit",
                    }}
                  >
                    {props.data.attributes.maxLimit && (
                      <>
                        <strong style={{ paddingRight: 10 }}>
                          {props.translate("range")}
                          {" : "}
                        </strong>
                        {setAttributeFormat(
                          "speed",
                          props.data.attributes.minLimit
                        )}{" "}
                        {" - "}{" "}
                        {setAttributeFormat(
                          "speed",
                          props.data.attributes.maxLimit
                        )}
                        <br />
                      </>
                    )}
                    {props.data.attributes.value && (
                      <>
                        <strong style={{ paddingRight: 10 }}>
                          {props.translate("sharedValue")}
                          {" : "}
                        </strong>
                        {setAttributeFormat(
                          "speed",
                          props.data.attributes.value
                        )}
                        <br />
                      </>
                    )}
                    {props.data.attributes.tolerance && (
                      <>
                        <strong style={{ paddingRight: 10 }}>
                          {props.translate("tolerance")}
                          {" : "}
                        </strong>
                        {setAttributeFormat(
                          "speed",
                          props.data.attributes.tolerance
                        )}
                        <br />
                      </>
                    )}
                  </span>
                  <div style={{ margin: "auto" }} />
                </div>
              ) : null}

              {value === "other" ? (
                <div style={{ display: "flex", flex: 1 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: "inherit",
                    }}
                  >
                    {props.data.attributes.newValue !== null && (
                      <>
                        <strong style={{ paddingRight: 10 }}>
                          {props.translate("newValue")}
                          {" : "}
                        </strong>
                        {props.data.attributes.newValue}
                        <br />
                      </>
                    )}
                    {props.data.attributes.oldValue !== null && (
                      <>
                        <strong style={{ paddingRight: 10 }}>
                          {props.translate("oldValue")}
                          {" : "}
                        </strong>
                        {props.data.attributes.oldValue}
                        <br />
                      </>
                    )}
                  </span>
                  <div style={{ margin: "auto" }} />
                </div>
              ) : null}

              {value === "fuel" ? (
                <div style={{ display: "flex", flex: 1 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: "inherit",
                    }}
                  >
                    {props.data?.attributes?.newValue !== null && (
                      <>
                        <strong style={{ paddingRight: 10 }}>
                          {props.translate("newValue")}
                          {" : "}
                        </strong>
                        {setAttributeFormat(
                          "fuel",
                          props.data?.attributes?.newValue
                        )}
                        <br />
                      </>
                    )}
                    {props.data?.attributes?.oldValue !== null && (
                      <>
                        <strong style={{ paddingRight: 10 }}>
                          {props.translate("oldValue")}
                          {" : "}
                        </strong>
                        {setAttributeFormat(
                          "fuel",
                          props.data?.attributes?.oldValue
                        )}
                        <br />
                      </>
                    )}
                    {/* {(props.data?.attributes?.tolerance !== null) && <><strong style={{ paddingRight: 10 }}>{props.translate('tolerance')}{' : '}</strong>
                        {props.data?.attributes?.tolerance}{props.translate('g')}<br /></>} */}
                  </span>
                  <div style={{ margin: "auto" }} />
                </div>
              ) : null}

              {value === "customAlert" ? (
                <div style={{ display: "flex", flex: 1 }}>
                  <span
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: "inherit",
                    }}
                  >
                    {props.data?.attributes?.ps_trafficFileNumber && (
                      <>
                        <strong style={{ paddingRight: 10 }}>
                          {props.translate("ps_trafficFileNumber")}
                          {" : "}
                        </strong>
                        {props.data?.attributes?.ps_trafficFileNumber}
                        <br />
                      </>
                    )}
                    {props.data?.attributes?.ps_plateNumber && (
                      <>
                        <strong style={{ paddingRight: 10 }}>
                          {props.translate("ps_plateNumber")}
                          {" : "}
                        </strong>
                        {props.data?.attributes?.ps_plateNumber}
                        <br />
                      </>
                    )}
                    {props.data?.attributes?.ps_brand && (
                      <>
                        <strong style={{ paddingRight: 10 }}>
                          {props.translate("ps_brand")}
                          {" : "}
                        </strong>
                        {props.data?.attributes?.ps_brand}
                        <br />
                      </>
                    )}
                    {props.data?.attributes?.ps_model && (
                      <>
                        <strong style={{ paddingRight: 10 }}>
                          {props.translate("ps_model")}
                          {" : "}
                        </strong>
                        {props.data?.attributes?.ps_model}
                        <br />
                      </>
                    )}
                    {props.data?.attributes?.ps_state && (
                      <>
                        <strong style={{ paddingRight: 10 }}>
                          {props.translate("ps_state")}
                          {" : "}
                        </strong>
                        {props.data?.attributes?.ps_state}
                        <br />
                      </>
                    )}
                    {props.data?.attributes?.ps_color && (
                      <>
                        <strong style={{ paddingRight: 10 }}>
                          {props.translate("ps_color")}
                          {" : "}
                        </strong>
                        {props.data?.attributes?.ps_color}
                        <br />
                      </>
                    )}
                  </span>
                  <div style={{ margin: "auto" }} />
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        <p style={{ margin: "5px 0" }}>
          <span style={{ color: "#ED3A3A", fontSize: 14 }}>
            {" "}
            {props.translate(props.data.type)}
          </span>
        </p>
        {props?.data?.coordinate && (
          <p
            style={{ color: "#3A3A39", fontSize: 12, padding: "0", margin: 0 }}
          >
            <svg fill="currentColor" width={14} height={14}>
              <use xlinkHref={`${CoordinatesIcon}#icon`} />
            </svg>
            <span style={{ margin: 0, marginLeft: 5 }}>
              {setAttributeFormat(
                "latitude",
                props.data.coordinate ? props.data.coordinate.split(",")[0] : ""
              )}
              {"  , "}
              {setAttributeFormat(
                "longitude",
                props.data.coordinate ? props.data.coordinate.split(",")[1] : ""
              )}
            </span>
          </p>
        )}
        {props?.data?.address && (
          <p
            style={{ color: "#3A3A39", fontSize: 12, padding: "0", margin: 0 }}
          >
            <svg fill="currentColor" width={14} height={14}>
              <use xlinkHref={`${PinIcon}#icon`} />
            </svg>
            <span style={{ margin: 0, marginLeft: 5 }}>
              {props.data.address}
            </span>
          </p>
        )}
      </div>
    </li>
  );
}

// class UserEventDetails2 extends Component {
//   constructor (props) {
//     super(props)
//     this.state = {
//       currentItemId: '',
//       isDetailsCompleted: false,
//       geofenceName: null
//     }
//   }

//   UNSAFE_componentWillReceiveProps(n) {
//     if(n.data && n.data.positionId !== this.state.currentItemId) {
//       this.setState({currentItemId: n.data.positionId})
//     }
//   }

//   /* drawUnit = data => {
//     if (data && data.type === 'deviceOverspeed') {
//       return data.attributes && data.attributes.speed
//         ? ' (' +
//             setAttributeFormat('speed', data.attributes.speed) +
//             ')'
//         : null
//     } else if (
//       data &&
//       ([
//         'geofenceEnter',
//         'deviceEnterGeofence',
//         'deviceExitGeofence',
//         'geofenceExit'
//       ].includes(data.type) ||
//         data.geofenceName)
//     ) {
//       if (!this.state.geofenceName && !data.geofenceName) {
//         return ''
//       } else {
//         return ` [  ${this.state.geofenceName} ${
//           data.geofenceName ? data.geofenceName : ''
//         } ]`
//       }
//     }
//     return null
//   } */

//   render () {

//     let value = '';
//     Object.keys(Types).map(key => {
//       if(Types[key]&&Types[key].find(type => type.value === this.props.data.type)){
//           value = key
//       }})

//     return (
//       <div>
//         {<Card
//             style={{
//               borderRadius: 10,
//               boxShadow: 'none',
//               background: this.props.themecolors.backgroundColor,
//               color: this.props.themecolors.textColor
//             }}
//           >
//             <CardContent
//               className='card-content'
//               style={{ padding: '6px 12px' }}
//             >
//               <div className={'notification-card clearfix'}>
//                 <Typography
//                   variant='h6'
//                   component='h6'
//                   className='event-detail-heading'
//                   style={{
//                     color: 'inherit',
//                     borderBottom: '1px solid ' + this.props.themecolors.themeLightColor,
//                     marginBottom: 10
//                   }}
//                 >
//                   {this.props.data.deviceName || this.props.device.name}
//                 </Typography>

//                 <CloseIcon
//                     className='feature-close-button'
//                     onClick={this.props.hideDetails}
//                   />
//                       {/* {console.log('value ======', value)} */}

//                 <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
//                   <span
//                     style={{
//                       fontSize: 12,
//                       marginRight: 10,
//                       color: 'inherit'
//                     }}
//                   >
//                     <strong>{this.props.data.name}</strong>
//                     <br />
//                     {this.props.translate(
//                       'notification.' + this.props.data.type
//                     )}
//                     <br />
//                     {this.props.data.attributes.input ? (this.props.data.attributes.input === "mileage" ? setAttributeFormat('mileage',this.props.data.attributes.value):
//                     this.props.data.attributes.input === "hours" ? ((this.props.data.attributes.value)/(1000*3600)).toFixed(2)+ ' hours':
//                     this.props.data.attributes.input === "date" ? moment(this.props.data.attributes.value).tz(this.props.serverTimeZone).format(getDateFormat()): null)
//                     :null}
//                     {/* drawUnit && drawUnit.length ? drawUnit : null */}
//                   </span>
//                   <div style={{ margin: 'auto' }} />
//                   <span
//                     style={{
//                       fontSize: 12,
//                       color: 'inherit',
//                       whiteSpace: 'nowrap'
//                     }}
//                     className='event-detail-date'
//                   >
//                     {moment(this.props.data.eventTime)
//                       .tz(this.props.serverTimeZone)
//                       .format(getDateFormat())}
//                     <br />
//                     {moment(this.props.data.eventTime)
//                       .tz(this.props.serverTimeZone)
//                       .format(getTimeFormat())}
//                   </span>
//                 </div>

//                 {value === 'digital' ? <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
//                   <span
//                     style={{
//                       fontSize: 12,
//                       marginRight: 10,
//                       color: 'inherit'
//                     }}
//                   >
//                     <strong style={{ paddingRight: 10 }}>{this.props.translate('status')}{' : '}</strong>
//                       {this.props.data.attributes.status}<br />
//                   </span>
//                   <div style={{ margin: 'auto' }} />
//                 </div> : null}

//                 {value === 'analog' ? <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
//                   <span
//                     style={{
//                       fontSize: 12,
//                       marginRight: 10,
//                       color: 'inherit'
//                     }}
//                   >
//                     <strong style={{ paddingRight: 10 }}>{this.props.translate('range')}{' : '}</strong>
//                       {this.props.data.attributes.minLimit + ' - ' + this.props.data.attributes.maxLimit}<br />
//                     <strong style={{ paddingRight: 10 }}>{this.props.translate('sharedValue')}{' : '}</strong>
//                       {this.props.data.attributes.value}<br />
//                     <strong style={{ paddingRight: 10 }}>{this.props.translate('status')}{' : '}</strong>
//                       {this.props.data.attributes.logic + '-Range'}<br />
//                     {this.props.data&&this.props.data.geofenceName?<><strong style={{ paddingRight: 10 }}>{this.props.translate('geofence')}{' : '}</strong>
//                       {this.props.data.geofenceName}<br /></>:null}
//                   </span>
//                   <div style={{ margin: 'auto' }} />
//                 </div> : null}

//                 {value === 'ecodriving' ? <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
//                   <span
//                     style={{
//                       fontSize: 12,
//                       marginRight: 10,
//                       color: 'inherit'
//                     }}
//                   >
//                     <strong style={{ paddingRight: 10 }}>{this.props.translate('range')}{' : '}</strong>
//                     {this.props.data.attributes.minLimit}{this.props.translate('g')} {' - '} {this.props.data.attributes.maxLimit}{this.props.translate('g')}<br />
//                     <strong style={{ paddingRight: 10 }}>{this.props.translate('sharedValue')}{' : '}</strong>
//                     {this.props.data.attributes.value}{this.props.translate('g')}<br />
//                     <strong style={{ paddingRight: 10 }}>{this.props.translate('tolerance')}{' : '}</strong>
//                     {this.props.data.attributes.tolerance}{this.props.translate('g')}<br />
//                   </span>
//                   <div style={{ margin: 'auto' }} />
//                 </div> : null}
//                 {value === 'movement' ? <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
//                   <span
//                     style={{
//                       fontSize: 12,
//                       marginRight: 10,
//                       color: 'inherit'
//                     }}
//                   >
//                     {this.props.data.attributes.maxLimit && <><strong style={{ paddingRight: 10 }}>{this.props.translate('range')}{' : '}</strong>
//                     {setAttributeFormat ('speed', this.props.data.attributes.minLimit)} {' - '} {setAttributeFormat ('speed', this.props.data.attributes.maxLimit)}
//                       <br /></>}
//                     {this.props.data.attributes.value && <><strong style={{ paddingRight: 10 }}>{this.props.translate('sharedValue')}{' : '}</strong>
//                       {setAttributeFormat ('speed', this.props.data.attributes.value)}
//                       <br /></>}
//                     {this.props.data.attributes.tolerance && <><strong style={{ paddingRight: 10 }}>{this.props.translate('tolerance')}{' : '}</strong>
//                     {setAttributeFormat ('speed', this.props.data.attributes.tolerance)}
//                       <br /></>}
//                   </span>
//                   <div style={{ margin: 'auto' }} />
//                 </div> : null}
//                 {value === 'other' ? <div style={{ display: 'flex', flex: 1, marginBottom: 10 }}>
//                   <span
//                     style={{
//                       fontSize: 12,
//                       marginRight: 10,
//                       color: 'inherit'
//                     }}
//                   >
//                     {this.props.data.attributes.newValue && <><strong style={{ paddingRight: 10 }}>{this.props.translate('newValue')}{' : '}</strong>
//                       {this.props.data.attributes.newValue}<br /></>}
//                     {this.props.data.attributes.oldValue && <><strong style={{ paddingRight: 10 }}>{this.props.translate('oldValue')}{' : '}</strong>
//                       {this.props.data.attributes.oldValue}<br /></>}
//                   </span>
//                   <div style={{ margin: 'auto' }} />
//                 </div> : null}
//                 <Typography
//                   style={{
//                     fontSize: 12,
//                     color: 'inherit',
//                     marginBottom: 10,
//                     display: 'flex'
//                   }}
//                   className='event-detail-address'
//                 >
//                   <strong style={{ paddingRight: 6 }}>
//                     <svg fill='currentColor' width={14} height={14}>
//                       <use xlinkHref={`${PinIcon}#icon`} />
//                     </svg>
//                   </strong>
//                   {this.props.address ? <span>{parse(`${this.props.address}`)}</span> : 'N/A'}
//                 </Typography>
//                 <Typography
//                   className='detail-right-section'
//                   style={{
//                     fontSize: 12,
//                     color: 'inherit',
//                     marginBottom: 10,
//                     display: 'flex'
//                   }}
//                 >
//                   <strong style={{ paddingRight: 6 }}>
//                     <svg fill='currentColor' width={14} height={14}>
//                       <use xlinkHref={`${CoordinatesIcon}#icon`} />
//                     </svg>
//                   </strong>
//                   {this.props.data.coordinate ? <span>
//                     <span>
//                       {setAttributeFormat(
//                         'latitude',
//                         this.props.data.coordinate ? this.props.data.coordinate.split(',')[0] : ""
//                       )}
//                       ,
//                     </span>
//                     <span>
//                       {setAttributeFormat(
//                         'longitude',
//                         this.props.data.coordinate ? this.props.data.coordinate.split(',')[1] : ""
//                       )}
//                     </span>
//                   </span> : 'N/A'}
//                 </Typography>
//                 <Typography
//                   style={{
//                     fontSize: 12,
//                     color: 'inherit',
//                     marginBottom: 10,
//                     display: 'flex'
//                   }}
//                   className='event-detail-driver'
//                 >
//                   <strong style={{ paddingRight: 6 }}>
//                     <svg fill='currentColor' width={14} height={14}>
//                       <use xlinkHref={`${UserIcon}#icon`} />
//                     </svg>
//                   </strong>
//                   <span>{this.props.data.driverName || 'Unknow Driver'}</span>
//                 </Typography>
//               </div>
//             </CardContent>
//           </Card>
//          /*  : (
//           <Card
//             style={{
//               borderRadius: 10,
//               boxShadow: 'none',
//               background: this.props.themecolors.backgroundColor,
//               color: this.props.themecolors.textColor
//             }}
//           >
//             <CardContent className='card-content'>
//               <div className={'notification-card event-detail-empty-heading'}>
//                 <Typography
//                   variant='h6'
//                   component='h6'
//                   style={{ color: 'inherit', textAlign: 'center' }}
//                 >
//                   {this.props.translate('notFound')}
//                 </Typography>
//               </div>
//             </CardContent>
//           </Card>
//         ) */}
//       </div>
//     )
//   }
// }

const mapStateToProps = (state) => ({
  notificationCount: state.notificationCount,
  themecolors: state.themeColors,
  logInUser: state.logInUsers,
});

// export const UserEventDetails = connect(mapStateToProps)(UserEventDetails2)
export const UserWiseEvents = connect(mapStateToProps)(UserEvents);
