import React from "react";
import { connect } from "react-redux";
import {
  setBounds,
  resetBounds,
  setBoundApply,
  updateDeviceVisible,
  updateGeoFence,
} from "./../../Actions/Devices";
import { addPOI, updatePOI } from "./../../Actions/POI";
import {
  MapContainer as Map,
  TileLayer,
  FeatureGroup,
  Circle,
  Popup,
  Polygon,
  Tooltip,
  Polyline,
  Marker,
  ZoomControl,
  LayersControl,
  Pane,
} from "react-leaflet";
import CustomMarker from './UpdatedMarker'
import CustomMarker2 from "./CustomMarker2";
import "leaflet-plugins/layer/tile/Yandex";
import { EditControl } from "react-leaflet-draw";
import L from "leaflet";
import "./../../../node_modules/leaflet-draw/dist/leaflet.draw.css";
import LCG from "leaflet-control-geocoder";
import isEqual from "react-fast-compare";
import { MapFiltersVerticle } from "./MapFilters";
// import "react-contextmenu";
// import MarkerClusterGroup from "./MarkerClusterGroup";
import MarkerClusterGroup from "@changey/react-leaflet-markercluster";

import parse from "html-react-parser";
import "./DrawMap.scss";
import { MapTooltip } from "./MapTooltip";
import { notifySuccess } from "../../Utils/CustomNotifcations";
import CloseIcon from "@mui/icons-material/Close";
import Button from "../common/Button";
import moment from "moment";
import {
  convertSpeedUnits,
  errorHandler,
  getDateTimeFormat,
  setAttributeFormat,
  setIconOnMap2,
} from "../../Helpers";
import instance from "../../axios";
import { MapPagination } from "./MapPagination";
import { MapTileLayers } from "../../Reducers/Maps";
// import TextPath from "react-leaflet-textpath";
import * as turf from "@turf/turf";
const polyDefault = [
  [0, 0],
  [0, 0],
];

let initState = {
  lat: 0,
  lng: 0,
  zoom: 12,
  minZoom: 3,
  bounds: null,
  apply: false,
  referencePoints: [],
  poi: [],
  numMapClicks: 0,
  update: true,
  updatePOI: true,
  EditControlObject: {},
  addGeoFenceObject: null,
  addGeoFenceObjectProps: null,
  EditControlObjectProps: {},
  updatedGeo: {},
  geo: {},
  pointer: null,
  newVectorId: 0,
  position: [0, 0],
  whichControls: "",
  poiForm: {},
  MarkerDOM: "",
  EditMode: false,
  DrawControl: {},
  MeasureControl: {},
  MeasureEditMode: false,
  newVector: {},
  noTracks: true,
  Drawing: "",
  Measuring: "",

  fitBounds: {},
  referenceMarker: {},
  contextMarker: {},
  polylineMarkers: [],

  animCount: 0,
  bounding: false,
  currentLayer: null,
  assignMarkers: false,
  showEventOnMap: false,
  kmlLayer: null,
  showVehicleName: false,
};
let geo = {};
let MarkerDOM;

const headingStyle = {
  position: "absolute",
  top: "50%",
  right: "-3%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  zIndex: 1000, // Ensure the heading appears in front of the map
  backgroundColor: "white", // Optional: Set background color
  padding: "10px", // Optional: Add padding for better visibility
  borderRadius: "5px", // Optional: Add border radius for a rounded appearance
  color: "black",
};

// const heading = (
//   <div style={headingStyle}>
//     <h1>Map Heading</h1>
//     <p>Subtitle or additional information</p>
//   </div>
// );
class DrawMap extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...initState,
      getGeofanceItem: this.getGeofanceItem.bind(this),
      cancelMapChanges: this.cancelMapChanges.bind(this),
      savePOI: this.savePOI.bind(this),
      updateVisible: this.updateVisible,
      updatePOIVisible: this.updatePOIVisible.bind(this),
    };

    this.myRef = this.myRef.bind(this);
    this.setBoundOptions = this.setBoundOptions.bind(this);
    this.mapEvent = this.mapEvent.bind(this);
    this.createReferencePoint = this.createReferencePoint.bind(this);
    this.createPOI = this.createPOI.bind(this);
    this.deleteRef = this.deleteRef.bind(this);
    this.addPOIOnMap = this.addPOIOnMap.bind(this);
    this.handleMoveEnd = this.handleMoveEnd.bind(this);
    this.viewData = this.viewData.bind(this);
    this.addMeasureShape = this.addMeasureShape.bind(this);
    this._onEditControlReady = this._onEditControlReady.bind(this);
    this._onEditPath = this._onEditPath.bind(this);
    this._onEditStart = this._onEditStart.bind(this);
    this.setEditControlProps = this.setEditControlProps.bind(this);
    this.setEditControlValue = this.setEditControlValue.bind(this);
    this.reactECref = {};
    this.markers = {};
    this.clusterMarkerList = {};
    this.clusterMarkers = null;
  }

  handleVehicleNameDisplay = (event) => {
    const { checked } = event.target;
    this.setState({ showVehicleName: checked });
  };

  getGeofanceItem(item) {
    this.setState({
      geo: { ...item },
    });
  }

  setEditControlProps(obj) {
    this.setState({
      newVectorId: obj.id || 0,
      EditControlObjectProps: obj,
    });
  }

  setEditControlValue(obj) {
    this.setState({
      EditControlObject: obj,
    });
  }

  addPOIOnMap() {
    const center = this.map && this.map.getCenter();
    this.setState({
      poiForm: {
        name: "POI's Name",
        description: "POI's Description",
        visible: true,
        area: "CIRCLE (" + center.lat + " " + center.lng + ", 50)",
        attributes: {
          radius: 50,
          latlng: center,
        },
      },
    });
  }

  myRef(el) {
    if (this.props.tracksPage) {
      if (el) {
        this.map = el;
      }
    } else {
      if (el) {
        this.map = el;
        this.map && this.props.setMapRef(this.map);
        this.map &&
          L.Control.geocoder({
            position: "bottomright",
            placeholder: "Search location...",
            defaultMarkGeocode: false,
            expand: "click",
            geocoder: L.Control.Geocoder.nominatim({
              htmlTemplate: (r) => {
                return `<span class="leaflet-control-geocoder-address-context">${r.display_name}<br>`;
              },
            }),
          })
            .on("finishgeocode", (e) => {
              let results = e.results.map((res) => ({
                ...res,
                html: res.name,
              }));
              e.target._alts.childNodes.forEach(
                (el) => (el.innerHTML = "hello")
              );
            })
            .on("markgeocode", (e) => {
              this.map &&
                this.map.fitBounds([
                  [e.geocode.center.lat, e.geocode.center.lng],
                ]);
              this.setState({
                pointer: (
                  <Marker
                    position={[e.geocode.center.lat, e.geocode.center.lng]}
                    icon={L.icon({
                      iconUrl: "/assets/images/location-pin.svg",
                      iconSize: [79, 64],
                      iconAnchor: [20, 64],
                    })}
                  />
                ),
              });

              if (this.props.getPostion) {
                this.map &&
                  this.props.getPostion(e.geocode.center, this.map.getZoom());
              }
            })
            .addTo(this.map);
      }
    }
  }

  mapReference(el) {
    if (el) {
      this.map = el.leafletElement;
      this.map &&
        L.Control.geocoder({
          position: "bottomright",
          placeholder: "Search location...",
          defaultMarkGeocode: false,
          expand: "click",
          geocoder: L.Control.Geocoder.nominatim({
            htmlTemplate: (r) => {
              return `<span class="leaflet-control-geocoder-address-context">${r.display_name}<br>`;
            },
          }),
        })
          .on("finishgeocode", (e) => {
            e.target._alts.childNodes.forEach((el) => (el.innerHTML = "hello"));
          })
          .on("markgeocode", (e) => {
            this.map &&
              this.map.fitBounds([
                [e.geocode.center.lat, e.geocode.center.lng],
              ]);
            this.setState({
              pointer: (
                <Marker
                  position={[e.geocode.center.lat, e.geocode.center.lng]}
                  icon={L.icon({
                    iconUrl: "/assets/images/location-pin.svg",
                    iconSize: [79, 64],
                    iconAnchor: [20, 64],
                  })}
                />
              ),
            });

            if (this.props.getPostion && this.map) {
              this.props.getPostion(e.geocode.center, this.map.getZoom());
            }
          })
          .addTo(this.map);
    }
  }

  changeBaseLayer = (event) => {
    if (this.map) {
      const center = this.map.getCenter();
      this.map.options.crs = ["yandexMap", "yandexSat"].includes(
        event.layer.options && event.layer.options.id
      )
        ? L.CRS.EPSG3395
        : L.CRS.EPSG3857;
      this.map.setView(center);
    }
    this.setState({ currentLayer: event.layer.options });
  };

  saveMapChanges(obj) {
    this.setState({ geo: obj });
    if (this.state.EditControlObject[obj.id]._toolbars.edit._actionButtons[0]) {
      this.state.EditControlObject[
        obj.id
      ]._toolbars.edit._actionButtons[0].button.click();
    }
  }

  bindMap(el) {
    this.map = el.leafletElement;
  }

  setBoundOptions() {
    return {};
  }

  checkZoom = () => {
    this.setState({ bounds: null, bounding: false });

    // this.props.dispatch(resetBounds())
    // this.props.dispatch(setTrackId(0))
    /* if(this.map && (this.map.getZoom() < 12) || this.map.getZoom() > 18 ) {
    } */
  };

  mapContextMenu = (e) => {
    this.setState({ contextMarker: { position: e.latlng, address: "" } });
    this.getContextMenuInfo(e);
  };

  getContextMenuInfo = (e, column, ref) => {
    instance({
      url: `https://nominatim.openstreetmap.org/reverse?format=json&lat=${e.latlng.lat}&lon=${e.latlng.lng}&zoom=18&addressdetails=1`,
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((address) => {
        this.setState({
          contextMarker: {
            ...this.state.contextMarker,
            address: address.display_name,
            obj: address.display_name,
          },
        });
      })
      .catch((e) => {});
  };

  createReferencePoint(location) {
    location.id = "ref_" + new Date().getTime().toString();

    var referencePoints = this.state.referencePoints.concat(location);

    this.setState({
      ...this.state,
      referencePoints: referencePoints,
      contextMarker: {},
    });

    this.map && this.map.closePopup();
  }

  createPOI(obj) {
    //var poi = this.state.poi.concat(obj);
    this.setState({
      contextMarker: {},
      poiForm: {
        name: "POI",
        description: obj.address,
        visible: true,
        area: "CIRCLE (" + obj.position.lat + " " + obj.position.lng + ", 50)",
        attributes: {
          radius: 50,
          latlng: obj.position,
        },
      },
    });

    this.map && this.map.closePopup();
  }

  mapEvent(e) {
    if (this.props.markerEnabled) {
      this.setState({ referenceMarker: { position: e.latlng, address: "" } });

      this.getInfoReference(e);
    }
  }

  getInfoReference = (e) => {
    if (this.map && LCG && LCG.L && LCG.L.Control && LCG.L.Control.fGeocoder) {
      const geocoder = LCG.L.Control.Geocoder.nominatim();

      geocoder.reverse(e.latlng, this.map.options.crs.scale(18), (results) => {
        var r = results[0];

        this.setState({
          referenceMarker: { ...this.state.referenceMarker, address: r.name },
        });
      });
    }
  };

  // --------------------------------------------------//
  // --------------------------------------------------//
  // --------------------------------------------------//
  // --------------------------------------------------//

  onFeatureGroupAdd = (e, b) => {
    geo[b] = e.target.getBounds();
    this.setState(
      {
        fitBounds: {
          ...geo,
        },
      },
      () => {
        let bounds = [];
        this.props.geoFence.map((g) => {
          if (g.visible === true && this.state.fitBounds[g.id]) {
            bounds.push(this.state.fitBounds[g.id]);
          }
          return null;
        });
        if (!bounds.length) {
          geo = Object.assign({});
        }
      }
    );
    this.updateVisible();
  };

  _onEditStart(id, event) {
    if (this.reactECref[id]) {
      this.setEditControlValue(this.state.EditControlObject);
      this.updateVisible();
    }
  }

  toggleGeofences = (checked) => {
    if (!checked) {
      this.setState({ fitBounds: {} });
      geo = {};
    } else {
      this.updateVisible();
    }
  };

  updateVisible = () => {
    const currentUrl = window.location.href;

    if (currentUrl.includes("geofence")) {
      setTimeout(() => {
        let bounds = [];
        this.props.geoFence.map((g) => {
          if (g.visible === true && this.state.fitBounds[g.id]) {
            bounds.push(this.state.fitBounds[g.id]);
          }
          return null;
        });
        if (bounds.length) {
          this.map?.fitBounds(bounds);
        }
      }, 50);
    } else {
      setTimeout(() => {
        let bounds = [];
        this.props.geoFence.map((g) => {
          if (g.visible === true && this.state.fitBounds[g.id]) {
            bounds.push(this.state.fitBounds[g.id]);
          }
          return null;
        });

        if (bounds) {
          this.setState({ bounds }, () => {
            this.props.dispatch(setBounds(bounds));
          });
        }
      }, 50);
    }
  };

  _onEditPath(id, e) {
    if (this.reactECref[id]) {
      this.setState(
        {
          EditControlObject: {
            ...this.state.EditControlObject,
            [id]: this.reactECref[id],
          },
        },
        () => {
          let layers = e.layers;
          let item = this.state.geo;
          let attributes = item ? item.attributes : {};
          let updatedItem = this.state.geo || {};
          let jsonObj;

          layers.eachLayer((layer) => {
            jsonObj = layer.toGeoJSON();
            if (jsonObj.geometry.type === "Point") {
              var latlng2 = jsonObj.geometry.coordinates;
              updatedItem = {
                ...item,
                area:
                  "CIRCLE (" +
                  latlng2[1] +
                  " " +
                  latlng2[0] +
                  ", " +
                  layer._mRadius +
                  ")",
                attributes: {
                  ...attributes,

                  type: "circle",
                  latlng: [latlng2[1], latlng2[0]],
                  radius: layer._mRadius,
                },
              };
            } else if (jsonObj.geometry.type === "Polygon") {
              item = this.state.geo;
              var latlng22 = jsonObj.geometry.coordinates;
              var p22 = [];
              let prepare = latlng22[0].map((pos) => {
                p22.push([pos[1], pos[0]]);
                return "" + pos[1] + " " + pos[0] + "";
              });
              updatedItem = {
                ...item,
                area: "POLYGON((" + prepare.join(", ") + "))",
                attributes: { ...attributes, type: "polygon", latlng: p22 },
              };
            } else if (jsonObj.geometry.type === "LineString") {
              item = this.state.geo;
              var latlng23 = jsonObj.geometry.coordinates;
              var p23 = [];
              let prepare = latlng23.map((pos) => {
                p23.push([pos[1], pos[0]]);

                return "" + pos[1] + " " + pos[0] + "";
              });
              updatedItem = {
                ...item,
                area: "LINESTRING (" + prepare.join(", ") + ")",
                attributes: {
                  ...attributes,
                  type: "polyline",
                  latlng: p23,
                  imported: false,
                },
              };
            }
          });

          if (
            this.state.updatedGeo[id] === false ||
            this.state.updatedGeo[id] === undefined
          ) {
            if (updatedItem && updatedItem.visible !== undefined) {
              delete updatedItem.visible;
            }
            if (
              updatedItem &&
              updatedItem.attributes &&
              updatedItem.attributes.speedLimit
            ) {
              updatedItem.attributes.speedLimit = convertSpeedUnits(
                updatedItem.attributes.speedLimit || 0,
                "kmh",
                "kn"
              );
            }
            this.setState({
              updatedGeo: {
                [id]: true,
              },
            });
            instance({
              url: `api/geofences/${id}`,
              method: `PUT`,
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              data: {
                ...updatedItem,
              },
            })
              .then((geofence) => {
                this.setState({
                  updatedGeo: {
                    [id]: false,
                  },
                  EditMode: false,
                });
                this.props.dispatch(
                  updateGeoFence({ ...geofence, visible: false })
                );
                this.props.dispatch(
                  updateGeoFence({ ...geofence, visible: true })
                );
                //   })
                // }
                // else{
                //   throw response
                // }
              })
              .catch((error) => {
                errorHandler(error, this.props.dispatch);
              });
          }
        }
      );
    }
  }

  _onEditControlReady(reactECref) {
    setTimeout(() => {
      if (reactECref) {
        var key = Object.keys(
          reactECref.leafletElement._toolbars.edit.options.featureGroup._layers
        );
        if (key.length) {
          var id =
            reactECref.leafletElement._toolbars.edit.options.featureGroup
              ._layers[key].options.id;

          this.reactECref[id] = reactECref.leafletElement;

          this.setState(
            {
              EditControlObject: {
                ...this.state.EditControlObject,
                [id]: reactECref.leafletElement,
              },
            },
            () => {
              //this.enableEditMap(id);
            }
          );
        }
      }
    }, 50);
  }

  updatePOIVisible() {
    //this.props.dispatch(setBoundApply({apply: false}));

    setTimeout(() => {
      let bounds = [];
      this.props.POI.map((g) => {
        if (g.visible === true) {
          bounds.push(g.attributes.latlng);
        }
        return null;
      });

      if (bounds) {
        this.setState({ bounds }, () => {
          this.props.dispatch(setBounds(bounds));
        });
      }
    }, 50);
  }

  cancelMapChanges() {
    this.setState({ poiForm: {} });
  }

  onChangePOI = (key, value) => {
    this.setState({ poiForm: { ...this.state.poiForm, [key]: value } });
  };

  enableEditMap = (poiForm) => {
    this.setState({ poiForm });
  };

  savePOI() {
    let obj = this.state.poiForm;
    let latlng = {};
    if (obj.id) {
      latlng = obj.attributes && obj.attributes.latlng;
    } else {
      latlng =
        this.state.poiForm.attributes && this.state.poiForm.attributes.latlng;
    }
    const newPOIObj = {
      ...obj,
      name: parse(obj.name)[0] || " ",
      description: parse(obj.description)[0] || " ",
      attributes: { ...obj.attributes, latlng: latlng },
      area:
        "CIRCLE (" +
        latlng.lat +
        " " +
        latlng.lng +
        ", " +
        obj.attributes.radius +
        ")",
    };

    delete newPOIObj.visible;

    if (obj.id) {
      // update

      instance({
        url: `api/pois/${obj.id}`,
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...newPOIObj,
        },
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
        .then((poiData) => {
          this.props.dispatch(updatePOI({ ...poiData, visible: false }));
          this.props.dispatch(updatePOI({ ...poiData, visible: true }));
          this.setState({ poiForm: {} });
          //   })
          // }
          // else{
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    } else {
      instance({
        url: `api/pois`,
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        data: {
          ...newPOIObj,
          id: 0,
        },
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
        .then((poiData) => {
          this.props.dispatch(addPOI([{ ...poiData, visible: true }]));
          this.setState({
            poiForm: {},
          });
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  }

  // measure code

  _onMeasureControlReady = (reactECref) => {
    if (reactECref) {
      reactECref.leafletElement._container.classList.add("custom-edit");
    }
  };

  addMeasureShape(type) {
    this.setState({ whichControls: "measure", Measuring: type }, () => {
      this.cancelMeasuring(type);
    });
  }

  _onMeasureDrawStart = (e) => {
    if (this.state && this.state.whichControls === "measure") {
      this.setState({
        Measuring: e.layerType,
      });
    }
  };

  _onMeasureDrawCreated = (e) => {
    if (this.state && this.state.whichControls === "measure") {
      let latlng = [];
      let dist = 0;
      if (e.layers.getLayers().length) {
        e.layers.getLayers().map((layer) => {
          latlng.push(layer._latlng);
          return null;
        });

        for (let i = 0; i < latlng.length; i++) {
          if (latlng[i + 1]) {
            dist += latlng[i].distanceTo(latlng[i + 1]);
          }
        }

        let polylineMarkers = this.state.polylineMarkers;

        let currentLatlng = latlng[latlng.length - 1];

        polylineMarkers.push(
          <Marker
            ref={this.openPopup}
            key={new Date().getTime()}
            position={currentLatlng}
            iconAnchor={[21, 41]}
          >
            <Tooltip direction={"top"} permanent>
              {dist ? (dist / 1000).toFixed(2) : "0"} km
            </Tooltip>
          </Marker>
        );

        this.setState({ polylineMarkers: [...polylineMarkers] });
      }
    }
  };

  _onMeasureDrawStop = (e) => {
    if (this.state && this.state.whichControls === "measure") {
      let currentShap = {};
      Object.keys(e.target._targets).map((id) => {
        if (parseInt(id) !== e.target._containerId) {
          currentShap = e.target._targets[id];
        }
        return null;
      });

      if (Object.keys(currentShap).length && currentShap.options.newShap) {
        if (this.state.Measuring === "polygon") {
          if (currentShap._latlngs) {
            let area =
              L.GeometryUtil.geodesicArea(currentShap._latlngs[0]) || 0;
            let areaFormated = area ? area.toFixed(2) + " m<sup>2</sup>" : 0;
            currentShap
              .bindPopup(
                '<div className="position-box"><strong>Area</strong><div className="position-box-body">Total area: ' +
                  areaFormated +
                  "</div></div>"
              )
              .openPopup();
          }
        } else if (this.state.Measuring === "polyline") {
          let polylineMarkers = this.state.polylineMarkers;
          this.setState({ polylineMarkers: [...polylineMarkers] });
        }
        this.setState({ MeasureEditMode: false });
      } else {
        this.setState({ MeasureEditMode: false }, () => {
          this.cancelMeasuring();
        });
      }
    }
  };

  cancelMeasuring = (type) => {
    if (
      this.state &&
      this.state.whichControls === "measure" &&
      this.state.MeasureEditMode === false
    ) {
      this.setState({ polylineMarkers: [] }, () => {
        if (
          this.state.Measuring === "polygon" ||
          this.state.Measuring === "polyline"
        ) {
          //this.state.MeasureControl.leafletElement._toolbars.draw._actionButtons[2].button.click()
          const el = document.getElementsByClassName("custom-edit");
          if (el && el.length) {
            const container = el[0];
            const polyline =
              container.childNodes[0].childNodes[0].childNodes[0];
            const polygon = container.childNodes[0].childNodes[0].childNodes[1];
            const deleteOptions =
              container.childNodes[1].childNodes[0].childNodes[1];

            if (
              deleteOptions &&
              deleteOptions.classList &&
              !deleteOptions.classList.contains("leaflet-disabled")
            ) {
              deleteOptions.click();
              setTimeout(() => {
                if (
                  container.childNodes[1].childNodes[1] &&
                  container.childNodes[1].childNodes[1].childNodes[2]
                ) {
                  const clearAll =
                    container.childNodes[1].childNodes[1].childNodes[2]
                      .childNodes[0];
                  clearAll.click();
                  if (type && type === "polyline") polyline.click();
                  if (type && type === "polygon") polygon.click();
                }
              }, 50);
            } else {
              if (type && type === "polyline") polyline.click();
              if (type && type === "polygon") polygon.click();
            }
          }
          /* if (
            this.state.MeasureControl.leafletElement._container.childNodes[1]
              .childNodes[0].childNodes[1]
          ) {
            this.state.MeasureControl.leafletElement._container.childNodes[1].childNodes[0].childNodes[1].click()
          } if (
            this.state.MeasureControl.leafletElement._container.childNodes[0]
              .childNodes[1].childNodes[2]
          ) {
            this.state.MeasureControl.leafletElement._container.childNodes[0].childNodes[1].childNodes[2].childNodes[0].click()
          }
          if (
            this.state.MeasureControl.leafletElement._container.childNodes[1]
              .childNodes[1].childNodes[2] &&
            this.state.MeasureControl.leafletElement._container.childNodes[1]
              .childNodes[1].childNodes[2].childNodes[0]
          ) {
            this.state.MeasureControl.leafletElement._container.childNodes[1].childNodes[1].childNodes[2].childNodes[0].click()
          } */
        }
        this.setState({ Measuring: "" });
      });
    }
  };

  /// measure code end

  // --------------------------------------------------//
  // --------------------------------------------------//
  // --------------------------------------------------//
  // --------------------------------------------------//

  UNSAFE_componentWillReceiveProps(NextProps) {
    const currentUrl = window.location.href;

    if (NextProps.trackId && this.props.trackId !== NextProps.trackId) {
      // this.setState({animCount: 0})
      this.setState({ bounding: true });
      this.props.dispatch(
        updateDeviceVisible({ checked: true, id: NextProps.trackId })
      );
    }
    if (
      NextProps.deviceRelatedData &&
      Object.keys(NextProps.deviceRelatedData).length
    ) {
      NextProps.devices &&
        NextProps.devices.map((deviceData, index) => {
          const position = NextProps.deviceRelatedData[deviceData.id];
          const driver =
            NextProps.drivers &&
            NextProps.drivers.find(
              (dr) =>
                position &&
                position.exists &&
                position.driverId &&
                [position.driverId].includes(dr.id)
            );
            const status = deviceData.status === "offline" ? "offline"  :
            deviceData.status === "gpsNotUpdated" ? "gpsNotUpdated"
          : (position?.icons?.playicon?.label || "")
          const trailer =
            NextProps.trailers &&
            NextProps.trailers.find(
              (t) =>
                position &&
                position.exists &&
                position.attributes.trailerUniqueId &&
                [position.attributes.trailerUniqueId].includes(t.uniqueId)
            );
          if (
            position &&
            position.exists &&
            deviceData &&
            deviceData.visible === true
          ) {
            if (
              this.props.logInUser.attributes &&
              this.props.logInUser.attributes.clusters
            ) {
              this.clusterMarkerList[deviceData.id] = (
                <Marker
                  key={deviceData.id}
                  position={{ lat: position.latitude, lng: position.longitude }}
                  rotationAngle={0}
                  zIndexOffset={1000}
                  rotationOrigin="center"
                  animationTime={
                    deviceData.id === this.props.trackId &&
                    this.state.animCount === 1
                      ? position.animationTime
                      : 0.5
                  }
                  tracking={this.props.trackId}
              
                  icon={L.divIcon({
                    iconUrl:
                      "/assets/category/default/" +
                      (deviceData.category || "default") +
                      "top.svg",
                    iconSize: [50, 50],
                    iconAnchor: [25, 25],
                    tooltipAnchor: [0, -20],
                    className: "custom-marker",
                    html: this.props?.logInUser?.attributes?.unitsViewType === 'default' 
                    ? `<img
                      style="transform: rotate(${position.course}deg)"
                        src=
                          '/assets/category/default/${deviceData.category ||
                            'default'}top.svg'
                        
                        alt=''
                      />` : `<p style="transform: rotate(${position.course}deg)">${setIconOnMap2(deviceData?.category,status,position.icons.playicon,this.props?.logInUser?.attributes?.unitsViewType, deviceData?.attributes?.color)}`,
                  })}
                  iconSize={[50, 50]}
                >
                  <Tooltip direction={"top"}>
                    <MapTooltip
                      key={deviceData.id}
                      trailer={trailer}
                      themecolors={this.props.themecolors}
                      position={position}
                      driver={driver}
                      device={deviceData}
                      logInUser={this.props.logInUser}
                      translate={this.props.translate}
                    />
                  </Tooltip>
                </Marker>
              );
            } else {
              this.markers[deviceData.id] = (
                <CustomMarker
                key={deviceData.id}
                position={{ lat: position.latitude, lng: position.longitude, updated: moment(position.serverTime) }}
                rotationAngle={0}
                rotationOrigin='center'
                color={deviceData.attributes.color}
                polyHead={this.polyHead}
                tracking={this.props.trackId === deviceData.id}
                stopTracking={this.props.trackId===0}
                bounding={this.state.bounding}
                category={deviceData.category || 'default'}
                course={position.course}
                showAll={NextProps.showAll}
                // status={status || ""}
                device={deviceData}
                motionStatus={position.icons.playicon}
                logInUser={this.props.logInUser}
                speed={position.speed}
                trackId={this.props.trackId}
                mapRef={this.map&&this.map}
                icon={ L.divIcon({
                  iconUrl:
                    '/assets/category/default/' +
                    (this.props.category || 'default') +
                    'top.svg',
                  iconSize: [50, 50],
                  iconAnchor: [25, 25],
                  tooltipAnchor: [0, -20],
                  className: 'custom-marker',
                  html: `<img
                    style="transform: rotate(${position.course}deg)"
                      src=
                        '/assets/category/default/${this.props.category ||
                          'default'}top.svg'
                      
                      alt=''
                    />`
                })}
                iconSize={[50, 50]}
               >
                {/* monitoring Tooltip */}
                <Tooltip direction={'top'} key={deviceData.id} >
                  <MapTooltip
                    key={deviceData.id}
                    trailer={trailer}
                    themecolors={this.props.themecolors}
                    position={position}
                    driver={driver}
                    device={deviceData}
                    logInUser={this.props.logInUser}
                    translate={this.props.translate}
                  />
              </Tooltip>
            </CustomMarker>

              );

            }
          } else {
            delete this.markers[deviceData.id];
            delete this.clusterMarkerList[deviceData.id];
          }
        });
    } else {
      this.markers = this.clusterMarkerList = {};
    }

    if (!isEqual(NextProps, this.props)) {
      if (NextProps.trackId) {
        let pos = NextProps.deviceRelatedData[NextProps.trackId];

        if (pos && pos.exists && pos.latitude && pos.longitude) {
          //this.map && this.map.setMaxZoom(16);//.fitBounds([[pos.latitude, pos.longitude]])
          /* this.map && this.map.setView({lat: pos.latitude, lng: pos.longitude}, 16, {
              animate: true,
              duration: 1
            }) */
          /* setTimeout(() => {
              this.map && this.map.setMaxZoom(NextProps.mapLayer.maxZoom)
            }, 100) */
          this.props.dispatch(setBoundApply({ apply: true }));
        }
      }
      if (
        NextProps.trackId !== 0 &&
        (this.props.trackId === 0 ||
          (this.props.trackId !== 0 &&
            this.props.trackId !== NextProps.trackId))
      ) {
        let pos = NextProps.deviceRelatedData[NextProps.trackId];
        if (pos && pos.exists) {
          // && pos.latitude && pos.longitude) {
          // this.map && this.map.setMaxZoom(16).fitBounds([[pos.latitude, pos.longitude]])
          this.setState({ bounding: true });
          this.map &&
            this.map.setView({ lat: pos.latitude, lng: pos.longitude }, 16, {
              animate: true,
              duration: 1,
            });
          setTimeout(() => {
            this.map && this.map.setMaxZoom(NextProps.mapLayer.maxZoom);
          }, 100);
        }

        this.props.dispatch(setBoundApply({ apply: true }));

        // this.setState({ animCount: 0 })
      } /* else {
        this.setState({ animCount: 1 })
      } */

      if (NextProps.markerEnabled === false) {
        this.setState({ referencePoints: [], referenceMarker: {} });
      }

      if (!NextProps.bounded) {
        if (
          NextProps.logInUser &&
          NextProps.logInUser.zoom &&
          NextProps.logInUser.zoom !== this.state.zoom &&
          NextProps.logInUser.latitude !== this.state.lat &&
          NextProps.logInUser.longitude !== this.state.lng
        ) {
          this.setState({
            zoom: NextProps.logInUser.zoom,
            lat: NextProps.logInUser.latitude,
            lng: NextProps.logInUser.longitude,
          });
        } else if (
          NextProps.ServerSetting &&
          NextProps.ServerSetting.zoom &&
          NextProps.ServerSetting.zoom !== this.state.zoom &&
          NextProps.ServerSetting.latitude !== this.state.lat &&
          NextProps.ServerSetting.longitude !== this.state.lng
        ) {
          this.setState({
            zoom: NextProps.ServerSetting.zoom,
            lat: NextProps.ServerSetting.latitude,
            lng: NextProps.ServerSetting.longitude,
          });
        } else {
          if (
            NextProps.deviceRelatedData &&
            Object.values(NextProps.deviceRelatedData).length
          ) {
            const arr = [];
            Object.values(NextProps.deviceRelatedData).map((pos) => {
              if (pos.exists && pos.latitude && pos.longitude)
                arr.push([pos.latitude, pos.longitude]);
            });
            if (arr.length) {
              NextProps.dispatch(setBounds([arr]));
              NextProps.dispatch(setBoundApply({ apply: true }));
            }
          }
        }
      }
    }

    if (
      this.props.eventLat !== NextProps.eventLat &&
      this.props.eventLng !== NextProps.eventLng &&
      NextProps.eventEnableMap
    ) {
      this.setState({ showEventOnMap: true });
      MarkerDOM = (
        <Marker
          position={[NextProps.eventLat || 0, NextProps.eventLng || 0]}
          icon={L.icon({
            iconUrl: "/assets/images/location-pin.svg",
            iconSize: [79, 64],
            iconAnchor: [20, 64],
          })}
        />
      );
    } else {
      MarkerDOM = "";
    }

    if (NextProps.kmlContent) {
      if (!this.state.kmlLayer && this.map) {
        const layer = new L.KML(NextProps.kmlContent);
        this.setState({ kmlLayer: layer }, () => {
          if (this.map) {
            this.map.addLayer(layer);
            this.map.fitBounds(layer.getBounds());
          }
        });
      } else {
        this.clearSelection();
        this.setState(
          {
            kmlLayer: null,
          },
          () => {
            const layer = new L.KML(NextProps.kmlContent);
            this.setState({ kmlLayer: layer }, () => {
              if (this.map) {
                this.map.addLayer(layer);
                this.map.fitBounds(layer.getBounds());
              }
            });
          }
        );
      }
    }

    if (!currentUrl.includes("tracks") && this.props.tracksPage) {
      this.setState({ noTracks: false });
    }
  }

  clearSelection = () => {
    if (this.map) {
      this.map.removeLayer(this.state.kmlLayer);
    }
  };

  componentDidMount() {
    if (!this.props.bounded) {
      if (
        this.props.deviceRelatedData &&
        Object.keys(this.props.deviceRelatedData).length
      ) {
        const arr = [];
        Object.values(this.props.deviceRelatedData).map((pos) => {
          if (pos.exists && pos.latitude && pos.longitude)
            arr.push([pos.latitude, pos.longitude]);
        });
        if (arr.length) {
          this.props.dispatch(setBounds([arr]));
          this.props.dispatch(setBoundApply({ apply: true }));
        }
      }
    }
  }

  UNSAFE_componentWillMount() {
    if (!this.props.bounded) {
      if (
        this.props.logInUser &&
        this.props.logInUser.zoom &&
        this.props.logInUser.zoom !== this.state.zoom &&
        this.props.logInUser.latitude !== this.state.lat &&
        this.props.logInUser.longitude !== this.state.lng
      ) {
        this.setState({
          zoom: this.props.logInUser.zoom,
          lat: this.props.logInUser.latitude,
          lng: this.props.logInUser.longitude,
        });
      } else if (
        this.props.ServerSetting &&
        this.props.ServerSetting.zoom &&
        this.props.ServerSetting.zoom !== this.state.zoom &&
        this.props.ServerSetting.latitude !== this.state.lat &&
        this.props.ServerSetting.longitude !== this.state.lng
      ) {
        this.setState({
          zoom: this.props.ServerSetting.zoom,
          lat: this.props.ServerSetting.latitude,
          lng: this.props.ServerSetting.longitude,
        });
      } else {
        if (
          this.props.deviceRelatedData &&
          Object.values(this.props.deviceRelatedData).length
        ) {
          const arr = [];
          Object.values(this.props.deviceRelatedData).map((pos) => {
            if (pos.exists && pos.latitude && pos.longitude)
              arr.push([pos.latitude, pos.longitude]);
          });
          if (arr.length) {
            this.props.dispatch(setBounds([arr]));
            this.props.dispatch(setBoundApply({ apply: true }));
          }
        }
      }
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setBoundApply({ apply: false }));
    this.referencePoints = this.poi = this.markers = this.clusterMarkers = null;
    this.setState({ bounds: null });
    this.props.dispatch(resetBounds());
    this.map &&
      this.map.eachLayer((layer) => {
        this.map && this.map.removeLayer(layer);
      });
    this.state = this.map = null;
  }

  deleteRef(d) {
    this.state.referencePoints.map((obj, index) => {
      if (obj.id === d.id) {
        this.state.referencePoints.splice(index, 1);
      }
      return "";
    });
    this.setState({ ...this.state, referenceMarker: {} });
    this.map && this.map.closePopup();
  }

  getMapAttr = (obj) => {
    return obj;
  };

  openPopup = (marker) => {
    if (marker && marker.leafletElement) {
      window.setTimeout(() => {
        marker.leafletElement.openPopup();
      });
    } else if (marker && marker.latlng) {
      marker.target.openPopup();
    }
  };

  handleMoveEnd(e) {
    this.setState({
      poiForm: {
        ...this.state.poiForm,
        attributes: {
          ...this.state.poiForm.attributes,
          latlng: e.target._latlng,
        },
      },
    });
  }

  updateLayer = () => {
    let zoom = this.map && this.map.getZoom();
    let center = this.map && this.map.getCenter();
    setTimeout(() => {
      this.map && this.map.setView(center, zoom);
    }, 100);
  };

  copyToClipboard2 = (obj) => {
    const el = document.createElement("textarea");
    el.value = `${obj.lat.toFixed(8)},${obj.lng.toFixed(8)}`;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.props.dispatch(notifySuccess("Copied to clipboard!"));
  };
  markerClose = () => {
    this.setState({ contextMarker: {} });
  };

  _onDrawControlReady(reactECref) {
    this.setState({ DrawControl: reactECref });
  }

  _onDrawStop(e) {
    let currentShap = {};
    Object.keys(e.target._targets).map((id) => {
      if (parseInt(id) !== e.target._containerId) {
        currentShap = e.target._targets[id];
      }
      return null;
    });

    if (Object.keys(currentShap).length) {
      if (currentShap.options.newShap) {
        if (this.state.Drawing === "circle") {
          this.setState(
            {
              newVector: {
                name: "New Circle",
                calendarId: 0,
                description: "",
                area:
                  "CIRCLE (" +
                  currentShap._latlng.lat +
                  " " +
                  currentShap._latlng.lng +
                  ", " +
                  currentShap._mRadius +
                  ")",
                attributes: {
                  type: "circle",
                  radius: currentShap._mRadius,
                  color: currentShap.options.color,
                  latlng: [currentShap._latlng.lat, currentShap._latlng.lng],
                },
              },
            },
            () => {
              this._saveShap(this.state.newVector);
            }
          );
        } else if (this.state.Drawing === "polygon") {
          if (currentShap._latlngs) {
            let prepare = currentShap._latlngs[0].map((pos) => {
              return "" + pos.lat + " " + pos.lng + "";
            });
            this.setState(
              {
                newVector: {
                  name: "New Polygon",
                  calendarId: 0,
                  description: "",
                  area: "POLYGON((" + prepare.join(", ") + "))",
                  attributes: {
                    type: "polygon",
                    color: currentShap.options.color,
                    latlng: currentShap._latlngs[0],
                  },
                },
              },
              () => {
                this._saveShap(this.state.newVector);
              }
            );
          }
        } else if (this.state.Drawing === "polyline") {
          let distance = 0;
          let prepare = currentShap._latlngs.map((pos, index) => {
            distance += pos.distanceTo(currentShap._latlngs[index - 1] || pos);
            return "" + pos.lat + " " + pos.lng + "";
          });
          this.setState(
            {
              newVector: {
                name: "New Polyline",
                calendarId: 0,
                description: "",
                area: "LINESTRING (" + prepare.join(", ") + ")",
                attributes: {
                  type: "polyline",
                  color: currentShap.options.color,
                  latlng: currentShap._latlngs,
                  polylineDistance: distance / 1000,
                },
              },
            },
            () => {
              this._saveShap(this.state.newVector);
            }
          );
        }
      }
    } else {
      this.setState({ Drawing: "" });
    }
  }

  _onDrawStart(e) {
    this.setState({
      Drawing: e.layerType,
    });
  }

  viewData(row, e) {
    let points = e.target._latlngs.map((latlng) => [latlng.lng, latlng.lat]);
    var line = turf.lineString(points);
    var pt = turf.point([e.latlng.lng, e.latlng.lat]);
    var nearestPoint = turf.nearestPointOnLine(line, pt, { units: "meters" });

    var nlatlng =
      this.props.routes[row.startPositionId]["positions"][
        nearestPoint["properties"]["index"]
      ];

    let timeFormat = getDateTimeFormat();

    let t = moment(nlatlng.fixTime)
      .tz(this.props.serverTimeZoneName)
      .format(timeFormat);

    let html =
      '<div class="position-box"><strong>' +
      row.deviceName +
      '</strong><div class="position-box-body">Address: ' +
      (parse(nlatlng.address)[0] || "") +
      " <br />Time: " +
      t +
      "<br/> Speed : " +
      // (nlatlng.speed * 1.852).toFixed(0)
      setAttributeFormat("speed", nlatlng.speed) +
      "<br />Coordinates: " +
      e.latlng.lat.toFixed(8) +
      ", " +
      e.latlng.lng.toFixed(8) +
      "</div></div>";

    e.target.bindPopup(html).openPopup(e.latlng);

    if (e.sourceTarget._map) {
      //L.marker([nearestPoint.geometry.coordinates[1], nearestPoint.geometry.coordinates[0]]).addTo(e.sourceTarget._map);
      // [lat, lng]
      //L.marker([nlatlng.latitude, nlatlng.longitude]).addTo(e.sourceTarget._map);
    }
  }
  onAdded = (e, b) => {
    const newFitBounds = {
      ...this.state.fitBounds,
      [b]: e.target.getBounds(),
    };
    this.setState(
      {
        fitBounds: newFitBounds,
      },
      () => {
        const bounds = [];
        Object.keys(newFitBounds).forEach((id) => {
          const obj = this.props.routes[id];
          if (obj?.visible && newFitBounds[id] && Object.keys(newFitBounds[id]).length > 0) {
            bounds.push(newFitBounds[id]);
          }
        });
  
        if (bounds.length) {
          this.map?.fitBounds(bounds);
        }
      }
    );
  };
  
  componentDidUpdate(prevProps) {
    const isEventsPage = window.location.href.includes("events");
    if (!isEventsPage) return;
  
    const { eventLat, eventLng } = this.props;
    if(eventLat===0||eventLng===0){
      const backUserLocationDefault = [this.state.lat || 0, this.state.lng || 0];
      this.map?.setView(backUserLocationDefault, 15);
    }else{
      if (
        (prevProps.eventLat !== eventLat || prevProps.eventLng !== eventLng) &&
        eventLat != null &&
        eventLng != null &&
        this.map
      ) {
        this.map.setView([eventLat, eventLng], 15);
      }
    }
  }
  
  

  render() {
    const currentUrl = window.location.href;
    let polylines = [];
    let waypoints = [];
    let bounds = [];
    let zoom = this.state.zoom;
    let position = this.state.position;
    let timeFormat = getDateTimeFormat();
    const position3 = [this.state.lat || 0, this.state.lng || 0];


    const settings = {
      rectangle: false,
      circle: false,
      circlemarker: false,
      marker: false,
      polyline: false,
      polygon: false,
    };
    if (this.props.routes && this.props.tracksPage && this.state.noTracks) {
      if (Object.keys(this.props.routes).length) {
        Object.keys(this.props.routes).map((key) => {
          if (this.props.routes[key].visible) {
            let p = [];
            let obj = this.props.routes[key];

            if (this.props.routes[key]) {
              polylines.push(
                <Marker
                  key={obj.row.startPositionId + "start"}
                  position={[obj.row.startLat, obj.row.startLon]}
                  icon={L.icon({
                    iconUrl: "/assets/images/maps/start-marker.svg",
                    iconSize: [40, 40],
                    iconAnchor: [10, 40],
                    popupAnchor: [0, -40],
                  })}
                >
                  <Popup>
                    <div className="position-box">
                      <strong>{obj.row.deviceName}</strong>
                      <div className="position-box-body">
                        Address: {parse(obj.row.startAddress)} <br />
                        Time:{" "}
                        {moment(obj.row.startTime)
                          .tz(this.props.serverTimeZoneName)
                          .format(timeFormat)}
                        <br />
                        Coordinates:{" "}
                        {obj.row.startLat + ", " + obj.row.startLon}
                      </div>{" "}
                    </div>
                  </Popup>
                </Marker>
              );
              polylines.push(
                <Marker
                  key={obj.row.endPositionId + "end"}
                  position={[obj.row.endLat, obj.row.endLon]}
                  icon={L.icon({
                    iconUrl: "/assets/images/maps/end-marker.svg",
                    iconSize: [40, 40],
                    iconAnchor: [10, 40],
                    popupAnchor: [0, -40],
                  })}
                >
                  <Popup>
                    <div className="position-box">
                      <strong>{obj.row.deviceName}</strong>
                      <div className="position-box-body">
                        Address: {parse(obj.row.endAddress)} <br />
                        Time:{" "}
                        {moment(obj.row.endTime)
                          .tz(this.props.serverTimeZoneName)
                          .format(timeFormat)}
                        <br />
                        Coordinates: {obj.row.endLat + ", " + obj.row.endLon}
                      </div>{" "}
                    </div>
                  </Popup>
                </Marker>
              );

              obj.positions.map((pos) => {
                p.push(L.latLng(pos.latitude, pos.longitude));
                waypoints.push({
                  latLng: L.latLng(pos.latitude, pos.longitude),
                });
                return null;
              });

              /*L.Routing.control({
                     waypoints: waypoints,
                   }).addTo(this.map);*/

              polylines.push(
                <Polyline
                  pane="overlayPane"
                  onClick={(e) => this.viewData(obj.row, e)}
                  eventHandlers={{
                    add: (e) => {
                      this.onAdded(e,key)
                    },
                  }}
                  // onAdd={(e) => this.onAdded(e, key)}
                  key={key}
                  color={obj.row.color}
                  positions={p}
                  weight={6}
                >
                  {/* <TextPath
                    positions={p}
                    text="&#x25B6;          "
                    repeat
                    offset={8}
                    attributes={{ "font-size": 30, fill: obj.row.color }}
                  /> */}
                </Polyline>
              );
            }
          }
          return null;
        });
      }
    }

    const drawOptions = {
      shapeOptions: {
        color: this.props.themecolors && this.props.themecolors.themeDarkColor,
        opacity: 1,
        newShap: true,
      },
    };
    const drawSettings = {
      ...settings,
      circle: drawOptions,
      polygon: drawOptions,
      polyline: drawOptions,
    };
    const referenceMarkers = this.state.referenceMarker.position ? (
      <Marker
        ref={this.openPopup}
        onMove={this.openPopup}
        key={122212}
        zoom={3}
        position={this.state.referenceMarker.position}
        className="ref-marker"
        icon={L.icon({
          iconUrl: "/assets/category/default/defaulttop.svg",
          iconSize: [50, 50],
          iconAnchor: [25, 50],
          popupAnchor: [0, -50],
        })}
      >
        <Popup>
          <div className="position-box">
            <strong>Reference Point</strong>
            <div className="position-box-body">
              Coordinates: {this.props.eventLat},{this.props.eventLng}
            </div>
          </div>
        </Popup>
      </Marker>
    ) : null;

    const contextMarker = this.state.contextMarker.position ? (
      <Marker
        ref={this.openPopup}
        onMove={this.openPopup}
        key={1222}
        position={this.state.contextMarker.position}
        className="ref-marker"
        iconSize={[25, 41]}
        icon={L.icon({
          iconUrl: "/assets/images/location-pin.svg",
          iconSize: [50, 50],
          iconAnchor: [25, 50],
          popupAnchor: [0, -50],
        })}
      >
        <Popup>
          <div className="position-box">
            <strong>
              Reference Point
              <CloseIcon
                size="small"
                className={"feature-close-button"}
                onClick={this.markerClose}
                style={{ top: 0, right: 5 }}
              />
            </strong>
            <div className="position-box-body">
              <b>Address:</b>
              {this.state.contextMarker.address ? (
                this.state.contextMarker.address
              ) : (
                <span
                  style={{
                    display: "inline-block",
                    verticalAlign: "top",
                    marginTop: -5,
                    marginBottom: -5,
                    color: this.props.themecolors.textColor,
                  }}
                >
                  Loading...
                </span>
              )}
              <br />
              <b>Coordinates:</b>{" "}
              {this.state.contextMarker.position.lat.toFixed(8)},
              {this.state.contextMarker.position.lng.toFixed(8)} <br /> <br />
              <Button
                onClick={(e) =>
                  this.copyToClipboard2(this.state.contextMarker.position)
                }
              >
                Copy Coordinates
              </Button>
            </div>
          </div>
        </Popup>
      </Marker>
    ) : null;

    this.referencePoints = this.state.referencePoints.map((position, index) => {
      return (
        <Marker
          key={position.id}
          position={{ lat: position.lat, lng: position.lng }}
          icon={L.icon({
            iconUrl: "/assets/category/default/defaulttop.svg",
            iconSize: [50, 50],
            iconAnchor: [25, 50],
            popupAnchor: [0, -50],
          })}
        >
          <Popup>
            <div className="position-box">
              <strong>Reference Point</strong>
              <div className="position-box-body">
                Coordinates: {position.lat}, {position.lng} <br />
                <button onClick={(e) => this.deleteRef(position)}>
                  Delete
                </button>
              </div>
            </div>
          </Popup>
        </Marker>
      );
    });

    this.poi = this.props.POI.map((obj, index) => {
      if (obj.visible) {
        let n = obj;
        if (this.state.poiForm["id"] === obj.id) {
          n = this.state.poiForm;
        }
        return (
          <Marker
            key={"poi-" + index}
            position={n.attributes.latlng}
            icon={L.icon({
              iconUrl: "/assets/category/default/defaulttop.svg",
              iconSize: [50, 50],
              iconAnchor: [25, 50],
              popupAnchor: [0, -50],
              tooltipAnchor: [0, -50],
            })}
            draggable={this.state.poiForm["id"] === obj.id}
            onMoveend={(e) => this.handleMoveEnd(e, n)}
          >
            <Tooltip permanent direction="top">
              <div className="position-box">
                <strong>POI</strong>
                <div className="position-box-body">
                  Name: {parse(n.name)} <br />
                  Description: {parse(n.description)} <br />
                  Coordinates:
                  {parseFloat(n.attributes.latlng.lat).toFixed(8) +
                    ", " +
                    parseFloat(n.attributes.latlng.lng).toFixed(8)}
                </div>
              </div>
            </Tooltip>
          </Marker>
        );
      } else {
        return null;
      }
    });

    const geofences = this.props.geoFence.map((obj) => {
      if (obj && obj.attributes.type === "circle" && obj.visible === true) {
        return (
          <FeatureGroup
          eventHandlers={{
            add: (e) => {
              this.onFeatureGroupAdd(e, obj.id)
            },
          }}
            // onAdd={(e) => this.onFeatureGroupAdd(e, obj.id)}
            ref={this._onFeatureGroupReady}
            key={obj.id}
          >
            <EditControl
              ref={this._onEditControlReady}
              position="bottomleft"
              draw={settings}
              onEdited={this._onEditPath.bind(this, obj.id)}
              onEditStart={this._onEditStart.bind(this, obj.id)}
            />
            <Circle
              id={obj.id}
              radius={obj.attributes.radius}
              center={obj.attributes.latlng}
              color={obj.attributes.color}
            >
              <Tooltip direction={"top"} permanent>
                <div>
                  <span>{parse(obj.name)}</span>
                </div>
              </Tooltip>
            </Circle>
          </FeatureGroup>
        );
      } else if (
        obj &&
        obj.attributes.type === "polygon" &&
        obj.visible === true
      ) {
        return (
          <FeatureGroup
            // onAdd={(e) => this.onFeatureGroupAdd(e, obj.id)}
            eventHandlers={{
              add: (e) => {
                this.onFeatureGroupAdd(e, obj.id)
              },
            }}
            ref={this._onFeatureGroupReady}
            key={obj.id}
          >
            <EditControl
              ref={this._onEditControlReady}
              position="bottomleft"
              draw={settings}
              edit={{ remove: false }}
              onEdited={this._onEditPath.bind(this, obj.id)}
              onEditStart={this._onEditStart.bind(this, obj.id)}
            />
            <Polygon
              id={obj.id}
              key={obj.id + "__1"}
              positions={obj.attributes.latlng}
              color={obj.attributes.color}
            >
              <Tooltip direction={"top"} permanent>
                <div>
                  <span>{parse(obj.name)}</span>
                </div>
              </Tooltip>
            </Polygon>
          </FeatureGroup>
        );
      } else if (
        obj &&
        obj.attributes.type === "polyline" &&
        obj.visible === true
      ) {
        return (
          <FeatureGroup
            // onAdd={(e) => this.onFeatureGroupAdd(e, obj.id)}
            eventHandlers={{
              add: (e) => {
                this.onFeatureGroupAdd(e, obj.id)
              },
            }}
            ref={this._onFeatureGroupReady}
            key={obj.id}
          >
            <EditControl
              ref={this._onEditControlReady}
              position="bottomleft"
              draw={settings}
              edit={{ remove: false }}
              onEdited={this._onEditPath.bind(this, obj.id)}
              onEditStart={this._onEditStart.bind(this, obj.id)}
            />
            <Polyline
              id={obj.id}
              key={obj.id + "__1"}
              positions={obj.attributes.latlng}
              color={obj.attributes.color}
            >
              <Tooltip direction={"top"} permanent>
                <div>
                  <span>{parse(obj.name)}</span>
                </div>
              </Tooltip>
            </Polyline>
          </FeatureGroup>
        );
      }

      return "";
    });

    let drawPOI = null;
    if (this.state.poiForm.name && !this.state.poiForm["id"]) {
      drawPOI = (
        <Marker
          key={"poi-newpoi"}
          position={this.state.poiForm.attributes.latlng}
          icon={L.icon({
            iconUrl: "/assets/category/timemachine/default.svg",
            iconSize: [50, 50],
            iconAnchor: [25, 50],
            popupAnchor: [0, -50],
            tooltipAnchor: [0, -50],
          })}
          draggable={true}
          onMoveend={this.handleMoveEnd}
        >
          <Tooltip direction="top" permanent>
            <div className="position-box">
              <strong>POI</strong>
              <div className="position-box-body">
                Name: {parse(this.state.poiForm.name)} <br />
                Description: {parse(this.state.poiForm.description)}
                <br />
                Coordinates:
                {this.state.poiForm.attributes.latlng.lat.toFixed(8)},
                {this.state.poiForm.attributes.latlng.lng.toFixed(8)}
              </div>
            </div>
          </Tooltip>
        </Marker>
      );
    }

    const poly =
      this.props.tailEnabled &&
      this.props.trackId &&
      this.props.tailSegments &&
      this.props.tailSegments.length
        ? this.props.tailSegments.map((p, i) =>
            i > 1 ? (
              <Polyline
                weight={4}
                color={p.from.color}
                positions={[
                  [p.from.lat, p.from.lng],
                  [p.to.lat, p.to.lng],
                ]}
                smoothFactor="1"
                opacity={1 - i * (1 / this.props.tailSegments.length)}
              />
            ) : null
          )
        : null;

    const body = [
      <ZoomControl key={1} position={"bottomright"} />,

      <div key={3}>
        {!currentUrl.includes("geofence") ? (
          this.props.logInUser &&
          this.props.logInUser.attributes &&
          this.props.logInUser.attributes.clusters ? (
            <MarkerClusterGroup key={1}>
              {Object.values(this.clusterMarkerList)}
            </MarkerClusterGroup>
          ) : (
            Object.values(this.markers)
          )
        ) : null}

        {this.referencePoints}
        {this.state.pointer}
        {this.poi}
        {drawPOI}
        {currentUrl.includes("geofence") ? geofences : ""}
        {this.state.polylineMarkers}
        {referenceMarkers}
        {/* {contextMarker} */}

        <FeatureGroup key={2}>
          <EditControl
            ref={this._onMeasureControlReady}
            className="control"
            draw={drawSettings}
            position="bottomleft"
            onDrawStop={this._onMeasureDrawStop}
            onDrawStart={this._onMeasureDrawStart}
            onDrawVertex={this._onMeasureDrawCreated}
          />
        </FeatureGroup>
        <LayersControl position="bottomright">
          {MapTileLayers.map((layer) => (
            <LayersControl.BaseLayer
              id={layer.id}
              checked={layer.id === this.props.mapLayer.id}
              key={layer.id}
              name={layer.name}
            >
              <TileLayer {...layer} minZoom={this.state.minZoom} />
            </LayersControl.BaseLayer>
          ))}
        </LayersControl>
      </div>,
    ];

    let crs = {};
    if (
      ["yandexMap", "yandexSat"].includes(
        this.props.mapLayer && this.props.mapLayer.id
      )
    ) {
      crs = { crs: L.CRS.EPSG3395 };
    }
    if (Object.keys(this.state.fitBounds).length) {
      Object.keys(this.state.fitBounds).map((id) => {
        if (Object.keys(this.props.routes).length) {
          let obj = this.props.routes[id];
          if (obj && obj.visible) {
            bounds.push(this.state.fitBounds[id]);
          }
        }
        return null;
      });
    } else {
      if (this.props.logInUser.zoom) {
        position = [
          this.props.logInUser.latitude || 0,
          this.props.logInUser.longitude || 0,
        ];
        zoom = this.props.logInUser.zoom;
      } else if (this.props.ServerSetting.zoom) {
        position = [
          this.props.ServerSetting.latitude || 0,
          this.props.ServerSetting.longitude || 0,
        ];
        zoom = this.props.ServerSetting.zoom;
      }
    }
    const position2 = [this.props.eventLat,this.props.eventLng];


    const thisMap = [
      <>
        <Map
          key={1}
          ref={this.myRef}
          onZoomAnim={this.checkZoom}
          boundsOptions={this.setBoundOptions}
          bounds={
            this.props.tracksPage
              ? bounds && bounds.length
                ? bounds
                : null
              : this.props.bounds.length
              ? this.props.bounds
              : null
          }
          style={{ height: this.props.height, width: this.props.width }}
          center={currentUrl.includes("events") ? position2 : position}
          zoom={currentUrl.includes("events") ? 15 : this.state.zoom}
          zoomControl={false}
          onClick={this.mapEvent}
          minZoom={this.state.minZoom}
          maxZoom={this.props.mapLayer.maxZoom}
          maxNativeZoom={this.props.mapLayer.maxZoom}
          onContextMenu={this.mapContextMenu}
          onBaselayerchange={this.changeBaseLayer}
          {...crs}
        >
          <TileLayer
            {...this.props.mapLayer}
            url={
              this.props.mapTraffic
                ? this.props.mapLayer && this.props.mapLayer.trafficUrl
                : this.props.mapLayer && this.props.mapLayer.url
            }
            maxNativeZoom={this.props.mapLayer.maxZoom}
            maxZoom={this.props.mapLayer.maxZoom}
            minZoom={this.state.minZoom}
          />


          {currentUrl.includes("events") &&
          this.props.eventLat &&
          this.props.eventLng ? (
            <Marker
              key={545}
              position={position2}
              rotationAngle={0}
              rotationOrigin="center"
              icon={L.icon({
                iconUrl:
                  "/assets/category/default/" +
                  (this.props.deviceCategory || "default") +
                  "top.svg",
                iconSize: [50, 50],
                iconAnchor: [25, 50],
                popupAnchor: [0, -50],
              })}
              iconSize={[50, 50]}
              category={this.props.deviceCategory}
            />
          ) : currentUrl.includes("tracks") && this.state.noTracks ? (
            <>
              <ZoomControl position={"bottomright"} />

              {MarkerDOM}
              {polylines}
              {currentUrl.includes("geofence") ? geofences : ""}
              <div className="map-filters-wrapper">
                <MapFiltersVerticle
                  disableBottomLeftFilters
                  disablePOIFilters
                  disableTrafficLayer
                  disableMapLayer
                  disableSettingsLayer
                  disableClusterLayer
                  updateVisible={this.updateVisible}
                  themecolors={this.props.themecolors}
                  translate={this.props.translate}
                  mapRef={this.map}
                />
                <LayersControl position="bottomright">
                  {MapTileLayers.map((layer) => (
                    <LayersControl.BaseLayer
                      id={layer.id}
                      checked={layer.id === this.props.mapLayer.id}
                      key={layer.id}
                      name={layer.name}
                    >
                      <TileLayer {...layer} minZoom={this.state.minZoom} />
                    </LayersControl.BaseLayer>
                  ))}
                </LayersControl>
              </div>
            </>
          ) : currentUrl.includes("geofences") && this.state.noTracks ? (
            <>
              {this.state.pointer}
              {body}
            </>
          ) : (
            body
          )}
        </Map>
        ,
        {/* <div style={headingStyle}>
          <Checkbox 
          style={{color:"red"}}
           label={this.props.translate("Show Vehicle Name")}
          checked={this.state.showVehicleName}
          onChange={this.handleVehicleNameDisplay}
          />
        </div> */}
      </>,
    ];

    return (
      <div style={{ height: "100%" }}>
        {["osm", ""].includes(this.props.mapLayer.id) ? thisMap : null}
        {["yandexMap", "yandexSat"].includes(this.props.mapLayer.id)
          ? thisMap
          : null}
        {["carto"].includes(this.props.mapLayer.id) ? thisMap : null}
        {[
          "googleTerrain",
          "googleHybrid",
          "googleSatellite",
          "googleRoad",
        ].includes(this.props.mapLayer.id)
          ? thisMap
          : null}
        {["baidu"].includes(this.props.mapLayer.id) ? thisMap : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bounds: state.bounds,
  deviceRelatedData: state.deviceRelatedData,
  bounded: state.bounded,
  geoFence: state.geoFence,
  mapTraffic: state.mapTraffic,
  tailSegments: state.tailSegments,
  POI: state.POI,
});

export default connect(mapStateToProps)(DrawMap);
