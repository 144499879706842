import React, { Fragment } from "react";
import axios from "axios";
import SimpleModalWrapped from "./../common/Modal";
import { ReactComponent as SendIcon } from "../../assets/nav/send.svg";
import TextField from "./../common/TextField";
import { withStyles } from "@mui/styles";
import Tooltip from "./../common/Tooltip";
import MenuItem from "./../common/MenuItem";
import Grid from "@mui/material/Grid";
import { categoriesList, checkPrivileges } from "../../Helpers";
import SingleSelect from "../common/SingleSelect";
import { unitsList } from "./unitsPortList";
import { timeZonesTypes } from "./../Attributes/timezone";
import Button from "../common/Button";
import { Avatar, Paper } from "@mui/material";
import { Scrollbar } from "react-scrollbars-custom";
import withTranslationWrapper from "../../HOC/withTranslation";

const styles = (theme) => ({});
//let emptyLink = "javascript:void(0)";
class addDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      category: {
        name: this.props.selectedDevice.category || "default",
        id: this.props.selectedDevice.category || "default",
      },
      images: [],
      applied: false,

      visible: true,
      at_modal_visable: false,
      isVisableDeviceBtn: false,
      form: {
        attributes: {},
      },
      open: true,
      isAdvSettingEnable: false,
    };
    this.addAttributes = this.addAttributes.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.searchFromList = this.searchFromList.bind(this);
    this.searchFromZoneList = this.searchFromZoneList.bind(this);
    this.onChange = this.onChange.bind(this);

    // this.checkRequiredFields=this.checkRequiredFields.bind(this)
    // this.formSubmit=this.formSubmit.bind(this)
  }

  onChange(category) {
    this.setState({ category });
    this.props.logo_handelChanges(category);
  }

  closeModal() {
    this.setState({
      visible: false,
    });
  }

  toggleCollapse() {
    let a = this.state.open;
    this.setState({
      open: !a,
    });
  }

  addAttributes() {
    this.setState({
      at_modal_visable: !this.state.at_modal_visable,
    });
  }

  async searchFromList(inputValue) {
    if (!inputValue) {
      return [];
    }

    return await axios({
      method: "GET",
      url: `/api/devices/models?search=${inputValue}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.data && response.data.data && response.data.data.data) {
        return response.data.data.data.map((item) => {
          return {
            id: item.name,
            name: item.name,
            key: item.name,
            port: item.port,
            protocol: item.protocol,
            label: item.name,
            value: item.name,
          };
        });
      }
    });
  }

  async searchFromZoneList(inputValue) {
    if (!inputValue) {
      return [];
    }

    const result = timeZonesTypes.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
    return await result.map((item) => {
      return {
        id: item.key,
        key: item.key,
        label: item.label,
        value: item.key,
      };
    });
  }

  render() {
    const { classes } = this.props;
    const { images } = this.props;

    const defaultOptions = [];
    unitsList.map((item, i) => {
      if (i <= 9) {
        defaultOptions.push({
          id: item.device,
          name: item.device,
          key: item.device,
          port: item.port,
          protocol: item.protocol,
          label: item.device,
          value: item.device,
        });
      }
      return null;
    });
    if (
      defaultOptions &&
      defaultOptions.length < unitsList.length &&
      unitsList
    ) {
      defaultOptions.push({
        id: "",
        name: "Search for more items...",
        key: "",
        port: "",
        label: "Search for more items...",
        value: "",
        isDisabled: true,
      });
    }
    const defaultZones = [];
    timeZonesTypes.map((item, i) => {
      // if (i <= 9) {
      defaultZones.push({
        id: item.key,
        key: item.key,
        label: item.label,
        value: item.key,
      });
      //}
      return null;
    });

    /* defaultZones.push({
      id: '',
      name: 'Search for more items...',
      key: '',
      port: '',
      label: 'Search for more items...',
      value: '',
      isDisabled: true
    }) */
    return (
      <Fragment>
        <SimpleModalWrapped
          visable
          isButtonVisable
          title=""
          formSubmit={this.props.formSubmit}
          notShowAttributesBtn
          notShowGroupBtn={this.props.notShowGroupBtn}
          modalControle={this.props.modalControle}
          buttonText={this.props.buttonText}
          isVisableBtn={this.props.isVisableDeviceBtn}
          btnCancelNotShow={this.props.btnCancelNotShow}
          canUpdate={checkPrivileges(this.props.isHaveAccess)}
          hasPremissions
          HaveGroupCreateAccess={checkPrivileges("groupCreate")}
          elevation={this.props.editOption !== undefined ? 0 : 2}
          showPortList={this.props.showPortList}
          showPort={this.props.showPort}
          btnCloseText={"close"}
          content={
            <form autoComplete="off">
              <div style={{ marginTop: 5 }}>
                <h4
                  className="all-attributes-heading"
                  style={{ margin: "5px 0", fontSize: 16 }}
                >
                  {this.props.translate("basic")}
                </h4>
                <Grid container>
                  <Grid item xl={6} md={6} xs={6} style={{ padding: "0 8px" }}>
                    <TextField
                      id="name"
                      label={this.props.translate("sharedName")}
                      type="text"
                      placeholder=""
                      required
                      value={this.props.form.name || ""}
                      onChange={this.props.handleChange("name")}
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  {this.props.editOption ? (
                    checkPrivileges("deviceModel") ? (
                      <Grid
                        item
                        xl={6}
                        md={6}
                        xs={6}
                        style={{ zIndex: 99, padding: "0 8px" }}
                      >
                        <SingleSelect
                          array={[]}
                          async
                          selectName="model"
                          isClearable
                          defaultOptions={defaultOptions}
                          loadOptions={this.searchFromList}
                          label={this.props.translate("deviceModel") + "*"}
                          value={
                            typeof this.props.form.model === "object"
                              ? this.props.form.model
                              : this.props.form.model
                              ? {
                                  id: this.props.form.model,
                                  key: this.props.form.model,
                                  label: this.props.form.model,
                                  value: this.props.form.model,
                                }
                              : ""
                          }
                          handleChange={this.props.port_handleChange}
                          canAssign
                        />
                      </Grid>
                    ) : null
                  ) : (
                    <Grid
                      item
                      xl={6}
                      md={6}
                      xs={6}
                      style={{ zIndex: 99, padding: "0 8px" }}
                    >
                      <SingleSelect
                        array={[]}
                        async
                        selectName="model"
                        isClearable
                        defaultOptions={defaultOptions}
                        loadOptions={this.searchFromList}
                        label={this.props.translate("deviceModel") + "*"}
                        value={
                          typeof this.props.form.model === "object"
                            ? this.props.form.model
                            : this.props.form.model
                            ? {
                                id: this.props.form.model,
                                key: this.props.form.model,
                                label: this.props.form.model,
                                value: this.props.form.model,
                              }
                            : ""
                        }
                        handleChange={this.props.port_handleChange}
                        canAssign
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xl={12}
                    md={12}
                    xs={12}
                    style={{ padding: "0 8px" }}
                  >
                    {this.props.form.model !== "FX Tracker" ? (
                      <TextField
                        id="uniqueId"
                        label={this.props.translate("deviceIdentifier")}
                        type="text"
                        placeholder=""
                        required
                        value={this.props.form.uniqueId || ""}
                        InputProps={
                          this.props.editOption
                            ? { readOnly: !checkPrivileges("deviceImei") }
                            : ""
                        }
                        onChange={this.props.handleChange("uniqueId")}
                        variant="outlined"
                        margin="dense"
                        // disabled={this.props.editOption && this.props.logInUser && this.props.logInUser.userType !== -1}
                        fullWidth
                      />
                    ) : (
                      <Tooltip title={this.props.translate("autoGeneratedId")}>
                        <TextField
                          id="uniqueId"
                          label={this.props.translate("deviceIdentifier")}
                          type="text"
                          placeholder=""
                          required
                          value={this.props.form.uniqueId || ""}
                          variant="outlined"
                          margin="dense"
                          disabled={
                            this.props.editOption ||
                            (this.props.logInUser &&
                              this.props.logInUser.userType !== -1)
                          }
                          fullWidth
                        />
                      </Tooltip>
                    )}
                  </Grid>
                  {this.props.addOption && (
                    <Grid item md={12} xs={12} style={{ padding: "0 8px" }}>
                      <p
                        style={{
                          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                          fontSize: "16",
                          background: this.props.themecolors.backgroundColor,
                          color: this.props.themecolors.textColor,
                          padding: "12px 33px",
                          marginTop: 0,
                          marginLeft: "25",
                        }}
                      >
                        <span>
                          {this.props.translate(
                            "toConfigureYourDeviceManuallyUseConnectionParameters"
                          )}
                        </span>
                        <span style={{ display: "block" }}>
                          <label
                            style={{ width: "40%", display: "inline-block" }}
                          >
                            {this.props.translate("serverIP")}:
                          </label>
                          <span>{this.props.serverIp}</span>
                        </span>
                        <span style={{ display: "block" }}>
                          <label
                            style={{ width: "40%", display: "inline-block" }}
                          >
                            {this.props.translate("Port")}:
                          </label>
                          <span>{this.props.form.device}</span>
                        </span>
                      </p>
                    </Grid>
                  )}
                </Grid>
              </div>

              <Grid container>
                <Grid item xl={6} md={6} xs={6} style={{ padding: "0 8px" }}>
                  <TextField
                    id="phone"
                    label={this.props.translate("simNumber")}
                    type="text"
                    placeholder=""
                    value={this.props.form.phone || ""}
                    onChange={this.props.handleChange("phone")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>

                {/* <---------- temprary comment --------------> */}
                {/* <Grid
                  item
                  xl={5}
                  md={5}
                  xs={12}
                  style={{ position: 'relative', zIndex: 3 }}
                >
                  <SearchGroups
                    isMulti={false}
                    api='groups'
                    name='groupId'
                    onChange={this.props.handleChange2}
                    value={
                      this.props.form.groupId
                        ? {
                            value: this.props.form.groupId,
                            label: this.props.group.find(
                              a => this.props.form.groupId === a.id
                            )
                              ? this.props.group.find(
                                  a => this.props.form.groupId === a.id
                                )['name']
                              : ''
                          }
                        : null
                    }
                    placeholder={this.props.translate('searchGroup')}
                  />
                </Grid> */}
                <Grid item xl={6} md={6} xs={6} style={{ padding: "0 8px" }}>
                  <TextField
                    id="contact"
                    label={this.props.translate("deviceContact")}
                    placeholder=""
                    value={this.props.form.contact || ""}
                    onChange={this.props.handleChange("contact")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                {this.props.addOption && (
                  <Grid item xl={6} md={6} xs={6} style={{ padding: "0 8px" }}>
                    <TextField
                      id="category"
                      select
                      label={this.props.translate("deviceCategory")}
                      value={this.props.form.category || ""}
                      onChange={this.props.handleChange("category")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="dense"
                      fullWidth
                    >
                      <MenuItem value={0}>
                        <em>None</em>
                      </MenuItem>
                      {categoriesList.map((option) => (
                        <MenuItem key={option} value={option}>
                          {this.props.translate(option)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
              </Grid>

              <h4
                className="all-attributes-heading"
                style={{ margin: "5px 0", fontSize: 16 }}
              >
                {this.props.translate("driver")}
              </h4>
              <Grid container>
                <Grid item xl={6} md={6} xs={12} style={{ padding: "0 8px" }}>
                  <TextField
                    id="driverName"
                    label={this.props.translate("driverName")}
                    placeholder=""
                    value={
                      this.props.form.attributes &&
                      this.props.form.attributes.driverName
                        ? this.props.form.attributes.driverName
                        : ""
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length <= 60) {
                        this.props.changeAttribute2("driverName", e);
                      }
                    }}
                    // onChange={e =>
                    //   this.props.changeAttribute2('driverName', e)
                    // }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item xl={6} md={6} xs={12} style={{ padding: "0 8px" }}>
                  <TextField
                    id="driverPhoneNumber"
                    label={this.props.translate("driverPhoneNumber")}
                    placeholder=""
                    value={
                      (this.props.form.attributes &&
                        this.props.form.attributes.driverPhoneNumber) ||
                      ""
                    }
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length <= 15) {
                        this.props.changeAttribute2("driverPhoneNumber", e);
                      }
                    }}
                    // onChange={e => this.props.changeAttribute2('driverPhoneNumber', e)}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item xl={6} md={6} xs={12} style={{ padding: "0 8px" }}>
                  <TextField
                    id="code"
                    label={this.props.translate("driverCode")}
                    placeholder=""
                    value={
                      (this.props.form.attributes &&
                        this.props.form.attributes.code) ||
                      ""
                    }
                    // onChange={e => this.props.changeAttribute2('code', e)}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length <= 15) {
                        this.props.changeAttribute2("code", e);
                      }
                    }}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                  {this.state.codeError && <p>{this.state.codeError}</p>}
                </Grid>
              </Grid>

              <h4
                className="all-attributes-heading"
                style={{ margin: "5px 0", fontSize: 16 }}
              >
                {this.props.translate("icons")}
              </h4>
              <div style={{ padding: "0 5px" }}>
                <Grid
                  container
                  spacing={1}
                  // className="grid-bb"
                  style={{ padding: "0 8px" }}
                >
                  <Scrollbar style={{ height: 110 }}>
                    <ul className="units-category-list">
                      {images.map((item, i) => {
                        return (
                          <>
                            <Grid
                              style={{ cursor: "pointer", margin: "2px" }}
                              item
                              key={i}
                              onClick={(e) => this.onChange(item)}
                            >
                              <Tooltip title={this.props.translate(item.name)}>
                                <Paper
                                  style={{
                                    textAlign: "center",
                                    alignItems: "center",
                                  }}
                                  className={
                                    item.name ===
                                    (this.state.category.name || "default")
                                      ? "unit-image-active"
                                      : "unit-image"
                                  }
                                >
                                  <Avatar
                                    alt=""
                                    src={
                                      "/assets/category/default/" +
                                      item.name +
                                      ".svg"
                                    }
                                    style={{
                                      // padding: 10,
                                      margin: "2px",
                                      height: 55,
                                      width: 55,
                                    }}
                                  />
                                </Paper>
                              </Tooltip>
                            </Grid>
                          </>
                        );
                      })}
                    </ul>
                  </Scrollbar>
                </Grid>
              </div>
              {/* <hr className="hr-divider" /> */}

              {/* <h4 style={{ margin: '10px 0' }}>
                <SendIcon width={14} height={14} style={{marginRight: 10, verticalAlign: 'middle'}} />
                {this.props.translate('sharedAttributes')}
              </h4>
              <Grid container >
                <Grid item xl={12} md={12} xs={12}>
                  <TextField
                    id='attributeDevicePassword'
                    label={this.props.translate('attributeDevicePassword')}
                    placeholder=''
                    value={
                      this.props.form.attributes &&
                      this.props.form.attributes.devicePassword
                        ? this.props.form.attributes.devicePassword
                        : ''
                    }
                    onChange={e =>
                      this.props.changeAttribute2('devicePassword', e)
                    }
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
                <Grid item xl={12} md={12} xs={12}>
                  <SingleSelect
                    array={[]}
                    async
                    selectName='decoder.timezone'
                    isClearable={false}
                    defaultOptions={defaultZones}
                    loadOptions={this.searchFromZoneList}
                    label={this.props.translate('sharedTimezone')}
                    value={this.props.form.attributes && this.props.form.attributes['decoder.timezone'] ? timeZonesTypes.find( t => t.key === this.props.form.attributes['decoder.timezone']): ''}
                    handleChange={this.props.timezone_handleChange}
                    canAssign
                  />
                </Grid>
                <Grid item xl={12} md={12} xs={12}>
                  <TextField
                    id='attributeSpeedLimit'
                    label={
                      this.props.translate('attributeSpeedLimit') + '(Km/h)'
                    }
                    placeholder=''
                    value={
                      (this.props.form.attributes &&
                        this.props.form.attributes.speedLimit) ||
                      ''
                    }
                    onChange={e => this.props.changeAttribute2('speedLimit', e)}
                    variant='outlined'
                    margin='dense'
                    fullWidth
                  />
                </Grid>
              </Grid> */}
            </form>
          }
        />
      </Fragment>
    );
  }
}

const AddDevice = withStyles(styles);
export default AddDevice(withTranslationWrapper(addDevice));
