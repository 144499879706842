import React, { Component } from "react";
import SimpleModalWrapped from "../common/Modal";
import Grid from "@mui/material/Grid";
import TextField from "../common/TextField";
import Tooltip from "../common/Tooltip";
import { withStyles } from "@mui/styles";
import Attributes from "../Attributes/attributesModal";
import { connect } from "react-redux";
import withTranslationWrapper from "../../HOC/withTranslation";

const mapStateToProps = (state, ownProps) => {
  return {
    logInUsers: state.logInUsers,
    themecolors: state.themeColors,
  };
};

const styles = (theme) => ({});

class addTrailer extends Component {
  constructor() {
    super();
    this.state = {
      attm_isVisable: false,
    };
    this.addAttributes = this.addAttributes.bind(this);
  }

  addAttributes() {
    this.setState({
      attm_isVisable: !this.state.attm_isVisable,
    });
  }

  render() {
    const { selectedTrailers } = this.props;

    return (
      <div style={{ height: "100%" }}>
        <SimpleModalWrapped
          visable
          isButtonVisable
          title="sharedTrailer"
          formSubmit={this.props.formSubmit}
          addAttributes={this.addAttributes}
          modalControle={this.props.onClose}
          buttonText={this.props.buttonText}
          btnCloseText="sharedBack"
          isVisableBtn={this.props.isVisibleBtn}
          selectedItemId={selectedTrailers.uniqueId}
          attributeChangesMessage={this.props.attributeChangesMessage}
          content={
            <form autoComplete="off">
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id="name"
                    label={this.props.translate("trailersName")}
                    required
                    placeholder=""
                    value={this.props.form.name}
                    onChange={this.props.handleChange("name")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id="uniqueId"
                    label={this.props.translate("trailerUniqueId")}
                    type="text"
                    required
                    placeholder=""
                    value={this.props.form.uniqueId}
                    onChange={this.props.handleChange("uniqueId")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Grid item sm={6} xs={12}>
                  <TextField
                    id="department"
                    label={this.props.translate("sharedDescription")}
                    type="text"
                    placeholder=""
                    value={this.props.form.department}
                    onChange={this.props.handleChange("department")}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <input
                    type="file"
                    name="pic"
                    accept="image/*"
                    onChange={(e) => this.props.uploadImage(e)}
                  />
                  {this.props.form.trailerImg && (
                    <Tooltip
                      placement="right-end"
                      title={this.props.translate("clickForPreview")}
                    >
                      <img
                        style={{ width: 120, height: "auto" }}
                        alt="trailerImage"
                        src={this.props.form.trailerImg}
                        onClick={this.props.imagePopup}
                      />
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </form>
          }
        />
        {this.state.attm_isVisable && (
          <Attributes
            onCloseModal={this.addAttributes}
            itemAttributes={this.props.itemAttributes}
            changeAttribute={this.props.changeAttribute}
            formSubmit={this.ud_submit}
            componentType="trailer"
            isHaveAccess={this.props.isHaveAccess}
          />
        )}
      </div>
    );
  }
}

const AddTrailer = withStyles(styles);
export default connect(mapStateToProps)(
  AddTrailer(withTranslationWrapper(addTrailer))
);
