import React, { Component } from "react";
import { checkPrivileges } from "../../Helpers";
import TextField from "../../Components/common/TextField";
import Checkbox from "../../Components/common/Checkbox";
import Button from "../../Components/common/Button";
import Switch from "../../Components/common/Switch";
import SingleSelect from "../../Components/common/SingleSelect";
import {
  Collapse,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Tooltip,
} from "@mui/material";
import CustomDatePicker from "../../Components/common/CustomDatePicker"
import DateFnsUtils from "@date-io/date-fns";
import SearchDevices from "../../Components/Devices/searchItems";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { SettingForm } from "./SettingForm";

export const CreateMaintenanceForm = ({
  form,
  isVisible,
  handleChange,
  onChangedDevice,
  vehicleTracker,
  selectedUnit,
  translate,
  handleChangeAttributes,
  submitForm,
  imagePopup,
  closeCreateFrom,
  dispatch,
  mode,
  assignModal,
  saveToParent,
  themecolors,
  servicesAttributes,
  logInUser,
  uploadImage,
  subTypes,
}) => {
  const periodLabel =
    form.type === "hours" ? "hours" : form.type === "date" ? "days" : "km";
  const paperStyle = {
    background: "none",
    marginBottom: 5,
    paddingTop: 0,
    paddingBottom: 0,
    width: "100%",
  };
  return (
    <div>
      <div
        className="page-title"
        style={{
          display: "flex",
          paddingBottom: 10,
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <h4 style={{ margin: 0 }}>{translate("sharedMaintenance")}</h4>
        <div>
          <Button
            size="small"
            onClick={() => closeCreateFrom("showMaintenance")}
          >
            {translate("sharedBack")}{" "}
          </Button>
        </div>
      </div>

      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
          style={{ postion: "relative", zIndex: 92 }}
        >
          <SearchDevices
            isMulti={false}
            api="devices"
            isClearable
            onChange={onChangedDevice}
            value={vehicleTracker || selectedUnit}
            placeholder={translate("searchUnits")}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="name"
            margin="dense"
            label={translate("sharedName")}
            variant="outlined"
            fullWidth
            value={form.name}
            onChange={(e) => handleChange("name", e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            id="cost"
            margin="dense"
            label={translate("cost")}
            variant="outlined"
            fullWidth
            value={form.cost}
            onChange={(e) => handleChange("cost", e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start" disableTypography>
                  {(logInUser.attributes &&
                    logInUser.attributes.currencyLabel) ||
                    "AED"}
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SingleSelect
            array={servicesAttributes ? servicesAttributes : []}
            id="serviceTypeId"
            selectName="serviceTypeId"
            label={translate("sharedType")}
            value={
              form.attributes && form.attributes.serviceTypeId
                ? {
                    key: form.attributes.serviceTypeId.key,
                    id: form.attributes.serviceTypeId.id,
                    value: form.attributes.serviceTypeId.id,
                    label: form.attributes.serviceTypeId.name
                      ? form.attributes.serviceTypeId.name
                      : form.attributes.serviceTypeId.name,
                  }
                : ""
            }
            handleChange={handleChangeAttributes}
            canRemove={true}
            canAssign={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <SingleSelect
            id="serviceExpenseTypeId"
            array={subTypes || []}
            selectName="serviceExpenseTypeId"
            label={translate("subType")}
            value={
              form.attributes && form.attributes.serviceExpenseTypeId
                ? {
                    key: form.attributes.serviceExpenseTypeId.key,
                    id: form.attributes.serviceExpenseTypeId.id,
                    value: form.attributes.serviceExpenseTypeId.id,
                    label: form.attributes.serviceExpenseTypeId.name
                      ? form.attributes.serviceExpenseTypeId.name
                      : form.attributes.serviceExpenseTypeId.label,
                  }
                : ""
            }
            handleChange={handleChangeAttributes}
            canRemove={true}
            canAssign={true}
          />
        </Grid>
        {(vehicleTracker || selectedUnit) && (
          <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <div
                style={{
                  display: "flex",
                  color: "orange",
                  justifyContent: "space-between",
                }}
              >
                <Checkbox
                  checked={
                    form &&
                    form.attributes &&
                    form &&
                    form.attributes &&
                    form.attributes.repeat
                  }
                  onChange={(e) =>
                    handleChangeAttributes("repeat", e.target.checked)
                  }
                  color="primary"
                  label={translate("repeat")}
                />
                <Tooltip
                  classes={{
                    popper: "menu-popper",
                    tooltip: "menu-popper-tooltip",
                  }}
                  title={translate("maintenanceRepeatTooltip")}
                >
                  <HelpOutlineIcon
                    style={{ fontSize: 15, marginTop: 3, marginRight: 15 }}
                  />
                </Tooltip>
              </div>
            </Grid>
            <Grid
              item
              className={
                form.attributes && form.attributes.byMileage ? "opened " : ""
              }
              xs={12}
            >
              <div style={{ display: "flex" }}>
                <h4 style={{ margin: 0, paddingTop: 8 }}>
                  {translate("deviceMileage")}
                </h4>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        form &&
                        form.attributes &&
                        form.attributes.byMileage === true
                      }
                      onChange={(e) =>
                        handleChangeAttributes(
                          "byMileage",
                          true,
                          e.target.checked
                        )
                      }
                      name="totalDistance"
                    />
                  }
                  label={
                    form &&
                    form.attributes &&
                    form.attributes.byMileage === true
                      ? translate("enable")
                      : translate("disable")
                  }
                  labelPlacement="start"
                  classes={{ label: "color-inherit" }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Collapse
                in={
                  form && form.attributes && form.attributes.byMileage === true
                }
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      id="start"
                      type="number"
                      margin="dense"
                      label={translate(
                        form && form.attributes && form.attributes.repeat
                          ? "maintenanceStart"
                          : "targetValue"
                      )}
                      variant="outlined"
                      fullWidth
                      value={
                        (form && form.attributes && form.attributes.mileage) ||
                        ""
                      }
                      onChange={(e) =>
                        handleChangeAttributes("mileage", e.target.value)
                      }
                      InputProps={{
                        readOnly:
                          form && form.attributes && form.attributes.repeat
                            ? true
                            : false,
                        endAdornment: (
                          <InputAdornment position="start" disableTypography>
                            {"(km)"}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {form.attributes && form.attributes.repeat ? (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        id="periodTotalDistance"
                        type="number"
                        margin="dense"
                        label={translate("maintenancePeriod") + " (km)"}
                        variant="outlined"
                        fullWidth
                        value={form.attributes.mileageInterval || ""}
                        onChange={(e) =>
                          handleChangeAttributes(
                            "mileageInterval",
                            e.target.value
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start" disableTypography>
                              {"(km)"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  ) : null}
                  <Grid item style={{ marginLeft: 5 }}>
                    <div
                      style={{
                        paddingTop: 10,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Checkbox
                        checked={
                          form &&
                          form.attributes &&
                          form.attributes.reminderByMileage
                        }
                        onChange={(e) =>
                          handleChangeAttributes(
                            "reminderByMileage",
                            e.target.checked
                          )
                        }
                        color="primary"
                        label={translate("reminder")}
                      />
                      <Tooltip
                        classes={{
                          popper: "menu-popper",
                          tooltip: "menu-popper-tooltip",
                        }}
                        title={translate("checkRemindMeBefore")}
                      >
                        <HelpOutlineIcon
                          style={{
                            fontSize: 15,
                            marginTop: 3,
                            marginLeft: 15,
                            marginRight: 10,
                          }}
                        />
                      </Tooltip>
                    </div>
                  </Grid>
                  {form &&
                  form.attributes &&
                  form.attributes.reminderByMileage ? (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        id="maxLimit"
                        label={translate("remindMeBefore") + " "}
                        required
                        placeholder=""
                        type="number"
                        value={
                          (form &&
                            form.attributes &&
                            form.attributes.reminderBeforeMileage) ||
                          ""
                        }
                        onChange={(e) =>
                          handleChangeAttributes(
                            "reminderBeforeMileage",
                            e.target.value
                          )
                        }
                        error={
                          // (form.attributes.mileageInterval) < (form.attributes.reminderBeforeMileage)
                          //  ? true
                          //   : false
                          form.attributes.mileageInterval &&
                          form.attributes.reminderBeforeMileage &&
                          form.attributes.repeat
                            ? form.attributes.mileageInterval -
                                form.attributes.reminderBeforeMileage >=
                              0
                              ? false
                              : true
                            : false
                        }
                        helperText={
                          form && form.attributes && form.attributes.repeat ? (
                            <p
                              style={{
                                fontSize: 9,
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              {translate("enterPeriodValueKm")}
                            </p>
                          ) : (
                            ""
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start" disableTypography>
                              {"(km)"}
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Collapse>
            </Grid>
            <Grid
              item
              xs={12}
              className={
                form.attributes && form.attributes.byHours ? "opened " : ""
              }
            >
              <div style={{ display: "flex" }}>
                <h4 style={{ margin: 0, paddingTop: 8 }}>
                  {translate("reportEngineHours")}
                </h4>
                <FormControlLabel
                  control={
                    <Switch
                      checked={
                        form &&
                        form.attributes &&
                        form.attributes.byHours === true
                      }
                      onChange={(e) =>
                        handleChangeAttributes(
                          "byHours",
                          true,
                          e.target.checked
                        )
                      }
                      name="hours"
                    />
                  }
                  label={
                    form && form.attributes && form.attributes.byHours === true
                      ? translate("enable")
                      : translate("disable")
                  }
                  labelPlacement="start"
                  classes={{ label: "color-inherit" }}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <Collapse
                in={form && form.attributes && form.attributes.byHours === true}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      id="start"
                      type="number"
                      margin="dense"
                      label={translate(
                        form && form.attributes && form.attributes.repeat
                          ? "maintenanceStart"
                          : "targetValue"
                      )}
                      variant="outlined"
                      fullWidth
                      value={
                        (form && form.attributes && form.attributes.hours) || ""
                      }
                      onChange={(e) =>
                        handleChangeAttributes("hours", e.target.value)
                      }
                      InputProps={{
                        readOnly:
                          form && form.attributes && form.attributes.repeat
                            ? true
                            : false,
                        endAdornment: (
                          <InputAdornment position="start" disableTypography>
                            {"(hours)"}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  {form && form.attributes && form.attributes.repeat ? (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        id="hoursInterval"
                        type="number"
                        margin="dense"
                        label={translate("maintenancePeriod") + " (hour)"}
                        variant="outlined"
                        fullWidth
                        value={
                          (form &&
                            form.attributes &&
                            form.attributes.hoursInterval) ||
                          ""
                        }
                        onChange={(e) =>
                          handleChangeAttributes(
                            "hoursInterval",
                            e.target.value
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start" disableTypography>
                              {"(hours)"}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  ) : null}
                  <Grid item style={{ marginLeft: 5 }}>
                    <div
                      style={{
                        paddingTop: 10,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Checkbox
                        checked={
                          form &&
                          form.attributes &&
                          form.attributes.reminderByHours
                        }
                        onChange={(e) =>
                          handleChangeAttributes(
                            "reminderByHours",
                            e.target.checked
                          )
                        }
                        color="primary"
                        label={translate("reminder")}
                      />
                      <Tooltip
                        classes={{
                          popper: "menu-popper",
                          tooltip: "menu-popper-tooltip",
                        }}
                        title={translate("checkRemindMeBefore")}
                      >
                        <HelpOutlineIcon
                          style={{
                            fontSize: 15,
                            marginTop: 3,
                            marginLeft: 15,
                            marginRight: 10,
                          }}
                        />
                      </Tooltip>
                    </div>
                  </Grid>
                  {form &&
                  form.attributes &&
                  form.attributes.reminderByHours ? (
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        id="maxLimit"
                        label={translate("remindMeBefore") + " "}
                        required
                        placeholder=""
                        type="number"
                        value={
                          (form.attributes &&
                            form.attributes.reminderBeforeHours) ||
                          ""
                        }
                        onChange={(e) =>
                          handleChangeAttributes(
                            "reminderBeforeHours",
                            e.target.value
                          )
                        }
                        error={
                          form.attributes.hoursInterval &&
                          form.attributes.reminderBeforeHours &&
                          form.attributes.repeat
                            ? form.attributes.hoursInterval -
                                form.attributes.reminderBeforeHours >=
                              0
                              ? false
                              : true
                            : false
                        }
                        helperText={
                          form && form.attributes && form.attributes.repeat ? (
                            <p
                              style={{
                                fontSize: 9,
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              {translate("enterPeriodValueHour")}
                            </p>
                          ) : (
                            ""
                          )
                        }
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start" disableTypography>
                              {"(hours)"}
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Collapse>
            </Grid>

            <Grid
              container
              spacing={1}
              className={
                form.attributes && form.attributes.byDays ? "opened " : ""
              }
            >
              <Grid item xs={12}>
                <div style={{ display: "flex" }}>
                  <h4 style={{ margin: 0, paddingTop: 8 }}>
                    {translate("date")}
                  </h4>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={
                          form &&
                          form.attributes &&
                          form.attributes.byDays === true
                        }
                        onChange={(e) =>
                          handleChangeAttributes(
                            "byDays",
                            true,
                            e.target.checked
                          )
                        }
                        name="date"
                      />
                    }
                    label={
                      form && form.attributes && form.attributes.byDays === true
                        ? translate("enable")
                        : translate("disable")
                    }
                    labelPlacement="start"
                    classes={{ label: "color-inherit" }}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <Collapse
                  in={
                    form && form.attributes && form.attributes.byDays === true
                  }
                >
                  <Grid container spacing={1}>
                    {/* <Grid item xs={12} sm={6} md={4} lg={3}>
                    <TextField
                      id='start'
                      type='date'
                      margin='dense'
                      label={translate('maintenanceStart')}
                      variant='outlined'
                      fullWidth
                      value={form.attributes && form.attributes.date
                        ? new Date(`${form.attributes.date}`).toString()
                        : null || ''}
                      onChange={e => handleChange('date', e.target.value)}
                    />
                  </Grid> */}

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                    <CustomDatePicker
                        format="dd/MM/yyyy"
                        margin="dense"
                        label={translate(form&&form.attributes&&form.attributes.repeat?'startDate':'targetValue')}
                        variant="outlined"
                        fullWidth
                        // minDate={new Date()}
                        value={
                          form?.attributes?.date
                            ? 
                                `${form.attributes.date}`
                            : null
                        }
                        onChange={e => handleChangeAttributes('date', e)}
                      />
                    </Grid>

                    {form && form.attributes && form.attributes.repeat ? (
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="daysInterval"
                          type="number"
                          margin="dense"
                          label={translate("maintenancePeriod") + " (days)"}
                          variant="outlined"
                          fullWidth
                          value={
                            (form &&
                              form.attributes &&
                              form.attributes.daysInterval) ||
                            ""
                          }
                          onChange={(e) =>
                            handleChangeAttributes(
                              "daysInterval",
                              e.target.value
                            )
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="start"
                                disableTypography
                              >
                                {"(days)"}
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                    ) : null}
                    <Grid item style={{ marginLeft: 5 }}>
                      <div
                        style={{
                          paddingTop: 10,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Checkbox
                          checked={
                            form &&
                            form.attributes &&
                            form.attributes.reminderByDays
                          }
                          onChange={(e) =>
                            handleChangeAttributes(
                              "reminderByDays",
                              e.target.checked
                            )
                          }
                          color="primary"
                          label={translate("reminder")}
                        />
                        <Tooltip
                          classes={{
                            popper: "menu-popper",
                            tooltip: "menu-popper-tooltip",
                          }}
                          title={translate("checkRemindMeBefore")}
                        >
                          <HelpOutlineIcon
                            style={{
                              fontSize: 15,
                              marginTop: 3,
                              marginLeft: 15,
                              marginRight: 10,
                            }}
                          />
                        </Tooltip>
                      </div>
                    </Grid>
                    {form &&
                    form.attributes &&
                    form.attributes.reminderByDays ? (
                      <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                          id="maxLimit"
                          label={translate("remindMeBefore") + " "}
                          required
                          placeholder=""
                          type="number"
                          value={
                            (form &&
                              form.attributes &&
                              form.attributes &&
                              form.attributes.reminderBeforeDays) ||
                            ""
                          }
                          onChange={(e) =>
                            handleChangeAttributes(
                              "reminderBeforeDays",
                              e.target.value
                            )
                          }
                          error={
                            form &&
                            form.attributes &&
                            form.attributes.daysInterval &&
                            form.attributes.reminderBeforeDays &&
                            form.attributes.repeat
                              ? (form &&
                                  form.attributes &&
                                  form.attributes.daysInterval -
                                    form.attributes.reminderBeforeDays) >= 0
                                ? false
                                : true
                              : false
                          }
                          helperText={
                            form &&
                            form.attributes &&
                            form.attributes.repeat ? (
                              <p
                                style={{
                                  fontSize: 9,
                                  marginTop: 0,
                                  marginBottom: 0,
                                }}
                              >
                                {translate("enterPeriodValueDay")}
                              </p>
                            ) : (
                              ""
                            )
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="start"
                                disableTypography
                              >
                                {"(days)"}
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        />
                      </Grid>
                    ) : null}
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
          </>
        )}
        {/* ----------------- temprary comment----------------- */}
        {/* {mode !== 'create' && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                {form.receiptUrl && (
                      <Tooltip
                        placement='right-end'
                        title={translate('clickForPreview')}
                      >
                        <img
                          style={{ width: 120, height: 'auto' }}
                          alt='Document Image'
                          src={form.receiptUrl}
                          onClick={imagePopup}
                        />
                      </Tooltip>
                    )}
              <Tooltip title='Upload Image'>
                      <Button
                        style={{ float: 'right', maxWidth: '40px',minWidth: '40px' }}
                        variant='contained'
                        margin='dense'
                        component="label"
                        onChange={e => uploadImage(e,'create')}
                      >
                        <PublishIcon  style={{ color: '#fff'}}/>
                        <input
                          type="file"
                          hidden
                        />
                      </Button>
                    </Tooltip>
            </Grid>
            )} */}
        <Grid container>
          {/* <Grid item xs={12} sm={6} md={4} lg={3} style={{marginLeft: 5}}>
                <div style={{paddingTop:10,display:'flex', justifyContent:'space-between'}}>
                  <Checkbox
                    checked={form&&form.attributes&&form.attributes.repeat}
                    onChange={e => handleChangeAttributes('repeat', e.target.checked)}
                    color='primary'
                    label={translate('repeat')}
                  />
                  <Tooltip 
                    classes={{
                      popper: 'menu-popper',
                      tooltip: 'menu-popper-tooltip'
                    }}
                    title={translate('maintenanceRepeatTooltip')}>
                    <HelpOutlineIcon style={{fontSize:15, marginTop:3, marginRight:15}}/>
                  </Tooltip>
                </div>
              </Grid> */}
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <div
              style={{
                paddingTop: 10,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Checkbox
                checked={
                  form && form.attributes && form.attributes.userInputRequired
                }
                onChange={(e) =>
                  handleChangeAttributes("userInputRequired", e.target.checked)
                }
                color="primary"
                label={translate("userInputRequired")}
              />
              <Tooltip
                classes={{
                  popper: "menu-popper",
                  tooltip: "menu-popper-tooltip",
                }}
                title={translate("maintenanceUserInputRequiredTooltip")}
              >
                <HelpOutlineIcon
                  style={{ fontSize: 15, marginTop: 3, marginRight: 15 }}
                />
              </Tooltip>
            </div>
          </Grid>
          {/* {mode !== 'create' && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <div style={{paddingTop:10,display:'flex', justifyContent:'space-between'}}></div>
                  <Checkbox
                    checked={form.attributes.status}
                    onChange={e =>
                      handleChangeAttributes('status', e.target.checked)
                    }
                    color='primary'
                    label={translate('status')}
                  />
                <div/>
              </Grid>
            )} */}
        </Grid>
        <Grid item xs={12}>
          <SettingForm
            form={form.attributes}
            mode={mode}
            saveToParent={saveToParent}
            dispatch={dispatch}
            translate={translate}
          />
        </Grid>
      </Grid>
      <div style={{ display: "flex", marginTop: 10 }}>
        {mode !== "create" ? (
          <>
            {/* <Button
                size='small'
                style={{ marginRight: 15 }}
                onClick={e => assignModal('unit', form)}
              >
                {translate('assignUnit')}{' '}
              </Button> */}
            <Button
              size="small"
              style={{ marginRight: 15 }}
              onClick={(e) => assignModal("user", form)}
            >
              {translate("assignUser")}{" "}
            </Button>
            {/* <Button
                size='small'
                style={{ marginRight: 15 }}
                onClick={e => assignModal('group', form)}
              >
                {translate('assignGroup')}{' '}
              </Button> */}
          </>
        ) : null}

        <div style={{ marginLeft: "auto" }} />
        {/* <Button
            size='small'
            style={{ marginRight: 15 }}
            className='button-white'
            onClick={()=>closeCreateFrom('showMaintenance')}
          >
            {translate('sharedCancel')}{' '}
          </Button> */}
        {checkPrivileges("maintenanceCreate") && (
          <Button size="small" onClick={submitForm} disabled={isVisible}>
            {translate(mode === "create" ? "sharedCreate" : "update")}
          </Button>
        )}
      </div>
    </div>
  );
};
