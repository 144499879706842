import React, { Component, Fragment } from "react";
import { EditForm } from "../../../Components/Geofence/editForm";
import Button from "../../../Components/common/Button";
import SearchField from "../../../Components/common/SearchField";
import { connect } from "react-redux";
import { addcalendars } from "../../../Actions/Devices";
import { geoFenceVisible, deleteGeofence } from "../../../Actions/Devices";
import isEqual from "react-fast-compare";
import Icon from "@mui/material/Icon";
import { FormControlLabel, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import {
  checkPrivileges,
  convertSpeedUnits,
  errorHandler,
} from "../../../Helpers";
import ConfirmDialoag from "../../../Components/common/ConfirmDialoag";
import Style from "style-it";
import Scrollbar from "react-scrollbars-custom";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../Loader";
import { CheckIcon } from "../../../Components/common/CheckIcon";
import { removedCommands } from "../../../Actions/Commands";
import {notifySuccess } from "../../../Utils/CustomNotifcations";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import instance from "../../../axios";
import { removedTypes } from "../../../Actions/Services";
import withTranslationWrapper from "../../../HOC/withTranslation";
class Commands extends Component {
  constructor(props) {
    super(props);
    this.state = { searchRecently: "" };
  }

  search(e) {
    this.props.searchItems(e.target.value);
  }

  render() {
    const { services } = this.props;
    let serviesList;
    const commands1 =
      services &&
      services.map((g) => (
        <ListRow
          key={g.id}
          item={g}
          editCommandForm={this.props.editCommandForm}
          modeEmpty={this.props.modeEmpty}
          onEditType={this.props.onEditType}
        />
      ));

    return (
      <Style>
        {`
					.material-icons {vertical-align: middle; color: inherit}
				`}
        <div className="fms-sidebar sidebar-bg-layer">
          {checkPrivileges("command") && (
            <div>
              <li
                className="filter-nav section-head-filter"
                style={{ position: "relative" }}
              >
                <div className="filter-row" style={{ flex: 1, marginTop: -2 }}>
                  <div style={{ flex: 1 }}>
                    <SearchField
                      label={this.props.translate("searchServices")}
                      fullWidth
                      onChange={(e) => this.search(e)}
                    />
                  </div>
                  {/* </li> */}
                  {/* <li style={{ paddingTop: 8, paddingBottom: 4 }}> */}
                  {checkPrivileges("expensetypeCreate") && (
                    <IconButton onClick={(e) => this.props.openCreateFrom()}>
                      <AddIcon />
                    </IconButton>
                  )}
                </div>
              </li>
              {/* </ul> */}
              {/* </div> */}
              <ul className="list-view with-padding-right geofence-sidebar-list">
                <Scrollbar
                  disableTracksWidthCompensation={true}
                  scrollerProps={{
                    renderer: (props) => {
                      const { elementRef, ...restProps } = props;
                      return (
                        <div
                          {...restProps}
                          ref={elementRef}
                          id="scrollableDiv"
                        />
                      );
                    },
                  }}
                >
                  {this.props.itemPagination &&
                  this.props.itemPagination.items ? (
                    <InfiniteScroll
                      dataLength={this.props.itemPagination.items.length}
                      next={this.props.fetchMoreItems}
                      hasMore={this.props.itemPagination.hasNext}
                      // loader={<Loader defaultStyle={true} imgSize={50} />}
                      scrollableTarget="scrollableDiv"
                      endMessage={this.props.endMessage}
                    >
                      {commands1}
                    </InfiniteScroll>
                  ) : null}
                </Scrollbar>
              </ul>
            </div>
          )}
        </div>
      </Style>
    );
  }
}

class ListRow1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onDeleteConfirmation: false,
    };
  }

  onDelete = () => {
    if (this.props.item.id) {
      // fetch(`api/commands/${this.props.item.id}`, {
      //   method: 'DELETE',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json'
      //   }
      // })
      instance({
        url: `api/expensetypes/${this.props.item.id}`,
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          // if (response.ok) {
          this.props.dispatch(removedTypes({ id: this.props.item.id }));
          this.props.dispatch(
            notifySuccess(this.props.translate("savedServiceIsDeleted"))
          );
          // this.props.modeEmpty()
          this.setState({ item: {} });
          // }
          // else{
          //   throw response
          // }
        })
        .catch((error) => {
          errorHandler(error, this.props.dispatch);
        });
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
  }

  onRemovedItem = () => {
    this.setState({
      onDeleteConfirmation: true,
    });
  };
  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };

  render() {
    return (
      <li>
        <div className="clearfix list-row" key={this.props.item.id}>
          <label
            className="checkbox"
            onClick={(e) => this.props.editCommandForm(this.props.item)}
          >
            <span className="unit-name" style={{ fontSize: 12 }}>
              {this.props.item.name}
            </span>
          </label>
          <div className="pull-right">
            {(checkPrivileges("garageDelete") || true) && (
              <span
                className="action-item hovered"
                onClick={this.onRemovedItem}
              >
                <DeleteIcon className="material-icons" />
              </span>
            )}
            {/* </div> */}
            {/* <div className='pull-right'> */}
            {(checkPrivileges("garageDelete") || true) && (
              <span
                className="action-item hovered"
                onClick={() => this.props.onEditType(this.props.item)}
              >
                <EditIcon className="material-icons" />
              </span>
            )}
          </div>

          {this.state.onDeleteConfirmation && (
            <ConfirmDialoag
              onCancel={this.onCancel}
              onOk={this.onDelete}
              title={this.props.translate("areYouWantToDelete")}
              children={this.props.item.description}
            />
          )}
        </div>
      </li>
    );
  }
}

const mapState = (state) => ({
  geoFence: state.geoFence,
  themecolors: state.themeColors,
});

const mapStateToProps = connect(mapState);
export const ListRow = mapStateToProps(withTranslationWrapper(ListRow1));
export default mapStateToProps(Commands);
