import React, { Component } from "react";
import {
  Collapse,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  RadioGroup,
  Tooltip,
} from "@mui/material";
import Switch from "../../Components/common/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import { ReactComponent as AddNewIcon } from "../../assets/add-new.svg";
import Radio from "../../Components/common/Radio";
import TextField from "../../Components/common/TextField";
import { checkPrivileges, selectAll, validEmailPattern } from "../../Helpers";
import { notifyError} from "../../Utils/CustomNotifcations";

class SettingForm1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: [""],
      emails: "",
      mailTo: "user",
      notificators: "web,firebase",
      // alertColor: "#ff0000",
      notifications: ["web", "firebase"],
    };
  }

  UNSAFE_componentWillReceiveProps(n) {
    if (
      this.props.maintenanceForm &&
      n.maintenanceForm.id !== this.props.maintenanceForm.id
    ) {
      if (n.mode === "create") {
        this.saveToParent();
      } else {
        this.setState(
          {
            notificators: n.form.notificators || this.state.notificators,
            // alertColor: n.form.alertColor || this.state.alertColor,
            mailTo: n.form.mailTo || this.state.mailTo,
            emails: n.form.emails || this.state.emails,
            email: n.form.emails ? n.form.emails.split(",") : this.state.emails,
            notifications: n.form.notificators
              ? n.form.notificators.split(",")
              : this.state.notifications,
          },
          () => this.saveToParent()
        );
      }
    }
  }

  componentDidMount() {
    if (this.props.mode === "create") {
      this.saveToParent();
    } else {
      this.setState(
        {
          notificators: this.props.form.notificators || this.state.notificators,
          // alertColor: this.props.form.alertColor || this.state.alertColor,
          mailTo: this.props.form.mailTo || this.state.mailTo,
          emails: this.props.form.emails || this.state.emails,
          email: this.props.form.emails
            ? this.props.form.emails.split(",")
            : this.state.emails,
          notifications: this.props.form.notificators
            ? this.props.form.notificators.split(",")
            : this.state.notifications,
        },
        () => this.saveToParent()
      );
    }
  }

  saveToParent = () => {
    const { email, mailTo, notifications /* , alertColor */ } = this.state;
    this.props.saveToParent({
      emails: email.toString(),
      mailTo /* , alertColor */,
      notificators: notifications.toString(),
    });
  };

  handleClick = (name, event) => {
    const n = [...this.state.notifications];
    this.setState(
      {
        notifications: event.target.checked
          ? n.concat(name)
          : n.filter((r) => r !== name),
      },
      () => this.saveToParent()
    );
  };

  handleChange = (name, event, index) => {
    if (name === "email") {
      const email = this.state.email.map((e, i) => {
        if (i === index) {
          return event.target.value;
        } else {
          return e;
        }
      });
      this.setState(
        {
          email,
        },
        () => this.saveToParent()
      );
    } else {
      if (name === "mailTo") {
        this.setState({ email: [""] });
      }
      this.setState({ [name]: event.target.value }, () => this.saveToParent());
    }
  };

  onAddAnotherMail = (type) => {
    let { email } = this.state;
    // frist check the formate of prevous emails
    let formateValidation = true;
    if (type === "email") {
      email.map((itam) => {
        if (!validEmailPattern.test(itam)) {
          formateValidation = false;
        }
        return null;
      });
      if (formateValidation) {
        email.push("");
        this.setState({
          email: email,
        });
      } else {
        this.props.dispatch(
          notifyError(this.props.translate("previousEmailInvalid"))
        );
      }
    }
  };

  deleteMail = (type, id) => {
    // frist check the formate of prevous emails
    let data = this.state[type];
    if (data.length === 1 && id === 1) {
      this.setState(
        {
          [type]: [""],
        },
        () => this.saveToParent()
      );
    } else {
      let newList = data.filter((itm, i) => i !== id);
      this.setState(
        {
          [type]: newList,
        },
        () => this.saveToParent()
      );
    }
  };

  /* 
    hideColorPicker = e => {
      this.setState({
        colorPicker: false
      })
    }
  
    showColorPicker = e => {
      this.setState({
        colorPicker: true
      })
    }
  
    updateColor = e => {
      this.setState({ alertColor: e.hex }, () => this.saveToParent())
    } */

  render() {
    const paperStyle = {
      background: "none",
      marginBottom: 5,
      paddingTop: 0,
      paddingBottom: 0,
    };
    return (
      <div>
        <h3>{this.props.translate("notificators")}</h3>
        <Grid container>
          <Grid sm={this.props.fullWidth ? 12 : 4} xs={12}>
            <Paper
              className={
                " " +
                (this.state.notifications.includes("mail") ? "opened " : "")
              }
              style={paperStyle}
              elevation={0}
            >
              <h4
                className="page-title-notification"
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: 0,
                  padding: 0,
                }}
              >
                <span>{this.props.translate("emails")}</span>
                <FormControlLabel
                  style={{ marginLeft: "auto" }}
                  control={
                    <Switch
                      checked={this.state.notifications.includes("mail")}
                      onChange={(e) => this.handleClick("mail", e)}
                      name="mail"
                    />
                  }
                  label={
                    this.state.notifications.includes("mail")
                      ? this.props.translate("enable")
                      : this.props.translate("disable")
                  }
                  labelPlacement="start"
                  classes={{ label: "color-inherit" }}
                />
              </h4>
              <Collapse in={this.state.notifications.includes("mail")}>
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl
                      component="fieldset"
                      style={{ width: "max-content" }}
                    >
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        value={this.state.mailTo}
                        onChange={(e) => this.handleChange("mailTo", e)}
                      >
                        <FormControlLabel
                          classes={{
                            label: "form-contol-label",
                          }}
                          value="user"
                          control={<Radio />}
                          label={this.props.translate("userEmail")}
                          labelPlacement="end"
                          disabled={!this.state.notifications.includes("mail")}
                        />
                        <FormControlLabel
                          classes={{
                            label: "form-contol-label",
                          }}
                          value="custom"
                          control={<Radio />}
                          label={this.props.translate("customEmails")}
                          labelPlacement="end"
                          disabled={!this.state.notifications.includes("mail")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  {this.state.mailTo !== "user" && (
                    <Grid item xs={12}>
                      {this.state.email.map((item, index) => {
                        return (
                          <div style={{ display: "flex" }} key={index}>
                            <TextField
                              id="email"
                              label={this.props.translate("userEmail")}
                              placeholder=""
                              value={item || ""}
                              onChange={(e) =>
                                this.handleChange("email", e, index)
                              }
                              disabled={
                                !this.state.notifications.includes("mail")
                              }
                              variant="outlined"
                              margin="dense"
                              fullWidth
                            />
                            {index + 1 === this.state.email.length && (
                              <Tooltip
                                title={this.props.translate("addAnother")}
                              >
                                <span>
                                  <IconButton
                                    color="inherit"
                                    aria-label="directions"
                                    onClick={(e) =>
                                      this.onAddAnotherMail("email")
                                    }
                                    disabled={
                                      !this.state.notifications.includes("mail")
                                    }
                                  >
                                    <AddNewIcon
                                      width={18}
                                      height={18}
                                      style={{ fill: "currentColor" }}
                                    />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            )}
                            {1 < this.state.email.length && (
                              <Tooltip title={this.props.translate("delete")}>
                                <span>
                                  <IconButton
                                    color="inherit"
                                    aria-label="directions"
                                    onClick={(e) =>
                                      this.deleteMail("email", index)
                                    }
                                    disabled={
                                      !this.state.notifications.includes("mail")
                                    }
                                  >
                                    <DeleteIcon
                                      width={18}
                                      height={18}
                                      style={{ fill: "currentColor" }}
                                    />
                                  </IconButton>
                                </span>
                              </Tooltip>
                            )}
                          </div>
                        );
                      })}
                    </Grid>
                  )}
                </Grid>
              </Collapse>
            </Paper>
            <Paper
              className={
                " " + (this.state.notifications.includes("web") ? "opened" : "")
              }
              style={paperStyle}
              elevation={0}
            >
              <h4
                className="page-title-notification"
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: 0,
                  padding: 0,
                }}
              >
                <span>{this.props.translate("webPopUp")}</span>
                <FormControlLabel
                  style={{ marginLeft: "auto" }}
                  control={
                    <Switch
                      checked={this.state.notifications.includes("web")}
                      onChange={(e) => this.handleClick("web", e)}
                      name="checkedA"
                    />
                  }
                  label={
                    this.state.notifications.includes("web")
                      ? this.props.translate("enable")
                      : this.props.translate("disable")
                  }
                  labelPlacement="start"
                  classes={{ label: "color-inherit" }}
                />
              </h4>
              {/* <Collapse in={this.state.notifications.includes('web')}>
                <Grid container>
                  <Grid item xs={12}>
                    <div style={{ marginBottom: 16 }}>
                      <label>{this.props.translate('selectColor')}</label>
                      <div
                        className='colorPicker-trigger'
                        style={{
                          background:
                            this.state.alertColor
                              ? this.state.alertColor
                              : 'red',
                          display: 'inline-block',
                          width: 18,
                          height: 18,
                          marginLeft: 10,
                          verticalAlign: 'middle',
                          position: 'relative',
                          zIndex: 9,
                          borderRadius: '50%',
                          pointerEvents: this.state.notifications.includes('web') ? 'auto' : 'none'
                        }}
                        onClick={this.showColorPicker}
                      >
                        {this.state.colorPicker && (
                          <div
                            style={{ position: 'absolute', top: 0, left: '-50%' }}
                            onMouseLeave={this.hideColorPicker}
                          >
                            <CompactPicker
                              color={this.state.alertColor}
                              onChangeComplete={this.updateColor}
                              //disabled={!this.state.notifications.includes('web')}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Collapse> */}
            </Paper>
            <Paper className="" style={paperStyle} elevation={0}>
              <h4
                className="page-title-notification"
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: 0,
                  padding: 0,
                }}
              >
                <span>{this.props.translate("mobileNotifications")}</span>
                <FormControlLabel
                  style={{ marginLeft: "auto" }}
                  control={
                    <Switch
                      checked={this.state.notifications.includes("firebase")}
                      onChange={(e) => this.handleClick("firebase", e)}
                      name="checkedA"
                    />
                  }
                  label={
                    this.state.notifications.includes("firebase")
                      ? this.props.translate("enable")
                      : this.props.translate("disable")
                  }
                  labelPlacement="start"
                  classes={{ label: "color-inherit" }}
                />
              </h4>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export const SettingForm = SettingForm1;
