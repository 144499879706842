import React, { Component } from "react";
import { connect } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import { Link } from "react-router-dom";
import { checkPrivileges } from "../../Helpers";
import { ReactComponent as Vehicle } from "../../assets/nav/commute.svg";

class vehicle extends React.PureComponent {
  render() {
    const list =
      this.props.vehicles && this.props.vehicles.length
        ? this.props.vehicles.filter((d) => {
            return (d.id + d.label + d.model + d.deviceId)
              .toLowerCase()
              .includes((this.props.search || "").toLowerCase());
          })
        : [];

    let data = "";
    const loading = [];
    if (list.length) {
      data = list.map((vehicle) => (
        <ListRow
          key={vehicle.id}
          item={vehicle}
          {...this.props}
          removedItem={this.props.removedItem}
          editItem={this.props.editItem}
          selecteItem={this.props.selecteItem}
          selectedItemId={this.props.selectedItemId}
        />
      ));
    } else {
      return (
        <p style={{ textAlign: "center", color: "#ccc", fontWeight: "bold" }}>
          {this.props.translate("notFound")}
        </p>
      );
    }
    return (
      <>
        {data && (
          <div className="individual-view">
            <ul className="list-view with-padding-right">{data}</ul>
          </div>
        )}
        {loading}
      </>
    );
  }
}

export class ListRows extends Component {
  render() {
    if (this.props.children.length) {
      return (
        <ul className="list-view">
          {this.props.children.map((data) => (
            <ListRow key={data.id} item={data} {...this.props} />
          ))}
        </ul>
      );
    } else {
      return "";
    }
  }
}
export class ListRow extends Component {
  render() {
    const device =
      (this.props.allVehicleLocation &&
        this.props.allVehicleLocation[this.props.item.deviceId]) ||
      null;
    return (
      <li
        className={
          "list-row " +
          (parseInt(this.props.match.params.id) === parseInt(this.props.item.id)
            ? "active"
            : "")
        }
      >
        <Link
          style={{ minWidth: 0 }}
          onClick={this.handleClick}
          to={"/vehicle/" + this.props.item.id}
        >
          <label
            style={{ display: "flex" }}
            className="checkbox"
            onClick={() =>
              this.props.item.id !== parseInt(this.props.match.params.id)
                ? this.props.selecteItem(this.props.item.id)
                : this.handleClick
            }
          >
            <span className="unit-name">
              <span
                style={{
                  marginRight: 10,
                }}
              >
                <Vehicle
                  width={24}
                  height={24}
                  style={{ display: "block", fill: "currentColor" }}
                />
              </span>
              <span className="unit-name-text">{this.props.item.label}</span>
            </span>
          </label>
        </Link>
        <div className="pull-right">
          {checkPrivileges("vehicleDelete") && (
            <span
              className="action-item hovered"
              title={this.props.translate("delete")}
              onClick={() => this.props.removedItem(this.props.item)}
            >
              <DeleteIcon style={{ fontSize: 16 }} />
            </span>
          )}
          {checkPrivileges("vehicleUpdate") && (
            <span
              className="action-item hovered"
              title={this.props.translate("edit")}
              onClick={() => this.props.editItem(this.props.item)}
            >
              <EditIcon style={{ fontSize: 16 }} />
            </span>
          )}

          {device ? (
            <span>
              <img
                width={24}
                height={24}
                style={{ display: "block" }}
                src={
                  "/assets/category/default/" +
                  (device.category || "default") +
                  ".svg"
                }
                alt=""
              />
            </span>
          ) : (
            <span className="action-item">
              <LinkOffIcon style={{ fontSize: 16 }} />
            </span>
          )}
        </div>
      </li>
    );
  }
}
export const VehicleShortList = vehicle;
