import React from "react";
import Tooltip from "@mui/material/Tooltip";

//import { NavLink } from 'react-router-dom';
import Style from "style-it";
import { NavLink } from "react-router-dom";
export default class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "/monitoring",
    };
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
    this.props.history.push(newValue);
    return false;
  };

  UNSAFE_componentWillMount() {
    if (this.props.location.pathname !== this.state.value) {
      this.setState({ value: this.props.location.pathname });
    }
  }

  collapseMenu = () => {
    if (document.body.offsetWidth < 959) {
      this.props.collapseMenu();
    }
  };

  render() {
    const currentUrl = window.location.href;

    return (
      <Style>
        {`
        .navbar-indicator {
          background-color: transparent
        }
        .navbar-selected {
          background-color: rgba(0,0,0,0.1);
          font-weight: bold
        }

    `}
        <div>
          <div className="navbar-menu">
            {Object.keys(this.props.items).map((name) => {
              let item = this.props.items[name];
              const { icon: ItemIcon } = item;
              let navlink = null;
              if (item.checked) {
                navlink = (
                  <Tooltip
                    key={name}
                    title={item.title}
                    placement="right"
                    classes={{
                      popper: "menu-popper",
                      tooltip: "menu-popper-tooltip",
                    }}
                  >
                    <NavLink
                      className="navbar-menu-item"
                      onClick={this.collapseMenu}
                      to={item.href}
                      style={{
                        position: "relative",
                        width:
                          currentUrl.includes(item.href) ||
                          (currentUrl.includes("notifications") &&
                            item.href === "/events")
                            ? "90px"
                            : "",
                        borderLeftColor:
                          currentUrl.includes(item.href) ||
                          (currentUrl.includes("notifications") &&
                            item.href === "/events")
                            ? this.props.themecolors.menuActiveBackground
                            : "#FFFFFF",
                      }}
                    >
                      <ItemIcon
                        fill={
                          currentUrl.includes(item.href) ||
                          (currentUrl.includes("notifications") &&
                            item.href === "/events")
                            ? this.props.themecolors.menuActiveBackground
                            : "currentColor"
                        }
                        width={18}
                        height={18}
                      />
                      <span
                        className="nav-link-text"
                        style={{
                          color:
                            currentUrl.includes(item.href) ||
                            (currentUrl.includes("notifications") &&
                              item.href === "/events")
                              ? this.props.themecolors.menuActiveBackground
                              : "currentColor",
                        }}
                      >
                        {item.title}
                      </span>
                      {/* {item.count !== undefined ? <span className="counter">{(item.count>99)?'99+':item.count}</span> : null}
                      <span className='nav-link-text'>{item.title}</span> */}
                    </NavLink>
                  </Tooltip>
                );
              }

              return navlink;
            })}
          </div>
        </div>
      </Style>
    );
  }
}
