import React, { Component } from "react";
import Sidebar from "./Sidebar";
import Loader from "./Loader";
import { connect } from "react-redux";
import {
  fetchDevices,
  fetchDevicesList,
  fetchMoreDevices,
  searchDevices,
} from "../Actions/Devices";
import { logInUserInfo, getRoles } from "./../Actions/Users";
import { ServerInfo } from "./../Actions/serverSetting";
import CommonSection from "../Helpers/commonSection";
import Style from "style-it";
import "./index.scss";
import Scrollbar from "react-scrollbars-custom";
import FeatureRow from "../Components/Features";
import {
  setCookie,
  checkedDevelopmentType,
  defaultDevicesLength,
  checkPrivileges,
} from "../Helpers";
import { getLayer } from "./../Actions/Maps";
import instance from "../axios";
import withTranslationWrapper from "../HOC/withTranslation";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      collapse_sidebar: "",
      collapse: false,
    };

    this.collapseSidebarClass = this.collapseSidebarClass.bind(this);
    this.collapseSidebar = this.collapseSidebar.bind(this);
    this.collapseSidebarWithButton = this.collapseSidebarWithButton.bind(this);
  }

  collapseSidebarClass(value) {
    this.setState({ collapse_sidebar: value || "" });
  }

  collapseSidebar() {
    const w = window.innerWidth;
    if (w <= 959) {
      const v = this.state.collapse;
      this.setState({ collapse: !v }, () => {
        if (!v) {
          this.collapseSidebarClass("sidebar-collapse");
        } else {
          this.collapseSidebarClass("");
        }
      });
    }
  }

  collapseSidebarWithButton() {
    const v = this.state.collapse;
    this.setState({ collapse: !v }, () => {
      if (!v) {
        this.collapseSidebarClass("sidebar-collapse");
      } else {
        this.collapseSidebarClass("");
      }
    });
  }

  componentWillUnmount() {
    this.props.dispatch(fetchMoreDevices(true));
    this.props.dispatch(searchDevices({ device: "" }));
  }

  componentWillMount() {
    this.props.dispatch(fetchMoreDevices(true, defaultDevicesLength));
    if (!this.props.logInUser.id) {
      const fetchUrl = checkedDevelopmentType();
      // fetch(`/api/session?app=3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05${fetchUrl}`,{
      //     headers: {
      //       Accept: 'application/json; odata=verbose'
      //     }
      //   })
      instance({
        url: `/api/session`,
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        params: {
          app: `3b8d4deb84c0d9b65070c872e2f73626f23d8dc8789a7a05`,
          // host: fetchUrl
        },
      })
        // .then(response => {
        //   if (response.ok) {
        //     response.json()
        .then((userInfoFront) => {
          if (userInfoFront.temporary) {
            this.handleLogout();
            return;
          }
          const userPrivileges = false; /* JSON.parse(
              localStorage.getItem('frontPrivileges')
            ) */
          // const lang = userInfoFront?.attributes?.lang  || 'en'
          // this.props.setActiveLanguage(lang)

          if (!userPrivileges) {
            // fetch(`/api/privileges?roleId=${userInfoFront.roleId}`, {
            //   method: 'Get'
            // })
            instance({
              url: `/api/privileges`,
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              params: {
                roleId: userInfoFront.roleId,
              },
            })
              // .then(response => {
              //   if (response.ok) {
              //     response.json()
              .then((privileges) => {
                const privilegeKeys = [];

                privileges.map((item) => {
                  privilegeKeys.push(item.key);
                  return false;
                });
                localStorage.setItem(
                  "frontPrivileges",
                  JSON.stringify({ ...privilegeKeys })
                );

                this.setState({ loading: false });
                this.props.dispatch(logInUserInfo(userInfoFront));
                this.props.dispatch(fetchDevicesList(userInfoFront));
                this.props.dispatch(fetchDevices(userInfoFront));

                if (userInfoFront.userType === -1) {
                  // fetch('/api/roles?all=true')
                  instance({
                    url: `/api/roles`,
                    method: "GET",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    params: {
                      all: true,
                    },
                  })
                    // .then(response => {
                    //   if (response.ok) {
                    //     response.json()
                    .then((role) => {
                      this.props.dispatch(getRoles(role));
                      //   })
                      // }
                      // else{
                      //   throw response
                      // }
                    })
                    .catch((error) => {
                      // errorHandler(error, this.props.dispatch)
                    });
                } else if (
                  userInfoFront.userType !== 1 &&
                  checkPrivileges("role")
                ) {
                  // fetch(`/api/roles?userId=${userInfoFront.id}`)
                  instance({
                    url: `/api/roles`,
                    method: "GET",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                    params: {
                      userId: userInfoFront.id,
                    },
                  })
                    // .then(
                    //   response => {
                    //     if (response.ok) {
                    //       response.json()
                    .then((role) => {
                      this.props.dispatch(getRoles(role));
                      //   })
                      // }
                      // else{
                      //   throw response
                      // }
                    })
                    .catch((error) => {
                      // errorHandler(error, this.props.dispatch)
                    });
                }

                //   })
                // }
                // else{
                //   throw response
                // }
              })
              .catch((error) => {
                // errorHandler(error, this.props.dispatch)
              });
          }
          if (userInfoFront) {
            // fetch(`/api/accounts?accountId=${userInfoFront.accountId}`)
            instance({
              url: `/api/accounts`,
              method: "GET",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              params: {
                accountId: userInfoFront.accountId,
              },
            })
              // .then(
              //   response => {
              //     if (response.ok) {
              //       response.json()
              .then((server) => {
                const domElements = document.getElementById("favicon");
                if (
                  server &&
                  server[0].attributes &&
                  server[0].attributes.favIcon &&
                  domElements
                ) {
                  domElements.setAttribute(
                    "href",
                    server[0].attributes.favIcon
                  );
                }
                this.props.dispatch(ServerInfo(server[0]));
                this.props.dispatch(
                  getLayer({
                    id: userInfoFront.map || server[0].map || "osm",
                  })
                );
                //   })
                // }
                // else{
                //   throw response
                // }
              })
              .catch((error) => {
                this.handleLogout();
              });
          }
          //   })
          // } else {
          //   setCookie('JSESSIONID', 0)

          //   this.props.history.push(
          //     '/logout',
          //     this.props.history.location.pathname
          //   )
          //     throw response
          //   }
        })
        .catch((error) => {
          this.handleLogout();
        });
    } else {
      this.setState({ loading: false });
    }
  }
  handleLogout = () => {
    setCookie("JSESSIONID", 0);
    this.props.history.push("/logout", this.props.history.location.pathname);
  };
  render() {
    let makeHead = `2px solid ${this.props.themecolors.tabBgColor}`;
    const currentUrl = window.location.href;

    if (this.props.noSidebar) {
      document.body.classList.add("nosidebar-mode");
    } else {
      document.body.classList.remove("nosidebar-mode");
    }
    if (this.props?.notificationSystem?.length > 2) {
      document.body.classList.add("shown-clearall-btn");
    } else {
      document.body.classList.remove("shown-clearall-btn");
    }
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.fontFamily
    ) {
      document.body.classList.remove("ff-1", "ff-2", "ff-3", "ff-4", "ff-5");
      document.body.classList.add(
        this.props.ServerSetting.attributes.fontFamily
      );
    } else {
    }

    if (!this.state.loading && this.props.ServerSetting) {
      return (
        <>
          <Style>
            {`
          .fms-sidebar .list-view-children .list-view-group > li > .list-row:first-child {
            background: none;           
          }
          .list-row {
            margin: 0 10px 0px 5px;
            cursor: pointer;
          }
          
          .list-row:hover {
            border: 1px solid ${this.props.themecolors.menuActiveBackground} !important;
            border-radius:8;
          }

         
          .unit-name .unit-img {
            background: ${this.props.themecolors.tabBgColor}; 
            color: ${this.props.themecolors.tabTextColor}; 
          }
          .fms-sidebar .list-view-children .list-view-group > li > .list-row:first-child .unit-name {
            font-weight: 700;
          }
          .fms-sidebar .list-view-children .list-view-group > li > .list-row:first-child:before {
            background: ${this.props.themecolors.textColor};
          }
          .fms-sidebar .list-view {
            color:  ${this.props.themecolors.textColor}
          }
          .device-status-box {
            border-color: ${this.props.themecolors.textColor}33
          }
          .device-status-box em {
            color:  ${this.props.themecolors.textColor}
          }
           
          .trip-time, .trip-distance {
            border-right: 1px solid ${this.props.themecolors.themeLightColor}80 
          }
          .page-title {
            border-bottom: 1px solid ${this.props.themecolors.InputBorderColor} 
          }
          .daterangepicker .ranges li:hover {
            background: ${this.props.themecolors.themeLightColor};
            color: ${this.props.themecolors.themeLightInverse};
          }
          .daterangepicker {
            background: ${this.props.themecolors.backgroundColor}; 
            color: ${this.props.themecolors.textColor} 
            border-color: ${this.props.themecolors.InputBorderColor} 
          }

          .dnd-ebuilder-wrapper {
            background: ${this.props.themecolors.tabBgColor};
            color: ${this.props.themecolors.tabTextColor};
          }
           
          .list-footer-row,
          .trip-date-row {
              background: ${this.props.themecolors.backgroundColor};
              border: 1px solid ${this.props.themecolors.tableBorderColor};
          }
          .trip-date-row {
            border-bottom: 0;
          }
          .list-footer-row {
            border-top: 0;
          }
          .list-view-group .list-row {
            background: ${this.props.themecolors.themeListingColor}
          }
          .list-view-group .list-row.opened .action-related-info-value,
          .list-view-group .list-row.opened .unit-status-text {
            color: inherit;
          }
          .list-view-group .list-row.opened {
            background: ${this.props.themecolors.tabTextColor};
            color: ${this.props.themecolors.tabBgColor}
          }
          .tracks-list-row-wrap {
            border-right: 1px solid ${this.props.themecolors.tableBorderColor};
            border-left: 1px solid ${this.props.themecolors.tableBorderColor};
          }
          .tracks-list-row-wrap ~ .tracks-list-row-wrap .tracks-list-row {
            border-top: 1px solid ${this.props.themecolors.tableBorderColor};
          }
          .list-view a.list-row {
            color: ${this.props.themecolors.themeListingInverse};
          }
          .droppable-element,
          .list-row,.list-row-1 {
            color: ${this.props.themecolors.themeListingInverse};
            box-shadow: 0 4px 8px rgba(0,0,0,0.08);
          }
          .tab-title,
          .list-view a.list-row:hover,
          .list-row:hover,.list-row-1:hover,
          .group-list-row:hover,
          a.list-row.active,
          .list-row.active {
          }


          .category-item {
            background: ${this.props.themecolors.themeLightColor}33;
            color: ${this.props.themecolors.themeLightInverse};
          }
          .main-thumb-view .list-view.list-view-children .checkbox .v-checkbox {
            background: ${this.props.themecolors.themeDarkColor};
          }
          .main-thumb-view .group-list-row:hover,
          .main-thumb-view .group-list-row {
            background: none;
            color: ${this.props.themecolors.textColor};
          }
         .main-thumb-view .fms-sidebar .list-row-condest .list-view-group > li > .list-row:first-child:before {
           background: ${this.props.themecolors.textColor}
         }
         .row-label {
           color: ${this.props.themecolors.menuTextColor}
         }
         .feature-section .item-row:hover {
          color: ${this.props.themecolors.textColor};
         }
         .feature-box-Icons{
          color: ${this.props.themecolors.textColor};
         }
         a {
           color: ${this.props.themecolors.themeDarkColor};
           cursor: pointer
          }
          .widget-actions,
          .vis-dot.custom-timemachine-item,
          .vis-item.vis-background,
          .vis-item.vis-range {
            background: ${this.props.themecolors.backgroundColor};
            color: ${this.props.themecolors.textColor};
            border-color: ${this.props.themecolors.themeDarkColor};
          }
          .speed-text {
            color: ${this.props.themecolors.themeDarkColor};
          }
        .unit-tooltip-custom {
          background: ${this.props.themecolors.backgroundColor};
          color: ${this.props.themecolors.textColor};
        }
        .main-unit-modal-bg {
          color: ${this.props.themecolors.themeLightInverse};
          marginLeft:5;
          padding:10px;
        }
        .v-tab-heading {
          color: ${this.props.themecolors.textColor};
        }

        .all-attributes-wrapper {
          background-color: ${this.props.themecolors.backgroundColor}55;
          background-image: linear-gradient(180deg, transparent 50%, rgba(255,255,255,0.15) 50%);        
        }
        .all-attributes-heading{
          fontSize:18,
          fontWeight:500,
          color:${this.props.themecolors.menuActiveBackground},
          margin:"5px 0"
        }


        .custom-tab-button {
          min-height: 29px;
          border-radius: 6px 6px 0 0;
          margin: 0 2px;
          background: ${this.props.themecolors.menuActiveBackground};
          color: ${this.props.themecolors.themeLightInverse};
        }
        .custom-tab-button.custom-tab-button-selected,
        .custom-tab-button-selected {
          background: ${this.props.themecolors.backgroundColor};
          color: ${this.props.themecolors.textColor};
        }
        
        .custom-tab-button-1 {
          background: ${this.props.themecolors.menuActiveBackground};
          border-radius: 0
        }
        .custom-tab-button-1.custom-tab-button-selected {
          background: ${this.props.themecolors.menuActiveBackground};
          color: ${this.props.themecolors.themeLightInverse};
        }
        
        .MuiSelect-iconOutlined,
        .menu-list-icon {
          color: ${this.props.themecolors.textColor};
        }
        
        .sensor-collapse-wrapper {
          // background: ${this.props.themecolors.backgroundColor};

          color: ${this.props.themecolors.textColor};
        }

        .sensor-row:nth-child(even) {
        }
        .main-menu-list-wrapper {
          background-color: ${this.props.themecolors.themeLightColor};
        }

        .device-status-box:not(.nobg) {
          padding: 10px;
          border-radius: 6px;
          background-color: ${this.props.themecolors.menuBackgroundColor};
          color: ${this.props.themecolors.menuTextColor};
        } .tab_menu_icon {
          color: ${this.props.themecolors.themeLightColor};
        }
        .tab-selected-white .tab_menu_icon,
        .tab-selected .tab_menu_icon {
            } `}
          </Style>
          <div>
            <Style>
              {`
              .leaflet-control-layers-toggle {
                  background: url('/assets/images/maps/layers.png') no-repeat center;
                } 
              .grid-br {
                border-right: 2px dashed ${this.props.themecolors.InputBorderColor};
              }
              
              .grid-bb {
                border-bottom: 2px dashed ${this.props.themecolors.InputBorderColor};
              }
              
              .MuiTableRow-root:nth-child(Even) {
                background: ${this.props.themecolors.menuBackgroundColor}; 
                color: ${this.props.themecolors.menuTextColor}
              }
              .logo-section {
                background: #fff;
              }
              .theme-label.Mui-focused,
              .theme-label {
                margin-top: -7px;
                font-size: 12px;
                color: ${this.props.themecolors.textColor}
              }

              .MuiButton-text {
                padding: 5px 8px;
              }
    
              .text-field-helper-text {
                color: inherit;
              }
              
              .theme-label-shrink {
                margin-top: 0;
              }
              .theme-label-focused {
                margin-top: 0;
              }
              .theme-textarea {
                font-size: 14px;
                padding: 0
              }
              .theme-label-error {
                color: ${this.props.themecolors.error}
              }
              .theme-notchedOutline-multiline,
              .theme-notchedOutline {
                border-color:  ${this.props.themecolors.InputBorderColor} !important;
                border-radius: 4px;
              }
              .theme-input-error .theme-notchedOutline {
                border-color:  ${this.props.themecolors.error} !important;
              }
              .theme-cssFocused .theme-notchedOutline {
                border-color: #3A3A39 !important;
              }
              .theme-input-select {
                border-color: #3A3A39;
                color: ${this.props.themecolors.textColor};
                padding: 0;
                display: flex;
                height: auto
              }
              .theme-textarea,
              .theme-input {
                color: ${this.props.themecolors.textColor};
                font-size: 11px;
                height: 0em;
              }
              .custom-theme-label {
                font-size: 12px;
                display: block;
                margin-bottom: 5px;
              }
              .custom-theme-input:focus {
                outline: none
              }
              .custom-theme-input {
                color: ${this.props.themecolors.textColor};
                background: ${this.props.themecolors.backgroundColor};
                border-color: #3A3A39;
                border-radius: 4px;
                font-size: 12px;
                padding: 5px 14px;
                font-family: inherit;
              }
              .menu-list-icon {
                color: ${this.props.themecolors.textColor};
              }
              .custom-paper {
                background-color: ${this.props.themecolors.themeLightColor};
              }
              .msg_body,
              .boxhandle-icon,
              .msg_head {
                background: ${this.props.themecolors.themeDarkColor};
                color: ${this.props.themecolors.themeInverse};
              }
              
              .dashboard-page {
                background: ${this.props.themecolors.dashboardBackgroundColor};
              }
        .material-icons {vertical-align: middle; color: inherit}
        .fms-sidebar .filter-row .filter-nav-link {
          color: ${this.props.themecolors.themeLightInverse};
          background: ${this.props.themecolors.themeLightColor};
        }
        .MuiPickersDay-day.MuiPickersDay-daySelected,
        .fms-sidebar .filter-row .filter-nav-link.active{
          color: ${this.props.themecolors.menuActiveBackground};
          background: ${this.props.themecolors.menuActiveColor};
        }

        .sidebar-bg-layer {
          color: ${this.props.themecolors.tabTextColor};
          background: ${this.props.themecolors.tabBgColor};
          border-radius:16px;
          border-top: 3px solid ${this.props.themecolors.menuActiveBackground};
        }

        .sidebar-collapse-button {
          cursor: pointer;
          color: ${this.props.themecolors.textColor};
          background: ${this.props.themecolors.backgroundColor};
        }
        .MuiInputBase-root {
          color: inherit;
        }  

        .feature-row {
          border-top: 3px solid ${this.props.themecolors.menuActiveBackground};
          color: ${this.props.themecolors.menuTextColor};
        }
        .unit-name-text{
          color:${this.props.themecolors.menuTextColor}
        }
        .leaflet-draw-actions a:hover {
          background: ${this.props.themecolors.backgroundColor};
        }
        .leaflet-draw-actions a {
          border-left: 1px solid rgba(0,0,0,0.1);
          color: ${this.props.themecolors.textColor};
          background: ${this.props.themecolors.themeLightColor};
        }
        .dnd-ebuilder .tag,
        .meter-section .device-input {
          color: ${this.props.themecolors.themeInverse};
          background: ${this.props.themecolors.themeDarkColor};
        }
        .meter-section .device-input-alt {
          background: ${this.props.themecolors.themeInverse};
          color: ${this.props.themecolors.themeDarkColor};
        }
      .custom-tabs-root .custom-tabs-scrollable {
        margin-bottom: 0 !important;
        margin-left:20px
      }
      .custom-tabs-root {
        min-height: inherit;
      }

      .custom-indicator {
        background: transparent;
        height: 1px
      }
    .MuiListSubheader-root {
      color: ${this.props.themecolors.textColor}88;
      background: ${this.props.themecolors.backgroundColor}
    }
    .custom-menu-paper {
      background: ${this.props.themecolors.backgroundColor};
      color: ${this.props.themecolors.textColor}
    }
    .custom-table tbody tr:nth-child(Even) {
      background: ${this.props.themecolors.backgroundColor}33;
    }
    .notification-head-row {
      background: ${this.props.themecolors.backgroundColor}88;
      color:  ${this.props.themecolors.textColor}
    }
    .unread .read-unread-mark {
      background: ${this.props.themecolors.textColor};
    }
    .unit-image-active {
       border-top: 3px solid ${this.props.themecolors.menuActiveBackground};
    }
    .theme-input-checkbox {
      padding: 2px;
    }

    .menu-poper {
      opacity: 1;
      z-index: 9;
    }
    .menu-popper-tooltip {
      background: ${this.props.themecolors.textColor};
      color: ${this.props.themecolors.backgroundColor};
      font-size: 12px;
    }
    .MuiTooltip-tooltipPlacementTop {
      margin-top: 6px;
      margin-bottom: 6px;
    }
    .with-border:before {
      background: ${this.props.themecolors.textColor};

    }
    .main-content-page .MuiPaper-root:not(.unit-image-active),
    .custom-unit-tabpanel-container {
      background: ${this.props.themecolors.tabBgColor};
      color: ${this.props.themecolors.textColor};
      border-radius: 6px;
    }
    .tab-selected {
      background: #F1F4FD;
      border-top : 2px solid ${this.props.themecolors.menuActiveBackground};
    }
    .tab-selected-white {
      background: #F1F4FD;
      border-top : 2px solid ${this.props.themecolors.tabBgColor};

    }

    .nav-container a {
      color: ${this.props.themecolors.menuTextColor};
    }

    .custom-tabs-root-border {
      border-bottom: 1px solid ${this.props.themecolors.themeLightColor}60;
    }

    .tab-container-outer {
      color: ${this.props.themecolors.textColor};
      background: ${this.props.themecolors.backgroundColor};
      border-radius: 6px
    }
    .notification-head {
      border-bottom: 1px solid ${this.props.themecolors.themeLightColor}
    }
    .notification-head,
    .notification-bar .infinite-scroll-component {
      background: ${this.props.themecolors.backgroundColor};
      color: ${this.props.themecolors.textColor}
    }
    
    .sidebar-collapse .sidebar-collapse-button {
      background: ${this.props.themecolors.backgroundColor};
    }
    
    .motion-status-bar {
      background: ${this.props.themecolors.tabBgColor};
      color: ${this.props.themecolors.tabTextColor};
    }
    .motion-status-bar:hover {
      background: ${this.props.themecolors.themeLightColor};
      color: ${this.props.themecolors.themeLightInverse};
    }

    .react-base-table {
      background: ${this.props.themecolors.tabBgColor};
      border-top: 1px solid ${this.props.themecolors.tableBorderColor};
      border-left: 1px solid ${this.props.themecolors.tableBorderColor};
    }
    
    .react-base-table .MuiTableHead-root {
      box-shadow: none;
    }
    
    .react-base-table .MuiTableCell-root {
      border-right: 1px solid ${this.props.themecolors.tableBorderColor}
    }
    .MuiTableCell-root {
      border-bottom: 0;
      font-family: inherit
    }

    .ReactTable .rt-noData {
      
      font-size: 1.5em;
      background: transparent;
      font-weight: bold
    }
    .ReactTable .rt-expander:after {
      display: none;
    }
    .ReactTable .rt-expander.-open {
      transform: rotate(90deg);
    }
    .ReactTable .rt-expander svg {
      font-size: inherit;
    }
    .ReactTable .rt-expander {
      color: inherit;
      font-size: 16px;
      transform: rotate(0deg);
      transition: all .3s cubic-bezier(.175,.885,.32,1.275)
    }
    .sidebar,
    .form-title-section,
    .overlayform:before,
    .overlayform {
      background-color: ${this.props.themecolors.backgroundColor};
      color: ${this.props.themecolors.textColor};
    }

    .ReactTable-2 .rt-td-1 {
      border-right: 1px solid ${this.props.themecolors.themeLightColor}66;
      border-bottom: 1px solid ${this.props.themecolors.textColor}99
    }
    .c-card-alter {
      color: ${this.props.themecolors.textColor};
      border: 1px solid ${this.props.themecolors.themeLightColor}66;
    }
    .ReactTable-2:first-child .rt-td-1:first-child {
      border-left: 0;
    }
    .ReactTable .-pagination .-btn {
      color: inherit;
    }
    .leaflet-container a.leaflet-popup-close-button {
      color: ${this.props.themecolors.textColor}
    }
    .position-box strong {
      background: ${this.props.themecolors.backgroundColor};
      color: ${this.props.themecolors.textColor}
    }
    .callout {
      background: ${this.props.themecolors.backgroundColor};
      color: ${this.props.themecolors.textColor} 
    }
    .form-contol-label {
       color: ${this.props.themecolors.textColor}!important
    }
    .report-sidebar-switch:after,
    .callout-body-inner .items .callout-list-item:hover {
      background: ${this.props.themecolors.themeLightColor}
    }
    .ReactTable .rt-th {
      white-space: normal;
      text-align: left;
      word-break: break-word;
    }
    .notification-wizard li .active,.notification-wizard li:hover > span{
      background: ${this.props.themecolors.themeLightColor};
    }
    .form-control,.rdt .form-control {
      background: ${this.props.themecolors.backgroundColor};
      color: ${this.props.themecolors.textColor};
      width: 100%;
      height: 28px;
      border: 1px solid ${this.props.themecolors.themeLightColor};
      border-radius: 4px;
    }
    .form-control:focus,.rdt .form-control:focus {
      border: 1px solid ${this.props.themecolors.themeLightColor};
      border-radius: 4px;
      outline: 0;
    }
    .stepper-step.stepper-step-active, .stepper-step.stepper-step-completed {
      color: ${this.props.themecolors.themeLightColor};
    }

    .rdtPicker {
      display: none;
      position: absolute;
      width: 250px;
      padding: 4px;
      margin-top: 1px;
      z-index: 99999 !important;
      background: ${this.props.themecolors.backgroundColor};
      color: ${this.props.themecolors.textColor};
      border: 1px solid ${this.props.themecolors.themeLightColor};
    }
    .rdtPicker td.rdtDay:hover,
    .rdtPicker td.rdtHour:hover,
    .rdtPicker td.rdtMinute:hover,
    .rdtPicker td.rdtSecond:hover,
    .rdtPicker .rdtTimeToggle:hover {
      background:${this.props.themecolors.themeLightColor};
      cursor: pointer;
    }
    .rdtPicker td.rdtOld,
    .rdtPicker td.rdtNew {
      color: ${this.props.themecolors.themeLightColor};
    }
    .rdtPicker td.rdtActive.rdtToday:before {
      border-bottom-color: ${this.props.themecolors.themeLightColor};
    }
    .rdtPicker td.rdtToday:before {
      content: '';
      display: inline-block;
      border-left: 7px solid transparent;
      border-bottom: 7px solid ${this.props.themecolors.themeDarkColor};
      border-top-color:${this.props.themecolors.themeLightColor};
      position: absolute;
      bottom: 4px;
      right: 4px;
    }
    .rdtPicker td.rdtActive,
    .rdtPicker td.rdtActive:hover {
      background-color: #428bca;
      color: #fff;
      text-shadow: 0 -1px 0 ${this.props.themecolors.themeLightColor};
    }

    .rdtPicker td.rdtDisabled,
    .rdtPicker td.rdtDisabled:hover {
      background: none;
      color:${this.props.themecolors.themeDarkColor};
      cursor: not-allowed;
    }

    .rdtPicker td span.rdtOld {
      color: ${this.props.themecolors.themeDarkColor};
    }
    .rdtPicker td span.rdtDisabled,
    .rdtPicker td span.rdtDisabled:hover {
      background: none;
      color: ${this.props.themecolors.themeDarkColor};
      cursor: not-allowed;
    }
    .rdtPicker th.rdtDisabled,
    .rdtPicker th.rdtDisabled:hover {
      background: none;
      color:${this.props.themecolors.themeDarkColor};
      cursor: not-allowed;
    }
    .rdtPicker thead tr:first-child th {
      cursor: pointer;
    }
    .rdtPicker thead tr:first-child th:hover {
      background: ${this.props.themecolors.themeLightColor};
    }

    .rdtPicker tfoot {
      border-top: 1px solid ${this.props.themecolors.themeDarkColor};
    }
    .rdtPicker button:hover {
      background-color: ${this.props.themecolors.themeLightColor};
    }
    .rdtPicker th {
      border-bottom: 1px solid ${this.props.themecolors.themeDarkColor};
    }
    .btn-group.btn-group-toggle.offset-sm-2 {
      display: flex;
      margin-left: 24%;
    }
    label.btn.btn-primary {
      width: 5%;
      margin: 0 8px;
    }
    .form-group {
      margin-bottom: 10px;
    }
    .row{
      margin:10px 0;
    }
    .menu-paper {
      background-color: ${this.props.themecolors.tabBgColor};
      color: ${this.props.themecolors.tabTextColor};
    }
    .list-menu-item {
      background-color: ${this.props.themecolors.tabBgColor};
      color: ${this.props.themecolors.tabTextColor};
      padding-top: 6px;
      padding-bottom: 6px;
      font-size: 12px;
    }
      .list-menu-item:hover {
        background-color: ${this.props.themecolors.themeLightColor};
        color: ${this.props.themecolors.themeLightInverse};
      }
      .Mui-selected.selected_item:hover,
      .Mui-selected.selected_item {
        background-color: ${this.props.themecolors.themeLightColor};
        color: ${this.props.themecolors.themeLightInverse};
      }
      .driver-state-wrap .driver-state {
        background-color: ${this.props.themecolors.themeLightColor};
        color: ${this.props.themecolors.themeLightInverse};
      }
      .common-tab {
        letter-spacing: inherit;
        font-family: inherit;
        text-transform: none;
        opacity: 1;
        border-radius: 5px;
        min-height: 28px;
        color:#27272E;
        padding: 0px 6px;
        margin: 0 2px;
        box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
      }
      .MuiIconButton-root {
        color: inherit;
      }
      .vis-label.selected-group {
        background: ${this.props.themecolors.themeLightColor};
      }
      .vis-labelset .vis-label,
      .vis-time-axis .vis-text {
        color: ${this.props.themecolors.menuTextColor};
      }
      .vis-panel.vis-bottom, .vis-panel.vis-center, .vis-panel.vis-left, .vis-panel.vis-right, .vis-panel.vis-top,
      .vis-timeline {
        background: none;
        border-color: ${this.props.themecolors.InputBorderColor};
      }
      
      .main-view {
        color: ${this.props.themecolors.menuTextColor};
        border-top-color: ${this.props.themecolors.menuActiveBackground}; 
      }
      .theme-range-slider {
        color: ${this.props.themecolors.menuActiveBackground};
      }
      .rta__list {
        color: ${this.props.themecolors.textColor};
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12);
      }
      .rta__entity {
        background: ${this.props.themecolors.backgroundColor};
      }
      .rta__item:not(:last-child) {
        border-bottom-color: ${this.props.themecolors.themeLightColor};
      }
      .rta__entity--selected {
        color: ${this.props.themecolors.themeDarkColor};
        background: ${this.props.themecolors.textColor};
      }
      .react-time-picker__wrapper {
        border-color: ${this.props.themecolors.themeLightColor};
      }
      .react-time-picker__inputGroup__input {
        color: ${this.props.themecolors.textColor};
        background: ${this.props.themecolors.backgroundColor};
      }
      .theme-range-slider-label span span {
        color: ${this.props.themecolors.themeLightInverse};
      } 
      .fms-header {
        color: ${this.props.themecolors.tabTextColor}   
      }
      body {
        background: ${this.props.themecolors.backgroundColor};
        color: ${this.props.themecolors.textColor};
      }
    `}
            </Style>
            {this.props.noSidebar ? null : (
              <Sidebar
                {...this.props}
                sensorData={this.props.sensorData}
                sidebarClose={this.collapseSidebar}
                collapseSidebarWithButton={this.collapseSidebarWithButton}
                collapse={this.state.collapse}
                viewOnMap={this.props.viewOnMap}
                filterList={this.props.filterList}
                onChangedDevice={this.props.onChangedDevice}
                openCreateFrom={this.props.openCreateFrom}
              />
            )}
            {((((this.props.details && this.props.details.id) ||
              this.props.selectedUnit) &&
              this.props.location.pathname.indexOf("/monitoring") === 0) ||
              this.props.location.pathname.indexOf("/dashboard") === 0 ||
              (this.props.showReportData &&
                this.props.location.pathname.indexOf("/reports") === 0) ||
              this.props.location.pathname.indexOf("/accountManagements") ===
                0 ||
              this.props.location.pathname.indexOf("/notifications") === 0 ||
              this.props.location.pathname.indexOf("/users") === 0 ||
              this.props.location.pathname.indexOf("/addUser") === 0 ||
              this.props.location.pathname.indexOf("/Dlogs") === 0) && (
              <div
                className={
                  this.state.collapse_sidebar +
                  " " +
                  (this.props.noSidebar ? " no-sidebar " : "") +
                  " " +
                  " main-view " +
                  (localStorage.getItem("token") &&
                  localStorage.getItem("backtoAdmin")
                    ? "shown-bta-bar"
                    : "")
                }
                style={{
                  width: this.props.showReportData
                    ? "64%"
                    : currentUrl.includes("accountManagements") ||
                      currentUrl.includes("dashboard") ||
                      currentUrl.includes("Dlogs")
                    ? "67%"
                    : "",
                }}
              >
                <Scrollbar disableTrackYWidthCompensation>
                  <div
                    className={
                      this.props.classFromChildren
                        ? this.props.classFromChildren
                        : ""
                    }
                    style={{ borderRadius: 20 }}
                  >
                    {this.props.children}
                  </div>
                </Scrollbar>
              </div>
            )}

            {this.props.logInUser &&
              this.props.logInUser.id &&
              this.props.location.pathname.indexOf("/monitoring") !== 0 &&
              this.props.location.pathname.indexOf("/dashboard") !== 0 && (
                <CommonSection
                  location={this.props.location}
                  showDeviceModal={!this.props.showDeviceModal}
                />
              )}
            {this.props && this.props.deviceId ? (
              <Feature
                themecolors={this.props.themecolors}
                currentPosition={this.props.currentPosition}
                currentDevice={this.props.currentDevice}
                translate={this.props.translate}
                logInUser={this.props.logInUser}
                copyToClipboard={this.props.copyToClipboard}
              />
            ) : null}
          </div>
          {this.props.afterView}
          {/* <Footer history={this.props.history} /> */}
        </>
      );
    } else {
      return <Loader />;
    }
  }
}

export function Feature(props) {
  return (
    <FeatureRow
      themecolors={props.themecolors}
      currentPosition={props.currentPosition}
      currentDevice={props.currentDevice}
      translate={props.translate}
      logInUser={props.logInUser}
      copyToClipboard={props.copyToClipboard}
    />
  );
}
const mapStateToProps = (state) => ({
  logInUser: state.logInUsers,
  ServerSetting: state.ServerSetting,
  themecolors: state.themeColors,
  notificationSystem: state.notificationSystem,
  filterList: state.filterList?.filters || [],
});

export default connect(mapStateToProps)(withTranslationWrapper(Layout));
