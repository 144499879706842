import React, { Component } from "react";
import TextField from "./../../../Components/common/SearchField";
import {
  DriversShortList,
  ListRow,
} from "./../../../Components/Drivers/shortList";
import { checkPrivileges } from "../../../Helpers";
import Loader from "./../../Loader";
import Scrollbar from "react-scrollbars-custom";
import ExportImportMenu from "../../../Components/Maps/ExportImportMenu";
import RecourcesList from "./RecourcesList";
import { FormControlLabel, IconButton } from "@mui/material";
import Switch from "../../../Components/common/Switch";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";

export default class DriverSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeviceLimit: false,
      countCall: true,
    };
  }

  toggleSearch = () => {
    this.setState({
      searchBox: !this.state.searchBox,
    });
  };
  render() {
    const drivers =
      this.props.itemPagination && this.props.itemPagination.items
        ? this.props.itemPagination.items.map((row) => {
            const attr = Object.keys(row.attributes);
            const others = {};
            attr.map((key) => {
              others[key] = row.attributes[key];
              return null;
            });
            return {
              name: row.name,
              uniqueId: row.uniqueId,
              ...others,
            };
          })
        : [];

    const fields = [];
    drivers.map((veh) => {
      fields.push(...Object.keys(veh));
      return null;
    });
    return (
      <div className="fms-sidebar sidebar-bg-layer">
        <ul className="filter-row">
          {!this.state.searchBox ? (
            <li className="section-head-filter">
              <div className="filter-row">
                <IconButton onClick={this.toggleSearch}>
                  <SearchIcon />
                </IconButton>
                {this.props.resourceList &&
                checkPrivileges("itemgroupCreate") ? (
                  <IconButton
                    onClick={() => {
                      this.props.addResource();
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                ) : checkPrivileges("driverCreate") ? (
                  <IconButton
                    onClick={() => {
                      this.props.addDriver();
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                ) : null}
                <FormControlLabel
                  style={{ marginLeft: "auto", flex: 1 }}
                  control={
                    <Switch
                      checked={this.props.resourceList}
                      onChange={() =>
                        this.props.showResources(!this.props.resourceList)
                      }
                      name="isOpenEmailSection"
                    />
                  }
                  label={this.props.translate("groups")}
                  labelPlacement="start"
                  classes={{ label: "color-inherit" }}
                />
                <ExportImportMenu
                  fileName="drivers"
                  checkPrivilege="driverCreate"
                  data={drivers}
                  themecolors={this.props.themecolors}
                />
              </div>
            </li>
          ) : (
            <li
              className="filter-nav section-head-filter"
              style={{ position: "relative" }}
            >
              <div className="filter-row" style={{ flex: 1, marginTop: -2 }}>
                <div style={{ flex: 1 }}>
                  <TextField
                    label={this.props.translate("searchDrivers")}
                    type="search"
                    placeholder="exp: ID, Name"
                    value={this.state.search}
                    onChange={this.props.searchItem}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </div>
                <IconButton
                  onClick={this.toggleSearch}
                  className="search-close-icon"
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </li>
          )}
        </ul>
        {this.props.resourceList ? (
          <RecourcesList
            ItemChild={ListRow}
            ItemChildProps={this.props}
            fromArray={
              (this.props.itemPagination && this.props.itemPagination.items) ||
              []
            }
            {...this.props}
          />
        ) : (
          <div className="sidebar-devices-list" id="scrollableDrivers">
            <Scrollbar
              disableTracksWidthCompensation
              scrollerProps={{
                renderer: (props) => {
                  const { elementRef, ...restProps } = props;
                  return (
                    <div
                      {...restProps}
                      ref={elementRef}
                      id="scrollableDrivers"
                    />
                  );
                },
              }}
            >
              {!this.props.isDriverLoad ? (
                checkPrivileges("driver") &&
                this.props.itemPagination &&
                this.props.itemPagination.items &&
                this.props.itemPagination.items.length ? (
                  <DriversShortList
                    {...this.state}
                    items={this.props.itemPagination.items}
                    allDriverLocation={this.props.allDriverLocation}
                    match={this.props.match}
                    editItem={this.props.editItem}
                    removedItem={this.props.removedItem}
                    selecteItem={this.props.selecteItem}
                    translate={this.props.translate}
                  />
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      color: "#ccc",
                      fontWeight: "bold",
                    }}
                  >
                    {this.props.translate("notFound")}
                  </p>
                )
              ) : (
                <Loader />
              )}
            </Scrollbar>
          </div>
        )}
      </div>
    );
  }
}
