import React, { Fragment } from "react";
import axios from "axios";
import SimpleModalWrapped from "../../Components/common/Modal";
import TextField from "../../Components/common/TextField";
import { withStyles } from "@mui/styles";
import Tooltip from "../../Components/common/Tooltip";
import Grid from "@mui/material/Grid";
import { categoriesList, checkPrivileges, errorHandler } from "../../Helpers";
import Button from "../../Components/common/Button";
import SingleSelect from "../../Components/common/SingleSelect";
import MenuItem from "../../Components/common/MenuItem";
import { unitsList } from "../../Components/Devices/unitsPortList";
import CloseIcon from "@mui/icons-material/Close";
import withTranslationWrapper from "../../HOC/withTranslation";

const styles = (theme) => ({});

class addDevice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
      at_modal_visable: false,
      isVisableDeviceBtn: false,
      open: true,
      isAdvSettingEnable: false,
    };
    this.searchFromList = this.searchFromList.bind(this);
  }

  async searchFromList(inputValue) {
    if (!inputValue) {
      return [];
    }

    return await axios({
      method: "GET",
      url: `/api/devices/models?search=${inputValue}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.data && response.data.data && response.data.data.data) {
        return response.data.data.data.map((item) => {
          return {
            id: item.name,
            name: item.name,
            key: item.name,
            port: item.port,
            protocol: item.protocol,
            label: item.name,
            value: item.name,
          };
        });
      }
    });
  }

  render() {
    const { classes } = this.props;
    const defaultOptions = [];
    unitsList.map((item, i) => {
      if (i <= 9) {
        defaultOptions.push({
          id: item.device,
          name: item.device,
          key: item.device,
          port: item.port,
          protocol: item.protocol,
          label: item.device,
          value: item.device,
        });
      }
      return null;
    });
    if (
      defaultOptions &&
      defaultOptions.length < unitsList.length &&
      unitsList
    ) {
      defaultOptions.push({
        id: "",
        name: "Search for more items...",
        key: "",
        port: "",
        label: "Search for more items...",
        value: "",
        isDisabled: true,
      });
    }
    const defaultZones = [];

    return (
      <Fragment>
        <SimpleModalWrapped
          visable
          isButtonVisable
          title=""
          formSubmit={this.props.formSubmit}
          notShowAttributesBtn
          notShowGroupBtn={this.props.notShowGroupBtn}
          modalControle={this.props.modalControle}
          buttonText={this.props.buttonText}
          isVisableBtn={this.props.isVisableDeviceBtn}
          canUpdate={checkPrivileges(this.props.isHaveAccess)}
          hasPremissions
          HaveGroupCreateAccess={checkPrivileges("groupCreate")}
          elevation={this.props.editOption !== undefined ? 0 : 2}
          btnCloseText={"close"}
          showPortList={this.props.showPortList}
          closeCreateView={this.props.closeCreateView}
          btnCancelNotShow={true}
          showPort={this.props.showPort}
          closeCreateBtn
          content={
            <form autoComplete="off">
              <div>
                <div style={{ display: "flex" }}>
                  <h4
                    className="all-attributes-heading"
                    style={{ margin: "5px 0", fontSize: 16 }}
                  >
                    {this.props.translate("basic")}
                  </h4>
                  <span onClick={() => this.props.closeCreateView()}>
                    {" "}
                    <CloseIcon
                      className={"feature-close-button"}
                      style={{ fill: "#3A3A39" }}
                    />
                  </span>
                </div>
                <Grid container spacing={0}>
                  <Grid item xl={6} md={6} xs={6} style={{ padding: "0 8px" }}>
                    <TextField
                      id="name"
                      label={this.props.translate("sharedName")}
                      type="text"
                      placeholder=""
                      required
                      value={this.props.form?.name || ""}
                      onChange={(e) =>
                        this.props.handleChange("name", e.target.value)
                      }
                      variant="outlined"
                      margin="dense"
                      fullWidth
                    />
                  </Grid>
                  {this.props.editOption ? (
                    checkPrivileges("deviceModel") ? (
                      <Grid
                        item
                        xl={6}
                        md={6}
                        xs={6}
                        style={{ zIndex: 99, padding: "0 8px" }}
                      >
                        <SingleSelect
                          array={[]}
                          async
                          selectName="model"
                          isClearable
                          defaultOptions={defaultOptions}
                          loadOptions={this.searchFromList}
                          label={this.props.translate("deviceModel") + "*"}
                          value={
                            typeof this.props.form.model === "object"
                              ? this.props.form.model
                              : this.props.form.model
                              ? {
                                  id: this.props.form.model,
                                  key: this.props.form.model,
                                  label: this.props.form.model,
                                  value: this.props.form.model,
                                }
                              : ""
                          }
                          handleChange={this.props.port_handleChange}
                          canAssign
                        />
                      </Grid>
                    ) : null
                  ) : (
                    <Grid
                      item
                      xl={6}
                      md={6}
                      xs={6}
                      style={{ zIndex: 99, padding: "0 8px" }}
                    >
                      <SingleSelect
                        array={[]}
                        async
                        selectName="model"
                        isClearable
                        defaultOptions={defaultOptions}
                        loadOptions={this.searchFromList}
                        label={this.props.translate("deviceModel") + "*"}
                        value={
                          typeof this.props.form.model === "object"
                            ? this.props.form.model
                            : this.props.form.model
                            ? {
                                id: this.props.form.model,
                                key: this.props.form.model,
                                label: this.props.form.model,
                                value: this.props.form.model,
                              }
                            : ""
                        }
                        handleChange={this.props.port_handleChange}
                        canAssign
                      />
                    </Grid>
                  )}
                  <Grid item xl={6} md={6} xs={6} style={{ padding: "0 8px" }}>
                    {this.props.form.model !== "FX Tracker" ? (
                      <TextField
                        id="uniqueId"
                        label={this.props.translate("deviceIdentifier")}
                        type="text"
                        placeholder=""
                        required
                        value={this.props.form.uniqueId || ""}
                        InputProps={
                          this.props.editOption
                            ? { readOnly: !checkPrivileges("deviceImei") }
                            : ""
                        }
                        onChange={(e) =>
                          this.props.handleChange("uniqueId", e.target.value)
                        }
                        variant="outlined"
                        margin="dense"
                        // disabled={this.props.editOption && this.props.logInUser && this.props.logInUser.userType !== -1}
                        fullWidth
                      />
                    ) : (
                      <Tooltip title={this.props.translate("autoGeneratedId")}>
                        <TextField
                          id="uniqueId"
                          label={this.props.translate("deviceIdentifier")}
                          type="text"
                          placeholder=""
                          required
                          value={this.props.form.uniqueId || ""}
                          variant="outlined"
                          margin="dense"
                          disabled={
                            this.props.editOption ||
                            (this.props.logInUser &&
                              this.props.logInUser.userType !== -1)
                          }
                          fullWidth
                        />
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item md={12} xs={12} style={{ padding: "0px 10px" }}>
                    <p
                      style={{
                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                        fontSize: "16",
                        background: this.props.themecolors.backgroundColor,
                        color: this.props.themecolors.textColor,
                        padding: "12px 33px",
                        marginTop: 0,
                        marginLeft: "25",
                      }}
                    >
                      <span>
                        {this.props.translate(
                          "toConfigureYourDeviceManuallyUseConnectionParameters"
                        )}
                      </span>
                      <span style={{ display: "block" }}>
                        <label
                          style={{ width: "40%", display: "inline-block" }}
                        >
                          {this.props.translate("serverIP")}:
                        </label>
                        <span>{this.props.serverIp}</span>
                      </span>
                      <span style={{ display: "block" }}>
                        <label
                          style={{ width: "40%", display: "inline-block" }}
                        >
                          {this.props.translate("Port")}:
                        </label>
                        <span>{this.props.form.device}</span>
                      </span>
                    </p>
                  </Grid>
                </Grid>
              </div>
              <h4 style={{ margin: "10px 0" }}>
                <hr className="hr-divider" />
                {this.props.translate("sharedExtra")}
              </h4>
              <Grid container spacing={0}>
                <Grid item xl={6} md={6} xs={6} style={{ padding: "0 8px" }}>
                  <TextField
                    id="phone"
                    label={this.props.translate("sharedPhone")}
                    type="text"
                    placeholder=""
                    value={this.props.form.phone || ""}
                    onChange={(e) =>
                      this.props.handleChange("phone", e.target.value)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>

                <Grid item xl={6} md={6} xs={6} style={{ padding: "0 8px" }}>
                  <TextField
                    id="contact"
                    label={this.props.translate("deviceContact")}
                    placeholder=""
                    value={this.props.form.contact || ""}
                    onChange={(e) =>
                      this.props.handleChange("contact", e.target.value)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                {this.props.addOption && (
                  <Grid item xl={6} md={6} xs={6} style={{ padding: "0 8px" }}>
                    <TextField
                      id="category"
                      select
                      label={this.props.translate("deviceCategory")}
                      value={this.props.form.category || ""}
                      onChange={(e) =>
                        this.props.handleChange("category", e.target.value)
                      }
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      margin="dense"
                      fullWidth
                    >
                      <MenuItem value={0}>
                        <em>None</em>
                      </MenuItem>
                      {categoriesList.map((option) => (
                        <MenuItem key={option} value={option}>
                          {this.props.translate(option)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                )}
              </Grid>

              <h4
                className="all-attributes-heading"
                style={{ margin: "5px 0", fontSize: 16 }}
              >
                {this.props.translate("driver")}
              </h4>

              <Grid container spacing={0}>
                <Grid item xl={6} md={6} xs={12} style={{ padding: "0 8px" }}>
                  <TextField
                    id="driverName"
                    label={this.props.translate("driverName")}
                    placeholder=""
                    value={
                      this.props.form.attributes &&
                      this.props.form.attributes.driverName
                        ? this.props.form.attributes.driverName
                        : ""
                    }
                    onChange={(e) =>
                      this.props.changeAttribute2("driverName", e)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
                <Grid item xl={6} md={6} xs={12} style={{ padding: "0 8px" }}>
                  <TextField
                    id="driverPhoneNumber"
                    label={this.props.translate("driverPhoneNumber")}
                    placeholder=""
                    value={
                      (this.props.form.attributes &&
                        this.props.form.attributes.driverPhoneNumber) ||
                      ""
                    }
                    onChange={(e) =>
                      this.props.changeAttribute2("driverPhoneNumber", e)
                    }
                    variant="outlined"
                    margin="dense"
                    fullWidth
                  />
                </Grid>
              </Grid>
            </form>
          }
        />
      </Fragment>
    );
  }
}

const AddDevice = withStyles(styles);
export default AddDevice(withTranslationWrapper(addDevice));
