import React, { Component } from "react";
import { connect } from "react-redux";
import MarkerMap from "../../Components/Maps";
import CustomMarker from "../../Components/Maps/CustomMarker";
import { MapTooltip } from "../../Components/Maps/MapTooltip";
import Layout from "../../Layout";
import Grid from "@mui/material/Grid";
import isEqual from "react-fast-compare";
import { TrailerModal } from "../../Components/Trailer/trailerModal";
import ConfirmDialoag from "../../Components/common/ConfirmDialoag";
import { setTrackId } from "../../Actions/Devices";
import { removeTrailer } from "../../Actions/Trailer";
import withResources from "../HOCRecources";
import ResourceModal from "../../Components/Recources/resourceModal";
import { notifySuccess } from "../../Utils/CustomNotifcations";
import moment from "moment";
import instance from "../../axios";
import { errorHandler } from "../../Helpers";
import {
  MapContainer as Map,
  TileLayer,
  Tooltip,
  ZoomControl,
  LayersControl,
} from "react-leaflet";
import { MapTileLayers } from "../../Reducers/Maps";
import L from "leaflet";
import Style from "style-it";
import withTranslationWrapper from "../../HOC/withTranslation";

function importAll(r) {
  return r.keys().map(r);
}

const images = importAll(
  require.context("./../../images", false, /\.(png|jpe?g|svg)$/)
);

class Trailer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selecteditem: "",
      isVisable: false,
      showItemDetail: false,
      activeOperation: "",
      trailerAddress: "",
      trailerLat: "",
      trailerLon: "",
      selecteditemId: "",
      trackersApiResponce: false,
      multiTrackers: "",
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      lat: 0,
      lng: 0,
      zoom: 3,
      minZoom: 3,
      animCount: 0,
      assigned: false,
      tracking: false,
      applied: false,
      allTrailerLocation: {},
      resourceList: false,
      linkResource: false,
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
    };
    this.onCloseModal = this.onCloseModal.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.ServerSetting && this.props.ServerSetting.zoom) {
      this.setState({
        zoom: this.props.ServerSetting.zoom,
        lat: this.props.ServerSetting.latitude,
        lng: this.props.ServerSetting.longitude,
      });
    }
    if (this.props.logInUser && this.props.logInUser.zoom) {
      this.setState({
        zoom: this.props.logInUser.zoom,
        lat: this.props.logInUser.latitude,
        lng: this.props.logInUser.longitude,
      });
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.id &&
      this.props.trailers.length &&
      this.state.initFetch === false
    ) {
      this.fetchData(this.props);
    }
    let trailerUniqueId = 0;
    if (
      this.props.deviceRelatedData &&
      Object.values(this.props.deviceRelatedData).length
    ) {
      const list = {};
      const ids = this.props.trailers.map((d) => {
        list[d.uniqueId] = d;
        if (d.id === parseInt(this.props.match.params.id)) {
          trailerUniqueId = d.uniqueId;
        }
        return d.uniqueId;
      });
      const trailers = {};
      Object.values(this.props.deviceRelatedData).map((d) => {
        const trailerUniqueId =
          (d.attributes && d.attributes.trailerUniqueId) || 0;
        if (ids.includes(trailerUniqueId)) {
          trailers[trailerUniqueId] = d;
          trailers[trailerUniqueId].trailer = list[trailerUniqueId];
        }
        return null;
      });

      this.setState({ allTrailerLocation: trailers });
    }
    if (
      this.state.showItemDetail &&
      this.props.deviceRelatedData &&
      Object.values(this.props.deviceRelatedData).length &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      const trailerLocation = Object.values(this.props.deviceRelatedData).find(
        ({ attributes }) =>
          attributes &&
          attributes.trailerUniqueId &&
          parseInt(attributes.trailerUniqueId) === parseInt(trailerUniqueId)
      );
      if (
        trailerLocation &&
        trailerLocation.latitude &&
        trailerLocation.longitude
      ) {
        this.setState({ trailerLocation: trailerLocation || null }, () => {
          if (trailerLocation && this.state.tracking === true) {
            this.map &&
              this.map
                .setMaxZoom(16)
                .fitBounds([
                  [trailerLocation.latitude, trailerLocation.longitude],
                ]);
            setTimeout(() => {
              this.map && this.map.setMaxZoom(this.props.mapLayer.maxZoom);
              this.setState({ applied: true });
            }, 100);
          }
        });
      }
    }
  }

  componentWillUnmount() {
    this.setState({
      selecteditem: "",
      isVisable: false,
      showItemDetail: false,
      activeOperation: "",
      trailerAddress: "",
      trailerLat: "",
      trailerLon: "",
      selecteditemId: "",
      trackersApiResponce: false,
      multiTrackers: "",
      initFetch: false,
      currentPage: 1,
      pagesize: 50,
      animCount: 0,
      allTrailerLocation: {},
      itemPagination: {
        items: [],
        total: 0,
        currentPage: 0,
        currentDevice: this.props.deviceId,
        hasNext: true,
        searchText: "",
      },
    });
  }

  componentDidUpdate() {
    if (
      this.props.logInUser &&
      this.props.logInUser.latitude &&
      this.props.logInUser.longitude &&
      this.state.assigned === false
    ) {
      this.setState({
        assigned: true,
        lat: this.props.logInUser.latitude,
        lng: this.props.logInUser.longitude,
      });
    }
  }

  UNSAFE_componentWillReceiveProps(n, s) {
    if (n.ServerSetting.zoom) {
      this.setState({
        zoom: n.ServerSetting.zoom,
        lat: n.ServerSetting.latitude,
        lng: n.ServerSetting.longitude,
      });
    }
    if (n.logInUser.zoom) {
      this.setState({
        zoom: n.logInUser.zoom,
        lat: n.logInUser.latitude,
        lng: n.logInUser.longitude,
      });
    }
    if (
      n.logInUser &&
      n.logInUser.id &&
      n.trailers.length &&
      isEqual(n.trailers, this.props.trailers) &&
      this.state.initFetch === false
    ) {
      this.setState(
        {
          initFetch: true,
        },
        () => {
          this.fetchData(n);
        }
      );
    }
    if (
      isEqual(n.trailers, this.props.trailers) &&
      n.trailers.length &&
      this.props.trailers.length
    ) {
      this.fetchData(n);
    }
    if (this.props.trackId !== n.trackId) {
      this.setState({ allTrailerLocation: {}, animCount: 0 }, () => {
        this.calculate(n);
      });
    } else {
      this.calculate(n);
    }
  }

  mapReference = (el) => {
    if (el) {
      this.map = el.leafletElement;
    }
  };
  showResources = (type) => {
    this.setState({
      resourceList: type,
    });
  };
  addResource = () => {
    this.setState({
      isVisable: true,
      showItemDetail: false,
      activeOperation: "addResource",
      selecteditem: "",
      driverAddress: "",
      driverLat: "",
      driverLon: "",
    });
  };
  onEditResource = (item) => {
    this.setState({
      isVisable: true,
      showItemDetail: false,
      activeOperation: "editResource",
      selecteditem: item,
      driverAddress: "",
      driverLat: "",
      driverLon: "",
    });
  };
  onLinkResource = (item) => {
    this.setState(
      {
        linkResource: true,
        selecteditem: item,
      },
      () => {
        this.props.fetchNestedItems(item.id, 1);
      }
    );
  };
  onCloseResource = () => {
    this.setState({
      linkResource: false,
    });
    this.onCloseModal();
  };

  fetchMoreItems = () => {
    this.fetchData(this.props);
  };

  searchItems = (text) => {
    this.setState(
      {
        searchText: text,
      },
      () => {
        this.fetchData(this.props);
      }
    );
  };

  fetchData = (nextProps) => {
    let items = nextProps.trailers.filter((row) =>
      (row.id + "" + row.uniqueId + "" + row.name)
        .toLowerCase()
        .includes((this.state.searchText || "").toLowerCase())
    );
    this.setState({
      itemPagination: {
        total: items.length,
        items,
      },
    });
  };

  removedItem = (item) => {
    instance({
      url: `/api/trailers/${item.id}`,
      method: "DELETE",
    })
      // .then(response => {
      //   if (response.ok) {
      //     response.json()
      .then((trailer) => {
        // if(trailer.status === 'success'){
        this.props.dispatch(removeTrailer(item));
        // this.onCloseModal()
        this.fetchMoreItems();
        this.setState({
          isVisable: true,
          showItemDetail: false,
          activeOperation: "",
          selecteditem: "",
          trailerAddress: "",
          onDeleteConfirmation: false,
          trailerLat: "",
          trailerLon: "",
        });
        //this.props.enqueueSnackbar(this.props.translate('driverIsDeleted'));
        this.props.dispatch(
          notifySuccess(this.props.translate("trailerIsDeleted"))
        );
        // }
        //   })
        // } else {
        //   throw response
        // }
      })
      .catch((error) => {
        errorHandler(error, this.props.dispatch);
      });
  };

  editItem = (item) => {
    this.setState({ showItemDetail: false }, () => {
      this.getMultiDevice(item.id);
      this.setState({
        isVisable: true,
        selecteditem: item,
        activeOperation: "edit",
        trailerAddress: "",
        trailerLat: item.attributes.trailerLat || "",
        trailerLon: item.attributes.trailerLon || "",
      });
    });
  };

  addItem = () => {
    this.props.history.push("/trailers");
    this.setState({
      isVisable: true,
      showItemDetail: false,
      selecteditem: "",
      activeOperation: "add",
      trailerAddress: "",
      trailerLat: "",
      trailerLon: "",
    });
  };

  onCloseModal = () => {
    this.setState({
      isVisable: false,
      showItemDetail: false,
      selecteditem: "",
      activeOperation: "",
      onDeleteConfirmation: false,
    });
    this.props.history.push("/trailers");
  };

  selecteItem = (item) => {
    this.props.dispatch(setTrackId(0));
    this.setState({ allTrailerLocation: {} }, () => {
      this.getMultiDevice(item.id);
      this.calculate(this.props);
      this.setState(
        {
          showItemDetail: true,
          isVisable: false,
          selecteditem: item,
          selecteditemId: item.id,
          activeOperation: "details",
          trackersApiResponce: false,
          multiTrackers: "",
          trailerLocation: null,
          animCount: 0,
          tracking: true,
          applied: false,
          onDeleteConfirmation: false,
        },
        () => {
          this.getMultiDevice(item.id);
        }
      );
    });
  };

  checkZoom = () => {
    if (this.state.applied === true) {
      this.setState({ tracking: false, applied: false, animCount: 0 }, () => {
        this.props.dispatch(setTrackId(0));
      });
      this.setState({ allTrailerLocation: {} }, () => {
        this.calculate(this.props);
      });
    }
  };

  calculate = (n) => {
    if (n.deviceRelatedData && Object.values(n.deviceRelatedData).length) {
      const list = {};
      const ids = n.trailers.map((d) => {
        list[d.uniqueId] = d;
        return d.uniqueId;
      });
      const trailers = {};
      Object.values(n.deviceRelatedData).map((d) => {
        const trailerUniqueId =
          (d.attributes && d.attributes.trailerUniqueId) || 0;
        if (
          ids.includes(trailerUniqueId) &&
          n.allComputedAttributes &&
          n.allComputedAttributes.length &&
          n.allComputedAttributes.includes(d.id)
        ) {
          trailers[trailerUniqueId] = d;
          trailers[trailerUniqueId].trailer = list[trailerUniqueId];
        }
        return null;
      });
      this.setState({ allTrailerLocation: trailers });
    }

    if (
      this.state.showItemDetail &&
      n.deviceRelatedData &&
      Object.values(n.deviceRelatedData).length &&
      n.match &&
      n.match.params &&
      n.match.params.id
    ) {
      const trailerLocation = Object.values(n.deviceRelatedData).find(
        ({ id, trailer }) =>
          trailer &&
          parseInt(trailer.id) === parseInt(n.match.params.id) &&
          n.allComputedAttributes &&
          n.allComputedAttributes.length &&
          n.allComputedAttributes.includes(id)
      );
      if (
        trailerLocation &&
        trailerLocation.latitude &&
        trailerLocation.longitude
      ) {
        this.setState({ trailerLocation: trailerLocation || null }, () => {
          if (trailerLocation && this.state.tracking === true) {
            this.map &&
              this.map
                .setMaxZoom(16)
                .fitBounds([
                  [trailerLocation.latitude, trailerLocation.longitude],
                ]);
            setTimeout(() => {
              this.map && this.map.setMaxZoom(n.mapLayer.maxZoom);
              this.setState({ applied: true, animCount: 1 });
            }, 200);
          }
        });
      }
    }
  };

  getPostion = (address, latlng) => {
    if (address) {
      this.setState({
        trailerAddress: address,
        trailerLat: latlng.lat,
        trailerLon: latlng.lng,
      });
    }
  };

  getMultiDevice = (trailerUniqueId) => {
    this.props.dispatch(setTrackId(0));
    this.map && this.map.setZoom(3);
    if (trailerUniqueId) {
      if (
        this.map &&
        this.props.deviceRelatedData &&
        Object.values(this.props.deviceRelatedData).length
      ) {
        // const trailerLocation = Object.values(this.props.deviceRelatedData).find(d => d && d.trailerUniqueId === id)
        const trailerLocation = Object.values(
          this.props.deviceRelatedData
        ).find(
          ({ trailer, id }) =>
            trailer &&
            trailer.id &&
            trailer.id === trailerUniqueId &&
            this.props.allComputedAttributes &&
            this.props.allComputedAttributes.length &&
            this.props.allComputedAttributes.includes(id)
        );
        this.setState({
          trailerLocation: trailerLocation || null,
          animCount: 0,
        });
        if (trailerLocation) {
          this.props.dispatch(setTrackId(trailerLocation.id));
        }
      }
      this.setState({
        multiTrackers: [],
        trackersApiResponce: true,
      });
    }
  };

  onCancel = () => {
    this.setState({
      onDeleteConfirmation: false,
    });
  };

  onRemovedItem = (item) => {
    this.setState({
      selecteditem: item,
      onDeleteConfirmation: true,
    });
  };

  render() {
    let crs = {};
    if (["yandexMap", "yandexSat"].includes(this.props.mapLayer.id)) {
      crs = { crs: L.CRS.EPSG3395 };
    }

    const body = [
      <>
        <ZoomControl position={"bottomright"} />
      </>,
    ];

    const position = [this.state.lat, this.state.lng];

    const thisMap = [
      <Map
        key={1}
        ref={this.mapReference}
        onZoomAnim={this.checkZoom}
        zoomControl={false}
        bounds={
          this.state.bounds && this.state.bounds.length
            ? this.state.bounds
            : null
        }
        boundsOptions={this.setBoundOptions}
        style={{ height: this.props.height, width: this.props.width }}
        center={position}
        zoom={this.state.zoom}
        minZoom={this.state.minZoom}
        maxZoom={this.props.mapLayer.maxZoom}
        maxNativeZoom={this.props.mapLayer.maxZoom}
        {...crs}
      >
        {this.state.pointer}
        {body}

        {this.state.allTrailerLocation &&
        Object.keys(this.state.allTrailerLocation).length
          ? Object.values(this.state.allTrailerLocation).map((row) => (
              <CustomMarker
                key={row.id}
                position={{
                  lat: row.latitude,
                  lng: row.longitude,
                  updated: moment(row.serverTime),
                }}
                rotationAngle={0}
                rotationOrigin="center"
                animationTime={
                  this.state.animCount > 0 &&
                  (this.state.applied === true) &
                    (this.props.trackId === row.id)
                    ? row.animationTime
                    : 0
                }
                icon={L.divIcon({
                  iconUrl:
                    "/assets/category/default/" +
                    (row.category || "default") +
                    "top.svg",
                  iconSize: [50, 50],
                  iconAnchor: [25, 25],
                  tooltipAnchor: [0, -20],
                  className: "custom-marker",
                  html: `<img
                      style="transform: rotate(${row.course}deg)"
                        src=
                          '/assets/category/default/${
                            row.category || "default"
                          }top.svg'
                        
                        alt=''
                      />`,
                })}
                iconSize={[50, 50]}
              >
                <Tooltip direction={"top"}>
                  <MapTooltip
                    themecolors={this.props.themecolors}
                    position={row}
                    device={row}
                    trailer={row.trailer}
                    trailer2={this.state.selecteditem}
                    logInUser={this.props.logInUser}
                    translate={this.props.translate}
                  />
                </Tooltip>
              </CustomMarker>
            ))
          : null}
        <LayersControl position="bottomright">
          {MapTileLayers.map((layer) => (
            <LayersControl.BaseLayer
              id={layer.id}
              checked={layer.id === this.props.mapLayer.id}
              key={layer.id}
              name={layer.name}
            >
              <TileLayer {...layer} minZoom={this.state.minZoom} />
            </LayersControl.BaseLayer>
          ))}
        </LayersControl>
      </Map>,
    ];

    return (
      <Layout
        {...this.props}
        addTrailer={this.addItem}
        editItem={this.editItem}
        removedItem={this.onRemovedItem}
        selecteItem={this.selecteItem}
        fetchMoreItems={this.fetchMoreItems}
        classFromChildren={!this.state.isVisable ? "no-padding" : "has-padding"}
        itemPagination={{ ...this.state.itemPagination }}
        searchItems={this.searchItems}
        allTrailerLocation={this.state.allTrailerLocation}
        showResources={this.showResources}
        onEditResource={this.onEditResource}
        onLinkResource={this.onLinkResource}
        addResource={this.addResource}
        resourceList={this.state.resourceList}
      >
        <Style>{`
                .leaflet-control-layers-toggle {
                    background: url('/assets/images/maps/layers.png') no-repeat center;
                  } 
              `}</Style>
        {!this.state.isVisable ? (
          <div>
            {["osm", ""].includes(this.props.mapLayer.id) ? thisMap : null}
            {["carto"].includes(this.props.mapLayer.id) ? thisMap : null}
            {["googleTerrain"].includes(this.props.mapLayer.id)
              ? thisMap
              : null}
            {["googleSatellite"].includes(this.props.mapLayer.id)
              ? thisMap
              : null}
            {["googleHybrid"].includes(this.props.mapLayer.id) ? thisMap : null}
            {["googleRoad"].includes(this.props.mapLayer.id) ? thisMap : null}
            {["baidu"].includes(this.props.mapLayer.id) ? thisMap : null}
            {["yandexMap", "yandexSat"].includes(this.props.mapLayer.id)
              ? thisMap
              : null}
          </div>
        ) : null}

        {this.state.showItemDetail && (
          <TrailerModal
            onCloseModal={this.onCloseModal}
            selecteditem={this.state.selecteditem}
            showItemDetail={this.state.showItemDetail}
            devicesIcons={images}
            trackersApiResponce={this.state.trackersApiResponce}
            multiTrackers={this.state.multiTrackers}
            currentLocation={this.state.trailerLocation}
            devices={this.props.devices}
            resourceList={this.state.resourceList}
          />
        )}
        <ResourceModal
          changeResource={this.props.changeResource}
          selectedResourse={this.state.selecteditem}
          activeOperation={this.state.activeOperation}
          onCloseResource={this.onCloseResource}
          itemPagination={
            this.state.itemPagination && this.state.itemPagination.items
          }
          assignItem={this.props.assignItem}
          unassignItem={this.props.unassignItem}
          fetchNestedItems={this.props.fetchNestedItems}
          nestedResources={this.props.nestedResources}
          translate={this.props.translate}
          linkResource={this.state.linkResource}
          themecolors={this.props.themecolors}
          itemType="Trailer"
        />
        {!this.state.showItemDetail ? (
          <div className="main-content-page">
            {this.state.isVisable &&
              ["add", "edit"].includes(this.state.activeOperation) && (
                <Grid container spacing={2} className="driver-page-content">
                  <Grid item xs={12} md={7}>
                    <TrailerModal
                      onCloseModal={this.onCloseModal}
                      activeOperation={this.state.activeOperation}
                      selecteditem={this.state.selecteditem}
                      selectedAddress={this.state.trailerAddress}
                      trailerLat={this.state.trailerLat}
                      trailerLon={this.state.trailerLon}
                      trackersApiResponce={this.state.trackersApiResponce}
                      multiTrackers={this.state.multiTrackers}
                      resourceList={this.state.resourceList}
                      getMultiDevice={this.getMultiDevice}
                      fetchMoreItems={this.fetchMoreItems}
                    />
                  </Grid>
                  <Grid item xs={12} md={5}>
                    <MarkerMap
                      showMarker={true}
                      getPostion={this.getPostion}
                      zoom={0}
                      lat={this.state.trailerLat || 0}
                      lng={this.state.trailerLon || 0}
                    />
                  </Grid>
                </Grid>
              )}
          </div>
        ) : null}

        {this.state.onDeleteConfirmation && (
          <ConfirmDialoag
            onCancel={this.onCancel}
            onOk={() => this.removedItem(this.state.selecteditem)}
            title={this.props.translate("areYouWantToDelete")}
            children={this.state.selecteditem.name}
          ></ConfirmDialoag>
        )}
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  let driverIdsList = [];
  Object.values(state.allComputedAttributes).map(({ item, deviceIds }) => {
    if (deviceIds && item.attribute === "trailerUniqueId") {
      driverIdsList = [...driverIdsList, ...deviceIds];
    }
    return null;
  });
  return {
    ServerSetting: state.ServerSetting,
    // drivers: state.drivers,
    trailers: state.trailers,
    isTrailerLoad: state.isTrailerLoad,
    devices: state.devices,
    logInUser: state.logInUsers,
    themecolors: state.themeColors,
    deviceRelatedData: state.deviceRelatedData,
    allComputedAttributes: driverIdsList,
    trackId: state.trackId,
    mapLayer: state.mapLayer,
  };
};

export default connect(mapStateToProps)(
  withTranslationWrapper(withResources(Trailer, "Trailer"))
);
