import React from "react";
import { Grid } from "@mui/material";
import { checkPrivileges } from "../../Helpers";
import Loader from "../../Layout/Loader";
import Button from "../../Components/common/Button";
import Table from "../../Components/common/tableWithBackEndPagination";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
export const MaintenanceForm = ({
  data,
  isDataRecieved,
  translate,
  setPagination,
  handleChangeRowsPerPage,
  handleChangePage,
  SearchItem,
  themecolors,
  openCreateFrom,
  onRemove,
  onStatusChange,
  switchMode,
  mode,
  editMaintenanceForm,
  dueActive,
  logInUser,
}) => {
  return (
    <div>
      {
        <Grid container style={{ height: "100%" }}>
          <Grid item sm={12} xs={12}>
            {isDataRecieved ? (
              <Table
                rows={(data && data.data) || []}
                pagination={setPagination(data)}
                handleChangeRowsPerPage={(n) =>
                  handleChangeRowsPerPage(n, "maintenance")
                }
                handleChangePage={(n) => handleChangePage(n, "maintenance")}
                SearchItem={(n) => SearchItem(n, "maintenance")}
                onEdit={(n) => editMaintenanceForm(n)}
                createButton={
                  checkPrivileges("maintenanceCreate") && (
                    <div style={{ marginLeft: 10 }}>
                      <Button onClick={openCreateFrom}>
                        {translate("Create")}
                      </Button>
                    </div>
                  )
                }
                logInUser={logInUser}
                // switchMode={<>
                //     <div style={{ marginLeft: 0 }} >
                //     <Button onClick={()=>switchMode('showMaintenance')}
                //       className={'button-active'}>
                //       {translate('Maintenance')}</Button></div>
                //     <div style={{ marginLeft: 5 }} >
                //     <Button onClick={()=>switchMode('showHist','due')}
                //       className={dueActive ? 'button-active' : 'button-inactive'}>
                //       {/* className={'button-active'}> */}
                //       {translate('work')+' '+translate('due')}</Button></div>
                //     <div style={{ marginLeft: 5 }} >
                //       <Button onClick={()=>switchMode('showHist','history')}
                //       className={'button-inactive'}>
                //       {/* className={'button-active'}> */}
                //     {translate('maintenanceHistory')}</Button></div></>}
                switchMode={
                  <>
                    <AppBar
                      position="static"
                      color="inherit"
                      style={{ background: "none", boxShadow: "none" }}
                    >
                      <Tabs
                        value={mode}
                        onChange={switchMode}
                        TabIndicatorProps={{
                          style: { display: "none" },
                        }}
                        scrollButtons="on"
                        classes={{
                          root: "custom-tabs-root",
                          flexContainer: "custom-tabs",
                          scrollable: "custom-tabs-scrollable",
                          indicator: "custom-indicator",
                          scrollButtons: "scrollable-buttons",
                        }}
                      >
                        <Tab
                          value={"showMaintenance"}
                          classes={{
                            root: "common-tab",
                            selected: "tab-selected",
                          }}
                          label={translate("sharedMaintenance")}
                        />
                        {checkPrivileges("service") && (
                          <Tab
                            value={"due"}
                            classes={{
                              root: "common-tab",
                              selected: "tab-selected",
                            }}
                            label={
                              translate("work") +
                              " " +
                              translate("due") +
                              "/" +
                              translate("overdue")
                            }
                            // disabled={checkedDisableTab('form2')}
                            // disabled={() => switchMode('showHist', 'due')}
                          />
                        )}
                        {checkPrivileges("service") && (
                          <Tab
                            value={"history"}
                            classes={{
                              root: "common-tab",
                              selected: "tab-selected",
                            }}
                            label={translate("maintenanceHistory")}
                            // disabled={() => switchMode('showHist', 'history')}
                          />
                        )}
                      </Tabs>
                    </AppBar>
                  </>
                }
                onDelete={(n) => onRemove(n)}
                onStatusChange={(n) => onStatusChange(n)}
                isEditable={true}
                status={true}
                themecolors={themecolors}
                translate={translate}
                searchable
                hasAccessOfUpdate={checkPrivileges("userUpdate")}
                hasAccessOfDelete={checkPrivileges("userDelete")}
                // title={this.props.translate('users')}----------
                rowDefinition={[
                  {
                    id: "deviceName",
                    numeric: false,
                    disablePadding: false,
                    label: translate("sharedDevice"),
                  },
                  {
                    id: "name",
                    numeric: false,
                    disablePadding: false,
                    label: translate("sharedName"),
                  },
                  {
                    id: "attributes.mileage",
                    numeric: false,
                    disablePadding: false,
                    label: translate("targetValue"),
                    colspan: 3,
                  },
                  {
                    id: "attributes.repeat",
                    numeric: false,
                    disablePadding: false,
                    label: translate("repeat"),
                  },
                  {
                    id: "cost",
                    numeric: false,
                    disablePadding: false,
                    label: translate("cost"),
                  },
                ]}
              />
            ) : (
              <Loader />
            )}
          </Grid>
        </Grid>
      }
    </div>
  );
};
