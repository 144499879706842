import React, { Component } from "react";
// import classNames from "classnames";
import clsx from "clsx";

import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import RestoreIcon from "@mui/icons-material/Restore";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import EditIcon from "@mui/icons-material/Edit";
// import { lighten } from "@mui/styles/colorManipulator";
import MenuItem from "./MenuItem";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import styled from "styled-components";
import TextField from "./TextField";
import Avatar from "@mui/material/Avatar";
import NextPageIcon from "@mui/icons-material/NavigateNext";
import PrevPageIcon from "@mui/icons-material/NavigateBefore";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { getValue, setAttributeFormat } from "../../Helpers";
import { ReactComponent as NotificationOff } from "../../assets/notification-off.svg";
import { ReactComponent as NotificationOn } from "../../assets/notification-on.svg";
import Icon from "@mui/material/Icon";
import moment from "moment";
import AsyncSelect from "./asyncSelect";
import { SingleItem } from "../../Hooks/useSingleItem";
import { ReactComponent as SendIcon } from "../../assets/nav/send.svg";
let searchVehicle = [];
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends Component {
  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, classes, isEditable, isRowIcons, translate } =
      this.props;
    return (
      <TableHead>
        <TableRow className={classes.tableRow}>
          {this.props.rowDefinition.map(
            (row) =>
              !row.hide && (
                <TableCell
                  colSpan={row.colspan || 1}
                  key={row.id}
                  align={row.numeric ? "right" : "left"}
                  padding={row.disablePadding ? "none" : "default"}
                  sortDirection={orderBy === row.id ? order : false}
                  style={{
                    padding: "0 10px",
                    color: "inherit",
                  }}
                >
                  <Tooltip
                    title="Sort"
                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                    enterDelay={300}
                  >
                    <TableSortLabel
                      active={orderBy === row.id}
                      direction={order}
                      onClick={this.createSortHandler(row.id)}
                    >
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
              ),
            this
          )}
          {isRowIcons && (
            <TableCell
              key={"statistics"}
              width={60}
              style={{ padding: "0 10px" }}
            >
              {" "}
              {this.props.translate("statistics")}
            </TableCell>
          )}
          {isEditable && (
            <TableCell
              key={"editable"}
              width={60}
              style={{ padding: "0 10px", color: "inherit" }}
            />
          )}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = (theme) => ({
  root: {
    minHeight: "44px",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          backgroundColor: (theme.palette.secondary.light, 0.85),
        }
      : {
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 auto",
  },
  actions: {
    color: theme.palette.text.secondary,
  },
  title: {
    fontFamily: "inherit",
    flex: "0 0 auto",
    fontSize: 18,
    fontWeight: 500,
  },
});

let EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    classes,
    className,
    themecolors,
    column,
    selected,
    actionInHead,
    style,
  } = props;

  const ThemeIconButton = styled(IconButton)`
    background-color: ${(props) => themecolors.themeDarkColor};
    border-radius: 0;
    color: ${(props) => themecolors.textColor};
    &:hover {
      cursor: pointer;
      background-color: ${(props) => themecolors.themeLightColor};
    }
  `;

  return (
    <Toolbar
      className={
        clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        }) +
        " " +
        className
      }
      style={style}
    >
      <div>
        {
          <Typography className={classes.title} id="tableTitle" color="inherit">
            {props.title}
          </Typography>
        }
      </div>
      <div className={classes.spacer} />
      {props.btnName && (
        <Tooltip title={this.props.translate("sharedCreate")}>
          <Button
            disabled={!props.createButton}
            aria-label="Create"
            size="small"
            variant="outlined"
            color="inherit"
            onClick={props.onCreate}
            id="createButton"
          >
            {props.btnName}
          </Button>
        </Tooltip>
      )}
      <div className={classes.actions}>
        {actionInHead && numSelected > 0 ? (
          <Tooltip title="Delete">
            <ThemeIconButton
              aria-label="Delete"
              color="inherit"
              onClick={props.onDelete}
            >
              <DeleteIcon fontSize="small" />
            </ThemeIconButton>
          </Tooltip>
        ) : null}
        {actionInHead && numSelected === 1 ? (
          <Tooltip title="Edit">
            <ThemeIconButton
              aria-label="Edit"
              onClick={props.onEdit}
              color="inherit"
            >
              <EditIcon fontSize="small" />
            </ThemeIconButton>
          </Tooltip>
        ) : null}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
    paddingTop: 44,
    boxShadow: "none",
    position: "relative",
    background: "none",
    color: "inherit",
    tableLayout: "auto",
  },
  table: {
    minWidth: "100%",
  },
  tableWrapper: {
    overflow: "auto",
  },
  tableRow: {
    height: 36,
    "&:hover .actionButton": {
      display: 1,
      color: "inherit",
    },
    "& .actionButton": {
      cursor: "pointer",
      marginLeft: 5,
      display: 0,
      color: "inherit",
    },
  },
  toolbar: {
    root: {
      position: "absolute",
      bottom: 0,
      left: 0,
      right: 0,
      height: "44px",
      minHeight: "inherit",
    },
    toolbar: {
      height: "44px",
      minHeight: "inherit",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
  },
});

class EnhancedTable extends Component {
  constructor(props) {
    super(props);
    this.onEdit = this.onEdit.bind(this);
    this.showStatistics = this.showStatistics.bind(this);
    this.loginAsUser = this.loginAsUser.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.statusChange = this.statusChange.bind(this);
    this.onRestore = this.onRestore.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.drawValue = this.drawValue.bind(this);
    this.makeSearch = this.makeSearch.bind(this);
    this.state = {
      order: "asc",
      orderBy: "calories",
      selected: [],
      data: this.props.rows,
      page: this.props.page || 0,
      rowsPerPage: this.props.pagesize || 5,
      total: this.props.total || 0,
      lastPage: this.props.lastPage || 0,
      searchValue: "",
    };
  }

  makeSearch(event, vehicle) {
    if (event === "vehicles") {
      this.props.SearchVehicle(event, vehicle);
    } else {
      this.props.SearchItem(event.target.value);
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      this.setState((state) => ({ selected: state.data.map((n) => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, item) => {
    if (item.hasAccess !== undefined) {
      if (item.hasAccess) {
        if (this.props.onClick) {
          let newSelected = [];
          newSelected.push(item);
          this.props.onClick(item);
          this.setState({ selected: newSelected });
        }
      }
    } else {
      if (this.props.onClick) {
        let newSelected = [];
        newSelected.push(item);
        this.props.onClick(item);
        this.setState({ selected: newSelected });
      }
    }
  };

  handleChangePage = (action) => {
    let { pagination } = this.props;
    if (pagination) {
      if (action === "first") {
        if (pagination.page > 1) {
          this.props.handleChangePage(1);
        }
      } else if (action === "prev") {
        if (pagination.page && pagination.page > 1) {
          this.props.handleChangePage(pagination.page - 1);
        }
      } else if (action === "next") {
        if (
          pagination.hasNext &&
          pagination.page &&
          pagination.lastPage &&
          pagination.lastPage > pagination.page
        ) {
          this.props.handleChangePage(pagination.page + 1);
        }
      } else if (action === "last") {
        if (pagination.lastPage) {
          this.props.handleChangePage(pagination.lastPage);
        }
      }
    }
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
    this.props.handleChangeRowsPerPage(event.target.value);
  };

  isSelected = (id) =>
    (this.state.selected[0] && this.state.selected[0].id === id) || false;

  onEdit(event, selected) {
    event.stopPropagation();
    this.props.onEdit(selected);
  }

  loginAsUser(event, selected) {
    event.stopPropagation();
    this.props.loginAsUser(selected);
  }
  handleChange = (event, name) => {
    const { target } = event;
    if (target) {
      this.props.loginAsUserhandleChange(name, event.target.value);
    }
  };
  stopthandleClick = (e) => {
    e.stopPropagation();
  };
  showStatistics(event, selected) {
    event.stopPropagation();
    this.props.showStatistics(selected);
  }

  onDelete(event, selected) {
    event.stopPropagation();
    this.props.onDelete(selected);
    this.setState({ selected: [] });
  }
  onRestore(event, selected) {
    event.stopPropagation();
    this.props.onRestore(selected);
    this.setState({ selected: [] });
  }
  statusChange(event, selected) {
    event.stopPropagation();
    this.props.onStatusChange(selected[0]);
  }
  onClose() {
    this.props.onClose();
  }

  onCreate() {
    this.props.onCreate();
  }

  drawValue(row, def) {
    const { type, id, nested, nestedId } = def;
    const { valueType } = row;
    let value;

    if (nested) {
      value = row[id][nestedId];
    } else {
      value = row[id];
    }

    if (type && type === "any") {
      if (valueType && valueType === "boolean") {
        if (value === true) {
          return "Yes";
        }
        return "No";
      } else {
        return value;
      }
    } else if (type && type === "boolean") {
      if (value === true) {
        return "Yes";
      }
      return "No";
    } else if (type && type === "userType") {
      if (value === 1) {
        return "User";
      } else if (value === -1) {
        return "SuperAdmin";
      } else if (value === 2) {
        return "Admin";
      } else if (value === 3) {
        return "Dealer";
      }
      return "";
    } else if (type && type === "symbol") {
      let ty = "";
      if (value === -1) {
        ty = "O";
      } else if (value === 1) {
        ty = "U";
      } else if (value === 2) {
        ty = "A";
      } else if (value === 3) {
        ty = "AA";
      } else if (value === 4) {
        ty = "SP";
      } else if (value === 5) {
        ty = "R";
      } else if (value === 6) {
        ty = "D";
      } else {
        ty = "";
      }
      return (
        <Avatar
          className={"avatar-" + ty}
          style={{
            width: 18,
            height: 18,
            fontSize: 12,
            display: "inline-flex",
            marginLeft: "2px",
          }}
        >
          {ty}
        </Avatar>
      );
    } else {
      return value;
    }
  }

  searchList = async (inputValue) => {
    searchVehicle = [];
    this.props.vehicles.map((item) =>
      (item.label + " " + item.vehicleLP)
        .toLowerCase()
        .includes(inputValue.toLowerCase())
        ? searchVehicle.push(item)
        : ""
    );

    return await searchVehicle;
  };

  render() {
    const { classes } = this.props;
    const { order, orderBy, selected, rowsPerPage, page } = this.state;
    const { rows: data = [], isCursoPointer, pagination } = this.props;
    const emptyRows = rowsPerPage - data.length;
    let serverTimeZoneName = "Asia/Dubai";
    let vehicle = "N/A";
    if (
      this.props.ServerSetting &&
      this.props.ServerSetting.attributes &&
      this.props.ServerSetting.attributes.timezone
    ) {
      serverTimeZoneName = this.props.ServerSetting.attributes.timezone;
    }
    if (
      this.props.logInUser &&
      this.props.logInUser.attributes &&
      this.props.logInUser.attributes.timezone
    ) {
      serverTimeZoneName = this.props.logInUser.attributes.timezone;
    }
    return (
      <Paper
        className={classes.root}
        style={{ paddingTop: this.props.title ? "44px" : 0 }}
      >
        {this.props.title && (
          <EnhancedTableToolbar
            actionInHead={this.props.actionInHead}
            column={this.props.showInHead}
            selected={this.state.selected}
            insideDialog={this.props.insideDialog}
            themecolors={this.props.themecolors}
            numSelected={selected.length}
            title={this.props.title}
            onEdit={this.onEdit}
            showStatistics={this.showStatistics}
            loginAsUser={this.loginAsUser}
            handleChange={this.handleChange}
            onDelete={this.onDelete}
            onRestore={this.onRestore}
            onClose={this.onClose}
            onCreate={this.onCreate}
            createButton={this.props.createButton}
            btnName={this.props.btnName}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              background: this.props.themecolors.backgroundColor,
              color: this.props.themecolors.textColor,
              paddingRight: this.props.insideDialog ? "44px" : "def",
              paddingLeft: this.props.insideDialog ? "44px" : "def",
            }}
          />
        )}

        <div className={classes.tableWrapper}>
          {this.props.trashLabel && (
            <div
              style={{ marginTop: 8, marginBottom: 4, display: "inline-flex" }}
            >
              <h3 className="v-tab-heading pull-left">
                <SendIcon
                  width={14}
                  height={14}
                  style={{ marginRight: 10, verticalAlign: "middle" }}
                />
                {this.props.translate("trash")}
              </h3>
            </div>
          )}
          {this.props.switchMode && (
            <div
              style={{ marginTop: 8, marginBottom: 4, display: "inline-flex" }}
            >
              {this.props.switchMode}
            </div>
          )}
          {this.props.isDownload && (
            <div
              style={{ marginTop: 8, display: "inline-flex", float: "right" }}
            >
              {this.props.isDownload}
            </div>
          )}
          {this.props.createButton && (
            <div
              style={{ marginTop: 8, display: "inline-flex", float: "right" }}
            >
              {this.props.createButton}
            </div>
          )}
          {this.props.isFilter && (
            <div
              style={{
                marginTop: 8,
                marginLeft: 10,
                marginRight: 5,
                display: "inline-flex",
                float: "right",
              }}
            >
              {this.props.isFilter}
            </div>
          )}
          {this.props.searchable && (
            <div style={{ textAlign: "right", float: "right" }}>
              <TextField
                fullWidth
                label={this.props.translate("sharedSearch")}
                onChange={this.makeSearch}
              />
            </div>
          )}
          {this.props.isSearchVehicle && (
            <div
              style={{
                float: "right",
                width: 150,
                marginRight: 5,
                fontSize: 12,
              }}
            >
              <SingleItem
                collection={"vehicles"}
                field={"id"}
                value={0}
                item={"multi"}
              >
                {(id) => {
                  let vehiclesList = id.map((item) => {
                    return {
                      id: item.id,
                      label: item.label,
                      value: item.id,
                      vehicleLP: item.vehicleLicensePlate,
                      valueType: "string",
                    };
                  });
                  vehiclesList.unshift({
                    id: 0,
                    label: "All Vehicles",
                    value: 0,
                    vehicleLP: "00000",
                    valueType: "string",
                  });
                  return (
                    <AsyncSelect
                      value={this.props.searchVehicleId}
                      placeholder={"Search Vehicles"}
                      selectName="vehicles"
                      // resultMessage={resultMessage}
                      defaultOptions={vehiclesList}
                      handleChange={this.makeSearch}
                      loadOptions={this.searchList}
                      isMulti={false}
                    />
                  );
                }}
              </SingleItem>
            </div>
          )}
          <Table
            className={classes.table + " react-base-table "}
            classes={classes}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              translate={this.props.translate}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              isEditable={this.props.isEditable}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              themecolors={this.props.themecolors}
              rowDefinition={
                this.props.rowDefinition || [
                  {
                    id: "name",
                    numeric: false,
                    disablePadding: true,
                    label: this.props.translate("name"),
                  },
                ]
              }
              classes={classes}
              isRowIcons={this.props.isRowIcons}
            />
            <TableBody>
              {data && data.length ? (
                stableSort(data, getSorting(order, orderBy)).map((n) => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      onClick={(event) => this.handleClick(event, n)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id || n.name}
                      selected={isSelected}
                      className={
                        classes.tableRow + " " + (isCursoPointer && "pointer")
                      }
                    >
                      {this.props.rowDefinition.map((r, i) => {
                        return (
                          // <TableCell
                          //   key={r.id}
                          //   align={r.numeric ? 'right' : 'left'}
                          //   padding={r.disablePadding ? 'none' : 'default'}
                          //   component='td'
                          //   scope='row'
                          //   size='small'
                          //   style={{ whiteSpace: 'nowrap', color: 'inherit' }}
                          // >
                          r.id === "attributes.mileage" ? (
                            <>
                              <TableCell
                                key={i}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",

                                  width: 50,
                                }}
                              >
                                {n.attributes.mileage &&
                                n.attributes.mileageInterval
                                  ? setAttributeFormat(
                                      "mileage",
                                      n.attributes.mileage +
                                        n.attributes.mileageInterval
                                    )
                                  : n.attributes.mileage
                                  ? setAttributeFormat(
                                      "mileage",
                                      n.attributes.mileage
                                    )
                                  : "-"}
                              </TableCell>

                              <TableCell
                                key={i + "_"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",

                                  width: 50,
                                }}
                              >
                                {/* {setAttributeFormat ('hours', n.attributes.hours) || '-'} */}
                                {n.attributes.hours &&
                                n.attributes.hoursInterval
                                  ? (
                                      (n.attributes.hours +
                                        n.attributes.hoursInterval) /
                                      (1000 * 3600)
                                    ).toFixed(2) + " hours"
                                  : n.attributes.hours
                                  ? (
                                      n.attributes.hours /
                                      (1000 * 3600)
                                    ).toFixed(2) + " hours"
                                  : "-"}
                              </TableCell>

                              <TableCell
                                key={i + "__"}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",

                                  width: 50,
                                }}
                              >
                                {n.attributes.date
                                  ? moment(n.attributes.date)
                                      .tz(serverTimeZoneName)
                                      .format("DD-MM-YYYY")
                                  : "-"}
                              </TableCell>
                            </>
                          ) : r.id === "attributes.value" ? (
                            <>
                              <TableCell
                                key={i}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",

                                  width: 50,
                                }}
                              >
                                {n.attributes.input === "mileage"
                                  ? setAttributeFormat(
                                      "mileage",
                                      n.attributes.value
                                    )
                                  : "-"}
                              </TableCell>
                              <TableCell
                                key={i}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",

                                  width: 50,
                                }}
                              >
                                {/* {n.attributes.input==='hours'? setAttributeFormat ('hours', n.attributes.value) : '-'} */}
                                {n.attributes.input === "hours"
                                  ? (
                                      n.attributes.value /
                                      (1000 * 3600)
                                    ).toFixed(2) + " hours"
                                  : "-"}
                              </TableCell>
                              {/* <TableCell
                              key={i}
                              component='td'
                              scope='row'
                              style={{
                                padding: '0 10px',
                                whiteSpace: 'nowrap',
                                color: 'inherit',
                                
                                  width:50
                              }}
                            >
                          {n.attributes.input==='date'? moment(n.attributes.value).tz(serverTimeZoneName).format('DD-MM-YYYY') : '-'}   
                        </TableCell> */}
                            </>
                          ) : r.id === "files" ? (
                            !r.hide && (
                              <TableCell
                                key={i}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",

                                  width: 50,
                                }}
                              >
                                {
                                  <PhotoLibraryIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.props.imagePopup(n.files)
                                    }
                                  />
                                }
                              </TableCell>
                            )
                          ) : r.id === "cost" ? (
                            <TableCell
                              key={i}
                              component="td"
                              scope="row"
                              style={{
                                padding: "0 10px",
                                whiteSpace: "nowrap",
                                color: "inherit",
                                width: 50,
                              }}
                            >
                              {n.cost +
                                " " +
                                ((this.props.logInUser &&
                                  this.props.logInUser.attributes &&
                                  this.props.logInUser.attributes
                                    .currencyLabel) ||
                                  "AED")}
                            </TableCell>
                          ) : r.id === "status" ? (
                            !r.hide && (
                              <TableCell
                                key={i}
                                component="td"
                                scope="row"
                                style={{
                                  padding: "0 10px",
                                  whiteSpace: "nowrap",
                                  color: "inherit",
                                  width: 50,
                                }}
                              >
                                {
                                  n.status === "required"
                                    ? this.props.translate("Due")
                                    : n.status === "expired"
                                    ? this.props.translate("Overdue")
                                    : null
                                  // ):
                                }
                              </TableCell>
                            )
                          ) : (
                            <TableCell
                              key={i}
                              component="td"
                              scope="row"
                              style={{
                                padding: "0 10px",
                                whiteSpace: "nowrap",
                                color: "inherit",
                              }}
                            >
                              {
                                // r.id==='files' ?(
                                //   <PhotoLibraryIcon
                                //   onClick={() => this.props.imagePopup(n.files)}
                                //   />
                                // ):
                                r.id === "attributes.repeat" ? (
                                  n.attributes.repeat ? (
                                    this.props.translate("Yes")
                                  ) : (
                                    this.props.translate("No")
                                  )
                                ) : r.id === "type" ? (
                                  n.type === "date" ? (
                                    this.props.translate("date")
                                  ) : n.type === "hours" ? (
                                    this.props.translate("sharedHours")
                                  ) : n.type === "totalDistance" ? (
                                    this.props.translate("Mileage")
                                  ) : null
                                ) : r.id === "vehicleName" ? (
                                  // this.props.deviceRelatedData[n.deviceId]&&this.props.deviceRelatedData[n.deviceId].name
                                  ((vehicle = this.props.vehicles.find(
                                    (item) => item.id === n.vehicleId
                                  )),
                                  vehicle && vehicle.label)
                                ) : r.id === "odometer" ? (
                                  n.attributes.input === "mileage" ? (
                                    <span
                                      style={{
                                        color: "#41ef68",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {" "}
                                      {setAttributeFormat(
                                        "mileage",
                                        n.odometer
                                      )}
                                    </span>
                                  ) : (
                                    setAttributeFormat("mileage", n.odometer)
                                  )
                                ) : r.id === "engineHours" ? (
                                  n.attributes.input === "hours" ? (
                                    <span
                                      style={{
                                        color: "#41ef68",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {" "}
                                      {(n.engineHours / (1000 * 3600)).toFixed(
                                        2
                                      ) + " hours"}
                                    </span>
                                  ) : (
                                    (n.engineHours / (1000 * 3600)).toFixed(2) +
                                    " hours"
                                  )
                                ) : // r.id===('timeStamp')?
                                //    moment(n.timeStamp).tz(serverTimeZoneName).format('DD-MM-YYYY hh:mm')
                                //   :getValue(n,r.id)
                                // }
                                r.id === "serviceTime" ? (
                                  n.attributes.input === "date" ? (
                                    <span
                                      style={{
                                        color: "#41ef68",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {moment(n.serviceTime)
                                        .tz(serverTimeZoneName)
                                        .format("DD-MM-YYYY")}
                                    </span>
                                  ) : (
                                    moment(n.serviceTime)
                                      .tz(serverTimeZoneName)
                                      .format("DD-MM-YYYY")
                                  )
                                ) : (
                                  getValue(n, r.id)
                                )
                              }
                            </TableCell>
                          )
                        );
                      })}
                      {this.props.isRowIcons && (
                        <TableCell
                          key={n.id + "tableCel"}
                          align={"left"}
                          padding={"default"}
                          component="td"
                          scope="row"
                          size="small"
                          style={{ whiteSpace: "nowrap", color: "inherit" }}
                        >
                          <a
                            href="javascript:void(0)"
                            onClick={(e) => this.showStatistics(e, [n])}
                          >
                            <EqualizerIcon fontSize="small" />
                          </a>
                        </TableCell>
                      )}
                      {this.props.isEditable && (
                        <TableCell
                          key={n.id + "tableCel"}
                          component="td"
                          scope="row"
                          style={{
                            padding: "0 10px",
                            whiteSpace: "nowrap",
                            color: "inherit",

                            verticalAlign: "bottom",
                          }}
                        >
                          {this.props.status && (
                            <Tooltip
                              classes={{
                                popper: "menu-popper",
                                tooltip: "menu-popper-tooltip",
                              }}
                              title={
                                n.attributes.status
                                  ? "Click here to turn off Status"
                                  : "Click here to turn on Status"
                              }
                            >
                              <a
                                href="javascript:void(0)"
                                onClick={(e) => this.statusChange(e, [n])}
                                style={{ color: "inherit" }}
                              >
                                <Icon
                                  className="material-icons"
                                  fontSize="small"
                                  style={{ marginBottom: 10 }}
                                >
                                  {n.attributes.status ? (
                                    <NotificationOn />
                                  ) : (
                                    <NotificationOff />
                                  )}
                                </Icon>
                              </a>
                            </Tooltip>
                          )}

                          {this.props.hasAccessOfUpdate &&
                          this.props.completeIcon &&
                          n.attributes &&
                          n.attributes.status !== "completed" ? (
                            <Tooltip title="Mark as Complete">
                              <a
                                href="javascript:void(0)"
                                onClick={(e) => this.onEdit(e, [n])}
                                style={{ color: "inherit" }}
                              >
                                <DoneIcon
                                  className="actionButton"
                                  // fontSize='small'
                                />
                              </a>
                            </Tooltip>
                          ) : this.props.hasAccessOfUpdate ? (
                            <Tooltip title="Edit">
                              <a
                                href="javascript:void(0)"
                                onClick={(e) => this.onEdit(e, [n])}
                                style={{ color: "inherit" }}
                              >
                                <EditIcon
                                  className="actionButton"
                                  fontSize="small"
                                />
                              </a>
                            </Tooltip>
                          ) : null}

                          {this.props.restore && (
                            <Tooltip title="Restore">
                              <a
                                href={null}
                                onClick={(e) => this.onRestore(e, [n])}
                                style={{
                                  marginLeft: 5,
                                  marginTop: 3,
                                  display: "inline-block",
                                  color: "inherit",
                                }}
                              >
                                <RestoreIcon
                                  className="actionButton"
                                  fontSize="small"
                                />
                              </a>
                            </Tooltip>
                          )}
                          {this.props.hasAccessOfDelete && (
                            <Tooltip
                              title={
                                this.props.restore
                                  ? "Delete Permanently"
                                  : "Delete"
                              }
                            >
                              <a
                                href="javascript:void(0)"
                                onClick={(e) => this.onDelete(e, [n])}
                                style={{ color: "inherit" }}
                              >
                                <DeleteIcon
                                  className="actionButton"
                                  fontSize="small"
                                />
                              </a>
                            </Tooltip>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={12} style={{ color: "inherit" }}>
                    <h3 style={{ display: "flex", justifyContent: "center" }}>
                      {this.props.translate("noRecordFound")}
                    </h3>
                  </TableCell>
                </TableRow>
              )}
              {/* {emptyRows > 0 && (
                <TableRow style={{ height: 36 * emptyRows }}>
                  <TableCell colSpan={9} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </div>
        {
          <div
            style={{
              padding: "0 20px",
              display: "flex",
              alignItems: "center",
              color: "inherit",
            }}
          >
            <span style={{ marginRight: 10 }} className="hidden-responsive">
              {this.props.translate("rowsPerPage")}
            </span>
            <span style={{ marginRight: 10, minWidth: 60 }}>
              <TextField
                id="rowsPerPage"
                select
                style={{ width: 60 }}
                value={(pagination && pagination.pageSize) || 5}
                onChange={(e) => this.handleChangeRowsPerPage(e)}
                margin="dense"
              >
                {rowsPerPageOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </span>
            <span style={{ flex: 1 }}></span>

            <span style={{ marginRight: 5, whiteSpace: "nowrap" }}>
              {this.props.translate("page")}{" "}
              <strong>{pagination && pagination.page}</strong>{" "}
              <span className="visible-responsive">
                {" "}
                / {pagination && pagination.lastPage} -{" "}
                {pagination && pagination.total}{" "}
              </span>
            </span>
            <span style={{ marginRight: 10 }} className="hidden-responsive">
              {this.props.translate("outOf")}{" "}
              <strong>{(pagination && pagination.lastPage) || 1}</strong>
            </span>
            <span style={{ marginRight: 10 }} className="hidden-responsive">
              {this.props.translate("totalRecords")}{" "}
              <strong>{pagination && pagination.total}</strong>
            </span>
            <span style={{ marginRight: 10, cursor: "pointer" }}>
              <FirstPageIcon
                fontSize="small"
                onClick={(e) => this.handleChangePage("first")}
              />
            </span>
            <span style={{ marginRight: 10, cursor: "pointer" }}>
              <PrevPageIcon
                fontSize="small"
                onClick={(e) => this.handleChangePage("prev")}
              />
            </span>
            <span style={{ marginRight: 10, cursor: "pointer" }}>
              <NextPageIcon
                fontSize="small"
                onClick={(e) => this.handleChangePage("next")}
              />
            </span>
            <span style={{ marginRight: 10, cursor: "pointer" }}>
              <LastPageIcon
                fontSize="small"
                onClick={(e) => this.handleChangePage("last")}
              />
            </span>
          </div>
        }
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(EnhancedTable);
const rowsPerPageOptions = [5, 10, 15, 20, 25, 50, 100, 500];
